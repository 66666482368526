import { FilterValue } from 'app/filter/models/filter-value';
export class FilterValueCache {

  // cache for holding all filter display values for a single filter
  private cache: Map<string, string> = new Map<string, string>();

  constructor() {}

  put(fv: FilterValue) {
    this.cache.set(fv.n, fv.d || fv.n);
  }

  getDisplay(key: string): string {
    return this.cache.get(key);
  }

  public getAll(): FilterValue[] {
    const out: FilterValue[] = [];

    for (const [n, d] of this.cache.entries()) {
      out.push({
        n: n,
        d: d || n
      });
    }

    return out;
  }
}

import { BehaviorSubject } from 'rxjs';
import { ValidationMessage, ValidationMessageStatus } from 'app/core/models/validation-message';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { OnInit, Directive } from '@angular/core';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { CmsConfig, CmsField } from '@siq-js/cms-lib';
import { DateRangeInterface, DateSelectionConfig } from 'app/siq-forms/modules/dates/models/interfaces';

@Directive()
export abstract class BaseStepperComponent extends BaseSiqComponent implements OnInit {
  public dateSelectionConfig: DateSelectionConfig = {
    primaryDate: {
      rangeMode: true,
      allowShortcuts: true,
      shortcutPosition: 'right',
      weekEndingDay: true
    }
  };
  public disableSubmitBtn = true;
  public expanded = false;
  public primaryEntity: string;
  public queryModeModalConfig: QueryModeModalConfig;
  public stepValidationMessages: ValidationMessage[]; // validation message for each step

  constructor(protected asyncStatusService: AsyncStatusService) {
    super();
  }

  public static dimensionFilterFn = (schema: string, entity: CmsField , primaryEntity: string): boolean =>
    entity.retailer === schema || entity.retailer === primaryEntity || entity.retailer === CmsConfig.CORE_SCHEMA_ID

  public async ngOnInit() {
    await this.asyncStatusService.isReady({ cms: true, envConfig: true });
    this.primaryEntity = EnvConfigService.getConfig().primaryEntity;
    this.init();
  }
  /**
   * Update a specific step's validation message
   * @param i: index
   * @param status: ValidationMessage
   * @param message: message
   */
  public updateValidationMessage(i: number, status: ValidationMessageStatus, message: string): void {
    const valMsg: ValidationMessage = {
      status: status,
      message
    };
    this.stepValidationMessages.splice(i, 1, valMsg);
  }

  protected abstract dateChanged(dates: DateRangeInterface): void;
  protected abstract filterGroupChanged(filterModel: FilterSelection[], group: any): void;
  protected abstract init(): void;
  protected abstract onSubmit(): void;
}

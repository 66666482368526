<siq-app-header [config]="config"></siq-app-header>
<siq-query-mode #queryMode [disabled]="false" [modalConfig]="queryModeModalConfig" [overrideSchema]="overrideSchema"></siq-query-mode>

<siq-js-dayparts-stepper
  [formData]="formData"
  [queryMode]="queryMode"
  [submit]="submit"
  [overrideSchema]="overrideSchema"
>

</siq-js-dayparts-stepper>
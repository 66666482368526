import { filter, Subject, take, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordFormFields } from 'app/profile/models/password-form-fields.model';
import { User } from 'app/user/models/user.model';
import { UserPrefs } from 'app/profile/models/user-prefs.model';
import { UserService } from 'app/user/services/user.service';
import { AuthService } from 'app/auth/services/auth.service';
import { Router } from '@angular/router';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { UserPayloadInterface } from 'app/user/models/user-payload-interface';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { AccessGroup } from 'app/access-group/models/access-group.model';
import { GroupedArrayFunctions } from '@siq-js/core-lib';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { CmsApplication } from '@siq-js/cms-lib';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { NotificationService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public currentPWErrorMsg = '';
  public currentUser: User;
  public inputPrefix: String;
  public pristinePrefs: UserPrefs = {};
  public preferencesForm: UserPrefs = {};
  public passwordForm: PasswordFormFields = {};
  public userForm: UntypedFormGroup;
  public USER_EMAIL_TAKEN_NOTIFICATION = 'This email is already taken. Please enter a new one.';
  public emailErrorMsg: String;
  public formValueChanged: boolean = false;
  public passwordSuccess = false;
  public showSecret = false;
  public isAdmin = false;
  public adminRoute = '';
  @ViewChild(NgForm, {static: true}) passwordFormElement: NgForm;
  private unsub = new Subject<void>();
  private userFormRef;
  private currentAG: string;
  homePageValues: CmsApplication[] = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private accessGroupService: AccessGroupService,
    protected notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    // welcome page on first line
    this.homePageValues.push(new CmsApplication({path : '/' + 'welcome', description: '', color: '', active: true, display: 'Welcome Page', id: ''}))
    ApplicationService.Applications$.pipe(filter(isNotNullOrUndefined), take(1)).subscribe(activeApps => {
      this.homePageValues.push(...GroupedArrayFunctions.flatten(activeApps));
    });
    this.getPrefs();
    this.getUser();
    this.inputPrefix = window.location.origin + '/';
    this.isAdmin = this.currentUser.isInternal();
    this.initUserForm();
    this.accessGroupService
      .getAccessGroup(this.currentUser.accessGroup)
      .subscribe((ag: AccessGroup) => {
        this.currentAG = ag.getDisplayName();
        this.addAccessGroupName();
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  initUserForm() {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.currentUser.firstName, [
        Validators.required
      ]),
      lastName: new UntypedFormControl(this.currentUser.lastName, [
        Validators.required
      ]),
      email: new UntypedFormControl(this.currentUser.email, [
        Validators.required,
        Validators.email
      ]),
      accessGroup: new UntypedFormControl({value: this.currentAG, disabled: true})
    });
    this.userForm.controls.email.setErrors(null);
    this.createRef(this.userForm.value);
    this.formValueChanged = false;
    this.onFormValueChanges();
  }

  addAccessGroupName() {
    this.userForm.setControl(
      'accessGroup',
      new UntypedFormControl({
        value: this.currentAG,
        disabled: true
      })
    );
  }

  adminNavTo() {
    this.router.navigate([UtilsService.scrubUrl(this.adminRoute)], {
      queryParams: UtilsService.rebuildParams(this.adminRoute)
    });
  }

  getPrefs() {
    this.userService.getPreferences()
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe((res) => this.handlePrefs(res.preferenceJson));
  }

  getUser() {
    this.currentUser = AuthService.CurrentUser$.getValue();
  }

  setPrefs() {
    this.userService.setPreferences(this.preferencesForm)
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe((res) => this.handlePrefs(res.preferenceJson));
  }

  setPW(): void {
    this.passwordSuccess = false;
    this.currentPWErrorMsg = '';

    let req = this.userService.setPassword(this.passwordForm);

    req.pipe(
      takeUntil(this.unsub))
      .subscribe(
        (res) => {
          this.passwordSuccess = true;
          this.passwordFormElement.resetForm();
          this.authService.logout().subscribe(() => this.router.navigate(['/login']));
        },
        (err) => {
          this.currentPWErrorMsg = 'The value entered for your current password is incorrect.';
          this.passwordForm.password = null;
        }
      );
  }

  handlePrefs(prefs) {
    this.pristinePrefs = prefs;
    _.assign(this.preferencesForm, prefs);
  }

  submitPreferencesForm() {
    this.setPrefs();
  }

  submitPasswordForm(): void {
    this.setPW();
  }

  submitUserForm() {
    this.mapFormToUser(this.currentUser);
    let payload: UserPayloadInterface = this.currentUser.getUserAsPayload(false);

    this.userService.updateProfile(payload).subscribe(
      resp => {
        this.formValueChanged = false;
      },
      error => {
        // revert user name in application cache
        if (error.error.siteUserDTO) {
          this.mapUserToForm(error.error.siteUserDTO);
        }
        if (error?.error.errorMessage?.includes('EMAIL_ALREADY_USED')) {
          this.userForm.controls.email.setErrors({ invalid: true });
          this.notificationService.error(this.USER_EMAIL_TAKEN_NOTIFICATION, 'Error');
          this.emailErrorMsg = this.USER_EMAIL_TAKEN_NOTIFICATION;
        } else if (error.status === 403) {
          this.notificationService.error(error.error, 'Error');
          if (error.error.includes("email")) {
            this.userForm.controls.email.setErrors({ invalid: true });
            this.emailErrorMsg = error.error;
            this.currentUser.email = this.userFormRef.email;
          }
        } else {
          this.initUserForm();
          throw error;
        }
      }
    );
  }

  private onFormValueChanges(): void {
    this.userForm.valueChanges
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(val => {
        this.formValueChanged = !_.isEqual(val, this.userFormRef);
      });
  }

  private mapFormToUser(userRef: User) {
    userRef.firstName = this.userForm.value.firstName;
    userRef.lastName = this.userForm.value.lastName;
    userRef.email = this.userForm.value.email;
  }

  private mapUserToForm(userRef: User) {
    this.userForm.value.firstName = userRef.firstName;
    this.userForm.value.lastName = userRef.lastName;
    this.userForm.value.email = userRef.email;
    this.currentUser.firstName = userRef.firstName;
    this.currentUser.lastName = userRef.lastName;
    this.currentUser.email = userRef.email;
  }

  private createRef(obj: any) {
    this.userFormRef = _.cloneDeep(obj);
  }
}

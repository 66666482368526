<ng-container *ngIf="ready$ | async">
  <div
    id="{{ id }}"
    class="grid-outer-wrapper"
  >

    <div class="async-totals-wrapper">
      <siq-js-async-totals
        [gridComponent]="this"
      ></siq-js-async-totals>
    </div>

    <div class="visual-table-container grid-container"
      [attr.data-async-value]="this.asyncTotalsComponent?.config.progressValue !== null && this.asyncTotalsComponent?.config.progressValue !== undefined ? this.asyncTotalsComponent?.config.progressValue : ''"
    >
      <!--   TRANSITION: Copied/moved from visual-table.component.html-->
      <ag-grid-angular #gridGrid
        class="{{ agTheme }}"
        (paginationChanged)="onPaginationChanged()"
        [suppressPaginationPanel]="!(paginationConfig?.pagination)"
        [gridOptions]="gridOptions"
        [components]="frameworkComponents"
        [getContextMenuItems]="getContextMenuItems"
      >
      </ag-grid-angular>
    </div>
  
    <siq-js-grid-custom-paging 
      *ngIf="paginationConfig?.pagination && paginationConfig?.customPagination && grid"
      [gridOptions]="gridOptions"
      [grid]="grid"
      [paginationPageLoaded]="paginationPageLoaded"
    >
    </siq-js-grid-custom-paging>
    
  </div>
</ng-container>

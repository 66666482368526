import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesService } from 'app/activity/modules/favorites/shared/favorites.service';
import { FavoritesDetailComponent } from 'app/activity/modules/favorites/favorites-detail/favorites-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SiqMaterialModule
  ],
  declarations: [
    FavoritesDetailComponent
  ],
  providers: [
    FavoritesService
  ]
})
export class FavoritesModule { }

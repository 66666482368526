<div class="container" *ngIf="model$ | async as model">

  <mat-chip-list>
    <siq-filter-chip *ngFor="let filter of model"
                     [model]="filter"
                     [parent]="this"
    ></siq-filter-chip>
  </mat-chip-list>

  <div class="no-filters" *ngIf="!model.length">No Filters Selected</div>

</div>

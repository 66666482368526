import { Routes, Route } from '@angular/router';
import { AccessGroupAdminGuard } from 'app/guards/access-group-admin-guard';
import { AuthGuard } from 'app/guards/auth-guard';
import { UserListComponent } from 'app/user/components/user-list/user-list.component';
import { UserFormComponent } from 'app/user/components/user-form/user-form.component';

let detailChildren = [
  {
    path: '',
    component: UserFormComponent,
    canActivate: [
      AccessGroupAdminGuard
    ]
  }
];

const usersRoute: Route = {
  path: 'users',
  canActivate: [
    AuthGuard,
    AccessGroupAdminGuard
  ],
  runGuardsAndResolvers: 'always',
  data: {
    name: 'Users',
    icon: 'people'
  },
  children: [
    {
      path: '',
      component: UserListComponent
    },
    {
      path: ':id',
      children: [
        ...detailChildren,
        {
          path: 'form',
          children: [
            ...detailChildren
          ]
        }
      ]
    }
  ]
};

export const UserRoutes: Routes = [
  usersRoute
];

import {Injectable} from '@angular/core';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import {BehaviorSubject, Observable, map, of} from "rxjs";

interface StoreIdsResponse {
  [key: string]: string[]; // key is retailer, val is store ids
};

@Injectable()
export class SameStoreSalesService {
  public static readonly STORE_ID = 'store_id';
  public static readonly ENDPOINT = 'same-store-sales';
  public static checked$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public static openStoreIds: Map<string, Set<string>> = new Map();// key is retailer, val is store ids
  public static dates: DateRangeInterface;
  public static schema: string;
  public static currModalRetailer: string;

  public static getChecked() {
    return this.checked$.getValue();
  }

  public static setChecked(checked: boolean) {
    this.checked$.next(checked)
  }

  public static setDates(dates: DateRangeInterface) {
    this.dates = dates;
  }

  public static setSchema(schema: string) {
    this.schema = schema;
  }


  constructor(private siqHttp: SiqHttpService) {}

  getOpenStoreIds(check: boolean): Observable<Map<string, Set<string>>> {
    if (!check) {
      const map: Map<string, Set<string>> = new Map();
      if (SameStoreSalesService.schema === EnvConfigService.getConfig().primaryEntity) { // Multi
        EnvConfigService.getConfig().retailers.forEach(r => {
          map.set(r, new Set<string>());
        })
      } else {
        map.set(SameStoreSalesService.schema, new Set<string>());
      }
      return of(map);
    } else {
      const body = {
        startDate: DatesService.format(SameStoreSalesService.dates.begin, AppSiqConstants._dateFormat),
        endDate: DatesService.format(SameStoreSalesService.dates.end, AppSiqConstants._dateFormat),
      }
      if (SameStoreSalesService.schema !== EnvConfigService.getConfig().primaryEntity) {
        // Single Retailer Mode
        body['retailers'] = [SameStoreSalesService.schema];
      } else {
        // TODO: Multi Retailer Mode; Need to code and test this part
        // The output here should be an array of each retailer id, like: ["retailer-X", "retailer-Y"]
        // Have to confirm this with BE devs
      }
      return this.siqHttp.create({
        endpoint: SameStoreSalesService.ENDPOINT,
        body: body,
        suppressNotification: true,
      })
      .pipe(
        // catchError(err => { // TODO: testing multi code
        //   return of (
        //     {
        //       body: {
        //         cals: ['100', '10000', '101'], // 'cals' is ONLY in dev3
        //         // ampm: ['A0H5','A0H6','A0H7','A0H8','A0H9'],
        //         englefield: ['1', '2'],
        //         gate: ['3', '4', '5']
        //       }
        //     }
        //   )
        // }),
        map(res => res.body),
        map((res: StoreIdsResponse) => {
          SameStoreSalesService.openStoreIds = new Map(); // need to clear first
          for (let r in res) {
            SameStoreSalesService.openStoreIds.set(r, new Set(res[r]));
          }
          return SameStoreSalesService.openStoreIds;
        })
      )
    }
  }

}
import { Serializable } from '@siq-js/core-lib';

export interface EnvironmentInfoJson {
  id: string;
  name?: string;
  feVersion: string;
  beVersion: string;
  type: string;
  ready: boolean;
  readyTooltip: string;
}

export class EnvironmentInfo implements Serializable<EnvironmentInfoJson> {
  id: string;
  name?: string;
  feVersion: string;
  beVersion: string;
  type: string;
  ready: boolean;
  readyTooltip: string;

  constructor(json: EnvironmentInfoJson) {
    this.id = json.id;
    this.name = json.name || json.id;
    this.feVersion = json.feVersion;
    this.beVersion = json.beVersion;
    this.type = json.type;
    this.ready = json.ready;
    this.readyTooltip = json.readyTooltip;
  }

  toJson(): EnvironmentInfoJson {
    return {
      id: this.id,
      name: this.name,
      feVersion: this.feVersion,
      beVersion: this.beVersion,
      type: this.type,
      ready: this.ready,
      readyTooltip: this.readyTooltip
    };
  }
}

import { Component } from '@angular/core';
import { AgRendererComponent, ColDef } from '@siq-js/visual-lib';
import { ActivityService } from 'app/activity/services/activity.service';
import { Activity } from 'app/activity/models/activity.model';

@Component({
  selector: 'siq-name-renderer',
  templateUrl: './name-renderer.component.html',
  styleUrls: ['./name-renderer.component.scss']
})
export class NameRendererComponent implements AgRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: 'Name',
    cellRenderer: NameRendererComponent,
    width: 200,
    resizable: false,
    sortable: true,
    valueGetter: params => {
      return (params.data as Activity).getName();
    },
    pinned: 'left'
  };

  public name: string;
  public readonly: boolean;

  private activity: Activity;

  constructor(
    private activityService: ActivityService
  ) { }

  agInit(params: any) {
    this.name = params.value;
    this.activity = params.data as Activity;
    this.setReadonly();
  }

  refresh(): boolean {
    return true;
  }

  public update() {
    this.readonly = true;

    this.activityService.updateMetadata(this.activity, {
      name: this.name
    }, true).subscribe(() => this.setReadonly());
  }

  private setReadonly() {
    this.readonly = !this.activity.isMine();
  }
}

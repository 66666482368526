import { Component, Input
} from '@angular/core';
import { ProgressIndicatorColorType, ProgressIndicatorType } from 'libs/visual-lib/src';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'visual-lib-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent extends BaseSiqComponent {

  @Input() bufferValue: number;
  @Input() color: ProgressIndicatorColorType;
  @Input() isLoading: boolean;
  @Input() mode: ProgressIndicatorType;
  @Input() value: number;

  constructor() {
    super();

    this.bufferValue = this.bufferValue || 0;
    this.color = this.color || ProgressIndicatorColorType.PRIMARY;
    this.isLoading = !!this.isLoading;
    this.mode = this.mode || ProgressIndicatorType.INDETERMINATE;
    this.value = this.value || 0;
  }

}

<div class="pinned-fact-wrapper"
  [attr.data-async-value]="table.asyncTotalsComponent?.config.progressValue !== null && table.asyncTotalsComponent?.config.progressValue !== undefined ? table.asyncTotalsComponent?.config.progressValue : ''"
  style="--async-value: {{ table.asyncTotalsComponent?.config.progressValue }}%;"
  [ngClass]="{'is-async-related': isAsyncTotallingType || dependsOnAsyncMetrics}"
>
  <b [matTooltip]="tooltip" *ngIf="canShowValue">
    {{ value }}
  </b>

  <span class="sigma" *ngIf="(isAsyncTotallingType || dependsOnAsyncMetrics) && (!asyncResultsPopulated || !(!!value)) && !isIncrementalFact">- &Sigma; -</span>
</div>
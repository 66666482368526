import { DatePickerComponent } from 'app/siq-forms/modules/dates/components/date-picker/date-picker.component';
import { DateRangeParameter, DatePickerAction } from '@siq-js/core-lib';
import { BehaviorSubject } from 'rxjs';

export enum DateRangeInterfaceType {
  DEFAULT = 'default',
  POPULATED = 'populated',
  FORCE_VALID = 'force_valid' // used when manually call datepicker -> setDate (eg. promo-list -> clearDates) and want to force the validation
}

export interface DateSelectionConfig {
  comparisonToggleState?: boolean;
  comparisonDates?: DatePickerConfig[];
  primaryDate: DatePickerConfig;
}

/**
 * This DateRangeInterface interface is similar to the existing DateRangeModel interface.
 * The difference is the type is Date not Moment as we are trying to replace Moment with other lib(eg: date-fns).
 * During the date project, we should make use of this interface instead of DateRangeModel.
 * Eventually we will replace DateRangeModel with this interface
 */
export interface DateRangeInterface {
  begin?: Date;
  end?: Date; // When end is null, it means it's a single date
  dynamicBegin?: string; // when it's null, it's a static range. Else dynamic dates
  dynamicEnd?: string;
  type?: DateRangeInterfaceType; // ICE-3315: added the ability to switch retailer default dates when no dates selected
  isDateRangeValid?: boolean;
}

export interface DateRangeInterfaceJson {
  begin?: number; // in second
  end?: number;
  dynamicBegin?: string;
  dynamicEnd?: string;
}

export interface DatePickerConfig {
  rangeMode: boolean;
  allowShortcuts: boolean;
  name?: { show: boolean, canModify?: boolean };
  shortcutPosition?: 'left' | 'right';
  weekEndingDay?: boolean;
  hideTooltip?: boolean;
}

export interface DateShortcutsConfig {
  type: 'primary' | 'comparison';
  model$?: BehaviorSubject<DateRangeInterface>;
  datePickerAction$: BehaviorSubject<DatePickerAction>;
  parent: DatePickerComponent;
  position?: 'left' | 'right';
}

export interface DynamicDateShortcut {
  id: number; // index of the array
  name?: string;
  in: string;
  out: string;
  begin?: Date; // translated date
  end?: Date;
  disabled: boolean;
}

export interface DynamicDateShortcutParam {
  id: number;
  dynamicDateStart: string;
  dynamicDateEnd: string;
}

export interface CommunalShortcuts {
  communalRange: DateRangeParameter;
  shortcuts: DateRangeParameter[];
}

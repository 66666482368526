import * as _ from 'lodash';
import * as Clipboard from 'clipboard';
import { format } from 'sql-formatter';
import { AceConfigInterface, AceDirective } from 'ngx-ace-wrapper';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
  } from '@angular/core';
import { first, takeUntil } from 'rxjs';
import { ResponseType } from '@siq-js/core-lib';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'siq-app-activity-sql',
  templateUrl: './app-activity-sql.component.html',
  styleUrls: ['./app-activity-sql.component.scss']
})
export class AppActivitySqlComponent implements OnInit, AfterViewInit, OnDestroy {

  public id: string;
  public config: AceConfigInterface = {
    highlightActiveLine: true,
    mode: 'sql',
    readOnly : true,
    showGutter: true,
    showPrintMargin: false,
    theme: 'monokai',
    useSoftTabs: true,
    wrapBehavioursEnabled: true
  };
  public jobs: string[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChildren(AceDirective) directiveRef?: QueryList<AceDirective>;

  constructor(
    private route: ActivatedRoute,
    private siqHttpService: SiqHttpService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: ParamMap) => {
      this.id = params['id'];
    });

    this.siqHttpService.get({endpoint: 'app-activity-sql/' + this.id, responseType: ResponseType.TEXT})
      .pipe(
        first(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        this.jobs = _.map(_.drop(_.split(res, /\n*Job \d*:\n\n/)), job => format(_.trim(job)) );
      });
  }

  ngAfterViewInit() {
    this.directiveRef.changes
      .pipe(
        first(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        new Clipboard('.clipboard');
        this.directiveRef.forEach((ref: AceDirective, index: number) => ref.setValue(this.jobs[index]));
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

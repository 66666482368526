import * as _ from 'lodash';
import { catchError, map } from 'rxjs';
import { ContentType } from '@siq-js/core-lib';
import {
  NotificationService,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig
} from '@siq-js/angular-buildable-lib';
import { FavoritesParameters } from 'app/activity/modules/favorites/favorites-detail/favorites-parameters.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { Observable } from 'rxjs';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';

@Injectable()
export class FavoritesService extends SiqHttpService {

  private static readonly ENDPOINT = 'favorites';
  private overrideCodes: ResponseCode[] = [];

  constructor(
    http: HttpClient,
    notificationService: NotificationService,
    private mixpanelService: MixpanelService) {
    super(http, notificationService);
  }

  createFavorite(id: string, params: FavoritesParameters): Observable<HttpResponse<any>> {
    // Remove and Replace the ResponseCode for 204
    _.remove(this.overrideCodes, ['code', 204]);
    this.overrideCodes.push(
      new ResponseCode(
        204,
        '<hr/>Your Favorite <i>' + params.getName() + '</i> has been successfully created.',
        'Favorite Created',
        NotificationType.SUCCESS
      )
    );

    let payload = {
      id: id,
      name: params.getName(),
      description: params.getDescription()
    };

    return this.create({
      endpoint: FavoritesService.ENDPOINT,
      body: payload,
      type: ContentType.JSON
    })
    .pipe(
      map((res: HttpResponse<any>) => {
        this.mixpanelService.track(MixpanelEvent.FAVORITE_CREATED);
        return res;
      }),
      catchError(this.handleHttpError)
    );

  }

  getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    // OVERRIDE of super class function

    // REMOVE AND REPLACE THE 500 ResponseCode in order to get this "res" param for error details
    _.remove(this.overrideCodes, ['code', 500]);
    const errorMessage = this.get500ErrorMessage(responseCodesConfig.resp);
    this.overrideCodes.push(
      new ResponseCode(
        500,
        !_.isEmpty(errorMessage) ? 'Error: ' + errorMessage : '',
        'Error Favoriting Activity'
      )
    );

    return new ResponseCodes(this.overrideCodes);
  }

  removeFavorite(id: string): Observable<HttpResponse<any>> {
    // Remove and Replace the ResponseCode for 204
    _.remove(this.overrideCodes, ['code', 204]);
    this.overrideCodes.push(
      new ResponseCode(
        204,
        '<hr/>Favorite has been successfully deleted.',
        'Favorite Deleted',
        NotificationType.SUCCESS
      )
    );

    return this.remove({endpoint: FavoritesService.ENDPOINT + '/' + id})
      .pipe(
        map((res: HttpResponse<any>) => {
          this.mixpanelService.track(MixpanelEvent.FAVORITE_DELETED);
          return res;
        }),
        catchError(this.handleHttpError)
      );
  }

  updateFavorite(id: string, params: FavoritesParameters): Observable<HttpResponse<any>> {
    // Remove and Replace the ResponseCode for 204
    _.remove(this.overrideCodes, ['code', 204]);
    this.overrideCodes.push(
      new ResponseCode(
        204,
        '<hr/>Favorite <i>' + params.getName() + '</i> has been successfully updated.',
        'Favorite Updated',
        NotificationType.SUCCESS
      )
    );

    let payload = {
      id: id,
      name: params.getName(),
      description: params.getDescription()
    };

    return this.update({
      endpoint: FavoritesService.ENDPOINT,
      body: payload,
      notificationData: params,
      type: ContentType.JSON
    })
      .pipe(
        map((res: HttpResponse<any>) => {
          return res;
        }),
        catchError(this.handleHttpError)
      );
  }

}

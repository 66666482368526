import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  AsyncCellComponent,
  ColDef,
  ColGroupDef,
  ColumnHeaderComponent,
  FilterNames,
  GRID_DEFAULTS,
  GridColMetadata,
  GridColType,
  ResultColumn,
  ResultColumnGroup,
  ValueFormatterParams,
  ValueGetterParams
} from '@siq-js/visual-lib';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { YearOverYearEnum } from 'app/siq-applications/modules/report-builder/models/year-over-year.enum';
import { StatResponse } from 'app/core/models/stat-response.model';
import { Activity } from 'app/activity/models/activity.model';
import { AggregationType } from 'app/core/models/aggregation-type.enum';
import { RBMetricColumn } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { stringToNum } from '@siq-js/core-lib';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { ValidationService } from 'app/siq-applications/services/validation/validation.service';
import { ReportBuilderSheet } from 'app/siq-applications/modules/report-builder/models/results/report-builder-sheet.model';
import { AnalysisType } from 'app/core/models/analysis-type.enum';

export class ReportBuilderResultData {

  public asyncTotalsLoading: BehaviorSubject<boolean>;
  public colDefMeta: Map<string, GridColMetadata>;
  public colGroups: ResultColumnGroup[]; // each activity col id corresponds to a col group
  public dataMap: any;
  public dims: CmsField[];
  public metrics: CmsMetric[];
  public sheet: ReportBuilderSheet;
  public unsubTotals: Subject<void> = new Subject<void>();

  private currValIndex: number;
  private readonly dynamicColIds: any = {
    LAST_YEAR_DELTA: '|lyd',
    LAST_YEAR_PERCENT_DELTA: '|lypd'
  };

  constructor(public reportId: string) {
    this.currValIndex = 0;
    this.colGroups = [];
    this.dims = [];
    this.metrics = [];
    this.dataMap = {};
    this.colDefMeta = new Map<string, GridColMetadata>();
    this.asyncTotalsLoading = new BehaviorSubject<boolean>(false);
  }

  public addColumnGroup(id: string, name?: string): ResultColumnGroup {
    const newColumnGroup = <ResultColumnGroup>{
      id: id,
      name: name || '',
      children: []
    };

    this.colGroups.push(newColumnGroup);
    return newColumnGroup;
  }

  public addColumn(colGroupId: string, ref: CmsMetric | CmsField, meta?: any, job?: any): ResultColumn {
    const colGroup = this.getColgroupById(colGroupId);
    const newColumn = <ResultColumn>{
      ref: ref,
      valKey: '' + (this.currValIndex++),
      meta: meta || null,
      job: job || (meta ? meta.parentJob : null)
    };

    // Ensure that the ResultColumn has a ".meta" attribute.
    // This ".meta.jobId" is used when stitching together multiple paging requests to prevent duplicate columns from being created.
    if (_.isNil(newColumn.meta)) {
      newColumn.meta = {};
    }
    newColumn.meta.jobId = newColumn.job?.response.jobId;

    if (ref instanceof CmsMetric) this.metrics.push(ref);

    colGroup.children.push(newColumn);
    return newColumn;
  }

  public getColgroupById(id: string): ResultColumnGroup {
    return _.find(this.colGroups, ['id', id]);
  }

  public getCurrValIndex(): number {
    return this.currValIndex;
  }

  public toColDef(): ColDef[] {

    const colDefs = [];

    this.colGroups.forEach(colGroup => {
      const ref = colGroup.children[0]?.ref;
      const cg: ColGroupDef = {
        marryChildren: true,
        groupId: colGroup.id,
        headerName: colGroup.name,
        children: [],
        openByDefault: true
      };

      if (ref instanceof CmsField) {
        cg.children.push(this.generateDimColDef(colGroup.id, ref));

      } else if (ref instanceof CmsMetric) {

        if (colGroup.flag) {
          if (colGroup.flag.includes(RBMetricColumn.YEAR_OVER_YEAR)) {
            cg.children = this.generateYOYColDefs(colGroup);
          } else if (colGroup.flag === RBMetricColumn.TIME_AGGREGATE) {
            colGroup.children = colGroup.children.sort((a, b) => UtilsService.isLessThan(a.meta.val, b.meta.val));
            cg.children = colGroup.children.map(col => {
              const colDef = this.generateMetricColDefFromCol(col);

              // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
              colDef.cellRendererParams.colDefMeta.timeAgg = {
                dim: CmsService.get().findEntity<CmsField>(col.job.params.dimensions[col.job.params.dimensions.length - 1]['n']),
                prefix: RBMetricColumn.TIME_AGGREGATE + ':',
                dimVal: col.meta.val.valueOf ? col.meta.val.valueOf().toString() : col.meta.val.toString()
              };
              colDef.headerName = col.meta.type.formatter({ value: col.meta.val });
              return colDef;
            });
          }
        } else {
          colGroup.children.forEach(resultColumn => {
            const colDef = this.generateMetricColDefFromCol(resultColumn);
            cg.children.push(colDef);
          });
        }
      }

      colDefs.push(cg);

    });

    return colDefs;
  }

  public finalize() {
    ReportBuilderService.normalizeDataMap(this.dataMap, this.currValIndex);

    this.colGroups
      .filter(colGroup => isNaN(Number(colGroup.id)))
      .forEach((colGroup, i) => {
        colGroup.children.push({
          ref: this.dims[i],
          valKey: this.dims[i].id
        });
      });
  }

  public generateData(): any[] {
    const dims = this.dims;
    const output = [];
    const visualDimTypes = dims.map(d => GridService.getVisualType(d));
    const visualFactTypes = this.metrics.map(f => GridService.getVisualType(f));

    const buildObject = (obj: any, current: any, depth: number) => {
      if (Array.isArray(current)) {
        // fields are referenced by fact index instead of fact names (improves integrity of keys and prevents key collision)
        current.forEach((val, i) => {
          obj[i] = val;
          obj[i].val = visualFactTypes[i]?.parse(obj[i].val); // Parse the actual value of the nested obj
        });
        output.push(_.cloneDeep(obj));
      } else {
        for (const k in current) {
          obj[dims[depth].id] = visualDimTypes[depth].parse(k);
          buildObject(obj, current[k], depth + 1);
        }
      }
    };

    buildObject({}, this.dataMap, 0);
    return output;
  }

  public toStatResponse(activity: Activity): StatResponse {
    const sr = new StatResponse(this.dims, [[], [], []], this.metrics);

    sr.setParentActivity(activity);

    return sr;
  }

  private generateDimColDef(id: string, ref: CmsField): ColDef {
    const vizType = GridService.getVisualType(ref);

    this.colDefMeta.set(id, {
      gridColType: GridColType.FIELD,
      ref: ref
    });

    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    return {
      colId: ref.id,
      field: ref.id,
      headerName: ref.display,
      valueFormatter: GridService.getVisualType(ref).formatter,
      filterValueGetter: vizType.filterValueGetter,
      enableRowGroup: true,
      enablePivot: true,
      headerComponent: <any>ColumnHeaderComponent,
      filter: (GridService.getVisualType(ref).filterType || FilterNames.TEXT).toString(),
      menuTabs: ['filterMenuTab'],
      cellRendererSelector: GridService.dimensionCellRendererSelector,
      filterParams: {
        clearButton: true
      },
      comparator: (a, b) => UtilsService.isLessThan(a, b),
      pivotComparator: (a, b) => UtilsService.isLessThan(vizType.parse(a), vizType.parse(b)),
      cellClassRules: ReportBuilderService.getCellClassRules(ref),
      cellRendererParams: {
        colDefMeta: {
          colId: ref.id,
          ref: ref.clone(), // This *MUST* use ref.clone() in order to prevent an error where this "ref" replaces all other dimensions in the report (ICE-4626)
          gridColType: GridColType.FIELD
        }
      }
    };
  }

  private generateYOYColDefs(colGroup: ResultColumnGroup): ColDef[] {
    const colDefs: ColDef[] = [];

    const yoyArr = colGroup.flag.split('|').slice(1);
    const thisYearIndex = colGroup.children[0].valKey;
    const lastYearIndex = colGroup.children[1].valKey;
    const ref = colGroup.children[0].ref as CmsMetric;
    const key = GridService.getDataKey(ref);
    const formatterFn = GridService.generateFormatterFn(ref);
    const refCpy = _.cloneDeep(ref);
    refCpy.type = 'PERCENT';
    const formatterFnDelta = GridService.generateFormatterFn(refCpy);
    const jobParamsTY: any = _.cloneDeep(colGroup.children[0].job.params);
    const jobParamsLY: any = _.cloneDeep(colGroup.children[1].job.params);

    // Add TY column
    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    const ty = this.generateMetricColDef(ref, {
      headerName: ref.display + ' TY',
      colId: thisYearIndex,
      field: thisYearIndex,
      cellRendererParams: {
        colDefMeta: {
          params: jobParamsTY,
          yoy: {
            prefix: RBMetricColumn.YEAR_OVER_YEAR + ':'
          }
        }
      },
      filterValueGetter: params => {
        const displayVal = formatterFn({value: {val: _.get(params, ['data', thisYearIndex, key])} });
        return stringToNum(displayVal);
      },
      valueFormatter: params => {
        const customerDataParamFields = this.extendValueFormatterParamsForCustomerData(params);
        return formatterFn(_.extend(params, customerDataParamFields));
      },
      valueGetter: GridService.generateValueGetterFn(ref, thisYearIndex) // ag-grid charts & excel export need valueGetter
    });
    colDefs.push(ty);

    // Add LY column
    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    const ly = this.generateMetricColDef(ref, {
      headerName: ref.display + ' LY',
      colId: lastYearIndex,
      field: lastYearIndex,
      cellRendererParams: {
        colDefMeta: {
          params: jobParamsLY,
          yoy: {
            prefix: RBMetricColumn.YEAR_OVER_YEAR + ':offset:'
          }
        }
      },
      columnGroupShow: 'open',
      filterValueGetter: params => {
        const displayVal = formatterFn({value: {val: _.get(params, ['data', lastYearIndex, key])}});
        return stringToNum(displayVal);
      },
      valueFormatter: params => {
        const customerDataParamFields = this.extendValueFormatterParamsForCustomerData(params);
        return formatterFn(_.extend(params, customerDataParamFields));
      },
      valueGetter: GridService.generateValueGetterFn(ref, lastYearIndex) // ag-grid charts & excel export need valueGetter
    });
    colDefs.push(ly);

    const deltaFactResponse = new CmsMetric({
      display: null,
      id: 'RB_DELTA',
      type: ref.type,
      aggType: ref.aggType,
      active: null
    });

    // Add TY vs LY column
    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    const lydGetter = GridService.generateDeltaGetter(thisYearIndex, lastYearIndex, key);
    const lyd = this.generateMetricColDef(deltaFactResponse, {
      headerName: ref.display + ' TY vs LY',
      colId: thisYearIndex + '|' + lastYearIndex + this.dynamicColIds.LAST_YEAR_DELTA,
      cellRendererParams: {
        colDefMeta: {
          yoy: {
            dynamic: true,
            ref: ref
          }
        }
      },
      columnGroupShow: 'open',
      filterValueGetter: params => {
        const displayVal = formatterFn({value: {val: _.get(lydGetter(params), key)}});
        return stringToNum(displayVal);
      },
      valueGetter: params => _.get(lydGetter(params), key)
    });

    // Add a cellClass (based on the ref metric) so the Excel export is formatted correctly
    (<string[]>lyd.cellClass).push(GridService.ENUM_ID_PREPEND + ref.id);
    GridService.setMetricAsyncSettings(lyd);
    this.setRendererFramework(lyd, ref);

    colDefs.push(lyd);

    // Add percent delta column
    const getter = GridService.generatePercentDeltaGetter(thisYearIndex, lastYearIndex);
    const percentFactResponse = new CmsMetric({
      display: null,
      type: 'PERCENT',
      id: 'RB_PERCENT_DELTA',
      aggType: ref.aggType,
      active: null
    });
    let aggFn = GRID_DEFAULTS.AGG_MAP.NA;
    if (ref.canAgg()) {
      aggFn = ref.aggType === AggregationType.ASYNC ? GRID_DEFAULTS.AGG_MAP.AS : GRID_DEFAULTS.AGG_MAP.PD;
    }
    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    const lypd = this.generateMetricColDef(percentFactResponse, {
      headerName: ref.display + ' % \u0394 TY vs LY',
      colId: thisYearIndex + '|' + lastYearIndex + this.dynamicColIds.LAST_YEAR_PERCENT_DELTA,
      cellRendererParams: {
        colDefMeta: {
          yoy: {
            dynamic: true,
            ref: ref
          }
        }
      },
      aggFunc: aggFn,
      allowedAggFuncs: [aggFn],
      columnGroupShow: 'open',
      valueGetter: getter,
      filterValueGetter: params => {
        const displayVal = formatterFnDelta({value: {val: _.get(getter(params), key)}});
        return stringToNum(displayVal);
      }
    });

    GridService.addCellClassForRefMetric(ref, lypd);

    GridService.setMetricAsyncSettings(lypd);
    this.setRendererFramework(lypd, ref);

    colDefs.push(lypd);

    // Update the dataMap with the data used by this YOY col
    let vgParams: ValueGetterParams = {
      node: null,
      data: {}, // gets set in updateDataMapWithYOY
      colDef: null,
      column: null,
      api: null,
      context: null,
      getValue: (k: string) => null // gets set in updateDataMapWithYOY
    } as ValueGetterParams;

    const yoyDynamicColIds = [
      this.dynamicColIds.LAST_YEAR_DELTA,
      this.dynamicColIds.LAST_YEAR_PERCENT_DELTA
    ];

    // Add for LYD
    ReportBuilderResultData.updateDataMapWithYOY(
      this.dataMap,
      lyd.colId,
      vgParams,
      lydGetter,
      yoyDynamicColIds
    );

    // Add for LYPD
    ReportBuilderResultData.updateDataMapWithYOY(
      this.dataMap,
      lypd.colId,
      vgParams,
      getter,
      yoyDynamicColIds
    );

    if (!yoyArr.includes(YearOverYearEnum.THIS_YEAR)) {
      ty.hide = true;
    }

    if (!yoyArr.includes(YearOverYearEnum.LAST_YEAR)) {
      ly.hide = true;
    }

    if (!yoyArr.includes(YearOverYearEnum.LAST_YEAR_CHANGE)) {
      lyd.hide = true;
    }

    if (!yoyArr.includes(YearOverYearEnum.LAST_YEAR_PERCENT_CHANGE)) {
      lypd.hide = true;
    }

    return colDefs;
  }

  private static updateDataMapWithYOY(
    dataMap: any,
    colId: string,
    vgParams: ValueGetterParams,
    getter: (params: ValueGetterParams) => any,
    dynamicColIds: string[]
  ) {
    if (!Array.isArray(dataMap)) {
      // Add dynamicColIds (lyd and lypd)
      for (let k in dataMap) {
        let canProceed = true;
        for (let i = 0; i < dynamicColIds.length; i++) {
          if (k.includes(dynamicColIds[i])) {
            canProceed = false;
            break;
          }
        }

        if (canProceed) {
          vgParams.data = dataMap[k];
          vgParams.getValue = (key: string) => vgParams.data[key];

          dataMap[k][colId] = getter(vgParams);

          // Recursive call for key (k)
          this.updateDataMapWithYOY(dataMap[k], colId, vgParams, getter, dynamicColIds);
        }
      }
    }
  }

  /**
   *
   * @param params: ValueFormatterParams
   * @private
   * This function takes the ValueFormatterParams object and generates an object to be
   * returned that holds additional data needed to render Customer-centric dims correctly.
   * This helps the valueFormatter fn to return/display the desired value of '0' or 'N/A'
   */
  private extendValueFormatterParamsForCustomerData(params: ValueFormatterParams): {
    queryMode: string,
    queryModeSchema: string,
    retailerHasCustomerData: boolean,
    singleRetailerWithCustomerData: boolean
  } {
    const _queryMode = this.sheet.getForm().schema === EnvConfigService.getConfig().primaryEntity ? AnalysisType.MULTI_RETAILER : AnalysisType.SINGLE_RETAILER;
    let _hasCustomerDataKey = '';
    switch (_queryMode) {
      case AnalysisType.SINGLE_RETAILER:
        _hasCustomerDataKey = UtilsService.queryModeSchema;
        break;
      case AnalysisType.MULTI_RETAILER:
        // Check params.data for 'retailer' dim and if present, use this value (will be retailer name/key)
        if (!_.isNil(params.data?.retailer)) {
          _hasCustomerDataKey = params.data.retailer;
        }
        break;
    }
    let _retailerHasCustomerData = !!ValidationService.customerDataLookup.get(_hasCustomerDataKey);

    return {
      queryMode: _queryMode,
      queryModeSchema: this.sheet.getForm().schema,
      retailerHasCustomerData: _retailerHasCustomerData,
      singleRetailerWithCustomerData: !!ValidationService.customerDataLookup.get(UtilsService.queryModeSchema) && this.sheet.getForm().schema !== EnvConfigService.getConfig().primaryEntity
    };
  }

  private generateMetricColDefFromCol(resultColumn: ResultColumn): ColDef {
    const ref = resultColumn.ref as CmsMetric;
    const formatterFn = GridService.generateFormatterFn(ref);
    const valueGetterFn = GridService.generateValueGetterFn(ref, resultColumn.valKey); // ag-grid charts need valueGetter
    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    return this.generateMetricColDef(ref, {
      colId: resultColumn.valKey,
      field: ref.id,
      headerName: ref.display,
      valueFormatter: params => {
        const customerDataParamFields = this.extendValueFormatterParamsForCustomerData(params);
        return formatterFn(_.extend(params, customerDataParamFields));
      },
      valueGetter: params => valueGetterFn(params),
      cellRendererParams: {
        colDefMeta: {
          filters: resultColumn.job.params.filters
        }
      }
    });
  }

  private generateMetricColDef(metric: CmsMetric, colDef: ColDef): ColDef {

    const formatterFn = GridService.generateFormatterFn(metric);
    const valueGetterFn = GridService.generateValueGetterFn(metric);

    this.colDefMeta.set(colDef.colId, {
      ref: metric,
      gridColType: GridColType.METRIC
    });

    // Use the colDef.cellRendererParams to keep various data accessible to the colDef without having to always pass it to functions.
    const cellRendererParams = _.merge(colDef.cellRendererParams.colDefMeta, {
      colId: colDef.colId,
      ref: metric,
      gridColType: GridColType.METRIC
    });

    GridService.setMetricAsyncSettings(colDef);

    let yoyRefId;
    if (cellRendererParams.yoy?.ref) {
      yoyRefId = GridService.ENUM_ID_PREPEND + cellRendererParams.yoy.ref.id;
    }
    return _.merge({
      colId: colDef.colId,
      headerComponent: <any>ColumnHeaderComponent,
      filter: GridService.getFilterByFactType(metric.type),
      menuTabs: ['filterMenuTab'],
      enableValue: true,
      aggFunc: GridService.getAgg(metric),
      valueFormatter: params => formatterFn(params),
      comparator: (a, b) => {
        if (_.isNil(a)) return -1;
        if (_.isNil(b)) return 1;
        const aa = typeof a === 'object' ? a.val : a;
        const bb = typeof b === 'object' ? b.val : b;
        return UtilsService.isLessThan(aa, bb);
      },
      cellRendererSelector: GridService.factCellRendererSelector,
      filterParams: {
        clearButton: true
      },
      filterValueGetter: params => {
        const displayVal = formatterFn({ value: { val: _.get(params.data, [colDef.colId, 'val']) } });
        return stringToNum(displayVal);
      },
      cellClass: [GridService.ENUM_ID_PREPEND + metric.id, 'numeric', yoyRefId ? yoyRefId : ''], // Used/needed by Excel export
      cellClassRules: ReportBuilderService.getCellClassRules(metric, colDef.colId),
      chartDataType: 'series',
      valueGetter: params => {
        return valueGetterFn(params);
      },
      cellRendererParams: cellRendererParams
    }, colDef);
  }

  private setRendererFramework(factColDef: ColDef, ref: CmsMetric): void {
    if (ref.aggType === AggregationType.ASYNC) {
      factColDef.cellRenderer = AsyncCellComponent;
    }
  }
}

import { ReportBuilderDraft } from 'app/siq-applications/modules/report-builder/models/results/report-builder-draft.model';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { ReportBuilderResultData } from 'app/siq-applications/modules/report-builder/models/results/report-builder-result-data.model';
import { ActivityStatus } from 'app/activity/models/activity.model';

// Abstract class that is extended by ReportBuilderSheet and ReportBuilderDraft, provides a basic scaffolding
// of the behavior that each should support. These are the functions called in ReportBuilderResultComponent
export abstract class AbstractReportBuilderEntity {
  public abstract data: ReportBuilderFormData | ReportBuilderResultData;
  public abstract draft: boolean; // abstract boolean that provides an alternative to type-checking the template
  public abstract status: ActivityStatus;

  protected constructor(public parent: ReportBuilderResultComponent) {
  }

  // Removes itself from the list of sheets, and syncs to the model in the cloud
  public delete() {
    if (this.parent.activeSheet === this) {
      this.parent.setActiveSheet(this.parent.sheets[0], null, true);
    }

    const _sheets = this.parent.sheets.filter(s => s !== this);
    this.parent.sheets$.next(_sheets);
    this.parent.sync();
  }

  // Generates an instance of ReportBuilderFormData used to populate ReportBuilderFormComponent
  public abstract getForm(): ReportBuilderFormData;

  // Returns a (new) usable instance of a RB sheet draft
  public abstract clone(): ReportBuilderDraft;

  // Serialization
  public abstract toJson(): any;

  // Handles client-side sheet name editing behavior
  public abstract editName(event): any;

  // Handles client interactions with BE for persisting a sheet's name
  public abstract updateName(name: string): void;

  // Sets the active sheet in the parent to this sheet
  public abstract setActive(): void;
}

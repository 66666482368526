<div class="sov-container">
  <div class="dimension-section">
    <siq-js-entity-selector
      [entityData]="filteredDimData"
      [allowDuplicates]="true"
      [minCount]="1"
      [maxCount]="1"
      [initModel]="dimInitModel"
      [placeholders]="['Dimension']"
      (formEmitter)="dimensionChanged($event)"
    >
    </siq-js-entity-selector>
  </div>

  <p>in</p>

  <div class="cg-section">
    <siq-js-entity-selector
      [entityData]="filteredCGData"
      [allowDuplicates]="true"
      [minCount]="1"
      [maxCount]="1"
      [initModel]="cgInitModel"
      [placeholders]="['Comparison Group']"
      (formEmitter)="cgChanged($event)"
    >
    </siq-js-entity-selector>
  </div>

  <div class="chip-container">
    <mat-chip
      [ngClass]="(sov.isValid) ? 'valid' : 'invalid'"
    >{{sov.isValid ? 'Valid' : 'Invalid'}}
    </mat-chip>
  </div>

  <button mat-icon-button color="warn" (click)="onRemoveSOV()">
    <mat-icon>delete</mat-icon>
  </button>
</div>

export enum ValidationMessageStatus {
  INVALID = 'INVALID',
  UNSET = 'UNSET',
  VALID = 'VALID',
}

export interface ValidationMessage {
  message: string;
  status: ValidationMessageStatus;
}

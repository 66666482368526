import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CmsField } from '@siq-js/cms-lib';
import { CmsService } from '../../../../../core/services/cms/cms.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import {
  BaseFilterGroupComponent,
} from 'app/filter/components/base-filter-group/base-filter-group.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import {
  EditFilterModalComponent,
  FilterModalData
} from 'app/filter/components/edit-filter-modal/edit-filter-modal.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { FilterChipComponent } from 'app/filter/components/filter-chip/filter-chip.component';
import { BehaviorSubject } from 'rxjs';
import { delay, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-report-builder-global-filter',
  templateUrl: './report-builder-form-filter.component.html',
  styleUrls: ['./report-builder-form-filter.component.scss']
})
export class ReportBuilderFormFilterComponent extends BaseFilterGroupComponent implements OnInit, OnDestroy {
  @Input() schemaController: BehaviorSubject<string> | string;

  @ViewChildren(FilterChipComponent) public filterChips: QueryList<FilterChipComponent>;

  public summary$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Generates a summary of the current contents of the model, null if no filters present
  private static generateSummary(model: FilterSelection[]): string {
    if (!model.length) return null;

    return model.map(fs => {
      const filter = CmsService.get().findEntity<CmsField>(fs.field.id);
      let content = []; // [ filter_values_length, if_null_selected ] ie [5, 'Unknown']

      if (fs.values.length) {
        content.push(fs.values.length);
      }

      if (fs.nulls) {
        content.push('Unknowns');
      }

      return `${fs.getDisplayWithContext()} (${content.join(' + ')})`;
    })
      .join(', ');
  }

  constructor(
    protected asyncStatusService: AsyncStatusService,
    protected utils: UtilsService
  ) {
    super(asyncStatusService, utils);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.model$
      .pipe(
        delay(0), // delay(0) is like doing a setTimeout(), doing this here prevents a change detection error in the template
        takeUntil(this.unsub$)
      )
      .subscribe(model => {
        this.summary$.next(ReportBuilderFormFilterComponent.generateSummary(model));
      });
  }

  ngOnDestroy(): void {
    this.summary$.complete();
    super.ngOnDestroy();
  }

  /**
   * Custom addFilter logic for Report Builder
   * If the filter to be added already exists in the model, then populate the modal & *edit* the selection as usual
   * If it doesn't exist, then open the modal with a blank FilterSelection and *add* it to the model afterwards
   * @param field: passed in thru RB
   */
  addFilter(field: CmsField) {
    const filter = CmsService.get().findEntity<CmsField>(field.id);
    const localModel = this.getModel().find(fs => fs.field.id === field.id); // check if the filter already exists

    if (localModel) {
      // Edit op
      this.editFilter(field);
    } else {
      // Add op: skip selecting the filter & go straight to the EditFilterModalComponent
      this.utils.openModal(EditFilterModalComponent, <FilterModalData>{
        field: filter,
        config: {
          include: true,
          canToggle: true,
          nullsAllowed: true
        },
        schemaController: this.schemaController,
        model: localModel // pass in the current model of the FilterSelection
      }, UtilsService.MODAL_CONFIG_MEDIUM)
        .afterClosed()
        .subscribe((filterSelection: FilterSelection) => {
          if (filterSelection && !filterSelection.isEmpty()) {
            this.setModel(
              model => model.concat(filterSelection)
            );
          }
        });
    }
  }
}

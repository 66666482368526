export const CoreConstants = {
  cmsConfig: {}, // populated by apps/dashboard/src/app/core/services/cms/cms.service
  environment: {
    dashboard: { // populated by apps/dashboard/src/app/app.service.ts
      api: '',
      host: '',
      projectId: ''
    }
  },
  dateFormat: 'YYYY-MM-DD',
  _dateFormat: 'yyyy-MM-dd', // TODO: (Moment replacement) When moment.js is fully removed, delete "dateFormat" and then Refactor/Rename "_dateFormat" to "dateFormat"
  dateTimeFormat: 'yyyy-MM-dd_hh-mm-ss',
  shortDate: 'MM/DD/YY',
  _shortDate: 'MM/dd/yyyy' // TODO: (Moment replacement) When moment.js is fully removed, delete "shortDate" and then Refactor/Rename "shortDate" to "shortDate"
};

import { Component } from '@angular/core';
import { IHeaderAngularComp, IHeaderParams } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-header-cell',
  templateUrl: './header-cell.component.html',
  styleUrls: ['./header-cell.component.scss']
})
export class HeaderCellComponent implements IHeaderAngularComp {

  public header: any;

  constructor() { }

  agInit(header) {
    this.header = header;
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

}

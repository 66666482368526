import { ActivityModule } from 'app/activity/activity.module';
import { AgGridModule, AsyncCellComponent } from '@siq-js/visual-lib';
import { ColumnFilterModalComponent } from 'app/siq-applications/modules/report-builder/components/column-filter-modal/column-filter-modal.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import { ReportBuilderFormComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form/report-builder-form.component';
import { ReportBuilderFormFilterComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form-filter/report-builder-form-filter.component';
import { ReportBuilderListComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-list/report-builder-list.component';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { ReportBuilderRoutingModule } from 'app/siq-applications/modules/report-builder/routes/report-builder-routing.module';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { SiqFormsModule } from 'app/siq-forms/siq-forms.module';
import { GridModule } from 'app/grid/grid.module';
import { YearOverYearModalComponent } from 'app/siq-applications/modules/report-builder/components/year-over-year-modal/year-over-year-modal.component';
import { ReportBuilderDrilldownModalComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-drilldown-modal/report-builder-drilldown-modal.component';
import { ReportBuilderFormColumnGroupComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form-column-group/report-builder-form-column-group.component';
import { ReportBuilderCriteriaRendererComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-criteria-renderer/report-builder-criteria-renderer.component';
import { FilterModule } from 'app/filter/filter.module';
import { ReportBuilderFormColumnFilterComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form-column-filter/report-builder-form-column-filter.component';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';
import { ReportBuilderQueryModeModalComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-query-mode-modal/report-builder-query-mode-modal.component';
import { VisualLibModule } from '@siq-js/visual-lib';
import { SameStoreSalesModule } from 'app/siq-forms/modules/same-store-sales/same-store-sales.module';

@NgModule({
  imports: [
      ActivityModule,
      FormsModule,
      CommonModule,
      SharedModule,
      ReportBuilderRoutingModule,
      CoreModule,
      GridModule,
      DragulaModule.forRoot(),
      SiqFormsModule,
      AgGridModule,
      FilterModule,
      DatesModule,
      VisualLibModule,
      SameStoreSalesModule
  ],
  declarations: [
    ReportBuilderListComponent,
    ReportBuilderFormComponent,
    ReportBuilderFormFilterComponent,
    YearOverYearModalComponent,
    ColumnFilterModalComponent,
    ReportBuilderResultComponent,
    ReportBuilderDrilldownModalComponent,
    ReportBuilderFormColumnGroupComponent,
    ReportBuilderCriteriaRendererComponent,
    ReportBuilderFormColumnFilterComponent,
    ReportBuilderQueryModeModalComponent
  ],
  providers: [
    ReportBuilderService,
    ReportBuilderConfig,
    DragulaService
  ]
})
export class ReportBuilderModule {
  constructor() { }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordService } from 'app/public/services/forgot-password.service';

@Component({
  selector: 'siq-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss']
})
export class PasswordRulesComponent implements OnInit, OnChanges {

  @Input() newPassword = '';
  @Input() confirmNewPassword = '';

  public passwordRules: {
    uppercase?: boolean,
    lowercase?: boolean,
    number?: boolean,
    special?: boolean,
    eightLength?: boolean,
    space?: boolean,
    allowedChars?: boolean,
    passwordsMatch?: boolean
  };

  public errorMessage: string;

  constructor(
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public validatePasswords(): boolean {

    this.passwordRules = {
      lowercase: this.newPassword && new RegExp('[a-z]').test(this.newPassword),
      uppercase: new RegExp('[A-Z]').test(this.newPassword),
      number: new RegExp('[0-9]').test(this.newPassword),
      special: new RegExp('[!@#$&%*-]').test(this.newPassword),
      eightLength: this.newPassword && this.newPassword.length >= 8,
      space: this.newPassword && !(/^\s|\s$/).test(this.newPassword),
      allowedChars: this.newPassword && !(/[^a-zA-Z0-9!@#$&%*-]/).test(this.newPassword),
      passwordsMatch: this.newPassword && this.newPassword === this.confirmNewPassword
    };

    for (let rule in this.passwordRules) {
      if (!this.passwordRules[rule]) {
        return false;
      }
    }

    return true;

  }

  ngOnInit () {
    this.newPassword = this.newPassword || '';
    this.confirmNewPassword = this.confirmNewPassword || '';
  }

  ngOnChanges () {
    this.validatePasswords();
  }

}

import { Activity } from 'app/activity/models/activity.model'
import { Component, Inject, OnDestroy } from '@angular/core'
import { FavoritesParameters } from 'app/activity/modules/favorites/favorites-detail/favorites-parameters.model'
import { FavoritesService } from 'app/activity/modules/favorites/shared/favorites.service'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { HttpResponse } from '@angular/common/http'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs'
import { Subject ,  Observable } from 'rxjs';
import { NotificationService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-favorites-detail',
  templateUrl: './favorites-detail.component.html',
  styleUrls: ['./favorites-detail.component.scss']
})
export class FavoritesDetailComponent implements OnDestroy {

  public description: string;
  public edit: boolean;
  public name: string;
  private activity: Activity;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup;
  public params: FavoritesParameters;
  public submitting: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<FavoritesDetailComponent>,
    private favoritesService: FavoritesService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.activity = data as Activity;
    this.edit = this.activity.isFavorite();
    this.submitting = false;
    this.setupForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isEmptyString() {
    return (this.form.controls['name'].value || '').trim().length === 0;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  remove() {
    this.resolve(this.favoritesService.removeFavorite(this.activity.getId()), false);
  }

  save() {
    this.params.toParam(this.form);
    this.resolve(this.favoritesService.createFavorite(this.activity.getId(), this.params), true);
  }

  update() {
    this.params.toParam(this.form);
    this.resolve(this.favoritesService.updateFavorite(this.activity.getId(), this.params), true);
  }

  private resolve(obs: Observable<HttpResponse<any>>, favorite: boolean): void {
    obs.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => this.dialogRef.close(favorite),
        err => {
          this.notificationService.error('There was a problem with your favorite.', 'Error');
          this.dialogRef.close(null);
          throw new Error(err);
        });
  }

  private setupForm(): void {
    this.params = new FavoritesParameters();
    this.form = this.params.toForm(this.formBuilder);

    this.form.patchValue({
      name: this.activity.getName(),
      description: this.activity.getMetaDataByKey('description') || ''
    });
  }
}

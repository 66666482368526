import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from 'app/auth/services/auth.service';
import { finalize, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { NewUserService } from 'app/core/services/new-user/new-user.service';
import { UserService } from 'app/user/services/user.service';
import * as _ from 'lodash';
import { User } from 'app/user/models/user.model';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = AuthService.CurrentUser$.getValue();

    // Logic for new user setup: User is authenticated but routes are still restricted
    if (user) {
      if (!user.isValid() && state.url !== ('/' + NewUserService.defaultPath)) {
        this.router.navigateByUrl(NewUserService.defaultPath);
        return false;
      }

      return this.checkAccessGroup(user);
    }

    // If there is no currentUser set, try refreshing the session
    if (!user) {
      return this.authService.refreshToken()
        .pipe(
          map(res => !!res),
          finalize(() => {
            user = AuthService.CurrentUser$.getValue();

            if (user && !this.checkAccessGroup(user)) {
              console.info('User exists but has no AG');
              return false;
            }

            if (user) {
              // user is logged in

              if (user.isValid()) {
                // user has Valid account (fully setup)

                if (state.url === '/' || state.url === '/login') {
                  // If logged in, and on a login page, redirect to default page

                  this.userService.getDefaultUrl().subscribe((url: string) => {
                    this.router.navigate([url]);
                  });
                } else {

                  this.router.navigate([state.url]);
                }
              } else {
                // if user is logged in, but his account has NOT been fully setup (initial password NOT changed or security questions NOT filled)

                this.router.navigate(['/new-user']);
              }
            } else {
              // If there is still no user set (ie the CookieAuthenticator returned a 303), reroute back to login

              this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            }
          })
        );
    }
    return true;

  }

  private checkAccessGroup(user: User): boolean {
    if (_.isNil(user) || !this.ensureAccessGroup(user)) {
      console.error('User has no AccessGroup!');
      this.forceLogout();
      return false;
    } else {
      return true;
    }
  }

  private ensureAccessGroup(user: User): boolean {
    return !_.isNil(_.get(user, 'accessGroup'));
  }

  private forceLogout(): void {
    this.authService.logout()
    .subscribe(() => {
      console.info('Forced logout!');
      this.router.navigate(['/login']);
    });
  }
}

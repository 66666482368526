<section
  class="app-container"
  [ngClass]="{
    'expanded': isExpanded,
    'app-container-unboxed': !shouldBeBoxed,
    'app-container-boxed': shouldBeBoxed,
    'limited-width-content': !shouldBeBoxed
  }"
>

  <div>

    <div class="expand-all" *ngIf="!hideExpandAll && !shouldBeBoxed">
      <siq-expand-all></siq-expand-all>
    </div>

    <div *ngIf="isCloning">
      <h6>
        <span>Cloning <i>{{ activity.value.metaData.name || 'Untitled Activity' }}</i></span>
      </h6>
    </div>

    <mat-expansion-panel
      (closed)="closed()"
      (opened)="opened()"
      [expanded]="isExpanded"
      [disabled]="!shouldBeBoxed"
      class="activity-form-wrapper-panel"
      [ngClass]="{ 'panel-body-centered': shouldBeBoxed }"
    >

      <mat-expansion-panel-header
        *ngIf="!hideHeader && isResults"
        class="activity-form-wrapper-panel-header"
      >
        <mat-panel-title class="view-edit-title">
          <div>Criteria</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div [ngClass]="{'limited-width-content' : shouldBeBoxed }">
        <div class="expand-all" *ngIf="!hideExpandAll && shouldBeBoxed">
          <siq-expand-all></siq-expand-all>
        </div>

        <ng-content></ng-content>
      </div>

    </mat-expansion-panel>

  </div>

</section>

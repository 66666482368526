import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AggregationType, BarColumnConfig, BarColumnParams, ColDef, GridColMetadata, GridComponent, GridService, SimpleLineConfig, SimpleLineParams, ValueGetterParams } from '@siq-js/visual-lib';
import { DrawerService, DRAWER_DATA } from 'app/core/modules/drawer/services/drawer.service';
import { PromoResultGridParams, PromoResultGridProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/promo-result-grid-processor';
import { PromoResult, SOVDrilldown, SOVHelperParams, SalesByDayTSDataSet } from 'app/siq-applications/modules/promo/models/interfaces';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import {
  ChartTypes,
  KEY_CODE,
  KPI,
  PromoDimensionKeys,
  PromoJobEnums,
  PromoPeriods,
  UnitTypes
} from 'app/siq-applications/modules/promo/models/promo.enums';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { StatResponse } from 'app/core/models/stat-response.model';
import { SalesByDaySimpleLineProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/sales-by-day-simple-line-processor';
import { SovColumnChartProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/sov-column-chart.processor';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';

@Component({
  selector: 'siq-js-sov-helper',
  templateUrl: './sov-helper.component.html',
  styleUrls: ['./sov-helper.component.scss']
})
export class SovHelperComponent extends BaseSiqComponent implements OnInit, PromoResult {
  // Manages drilldowns
  public readonly drilldowns: SOVDrilldown[]; // Readonly to prevent re-assigning of ths pointer
  public drilldownKey: string;

  // Dropdown options
  public distDims: CmsField[]; // Only used by distribution visualizations
  public chartTypes = ChartTypes;
  public unitTypes = UnitTypes;
  public facts: CmsMetric[];

  // Dropdown selections
  public distDim: CmsField;
  public dataSetKeyDim: string;
  public chartType = ChartTypes.TIMESERIES;
  public unitType = UnitTypes.ABSOLUTE;
  public factKey: CmsMetric;

  // grid
  public grid: GridComponent;
  public gridParams: PromoResultGridParams;
  public gridProcessor = PromoResultGridProcessor.processor;
  public readyForExport$: BehaviorSubject<boolean>; // if grid is ready to be exported

  // chart
  public simpleLineData$: BehaviorSubject<SalesByDayTSDataSet>;
  public simpleLineParams: SimpleLineParams;
  public simpleLineConfig: SimpleLineConfig;
  public simpleLineProcessor = SalesByDaySimpleLineProcessor.processor;
  public columnParams: BarColumnParams;
  public columnConfig: BarColumnConfig;
  public columnProcessor = SovColumnChartProcessor.processor;
  public columnData$ = new BehaviorSubject<any>(null);

  public currDrilldown: SOVDrilldown;
  public preCustomName: string;
  public render$ = new Subject<void>();
  public renderedOnce = false;
  public yoyCustomName: string;
  public periodKey: PromoPeriods = PromoConfig.compPeriods[0];

  private distJob: AppResponseDataset;
  private distPSPWJob: AppResponseDataset;
  private _distFacts: CmsMetric[];
  private tsJob: AppResponseDataset;
  private _tsFacts: CmsMetric[];

  constructor(
    @Inject(DRAWER_DATA) public params: SOVHelperParams,
    public config: PromoConfig,
    private mixpanelService: MixpanelService,
    private promoService: PromoService,
    private gridService: GridService
  ) {
    super();
    params.helperComponent = this; // Inject a pointer of this component for easy reference in the sheet

    this.drilldowns = this.params.drilldowns;

    this.params.drilldownTrigger$
      .pipe(
        filter(label => !!label),
        takeUntil(this.unsub$)
      )
      .subscribe(label => {
        this.updateDrilldown(label);
      });

    this.simpleLineData$ = new BehaviorSubject(null);
  }

  // This only runs once, when the first drilldown is selected. Populates the dropdown options from the activity
  createDropdownOptions() {
    // Find the correct job to populate dropdowns. There can be multiple matching ones - doesn't matter which one we use
    this.distJob =
    this.currDrilldown.activity.getJobs()
      .find(j => j.getName() === PromoJobEnums.SOV_DISTRIBUTION);

    this.distPSPWJob =
    this.currDrilldown.activity.getJobs()
      .find(j => j.getName() === PromoJobEnums.SOV_DISTRIBUTION_PSPW);

    this.tsJob =
      this.currDrilldown.activity.getJobs()
      .find(j => j.getName() === PromoJobEnums.SOV_TIME_SERIES);

    this.distDims = (this.distJob.getResponse()).getDimensions().filter(dim => dim.id !== PromoDimensionKeys.PERIOD);
    this._distFacts = (this.distJob.getResponse()).getFacts();
    this._distFacts.push(new CmsMetric({
      id: KPI.AVG_PRICE,
      display: 'Average Price',
      active: true,
      type: 'DOLLAR',
      aggType: AggregationType.ASYNC
    }));
    this._tsFacts = (this.tsJob.getResponse()).getFacts();

    this.setupFacts();
    // Set initial model
    this.distDim = this.distDims[0];
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
      'Usage': 'Source of Volume'
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === KEY_CODE.ESC) {
      DrawerService.peek();
    }
  }

  ngOnInit(): void {
    this.preCustomName = this.params.parent.parent.formData[PromoConfig.periodNames[PromoPeriods.PRE]].customPeriodName;
    this.yoyCustomName = this.params.parent.parent.formData[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName;
    this.render$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsub$)
      )
      .subscribe(() => this.render());

    DrawerService.drawerResized$
      .pipe(
        takeUntil(this.unsub$)
      )
      .subscribe(() => {
        if (!this.renderedOnce) {
          this.updateDrilldown(this.params.drilldownTrigger$.getValue());
          this.renderedOnce = true;
        }
      });

    this.setupTable();
    this.setupChart();
    this.ready();
  }

  render() {
    // If the chart type has changed, tear everything down and re-render everything
    if (this.chartType === ChartTypes.TIMESERIES) {
      this.renderTimeSeries();
    } else if (this.chartType === ChartTypes.DISTRIBUTION) {
      this.renderDistribution();
    }
  }

  setupChart() {
    // Sales by day line chart
    this.simpleLineParams = {
      parent: this.params.parent,
      component: this,
      rawDataSimpleLine$: this.simpleLineData$
    };
    this.simpleLineConfig = SalesByDaySimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParams, this.params.parent.parent.formData);

    // Top growers & decliners column chart
    this.columnParams = {
      agChartOptions: null,
      parent: this.params.parent,
      parentActivity: null,
      rawData$: this.columnData$,
      highlightDatum$: new BehaviorSubject(null),
      component: this
    };
    this.columnConfig = SovColumnChartProcessor.generateBarColumnConfig(this.columnParams);
  }

  setupFacts() {
    let baseFacts: CmsMetric[];
    if (this.chartType === ChartTypes.TIMESERIES) {
      baseFacts = this._tsFacts;
    } else if (this.chartType === ChartTypes.DISTRIBUTION) {
      baseFacts = this._distFacts;
    }

    baseFacts = PromoService.filterMetricsForTakeRateDropdown(baseFacts);
    this.facts = baseFacts;
    PromoService.detectDynamicFacts(this, baseFacts);

  }

  setupTable() {
    this.gridParams = {
      parent: this,
      data: [],
      readyForExport$: this.readyForExport$
    };
    this.gridParams.gridVisualOptions = PromoResultGridProcessor.generateGridVisualOptions(this.gridParams);
  }

  switchChart(chartType: ChartTypes) {
    if (this.chartType === chartType) return;

    this.chartType = chartType;

    if (chartType === ChartTypes.TIMESERIES) {
      this.unitType = UnitTypes.ABSOLUTE;
    }

    this.updateUnitType();
  }

  togglePeriod(checked: boolean) {
    // Cast checked to a number (0 or 1) and set the period key
    this.periodKey = PromoConfig.compPeriods[Number(checked)];
    this.updateView();
  }

  updateChart(sr: StatResponse) {
    const rawData = GridService.jobToArray(sr).filter(data => data[this.factKey.id]?.val);
    if (this.chartType === ChartTypes.DISTRIBUTION) {
      this.columnData$.next(rawData);
    }
    if (this.chartType === ChartTypes.TIMESERIES) {
      const timeSeriesDataSet = SalesByDaySimpleLineProcessor.divideData(rawData, this.factKey.id, this.params.parent.parent.formData);
      this.simpleLineData$.next(timeSeriesDataSet);

    }
  }

  updateDrilldown(drilldownKey: string) {
    this.drilldownKey = drilldownKey;
    this.currDrilldown = this.drilldowns.find(dr => dr.label === this.drilldownKey);
    if (!this.tsJob || !this.distJob) {
      this.createDropdownOptions();
    }

    this.updateView();
  }

  updateUnitType() {
    this.setupFacts();
    this.updateView();
  }

  updateView() {
    this.render$.next();
  }

  private generateDistributionColDefs(colDefMeta: Map<string, GridColMetadata>): ColDef[] {
    const defs: ColDef[] = [];

    {
      // Dim ColDef
      const colDef = this.gridService.generateDimensionColumn(this.distDim, colDefMeta);
      defs.push(colDef);
    }

    {
      // Comp period fact ColDef
      const key = PromoService.generateValKey([this.periodKey, this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, key);
      colDef.field = colDef.colId = key;
      colDef.headerName = `${this.params.parent.parent.formData[PromoConfig.periodNames[this.periodKey]].customPeriodName} ${this.factKey.display}`;
      defs.push(colDef);
    }

    {
      // Promo period fact ColDef
      const key = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, key);
      colDef.field = colDef.colId = key;
      colDef.headerName = `Promo ${this.factKey.display}`;
      defs.push(colDef);
    }

    {
      // Delta col def
      const fact = new CmsMetric({
        display: 'Change',
        type: this.factKey.type,
        aggType: AggregationType.SUM,
        id: 'PROMO_DELTA',
        active: true,
      });
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta);
      const compKey = PromoService.generateValKey([this.periodKey, this.factKey.id]);
      const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
      // Add a cellClass (based on the ref metric) so the Excel export is formatted correctly
      (<string[]>colDef.cellClass).push(GridService.ENUM_ID_PREPEND + this.factKey.id);
      this.promoService.setRendererFramework(colDef, fact);

      colDef.colId = fact.id;
      colDef.valueGetter = GridService.generateDeltaGetter(promoKey, compKey);
      colDef.headerName = 'Change';
      colDef.cellClassRules = PromoService.getCellClassRules(fact);
      colDef.sort = 'desc';
      defs.push(colDef);
    }

    {
      // Percent Delta col def
      const fact = new CmsMetric({
        display: '% Change',
        type: 'PERCENT',
        aggType: AggregationType.SUM,
        id: 'PROMO_PERCENT_DELTA',
        active: true,
      });
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta);
      const compKey = PromoService.generateValKey([this.periodKey, this.factKey.id]);
      const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
      GridService.addCellClassForRefMetric(fact, colDef);
      this.promoService.setRendererFramework(colDef, fact);

      const percentDeltaGetter = GridService.generatePercentDeltaGetter(promoKey, compKey);

      colDef.colId = fact.id;
      colDef.valueGetter = percentDeltaGetter;
      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
      colDef.headerName = '% Change';
      colDef.cellClassRules = PromoService.getCellClassRules(fact);
      defs.push(colDef);
    }

    return defs;
  }

  private generateDistributionColumnChartStatResponse(data: any[]): StatResponse {
    const dimKey = this.distDim.id;

    const dims = [ // temp code from V1
      new CmsField({
        id: dimKey,
        display: null,
        type: 'STRING',
        retailer: null,
        table: null,
        field: null,
        active: true,
        filter: null,
      })
    ];

    const fact = _.cloneDeep(this.factKey);
    fact.display = fact.display + ' Change';
    const facts = [fact];

    const allDimVals = [];
    let vM = [];

    // calculate all delta values
    const deltaGetter = GridService.generateDeltaGetter(
      PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]),
      PromoService.generateValKey([this.periodKey, this.factKey.id])
    );
    data.forEach((dp, i) => {
      allDimVals.push(dp[dimKey]); // Add the dimension value to the global list
      vM.push([i, deltaGetter(dp).val]);
    });

    vM.sort((a, b) => b[1] - a[1]); // Sort the data by delta value

    if (vM.length > 20) { // If the data set is larger than 20 elements, we only want the TOP 10 & BOTTOM 10
      vM = vM.slice(0, 10).concat(vM.slice(-10));
    }

    const dM = [[]]; // The complete list of dim vals can have 1000s of values, and we only need the 20 in vM
    vM.forEach((row, i) => {
      dM[0].push(allDimVals[row[0]]);
      row[0] = i;
    });

    return new StatResponse(dims, dM, facts, vM);
  }

  private generateTimeSeriesChartStatResponse(): StatResponse {
    const totalKey = PromoService.generateValKey(['TOTAL', this.factKey.id]);

    const dims = [
      new CmsField({
        id: PromoDimensionKeys.TIMESERIES_DAY,
        display: 'Year-Month-Day',
        type: 'DATE',
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      }),
      new CmsField({
        display: 'Period',
        type: 'STRING',
        id: PromoDimensionKeys.PERIOD,
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      })
    ];
    const facts = [
      this.factKey
    ];

    const dM = [[], []]; // Init 2 empty dim matrices (period & YEARMONDAY)
    const vM = [];

    const _cache: any = {}; // cache for optimized index retrieval for period

    this.currDrilldown.data.timeseries.forEach((dp, i) => {

      // Memoize the index where the period dimval is (or insert it if it doesn't exist)
      const period = dp[PromoDimensionKeys.PERIOD];
      if (_.isNil(_cache[period])) {
        dM[1].push(period);
        _cache[period] = dM[1].length - 1;
      }

      if (typeof (dp[PromoDimensionKeys.TIMESERIES_DAY]) !== 'number') {
        console.error('fail to construct js date');
      }
      let date = new Date((dp[PromoDimensionKeys.TIMESERIES_DAY]));
      if (period === this.yoyCustomName) {
        date = DatesService.add(date, {years: 1});
      }

      // Convert moment to unix timestamp and add to dimensionMatrix
      dM[0].push(date.getTime());

      vM.push([
        i, // date dim value ref
        _cache[period], // period dim value ref
        dp[totalKey].val // calculated total value
      ]);
    });

    return new StatResponse(dims, dM, facts, vM);
  }

  private generateTimeSeriesColDefs(colDefMeta: Map<string, GridColMetadata>): ColDef[] {
    const defs: ColDef[] = [];

    // Period group
    {
      const dim = new CmsField({
        display: 'Period',
        type: 'STRING',
        id: PromoDimensionKeys.PERIOD,
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      });
      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      colDef.hide = true;
      colDef.rowGroupIndex = 0;
      defs.push(colDef);
    }

    // WEEK dim
    {
      const dim = new CmsField({
        id: PromoDimensionKeys.TIMESERIES_WEEK,
        display: null,
        type: 'DATE',
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      });
      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      colDef.rowGroupIndex = 1;
      colDef.hide = true;
      defs.push(colDef);
    }

    // YEARMONDAY dim
    {
      const dim = new CmsField({
        id: PromoDimensionKeys.TIMESERIES_DAY,
        display: 'Year-Month-Day',
        type: 'DATE',
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      });

      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      defs.push(colDef);
    }

    // Generate X number of columns (mocking a pivot table - similar to time aggregates in RB)
    const sr = this.tsJob.getResponse();
    const basketSizeIndex = sr.getDimensions().findIndex(d => d.field === PromoDimensionKeys.BASKET_SIZE);
    sr.getDimensionValues()[basketSizeIndex].forEach(basketSize => {
      const key = PromoService.generateValKey([basketSize, this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, key);
      colDef.field = colDef.colId = key;

      colDef.headerName = `${basketSize} / Basket`;
      defs.push(colDef);
    });

    // Total column
    {
      const key = PromoService.generateValKey(['TOTAL', this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, key);
      colDef.valueGetter = GridService.generateValueGetterFn(this.factKey, key);
      colDef.field = colDef.colId = key;
      colDef.headerName = 'Total';
      colDef.pinned = 'right';
      defs.push(colDef);
    }

    return defs;
  }

  private renderDistribution() {
    // grid
    const apiRef = this.grid.getApi();
    let data = this.currDrilldown.data.distribution[this.distDim.id];

    if (this.unitType === UnitTypes.PSPW) {
      if (!this.currDrilldown.data.distributionPSPW) {
        this.currDrilldown.data.distributionPSPW = this.promoService.generateDistributionData(this.distPSPWJob, this.params.parent.parent.activity);
        data = this.currDrilldown.data.distributionPSPW[this.distDim.id];
      }
      data = this.promoService.applyPerStorePerWeek(data);
    }
    apiRef.grid.suppressRowTransform = false;
    const colDefs = this.generateDistributionColDefs(apiRef.colDefMeta);
    apiRef.grid.api.updateGridOptions({ columnDefs: [] });
    apiRef.grid.api.updateGridOptions({ columnDefs: colDefs });
    apiRef.grid.api.updateGridOptions({ rowData: data });
    this.grid.aggregate();
    PromoResultGridProcessor.resizeGrid(apiRef.grid.api);

    // chart
    this.dataSetKeyDim = this.distDim.id;
    this.updateChart(this.generateDistributionColumnChartStatResponse(data));
  }

  private renderTimeSeries() {
    // grid
    const apiRef = this.grid.getApi();
    const colDefs = this.generateTimeSeriesColDefs(apiRef.colDefMeta);
    apiRef.grid.api.updateGridOptions({ columnDefs: colDefs });
    apiRef.grid.api.updateGridOptions({ rowData: this.currDrilldown.data.timeseries });
    this.grid.aggregate();
    PromoResultGridProcessor.resizeGrid(apiRef.grid.api);

    // chart
    this.updateChart(this.generateTimeSeriesChartStatResponse());
  }

}

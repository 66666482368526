import * as _ from 'lodash';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs';
import { AppComponent } from 'app/app.component';
import { AgGridAngular, AgGridConfig, GridOptions, GridService } from '@siq-js/visual-lib';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent extends BaseSiqComponent implements OnInit, OnDestroy {

  @Input() autoHeight: boolean;
  @Input() gridOptions: GridOptions;
  @Input() tableType: 'simple' | 'scrollable';
  @Input() subtitle: string;
  @Input() title: string;
  @Input() noCard: boolean;
  @Input() customHeight: string;
  @Input() autoSizeColumns: boolean;
  @Input() onResize: Function;

  @ViewChild('dataTableGridCard') gridCard!: AgGridAngular;
  @ViewChild('dataTableGridNoCard') gridNoCard!: AgGridAngular;

  public agTheme: string;
  private defaultOptions: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private el: ElementRef) {
    super();

    // Move this to a service
    this.defaultOptions = <any>{
      simple: <GridOptions>{
        overlayLoadingTemplate: AgGridConfig.overlayLoadingTemplate,
        domLayout: 'autoHeight',
        rowHeight: 60,
        defaultColDef: {
          resizable: true,
          sortable: true,
          unSortIcon: true
        }
      },
      scrollable: <GridOptions>{
        overlayLoadingTemplate: AgGridConfig.overlayLoadingTemplate,
        rowHeight: 60,
        defaultColDef: {
          resizable: true,
          sortable: true,
          unSortIcon: true
        }
      }
    };

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {

    AppComponent.resize$
      .pipe(
        debounceTime(100),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        if (this.el.nativeElement.offsetParent) {
          this.resizeCB();
        }
      });

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });

    // Change this.defaultOptions[n] to a service call;
    this.gridOptions = _.assign(this.defaultOptions[this.tableType], this.gridOptions);
    this.ready();

  }

  private resizeCB(): void {

    if (this.onResize) {
      return this.onResize();
    } else {

      /*
     * The autoSizeColumns() function cannot auto-size cols that are not on-screen.
     * First put all cols on-screen with sizeColumnsToFit(), then autoSizeColumns()
     */
      const gridApi = this.noCard ? this.gridNoCard.api : this.gridCard.api;
      if (gridApi) {
        gridApi.setGridOption('alwaysShowVerticalScroll', true);
        gridApi.refreshClientSideRowModel();
        gridApi.sizeColumnsToFit();
        if (this.autoSizeColumns) {
          gridApi.autoSizeAllColumns();
        }
      }
    }
  }
}

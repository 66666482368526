import { AbstractControl } from '@angular/forms';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
  } from '@angular/core';
import { merge, takeUntil, Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'siq-validation-description',
  templateUrl: './validation-description.html',
  styleUrls: ['./validation-description.scss']
})
export class ValidationDescriptionComponent implements OnChanges, OnDestroy {

  public statusClass = 'pristine';
  @Input() control: AbstractControl;
  private unsub = new Subject<void>();

  ngOnChanges (changes: SimpleChanges) {

    if (changes.control) {

      this.unsub.next();

      merge(
        this.control.valueChanges,
        this.control.statusChanges
      )
        .pipe(takeUntil(this.unsub))
        .subscribe(() => {
          this.statusClass = this.control.valid ? 'valid' : ( this.control.pristine ? 'pristine' : 'invalid' );
        })

    }
  }

  ngOnDestroy () {
    this.unsub.next();
  }

}

import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DatePartsFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/date-parts-filter/date-parts-filter.component';
import { DatePart } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-part.enum';
import { DaypartRange } from 'libs/visual-lib/src/lib/models/daypart-range.enum';

@Component({
  selector: 'siq-daypart-filter',
  templateUrl: './daypart-filter.component.html',
  styleUrls: ['./daypart-filter.component.scss']
})
export class DaypartFilterComponent extends DatePartsFilterComponent {
  public dayparts: string[] = [
    DaypartRange.BREAKFAST,
    DaypartRange.LUNCH,
    DaypartRange.RUSH_HOUR,
    DaypartRange.EVENING,
    DaypartRange.OVERNIGHT
  ];

  @ViewChild('daypart', { read: ViewContainerRef, static: true }) private _input;

  constructor() {
    super();
    this.setDatePart(DatePart.DAYPART);
  }

  // Focuses on the month element upon initialization of the filter controller
  // AfterViewInit interface implemented by inherited class
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => {
      this._input && this._input.element.nativeElement.focus();
    });
  }

}

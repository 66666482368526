import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild
  } from '@angular/core';
import { DynamicComponentHostDirective } from 'app/core/components/dynamic-component/dynamic-component-host.directive';

@Component({
  selector: 'siq-dynamic-component',
  templateUrl: './dynamic-component.component.html'
})
export class DynamicComponentComponent implements OnInit {

  @Input() private component: any;
  @Input() private data: any;

  @ViewChild(DynamicComponentHostDirective, { static: true }) host: DynamicComponentHostDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {

    const component = this.componentFactoryResolver.resolveComponentFactory(this.component);
    const vcr = this.host.viewContainerRef;
    vcr.clear();
    const componentRef = vcr.createComponent(component);
    (<any>componentRef.instance).data = this.data;

    // Run the dynamicInit function (if present)
    if ((<any>componentRef.instance).dynamicInit) {
      (<any>componentRef.instance).dynamicInit();
    }
  }

}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'libs/visual-lib/src';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-js-pinned-dimension-cell',
  templateUrl: './pinned-dimension-cell.component.html',
  styleUrls: ['./pinned-dimension-cell.component.scss']
})
export class PinnedDimensionCellComponent extends BaseSiqComponent implements AgRendererComponent {

  public isLabel: boolean;

  constructor() {
    super();
  }

  agInit(data: ICellRendererParams) {
    this.isLabel = data.eGridCell.classList.contains('ag-cell-last-left-pinned');
  }

  refresh(): boolean {
    return true;
  }

}

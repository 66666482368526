import { Routes } from '@angular/router';
import { AppGuard } from 'app/guards/app-guard';
import { AuthGuard } from 'app/guards/auth-guard';
import { ApplicationHash } from '@siq-js/core-lib';
import { AffinitiesListComponent } from 'app/siq-applications/modules/affinities/components/affinities-list/affinities-list.component';
import { AffinitiesFormComponent } from 'app/siq-applications/modules/affinities/components/affinities-form/affinities-form.component';
import { AffinitiesResultComponent } from 'app/siq-applications/modules/affinities/components/affinities-result/affinities-result.component';
import { AffinitiesCloneResolverService } from 'app/siq-applications/modules/affinities/services/affinities-clone-resolver.service';

export const affinitiesRoutes: Routes = [
  {
    path: 'app/affinities',
    canActivate: [
      AppGuard,
      AuthGuard
    ],
    data: {
      applicationHash: ApplicationHash.AFFINITIES
    },
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: AffinitiesListComponent
          }
        ]
      },
      {
        path: '~',
        children: [
          {
            path: '',
            component: AffinitiesFormComponent
          }
        ]
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: AffinitiesResultComponent
          },
          {
            path: 'clone',
            resolve: {nothing: AffinitiesCloneResolverService},
            children: []
          },
          {
            path: 'export',
            component: AffinitiesResultComponent
          }
        ]
      }
    ]
  }
];

<div [ngClass]="{'async-group': !!group || isPinned}"
    [attr.data-async-value]="table.asyncTotalsComponent?.config.progressValue !== null && table.asyncTotalsComponent?.config.progressValue !== undefined ? table.asyncTotalsComponent?.config.progressValue : ''"
    style="--async-value: {{ table.asyncTotalsComponent?.config.progressValue }}%;"
    class="is-async-related"
  >

  <span>
    <span *ngIf="group; else leaf">
      <span *ngIf="!!asyncResultsPopulated; else defaultView">
        {{ value }}
      </span>

      <ng-template #defaultView>
        <span class="sigma">- &Sigma; -</span>
      </ng-template>

    </span>
    <ng-template #leaf>
      <span [ngClass]="valueClass">
        {{ value || (isPinned ? '- &Sigma; -' : '0') }}
      </span>
    </ng-template>
  </span>
</div>

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CmsField } from '@siq-js/cms-lib';
import { BulkUploadModalComponent } from 'app/filter/components/bulk-upload-modal/bulk-upload-modal.component';
import { FilterValueRendererComponent } from 'app/filter/components/edit-filter-modal/cell-renderers/filter-value-renderer/filter-value-renderer.component';
import { SearchParams, BulkSearchParams, BulkSearchResponse } from 'app/filter/models/async-search';
import { Filter } from 'app/filter/models/filter';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { FilterValue } from 'app/filter/models/filter-value';
import { FilterService } from 'app/filter/services/filter.service';
import { CheckboxRendererComponent } from 'app/core/components/cell-renderers/checkbox-renderer/checkbox-renderer.component';
import { UsesCheckboxRenderer } from 'app/core/components/cell-renderers/checkbox-renderer/uses-checkbox-renderer';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AgGridConfig, GridOptions, GridReadyEvent, GridApi } from '@siq-js/visual-lib';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SchemaController } from 'app/filter/components/base-filter-group/base-filter-group.component';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { SameStoreSalesSelectionComponent } from 'app/siq-forms/modules/same-store-sales/components/same-store-sales-selection/same-store-sales-selection.component';
import { SameStoreSalesService } from 'app/siq-forms/modules/same-store-sales/services/same-store-sales.service';
import { Router } from '@angular/router';

export interface FilterModalConfig {
  include?: boolean;
  noNulls?: boolean;
  noToggle?: boolean;
  isColumnFilter?: boolean;
}

export interface FilterModalData {
  config: FilterModalConfig;
  field: CmsField;
  model?: FilterSelection;
  schemaController?: SchemaController;
}

enum Mode {
  SEARCH,
  BULKUPLOAD
}

enum SearchType {
  SYNC,
  ASYNC
}

@Component({
  templateUrl: './edit-filter-modal.component.html',
  styleUrls: ['./edit-filter-modal.component.scss']
})
export class EditFilterModalComponent extends BaseSiqComponent implements UsesCheckboxRenderer<FilterValue>, OnInit {
  @ViewChild('bulkUploadModalComponent', { static: true }) bulkUploadModalComponent: BulkUploadModalComponent;
  @ViewChild('sameStoreSalesComponent', { static: false }) sameStoreSalesComponent: SameStoreSalesSelectionComponent;

  private static MIN_CHAR_THRESHOLD = 3; // char length threshold to perform an async search
  private static SEARCH_DEBOUNCE_TIME = 500; // debounce time in ms
  private static AGG_CUSTOMER_ID = 'customer_id'; // "Aggregated Customer ID" dimension id

  public bulkStrings: string[];
  public bulkUploadCheckbox = false;

  public gridApi: GridApi;
  public currentMode: Mode; // used to render the previous result when uncheck the "show selected values only" slide toggle
  public field: CmsField;
  public filter: Filter;
  public gridOptions: GridOptions;
  public hideAsyncSpinner = true;
  public include = true; // default to inclusive filter
  public nulls = false; // default to null unchecked
  public searchString = '';

  // UsesCheckboxRenderer interface fields
  public selectedIds$: BehaviorSubject<Set<string>>;
  public showBulkUpload = false;
  public showSameStoreSales = false;
  public where = '';

  private searchDebouncer$: Subject<SearchType> = new Subject<SearchType>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterModalData,
    private dialogRef: MatDialogRef<EditFilterModalComponent>,
    private filterValueService: FilterService,
    private router: Router
  ) {
    super();

    this.searchDebouncer$
      .pipe(
        debounceTime(EditFilterModalComponent.SEARCH_DEBOUNCE_TIME),
        takeUntil(this.unsub$)
      )
      .subscribe(type => {
        if (type === SearchType.ASYNC) {
          this.performAsyncSearch();
        } else {
          this.performSearch();
        }
      });
  }

  public apply() {
    const fsJson: FilterSelectionJson = {
      ...this.field,
      include: this.include,
      nulls: this.nulls,
      values: Array.from(this.selectedIds$.getValue()),
    }
  
    if (this.data.model?.context) {
      fsJson.context = this.data.model.context
    }
    const model = new FilterSelection(fsJson);

    if (this.sameStoreSalesComponent) {
      this.sameStoreSalesComponent.onSelectedIdsApplied();
    }

    this.dialogRef.close(model);
  }

  /**
   *
   * @param searchStrings: user input
   * @param updateSelectedIds: default to true, which is after bulk search, all values are automatically selected.
   * However, after uncheck the "show selected values only" slide toggle, we set it to false
   * because we don't want the unselected values become selected
   */
  public bulkSearch(searchStrings: string[], updateSelectedIds = true) {
    this.currentMode = Mode.BULKUPLOAD;
    this.bulkStrings = searchStrings;

    this.hideAsyncSpinner = false;
    // Sync search
    if (!this.filter.async) {
      let matchedFV: FilterValue[];
      const targetSet = new Set(searchStrings.map(str => str.toLowerCase()));
      let matchedSet = new Set();
      let missingInputs = [];
      matchedFV = this.filter.values.getAll().filter(fv => {
        if (fv.d && targetSet.has(fv.d.toLowerCase())) {
          matchedSet.add(fv.d.toLowerCase());
          return true;
        } else if (targetSet.has(fv.n.toLowerCase())) {
          matchedSet.add(fv.n.toLowerCase());
          return true;
        } else {
          return false;
        }
      });
      missingInputs = searchStrings.filter(str => !matchedSet.has(str.toLowerCase()));
      this.bulkSearchDone(matchedFV, missingInputs, updateSelectedIds);
    } else {
      // Async search
      const params: BulkSearchParams = {
        d: FilterService.mapNToD(this.field.field),
        /*
        * n: For Bulk Search use this.field.filter instead of this.field.field
        * prod_upc_desc is not a filter type enum, it should be prod_upc.
        * Refer utils.service.ts function paramify().
        */
        n: FilterService.getAllFilters().find(f => f.id === this.field.id) ? this.field.field : this.field.filter.toLowerCase(),
        r: this.field.retailer,
        ta: this.field.table,
        where: '',
        chosenSingleRetailer: this.getChosenSingleRetailerParamValue()
      };
      this.filterValueService.bulkSearchAsync(this.filter.id, searchStrings, params).subscribe((res: BulkSearchResponse) => {
        this.bulkSearchDone(res.matchedIdList, res.missingIdList, updateSelectedIds);
      });
    }
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  getId(fv: FilterValue) {
    return fv.n;
  }

  ngOnInit() {
    let values = [];
    this.field = this.data.field;

    if (this.data.model) {
      // import from existing filter selection model
      const model = _.cloneDeep(this.data.model);
      this.include = model.include;
      this.nulls = model.nulls;
      values = model.values;
    } else {
      // create new one
      if (this.data.config.include !== undefined) {
        this.include = this.data.config.include;
      }
    }
    this.selectedIds$ = new BehaviorSubject(new Set<string>(values));
    this.filter = FilterService.getFilter(this.field.id);
    const url = this.router.url;
    this.showSameStoreSales = url.includes('report-builder') && this.field.field === SameStoreSalesService.STORE_ID;
    this.showSameStoreSales =
      this.showSameStoreSales && !this.data.config.isColumnFilter && EnvConfigService.isSingleMode();
    SameStoreSalesService.currModalRetailer = this.field.retailer;
    this.render();
  }
  public onToggleSelectedOnly(e: MatSlideToggleChange) {
    if (e.checked) {
      const selectedFV: FilterValue[] = [];
      this.selectedIds$.getValue().forEach(id => {
        selectedFV.push({
          n: id,
          d: this.filter.values.getDisplay(id)
        });
      });
      this.setRows(selectedFV);
    } else {
      if (this.currentMode === Mode.SEARCH) this.search(this.searchString);
      else this.bulkSearch(this.bulkStrings, false);
    }
  }

  public search(str = '') {
    this.currentMode = Mode.SEARCH;
    this.searchString = str;

    if (this.filter.async) {
      if (!str) {
        // no search term, just show the currently selected values, no debouncer necessary
        this.mergeFilterValuesAndSetRows([]);
      }
      this.searchDebouncer$.next(SearchType.ASYNC);
    } else {
      this.searchDebouncer$.next(SearchType.SYNC);
    }
  }

  public toggleBulkUpload() {
    this.showBulkUpload = !this.showBulkUpload;
    if (this.showBulkUpload) {
      this.dialogRef.updateSize('80vw');
    } else {
      this.dialogRef.updateSize('55vw');
      this.bulkUploadModalComponent.inputText = '';
    }
  }

  private bulkSearchDone(matchedFV: FilterValue[], missingInputs: string[], updateSelectedIds: boolean) {
    this.bulkUploadModalComponent.inputText = missingInputs.join('\n');
    this.bulkUploadModalComponent.notFound = missingInputs.length > 0;
    this.mergeFilterValuesAndSetRows(matchedFV);
    if (updateSelectedIds) {
      this.selectedIds$.next(new Set([...Array.from(this.selectedIds$.getValue()), ...matchedFV.map(fv => this.getId(fv))]));
    }
    this.hideAsyncSpinner = true;
  }

  private getChosenSingleRetailerParamValue(): string {
    const currSchema =  (typeof this.data.schemaController === 'string') ? this.data.schemaController : this.data.schemaController.getValue();
    return currSchema !== EnvConfigService.getConfig().primaryEntity ? currSchema : null;
  }

  private mergeFilterValuesAndSetRows(incomingFV: FilterValue[]) {
    const preSelectedValues: FilterValue[] = [];
    this.selectedIds$.getValue().forEach(id => {
      preSelectedValues.push({
        n: id,
        d: this.filter.values.getDisplay(id)
      });
    });
    const preSelectedSet = new Set(preSelectedValues.map(f => f.n));
    this.setRows([...preSelectedValues, ...incomingFV.filter(f => !preSelectedSet.has(f.n))]);
  }

  // performs an async search, should be debounced
  private performAsyncSearch() {
    if (this.field.id === EditFilterModalComponent.AGG_CUSTOMER_ID) {
      if (!this.searchString.includes('|') || this.searchString.split('|')[1].length < EditFilterModalComponent.MIN_CHAR_THRESHOLD) {
        this.hideAsyncSpinner = true;
        return;
      }
    } else if (this.searchString.length < EditFilterModalComponent.MIN_CHAR_THRESHOLD) {
      this.hideAsyncSpinner = true;
      return;
    }
    /**
     * Backend hard-coded (non-dynamic) dims do not have an associated filter with same id.
     * Instead these are set to point to another existing filter, so use the "filter" field
     * as the fallback in this case.
     */
    const n = FilterService.getAllFilters().find(f => f.id === this.field.id) ? this.field.field : this.field.filter.toLowerCase();

    const searchParams: SearchParams = {
      search: this.searchString.trim(),
      d: FilterService.mapNToD(this.field.field),
      r: this.field.retailer,
      ta: this.field.table,
      n: n,
      where: '',
      chosenSingleRetailer: this.getChosenSingleRetailerParamValue()
    };
    const req = this.filterValueService.search(this.filter.id, searchParams, true);
    this.hideAsyncSpinner = false;
    req.subscribe((asyncValues: FilterValue[]) => {
      this.setRows(asyncValues);
      this.hideAsyncSpinner = true;
    }, err => {
      this.hideAsyncSpinner = true;
    });
  }

  // performs a synchronous sort from filter values already loaded in memory
  private performSearch() {
    const str = this.searchString.toLowerCase();
    let values = this.filter.values.getAll();

    if (this.searchString) {
      values = values.filter(fv => {
        if (fv.d && fv.d.toLowerCase().includes(str)) {
          return true;
        }
        return fv.n.toLowerCase().includes(str);
      });
    }

    this.setRows(values);
  }

  private render() {
    this.gridOptions = {
      overlayLoadingTemplate: AgGridConfig.overlayLoadingTemplate,
      defaultColDef: {
        resizable: false,
        sortable: true
      },
      animateRows: true,
      rowHeight: 48,
      suppressContextMenu: true,
      columnDefs: [
        CheckboxRendererComponent.GenerateColDef({ parent: this }),
        FilterValueRendererComponent.GenerateColDef({ parent: this })
      ],
      onGridReady: (grid: GridReadyEvent) => {
        this.gridApi = grid.api;
        this.search();
      }
    };
  }

  // Helper fn for setting rows
  private setRows(fv: FilterValue[]) {
    this.gridApi.updateGridOptions({ rowData: fv });
    this.gridApi.sizeColumnsToFit();
    this.selectedIds$.next(this.selectedIds$.getValue()); // fire signal for the sub in SelectAllHeaderCellComponent
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { FilterService } from 'app/filter/services/filter.service';

@Pipe({
  name: 'filterSelectionLabel'
})
export class FilterSelectionLabelPipe implements PipeTransform {

  transform(model: FilterSelection): string {
    const field = model.field;
    const filter = FilterService.getFilter(field.id);

    if (!field) return;

    const numSelected = model.values.length;
    const nulls = model.nulls;
    let text = model.getDisplayWithContext();

    if (model.include) {
      text += ' +';
    } else {
      text += ' -';
    }

    if (numSelected) {
      text += numSelected;
    }

    if (nulls) {
      if (numSelected) {
        text += ' &';
      }
      text += ' Unknowns';
    }


    text += ` of ${filter.async ? 'many' : filter.size}`;

    return text;
  }

}

import * as _ from 'lodash';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import {
  ContentType,
  CrudConfig
} from '@siq-js/core-lib';
import { NotificationService, ResponseCodeType, ResponseCodes, ResponseCodesConfig } from '@siq-js/angular-buildable-lib';
import { filter, share } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SiqHttpService {

  static AUTH_SKIP_VALUE = 'no-auth';
  static AUTH_KEY = 'Authorization';

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService
  ) { }

  create(crudConfig: CrudConfig): Observable<HttpResponse<any>> {
    const responseCodesConfig: ResponseCodesConfig = {
      resp: null,
      responseCodeType: ResponseCodeType.CREATE,
      data: crudConfig.notificationData
    };

    // Make this a HOT observable: single code execution but multiple subscribers
    let obs$: Observable<HttpResponse<any> | ArrayBuffer> =
      this.http.post(
        crudConfig.fullURL ? crudConfig.endpoint : this.getFullEndpoint(crudConfig.endpoint),
        crudConfig.body,
        _.extend({ observe: 'response', headers: this.createHeaders(crudConfig.additionalHeaders, crudConfig.type) }, crudConfig.additionalConfig)
      ).pipe(share());

    let _sub = obs$
    .subscribe((resp: HttpResponse<any>) => {
      if (!crudConfig.suppressNotification && resp) {
        responseCodesConfig.resp = resp;
        this.showNotification(resp.status, this.getResponseCodes(responseCodesConfig));
      }
    }, err => {
      if (!crudConfig.suppressNotification) {
        responseCodesConfig.resp = err;
        this.showNotification(err.status, this.getResponseCodes(responseCodesConfig));
      }
      return err;
    }, () => {
      _sub.unsubscribe();
    });

    return obs$ as Observable<HttpResponse<any>>;
  }

  createHeaders(customHeaders: any = {}, contentType?: ContentType): HttpHeaders {
    let headers: any = {
      'Content-Type': contentType || ContentType.JSON,
      'Cache-Control': 'no-cache',
      'Content-Location': ''
    };

    if (_.keys(customHeaders).length) {
      headers = _.extend(headers, customHeaders);
    }

    return new HttpHeaders(headers);
  }

  getNoAuthHeaders() {
    let noAuthHeaders = {};
    noAuthHeaders[SiqHttpService.AUTH_KEY] = SiqHttpService.AUTH_SKIP_VALUE;
    return noAuthHeaders;
  }

  get(crudConfig: CrudConfig): Observable<any> {
    const url: string = crudConfig.fullURL ? crudConfig.endpoint : this.getFullEndpoint(crudConfig.endpoint);
    let options = { headers: this.createHeaders(crudConfig.additionalHeaders, crudConfig.type) };
    if (crudConfig.responseType) {
      options['responseType'] = crudConfig.responseType;
    }
    if (crudConfig.params) {
      options['params'] = crudConfig.params;
    }

    return this.http.get<HttpResponse<any>>(url, options);
  }

  getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    /*
     * This function may be overridden by derived classes to customize ResponseCodes
     * The params are not used here, but would be used in the OVERRIDE functions
     */
    return new ResponseCodes();
  }

  handleHttpError(error: HttpErrorResponse | any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  remove(crudConfig: CrudConfig): Observable<HttpResponse<any>> {
    const responseCodesConfig: ResponseCodesConfig = {
      resp: null,
      responseCodeType: ResponseCodeType.DELETE,
      data: crudConfig.notificationData
    };

    let obs$: Observable<HttpResponse<any>> = this.http.delete(this.getFullEndpoint(crudConfig.endpoint),
      { headers: this.createHeaders(crudConfig.additionalHeaders), observe: 'response' }).pipe(share());

    let _sub = obs$
    .subscribe(resp => {
      if (!crudConfig.suppressNotification) {
        responseCodesConfig.resp = resp;
        this.showNotification(resp.status, this.getResponseCodes(responseCodesConfig));
      }
    },
    err => {
      responseCodesConfig.resp = err;
      this.showNotification(err.status, this.getResponseCodes(responseCodesConfig));
    }, () => {
        _sub.unsubscribe();
    });

    return obs$;
  }

  update(crudConfig: CrudConfig): Observable<HttpResponse<any>> {
    const responseCodesConfig: ResponseCodesConfig = {
      resp: null,
      responseCodeType: ResponseCodeType.UPDATE,
      data: crudConfig.notificationData
    };

    let obs$: Observable<HttpResponse<any>> = this.http.put(
      crudConfig.fullURL ? crudConfig.endpoint : this.getFullEndpoint(crudConfig.endpoint),
      crudConfig.body,
      _.extend({ headers: this.createHeaders(crudConfig.additionalHeaders, crudConfig.type), observe: 'response' }, crudConfig.additionalConfig)
    ).pipe(share()) as Observable<HttpResponse<any>>;

    let _sub = obs$
    .subscribe((resp: HttpResponse<any>) => {
      if (!crudConfig.suppressNotification && resp) {
        responseCodesConfig.resp = resp;
        this.showNotification(resp.status, this.getResponseCodes(responseCodesConfig));
      }
    },
    err => {
      if (!crudConfig.suppressNotification) {
        responseCodesConfig.resp = err;
        this.showNotification(err.status, this.getResponseCodes(responseCodesConfig));
      }
      throw new Error(err);
    }, () => {
      _sub.unsubscribe();
    });

    return obs$;
  }

  public getFullEndpoint(endpoint: string): string {
    return AppSiqConstants.environment.api + '/' + endpoint;
  }

  public showNotification(status: number, responseCodes: ResponseCodes): void {
    this.notificationService.showHttpNotification(status, responseCodes);
  }

  /** expects file to be of type 'application/pdf' */
  public saveFile (path) {

    this.http.request(new HttpRequest(
      'GET',
      this.getFullEndpoint('files/' + path),
      {
        headers: this.createHeaders(),
        responseType: 'arraybuffer'
      }
    ))
    .pipe(
      filter((res: any) => res.body && res.status === 200)
    )
    .subscribe((res: HttpResponse<any>) => {

      let blob = new Blob([res.body], { type: 'application/pdf' });
      let URL = window.URL || window['webkitURL'];
      let downloadUrl = URL.createObjectURL(blob);
      let downloadLink = document.createElement('a');
      downloadLink.download = path.split('/').pop();
      downloadLink.href = downloadUrl;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      setTimeout(() => {
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);
      });

    });

  }

  public get500ErrorMessage(res: HttpResponse<any> | HttpErrorResponse): string {
    if(res instanceof HttpErrorResponse) {
      return res.error?.message ?? res.message;
    }
    return null;
  }

}

import { CmsService } from 'app/core/services/cms/cms.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { ComparisonGroup, ComparisonGroupJson } from 'app/siq-applications/modules/promo/models/comparison-group';
import { PromoParams } from 'app/siq-applications/modules/promo/models/interfaces';
import { SourceOfVolume, SourceOfVolumeJson } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { DateRangeInterface, DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';
import { DateRangeParameter } from '@siq-js/core-lib';
import { AppFormData, AppFormJson } from 'app/siq-applications/modules/shared/models/app-form-data.model';

export interface PromoFormJson extends AppFormJson {
  comparisonGroups?: ComparisonGroupJson[];
  customPromoDateRange?: DateRangeParameter;
  isCloned: boolean; // TODO: refactor to isClonedOrEdited - now used to signify also edit mode
  locationFilters?: FilterSelectionJson[];
  name: string;
  prePromoDateRange: DateRangeParameter;
  promoDateRange: DateRangeParameter;
  promoType?: string;
  sourcesOfVolume?: SourceOfVolumeJson[];
  upcFilters: FilterSelectionJson[];
  upcCount: number;
  yoyDateRange: DateRangeParameter;
}

export class PromoFormData extends AppFormData<PromoFormJson> {
  public comparisonGroups: ComparisonGroup[]; // Comparison tables
  public customPromoDateRange: DateRangeParameter; // Custom date
  public isCloned: boolean;
  public locationFilters: FilterSelection[]; // location filters
  public name: string;
  public prePromoDateRange: DateRangeParameter; // Pre-promo date
  public promoDateRange: DateRangeParameter; // Promo period date
  public promoDateRangeInterface: DateRangeInterface = {begin: null, end: null, type: DateRangeInterfaceType.POPULATED}; // Used to set dates for cloned form. Initialized as null to keep promo dates empty for blank form
  public promoType: string;
  public schema: string;
  public sourcesOfVolume: SourceOfVolume[];
  public upcFilters: FilterSelection[]; // UPCs
  public upcCount: number;
  public yoyDateRange: DateRangeParameter; // YOY date
  public id: string; // "Edit Report" needs the original id.

  constructor() {
    super();
    this.initDates();
  }

  /**
   * Used for QueryModeModalConfig diffFn. To determine whether the modal needs to be opened
   */
  public isValidSchema(schema: string): boolean {
    for (let fs of this.upcFilters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
    }
    for (let fs of this.locationFilters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
    }
    for (let cg of this.comparisonGroups) {
      if (!cg.isValid) break;
      for (let fs of cg.filters) {
        if (!CmsService.isValidField(fs.field, schema)) return false;
      }
    }
    for (let sov of this.sourcesOfVolume) {
      if (sov.isValid && !CmsService.isValidField(sov.dimension, schema)) return false;
    }
    return true;
  }

  public toJson(): PromoFormJson {
    const json: PromoFormJson = {
      isCloned: this.isCloned,
      prePromoDateRange: this.prePromoDateRange,
      promoDateRange: this.promoDateRange,
      upcFilters: this.upcFilters.map(f => f.toJson()),
      upcCount: this.upcCount,
      schema: this.schema,
      weekEndingDay: this.weekEndingday,
      name: this.name,
      yoyDateRange: this.yoyDateRange,
    };
    if (this.promoType) {
      json.promoType = this.promoType;
    }
    if (!!this.customPromoDateRange.periodStart) {
      json.customPromoDateRange = this.customPromoDateRange;
    }
    if (this.locationFilters) {
      json.locationFilters = this.locationFilters.map(f => f.toJson());
    }
    if (this.comparisonGroups) {
      json.comparisonGroups = this.comparisonGroups.map(cg => cg.toJson());
    }
    if (this.sourcesOfVolume) {
      json.sourcesOfVolume = this.sourcesOfVolume.map(sov => sov.toJson());
    }
    return json;
  }

  public toParams(): PromoParams {
    const param: PromoParams =  {
      name: this.name,
      upcFilters: this.upcFilters.map(f => UtilsService.paramify(f)),
      promoDateRange: this.promoDateRange,
      prePromoDateRange: this.prePromoDateRange,
      yoyDateRange: this.yoyDateRange,
      formValues: this.toJson(),
      retailers: [this.schema],
      analysisType: this.getAnalysisType()
    };
    if (this.promoType) {
      param.promoType = this.promoType;
    }
    if (!!this.customPromoDateRange.periodStart) {
      param.customPromoDateRange = this.customPromoDateRange;
    }
    if (this.locationFilters) {
      param.locationFilters = this.locationFilters.map(f => UtilsService.paramify(f));
    }
    if (this.comparisonGroups) {
      param.comparisonGroups = this.comparisonGroups.map(cg => cg.toParam());
    }
    if (this.sourcesOfVolume) {
      param.sourcesOfVolume = this.sourcesOfVolume.map(sov => sov.toParam());
    }
    if (this.id) {
      param.id = this.id; // "Edit Report" needs the original id.
    }
    return param;

  }

  private initDates() {
    this.promoDateRange = {
      periodStart: '',
      periodEnd: '',
      customPeriodName: 'Promo Period'
    };
    this.prePromoDateRange = {
      periodStart: '',
      periodEnd: '',
      customPeriodName: 'Pre Promo Period'
    };
    this.customPromoDateRange = {
      periodStart: '',
      periodEnd: '',
      customPeriodName: 'Custom Date Range'
    };
    this.yoyDateRange = {
      periodStart: '',
      periodEnd: '',
      customPeriodName: 'Year Over Year'
    };
  }
}

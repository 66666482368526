import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DatePartsFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/date-parts-filter/date-parts-filter.component';
import { DatePart } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-part.enum';
import { DateForm, dayPeriod } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-form.interface';
import { CustomFilterService } from 'libs/visual-lib/src/lib/modules/custom-filters/services/custom-filter.service';
import { IDoesFilterPassParams } from 'libs/visual-lib/src';

@Component({
  selector: 'siq-day-filter',
  templateUrl: './hour-filter.component.html',
  styleUrls: ['./hour-filter.component.scss', '../../styles/custom-filters.scss']
})
export class HourFilterComponent extends DatePartsFilterComponent {
  public twentyFourHourTime = false;

  @ViewChild('hour', { read: ViewContainerRef, static: true }) private _input;

  constructor() {
    super();
    this.setDatePart(DatePart.DAY);
  }

  // Focuses on the month element upon initialization of the filter controller
  // AfterViewInit interface implemented by inherited class
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => {
      this._input && this._input.element.nativeElement.focus();
    });
  }

  /**
   * Checks a single row, as passed to it, to see if it falls inside of the specified filter criteria.
   *
   * @param {IDoesFilterPassParams} params A IDoesFilterPassParams object containing a reference to a single cell.
   * @returns {boolean} A boolean variable indicating if the row passes the filter criteria or not.
   *
   */
  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return CustomFilterService.numberCompare(
      this.params.getValue(params.node),
      this.twentyFourHourTime ? this.getForm().hour : this.getTwentyFourHourTime(this.getForm()),
      this.filterType,
      this.twentyFourHourTime ? this.getFormRangeEnd().hour : this.getTwentyFourHourTime(this.getFormRangeEnd())
    );
  }

  /**
   * Gets the hour in twenty-four hour time from a model which is in 12 hour.
   *
   * @param {DateForm} form The model from which to extract the hour from.
   * @returns {number} The hour, in twenty-four hour time.
   *
   * @private
   */
  private getTwentyFourHourTime(form: DateForm): number {
    let hour = form.hour;
    if (hour == 12) {
      hour = 0;
    }
   
    if (form.period === dayPeriod.PM) {
      hour = hour + 12;
    }

    return hour;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {

  public static THEMES = {
    DARK: 'dark-theme',
    LIGHT: 'light-theme'
  };

  public static getTheme(): string {
    return ThemesService.theme$.value;
  }

  public static setTheme(theme: string = ThemesService.THEMES.LIGHT): void {
    ThemesService.theme$.next(theme);
    document.getElementsByTagName('html')[0].className = ThemesService.theme$.value;
  }

  public static theme$: BehaviorSubject<string> = new BehaviorSubject<string>(ThemesService.THEMES.LIGHT);

  constructor() { }
}

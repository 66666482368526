<div id="tabBody" class="ag-tab-body">
  <div class="ag-filter">
    <div>
      <div>
        <select class="ag-filter-select" [(ngModel)]="filterType" (ngModelChange)="onChangeFilterType($event)">
          <option *ngFor="let filterType of filterTypes" [value]="filterType">{{ filterLabels[filterType] }}</option>
        </select>
      </div>
      <div class="ag-filter-body">

        <div *ngIf="!isRange; else range">
          <input #input class="ag-filter-filter" type="number" step="any" [(ngModel)]="percentageFilterForm.text">
        </div>

        <ng-template #range>
          <div>
            <input class="ag-filter-filter range-low" type="number" step="any" [(ngModel)]="percentageFilterForm.rangeLow">
            <span> - </span>
            <input class="ag-filter-filter range-high"type="number" step="any" [(ngModel)]="percentageFilterForm.rangeHigh">
          </div>
        </ng-template>

      </div>
      <div class="ag-filter-apply-panel" id="applyPanel">
        <button type="button" id="clearButton" (click)="clearFilter()">Clear Filter</button>
        <button type="button" id="applyButton" (click)="applyFilter()">Apply Filter</button>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { AgRendererComponent, ColDef, IHeaderAngularComp } from '@siq-js/visual-lib';
import { takeUntil } from 'rxjs';
import { UsesCheckboxRenderer } from 'app/core/components/cell-renderers/checkbox-renderer/uses-checkbox-renderer';
import { CheckboxHeaderRendererComponent } from 'app/core/components/cell-renderers/checkbox-header-renderer/checkbox-header-renderer.component';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss']
})
export class CheckboxRendererComponent extends BaseSiqComponent implements AgRendererComponent {
  public static COL_ID = 'checkbox';
  public checked  = false;

  private parent: UsesCheckboxRenderer<any>;
  private id: string;

  public static GenerateColDef(params: {
    parent?: UsesCheckboxRenderer<any>,
    header?: IHeaderAngularComp | any
  } = {}): ColDef {
    return {
      colId: this.COL_ID,
      cellRenderer: CheckboxRendererComponent,
      headerComponent: params.header || CheckboxHeaderRendererComponent,
      width: 60,
      resizable: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      pinned: 'left',
      refData: {
        parent: params.parent as any
      }
    };
  }

  constructor() {
    super();
  }

  agInit(params: any) {
    this.parent = params.colDef.refData.parent;

    this.id = this.parent.getId(params.data);

    this.parent.selectedIds$
      .pipe(
        takeUntil(this.unsub$)
      )
      .subscribe((ids: Set<string>) => {
        this.checked = ids.has(this.id);
      });
  }

  refresh(): boolean {
    return true;
  }

  public update() {
    const ids = this.parent.selectedIds$.getValue();
    if (this.checked) {
      ids.add(this.id);
    } else {
      ids.delete(this.id);
    }

    this.parent.selectedIds$.next(ids);
  }

}

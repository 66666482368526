import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import {
  ApplicationHash,
  stringToNum
} from '@siq-js/core-lib';
import {
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig,
  NotificationService,
  NotificationType
} from '@siq-js/angular-buildable-lib';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { PromoFormData, PromoFormJson } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { SiqApplicationService } from 'app/siq-applications/modules/shared/services/siq-application.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs';
import { ActivityTypesListInterface, HasDynamicMetrics, PromoParams, SOVHelperData, UpcCountParams } from 'app/siq-applications/modules/promo/models/interfaces';
import { FilterSelection } from 'app/filter/models/filter-selection';
import * as _ from 'lodash';
import { ComparisonGroup } from 'app/siq-applications/modules/promo/models/comparison-group';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { PromoActivity } from 'app/siq-applications/modules/promo/models/promo-activity.model';
import { KPI, PromoDimensionKeys, PromoJobEnums, PromoPeriods, UnitTypes } from 'app/siq-applications/modules/promo/models/promo.enums';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import * as pluralize from 'pluralize';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import {
  AggregationType,
  AsyncCellComponent,
  ColDef,
  ExcelService,
  GridService,
  TextColorType,
  ValueGetterParams,
  VisualOptions
} from '@siq-js/visual-lib';
import { StatResponse } from 'app/core/models/stat-response.model';
import { SovDrilldownParameters } from 'app/siq-applications/modules/promo/models/sov-drilldown-parameters';
import { ActivityFactory } from 'app/activity/models/activity.factory';
import { DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';

@Injectable()
export class PromoService extends SiqHttpService {
  public static Activities$: BehaviorSubject<PromoActivity[]>;
  public static isCustomDateRangeValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static isPrePromoDateRangeValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static isYoyDateRangeValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static readonly apiPath = 'app/promo';
  public static readonly DETAIL = 'app/promo-detail';
  public static readonly appPromoTypes = 'app/promo-types';
  public static readonly upcCount = 'app/upc-count';
  public static promoMetricKeyToCmsMetricKey: ReadonlyMap<string, string> = new Map([
    [KPI.AVG_PRICE, 'AVG_PRICE'],
    [KPI.BASKET_TOTAL_AMOUNT, 'TOTAL_AMOUNT'],
    [KPI.BASKET_TOTAL_QUANTITY, 'TOTAL_QUANTITY'],
    [KPI.NUM_TRANSACTIONS, 'NUM_TRANSACTIONS'],
    [KPI.TOTAL_AMOUNT, 'TOTAL_AMOUNT'],
    [KPI.TOTAL_QUANTITY, 'TOTAL_QUANTITY'],
  ]);

  // temporary fcn to generate fake kpi data. Remove later
  public static setFakeKpiModel(a: PromoActivity, kpiModel: any) {
    a.kpiModel = _.cloneDeep(kpiModel);
  }

  public static promoTypesCache: ActivityTypesListInterface;

  public static validateForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false) ;

  public clonedActivity: PromoActivity;

  private overrideCodes: ResponseCode[] = [];

  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
    private activityService: ActivityService,
    private mixpanelService: MixpanelService,
    private datesService: DatesService,
    private router: Router,
    private siqApplicationService: SiqApplicationService,
    private utilsService: UtilsService,
    private config: PromoConfig

  ) {
    super(http, notificationService);
    if (!PromoService.Activities$) {
      PromoService.Activities$ = ActivityService.createStream<Activity>(activities =>
        activities.filter(a => a.getAppId() === ApplicationHash.PROMO && (!a.isMine() || (a.isMine() && !a.isSharedOrScheduled())))) as BehaviorSubject<PromoActivity[]>;
    }
  }

  public static generateValKey(keys: string[]): string {
    return keys.join('|');
  }

  public static detectDynamicFacts(component: HasDynamicMetrics, baseFacts: CmsMetric[], defaultFactId?: string) {
    let facts = _.cloneDeep(baseFacts);

    if (component.unitType === UnitTypes.PSPW) {
      facts = facts.filter(f => this.isFactPSPW(f.id));
    }

    component.facts = facts;

    if (!component.facts.map(f => f.id).includes(component.factKey?.id)) {
      component.factKey = facts.find(f => f.id === (defaultFactId ?? PromoConfig.defaultFact));
    } else {
      component.factKey = facts.find(f => f.id === component.factKey.id);
    }
  }

  public static filterMetricsForTakeRateDropdown(baseFacts: CmsMetric[]) {
    let facts = _.cloneDeep(baseFacts);
    return facts.filter(f => !f.id.match(PromoConfig.NUM_SELLING_STORE_WEEKS));
  }

  private static isFactPSPW(factEnum: string): boolean {
    return !factEnum.match(/AVG|NUM_STORES|PERCENT/g);
  }

  public static getCellClassRules(decoratedEnum: CmsMetric|CmsField): any {

    const key = GridService.getDataKey(decoratedEnum);
    const out = {};

    switch (decoratedEnum.id) {
      case 'PROMO_DELTA':
      case 'PROMO_PERCENT_DELTA':
        out[TextColorType.SUCCESS.toString()] = (context) => {
          let val = _.isObject(context.value) ? context.value[key] : context.value;
          if (typeof val === 'string') {
            val = UtilsService.scrubFormattedVal(val);
          }
          return !_.isNaN(val) && val > 0;
        };
        out[TextColorType.WARN.toString()] = (context) => {
          let val = _.isObject(context.value) ? context.value[key] : context.value;
          if (typeof val === 'string') {
            val = UtilsService.scrubFormattedVal(val);
          }
          return !_.isNaN(val) && val < 0;
        };
        break;
    }

    out[GridService.ENUM_ID_PREPEND + decoratedEnum.id] = () => true;

    return out;

  }

  public generateTakeRateTSData(sr: StatResponse, form: PromoFormData): any[] {
    sr.dimensions.forEach(d => {
      d.id = d.field;
    });
    const data = GridService.jobToArray(sr);
    this.applyWeights(data, false);

    data.forEach(dp => {
      // apply pretty-printed period names
      dp[PromoDimensionKeys.PERIOD] = form[PromoConfig.periodNames[dp[PromoDimensionKeys.PERIOD]]].customPeriodName;
    });

    return data;

  }

  public generateSOVData(job: AppResponseDataset, upcSet: Set<string>): any[] {
    const output = [];
    const dimKey = job.getResponse().getDimensions()[0].id;

    const _map = this.processJobToDatamap(job);
    for (let dV in _map) { // k is the dimension Value, _map[k] is the processed merged fact values
      const dataPoint = _map[dV];
      dataPoint[dimKey] = dV;

      if (
        dimKey.includes(PromoDimensionKeys.UPC_DESC) &&
        upcSet.has(UtilsService.processDrilldownValue(dV, PromoDimensionKeys.UPC_DESC))
      ) {
        dataPoint.promo = true;
      }

      output.push(dataPoint);
    }

    return output;
  }

  public generateSOVHelperData(activity: Activity, parentActivity: PromoActivity, form: PromoFormData): SOVHelperData {
    const distJob = activity.getJob(PromoJobEnums.SOV_DISTRIBUTION);
    const tsJob = activity.getJob(PromoJobEnums.SOV_TIME_SERIES);

    return {
      distribution: this.generateDistributionData(distJob, parentActivity),
      timeseries: this.processSOVTimeSeriesJob(tsJob, form),
    };
  }

  public generatePercentFilterValueGetter(colDef: ColDef, fact: CmsMetric): (params: ValueGetterParams) => any {
    return (params: ValueGetterParams) => {
      if (typeof colDef.valueGetter === 'function' && colDef.valueGetter(params)) {
        const formatterFn = GridService.generateFormatterFn(fact);
        const value = colDef.valueGetter(params);
        const displayVal = formatterFn({value: {val: value.val ?? value} });
        return stringToNum(displayVal);
      }
    };
  }

  // These jobs are identified thru the job name: '{{PERIOD}}-TIMESERIES'
  // Due to the unorthodox nature of the SOV timeseries table, this function
  // expects a VERY SPECIFIC job configuration (non-trivial dim order) and will
  // need to be modified if that structure ever changes
  // This takes an approach similar the RB's time aggregates, in which it generates
  // a pseudo column-group each basket size, as well as a total column for each fact
  private processSOVTimeSeriesJob(job: AppResponseDataset, form: PromoFormData): any[] {
    const output = [];
    const datamap = this.processJobToDatamap(job);

    // Used for generating total column(s)
    const factSet: Set<string> = new Set();
    const basketSizeSet: Set<string> = new Set();

    for (let week in datamap) { // First level: promo period dim
      for (let day in datamap[week]) { // Second level: week dim
        const dataPoint = {
          [PromoDimensionKeys.TIMESERIES_DAY]: Number(day),
          [PromoDimensionKeys.TIMESERIES_WEEK]: Number(week),
        };

        for (let basketSize in datamap[week][day]) { // Third level: day dim
          basketSizeSet.add(basketSize);

          for (let facts in datamap[week][day][basketSize]) { // Fourth (last) level: keys are in the format {{PERIOD}}|{{FACT}}}

            const period = facts.split('|')[0];
            const factKey = facts.split('|')[1];
            factSet.add(factKey);
            const key = PromoService.generateValKey([basketSize, factKey]);

            // Set up the key assignment to the leaf value node
            dataPoint[key] = datamap[week][day][basketSize][facts];

            // Tag this data point with its correct period label
            dataPoint[PromoDimensionKeys.PERIOD] = form[PromoConfig.periodNames[period]].customPeriodName;
          }
        }

        output.push(dataPoint);
      }
    }

    // Sort data points chronologically
    output.sort((a, b) => UtilsService.isLessThan(a[PromoDimensionKeys.TIMESERIES_DAY], b[PromoDimensionKeys.TIMESERIES_DAY]));

    // Generate totals for each fact (used in table & line graph)
    output.forEach(dp => {

      factSet.forEach(fact => {
        const totalKey = PromoService.generateValKey(['TOTAL', fact]);
        let total = 0;

        basketSizeSet.forEach(basketSize => {
          const key = PromoService.generateValKey([basketSize, fact]);
          if (_.get(dp, [key, 'val'])) {
            total += dp[key].val;
          }
        });

        dp[totalKey] = { val: total };
      });
    });

    return output;
  }

  /**
   * Basically when you see variable named dataMap, it's like a tree with a lot of nodes.
   * A leaf node contains the data for a store as a base unit.
   * This function traverses the tree using DFS and does aggregation when backtracking.
   * It aggregates in this order: store -> city -> state -> region.
   */
  public generateDistributionData(job: AppResponseDataset, parentActivity: PromoActivity): {[s in string]: any[]} {
    const _map = this.processJobToDatamap(job);
    const dims = job.getResponse().getDimensions().filter(d => d.id !== PromoDimensionKeys.PERIOD);
    const output: {[s in string]: any[]} = {};

    dims.forEach(d => output[d.id] = []);

    function recurse(pointer: any, depth = 0): any[] {

      if (depth < dims.length) {
        const allElements = [];
        for (let k in pointer) {
          const innerElements = recurse(pointer[k], depth + 1); // collect all data points with k dimVal
          const dataPoint = _.extend({
              [dims[depth].id]: k
            }, aggregateDataPoints(innerElements)
          );

          output[dims[depth].id].push(dataPoint);
          allElements.push(...innerElements); // add to aggregate list for previous recursive call
        }
        return allElements;
      } else {
        return [pointer];
      }
    }

    function aggregateDataPoints(arr: any[]): any {
      const dataPoint = {};

      arr.forEach(el => {

        for (let k in el) { // aggregate all individual values into a single data point
          dataPoint[k] = dataPoint[k] || { val: 0};
          dataPoint[k].val += el[k].val;

          if (k.includes(KPI.TOTAL_AMOUNT)) {
            // if a store sold any units in a certain period, mark it (for PSPW calculations)
            const period = k.split('|')[0];
            const storesKey = PromoService.generateValKey([period, 'NUM_STORES']);
            dataPoint[storesKey] = dataPoint[storesKey] || {val: 0};
            dataPoint[storesKey].val++;
          }
        }
      });

      dataPoint['NUM_STORES'] = {val: arr.length}; // include all the stores(sale or no sale)

      [PromoPeriods.PROMO, PromoPeriods.PRE, PromoPeriods.POST, PromoPeriods.YOY].forEach(period => {
        const amountKey = PromoService.generateValKey([period, KPI.TOTAL_AMOUNT]);
        if (dataPoint[amountKey]) {
          // manually calculate average metric
          const avgPriceKey = PromoService.generateValKey([period, KPI.AVG_PRICE]);
          const quantKey = PromoService.generateValKey([period, KPI.TOTAL_QUANTITY]);
          dataPoint[avgPriceKey] = { val: dataPoint[amountKey].val / dataPoint[quantKey].val };
        }
      });

      return dataPoint;
    }

    recurse(_map);

    for (let dim in output) {
      this.applyWeights(output[dim], true);
    }

    return output;
  }

  // Take Rate only requires a single dataset
  public generateTakeRateData(job: AppResponseDataset): any[] {
    const output = [];
    const sr = job.getResponse();
    const dims = sr.getDimensions();
    const dataMap = this.processJobToDatamap(job);

    function recurse(obj: any, current: any, depth: number) {
      if (!dims[depth + 1]) { // depth + 1 to ignore the PERIOD dimension (as it has already been merged in with the fact keys)
        const finalDataPoint = _.cloneDeep(obj);
        _.extend(finalDataPoint, current);
        output.push(_.cloneDeep(finalDataPoint));
      } else {
        for (const k in current) {
          obj[dims[depth].field] = k;
          recurse(obj, current[k], depth + 1);
        }
      }
    }

    recurse({}, dataMap, 0);

    this.applyWeights(output, true);

    return output;
  }

  /**
   * PURE FUNCTION (does not modify input) that calculates dynamic per-store-per-week values for each data point
   * @param data: original array of data points
   * @param merged: whether the data points use the merged factKey structure (ie YOY|TOTAL_UNITS) or regular (ie TOTAL_UNITS)
   */
  public applyPerStorePerWeek(data: any[], merged = true): any[] {
    return data.map(_datum => {
      let datum = _.cloneDeep(_datum);

      for (let k in datum) {
        if (datum[k].val && PromoService.isFactPSPW(k)) {
          let period: PromoPeriods;
          let factKey = PromoConfig.NUM_SELLING_STORE_WEEKS;
          if (merged) {
            const keys = k.split('|');
            period = keys[0] as PromoPeriods;
            factKey = PromoService.generateValKey([period, PromoConfig.NUM_SELLING_STORE_WEEKS]);
          } else {
            period = datum[PromoDimensionKeys.PERIOD];
          }

          datum[k].val = datum[factKey].val === 0 ? null : datum[k].val / datum[factKey].val;
        }
      }

      return datum;
    });
  }

  // Performs a drilldown for the SOV helper table
  public drilldownSOV(filterValue: string, sov: SourceOfVolume, form: PromoFormData): Observable<any> {
    let finalFilterValue = sov.dimension.id.includes('prod_upc_desc') ? filterValue.split('-')[0].trim() : filterValue;

    const detailFilters = [UtilsService.paramify(new FilterSelection({
      id: sov.dimension.id,
      values: [finalFilterValue],
      include: true,
      nulls: false
    }))];

    const params = new SovDrilldownParameters(detailFilters, form);

    if (sov.comparisonGroup) { // when drilldown under "Promoted UPC", CG will be null so this condition will not pass.
      const cg = form.comparisonGroups.find(c => c.id === sov.comparisonGroup.id);
      const cgFilters = cg?.filters.map(f => UtilsService.paramify(f));
      if (cgFilters && cgFilters.length) {
        params.globalFilters = params.globalFilters.concat(cgFilters);
      }
    }

    this.overrideResponseCode(
      200,
      NotificationType.SUCCESS,
      'Drilldown Created',
      'Drilldown has been successfully created and now is running.'
    );

    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': 'Drill Down',
      'Usage': 'Source of Volume'
    });

    return this.create({
      endpoint: PromoService.DETAIL,
      body: params
    }).pipe(map(res => res.body));
  }

  // Clone a promo form using formValues from an activity
  public cloneReport(id: string) {
    this.getReport(id, ActivityResultType.CLONE)
      .subscribe(activity => {
        this.clonedActivity = activity as PromoActivity;
        this.router.navigate(['/app/measurement/~']);
      });
  }

  public saveReport(formData: PromoFormData, edit: Boolean = false): Observable<any> {
    this.overrideCodesForSaveRequests(formData, edit);

    // if name is not set(undefined), use default value
    if (_.isNil(formData.name)) {
      formData.name = Activity.ActivityPlaceholder;
    }

    const params: PromoParams = formData.toParams();
    return this.create({endpoint: PromoService.apiPath, body: params}).pipe(
      tap(res => {
        if (res.status === 200) {
          if (edit) {
            this.mixpanelService.track(
              MixpanelEvent.ACTIVITY_EDITED,
              {
                'Application': this.config.getApplication().display,
                'Activity ID': params.id,
                'JSON': formData.toJson(),
                'Name': params.name,
                'Type': 'Report'
              }
            );
          } else {
            this.mixpanelService.track(
              MixpanelEvent.ACTIVITY_CREATED,
              {
                'Application': this.config.getApplication().display,
                'Activity ID': res.body['appActivityId'],
                'JSON': formData.toJson(),
                'Name': formData.name,
                'Type': 'Report'
              }
            );
          }
        }
      }),
      map(res => res.body),
    );
  }

  private overrideCodesForSaveRequests(formData: PromoFormData, edit: Boolean = false) {
    if (edit) {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Updated',
        'Your report has been updated and is now running.'
      );
    } else if (!!formData && formData.isCloned) {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Cloned',
        'Your report has been cloned.'
      );
    } else {
      this.overrideResponseCode(
        200,
        NotificationType.SUCCESS,
        'Report Created',
        'Your report has been created and is now running.'
      );
    }
  }

  public overrideResponseCode(code: number, type: NotificationType, header: string, message: string): void {
    _.remove(this.overrideCodes, ['code', code]);
    this.overrideCodes.push(
      new ResponseCode(
        code,
        '<hr/>' + message,
        header,
        type
      )
    );
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    return new ResponseCodes(this.overrideCodes);
  }

  /** GET Activity Type List from the server */
  public getActivityTypeList(): Observable<ActivityTypesListInterface> {
    // return cache result to eliminate delay which can cause list bubble chart not picking up the coloring
    if (PromoService.promoTypesCache && PromoService.promoTypesCache.types.length) {
      return of(PromoService.promoTypesCache);
    }
    return this.get({endpoint: PromoService.appPromoTypes}).pipe(tap(types => PromoService.promoTypesCache = types));
  }

  public getReport(reportId: string, type = ActivityResultType.NO_RESULTS): Observable<PromoActivity> {
    return this.activityService.getActivity<PromoActivity>({
      id: reportId,
      resultType: type
    });
  }

  public rerunPromoActivity(activity: Activity): Observable<PromoActivity> {
    return this.activityService.rerun(activity).pipe(
      map(activityJson => ActivityFactory.createActivity<PromoActivity>(activityJson))
    );
  }

  public getUpcCount(params: UpcCountParams): Observable<HttpResponse<number>> {
    return this.create({
      endpoint: PromoService.upcCount,
      body: params,
      suppressNotification: true
    });
  }

  public setRendererFramework(factColDef: ColDef, ref: CmsMetric): void {
    if (ref.aggType === AggregationType.ASYNC) {
      factColDef.cellRenderer = AsyncCellComponent;
    }
  }

  public createForm(fv?: PromoFormJson ): PromoFormData {
    const formData = new PromoFormData();
    if (fv) {
      if (fv.comparisonGroups) {
        formData.comparisonGroups = fv.comparisonGroups.map(c => new ComparisonGroup(c));
      }
      if (fv.locationFilters) {
        formData.locationFilters = fv.locationFilters.map(f => new FilterSelection(f));
      }
      if (fv.promoType) {
        formData.promoType = fv.promoType;
      }
      if (fv.sourcesOfVolume) {
        formData.sourcesOfVolume = fv.sourcesOfVolume.map(sov => new SourceOfVolume(sov));
      }
      formData.customPromoDateRange = fv.customPromoDateRange || {
        periodStart: '',
        periodEnd: '',
        customPeriodName: 'Custom Date Range'
      };
      formData.isCloned = fv.isCloned;
      formData.name = fv.name;
      formData.prePromoDateRange = fv.prePromoDateRange;
      formData.promoDateRange = fv.promoDateRange;
      formData.promoDateRangeInterface = DatesService.toDateRangeInterface(fv.promoDateRange);
      formData.promoDateRangeInterface.type = DateRangeInterfaceType.POPULATED;
      formData.schema = fv.schema;
      formData.upcFilters = fv.upcFilters.map(f => new FilterSelection(f));
      formData.upcCount = fv.upcCount;
      formData.yoyDateRange = fv.yoyDateRange;
    }

    return formData;
  }

  private applyWeights(arr: any[], merged: boolean) {

    function lookupWeight(datum: any, factKey: string, weightKey: string) {
      if (datum[factKey] && datum[weightKey]) {
        datum[factKey].weight = datum[weightKey].val;
      }
    }

    // Weights need to be generated for each average fact present (if they exist).
    // These values already exist as other facts, so copy them over
    arr.forEach(datum => {
      if (merged) {
        for (let period in PromoPeriods) {
          lookupWeight(datum, PromoService.generateValKey([period, 'AVG_BASKET_AMOUNT']), PromoService.generateValKey([period, KPI.NUM_TRANSACTIONS]));
          lookupWeight(datum, PromoService.generateValKey([period, 'AVG_BASKET_ITEM_CNT']), PromoService.generateValKey([period, KPI.NUM_TRANSACTIONS]));
          lookupWeight(datum, PromoService.generateValKey([period, 'AVG_PRICE']), PromoService.generateValKey([period, KPI.TOTAL_QUANTITY]));
        }
      } else {
        lookupWeight(datum, 'AVG_BASKET_AMOUNT', KPI.NUM_TRANSACTIONS);
        lookupWeight(datum, 'AVG_BASKET_ITEM_CNT', KPI.NUM_TRANSACTIONS);
        lookupWeight(datum, 'AVG_PRICE', KPI.TOTAL_QUANTITY);
      }
    });
  }

  private processJobToDatamap(job: AppResponseDataset): any {
    const sr = job.getResponse();
    const dims = sr.getDimensions();
    const facts = sr.getFacts();
    const dataMap: any = {};

    const basketSizeIndex = dims.findIndex(d => d.field === PromoDimensionKeys.BASKET_SIZE);
    if (~basketSizeIndex && !job['processed']) {
      const basketSizes = (job.getResponse()).getDimensionValues()[basketSizeIndex];
      basketSizes.forEach((size, i) => {
        if (Number(size) === PromoConfig.PROMO_ITEMS_THRESHOLD) {
          basketSizes[i] += '+';
        }
        basketSizes[i] += pluralize(' Item', Number(size));
      });

      (job.getResponse()).getValues().sort((a, b) => {
        const _a = basketSizes[a[basketSizeIndex]];
        const _b = basketSizes[b[basketSizeIndex]];
        return UtilsService.isLessThan(_a, _b);
      }); // Sorts the values by basket size - results in an numerically ordered array in its default "un-sorted" state
      job['processed'] = true;
    }

    sr.getValues().forEach(v => {
      // Iterate thru each row in valuesMatrix
      let pointer = dataMap; // create pointer
      let periodKey: string;
      v.forEach((e, i) => {
        if (dims[i]) {
          // process dimensions
          const dV = sr.getDimensionValues()[i][e];

          if (dims[i].field === PromoDimensionKeys.PERIOD) {
            // Special case for PERIOD dim - remember the period key
            periodKey = dV;
          } else {
            // Other dimensions - create another level in the data map
            pointer[dV] = pointer[dV] || {};
            pointer = pointer[dV];
          }
        } else {
          // process facts
          const factIndex = i - dims.length; // Get the fact
          const valKey = PromoService.generateValKey([periodKey, facts[factIndex].id]);
          pointer[valKey] = {val: Number(e)};
        }
      });
    });

    return dataMap;
  }

  public exportSheetAsExcel(activity: Activity, gridVisualOptions: VisualOptions) {
    ExcelService.saveSheetAsXLSX(activity.getName(), gridVisualOptions);

    this.mixpanelService.track(MixpanelEvent.REPORTS_EXPORTED, {
      'Application': this.config.getApplication().display,
      'Report ID': activity.getId(),
      'Report Name': activity.getName(),
      'File Type': 'xlsx',
      'Export Type': 'sheet'
    });
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CloudExportable } from 'app/core/modules/cloud-export/models/cloud-export.interface';
import { filter, take, takeWhile } from 'rxjs';
import {
  ExcelService,
  VisualOptions
} from '@siq-js/visual-lib';

@Injectable({
  providedIn: 'root'
})
export class CloudExportService {
  public static setReadyForExportSubscription(readyForExport$: BehaviorSubject<boolean>, component: CloudExportable) {
    readyForExport$.pipe(
      filter(ready => !!ready === true),
      takeWhile(r => false, true)
    )
    .subscribe(resp => {
      console.log('page is ready! attaching cloud data...'); // this message left in to aid cloud-export debugging
      component.attachCloudData([ component.gridParams.gridVisualOptions]);
    });
  }

  public static readonly Data$: BehaviorSubject<Blob> = new BehaviorSubject(null);
  // public static readonly CellThreshold = 500000;

  /**
   * Takes in a component & determines whether it is exportable or not
   * Currently just checks the query params for the static queryParamKey
   * This is done to prevent components from generating cloud-export data unnecessarily
   * @param component: should implement CloudExportable
   */
  public static async isExportable(component: CloudExportable): Promise<boolean> {
    return new Promise<boolean>(((resolve, reject) => {
      component.route.url
        .pipe(take(1))
        .subscribe(url => {
          resolve(!!url.find(urlSegment => urlSegment.path === 'export'));
        }, () => reject());
    }));
  }

  constructor() {}

  /**
   *
   * @param collection
   * Standard Cloud Export functionality to attach the data
   */
  public attachCloudData(collection: VisualOptions[]) {
    collection = collection as VisualOptions[];
    combineLatest(
      collection.map(v => v.gridConfig.gridReadyForExport$)
    )
    .subscribe(readyArr => {
      // Only continue if all elements in arr are true
      if (!readyArr.reduce((p, c) => p && c)) return;

      this.attachCloudDataWorkbook(collection);
    });
  }

  /**
   *
   * @param collection
   * Standard Cloud Export functionality to attach the data to the Excel workbook
   * Components that call this can prepend/append their own functionality as needed
   * This function is for single sheet apps: Affinities, Assortment, DayParts, Promo
   * Report Builder use cloudExportService.setData() function from reportBuilderResult page.
   */
  public async attachCloudDataWorkbook(collection: VisualOptions[]) {
    const params = ExcelService.getExcelExportParams(collection[0]); // No filename here, for cloud export filename is from cloud function.
    this.setData(<Blob>collection[0].apiRef().grid.api.getDataAsExcel(params));
  }

  public setData(data: Blob) {
    CloudExportService.Data$.next(data);
  }
}

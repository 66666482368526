import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseSiqComponent, NotificationService } from '@siq-js/angular-buildable-lib';
import { CmsField } from '@siq-js/cms-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { ReportBuilderFormFilterComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form-filter/report-builder-form-filter.component';
import { BehaviorSubject, Subject, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs';
import { SameStoreSalesService } from 'app/siq-forms/modules/same-store-sales/services/same-store-sales.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RetailerDisplayPipe } from 'app/core/pipes/retailer-display.pipe';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

type Params = {
  sssChecked: boolean;
  notification: boolean;
}

@Component({
  selector: 'siq-js-same-store-sales-selection',
  templateUrl: './same-store-sales-selection.component.html',
  styleUrls: ['./same-store-sales-selection.component.scss']
})
export class SameStoreSalesSelectionComponent extends BaseSiqComponent implements OnInit {
  @Input() isInsideFilterModal: boolean;
  @Input() globalFilter: ReportBuilderFormFilterComponent; // optional, because it is not available in EditFilterModalComponent
  @Input() schemaController$: BehaviorSubject<string>;
  @Input('selectedIds') selectedIds$: BehaviorSubject<Set<string>>;
  @Output() public checkChanged = new EventEmitter<boolean>();

  public checked: boolean = false;
  public hideMRV = false; // SSS is disabled for Multi Retailer and STANDARD Platform Mode

  public tooltip: {
    msg: string;
    read: boolean;
  } = null;

  // During fast switching RB drafts, previous draft ongoing call can finish on current draft. Use switchMap to cancel previous call when land on new draft
  // See details in https://bitbucket.org/pdisoftware/mcs-ic-fe-app-multi/pull-requests/751
  private obs$: Subject<any> = new Subject();

  constructor(
    private notificationService: NotificationService,
    private sameStoreSalesService: SameStoreSalesService,
    ) {
    super();
    this.obs$
    .pipe(
      takeUntil(this.unsub$),
      switchMap((params: Params) => 
        this.sameStoreSalesService.getOpenStoreIds(params.sssChecked)
        .pipe(
          map(storeIds => {
            return {
              storeIds: storeIds,
              params: params
            }
          })
        )
      )
    )
    .subscribe(
      {
        next: res => {
          const {storeIds, params} = res;
          // when fast switching RB drafts, ie. before sss call finishes, switched from sss checked draft to unchecked draft,
          // this.checked and params.sssChecked can go out of sync and previous result storeids can get applied to this draft.
          // this check prevents that case.
          if (this.isInsideFilterModal || this.checked === params.sssChecked) {
            this.applyResult(storeIds, params.sssChecked, params.notification);
          }
        },
        error: err => {
          this.notificationService.error(`Failed to get open store ids for ${SameStoreSalesService.schema}`, 'Same Store Sales');
          SameStoreSalesService.checked$.next(false);
        }
      }
    );
  }

  ngOnInit(): void {
    // SSS is disabled for Multi Retailer and STANDARD Platform Mode
    if (this.schemaController$) {
      this.schemaController$.pipe(takeUntil(this.unsub$)).subscribe(schema => {
        this.hideMRV = schema === EnvConfigService.getConfig().primaryEntity || EnvConfigService.isStandardMode();
      });
    } else {
      this.hideMRV = EnvConfigService.isStandardMode();
    }
    
    this.tooltip = {
      msg: 'Open Stores: Store transactions in past 90 days exceed 50% of the average\nClosed Stores: Store transactions in the past 90 days fall below 50% of the average\nN/A: Missing data',
      read: false
    };
    
    SameStoreSalesService.checked$
    .pipe(
      takeUntil(this.unsub$),
      distinctUntilChanged()
    )
    .subscribe(checkedState => {
        this.checked = checkedState;
        this.checkChanged.emit(checkedState);
      }
    );

    if (this.selectedIds$) {
      this.selectedIds$ // If user modifies in any way (check/uncheck a row value, or (bulk)Upload multiple, The SSS checkbox gets UNCHECKED.
      .pipe(takeUntil(this.unsub$))
      .subscribe((ids: Set<string>) => {
        const storeIds = SameStoreSalesService.openStoreIds.get(SameStoreSalesService.currModalRetailer);
        this.checked = !!storeIds?.size && UtilsService.areSetsEqual(ids, storeIds);
      });
    }
  }

  public onSelectedIdsApplied() {
    SameStoreSalesService.setChecked(this.checked);
  }

  public change(e: MatCheckboxChange) {
    this.checked = e.checked;
    this.apply(e.checked);
    if (!this.isInsideFilterModal){
      SameStoreSalesService.setChecked(e.checked);
    }
  }

  public apply(sssChecked: boolean, notification = true) {
    this.obs$.next({
      sssChecked: sssChecked,
      notification: notification
    } as Params)
  }

  private applyResult(storeIds: Map<string, Set<string>>, sssChecked: boolean, notification: boolean) {
    // clear, replace or add new filter
    if (this.globalFilter) { // Report Builder
      this.setGlobalFilterModel(storeIds, sssChecked);
    } else {
      this.selectedIds$.next(new Set(storeIds.get(SameStoreSalesService.currModalRetailer))); //new Set is used to restore the actual SSS storeIds set
    }
    if (notification) {
      const checkedMsg = storeIds.get(SameStoreSalesService.schema)?.size
                          ? 'All stores matching the defined <b>Same Store Sales</b> criteria have been applied as filters.'
                          : 'There are no stores that match the selected <b>Same Store Sales</b> criteria. Please modify (reduce) the date range to try again.';
      const msg = sssChecked ? checkedMsg : 'The <b>Same Store Sales</b> has been unselected and all related <b>Store Id</b> filters have been removed.';
      this.notificationService.info(msg, 'Same Store Sales');
    }
  }

  private setGlobalFilterModel(storeIds: Map<string, Set<string>>, sssChecked: boolean) {
    let model = this.globalFilter.getModel();
    const storeIdFilterIndex = model.findIndex(fs => fs.field.field === SameStoreSalesService.STORE_ID);
    // clear existing storeId filter(s)
    model = model.filter(fs => fs.field.field !== SameStoreSalesService.STORE_ID);

    if (sssChecked) {
      const res: FilterSelection[] = [];    
      storeIds.forEach((storeIds, retailer) => {
        if (storeIds.size) {
          const field: CmsField = CmsService.get().fields.find(f => f.field === SameStoreSalesService.STORE_ID && f.retailer === retailer);
          // Prepare the JSON to pass into the FilterSelection constructor
          const filterSelectionJson: FilterSelectionJson = {
            id: field.id,
            include: true,
            nulls: false,
            values: Array.from(storeIds)
          };
          // Determine "mode" of platform and if Multi-Retailer mode add 'context'
          if (this.schemaController$.getValue() === EnvConfigService.getConfig().primaryEntity) {
            filterSelectionJson.context = ': ' + new RetailerDisplayPipe().transform(field.retailer);
          }
          res.push(new FilterSelection(filterSelectionJson));
        }
      })

      if (storeIdFilterIndex >= 0) {
        // editing to keep store_id filter index
        model.splice(storeIdFilterIndex, 0, ...res);
      } else {
        model.splice(model.length, 0, ...res);
      }
    }
    this.globalFilter.setModel(model);
  }
}

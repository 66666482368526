import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'siq-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  public errMsg: string;
  public commonErrMsg = "It appears that the page you're trying to reach doesn't exist.";
  constructor(private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { errMsg: string };
    this.errMsg = state?.errMsg;
    if (_.isNil(this.errMsg)) {
      this.errMsg = this.commonErrMsg;
    }
  }
  ngOnInit() {
  }
}

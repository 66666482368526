import { Component, Input, OnInit } from '@angular/core';
import { ComparisonGroup } from 'app/siq-applications/modules/promo/models/comparison-group';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'siq-js-sov-holder',
  templateUrl: './sov-holder.component.html',
  styleUrls: ['./sov-holder.component.scss'],
})
export class SovHolderComponent implements OnInit {
  @Input() comparisonGroups$: BehaviorSubject<ComparisonGroup[]>;
  @Input() initSovs: SourceOfVolume[];
  @Input() schema$: BehaviorSubject<string>;
  @Input() sovs$: BehaviorSubject<SourceOfVolume[]>;

  public sovMax = PromoConfig.MAX_SOV;
  public sovArr: SourceOfVolume[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.initSovs) {
      this.sovArr = this.initSovs;
      this.sovs$.next(this.initSovs);
    }
  }

  public onAddSOV() {
    const sov = new SourceOfVolume();
    // set dimension in sov component
    this.sovArr.push(sov);
    this.sovs$.next(this.sovArr);

  }
  public onRemoveSOV(id: string) {
    this.sovArr = this.sovArr.filter(sov => sov.id !== id);
    this.sovs$.next(this.sovArr);
  }

}

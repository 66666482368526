import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import * as _ from 'lodash';
import { ApplicationHash } from '@siq-js/core-lib';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';

export class ReportActivity extends Activity {

  constructor(activity?: Activity) {
    super(activity);

    this.sheets = this.sheets || [];
  }

  isComplete(): boolean {
    return !_.find(this.sheets, s => s.getStatus() === ActivityStatus.RUNNING);
  }

  isReportActuallySheet(): boolean {
    return this.getAppId() === ApplicationHash.REPORT_BUILDER_SHEET;
  }

  isViewed(): boolean {
    return this.sheets.length === 0 || this.sheets[0].getMetaDataByKey('read') === 'true';
  }

  getStatus(): ActivityStatus {
    const statusCounter = {
      draft: ReportBuilderService.getCountOfDraftsInReportBuilderActivity(this.getMetaDataByKey('state') || '[]'),
      running: this.sheets.filter(s => s.getStatus() === ActivityStatus.RUNNING).length,
      ready: this.sheets.filter(s => s.getStatus() === ActivityStatus.READY).length,
      error: this.sheets.filter(s =>
        [ActivityStatus.ERROR, ActivityStatus.PREVIEW_RUNNING, ActivityStatus.PREVIEW_READY].includes(s.getStatus())
      ).length,
      expired: this.sheets.filter(s => s.getStatus() === ActivityStatus.EXPIRED).length,
    };

    if (statusCounter.running > 0) return ActivityStatus.RUNNING;
    if (statusCounter.error > 0) return ActivityStatus.ERROR;
    if (this.accessGroupChanged) {
      return ActivityStatus.ALERT;
    }
    if (statusCounter.expired > 0) return ActivityStatus.EXPIRED;

    if (this.sheets.length > 0 && statusCounter.draft === 0 ) {
      return ActivityStatus.READY;
    }else {
      return ActivityStatus.EMPTY;
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ValidationMessage, ValidationMessageStatus } from 'app/core/models/validation-message';

@Component({
  selector: 'siq-js-step-form-message',
  templateUrl: './step-form-message.component.html',
  styleUrls: ['./step-form-message.component.scss']
})
export class StepFormMessageComponent implements OnInit {
  @Input() validationMessage: ValidationMessage;

  public validationMessageStatus = ValidationMessageStatus;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseStepperComponent } from 'app/core/components/stepper/base-stepper.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { CoreConstants, GroupedArray, GroupedArrayFunctions, WeekEndingDay } from '@siq-js/core-lib';
import { CmsField, CmsConfig } from '@siq-js/cms-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { AffinitiesFormData } from 'app/siq-applications/modules/affinities/models/form/affinities-form-data.model';
import { ValidationMessageStatus, ValidationMessage } from 'app/core/models/validation-message';
import { AffinityType } from 'app/siq-applications/modules/affinities/models/form/affinities-type.model';
import { EntitySelectorStatus } from 'app/siq-forms/components/entity-selector/entity-selector.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { filter } from 'rxjs';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { AffinityFilterType } from 'app/siq-applications/modules/affinities/models/form/affinities-filter-type.model';
import { AffinitiesConfig } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { AffinitiesQueryModeModalComponent } from 'app/siq-applications/modules/affinities/components/affinities-query-mode-modal/affinities-query-mode-modal.component';
import * as _ from 'lodash';
import { QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { FilterGroupComponent } from 'app/filter/components/filter-group/filter-group.component';
import { FilterService } from 'app/filter/services/filter.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';

@Component({
  selector: 'siq-js-affinities-stepper',
  templateUrl: './affinities-stepper.component.html',
  styleUrls: ['./affinities-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class AffinitiesStepperComponent extends BaseStepperComponent implements OnInit {
  @Input() formData: AffinitiesFormData;
  @Input() queryMode: QueryModeComponent;
  @Input() overrideSchema: string;
  @Input() submit: () => Observable<any>;
  @ViewChild('transactionFilters') transactionFilters: FilterGroupComponent; // custom FilterGroupComponent for global filters

  public affinityFilterType = AffinityFilterType;
  public affinityOption = AffinityType.STANDARD;
  public affinityType = AffinityType;
  public entityData: GroupedArray<CmsField>; // original copy of grouped CmsField data coming from CMS
  public filteredData: GroupedArray<CmsField>; // filtered version of entityData
  public initModel: CmsField[] =  [];
  public isDateRangeValid: boolean = false;
  public stepOneError: boolean; // show error if step one is not filled
  public stepValidationMessages: ValidationMessage[] = [null, null, null, null]; // validation message for each step

  constructor(
    protected asyncStatusService: AsyncStatusService,
    private affinitiesService: AffinitiesService,
    private router: Router,
    private utilsService: UtilsService,
    private datesService: DatesService,
    private mixpanelService: MixpanelService,
    private config: AffinitiesConfig
  ) {
    super(asyncStatusService);
  }

  /**
   * Function called when entity-selector component emits values
   * @param entitiesStatus: status of the entity-selector component
   */
  public affinitiesTypeChanged(entitiesStatus: EntitySelectorStatus<CmsField>) {
    const { valid, selectedEntities } = entitiesStatus;
    if (valid) {
      this.formData.affinityType = selectedEntities[0];
      let valMsg = `${selectedEntities[0].display}`;
      if (this.affinityOption === AffinityType.ASYMMETRIC) {
        this.formData.secondaryAffinityType = selectedEntities[1];
        valMsg = `Left: ${selectedEntities[0].display}, Right: ${selectedEntities[1].display}`;
      }
      this.stepOneError = false;
      this.validateForm();
      this.updateValidationMessage(0, ValidationMessageStatus.VALID, valMsg);
    } else {
      this.formData.affinityType = null;
      this.formData.secondaryAffinityType = null;
      this.stepOneError = true;
      this.validateForm();
      this.updateValidationMessage(0, ValidationMessageStatus.INVALID, 'Select Correct Affinity Type(s)');
    }
  }

  /**
   * Called when affinity radio button selection changes
   * @param val incoming option: 'standard'/'asymmetric'
   */
  public affinityOptionChanged(val: string) {
    // Clear data and inputs
    this.formData.affinityType = null;
    this.formData.secondaryAffinityType = null;
    this.initModel = [];
    const valMsg = val === AffinityType.STANDARD ? 'Select an Affinity Type' : 'Select Left and Right Affinity Types';
    this.updateValidationMessage(0, ValidationMessageStatus.UNSET, valMsg);
    this.stepOneError = true;
    this.validateForm();
  }

  // function called when dates selected from the date selector
  public dateChanged(dates: DateRangeInterface) {
    this.formData.dateRanges = dates;
    let start: string, end: string;
    start = dates.begin ? DatesService.format(dates.begin, CoreConstants._shortDate) : '';
    end = dates.end ? DatesService.format(dates.end, CoreConstants._shortDate) : '';
    this.updateValidationMessage(1, ValidationMessageStatus.VALID, `${start} through ${end}`);
    this.isDateRangeValid = dates.isDateRangeValid;
    this.validateForm();
  }

  /**
   * Function called when FilterGroup changes
   * @param filterModel: FilterSelection[]
   * @param group: used to identify which FilterGroup changed. 'transaction' | 'left' | 'right'
   */
  public filterGroupChanged(filterModel: FilterSelection[], group: AffinityFilterType) {
    switch (group) {
      case AffinityFilterType.TRANSACTION:
        this.datesService.updateCommunalDateRange(this.queryMode.schema$.getValue(), this.formData.filters, filterModel);
        this.formData.filters = filterModel;
        if (!this.formData.filters.length) {
          this.updateValidationMessage(2, ValidationMessageStatus.UNSET, 'Select Transaction Filter(s)');
        } else {
          this.updateValidationMessage(2, ValidationMessageStatus.VALID, `${this.formData.filters.length} Transaction Filter(s) Selected`);
        }
        break;
      case AffinityFilterType.LEFT:
        this.formData.leftFilters = filterModel;
        if (!this.formData.leftFilters.length && !this.formData.rightFilters.length) {
          this.updateValidationMessage(3, ValidationMessageStatus.UNSET, 'Select Left & Right Filters');
        } else {
          this.updateValidationMessage(3, ValidationMessageStatus.VALID, 'Item Filters Selected');
        }
        break;
      case AffinityFilterType.RIGHT:
        this.formData.rightFilters = filterModel;
        if (!this.formData.leftFilters.length && !this.formData.rightFilters.length) {
          this.updateValidationMessage(3, ValidationMessageStatus.UNSET, 'Select Left & Right Filters');
        } else {
          this.updateValidationMessage(3, ValidationMessageStatus.VALID, 'Item Filters Selected');
        }
        break;
      default:
        console.warn('AffinitiesFormComponent -> filterGroupChanged: Invalid Filter Group');
    }
  }

  public init(): void {
    this.entityData = GroupedArrayFunctions.filter(CmsService.get().fieldsOrder, el => el.table === 'product');
    if (this.formData.isCloned) {
      this.processClonedForm();
    } else {
      this.stepOneError = true;
      this.validateForm();
    }
    this.purgeFilterValues();
    this.queryMode.schema$
      .pipe(filter(schema => !!schema))
      .subscribe(schema => {
        this.formData.schema = schema;
        this.filterFormData(schema);
      });
  }

  public itemsMapFn = (fields: CmsField[]) => fields.filter(f => f.table === 'product'); // mapping fn for item filters left & right

  // Submit form
  public onSubmit() {
    this.disableSubmitBtn = true;

    // The submit fn returns an Observable to which we can subscribe
    this.submit()
      .subscribe(res => {
        // redirect back to the list
        this.router.navigate(['app/affinities']);
      }, err => {
        console.log('Error submitting AffinitiesForm: %O', err);
        this.validateForm();
      });
  }

  public transactionMapFn = (fields: CmsField[]) => fields.filter(f => f.table === 'locations' || f.table === 'customer' || f.filter === 'RETAILER' || f.id.includes('loyalty_status')); // mapping fn for transaction filters

  public weekEndingDayChanged(we: WeekEndingDay) {
    if (we) {
      this.formData.weekEndingday = we;
    }
  } 
  
  /**
   * Filter form input data when schema changes
   * @param schema: new schema
   */
  private filterFormData(schema: string) {
    // left and right entityData
    this.filteredData = GroupedArrayFunctions.filter(this.entityData, entity => {
      return entity.retailer === schema || entity.retailer === this.primaryEntity || entity.retailer === CmsConfig.CORE_SCHEMA_ID;
    });
    this.datesService.datePickerSchema$.next(DatesService.getActiveSchemas(schema, [...this.formData.filters, ...this.formData.leftFilters, ...this.formData.rightFilters]));
  }

  // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
  private purgeFilterValues() {
    FilterService.purgeFilterValues(this.transactionFilters, this.formData.filters);
  }

  // Process form when current form is cloned
  private processClonedForm(): void {
    const currSchema = UtilsService.getQueryModeDefaultSchema(this.overrideSchema);
    // mark purging of second activity
    let purgeSecondAffinity = false;
    if (this.affinitiesService.clonedActivity.accessGroupChanged && !this.formData.isValidSchema(currSchema)) { // AG has changed and form no longer valid
      this.utilsService.openModal(
        AffinitiesQueryModeModalComponent,
        {
          data: _.cloneDeep(this.formData),
          schema: this.queryMode.schema$.getValue(),
          userGroupChanged: true
        } as QueryModeModalData
      );
      // Clear invalid affinity types
      // Invalid Filters don't get cleared here. Instead in the filterGroup component
      if (!CmsService.isValidField(this.formData.affinityType, currSchema)) {
        this.formData.affinityType = null;
      }
      if (this.formData.secondaryAffinityType && !CmsService.isValidField(this.formData.secondaryAffinityType, currSchema)) {
        this.formData.secondaryAffinityType = null;
        purgeSecondAffinity = true;
      }
      // reset form schema
      this.formData.schema = currSchema;
      if (!this.formData.affinityType) {
        this.stepOneError = true;
        this.validateForm();
      }
    } else { // form still valid
      this.stepOneError = false;
      this.validateForm();
    }
    if (this.formData.affinityType) {
      if (!this.formData.secondaryAffinityType && !purgeSecondAffinity) { // standard & purge of secondary affinity didn't happened
        this.affinityOption = AffinityType.STANDARD;
        this.initModel = [this.formData.affinityType];
      } else { // asymmetric
        this.affinityOption = AffinityType.ASYMMETRIC;
        this.initModel = [this.formData.affinityType, this.formData.secondaryAffinityType];
      }
    }
    this.mixpanelService.track(MixpanelEvent.REPORTS_CLONED, {
      'Application': this.config.getApplication().display,
      'Cloned Activity ID': this.affinitiesService.clonedActivity.getId(),
      'Name': this.formData.name,
      'JSON': this.formData.toJson(),
      'Type': 'Report'
    });
    this.affinitiesService.clonedActivity = null; // clear activity
  }

  private validateForm() {
    this.disableSubmitBtn = this.stepOneError || !this.isDateRangeValid;
  }

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AccessGroup } from 'app/access-group/models/access-group.model';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { AppComponent } from 'app/app.component';
import { DeleteCellCallbackFn, DeleteCellComponent } from 'app/core/components/cell-renderers/delete-cell/delete-cell.component';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { Router } from '@angular/router';
import { HeaderCellSortableComponent } from 'app/core/components/cell-renderers/header-cell-sortable/header-cell-sortable.component';
import { AgGridAngular, ColDef, FilterNames, GridOptions, GridReadyEvent, GridService } from '@siq-js/visual-lib';
import * as _ from 'lodash';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { debounceTime, first, takeUntil } from 'rxjs';
import { ConfirmationResponse } from 'app/core/components/confirmation-modal/confirmation-response.interface';
import { ConfirmationModalConfig } from 'app/core/components/confirmation-modal/confirmation-modal-config.interface';
import { ChipListCellComponent } from 'app/core/components/cell-renderers/chip-list-cell/chip-list-cell.component';
import { AuthService } from 'app/auth/services/auth.service';
import { AccessGroupCriteriaRendererComponent } from 'app/access-group/components/access-group-list/renderers/access-group-criteria-renderer/access-group-criteria-renderer.component';
import { StatusRendererComponent } from 'app/access-group/components/access-group-list/renderers/status-renderer/status-renderer.component';
import { FilterService } from 'app/filter/services/filter.service';
import { ThemesService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-access-group-list',
  templateUrl: './access-group-list.component.html',
  styleUrls: ['./access-group-list.component.scss']
})
export class AccessGroupListComponent implements OnDestroy, OnInit {
  @ViewChild('accessGroupListGrid') grid!: AgGridAngular;
  public accessGroupStream$: BehaviorSubject<AccessGroup[]>;
  public agTheme: string;
  public filterObs$: Subject<any> = new Subject();
  public gridOptions: GridOptions;
  public isAdminGroup = false;
  public isLoading = false;

  private sort$: Subject<any> = new Subject<any>(); // gets set by child header components
  private unsub$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private accessGroupService: AccessGroupService
  ) {
    // AccessGroupService is injected so that code in its constructor is executed
    this.accessGroupStream$ = AccessGroupService.createStream(list =>  this.mapFilters(list));
    AccessGroupService.refresh();

    this.accessGroupStream$
    .pipe(takeUntil(this.unsub$))
    .subscribe(accessGroups => {
      this.accessGroupService.poll(accessGroups); // Poll during setup if needed
      if (_.get(this.grid, 'api')) {
        this.setListData();
      }
    });
  }

  create(): void {
    this.router.navigate(['./user-groups/~/form']);
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    if (this.isLoading) {
      EmitterService.get('topLoading').emit(false);
    }
  }

  async ngOnInit() {
    this.isAdminGroup = await AuthService.CurrentUser$.getValue().isAdmin();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });

    this.setupGrid();
  }

  private generateCriteriaColDef(): ColDef {
    const colDef: ColDef = AccessGroupCriteriaRendererComponent.ColDef;
    colDef.field = 'criteria';
    return colDef;
  }

  private generateDefaultColDef(name: string, field: string, sort?: string): ColDef {
    const colDef: ColDef = {
      headerName: name,
      field: field,
      sort: sort,
      cellClass: 'simple-cell',
      headerComponent: HeaderCellSortableComponent,
      headerComponentParams : {
        parent: this
      },
      filter: (FilterNames.TEXT).toString(),
      menuTabs: ['filterMenuTab'],
      filterParams: {
        clearButton: true
      },
      width: 250,
      minWidth: 250,
      resizable: false,
      suppressSizeToFit: true
    } as ColDef;
    return colDef;
  }

  private generateDeleteColDef(): ColDef {
    const deleteFn: DeleteCellCallbackFn = (id: number) => {
      const accessGroup = _.find(this.accessGroupStream$.getValue(), {id: id});

      const remove$ = new Observable(observer => {
        this.accessGroupService.removeAccessGroup(id, accessGroup);
        observer.next();
        observer.complete();
      });

      this.utilsService.openModal(
        ConfirmationModalComponent,
        {
          header: 'Delete User Group?',
          body: `Are you sure you want to delete the User Group "${accessGroup.getDisplayName()}"?`,
          confirmationObs$: remove$
        } as ConfirmationModalConfig,
        UtilsService.MODAL_CONFIG_SMALL
      )
      .afterClosed()
      .pipe(first())
      .subscribe(
        (response: ConfirmationResponse) => {
          if (response && response.accepted) {
            AccessGroupService.refresh();
          }
        },
        (error) => {
          console.error(error);
        }
      );
    };

    const canDeleteFn: DeleteCellCallbackFn = (id: number) => {
      const accessGroup = _.find(this.accessGroupStream$.getValue(), {id: id});
      const accessGroupMembers = _.get(accessGroup, 'usersGroup', []);
      return _.isEmpty(accessGroupMembers) && accessGroup.getStatus();
    };

    return DeleteCellComponent.generateDeleteColDef('id', deleteFn, canDeleteFn);
  }

  private generateNumUsersColDef(): ColDef {
    return ChipListCellComponent.generateColDef('Number of Users', 'usersGroup', {
      headerComponentParams: {
        parent: this
      },
      cellRendererParams: {
        displayListLength: true
      },
      width: 180,
      minWidth: 180,
      resizable: false,
      suppressSizeToFit: true,
      valueFormatter: (params) => {
        return params.value.length; // count of items in array
      }
    });
  }

  private generateStatusColDef(): ColDef {
    StatusRendererComponent.setSortable(this);
    const colDef: ColDef = StatusRendererComponent.getColDef(StatusRendererComponent);
    colDef.field = 'status';
    colDef.width = 100;
    colDef.minWidth = 100;
    return colDef;
  }

  /**
   * AccessGroups returned by the endpoint have filters in format of SiqFilter.
   * These must be converted to FilterSelection to proceed.
   * @param jsonList
   */
  private mapFilters(jsonList: any[]) {
    return jsonList.map(accessGroup => {
      if (accessGroup.filters) {
        accessGroup.filters = (accessGroup.filters as any[]).map(f => {
          if (_.isNil(f.values)) {
            f = FilterService.convertSiqFilterToFilterSelection(f);
          }
          return f;
        });
      }
      return accessGroup;
    });
  }

  private setColDefs(): ColDef[] {
    const colDefs: ColDef[] = [];
    colDefs.push(this.generateDefaultColDef('User Groups', 'displayName', 'asc'));
    colDefs.push(this.generateStatusColDef());
    colDefs.push(this.generateCriteriaColDef());
    colDefs.push(this.generateNumUsersColDef());

    if (this.isAdminGroup) {
      colDefs.push(this.generateDeleteColDef());
    }

    return colDefs;
  }

  private setListData() {
    this.accessGroupService.autoSizeColumns(this.grid.api);
    this.grid.api.updateGridOptions({ rowData: this.accessGroupStream$.getValue() });

    // TEMPORARY - FOR QA
    console.log('>>> For QA; Access Groups: %O', this.accessGroupStream$.getValue());
  }

  private setupGrid(): void {
    const component: AccessGroupListComponent = this;

    this.gridOptions = {
      sideBar: false,
      headerHeight: 52,
      rowHeight: 58,
      suppressContextMenu: true,
      domLayout: 'autoHeight',
      suppressCellFocus: true,
      columnDefs: this.setColDefs(),
      animateRows: true,
      suppressDragLeaveHidesColumns: true,
      onGridReady: (grid: GridReadyEvent) => {

        this.sort$.next(this.grid.api.getColumnState());
        this.accessGroupService.autoSizeColumns(this.grid.api);

        AppComponent.resize$
        .pipe(
          debounceTime(100),
          takeUntil(this.unsub$)
        )
        .subscribe(() => this.accessGroupService.autoSizeColumns(this.grid.api));

        const globalListener = (event, args) => {
          if (!_.get(component.gridOptions, 'api')) return;
          switch (event) {
            case 'filterChanged':
              component.filterObs$.next(grid.api.getFilterModel());
              break;
          }
        };

        grid.api.addGlobalListener(globalListener);
        AccessGroupService.refresh();
      }
    };

    if (this.isAdminGroup) {
      this.gridOptions.onRowClicked = (event) => {
        if (!event.data.status) return;
        this.router.navigate(['./user-groups/' + event.node.data.id + '/form']);
      };
    }
  }
}

<div class="error-overlay">
  <mat-toolbar>
    <div class="align-left">
      <span class="application-name-in-reports">{{ reportActivityName}}</span>
    </div>
    <div class="align-right">
      <siq-js-activity-grid-options-menu
        (clickEvent)="clickEvent.emit($event)"
        [config]="menuItems"
      ></siq-js-activity-grid-options-menu>
    </div>
  </mat-toolbar>

  <mat-icon>error</mat-icon>
  <p class="main">
    Error Loading Report
  </p>
  <p *ngIf="appErrorMessage">
    {{ appErrorMessage.message }}
  </p>
  <p *ngIf="!appErrorMessage">
    App activity failed, please try again.
  </p>
</div>

<mat-dialog-content>
  <div *ngIf="model">
    <p>
      <b>Drilling into</b>
      <mat-chip-list>
        <mat-chip *ngFor="let entry of model.dimsBreakdown">{{ entry }}</mat-chip>
      </mat-chip-list>
    </p>
    <siq-js-entity-selector
      [entityData]="model.availableDims"
      [allowDuplicates]="false"
      [minCount]="1"
      [maxCount]="1"
      [placeholders]="['Field 1']"
      (formEmitter)="onSelectEntity($event)"
    ></siq-js-entity-selector>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-dialog-close mat-stroked-button class="secondary-button">Cancel</button>
  <button class="submit"
          color="accent"
          mat-raised-button
          [disabled]="!selectedDimension"
          [mat-dialog-close]="selectedDimension"
  >Drill</button>
</mat-dialog-actions>

export enum PromoPeriods {
  PROMO = 'PROMO',
  PRE = 'PRE',
  POST = 'CUSTOM',
  YOY = 'YOY'
}

export enum UnitTypes {
  ABSOLUTE = 'ABSOLUTE',
  PSPW = 'PSPW'
}

export enum FilterGroups {
  ITEM = 'ITEM',
  LOC = 'LOC',
  COMP = 'COMP'
}

export enum PromoSheets {
  TAKERATE = 'Take Rate',
  SOV = 'Source Of Volume'
}

export enum KEY_CODE {
  ESC = 'Escape'
}

export enum KPI {
  AVG_PRICE = 'AVG_PRICE',
  BASKET_TOTAL_AMOUNT = 'BASKET_TOTAL_AMOUNT',
  BASKET_TOTAL_QUANTITY = 'BASKET_TOTAL_QUANTITY_FACT',
  NUM_TRANSACTIONS = 'NUM_TRANSACTIONS_SUMMABLE',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  TOTAL_QUANTITY = 'TOTAL_QUANTITY',
}

export enum ChartTypes {
  TIMESERIES = 'TIMESERIES',
  DISTRIBUTION = 'DISTRIBUTION',
  TAKERATE = 'TAKERATE'
}

export enum PromoDimensionKeys {
  BASKET_SIZE = 'basket_item_set_quantity',
  UPC_DESC = 'prod_upc_desc',
  UPC = 'prod_upc',
  TIMESERIES_DAY = 'yearmonday',
  TIMESERIES_WEEK = 'WEEK_END_SUN',
  PERIOD = 'period'
}

export enum KPIChipClass {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NONE = 'same'
}

export enum PromoListDropdownMetrics {
  PROMO = 'Promo Amount',
  DELTA = 'Change',
  PERCENT_DELTA = '% Change'
}

export enum PromoJobEnums {
  TAKE_RATE = 'PROMO_SET',
  TAKE_RATE_HELPER = 'PROMO_SET-DETAIL',
  TAKE_RATE_TIME_SERIES = 'PROMO_SET-SALES',
  SOV_DISTRIBUTION = 'DISTRIBUTION',
  SOV_DISTRIBUTION_PSPW = 'DISTRIBUTION - per store per week',
  SOV_TIME_SERIES = 'TIMESERIES-'
}

export enum PromoFilterType {
  LOCATION = 'LOCATION'
}

export enum PromoActivityName {
  TAKE_RATE_ABS = 'Take Rate - Absolute',
  TAKE_RATE_PSPW = 'Take Rate - Per store per week',
  SOV_ABS = 'Source Of Volume - Absolute',
  SOV_PSPW = 'Source Of Volume - Per store per week',
}

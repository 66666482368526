<div class="app-container-wrapper app-header">
  <section class="app-container app-header-container">
    <h5 class="app-title">Reports</h5>
  </section>
</div>


<div class="app-container-wrapper no-bg">
  <section class="app-container limited-width-content content-padded">
    <siq-activity-grid
      #activityGrid
      [activityStream$]="activityStream$"
      [columns]="columns"
      [actions]="actions"
      [callbacks]="callbacks"
      [tabs]="tabs"
      [activityFilter]="activityFilter"
    ></siq-activity-grid>
  </section>
</div>

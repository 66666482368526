import { ActivityFormComponent } from 'app/siq-applications/modules/shared/components/activity-form/activity-form.component';
import { ActivityModule } from 'app/activity/activity.module';
import { ActivityResultsComponent } from 'app/siq-applications/modules/shared/components/activity-results/activity-results.component';
import { AppHeaderComponent } from 'app/siq-applications/modules/shared/components/app-header/app-header.component';
import { CommonModule } from '@angular/common';
import { UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SiqApplicationService } from 'app/siq-applications/modules/shared/services/siq-application.service';
import { SiqFormsModule } from 'app/siq-forms/siq-forms.module';
import { CoreModule } from 'app/core/core.module';
import { ActivityGridOptionsMenuComponent } from './components/activity-grid-options-menu/activity-grid-options-menu.component';
import { ErrorOverlayComponent } from './components/error-overlay/error-overlay.component';
import { AlertOverlayComponent } from './components/alert-overlay/alert-overlay.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ActivityModule,
        CoreModule,
        SiqFormsModule,
        ReactiveFormsModule
    ],
  declarations: [
    ActivityResultsComponent,
    ActivityFormComponent,
    AppHeaderComponent,
    ActivityGridOptionsMenuComponent,
    ErrorOverlayComponent,
    AlertOverlayComponent,
  ],
  exports: [
    ActivityResultsComponent,
    ActivityFormComponent,
    AppHeaderComponent,
    SiqFormsModule,
    ReactiveFormsModule,
    ActivityGridOptionsMenuComponent,
    ErrorOverlayComponent,
    AlertOverlayComponent
  ],
  providers: [
    SiqApplicationService,
    UntypedFormBuilder
  ]
})
export class SharedModule { }

import { AfterViewInit, Component, OnInit, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { BaseVisualizationComponent } from 'libs/visual-lib/src/lib/components/base-visualization/base-visualization.component';
import {
  BaseChart,
  BubbleParams,
  ChartSettings,
  SimpleBubbleApi,
  SimpleBubbleConfig,
} from 'libs/visual-lib/src/lib/modules/charts-graphs/models';
import { AgChartOptions, AgChartsAngular, AgCartesianChartOptions } from 'libs/visual-lib/src';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { takeUntil } from 'rxjs/operators';
import { ChartsService } from 'libs/visual-lib/src/lib/modules/charts-graphs/services/charts.service';

@Component({
  selector: 'siq-js-simple-bubble',
  templateUrl: './simple-bubble.component.html',
  styleUrls: ['./simple-bubble.component.scss']
})
export class SimpleBubbleComponent extends BaseVisualizationComponent<any, any, SimpleBubbleConfig, SimpleBubbleApi> implements BaseChart, OnInit, AfterViewInit {

  @ViewChildren(forwardRef(() => AgChartsAngular)) public angularCharts: QueryList<AgChartsAngular>;
  public agChartOptions: AgChartOptions;
  public chartReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }

  getApi(): SimpleBubbleApi {
    return {
      component: this,
      chartOptions: this.agChartOptions
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      const updatedChartOptions = <AgChartOptions>{...this.agChartOptions};
      updatedChartOptions.theme = ChartsService.getChartThemeObject(theme);
      updatedChartOptions.background = ChartsService.getChartBackgroundForTheme();
      this.agChartOptions = updatedChartOptions;
    });
  }

  ngAfterViewInit(): void {
    this.chartReady$.next(true);
    (<BubbleParams>this.data).chart = this; // set pointer to this chart instance to be used by processor/other classes

  }

  public static generateDefaultChartOptions(component: SimpleBubbleComponent): AgChartOptions {
    let typeSafe:AgCartesianChartOptions = {
      theme: ChartsService.getThemePdi(),
      // data: [],
      title: {
        enabled:true,
        text: '',
        fontSize: 18,
      },
      autoSize: true,
      subtitle: { text: '', enabled:true },
      series: [],//there could be pushed data later to empty initialized series array
      legend: { enabled: false },
    };
    return <AgChartOptions>typeSafe;
  }

  protected render(chartSettings: ChartSettings): void {
    this.agChartOptions = _.merge(SimpleBubbleComponent.generateDefaultChartOptions(this), chartSettings.agChartOptions);

  }

}

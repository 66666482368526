import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { FilterModule } from 'app/filter/filter.module';
import { AuthGuard } from 'app/guards/auth-guard';
import { AuthModule } from 'app/auth/auth.module';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from 'app/core/core.module';
import { CustomInjector } from 'app/core/models/custom-injector';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NotFoundModule } from 'app/not-found/not-found.module';
import { ProfileModule } from 'app/profile/profile.module';
import { PublicModule } from 'app/public/public.module';
import { RootGuard } from 'app/guards/root.guard';
import { SiqInternalModule } from 'app/siq-internal/siq-internal.module';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { TokenInterceptor } from 'app/auth/models/token-interceptor';
import { WhatfixService } from 'app/core/services/whatfix/whatfix.service';
import { AppService } from 'app/app.service';
import { AccessGroupModule } from 'app/access-group/access-group.module';
import { SiqApplicationsModule } from 'app/siq-applications/siq-applications.module';
import { UserModule } from 'app/user/user.module';
import { SuperGuard } from 'app/guards/super.guard';
import { GridModule } from 'app/grid/grid.module';
import { AccessGroupAdminGuard } from 'app/guards/access-group-admin-guard';
import { UnauthorizedModule } from 'app/unauthorized/unauthorized.module';
import { VisualLibModule } from '@siq-js/visual-lib';
import { FiltersetsModule } from 'app/filtersets/filtersets.module';

// Application structure documentation
// https://angular.io/docs/ts/latest/guide/style-guide.html#!#application-structure-and-angular-modules

// SIQV3-2329: Restoring this block due to needing to use Java web deploy until release 3.5.0 (node container)
/*
 * Functions that need to be run before the Angular application is loaded go here.
 * This uses APP_INITIALIZER calls this function(s). A promise is returned, and the
 * app will not load until this promise is resolved.
 */
export function onAppInit(
  http: HttpClient,
  appService: AppService
) {
  return () => {
    return new Promise<void>((resolve) => {
      const env = (window as any)['envconfig']['projectid'];
      if (env !== 'localhost') {
        AppService.updateEnvironmentSettings(env);
      }
      resolve();
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    VisualLibModule,
    AuthModule,
    SiqMaterialModule,
    AppRoutingModule,
    PublicModule,
    ProfileModule,
    UserModule,
    AccessGroupModule,
    SiqApplicationsModule,
    SiqInternalModule,
    GridModule,
    UnauthorizedModule,
    FilterModule,
    FiltersetsModule,
    NotFoundModule // KEEP THIS AT THE BOTTOM
  ],
  providers: [
    AuthGuard,
    SuperGuard,
    RootGuard,
    AccessGroupAdminGuard,
    Location,
    { provide: 'AppConstants', useValue: AppSiqConstants },
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [HttpClient, AppService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule {

  /**
   *  The WhatfixService is injected here so that it gets instantiated.
   *  WhatfixService listens for the currentUser and sets data needed by Whatfix.
   */

  public static legacy = true; // SIQV3-2329

  constructor(
    customInjector: CustomInjector,
    private whatfixService: WhatfixService
  ) { }

}

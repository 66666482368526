import * as _ from 'lodash';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { CmsService } from 'app/core/services/cms/cms.service';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { distinctUntilChanged, tap } from 'rxjs';
import { UntypedFormArray } from '@angular/forms';
import {
  takeUntil,
  debounceTime,
  } from 'rxjs';

declare type allowedIndexInt = 0|1|2;

export interface EnumSelectorGroup {
  displayGroup: any;
  items: any[];
}

@Component({
  selector: 'siq-enum-selector',
  templateUrl: './enum-selector.component.html',
  styleUrls: ['./enum-selector.component.scss']
})
export class EnumSelectorComponent implements OnInit, OnChanges, OnDestroy {

  @Input() formArray: UntypedFormArray;
  @Input() items: any[] = [];
  @Input() itemsPerRepeater: any[] = [];
  @Input() maxCount = 3;
  @Input() placeholders: string[] = [];
  @Input() required: boolean[] = [];
  @Input() visibility: boolean[] = [];
  @Input() displayName = 'Item';
  @Input() groupMatchOn: any;
  @Input() allowDuplicates = false;

  public allFilteredOptions: BehaviorSubject<any[]>[] = [];
  public selectorData: BehaviorSubject<EnumSelectorGroup[]>[] = [];
  private unsub = new Subject<void>();
  private itemUpdates = new Subject<void>();
  private formChangesUnsub = new Subject<void>();

  constructor() { }

  add(i: number): void {
    this.visibility[i] = true;
  }

  public idToName(id = ''): string {
    const match = _.find(this.items, { id: id });
    return match ? match.name : '';
  }

  ngOnInit () {

    if (this.maxCount >= 3 || isNaN(this.maxCount)) {
      this.maxCount = 3;
    }

    while (this.selectorData.length < this.maxCount) {
      this.selectorData.push(new BehaviorSubject<EnumSelectorGroup[]>([]));
    }

    this.setPlaceholders();

    this.itemUpdates
      .pipe(
        debounceTime(250),
        takeUntil(this.unsub)
      )
      .subscribe(() => {
        this.setSelectorData();
      });

    this.itemUpdates.next();

  }

  setPlaceholders () {
    for (let i = 0; i < this.maxCount; i++) {
      this.placeholders[i] = this.placeholders[i] || `${this.displayName} ${i + 1}`;
    }
  }

  listenForFormValueUpdates () {

    this.formChangesUnsub.next();

    this.formArray.valueChanges
      .pipe(
        tap(() => this.formArray.markAsPending()),
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.formChangesUnsub)
      )
      .subscribe(value => {
        this.setSelectorData();
      });
  }

  ngOnChanges (changes) {

    _.each(_.keys(changes), key => {
      switch (key) {
        case 'formArray':
          this.listenForFormValueUpdates();
          this.setPlaceholders();
          this.setVisibility();
          this.itemUpdates.next();
          break;
        case 'items':
        case 'itemsPerRepeater':
          this.itemUpdates.next();
          break;
        case 'placeholders':
          this.setPlaceholders();
          break;
      }
    });

  }

  ngOnDestroy () {
    this.unsub.next();
    this.formChangesUnsub.next();
  }

  validateSelections () {
    _.each(this.formArray.controls, c => {
      if (c.value) {

        const match = _.find(this.items, { id: c.value });

        if (!match) {
          c.setErrors({ 'no-match': c.value });
        } else {
          c.setErrors(null);
        }
      }
    });
  }

  resetControl(index: allowedIndexInt): void {
    this.visibility[index] = false;
    this.formArray.controls[index].reset();
    this.formArray.controls[index].setErrors(null);
  }

  remove(i: number): void {
    // shift any values from other selectors if necessary
    if (i < this.maxCount - 1) {

      for (let index = i; index < this.formArray.length; index++) {
        if (this.formArray.controls[index + 1] && this.visibility[index + 1]) {
          this.formArray.controls[index].patchValue(this.formArray.controls[index + 1].value);
        } else {
          this.resetControl(index as allowedIndexInt);
        }
      }
    } else {
      this.resetControl(i as allowedIndexInt);
    }

    this.setSelectorData();

  }

  private setSelectorData(): void {

    const selectorData = [];

    for (let i = 0; i < this.maxCount; i++) {

      let items = this.itemsPerRepeater[i] || this.items;

      if (this.formArray.controls[i].value) {
        items = _.filter(items, (item: any) => {
          if (_.isNil(item.name)) return false;
          return item.name.toLowerCase().includes(this.formArray.controls[i].value.toLowerCase());
        });
      }

      selectorData.push(_.filter(_.sortBy(_.map(_.groupBy(_.cloneDeep(items), AppSiqConstants.contexts.DISPLAY_GROUP), (group, key) => {
        return {
          // displayGroup: CmsService.find(_.extend((this.groupMatchOn || {}), { key: key })) || CmsService.defaults.displayGroup,
          items: _.filter(_.sortBy(group, ['sortOrder']), (item: any) => {
            return (_.isUndefined(item['active']) || item['active']) && !_.find(this.formArray.value, s => s === item.id);
          })
        };
      }), ['displayGroup.value.sortOrder']), group => group.items.length > 0));

    }
    _.each(this.selectorData, (selector, n) => {
      selector.next(selectorData[n]);
    });

    this.validateSelections();

  }

  private setVisibility () {
    _.map(this.formArray.controls, (f, i) => {
      this.visibility[i] = i === 0 || !!f.value;
    });
  }

}

export enum DatePart {
  YEAR = <any>'year',
  MONTH = <any>'month',
  WEEK = <any>'week',
  DAY_OF_WEEK = <any>'dayOfWeek',
  DAY = <any>'day',
  DAYPART = <any>'daypart',
  HOUR = <any>'hour',
  MINUTE = <any>'minute',
  SECOND = <any>'second'
}

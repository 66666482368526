<div class="progress-bar-wrapper">
  <mat-progress-bar
    *ngIf="isLoading"
    class="app-progress"
    [color]="color"
    [mode]="mode"
    [value]="value"
    [bufferValue]="bufferValue"
  ></mat-progress-bar>
</div>

import { Routes, Route } from '@angular/router';
import { AuthGuard } from 'app/guards/auth-guard';
import { SuperGuard } from 'app/guards/super.guard';
import { AboutComponent } from 'app/siq-internal/components/about/about.component';
import { AppActivitySqlComponent } from 'app/siq-internal/components/app-activity-sql/app-activity-sql.component';

const aboutRoute: Route = {
  path: 'about',
  component: AboutComponent,
  canActivate: [
    AuthGuard
  ]
};

const appActivitySqlRoute: Route = {
  path: 'app-activity-sql/:id',
  component: AppActivitySqlComponent,
  canActivate: [
    AuthGuard,
    SuperGuard
  ]
};


export const SiqInternalRoutes: Routes = [
  aboutRoute,
  appActivitySqlRoute
];

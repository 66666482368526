import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { CustomInjector } from 'app/core/models/custom-injector';
import { TextColorType } from '@siq-js/visual-lib';
import { BytesPipe } from 'app/core/pipes/bytes.pipe';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { SqlEditorService } from 'app/siq-applications/modules/temp/sql-editor.service';
import * as _ from 'lodash';
import { AceComponent, AceConfigInterface, AceDirective } from 'ngx-ace-wrapper';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs';

@Component({
  selector: 'siq-sql-input-box',
  templateUrl: './sql-input-box.component.html',
  styleUrls: ['./sql-input-box.component.scss']
})
export class SqlInputBoxComponent extends BaseSiqComponent implements OnInit, AfterViewInit {

  private static readonly statusText: any = {
    awaiting: 'Awaiting Input',
    invalid: 'Invalid Syntax',
    valid: 'Valid Syntax',
    validating: 'Validating Syntax'
  };
  public canRun: boolean;
  public config: AceConfigInterface = {
    highlightActiveLine: true,
    mode: 'sql',
    readOnly: false,
    showGutter: true,
    showPrintMargin: false,
    theme: this.sqlTheme,
    useSoftTabs: true,
    wrapBehavioursEnabled: true
  };
  public debounceInterval = 1000;
  public readonly defaultFeedback: string = 'SQL will be validated when user input stops';
  public readonly defaultFeedbackCss: string = '';
  public feedback: string;
  public feedbackCss: string;
  public status: string;
  @Input() prependSql: string; // gets prepended to the query before the dryRun (optional field)
  @Input() query: string;
  // @Input() initQuery$: BehaviorSubject<string>;
  @Input() initQuery: string;
  @Input() sqlEditorService: SqlEditorService;
  @Output() public onDryRun = new EventEmitter<boolean>();
  @Output() queryChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(AceComponent, { static: true }) componentRef?: AceComponent;
  @ViewChild(AceDirective, { static: true }) directiveRef?: AceDirective;
  private sqlQueryText = new Subject<string>();

  constructor() {
    super();
    this.canRun = false;
    this.feedback = this.defaultFeedback;
    this.feedbackCss = this.defaultFeedbackCss;
    this.status = SqlInputBoxComponent.statusText.awaiting;
  }

  @Input() set sqlTheme(sqlTheme: string) {
    this.config.theme = sqlTheme;
  }

  dryRun() {
    this.queryChange.emit(this.query);

    this.feedback = 'Please wait...';
    this.feedbackCss = '';
    this.status = SqlInputBoxComponent.statusText.validating;
    this.canRun = false;
    this.onDryRun.emit(this.canRun);

    /*
      For some dryRun queries, we may want/need to prepend the sql in the input box with additional sql to prevent
      the user from being able to modify this part. To allow for this, we use this @Input() prependSql variable.
      Example: access-group sql
     */
    const testQuery = (this.prependSql ? this.prependSql + '\n' : '') + this.query;
    this.sqlEditorService.submitQuery(testQuery, true)
      .pipe(
        map((resp: any) => resp.body.jobs[0])
      )
      .subscribe((resp: any) => {
        if (resp.errors) {
          this.feedback = resp.response.exception.message;
          this.canRun = false;
          this.feedbackCss = TextColorType.WARN.toString();
          this.status = SqlInputBoxComponent.statusText.invalid;
        } else {
          this.feedback = 'This query will process ' + new BytesPipe('en-US').transform(resp.response.response.totalBytesProcessed);
          this.canRun = true;
          this.feedbackCss = TextColorType.SUCCESS.toString();
          this.status = SqlInputBoxComponent.statusText.valid;
        }
        this.onDryRun.emit(this.canRun);
      });
  }

  ngAfterViewInit() {
    if (this.initQuery) {
      this.query = this.initQuery;
    }
    if (this.query) {
      this.directiveRef.setValue(this.query, 1);
    }
  }

  ngOnInit() {
    this.sqlQueryText.pipe(
      debounceTime(this.debounceInterval)
    )
      .subscribe(text => {
        if (!_.isEmpty(_.trim(text))) {
          this.dryRun();
        } else {
          // In this case, just emit the blank value of the query text in order to trigger any listeners in the parent form & reset messaging
          this.queryChange.emit(text);
          this.feedback = this.defaultFeedback;
          this.feedbackCss = this.defaultFeedbackCss;
          this.status = SqlInputBoxComponent.statusText.awaiting;
        }
      });
    EmitterService.get('schemaSelected').subscribe((schema: any) => {
      this.query += schema.name;
      this.directiveRef.setValue(this.query);
    });

    this.sqlEditorService = this.sqlEditorService || CustomInjector.injector.get(SqlEditorService);
  }

  public onContentChange(event: any): void {
    this.canRun = false;
    this.onDryRun.emit(this.canRun);

    this.query = this.directiveRef.getValue();
    this.sqlQueryText.next(this.query);
  }

}

import { CmsService } from 'app/core/services/cms/cms.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterValue } from 'app/filter/models/filter-value';
import { FilterValueCache } from 'app/filter/models/filter-value-cache';
import * as _ from 'lodash';
import { first } from 'rxjs';

export interface FilterSettingsJson {
  id: string;
  values: FilterValue[];
  async: boolean;
  size: number;
}

export class Filter {
  id: string;
  async: boolean;
  size: number;
  values: FilterValueCache = new FilterValueCache();

  constructor(json: FilterSettingsJson) {
    this.id = json.id;

    if (json.async) {
      this.async = json.async;
    } else {
      this.size = json.size;
      json.values && this.buildCache(json.values);
    }

    this.checkIfRetailer();
  }

  private buildCache(filterValues: FilterValue[]) {
    filterValues.forEach(fv => this.values.put(fv));
  }

  // Custom logic for the retailer filter
  private checkIfRetailer() {
    if (this.id === CmsService.RETAILER_FIELD_ID) {

      /* The "first" operator (in the .pipe() ) means the subscription runs only once when val is not null,
          then the observable gets unsubscribed.
      */
      EnvConfigService.data.pipe(
        first(val => !_.isNil(val))
      )
        .subscribe(envConfig => {
          // envConfig here is equivalent to EnvConfigService.getConfig()
          const values: FilterValue[] = envConfig.retailers.map(ret => {
            return { n: ret, d: UtilsService.getRetailerDisplay(ret) };
          });

          this.buildCache(values);
          this.size = values.length;
        });

    }
  }
}

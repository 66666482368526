import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { ActivityService } from 'app/activity/services/activity.service';
import { NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { SovResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-result/sov-result.component';
import { TakeRateResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-result/take-rate-result.component';
import { PromoFormData, PromoFormJson } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { KpiModel, PromoSheetResult } from 'app/siq-applications/modules/promo/models/interfaces';
import { PromoActivity } from 'app/siq-applications/modules/promo/models/promo-activity.model';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoJobEnums, PromoSheets } from 'app/siq-applications/modules/promo/models/promo.enums';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { forkJoin } from 'rxjs';
import { ActivityGridOptionsMenu } from 'app/siq-applications/modules/shared/components/activity-grid-options-menu/activity-grid-options-menu.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { DrawerService } from 'app/core/modules/drawer/services/drawer.service';
import { DrawerState } from 'app/core/modules/drawer/models/drawer-state.enums';
import { mergeMap, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-js-promo-result',
  templateUrl: './promo-result.component.html',
  styleUrls: ['./promo-result.component.scss']
})
export class PromoResultComponent extends BaseSiqComponent implements OnInit {
  @ViewChild(TakeRateResultComponent) takeRateComponent: TakeRateResultComponent;
  @ViewChild(SovResultComponent) sovComponent: SovResultComponent;
  @ViewChild('queryMode') queryMode: QueryModeComponent;

  /* Promo only download Excel, no CSV */
  // If you modify customOptionMenuItems, make sure to modify handleOptionMenuClick() function accordingly
  public customOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
    ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
    ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
    ActivityGridOptionsMenu.SHARE,
    ActivityGridOptionsMenu.EDIT_CRITERIA,
    ActivityGridOptionsMenu.CLONE_CRITERIA,
  ];

  public errorAndInvalidOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.EDIT_CRITERIA,
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  public applicationName: string;

  public activity: PromoActivity;

  public formData: PromoFormData;

  public currentSheet: PromoSheets = PromoSheets.TAKERATE; // field tracking current sheet
  public sheetEnums = PromoSheets; // pointer so template can reference enums

  public takeRateActivity: Activity;
  public takeRatePSPWActivity: Activity;
  public takeRateHelperActivity: Activity;
  public takeRateHelperPSPWActivity: Activity;
  public timeSeriesActivity: Activity;
  public timeSeriesPSPWActivity: Activity;
  public kpiModel: KpiModel;
  public sovActivities: Activity[];
  public sovPSPWActivities: Activity[];
  public status: ActivityStatus;
  public statusEnums = ActivityStatus;

  constructor(
    public config: PromoConfig,
    private route: ActivatedRoute,
    private router: Router,
    private promoService: PromoService,
    private activityService: ActivityService,
    private sharingService: SharingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.paramMap
    .pipe(
      takeUntil(this.unsub$),
      mergeMap(params => {
        const promoId = params.get('id'); // Parse promo activity ID
        return this.promoService.getReport(promoId); // Retrieve promo container activity
      })
    )
    .subscribe(activity => {
      this.activity = activity;
      this.filterOptionMenuItems();
      this.applicationName = activity.getName();
      this.status = activity.getStatus();
      if (this.status === ActivityStatus.EXPIRED) {
        this.promoService.rerunPromoActivity(activity)
        .pipe(takeUntil(this.unsub$))
        .subscribe(refreshedActivity => {
          this.init(refreshedActivity);
        });
      } else if (this.status !== ActivityStatus.ALERT && this.status !== ActivityStatus.ERROR) {
        this.init(activity);
      } else {
        this.ready();
      }
    });
  }

  filterOptionMenuItems() {
    this.errorAndInvalidOptionMenuItems = this.errorAndInvalidOptionMenuItems.filter(value => {
      if (value === ActivityGridOptionsMenu.EDIT_CRITERIA) {
        return this.activity.isMine();
      } else {
        return true;
      }
    });
  }

  ngOnDestroy() {
    DrawerService.clear();
  }

  handleOptionMenuClick(clickedItem: string) {
    switch (clickedItem) {
      case ActivityGridOptionsMenu.AUTOSIZE_COLUMNS.trim():
        this.autoSize();
        break;
      case ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN.trim():
        this.fitColumnsToScreen();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_EXCEL.trim():
        this.exportSheetXLSX();
        break;
      case ActivityGridOptionsMenu.SHARE.trim():
        this.share();
        break;
      case ActivityGridOptionsMenu.CLONE_CRITERIA.trim():
        this.cloneCriteria();
      case ActivityGridOptionsMenu.EDIT_CRITERIA.trim():
        this.editCriteria();
      default:
        break;
    }
  }

  public autoSize() {
    const grid_takeRateResult = this.takeRateComponent.gridParams.gridVisualOptions.apiRef().grid;
    grid_takeRateResult.api.sizeColumnsToFit();
    grid_takeRateResult.api.autoSizeAllColumns();

    const grid_sov = this.sovComponent.gridParams.gridVisualOptions.apiRef().grid;
    grid_sov.api.sizeColumnsToFit();
    grid_sov.api.autoSizeAllColumns();
  }

  public fitColumnsToScreen() {
    const grid_takeRateResult = this.takeRateComponent.gridParams.gridVisualOptions.apiRef().grid;
    grid_takeRateResult.api.sizeColumnsToFit();
    const grid_sov = this.sovComponent.gridParams.gridVisualOptions.apiRef().grid;
    grid_sov.api.sizeColumnsToFit();
  }

  public exportSheetXLSX() {
    let gridVisualOptions;

    // This switch case determines the component that should be exported
    switch (DrawerService.getState()) {
      case DrawerState.DEFAULT:
      case DrawerState.FULL:
        // If drawer is open, exporting helper component's table
        if (this.currentSheet === PromoSheets.SOV) {
          gridVisualOptions = this.sovComponent.helperParams.helperComponent.gridParams.gridVisualOptions;
        } else {
          gridVisualOptions = this.takeRateComponent.helperParams.helperComponent.gridParams.gridVisualOptions;
        }
        break;
      case DrawerState.PEEK:
      case null:
      default:
        // If drawer is not open, exporting active sheet's table
        if (this.currentSheet === PromoSheets.SOV) {
          gridVisualOptions = this.sovComponent.gridParams.gridVisualOptions;
        } else {
          gridVisualOptions = this.takeRateComponent.gridParams.gridVisualOptions;
        }
        break;
    }
    this.promoService.exportSheetAsExcel(this.activity, gridVisualOptions);
  }

  public share() {
    this.sharingService.share(this.activity);
  }

  public cloneCriteria() {
    this.promoService.cloneReport(this.activity.getId());
  }

  public editCriteria() {
    const url = '/app/measurement/' + this.activity.getId() + '/edit';
    this.router.navigate([url]);
  }

  public setActiveSheet(sheet: PromoSheets) {
    if (this.currentSheet === sheet) return;

    this.currentSheet = sheet;

    let comp: PromoSheetResult;
    switch (sheet) {
      case PromoSheets.TAKERATE:
        comp = this.takeRateComponent;
        break;
      case PromoSheets.SOV:
        comp = this.sovComponent;
        break;
    }

    // this.sheetContent.nativeElement.scrollTo(0, 0); // reset scroll position
    comp.setActive(); // Trigger custom component logic for setting active
  }

  private init(activity: PromoActivity) {
    const fv = JSON.parse(activity.formValues) as PromoFormJson;
    this.formData = this.promoService.createForm(fv);
    NavSecondaryService.close();
    this.processKPIComparisonGroup();
    this.queryMode.setSchema(this.formData.schema);

    const reqs = [
      ...this.activity.takeRateJobs,
      ...this.activity.takeRatePSPWJobs,
      ...this.activity.sovJobs,
      ...this.activity.sovPSPWJobs
    ];

    forkJoin(reqs.map(reqId => this.activityService.getActivityResults(reqId, true)))
      .pipe( takeUntil(this.unsub$) )
      .subscribe(
        (activities: Activity[]) => {
          const sovActivities = [];
          const sovPSPWActivities = [];
          activities.forEach(a => {
            const id = a.getJob().getName();
            switch (id) {
              case PromoJobEnums.TAKE_RATE:
                if (this.activity.takeRateJobs.has(a.id)) {
                  this.takeRateActivity = a;
                } else {
                  this.takeRatePSPWActivity = a;
                }
                break;
              case PromoJobEnums.TAKE_RATE_HELPER:
                if (this.activity.takeRateJobs.has(a.id)) {
                  this.takeRateHelperActivity = a;
                } else {
                  this.takeRateHelperPSPWActivity = a;
                }
                break;
              case PromoJobEnums.TAKE_RATE_TIME_SERIES:
                if (this.activity.takeRateJobs.has(a.id)) {
                  this.timeSeriesActivity = a;
                } // Time series don't have PSPW
                break;
            }

            if (id.includes('SOV') && this.activity.sovJobs.has(a.id)) {
              return sovActivities.push(a);
            }
            if (id.includes('SOV') && this.activity.sovPSPWJobs.has(a.id)) {
              return sovPSPWActivities.push(a);
            }
          });

          this.sovActivities = sovActivities;
          this.sovPSPWActivities = sovPSPWActivities;

          this.setActiveSheet(PromoSheets.TAKERATE); // Initialize to the first tab
          this.ready();
          this.activityService.markViewed(this.activity);

        }
      );

    if (!this.activity.isMine()) { // user is not report owner, cannot share, Edit report or download CSV
      this.customOptionMenuItems = [
        ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
        ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
        ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
        ActivityGridOptionsMenu.CLONE_CRITERIA,
      ];
    }

  }

  private processKPIComparisonGroup() { // Replace Comparison Group id with its name
    const keys = Object.keys(this.activity.kpiModel);
    keys.forEach(key => {
      if (key.includes('CG-')) {
        const cgId = key.slice(3);
        const cg = this.formData.comparisonGroups.find(c => c.id === cgId).name;
        this.activity.kpiModel[cg] = this.activity.kpiModel[key]; // Rename key name
        delete this.activity.kpiModel[key];
      }
    });
    this.kpiModel = this.activity.kpiModel;
  }

}

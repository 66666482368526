import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/auth/services/auth.service';
import { UserService } from 'app/user/services/user.service';

@Injectable()
export class SuperGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const superUser = this.authService.isSuperUser();

    if (!superUser) {
      this.userService.getDefaultUrl().subscribe((url: string) => {
        this.router.navigate([url]);
      });
    }

    return superUser;
  }
}

import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ReportBuilderColumnType } from 'app/siq-applications/modules/report-builder/models/form/enums';

export abstract class FormColumn {

  public columnGroupName = '';
  public abstract ref;
  public type;

  public key: string;
  public parent: ReportBuilderFormData;

  constructor(parent: ReportBuilderFormData) {
    this.key = UtilsService.guid();
    this.parent = parent;
  }

  public abstract toJson(): any;

  public abstract size(): number;

  public isMetric(): boolean {
    return this.type === ReportBuilderColumnType.METRIC;
  }

  public isDim(): boolean {
    return this.type === ReportBuilderColumnType.DIMENSION;
  }
}

import { Injectable } from '@angular/core';
import { ApplicationHash } from '@siq-js/core-lib';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { BehaviorSubject, Observable, forkJoin, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  public static PollFor$: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set()); // Holds set of activity ids
  private static readonly PollInterval = 4000;

  constructor(private activityService: ActivityService) {
    PollingService.PollFor$.pipe(
      switchMap((set) => {
        const requests: Observable<Activity>[] = [];
        for (const id of set) {
          requests.push(
            this.activityService.getActivity<Activity>({
              id: id,
              resultType: ActivityResultType.NO_RESULTS,
              suppressTopLoadingBar: true,
            })
          );
        }
        return forkJoin(requests);
      })
    ).subscribe((list: Activity[]) => {
      this.processPolledActivities(list);
    });
  }

  private processPolledActivities(list: Activity[]) {
    // 1. Get done list including completed, status=alert, status=error. Show notifications and update ActivityService.Activities$
    const done = list.filter(
      (a) => a.isComplete() || a.getStatus() === ActivityStatus.ALERT || a.getStatus() === ActivityStatus.ERROR
    );
    if (done.length) {
      // Show completed notifications and update ActivityService.Activities$ with done ones
      const doneMap: { [id: string]: Activity } = {};
      done.forEach((d) => {
        doneMap[d.id] = d;
        if (d.isComplete() && d.getStatus() !== ActivityStatus.ALERT && d.getStatus() !== ActivityStatus.ERROR) {
          this.activityService.notifyActivityComplete(d);
        }
      });

      const activities = ActivityService.Activities$.getValue();
      for (let i = 0; i < activities.length; i++) {
        if (doneMap[activities[i].id]) {
          if (activities[i].isSharedOrScheduled()) { // The getActivity endpoint returns activity with report:null, but we want to keep the report field info for shared/scheduled reports
            const reportField = activities[i].sharedReport;
            activities[i] = doneMap[activities[i].id]; // replace with the done activity
            activities[i].sharedReport = reportField;
          } else {
            activities[i] = doneMap[activities[i].id]; // replace with the done activity
          }
        }
      }

      ActivityService.Activities$.next(activities);
      // (!!!) Remove the done ones from poll
      this.removeDoneActivitiesFromPoll(done);
    }

    // 2. Poll. Update PollFor$ set by removing done items from previous PollFor to avoid race condition with ActivityService.refresh()
    if (done.length < list.length) {
      setTimeout(() => {
        PollingService.PollFor$.next(PollingService.PollFor$.getValue()); // done ones already removed from previous step (!!!)
      }, PollingService.PollInterval);
    }
  }

  private removeDoneActivitiesFromPoll(doneList: Activity[]) {
    const set = PollingService.PollFor$.getValue();
    const doneIds = new Set(doneList.map((a) => a.id));
    for (const item of set) {
      if (doneIds.has(item)) {
        set.delete(item);
      }
    }
    return set;
  }
}

import { Activity } from 'app/activity/models/activity.model';
import { Component, Input, OnChanges } from '@angular/core';
import { SiqError } from 'app/core/models/siq-error.model';

@Component({
  selector: 'siq-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss']
})
export class ErrorListComponent implements OnChanges {

  @Input() activity: Activity;
  public errorsDetails: SiqError[] = [];

  constructor() { }

  ngOnChanges(changes) {
    this.errorsDetails = this.activity ? this.activity.getErrorsDetails() : [];
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'siq-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.scss']
})
export class ScheduleDetailComponent implements OnInit {
  @Input() formGroupRecurrence: UntypedFormGroup;
  @Input() formControlStart: UntypedFormControl;
  @Input() formControlEnd: UntypedFormControl;
  @Input() formControlCount: UntypedFormControl;
  @Input() scheduleData: any;
  public recurrenceData: any;
  public frqPosted: string;

  constructor() { }

  ngOnInit() {
    if (this.scheduleData.recurrence) {
      this.frqPosted = this.scheduleData.recurrence.freq;
    }
    this.recurrenceData = this.scheduleData.recurrence || {};
    this.formControlStart.setValue(this.scheduleData.start);
    this.formControlEnd.setValue(this.scheduleData.end);
    this.formControlCount.setValue(this.scheduleData.count);
  }

}

import { Injectable } from '@angular/core';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { ContentType } from '@siq-js/core-lib';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SecurityQuestionPayloadInterface } from 'app/public/models/security-question-payload-interface';
import { ResetPasswordPayloadInterface } from 'app/public/models/reset-password-payload-interface';

@Injectable()
export class ForgotPasswordService {

  private static readonly paths = {
    validateEmail: 'user/public/forgot-password',
    answerSecurityQuestion: 'user/public/forgot-password/verify',
    resetPassword: 'user/public/change-password'
  };

  constructor(
    private siqHttpService: SiqHttpService
  ) { }

  public validateEmail(payload: { email: string }): Observable<HttpResponse<any>> {

    return this.siqHttpService
      .create({
        endpoint: ForgotPasswordService.paths.validateEmail,
        body: payload,
        type: ContentType.JSON,
        suppressNotification: true
      });

  }

  public answerSecurityQuestion(payload: SecurityQuestionPayloadInterface): Observable<HttpResponse<any>> {

    return this.siqHttpService
      .create({
        endpoint: ForgotPasswordService.paths.answerSecurityQuestion,
        body: payload,
        type: ContentType.JSON,
        suppressNotification: true
      });

  }

  public resetPassword(payload: ResetPasswordPayloadInterface): Observable<HttpResponse<any>> {

    return this.siqHttpService
      .create({
        endpoint: ForgotPasswordService.paths.resetPassword,
        body: payload,
        type: ContentType.JSON,
        additionalHeaders: this.siqHttpService.getNoAuthHeaders(),
        suppressNotification: true
      });

  }
}

import { CmsField } from '@siq-js/cms-lib';
import { FormColumn } from 'app/siq-applications/modules/report-builder/models/form/form-column.model';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';

export class DimensionColumn extends FormColumn {
  public type = AppSiqConstants.types.DIMENSION;
  public ref: CmsField;

  constructor(parent: ReportBuilderFormData, dim: CmsField) {
    super(parent);
    this.ref = dim;
  }

  public size(): number {
    return 1;
  }

  public toJson(): any {
    return {
      columnGroupName: this.columnGroupName,
      type: this.type,
      ref: this.ref.id
    };
  }
}

import { Routes, Route } from '@angular/router';
import { LoginComponent } from 'app/public/components/login/login.component';
import { ForgotPasswordComponent } from 'app/public/components/forgot-password/forgot-password.component';
import { UnsubscribedComponent } from 'app/public/components/unsubscribed/unsubscribed.component';
import { MaintenanceComponent } from 'app/public/components/maintenance/maintenance.component';

const forgotPasswordRoute: Route = {
  path: 'forgot-password',
  component: ForgotPasswordComponent,
  data: {
    name: 'Forgot Password'
  }
};

const loginRoute: Route = {
  path: 'login',
  component: LoginComponent,
  data: {
    name: 'Login'
  }
};

const unsubscribedRoute: Route = {
  path: 'unsubscribe',
  component: UnsubscribedComponent,
  data: {
    name: 'Unsubscribed'
  }
};

const maintenanceRoute: Route = {
  path: 'maintenance',
  data: {
    name: 'Maintenance'
  },
  children: [
    {
      path: '',
      component: MaintenanceComponent,
    },
    {
      path: ':type',
      component: MaintenanceComponent,
    }
  ]
};

export const publicRoutes: Routes = [
  forgotPasswordRoute,
  loginRoute,
  unsubscribedRoute,
  maintenanceRoute
];

import { Component } from '@angular/core';
import { ColDef } from '@siq-js/visual-lib';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityCriteriaRendererComponent } from 'app/activity/shared/renderers/activity-criteria-renderer/activity-criteria-renderer.component';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import { CriteriaChipFactory } from 'app/core/models/factories/criteria-chip-factory';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { PromoFormData, PromoFormJson } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

interface Model {
  comparisonGroup?: CriteriaChip;
  locationFilters?: CriteriaChip;
  otherDates: CriteriaChip;
  promoDate: string;
  promoType?: string;
  sourceOfVolume?: CriteriaChip;
  upcFilters: CriteriaChip;
  upcCount?: string;
  retailer: string;
}
@Component({
  selector: 'siq-js-promo-criteria-renderer',
  templateUrl: './promo-criteria-renderer.component.html',
  styleUrls: ['./promo-criteria-renderer.component.scss']
})
export class PromoCriteriaRendererComponent extends ActivityCriteriaRendererComponent {
  public static readonly ColDef: ColDef = {
    headerName: 'Criteria',
    sortable: false,
    cellRenderer: PromoCriteriaRendererComponent,
    width: 1100
  };

  public model: Model;

  constructor(
    asyncStatusService: AsyncStatusService,
    private promoService: PromoService
  ) {
    super(asyncStatusService);
  }

  protected setupModel(activity: Activity) {
    try {
      const fv: PromoFormJson = JSON.parse(activity.formValues);
      const form = this.promoService.createForm(fv);
      this.model = {
        upcFilters: CriteriaChipFactory.getFilters(form.upcFilters, 'UPC'),
        otherDates: this.getOtherDates(form),
        promoDate: CriteriaChipFactory.getDate(DatesService.toDateRangeInterface(form.promoDateRange)),
        retailer: form.schema
      };
      if (form.upcCount) {
        this.model.upcCount = CriteriaChipFactory.getUpcCount(form.upcCount);
      }
      if (form.locationFilters) {
        this.model.locationFilters = CriteriaChipFactory.getFilters(form.locationFilters, 'Location');
      }
      if (form.promoType) {
        this.model.promoType = form.promoType;
      }
      if (form.comparisonGroups) {
        let toolTip = '';
        if (form.comparisonGroups?.length) { // is not empty
          form.comparisonGroups.forEach(cg => { toolTip = toolTip + cg.name + ', '; });
          toolTip = toolTip.slice(0, -2);
        }
        this.model.comparisonGroup = {
          label: `${form.comparisonGroups.length} Comp. Group(s)`,
          toolTip: toolTip
        };
      }
      if (form.sourcesOfVolume) {
        let toolTip = '';
        if (form.sourcesOfVolume?.length) { // is not empty
          form.sourcesOfVolume.forEach(sov => {
            toolTip = toolTip + '(' + sov.dimension.display + ' in ' + sov.comparisonGroup.name + '), ';
          });
          toolTip = toolTip.slice(0, -2);
        }
        this.model.sourceOfVolume = {
          label: `${form.sourcesOfVolume.length} Source of Volume(s)`,
          toolTip: toolTip
        };
      }
    } catch (e) {
      console.warn('Mode not setting up correctly');
      this.model = null;
    } finally {
      this.ready();
    }
  }

  refresh(params: any) {
    return true;
  }

  private getOtherDates(form: PromoFormData): CriteriaChip {
    const preCustomName = form.prePromoDateRange.customPeriodName || 'Pre Promo Period';
    const preToolTip = `${preCustomName}: ${CriteriaChipFactory.getDate(DatesService.toDateRangeInterface(form.prePromoDateRange))}`;
    const yoyCustomName = form.yoyDateRange.customPeriodName || 'Year Over Year';
    const yoyToolTip = `${yoyCustomName}: ${CriteriaChipFactory.getDate(DatesService.toDateRangeInterface(form.yoyDateRange))}`;
    const customToolTip = form.customPromoDateRange.periodStart && `${form.customPromoDateRange.customPeriodName}: ${CriteriaChipFactory.getDate(DatesService.toDateRangeInterface(form.customPromoDateRange))}`;
    return {
      label: `${!!form.customPromoDateRange.periodStart ? '3' : '2'} Other Dates`,
      toolTip: preToolTip + '\n' + yoyToolTip + `${!!form.customPromoDateRange.periodStart ? '\n' + customToolTip : ''}`
    };
  }

}

<siq-js-sov
  *ngFor="let sov of sovArr"
  [sov]="sov"
  [schema$]="schema$"
  [comparisonGroups$]="comparisonGroups$"
  [sovs$]="sovs$"
  (sovRemoved)="onRemoveSOV($event)"
>
</siq-js-sov>

<button
  *ngIf="sovArr.length < sovMax"
  mat-stroked-button
  class="secondary-button"
  (click)="onAddSOV()"
>
  Add a Source of Volume
</button>

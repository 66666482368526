<div class="new-user-container">
  <mat-card class="new-user-wrapper">
    <mat-card-title>
      <img src="assets/img/PDI Logo-RGB-Two Color-with Tag-Digital.png" alt="PDI Technologies" />
      <h5>New Account Setup</h5>
    </mat-card-title>

    <form (ngSubmit)="submit()" [formGroup]="form" *ngIf="model && form">
      <mat-card-content>

        <div *ngIf="model.step === 'password'">
          <mat-form-field *ngIf="!haveCurrentPassword">
            <input matInput placeholder="Current Password" formControlName="currentPassword" (input)="validatePasswords()" name="current-password" type="password" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="New Password" formControlName="newPassword" (input)="validatePasswords()" name="password" type="password" required>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Confirm Password" formControlName="newPasswordConfirmation" (input)="validatePasswords()" name="confirm-password" type="password" required>
          </mat-form-field>

          <mat-card>
            <siq-password-rules
              [newPassword]="form.get('newPassword').value"
              [confirmNewPassword]="form.get('newPasswordConfirmation').value"
            ></siq-password-rules>
          </mat-card>
        </div>

        <div *ngIf="model.step === 'securityQuestions'">

          <p>Please select and answer three security questions.</p>
          <p>These questions will only be used if you need to reset your password.</p>

          <div class="question-answer" *ngFor="let control of form.get('questions').controls; let i = index">

            <mat-form-field formArrayName="questions" floatLabel="never">
              <mat-select [formControlName]="i" placeholder="-Select a Question-">
                <mat-option *ngFor="let q of model.availableQuestions" [value]="q">{{ q }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field formArrayName="answers" floatLabel="never">
              <input matInput [formControlName]="i" placeholder="Answer">

            </mat-form-field>

          </div>
        </div>

        <span *ngIf="error" class="error"> {{ error }}</span>
      </mat-card-content>

      <mat-card-actions>
        <p *ngIf="setPassword && !model.submitDisabled">You will be redirected to login page and please enter your new credentials there.</p>
        <button mat-raised-button color="primary" [disabled]="model.submitDisabled || !form.valid"> {{ 'Submit' }}</button>
      </mat-card-actions>
    </form>

  </mat-card>
</div>

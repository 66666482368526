<div class="summary-filters" *ngIf="model$ | async as model">
  <ng-container *ngIf="model.length">
    <span class="section-label">Column Filter(s):</span>
    <span class="filter-label" *ngFor="let f of model" (click)="editFilter(f.field, true)">
      {{ f.field.display }}
      ({{ f.values.length || ''}}
      {{ (f.values.length && f.nulls) ? ' + ' : ''}}
      {{ f.nulls ? 'Unknowns' : '' }})
  </span>
  </ng-container>
</div>

<div class="custom-paging" >
  <div class="custom-paging-items" #customPagingItems>

    <div class="custom-paging-goto" #customPagingGoto>
      <span>Jump to page:</span>
      <mat-form-field class="custom-paging-goto-input" appearance="fill">
        <input
          matInput
          type="number"
          [min]="1"
          [max]="totalPages"
          (input)="numericOnly($event)"
          [ngClass]="{'text-warn': !pageNumberCorrect}"
          [(ngModel)]="pageNumber">
        <button
          *ngIf="pageNumber"
            [disabled]="!pageNumberCorrect"
            matSuffix 
            mat-icon-button 
            aria-label="Jump"
            (click)="onGotoPage()">
            <mat-icon>arrow_forward</mat-icon>
          </button>
      </mat-form-field>
    </div>

    <mat-paginator
      #matPaginator
      [pageIndex]="currentPage"
      [length]="rowCount"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizes"
      [hidePageSize]="false"
      [showFirstLastButtons]="true"
      aria-label="Select page"
      (page)="onPageSizeChanged($event)"
      >
    </mat-paginator>

  </div>
</div>
import { Injectable, Renderer2 } from '@angular/core';
import { AuthService } from 'app/auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class IdleService {

  public static readonly MINUTES_UNTIL_LOGOUT = Infinity; // TODO - change this back for prod
  public static readonly ACTIVE_EVENTS = ['click', 'mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart'];

  private renderer: Renderer2;
  private minutesIdle;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public setup(renderer: Renderer2) {
    // Renderer2 can't be injected into a service, so we need to pass in a reference from the root AppComponent
    this.renderer = renderer;

    IdleService.ACTIVE_EVENTS.forEach(
      event => this.renderer.listen('body', event, () => {
        this.minutesIdle = 0;
      }));

    this.minutesIdle = 0;
    this.checkIdle();
  }

  public getMinutesIdle(): number {
    return this.minutesIdle;
  }

  private checkIdle() {
    const user = AuthService.CurrentUser$.getValue();
    if (user) {
      this.minutesIdle++;

      if (this.minutesIdle > IdleService.MINUTES_UNTIL_LOGOUT) {
        this.authService.logout()
          .subscribe(() => {
            this.router.navigate(['']);
            this.minutesIdle = 0;
          });
      }
    }

    setTimeout(() => this.checkIdle(), 60000);
  }
}

import { Serializable } from '@siq-js/core-lib';

export interface CmsEntityJson {
  id: string;
  display: string;
  active: boolean;
}

export abstract class CmsEntity<T extends CmsEntityJson> implements Serializable<T> {
  public id: string;
  public display: string;
  public active: boolean;

  protected constructor(model?: T) {
    this.id = model?.id || '';
    this.display = model?.display || '';
    this.active = model?.active || false;
  }

  toJson(): T {
    return {
      id: this.id,
      display: this.display,
      active: this.active
    } as T;
  }

  // This .clone() function is used to create a copy of CmsField|CmsMetric|CmsApplication
  abstract clone(): T;
}

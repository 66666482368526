import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/user/services/user.service';

// Prevents access to the '/', instead redirecting to the default url to prevent showing of login form, post login
@Injectable()
export class RootGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Checks if the active route is '/', and redirects if so
    if(state.url === '/') {
      this.userService.getDefaultUrl().subscribe((url: string) => {
        this.router.navigate([url]);
      });
    }

    return true;
  }
}

import { DateForm, dayPeriod } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-form.interface';
import * as _ from 'lodash';

export class DateModel implements DateForm {
  public year: number;
  public month: number;
  public day: number;
  public hour: number;
  public period?: dayPeriod;
  public dayOfWeek?: 1|2|3|4|5|6|7;
  public daypart?: number;

  constructor(form?: DateForm) {
    if (form) {
      const numericalForm = this.convertFormToNumber(form);
      this.year = numericalForm.year;
      this.month = typeof numericalForm.month === 'number' ? numericalForm.month : undefined;
      this.day = numericalForm.day;
      this.hour = numericalForm.hour;
      this.period = numericalForm.period;
      this.dayOfWeek = numericalForm.dayOfWeek;
      this.daypart = numericalForm.daypart;
    }
  }

  /**
   * Checks the validity of a model.
   *
   * @returns {boolean} Indicates if the model is valid (not empty).
   *
   */
  public isValid(): boolean {
    return !_.isNil(this.year) || !_.isNil(this.month)|| !_.isNil(this.day) || !_.isNil(this.hour) || !_.isNil(this.dayOfWeek) || !_.isNil(this.daypart);
  }

  /**
   * Accepts a DateForm object, and return the object with all members (other then period) converted to numbers.
   *
   * @param {DateForm} form The DateForm with members to convert to numerical.
   * @returns {DateForm} A DateForm object with the appropriate members converted to numerical.
   *
   * @private
   */
  private convertFormToNumber(form: DateForm): DateForm {
    const numericalForm = _.clone(form);
    _.each(numericalForm, (value, index) => {
      numericalForm[index] = (_.isNil(value) || _.isNaN(Number(value))) ? value : Number(value);
    });
    return numericalForm;
  }
}

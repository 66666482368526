import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, Route } from '@angular/router';
import { CmsApplication } from '@siq-js/cms-lib';
import { Group } from '@siq-js/core-lib';

@Component({
  selector: 'siq-application-button',
  templateUrl: './application-button.component.html',
  styleUrls: ['./application-button.component.scss']
})
export class ApplicationButtonComponent implements OnInit {

  @Input() item: CmsApplication | Group<CmsApplication>;
  public isGroup: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    this.isGroup = 'group' in this.item;
  }
}

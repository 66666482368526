import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { reportBuilderRoutes } from 'app/siq-applications/modules/report-builder/routes/report-builder-routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(reportBuilderRoutes)
  ],
  exports: [ RouterModule ]
})
export class ReportBuilderRoutingModule { }

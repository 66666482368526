import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDisplayName'
})
export class FilterDisplayNamePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, args?: any): any {
    // let matches = this.filterService.get([value]); TODO
    let matches = null;
    return matches.length ? matches[0].name : value;
  }

}

import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { SqlEditorParameters } from 'app/siq-applications/modules/temp/sql-editor-parameters.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { SiqApplicationService } from 'app/siq-applications/modules/shared/services/siq-application.service';
import { ActivityService } from 'app/activity/services/activity.service';
import { NotificationService } from '@siq-js/angular-buildable-lib';

@Injectable({
  providedIn: 'root'
})
export class SqlEditorService extends SiqApplicationService {

  private static readonly ApiPath = 'app/query';
  private static readonly ApiPathAsync = 'app/query/async';

  constructor(
    protected activityService: ActivityService,
    protected notificationService: NotificationService
  ) {
    super(activityService, notificationService);
  }

  submitQuery(query: string, dryRun = false): Observable<HttpResponse<any>> {

    const params = new SqlEditorParameters(query, dryRun);
    const dataSet = new AppDataset('query', params);
    const appRequest = new AppRequest([dataSet], params);

    return this.createActivity(appRequest, dryRun ? SqlEditorService.ApiPath : SqlEditorService.ApiPathAsync, true);

  }

}

import { Injectable } from '@angular/core';
import { AccessGroup, AccessGroupStatus } from 'app/access-group/models/access-group.model';
import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, take } from 'rxjs';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { AccessGroupDTO } from 'app/access-group/models/interfaces';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import { CrudConfig } from '@siq-js/core-lib';
import {
  NotificationService,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig
} from '@siq-js/angular-buildable-lib';
import { GridApi } from '@siq-js/visual-lib';
import { User } from 'app/user/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AccessGroupService extends SiqHttpService {
  public static readonly AccessGroups$: BehaviorSubject<AccessGroup[]> = new BehaviorSubject([]);
  public static readonly AccessGroupReady$: Subject<boolean> = new Subject<boolean>();
  private static readonly AccessGroupEndpoint: string = 'aggregate/access/groups';
  public static readonly CurrentUserAccessGroup$: BehaviorSubject<AccessGroup> = new BehaviorSubject(null);
  private static readonly PollInterval = 2000;
  private static readonly RefreshDebouncer$: Subject<void> = new Subject<void>();

  /**
   * Creates a BehaviorSubject stream
   * @param mapFn: pure function that transforms the access-group list into the desired output array
   * @param onlyDiff: by default, subsequent values will only be emitted to the generated BehaviorSubject if there is a
   *        discernible difference between the old & new lists. If set to false, ALL values will be emitted
   */
  public static createStream(
    mapFn: (accessGroups: AccessGroup[]) => AccessGroup[]
  ): BehaviorSubject<AccessGroup[]> {

    const bs$ = new BehaviorSubject<AccessGroup[]>([]);

    AccessGroupService.AccessGroups$
    .pipe(
      map(list => mapFn(list)),
      filter(list => {
        // nil check
        return !_.isNil(list);
      })
    )
    .subscribe(list => {
      bs$.next(list);
    });

    return bs$;
  }

  /** Get list of all users from the same access group as current user */
  public static getAccessGroupUsers(): User[] {
    // Nullish coalescing operator (Typescript) provides fallback value.
    // Instantiate each entry as a User object so that it has all internal methods of User object (.isInternal(), etc)
    return (AccessGroupService.CurrentUserAccessGroup$.getValue()?.getUsersGroup() ?? []).map(u => new User(u));
  }

  public static refresh() {
    this.RefreshDebouncer$.next();
  }

  constructor(
    http: HttpClient,
    notificationService: NotificationService
  ) {
    super(http, notificationService);
    AccessGroupService.RefreshDebouncer$
    .pipe(
      debounceTime(500)
    )
    .subscribe(() => {
      try {
        this.retrieveAccessGroups();
      } catch (err) {
        console.error(err);
      }
    });
  }

  addOrEditAccessGroup(accessGroupDTO: AccessGroupDTO): Observable<HttpResponse<any>> {
    // Hold the user data passed in to be used in Response notifications
    if (accessGroupDTO.retailers && accessGroupDTO.retailers.length === 1 && accessGroupDTO.retailers[0].trim() === '') {
      accessGroupDTO.retailers.shift(); // remove first (empty) item
    }

    const crudConfig: CrudConfig = {
      endpoint: AccessGroupService.AccessGroupEndpoint,
      body: accessGroupDTO,
      notificationData: accessGroupDTO
    };
    if (!_.isNil(accessGroupDTO.id) && accessGroupDTO.id > -1) {
      return super.update(crudConfig);
    } else {
      return super.create(crudConfig);
    }
  }

  autoSizeColumns(gridApi: GridApi) {
    if (!gridApi) {
      return;
    }

    gridApi.autoSizeAllColumns();
    gridApi.sizeColumnsToFit();
  }

  public getAccessGroup(id: string): Observable<AccessGroup> {
    const match: AccessGroup = _.find(AccessGroupService.AccessGroups$.getValue(), {id: parseInt(id, 10)});
    if (match) return observableOf(match);

    return this.get({endpoint: `${AccessGroupService.AccessGroupEndpoint}/${id}`})
    .pipe(
      map(accessGroupDTO => new AccessGroup(accessGroupDTO)),
      catchError(this.handleHttpError)
    );
  }

  public removeAccessGroup(id: number, data: AccessGroup): Observable<HttpResponse<any>> {
    return super.remove({endpoint: `${AccessGroupService.AccessGroupEndpoint}/${id}`, notificationData: data});
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    // OVERRIDE of super class function
    let overrideCodes: ResponseCode[] = [];
    const accessGroup = new AccessGroup(responseCodesConfig.data);

    if (accessGroup) {
      [200, 201, 204].forEach(code => {
        overrideCodes.push(new ResponseCode(
          code,
          '<hr/>User Group "' + accessGroup.getDisplayName() + '" has been ' + responseCodesConfig.responseCodeType['actions'][0],
          'User Group ' + _.capitalize(responseCodesConfig.responseCodeType['actions'][0]),
          NotificationType.INFO
        ));
      });
    }
    const errorMessage = this.get500ErrorMessage(responseCodesConfig.resp);
    overrideCodes.push(new ResponseCode(
      500,
      !_.isEmpty(errorMessage) ? '<hr/>' + errorMessage : '',
      'Error ' + responseCodesConfig.responseCodeType['actions'][1] + ' User Group',
      NotificationType.ERROR
    ));
    if (responseCodesConfig.resp['error']) {
      if (responseCodesConfig.resp.status === 409) {
        overrideCodes.push(new ResponseCode(
          409,
          !_.isEmpty(responseCodesConfig.resp) ? '<hr/>' + responseCodesConfig.resp['error']['siqApi']['errors'][0]['message'] : '',
          'Error ' + responseCodesConfig.responseCodeType['actions'][1] + ' User Group',
          NotificationType.ERROR
        ));
      } else if (responseCodesConfig.resp.status === 422) {
        overrideCodes.push(new ResponseCode(
          422,
          '<hr/>Unprocessable entity.',
          'Unprocessable Entity',
          NotificationType.ERROR
        ));
      } else {
        overrideCodes.push(new ResponseCode(
          400,
          '<hr/>An unknown error has occurred.',
          'Unknown Error',
          NotificationType.ERROR
        ));
      }
    }

    return new ResponseCodes(overrideCodes);
  }

  /**
   * Handles AccessGroup setup complete operations (polling)
   * @param accessGroups: newest list of AccessGroups
   */
  public poll(accessGroups: AccessGroup[]) {
    const incompleteAccessGroups: AccessGroup[] = accessGroups.filter(ag => ag.getStatus() === AccessGroupStatus.RUNNING);

    // Poll again if any activities are incomplete
    if (incompleteAccessGroups.length) {
      console.log('Found incomplete; polling...');
      setTimeout(() => AccessGroupService.refresh(), AccessGroupService.PollInterval);
    }
  }

  /**
   * Periodically loads the AccessGroup until it is not RUNNING
   * @param id id of AccessGroup
   */
   public validateGroupStatus(id: string) {
    // Do not attempt to getAccessGroup if id is null; Null accessGroup is handled in AuthGuard
    if (_.isNil(id)) return;

    const poll = () => {
      this.getAccessGroup(id).pipe(take(1)).subscribe(ag => {
        const group: AccessGroup = new AccessGroup(ag);
        if (group.getStatus() !== AccessGroupStatus.RUNNING) {
          AccessGroupService.CurrentUserAccessGroup$.next(ag); // set this so that it can be referenced by share/schedule modal (recipient-list.component)
          AccessGroupService.AccessGroupReady$.next(true);
        } else {
          setTimeout(() => poll(), 5000);
        }
      });
    };

    AppLoadingService.addBlocker(AccessGroupService.AccessGroupReady$, 'Waiting for Tables...');
    poll();
  }

  private convertResponse(jsonAccessGroups: AccessGroupDTO[]) {
    return _.map(jsonAccessGroups, (accessGroupDTO) => {
      return new AccessGroup(accessGroupDTO);
    });
  }

  public retrieveAccessGroups(): void {
    this.get({endpoint: AccessGroupService.AccessGroupEndpoint})
    .pipe(
      map((res: AccessGroupDTO[]) => this.convertResponse(res) as AccessGroup[]),
      catchError(this.handleHttpError)
    )
    .subscribe(
      accessGroups => AccessGroupService.AccessGroups$.next(accessGroups),
      err => {throw new Error(err); }
    );
  }
}


export enum FilterNames {
  // AG Grid Filters
  NUMBER = <any>'agNumberColumnFilter',
  TEXT = <any>'agTextColumnFilter',
  DATE = <any>'agDateColumnFilter',
  SET = <any>'agSetColumnFilter',

  // Custom Filters
  YEAR = <any> 'yearFilter',
  YEAR_MONTH = <any>'yearMonthFilter',
  YEAR_MONTH_DAY = <any>'yearMonthDayFilter',
  MONTH = <any>'monthFilter',
  DAY_OF_WEEK = <any>'dayOfWeekFilter',
  DAY = <any>'dayFilter',
  DAYPART = <any>'daypartFilter',
  FORMATTED_NUMBER = <any> 'formattedNumberFilter',
  HOUR = <any>'hourFilter',
  PERCENTAGE = <any> 'percentageFilter'
}

// iExcelCreator specifies the allowable strings in type "ExcelDataType"; However there is no enum for these values.
import { ExcelDataType } from 'libs/visual-lib/src';

export class ExcelDataTypeStatic {
  public static EXCEL_BOOLEAN: ExcelDataType = 'Boolean';
  public static EXCEL_DATETIME: ExcelDataType = 'DateTime';
  public static EXCEL_ERROR: ExcelDataType = 'Error';
  public static EXCEL_FORMULA: ExcelDataType = 'Formula';
  public static EXCEL_NUMBER: ExcelDataType = 'Number';
  public static EXCEL_STRING: ExcelDataType = 'String';
}

import * as _ from 'lodash';
import { AppComponent } from 'app/app.component';
import { AuthService } from 'app/auth/services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
  } from '@angular/forms';
import { map } from 'rxjs';
import { NewUserService } from 'app/core/services/new-user/new-user.service';
import { PasswordRulesComponent } from 'app/siq-forms/components/password-rules/password-rules.component';
import { Router } from '@angular/router';
import { NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { forbiddenRegExpValidator } from 'app/siq-forms/validators/forbidden-regexp.directive';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';

@Component({
  selector: 'siq-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  @ViewChild(PasswordRulesComponent) passwordRules: PasswordRulesComponent;

  public error: string;
  public form: UntypedFormGroup;
  public haveCurrentPassword = false;
  public model: {
    step: 'password' | 'securityQuestions',
    submitDisabled: boolean;
    availableQuestions?: string[]
  };
  public setPassword = false;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private newUserService: NewUserService,
    private router: Router
  ) {
    NavSecondaryService.close();
    AppComponent.visible.primary = false;
    this.getStep();
  }

  ngOnInit() {}

  private getStep() {
    this.model = null;
    this.form = null;

    setTimeout(() => {
      const user = AuthService.CurrentUser$.getValue();

      if (user.passwordExpired) {
        this.setPassword = true;
        this.setupPasswords();
      } else if (user.securityQuestionCount < NewUserService.securityQuestionsCount) {
        this.setupSecurityQuestions();
      } else {
        this.resolve();
      }
    });
  }

  public validatePasswords() {
    setTimeout(() => this.model.submitDisabled = this.passwordRules && !this.passwordRules.validatePasswords());
  }

  public submit() {
    if (this.model.step === 'password') {
      this.newUserService.setPassword({
        password: this.form.get('currentPassword').value,
        newPassword: this.form.get('newPassword').value,
        newPasswordConfirmation: this.form.get('newPasswordConfirmation').value
      }).subscribe(
        res => this.updateUserModel(),
        error => {
          this.error = error.error;
        }
      );
    } else if (this.model.step === 'securityQuestions') {
      this.newUserService.setSecurityQuestions(this.form)
        .pipe(
          map(res => res.body)
        )
        .subscribe(res => {
          if (res.error) {
            this.error = res.error;
          } else {
            this.error = '';
            this.updateUserModel();
          }
        });
    }
  }

  // Set up model & form for password
  private setupPasswords() {
    this.model = {
      step: 'password',
      submitDisabled: true
    };
    const user = AuthService.CurrentUser$.getValue();
    this.haveCurrentPassword = !!_.size(user.password);

    this.form = this.formBuilder.group({
      currentPassword: this.formBuilder.control(user.password, [Validators.required]),
      newPassword: this.formBuilder.control('', [Validators.required]),
      newPasswordConfirmation: this.formBuilder.control('', [Validators.required])
    });
  }

  // Set up model & form for security questions
  private setupSecurityQuestions() {
    this.newUserService.getSecurityQuestions()
      .subscribe(questions => {
        this.model = {
          step: 'securityQuestions',
          submitDisabled: false,
          availableQuestions: questions
        };

        this.form = this.formBuilder.group({
          questions: this.formBuilder.array([]),
          answers: this.formBuilder.array([])
        });

        for (let i = 0; i < NewUserService.securityQuestionsCount; i++) {
          (this.form.get('questions') as UntypedFormArray).push(new UntypedFormControl('', [Validators.required]));
          (this.form.get('answers') as UntypedFormArray).push(new UntypedFormControl('', [Validators.required, forbiddenRegExpValidator(/^\s+$/, `Input can not be white space only.`, 'white-space-only')]));
        }
      });
  }

  // Wrap up new user setup
  private resolve() {
    // display the primary nav bar
    AppComponent.visible.primary = true;
    NavSecondaryService.open();
    this.router.navigateByUrl(AppLoadingService.defaultPath);
  }

  private updateUserModel() {
    // Update the local user model & local storage with the correct fields
    const user = AuthService.CurrentUser$.getValue();

    if (this.model.step === 'password') {
      delete user.password;
      user.passwordExpired = false;
      this.authService.logout().subscribe(() => this.router.navigate(['/login']));
    } else if (this.model.step === 'securityQuestions') {
      user.securityQuestionCount = NewUserService.securityQuestionsCount;
      this.authService.setCurrentUser(user);
      this.getStep();
    }

  }
}

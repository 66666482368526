import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { EnvConfig, EnvConfigService, PLATFORM_MODE } from 'app/core/services/env-config/env-config.service';
import { BehaviorSubject } from 'rxjs';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { takeUntil } from 'rxjs';
import { Validation, ValidationService } from 'app/siq-applications/services/validation/validation.service';
import { AuthService } from 'app/auth/services/auth.service';

@Component({
  selector: 'siq-query-mode',
  templateUrl: './query-mode.component.html',
  styleUrls: ['./query-mode.component.scss']
})
export class QueryModeComponent extends BaseSiqComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean;
  @Input() disableMultiRetailer = false;
  @Input() modalConfig?: QueryModeModalConfig;
  @Input() overrideSchema?: string; // ICE-4180, for cloned report to display correct schema in query mode

  public dropdownModel: string; // this is used only for holding/overriding the state of the dropdown component
  public primarySchema: string; // pointer to main schema of the environment
  public schemas: string[] = []; // list of schemas available
  public schema$: BehaviorSubject<string> = new BehaviorSubject<string>(null); // handles the state & modifications of the current schema selection
  public singleRetailerOnly = false;
  public visible = false;

  constructor(
    private asyncStatusService: AsyncStatusService,
    private utils: UtilsService,
    private validationService: ValidationService
  ) {
    super();
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ envConfig: true, cms: true, filterValues: true });
    const envConfig = EnvConfigService.getConfig();
    this.visible = await this.getVisibility(envConfig);
    this.singleRetailerOnly = envConfig.platformMode === PLATFORM_MODE.SINGLE_ONLY;
    this.primarySchema = envConfig.primaryEntity;
    this.schemas = envConfig.retailers || this.schemas;

    if (EnvConfigService.isMultiMode()) this.schema$.next(this.primarySchema);

    // TODO: user preferred query mode can also be set here later

    const defaultSchema = UtilsService.getQueryModeDefaultSchema(this.overrideSchema, this.disableMultiRetailer || this.singleRetailerOnly);
    if (this.schemas.length === 1) this.disabled = true;
    this.setSchema(defaultSchema);

    // link the dropdownModel to the schema$ obs, this ensures that any changes to the observable gets propagated to the mat-select component
    this.schema$
      .pipe(takeUntil(this.unsub$))
      .subscribe(schema => {
        this.dropdownModel = schema;
        UtilsService.setQueryModeSchema(schema); // Makes this value accessible anywhere
      });

    this.ready();
  }

  ngOnDestroy(): void {
    this.schema$.complete();
    super.ngOnDestroy();
  }

  public updateSelection(newSchema: string) {
    let openModal = true;
    if (this.modalConfig) {
      // if a diff function was passed in, use it to determine whether the modal needs to be opened, otherwise default to true
      if (this.modalConfig.diffFn) {
        openModal = this.modalConfig.diffFn(newSchema);
      }
    }

    if (openModal) {
      this.utils.openModal(this.modalConfig.component as any, {
        data: this.modalConfig.dataGetter(), schema: newSchema
      }, {
        width: 'fit-content',
        maxWidth: '85vw',
        role: 'dialog'
      })
        .afterClosed()
        .subscribe(async confirm => {
          if (confirm) {
            // if the user confirmed, set the new schema
            this.setSchema(newSchema);
          } else {
            // otherwise, reset the schema selection to the previous (current) value

            /**
             * Before SSS, this line was "this.setSchema(this.getSchema())". 
             * However, in SSS, multi-mode, this action of resetting the same schema will
             * cause the BaseFilterGroupComponent, line 66 to remove the existing retailer store id filters.
             * Thus, just reset the dropdownModel which will not trigger schema$
             */
            this.dropdownModel = this.getSchema();
          }
        });
    } else {
      // if no modal config is passed in, just set the schema
      this.setSchema(newSchema);
    }
  }

  // getter of state
  public getSchema(): string {
    return this.schema$.getValue();
  }

  // setter of state
  public setSchema(schema: string) {
    if (!this.schemas.includes(schema) || EnvConfigService.isMultiMode()) {
      schema = this.primarySchema;
    }

    this.validationService.availableValidations.get(Validation.LOYALTY)(schema);
    this.schema$.next(schema);
  }

  private async getVisibility(envConfig: EnvConfig): Promise<boolean> {
    switch(envConfig.platformMode) {
      case PLATFORM_MODE.STANDARD: return Promise.resolve(true);
      case PLATFORM_MODE.SINGLE_ONLY: return AuthService.CurrentUser$.getValue().isAdmin();
      case PLATFORM_MODE.MULTI_ONLY: return Promise.resolve(false);
    }
  }
}

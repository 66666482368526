import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FiltersetResponse } from 'app/filtersets/models/filterset-response.model';
import { FiltersetsService } from 'app/filtersets/services/filtersets.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CmsField } from '@siq-js/cms-lib';
import { FiltersetInterface, FiltersetPayloadInterface } from 'app/filtersets/models/interfaces';

@Component({
  selector: 'siq-js-save-filterset-modal',
  templateUrl: './save-filterset-modal.component.html',
  styleUrls: ['./save-filterset-modal.component.scss']
})
export class SaveFiltersetModalComponent extends BaseSiqComponent implements OnInit {

  public model: {
    name: string
    op: 'save' | 'edit',
    filterModels: {
      name: string,
      desc: string,
      include: boolean
    }[]
  };

  public lwhEnabled: boolean;

  private filtersets: FiltersetInterface[];
  private id: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      formArr?: FilterSelection[],
      filtersetResponse?: FiltersetResponse,
      lwhEnabled?: boolean
    },
    private dialogRef: MatDialogRef<SaveFiltersetModalComponent>,
    private filtersetsService: FiltersetsService
  ) {
    super();
    this.filtersets = [];
    this.id = null;
    this.model = null;
  }

  ngOnInit(): void {
    this.lwhEnabled = !!this.data.lwhEnabled;
    if (this.data) {
      // Save operation
      this.setupSave(this.data.formArr);
    } else if (this.data.filtersetResponse) {
      // Edit operation
      this.setupEdit(this.data.filtersetResponse);
    }
  }

  save() {
    let payload: FiltersetPayloadInterface = {
      name: this.model.name,
      filterSets: this.filtersets
    };
    if (this.id) {
      payload.id = this.id;
    }
    this.filtersetsService.saveFilterset(payload);
    this.dialogRef.close(true);
  }

  // If the FilterGroupComponent's filterFormArray is passed in, this is a save operation
  private setupSave(formArr: FilterSelection[]) {
    formArr.forEach(f => {
      this.filtersets.push(
        {
          id: f.field.id,
          filterType: f.field.field,
          values: f.values,
          outNull: f.nulls,
          inNull: f.nulls,
          include: f.include
        }
      );
    });

    this.model = {
      name: '',
      op: 'save',
      filterModels: this.filtersets.map(f => this.filtersetToModel(f))
    };
  }

  // If an already created FiltersetResponse is passed in, this is an edit operation
  private setupEdit(fs: FiltersetResponse) {

    this.filtersets = fs.filterSets.map(f => {
      return ({
        id: f.id,
        filterType: f.filterType,
        include: f.include,
        inNull: f.inNull,
        outNull: f.outNull,
        values: f.values
      });
    });

    this.id = fs.id;

    this.model = {
      name: fs.name,
      op: 'edit',
      filterModels: this.filtersets.map(f => this.filtersetToModel(f))
    };
  }

  private filtersetToModel(fs: FiltersetInterface): { name: string, desc: string, include: boolean } {

    let desc = '';
    let displayName = fs.id && CmsService.get() ? CmsService.get().findEntity<CmsField>(fs.id).display : fs.filterType;

    if (fs.values.length) {
      desc = fs.values.length + ' ';
      if (fs.inNull || fs.outNull) {
        desc += ' and ';
      }
    }
    if (fs.inNull || fs.outNull) {
      desc += 'Unknowns ';
    }
    return {
      name: displayName,
      desc: desc + (fs.include ? 'included' : 'excluded'),
      include: fs.include
    };
  }

}

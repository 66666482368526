import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { coreRoutes } from 'app/core/routes/core-routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(coreRoutes)
  ],
  exports: [ RouterModule ]
})
export class CoreRoutingModule { }

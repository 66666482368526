import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { KPI, KPIChipClass, PromoPeriods } from 'app/siq-applications/modules/promo/models/promo.enums';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';

@Component({
  selector: 'siq-kpi-chip',
  templateUrl: './kpi-chip.component.html',
  styleUrls: ['./kpi-chip.component.scss']
})
export class KpiChipComponent implements OnInit, OnChanges {

  @Input() factKey: string;
  @Input() metrics: {
    [period in PromoPeriods]: number
  };
  @Input() periodKey: PromoPeriods;
  @Input() periodKeyName: String;

  public cssEnums = KPIChipClass;
  public toolTip: string;

  public model: {
    promoValue: string,
    growthValue: string,
    css: KPIChipClass,
    factName: string
  };

  constructor() {}

  ngOnInit() {
    const factName = PromoConfig.kpiNameMap[this.factKey];

    let css: KPIChipClass;
    if (this.metrics.PROMO === this.metrics[this.periodKey] || this.metrics[this.periodKey] === 0) {
      css = KPIChipClass.NONE;
    } else if (this.metrics.PROMO > this.metrics[this.periodKey]) {
      css = KPIChipClass.POSITIVE;
    } else {
      css = KPIChipClass.NEGATIVE;
    }

    const factFormatter = GridService.getVisualType(this.determineFactType(this.factKey)).formatter;
    const percentFormatter = GridService.getVisualType('PERCENT_TOTAL').formatter;

    const _promoVal = factFormatter({value: this.metrics.PROMO });
    const _preVal = factFormatter({value: this.metrics[this.periodKey]});
    const _changeVal = factFormatter({value: this.metrics.PROMO - this.metrics[this.periodKey]});
    const _growthVal = percentFormatter({value: (this.metrics.PROMO - this.metrics[this.periodKey]) / this.metrics[this.periodKey]});

    this.model = {
      factName: factName,
      promoValue: _promoVal,
      growthValue: _growthVal,
      css: css
    };

    const toolTips = [
      `Promo Amount: ${_promoVal}`,
      `${this.periodKeyName} Amount: ${_preVal}`,
      `Change: ${_changeVal}`
    ];

    if (this.model.css !== KPIChipClass.NONE) {
      toolTips.push(`% Change: ${_growthVal}`);
    }
    this.toolTip = toolTips.join('\n');
  }

  ngOnChanges(changes: SimpleChanges) {
    const metricsChange = changes.metrics;
    const periodKeyChange = changes.periodKey;
    let somethingChanged = false;
    if (metricsChange && !metricsChange.isFirstChange()) {
      this.metrics = metricsChange.currentValue;
      somethingChanged = true;
    }
    if (periodKeyChange && !periodKeyChange.isFirstChange()) {
      this.periodKey = periodKeyChange.currentValue;
      somethingChanged = true;
    }
    if (somethingChanged) {
      this.ngOnInit();
    }
  }

  private determineFactType(kpi: string) {
    let type: string;

    switch (kpi) {
      case KPI.TOTAL_AMOUNT:
        type = 'DOLLAR_SALES';
        break;
      case KPI.AVG_PRICE:
      case KPI.BASKET_TOTAL_AMOUNT:
        type = 'DOLLAR';
        break;
      default:
        type = 'VOLUME';
        break;
    }
    return type;
  }

}

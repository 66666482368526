import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  // Capitalizes the first letter of each word, with the rest lowercase
  // 'THIS is A tEsT' => 'This Is A Test'
  transform(value: string, args?: any): string {
    if (!value) {
      return value;
    }
    try {
      let words = value.split(' ').map(word => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase());
      return words.join(' ');
    } catch (e) {
      console.log("CapitalizePipe.transform ->Crash, to be investigated");
      return value;
    }
  }

}

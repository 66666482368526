<div class="affinities-form floating-box">

  <div class="stepper-header-outside">
    <mat-slide-toggle [(ngModel)]="expanded" [color]="'accent'">Expand</mat-slide-toggle>
  </div>

  <mat-vertical-stepper [ngClass]="{'expand-all': expanded}" color="accent">
    <mat-step [hasError]="stepOneError">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 1: Affinity Type(s)</h4>
          <siq-js-step-form-message
            *ngIf="affinityOption === affinityType.STANDARD"
            [validationMessage]="stepValidationMessages[0] ? stepValidationMessages[0] : {status: 'UNSET', message: 'Select an Affinity Type'}"
          >
          </siq-js-step-form-message>
          <siq-js-step-form-message
            *ngIf="affinityOption === affinityType.ASYMMETRIC"
            [validationMessage]="stepValidationMessages[0] ? stepValidationMessages[0] : {status: 'UNSET', message: 'Select Left and Right Affinity Types'}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container">
        <mat-radio-group
          [(ngModel)]="affinityOption"
          (change)="affinityOptionChanged($event.value)"
          aria-label="Select an Affinity option"
          color="accent"
        >
          <mat-radio-button value="{{affinityType.STANDARD}}">Standard</mat-radio-button>
          <mat-radio-button value="{{affinityType.ASYMMETRIC}}">Asymmetric</mat-radio-button>
        </mat-radio-group>
        <siq-js-entity-selector
            *ngIf="affinityOption === affinityType.STANDARD"
            [entityData]="filteredData"
            [allowDuplicates]="true"
            [minCount]="1"
            [maxCount]="1"
            [initModel]="initModel"
            [placeholders]="['Affinity Type']"
            (formEmitter)="affinitiesTypeChanged($event)"
          >
          </siq-js-entity-selector>
        <siq-js-entity-selector
            *ngIf="affinityOption === affinityType.ASYMMETRIC"
            [entityData]="filteredData"
            [allowDuplicates]="false"
            [minCount]="2"
            [maxCount]="2"
            [initModel]="initModel"
            [placeholders]="['Left Type', 'Right Type']"
            (formEmitter)="affinitiesTypeChanged($event)"
          >
          </siq-js-entity-selector>
      </div>
      <div *ngIf="!expanded" class="step-button-bar shift-end">
<!--        <button mat-button matStepperPrevious>Back</button>-->
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [hasError]="!isDateRangeValid">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 2: Date Range</h4>
          <siq-js-step-form-message
              [validationMessage]="stepValidationMessages[1] && isDateRangeValid ? stepValidationMessages[1] : {status: 'UNSET', message: 'Select Date Range'}"
            >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container">
        <siq-js-date-selection
          *ngIf="formData && dateSelectionConfig"
          [config]="dateSelectionConfig"
          [initModel]="formData.dateRanges"
          (onDateChanged)="dateChanged($event)"
          (onWeekEndingDayChanged)="weekEndingDayChanged($event)"
          ></siq-js-date-selection>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread push-down">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 3: Transaction Filter</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[2] ? stepValidationMessages[2] : {status: 'UNSET', message: 'Select Transaction Filter(s)'}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container transcation-filters-container">
        <siq-filter-group
          #transactionFilters
          [schemaController]="queryMode.schema$"
          [initModel]="formData.filters"
          [mapFn]="transactionMapFn"
          (emitter)="filterGroupChanged($event, affinityFilterType.TRANSACTION)"
        ></siq-filter-group>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 4: Item Filters</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[3] ? stepValidationMessages[3] : {status: 'UNSET', message: 'Select Left & Right Filters'}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="item-filters-container step-container">
        <div class="left-filter-container">
          <siq-filter-group
            [addFilterBtnDisplay]="'Add a Left Filter'"
            [schemaController]="queryMode.schema$"
            [initModel]="formData.leftFilters"
            [mapFn]="itemsMapFn"
            (emitter)="filterGroupChanged($event, affinityFilterType.LEFT)"
          ></siq-filter-group>
        </div>
        <div class="right-filter-container">
          <siq-filter-group
            [addFilterBtnDisplay]="'Add a Right Filter'"
            [schemaController]="queryMode.schema$"
            [initModel]="formData.rightFilters"
            [mapFn]="itemsMapFn"
            (emitter)="filterGroupChanged($event, affinityFilterType.RIGHT)"
          ></siq-filter-group>
        </div>
      </div >
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class="step-button-bar" [ngClass]="expanded ? 'shift-end' : 'spread'">
        <button mat-button matStepperPrevious *ngIf="!expanded">Back</button>
        <div>
          <mat-form-field class="user-activity-name">
            <mat-label>Name Activity (Optional)</mat-label>
            <input matInput type="text" [(ngModel)]="formData.name">
            <button mat-button *ngIf="formData.name" matSuffix mat-icon-button aria-label="Clear" (click)="formData.name=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="disableSubmitBtn"
            (click)="onSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>

</div>

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

export enum MonthMode {
  DEFAULT = 'byMonthDay',
  DAY = 'byDay'
}

@Component({
  selector: 'siq-frequency-month',
  templateUrl: './frequency-month.component.html',
  styleUrls: ['./frequency-month.component.scss']
})
export class FrequencyMonthComponent implements OnInit {
  @Input() frqPosted: string;
  @Input() formControlByDay: UntypedFormControl;
  @Input() formControlByMonthDay: UntypedFormControl;
  public monthMode = MonthMode.DEFAULT;
  public monthDays = this.getOrdinalArray(1, 31);
  public monthDayValue = this.monthDays[0];
  public weekdays = DatesService.getWeekdays();
  public weekIndexes = this.getOrdinalArray(1, 4);
  public monthWeekIndex = this.weekIndexes[0];
  public monthWeekValue = this.weekdays[0];

  ngOnInit() {
    if (this.frqPosted === 'MONTHLY') {
      if (!_.isNil(this.formControlByDay.value) && this.formControlByDay.value !== '') {
        this.monthMode = MonthMode.DAY;
        let temp = this.formControlByDay.value;
        this.monthWeekIndex = this.getDto2OrdinalArray(this.weekIndexes, parseInt(temp.substring(0, temp.length - 2), 10));
        this.monthWeekValue = this.weekdays
          .filter(day => day.substring(0, 2).toUpperCase() === temp.substring(temp.length - 2, temp.length))[0];
      } else if (!_.isNil(this.formControlByMonthDay.value) && this.formControlByDay.value !== '') {
        this.monthDayValue = this.getDto2OrdinalArray(this.monthDays, this.formControlByMonthDay.value);
      }
    }
    this.updateForm();
  }

  getOrdinalArray(start, end) {
    let res = [];
    let ordinal = ['th', 'st', 'nd', 'rd'];
    for (let i = start; i <= end; i++) {
      res.push(i + (ordinal[(i - 20) % 10] || ordinal[i] || ordinal[0]));
    }
    res.push('Last');
    return res;
  }

  getDto2OrdinalArray(array, index) {
    if (index >= array.length) return 0;
    if (index === -1) return array[array.length - 1];
    return array[index - 1];
  }

  getNumberFromString(str) {
    let res = parseInt(str, 10);
    return res > 0 ? res : -1;
  }

  updateForm() {
    if (this.monthMode === MonthMode.DAY && this.getNumberFromString(this.monthWeekIndex) === -1) {
      // handle special case for "last weekday", description follow SIQV3-2719
      this.formControlByDay.setValue(this.monthWeekValue.substring(0, 2).toUpperCase());
      this.formControlByMonthDay.setValue(-1);
    } else {
      this.formControlByMonthDay
        .setValue(this.monthMode === MonthMode.DEFAULT ? this.getNumberFromString(this.monthDayValue) : null);
      this.formControlByDay
        .setValue(this.monthMode === MonthMode.DAY ?
          this.getNumberFromString(this.monthWeekIndex) + this.monthWeekValue.substring(0, 2).toUpperCase() : null);
    }
  }
}

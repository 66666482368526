import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Activity } from 'app/activity/models/activity.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CustomInjector } from 'app/core/models/custom-injector';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs';
import { Input, OnDestroy, OnInit, Directive } from '@angular/core';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';

@Directive()
export class SiqApplicationActivity implements OnInit, OnDestroy {

  @Input() activityOverrideId: string; // used by dashboards to set an activity without route params

  public activity: BehaviorSubject<Activity> = new BehaviorSubject<Activity>(new Activity());
  public waitingForActivity = new BehaviorSubject<boolean>(false);
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  protected router: Router;
  protected newActivitySub = new Subject<void>();

  constructor (
    public route: ActivatedRoute,
    protected activityService: ActivityService
  ) {
    this.router = CustomInjector.injector.get(Router);
  }

  private requestActivity (id: string) {

    this.waitingForActivity.next(true);

    this.activityService
      .getActivity({
        id: id,
        resultType: ActivityResultType.NO_RESULTS
      })
      .pipe(takeUntil(this.newActivitySub))
      .subscribe(activity => {
        this.activity.next(activity);
        this.waitingForActivity.next(false);
      });

  }

  ngOnInit () {

    if (this.activityOverrideId) {

      this.requestActivity(this.activityOverrideId);

    } else {

      this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((e: NavigationEnd) => {
          this.activity.next(this.activity.value);
        });

      this.route.params
        .pipe(
          distinctUntilChanged(),
          filter(params => params.id && params.id !== '-1'),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(params => {
          this.newActivitySub.next();
          this.requestActivity(params.id);
        });

    }

  }

  ngOnDestroy() {
    this.destroy();
  }

  protected destroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

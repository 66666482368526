<div class="promo-form floating-box">

  <div class="stepper-header-outside">
    <mat-slide-toggle [(ngModel)]="expanded" [color]="'accent'">Expand</mat-slide-toggle>
  </div>

  <mat-vertical-stepper [ngClass]="{'expand-all': expanded}" color="accent">

    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 1: Activity Type</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[0] ? stepValidationMessages[0] : {status: validationMessageStatus.UNSET, message: promoTypeMessage}"
          >
          </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container">
        <siq-js-entity-selector
          [entityData]="filteredData"
          [allowDuplicates]="false"
          [minCount]="1"
          [maxCount]="1"
          [initModel]="initModel"
          [placeholders]="['Activity Type']"
          (formEmitter)="activitiesTypeChanged($event)"
        >
        </siq-js-entity-selector>
      </div>
      <div *ngIf="!expanded" class="step-button-bar shift-end">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [hasError]="upcRequiredError">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 2: UPC's</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[1] ? stepValidationMessages[1] : {status: validationMessageStatus.UNSET, message: 'Select UPC Filter(s)'}"
          >
          </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container">
        <siq-js-upc-selection
          [datesUpdated]="datesUpdated$"
          [queryMode]="queryMode"
          [formData]="formData"
          (validationChange)="validateUpcSelection($event)"
        ></siq-js-upc-selection>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [hasError]="promoDatesRequiredError">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 3: Promo Date Range</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[2] && !promoDatesRequiredError ? stepValidationMessages[2] : {status: validationMessageStatus.UNSET, message: 'Select Promo Date Range'}"
          >
          </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container">

        <siq-js-date-selection
          #promoDateSelectionComponent
          *ngIf="formData.promoDateRange && dateSelectionConfig"
          [config]="dateSelectionConfig"
          [initModel]="formData.promoDateRangeInterface"
          (onDateChanged)="dateChanged($event)"
          ></siq-js-date-selection>

      </div>
      <div *ngIf="!expanded" class="step-button-bar spread push-down">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [hasError]="comparisonDateError">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 4: Comparison Date Ranges</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[3] && !comparisonDateError ? stepValidationMessages[3] : {status: validationMessageStatus.UNSET, message: 'Select Comparison Date Ranges'}"
          >
          </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container">
        <siq-js-comparison-dates-selection
          [datesUpdated]="datesUpdated$"
          [baseDate]="formData.promoDateRange"
          [formData]="formData"
          [dateSelectionConfig]="dateSelectionConfig"
          (datesSelected)="updateValidationMessage(3, $event.status, $event.message)"
        >
        </siq-js-comparison-dates-selection>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread push-down">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 5: Transaction Filters</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[4] ? stepValidationMessages[4] : {status: validationMessageStatus.UNSET, message: transactionStepMessage}"
          >
          </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container filter-groups-container">
        <siq-filter-group
          [schemaController]="queryMode.schema$"
          [mapFn]="transactionMapFn"
          [initModel]="formData.locationFilters"
          (emitter)="filterGroupChanged($event, promoFilterType.LOCATION)"
        ></siq-filter-group>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 6: Comparison Groups</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[5] ? stepValidationMessages[5] : {status: validationMessageStatus.UNSET, message: comparisonGroupMessage}"
          >
          </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container">
        <siq-js-comparison-groups-holder
          [schema$]="queryMode.schema$"
          [initComparisonGroups]="formData.comparisonGroups"
          [comparisonGroups$]="comparisonGroups$"
        >
        </siq-js-comparison-groups-holder>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread push-down">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 7: Source of Volume</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[6] ? stepValidationMessages[6] : {status: validationMessageStatus.UNSET, message: sovMessage}"
          >
        </siq-js-step-form-message>
        </header>

      </ng-template>

      <div class="step-container">
        <siq-js-sov-holder
          [comparisonGroups$]="comparisonGroups$"
          [schema$]="queryMode.schema$"
          [initSovs]="formData.sourcesOfVolume"
          [sovs$]="sovs$"
        >
        </siq-js-sov-holder>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread push-down">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class="step-button-bar" [ngClass]="expanded ? 'shift-end' : 'spread'">
        <button mat-button matStepperPrevious *ngIf="!expanded">Back</button>
        <div>
          <mat-form-field class="user-activity-name">
            <mat-label>Name Activity (Optional)</mat-label>
            <input matInput type="text" [(ngModel)]="formData.name">
            <button mat-button *ngIf="formData.name" matSuffix mat-icon-button aria-label="Clear" (click)="formData.name=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="disableSubmitBtn"
            (click)="onSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-step>

  </mat-vertical-stepper>

</div>

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { unauthorizedRoutes } from 'app/unauthorized/routes/unauthorized-routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(unauthorizedRoutes)
  ],
  exports: [ RouterModule ]
})
export class UnauthorizedRoutingModule { }

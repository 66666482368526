import { CmsEntity, CmsEntityJson } from './cms-entity';

export interface CmsMetricJson extends CmsEntityJson {
  // configurable
  aggType: string;
  type: string;
  excelFormula?: string;
}

export class CmsMetric extends CmsEntity<CmsMetricJson> {
  aggType: string;
  type: string;
  excelFormula: string;

  constructor(model: CmsMetricJson) {
    super(model);

    this.aggType = model.aggType;
    this.type = model.type;
    this.excelFormula = model.excelFormula || '';
  }

  toJson(): CmsMetricJson {
    return {
      ...super.toJson(),
      aggType: this.aggType,
      type: this.type,
      excelFormula: this.excelFormula
    };
  }

  canAgg(): boolean {
    return this.aggType !== 'NA' && !!this.aggType;
  }

  clone(): CmsMetric {
    return new CmsMetric(this.toJson());
  }
}

<siq-app-header [config]="config"></siq-app-header>
<button
  mat-raised-button
  color="accent"
  class="new-report-button"
  (click)="toForm()"
>Create New</button>



<div class="app-container-wrapper no-bg">

  <section class="app-container limited-width-content content-padded">
    <mat-card [ngStyle]="bubbleContainerStyles">
      <siq-js-simple-bubble
      *ngIf="(ready$ | async) && promoTypesReady"
      [data]="bubbleParams"
      [processor]="bubbleProcessor"
      >
      </siq-js-simple-bubble>
    </mat-card>
  </section>

  <mat-card class="promo-panel">

    <label>Y Axis</label>
    <mat-form-field color="accent">
      <mat-select [(value)]="yMetric" (valueChange)="render()">
        <mat-optgroup *ngFor="let metricGroup of metrics" [label]="metricGroup.name">
          <mat-option *ngFor="let metric of metricGroup.items" [value]="metric.key">
            {{ metric.name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <label>X Axis</label>
    <mat-form-field color="accent">
      <mat-select [(value)]="xMetric" (valueChange)="render()">
        <mat-optgroup *ngFor="let metricGroup of metrics" [label]="metricGroup.name">
          <mat-option *ngFor="let metric of metricGroup.items" [value]="metric.key">
            {{ metric.name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <label>Bubble Width</label>
    <mat-form-field color="accent">
      <mat-select [(value)]="zMetric" (valueChange)="render()">
        <mat-optgroup *ngFor="let metricGroup of metrics" [label]="metricGroup.name">
          <mat-option *ngFor="let metric of metricGroup.items" [value]="metric.key">
            {{ metric.name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <section class="app-container limited-width-content content-padded">
    <div class="promo-list-filters">
      <mat-form-field floatLabel="never" class="promo-type-filter" color="accent">
        <mat-select [(value)]="promoTypeFilter" placeholder="All Promos" (valueChange)="updateListFilter()">
          <mat-option [value]="null">All Promos</mat-option>
          <mat-option *ngFor="let promoType of promoTypes" [value]="promoType">
            {{ promoType }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <siq-js-date-selection
        *ngIf="dateSelectionConfig"
        [config]="dateSelectionConfig"
        [disableInitModelValidation]="true"
        (onDateChanged)="updateDateFilter($event)"
        ></siq-js-date-selection>
    
      <mat-icon *ngIf="dateFilterEnabled" (click)="onClearDates()" class="cancel-icon">cancel</mat-icon>

    </div>
    <siq-activity-grid
      [activityStream$]="activityStream$"
      [columns]="columns"
      [actions]="actions"
      [callbacks]="callbacks"
      [tabs]="tabs"
      (renderedActivities)="updateRenderedStream($event)"
      ></siq-activity-grid>
    </section>
</div>



import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { LimitedLengthTextFormErrorStateMatcher } from 'app/core/components/limited-length-text/limited-length-text-form-error-state-matcher';
import * as _ from 'lodash';

@Component({
  selector: 'siq-limited-length-text',
  templateUrl: './limited-length-text.component.html',
  styleUrls: ['./limited-length-text.component.scss']
})
export class LimitedLengthTextComponent implements OnInit {

  public matcher = new LimitedLengthTextFormErrorStateMatcher();
  @Input() formControl = new UntypedFormControl();
  @Input() maxLength = 300;
  @Input() placeholder = 'Message';
  @Input() rows: number;
  @Input()
  get model(): string {
    return this.formControl.value;
  }
  set model(description: string) {
    this.formControl.setValue(description);
    this.modelChange.emit(this.model);
  }

  @Output() modelChange = new EventEmitter();

  constructor() {
    this.model = this.model || '';
  }

  ngOnInit() {
    this.formControl.valueChanges.subscribe(() => this.updateValidity());
  }

  private updateValidity(): void {
    if (_.isNil(this.model)) return;
    UtilsService.setFormError(this.formControl, 'maxLength', this.maxLength < this.model.length);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'siq-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent {

  @Input() copy = 'Submit';
  @Input() color = 'primary';
  @Input() spinnerColor = 'accent';
  @Input() disabled = false;
  @Input() submitting = false;

  constructor() { }

}

import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { Injectable } from '@angular/core';
import { ApplicationHash } from '@siq-js/core-lib';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { ExcelStyle, SortOptions } from '@siq-js/visual-lib';
import * as _ from 'lodash';
import { BackgroundColorType } from 'app/core/models/background-color-type';
import { StatResponse } from 'app/core/models/stat-response.model';

export enum AffinitiesFacts {
  OCCURRENCE = 'OCCURRENCE',
  SUPPORT = 'SUPPORT',
  CONFIDENCE = 'CONFIDENCE',
  ADDED_VALUE = 'ADDED_VALUE',
  LIFT = 'LIFT',
  CONVICTION = 'CONVICTION',
  LEFT = 'Left Item',
  RIGHT = 'Right (Affinity) Item'
}
export interface AffinitiesDrillDownSummary {
  occurrences: string;
  support: any;
  confidence: any;
  addedValue: any;
  addedValueCssClass: string;
  lift: string;
  liftCssClass: string;
  conviction: string;
  convictionCssClass: string;
}

@Injectable()
export class AffinitiesConfig extends SiqApplicationConfig {

  public static customFacts = [
    AffinitiesFacts.OCCURRENCE,
    AffinitiesFacts.SUPPORT,
    AffinitiesFacts.CONFIDENCE,
    AffinitiesFacts.ADDED_VALUE,
    AffinitiesFacts.LIFT,
    AffinitiesFacts.CONVICTION
  ];

  public static excelStyles = <ExcelStyle[]>[
    {
      id: AffinitiesFacts.OCCURRENCE,
      numberFormat: {
        format: '#,##0'
      }
    },
    {
      id: AffinitiesFacts.SUPPORT,
      numberFormat: {
        format: '0.0%'
      }
    },
    {
      id: AffinitiesFacts.CONFIDENCE,
      numberFormat: {
        format: '0.0%'
      }
    },
    {
      id: AffinitiesFacts.ADDED_VALUE,
      numberFormat: {
        format: '0.0%'
      }
    },
    {
      id: AffinitiesFacts.LIFT,
      numberFormat: {
        format: '0.000'
      }
    },
    {
      id: AffinitiesFacts.CONVICTION,
      numberFormat: {
        format: '0.000'
      }
    }
  ];

  public static MAX_YEAR_MONTH_DISPLAY = 8;

  public actions: ActivityOptionsActions[] = [
    ActivityOptionsActions.CLONE,
    ActivityOptionsActions.RESULTS,
    ActivityOptionsActions.SHARE,
    ActivityOptionsActions.DELETE
  ];

  public appId = ApplicationHash.AFFINITIES;
  public defaultSort: SortOptions;
  public factMappings: Map<string, string[]> = new Map<string, string[]>();
  public name: 'Affinities';
  public pollInterval = 2000;
  public tooltips: Map<string, string | ((params: any) => string)>;

  constructor() {
    super();

    this.factMappings.set('advanced', [
      AffinitiesFacts.LEFT,
      AffinitiesFacts.RIGHT,
      AffinitiesFacts.OCCURRENCE,
      AffinitiesFacts.SUPPORT,
      AffinitiesFacts.CONFIDENCE,
      AffinitiesFacts.ADDED_VALUE,
      AffinitiesFacts.LIFT,
      AffinitiesFacts.CONVICTION
    ]);

    this.factMappings.set('standard', [
      AffinitiesFacts.LEFT,
      AffinitiesFacts.RIGHT,
      AffinitiesFacts.OCCURRENCE,
      AffinitiesFacts.SUPPORT,
      AffinitiesFacts.CONFIDENCE,
      AffinitiesFacts.ADDED_VALUE
    ]);

    this.defaultSort = {
      sort: 'desc',
      byKey: AffinitiesFacts.OCCURRENCE,
    };

    this.setupTooltips();
  }

  public setupTooltips() {
    this.tooltips = new Map<string, string | ((params: any) => string)>();

    this.tooltips.set('Occurrences', 'Total number of transactions with both left and right affinity items in the basket.');

    this.tooltips.set('Support', '% of total transactions that have both left and right affinity items in basket (occurrences/total transactions).');

    this.tooltips.set('Confidence', '% of transactions with left item that have the right item in basket.');
    this.tooltips.set('Added Value', 'A measure of how much the left item drives the sales of the right item\n' +
    'If Added Value is between -100% and 0, then these two items have a weak correlation. The lower the number, the worse the correlation.\n' +
    'If Added Value is greater than 0 and 100%, then these two items have a strong correlation. The higher the number, the stronger the correlation.');
    this.tooltips.set('Lift', ' A measure of the randomness of the transaction.\n' +
    'If lift is between 0 and 1, then these two items are negatively correlated. This means that the occurrence of the left item has a negative effect on the occurrence of the right item.\n' +
    'If lift is greater than 1, then these two items are positively correlated. This means that the occurrence of the left item has a positive effect on the occurrence of the right item.');
    this.tooltips.set('Conviction', 'This value tells you how much the left item drives the sales of the right item\n' +
    'If conviction is between 0 and 1, then it\'s more likely the customer would not buy the right item when buying the left item. The closer to 0 the number is, the more that likelihood of the customer not buying the right item.\n' +
    'If conviction is greater than 1, the it implies that the customer would buy the right item when buying the left item. The larger the number, the stronger that likelihood is.');
    this.tooltips.set('Left Item', 'Main item being considered for Affinities.');
    this.tooltips.set('Right (Affinity) Item', 'This is the item which has affinity to the left item.');

  }

}

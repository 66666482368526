import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmsField } from '@siq-js/cms-lib';
import { ReportBuilderSheet } from 'app/siq-applications/modules/report-builder/models/results/report-builder-sheet.model';
import { GridService } from '@siq-js/visual-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { GroupedArray, GroupedArrayFunctions } from '@siq-js/core-lib';
import { EntitySelectorStatus } from 'app/siq-forms/components/entity-selector/entity-selector.component';

export interface RBDrilldownInput {
  dataNode: any;
  sheet: ReportBuilderSheet;
  schema: string;
}

@Component({
  selector: 'siq-report-builder-drilldown-modal',
  templateUrl: './report-builder-drilldown-modal.component.html',
  styleUrls: ['./report-builder-drilldown-modal.component.scss']
})
export class ReportBuilderDrilldownModalComponent implements OnInit {

  public model: {
    dimsBreakdown: string[],
    availableDims: GroupedArray<CmsField>
  };

  public selectedDimension: CmsField;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: RBDrilldownInput
  ) { }

  async ngOnInit() {
    this.setupModel();
  }

  public onSelectEntity(entityStatus: EntitySelectorStatus<CmsField>) {
    this.selectedDimension = entityStatus.selectedEntities[0];
  }

  private setupModel() {
    const presentDims = [];
    // Generate drilldown info strings
    const dimsBreakdown = this.params.sheet.sr.getDimensions().map(d => {
      const dV = this.params.dataNode[d.id];
      const formattedDV = GridService.getVisualType(d).formatter({ value: dV });

      presentDims.push(d);

      return `${d.display} (${formattedDV})`;
    });
    const presentIds = new Set(presentDims.map(item => item.id));
    this.model = {
      dimsBreakdown: dimsBreakdown,
      availableDims: GroupedArrayFunctions.filter(CmsService.get().fieldsOrder, entity => CmsService.isValidField(entity, this.params.schema) && !presentIds.has(entity.id))
    };
  }
}

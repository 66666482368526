import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ActivityInterface,
  CrudConfig
} from '@siq-js/core-lib';
import {
  HttpService,
  NotificationService,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig,
  ResponseCodeType
} from '@siq-js/angular-buildable-lib';
import { GridComponent } from 'libs/visual-lib/src/lib/modules/grid/components/grid/grid.component';
import { Observable, of } from 'rxjs';
import { map, share, switchMap } from 'rxjs';
import { ColumnState, TableState } from 'libs/visual-lib/src';


@Injectable({
  providedIn: 'root'
})
export class TableStateService {

  public static readonly META_KEY = 'tableStateId';
  private static readonly ENDPOINT_APPLICATION = 'dashboard';

  /**
   * This is a porting (with modification) of the functionality from apps/dashboard/src/app/visual/services/table-state/table-state.service.ts
   */
  constructor(private http: HttpClient, private notificationService: NotificationService) { }

  public getCurrentTableState(component: GridComponent): TableState {

    if (component?.grid?.api) {

     // perhaps getAllDisplayedVirtualColumns should be used here?
     const pivotDisplayedColumns: ColumnState[]  = component.grid.api.getAllDisplayedColumns().filter(col => !!col.getSort()).map(column => {
       return<ColumnState>{colId: column.getColId(), sort: column.getSort()};
     });

      return {
        visualizationState: component.visualizationState$.getValue(),
        pivotMode: component.grid.api.isPivotMode(),
        pivotSortModel: pivotDisplayedColumns,
        columnState: component.grid.api.getColumnState(),
        filterModel: component.grid.api.getFilterModel(),
        sortModel: component.grid.api.getColumnState()
      };
    }
  }

  public retrieveTableState (component: GridComponent): Observable<TableState> {

    const activity: ActivityInterface = component.parentActivity;

    if (activity) {

      const tableStateId = activity.getMetaDataByKey(TableStateService.META_KEY);

      if (tableStateId) {
        const fullUrl = HttpService.getFullEndpoint(TableStateService.ENDPOINT_APPLICATION,`tablestate/${tableStateId}`);
        const options = {headers: HttpService.createHeaders()};
        return this.http.get(fullUrl, options)
        .pipe(
          map(r => JSON.parse((<any>r).tableState))
        );
      }
    }

    return Observable.create(obs => {
      obs.next(this.getCurrentTableState(component));
    });

  }

  public saveTableState (component: GridComponent, snapshot: TableState): Observable<TableState> {
    const activity = component.parentActivity;
    if (activity) {
      // Existing TableState ID
      const ETSID = activity.getMetaDataByKey(TableStateService.META_KEY);

      const cc: CrudConfig = {
        endpoint: ETSID ? `tablestate/${ETSID}` : 'tablestate',
        body: {
          tableState: JSON.stringify(snapshot),
          metaData: {}
        },
        suppressNotification: true
      };

      const obs$ = ETSID ? this.update(cc) : this.create(cc);
      return obs$
      .pipe(
        map((res: HttpResponse<any>) => res.body),
        switchMap(tableStateId => {
          if (tableStateId && tableStateId !== ETSID) {
            activity.metaData[TableStateService.META_KEY] = tableStateId; // Associate created/updated TableStateId with Activity
            return this.updateTableState(activity, activity.getMetaData()) // Persist Activity Meta Data
            .pipe(map(() => snapshot)); // Return the TableState that was persisted
          }
          return of(snapshot);
        })
      );
    } else {
      return of(snapshot);
    }
  }

  /**
   * @param component
   * @param tableState
   */
  public applyTableState (component: GridComponent, tableState: TableState): GridComponent {

    if (!component?.grid?.api) {
      console.warn('Tried to apply TableState, but could not locate api.');
    } else {
      component.visualizationState$.next(tableState.visualizationState);
      component.grid.api.setGridOption('pivotMode', tableState.pivotMode);
      component.grid.api.applyColumnState({state: tableState.columnState});
      component.grid.api.setFilterModel(tableState.filterModel);
      component.grid.api.applyColumnState({state: tableState.sortModel}); //remove after sort api rework

    }

    return component;

  }

  private create(cc: CrudConfig): Observable<HttpResponse<any>> {
    // Copied from siq-http.service
    const responseCodesConfig: ResponseCodesConfig = {
      resp: null,
      responseCodeType: ResponseCodeType.CREATE,
      data: cc.notificationData
    };

    // Make this a HOT observable: single code execution but multiple subscribers
    let obs$: Observable<HttpResponse<any> | ArrayBuffer> =
      this.http.post(
        cc.fullURL ? cc.endpoint : HttpService.getFullEndpoint(TableStateService.ENDPOINT_APPLICATION, cc.endpoint),
        cc.body,
        _.extend({ observe: 'response', headers: HttpService.createHeaders(cc.additionalHeaders, cc.type) }, cc.additionalConfig)
      ).pipe(share());

    const overrideCodes: ResponseCode[] = []; // unused, but just placing here just for now in case needed
    let _sub = obs$
    .subscribe((resp: HttpResponse<any>) => {
      if (!cc.suppressNotification && resp) {
        responseCodesConfig.resp = resp;
        this.notificationService.showHttpNotification(resp.status, new ResponseCodes(overrideCodes));
      }
    }, err => {
      if (!cc.suppressNotification) {
        responseCodesConfig.resp = err;
        this.notificationService.showHttpNotification(err.status, new ResponseCodes(overrideCodes));
      }
      return err;
    }, () => {
      _sub.unsubscribe();
    });

    return obs$ as Observable<HttpResponse<any>>;
  }

  private update(cc: CrudConfig): Observable<HttpResponse<any>> {
    // Copied from siq-http.service
    const responseCodesConfig: ResponseCodesConfig = {
      resp: null,
      responseCodeType: ResponseCodeType.UPDATE,
      data: cc.notificationData
    };

    let obs$: Observable<HttpResponse<any>> = this.http.put(
      cc.fullURL ? cc.endpoint : HttpService.getFullEndpoint(TableStateService.ENDPOINT_APPLICATION, cc.endpoint),
      cc.body,
      _.extend({ headers: HttpService.createHeaders(cc.additionalHeaders, cc.type), observe: 'response' }, cc.additionalConfig)
    ).pipe(share()) as Observable<HttpResponse<any>>;

    const overrideCodes: ResponseCode[] = []; // unused, but just placing here just for now in case needed
    let _sub = obs$
    .subscribe((resp: HttpResponse<any>) => {
        if (!cc.suppressNotification && resp) {
          responseCodesConfig.resp = resp;
          this.notificationService.showHttpNotification(resp.status, new ResponseCodes(overrideCodes));
        }
      },
      err => {
        if (!cc.suppressNotification) {
          responseCodesConfig.resp = err;
          this.notificationService.showHttpNotification(err.status, new ResponseCodes(overrideCodes));
        }
        throw new Error(err);
      }, () => {
        _sub.unsubscribe();
      });

    return obs$;
  }

  private updateTableState(activity: ActivityInterface, metaDataToUpdate: any): Observable<HttpResponse<any>> {
    /**
     * This used to use the updateMetaData() fn in apps/dashboard/src/app/activity/services/activity.service.ts, but extracting
     * to here (visual-lib)
     */
    metaDataToUpdate = _.assign(activity.metaData, metaDataToUpdate);

    let metaObj = {};
    let exclusionList = [
      'parsedMinDate',
      'parsedMaxDate',
      'minTimestamp',
      'maxTimestamp'
    ];

    _.map(metaDataToUpdate, (val, key: string) => {
      if (exclusionList.indexOf(key) === -1) {
        metaObj[key] = !_.isNil(val) ? _.trim(val.toString()) : val;
      }
    });

    let payload = <any>{
      id: activity.id,
      metaData: metaDataToUpdate
    };

    return this.update({
      endpoint: 'app-activity-meta/' + activity.id,
      body: payload,
      suppressNotification: true
    });
  }
}

<p class="frequency-title hide-when-data-is-ready">Time</p>
<div class="radios-block">
  <mat-radio-group [(ngModel)]="start" (change)="updateForm()">
    <!--<mat-radio-button [value]="startsMode.DEFAULT" disabled="true">When data is ready</mat-radio-button>-->
    <!--<br>-->
    <mat-radio-button [value]="startsMode.START">
      <mat-form-field class="form-field-time" color="accent">
        <mat-select [(value)]="time" (valueChange)="updateForm()">
          <mat-option *ngFor="let t of times" [value]="t">
            {{t}} CT
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>
  </mat-radio-group>
</div>

<button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!activity">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">

  <ng-container *ngIf="activity">

    <button mat-menu-item (click)="clone()" *ngIf="actionsMap[actionsEnums.CLONE]">
      <mat-icon>filter_none</mat-icon>&nbsp;Clone Criteria
    </button>

    <ng-container *ngIf="!isTrashed">

      <button mat-menu-item (click)="results()" *ngIf="actionsMap[actionsEnums.RESULTS]">
        <mat-icon>assessment</mat-icon>&nbsp;Go to Results
      </button>

<!--      <button mat-menu-item (click)="pin()" *ngIf="actionsMap[actionsEnums.PIN]">-->
<!--        <mat-icon>library_add</mat-icon>&nbsp;Pin to Dashboard-->
<!--      </button>-->

      <ng-container *ngIf="!isErrorOrDraft">
        <button mat-menu-item (click)="share()" *ngIf="canShare">
          <mat-icon>share</mat-icon><span>{{share_display}}</span>
        </button>
      </ng-container>

      <button mat-menu-item (click)="delete()" *ngIf="!isTrashed && !isSharedOrScheduled && actionsMap[actionsEnums.DELETE]">
        <mat-icon>delete</mat-icon>&nbsp;Delete
      </button>

      <button mat-menu-item (click)="remove()" *ngIf="isSharedOrScheduled && actionsMap[actionsEnums.DELETE]">
        <mat-icon>delete</mat-icon>&nbsp;Remove
      </button>

    </ng-container>

    <button mat-menu-item (click)="undelete()" *ngIf="isTrashed && !isSharedOrScheduled && actionsMap[actionsEnums.DELETE]">
      <mat-icon>undo</mat-icon>&nbsp;UnDelete
    </button>

  </ng-container>

</mat-menu>

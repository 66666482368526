import { Injectable, InjectionToken, Type } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DrawerState } from 'app/core/modules/drawer/models/drawer-state.enums';
import { DrawerRules } from 'app/core/modules/drawer/models/drawer.interfaces';
import { UtilsService } from 'app/core/services/utils/utils.service';

export interface IDrawerComponent {
  component: Type<any>,
  data?: any,
  drawerRules?: (_state: DrawerState) => DrawerRules
}

// Token for handling data injection into custom components (Same concept as MAT_DIALOG_DATA)
export const DRAWER_DATA: InjectionToken<any> = new InjectionToken<any>('DRAWER_DATA');

@Injectable()
export class DrawerService {
  public static drawerState$: BehaviorSubject<DrawerState> = new BehaviorSubject(null);
  public static drawerComponent$: BehaviorSubject<IDrawerComponent> = new BehaviorSubject(null);
  public static drawerResized$: Subject<void> = new Subject();

  public static stateOrder: DrawerState[] = [
    DrawerState.PEEK,
    DrawerState.DEFAULT,
    DrawerState.FULL
  ];

  public static injectComponent(params: IDrawerComponent): Promise<any> {
    return UtilsService.updateObsAndReturnPromise(this.drawerComponent$, params);
  }

  public static open(): Promise<any> {
    const _state = this.drawerState$.getValue();
    if (![DrawerState.DEFAULT, DrawerState.FULL].includes(_state)) {
      return this.setState(DrawerState.DEFAULT);
    }
  }

  public static peek(): Promise<any> {
    return this.setState(DrawerState.PEEK);
  }

  // Closes the drawer, destroys the injected component, and removes the drawer toggle tab from view
  public static clear(): Promise<any> {
    return this.setState(null);
  }

  public static getState(): DrawerState {
    return this.drawerState$.getValue();
  }

  private static setState(_state: DrawerState): Promise<any> {
    return UtilsService.updateObsAndReturnPromise(this.drawerState$, _state);
  }

  constructor() {

  }
}

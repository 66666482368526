import { Component } from '@angular/core';
import { CheckboxHeaderRendererComponent } from 'app/core/components/cell-renderers/checkbox-header-renderer/checkbox-header-renderer.component';
import {
  ActivityTableComponent,
  ActivityTableFilter
} from 'app/activity/components/activity-table/activity-table.component';

@Component({
  selector: 'siq-activity-checkbox-header-renderer',
  templateUrl: './activity-checkbox-header-renderer.component.html',
  styleUrls: ['./activity-checkbox-header-renderer.component.scss']
})
export class ActivityCheckboxHeaderRendererComponent extends CheckboxHeaderRendererComponent {

  public activityTableFilterEnums = ActivityTableFilter;

  constructor() {
    super();
  }

  public applyFilter(f: ActivityTableFilter) {
    (this.parent as ActivityTableComponent).applyTableFilter(f);
  }
}

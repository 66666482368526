import { Component, OnInit } from '@angular/core';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { environment } from 'environments/environment';

@Component({
  selector: 'siq-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public env: object;
  public projectId = AppSiqConstants.environment.projectId;

  constructor() { }

  ngOnInit() {
    this.env = {
      version: environment.about['version'],
      dependencies: environment.about['dependencies']
    };
  }

}

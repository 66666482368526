export enum DatePickerAction {
  APPLY = 'APPLY',
  CANCEL = 'CANCEL',
  DEFAULT = 'DEFAULT'
}

export enum DateUnit {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  DAY_OF_WEEK = 'dayOfWeek',
  QUARTER = 'quarter',
  TODAY = 'today',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second'
}

export enum WeekEndingDay {
  MON = 'mon',
  TUE = 'tue',
  WED = 'wed',
  THU = 'thu',
  FRI = 'fri',
  SAT = 'sat',
  SUN = 'sun',
  OLD_REPORT = 'old'
}

import { Component } from '@angular/core';
import { ActivityCriteriaRendererComponent } from 'app/activity/shared/renderers/activity-criteria-renderer/activity-criteria-renderer.component';
import { DaypartsService } from 'app/siq-applications/modules/dayparts/services/dayparts.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import { ColDef } from '@siq-js/visual-lib';
import { CriteriaChipFactory } from 'app/core/models/factories/criteria-chip-factory';
import { DaypartsActivity } from 'app/siq-applications/modules/dayparts/models/dayparts-activity.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Model {
  dateRange: string;
  retailers: CriteriaChip;
  filters: CriteriaChip;
}

@Component({
  selector: 'siq-js-dayparts-criteria-renderer',
  templateUrl: './dayparts-criteria-renderer.component.html',
  styleUrls: ['./dayparts-criteria-renderer.component.scss']
})
export class DaypartsCriteriaRendererComponent extends ActivityCriteriaRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: 'Criteria',
    sortable: false,
    cellRenderer: DaypartsCriteriaRendererComponent,
    width: 300
  };

  public model: Model;
  public isMultiMode: boolean = false;

  constructor(
    private daypartsService: DaypartsService,
    asyncStatusService: AsyncStatusService
  ) {
    super(asyncStatusService);
  }

  protected setupModel(activity: DaypartsActivity) {
    this.isMultiMode = EnvConfigService.isMultiMode();
    if (activity.getJob()?.getParams()) {
      const form = this.daypartsService.createForm(JSON.parse(activity.formValues));
      this.model = {
        dateRange: CriteriaChipFactory.getDate(form.dateRanges),
        retailers: CriteriaChipFactory.getRetailers(form.filters, form.schema),
        filters: CriteriaChipFactory.getFilters([...form.filters])
      };
    } else {
      this.model = null;
    }
    this.ready();
  }

  refresh(params: any) {
    return true;
  }

}

import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { GridService, GridOptions, AgGridAngular } from 'libs/visual-lib/src';
import * as _ from 'lodash';

@Component({
  selector: 'siq-js-grid-custom-paging',
  templateUrl: './custom-paging.component.html',
  styleUrls: ['./custom-paging.component.scss']
})
export class CustomPagingComponent implements AfterViewInit, OnInit, AfterViewChecked {
  @ViewChild('customPagingItems') customPagingItems: ElementRef;
  @ViewChild('customPagingGoto') gotoElement: ElementRef;

  @Input() gridOptions: GridOptions;
  @Input() grid: AgGridAngular;
  @Input() paginationPageLoaded;

  pageNumber: number = 1;
  pageNumberCorrect: boolean = true;
  pageSize: number;
  pageSizes: Array<number> = [100, 500, 1000];
  rowCount: number;
  currentPage: number = 0;
  totalPages: number = 0;

  constructor(private gridService: GridService, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.totalPages = this.grid.api?.paginationGetTotalPages();
  }

  ngAfterViewInit(): void {
    // reaarange divs
    const list = this.customPagingItems.nativeElement.getElementsByClassName("mat-paginator-range-actions");
    if (!!list[0]) {
      list[0].appendChild(this.gotoElement.nativeElement);

      // add page info
      const div = this.renderer.createElement("div");
      this.renderer.addClass(div, "mat-paginator-page-info");
      list[0].appendChild(div);
    }
  }

  ngAfterViewChecked() {
    // update page info - paginator does not support that
    const list = this.customPagingItems.nativeElement.getElementsByClassName("mat-paginator-page-info");

    // +1 to current page, as pages are zero based
    if (!!list[0])
      list[0].innerHTML = `Page ${(this.currentPage + 1).toString()} of ${this.totalPages?.toString()}`;
  }

  /** 
   To simplify paginator we dont create service
   in official documentation they use some "Workaround for bug in events order" 
   so let build on it
   Whenever the onPaginationChanged is fired, this method gets triggered 
  */
  ngOnChanges() {
    if (this.grid?.api) {
      this.pageSize = this.grid.api.paginationGetPageSize();
      this.rowCount = this.grid.api.paginationGetRowCount();
      this.totalPages = this.grid.api.paginationGetTotalPages();
      this.currentPage = this.grid.api.getDisplayedRowCount() == 0
                          ? this.currentPage = -1 
                          : this.grid.api.paginationGetCurrentPage();
    }
  }

  // page size
  onPageSizeChanged(event) {
    this.pageSize = event.pageSize;
    this.grid.api.updateGridOptions({ paginationPageSize: Number(event.pageSize) })
    this.grid.api.paginationGoToPage(event.pageIndex);
    this.rowCount = this.grid.api.paginationGetRowCount();
    this.totalPages = this.grid.api.paginationGetTotalPages();
    this.currentPage = this.grid.api.paginationGetCurrentPage();
  }

  /**
   * Go to page in case this is safe integer without digits
   */
  onGotoPage() {
    let isSafeInteger = Number.isSafeInteger(this.pageNumber);
    if(isSafeInteger) {
      // page N-1, as the first page is zero
      this.grid.api.paginationGoToPage(this.pageNumber - 1);
    } else {
      this.pageNumberCorrect = false;
    }
  }

  /**
   * Checks if whole text is a int number consising only of numbers.
   * @param event
   */
  numericOnly(event): boolean {
    let patt = /^[0-9]+$/;
    const resultWholeString = patt.test(String(this.pageNumber)) || _.isNil(this.pageNumber); //test current string if not empty
    //Last character in (input)="numericOnly($event)" is not contained inside pageNumber variable, that is why is tested separately
    const resultLastCharacter = patt.test(event.data) || _.isNil(event.data); //test last character if not empty
    const result = resultWholeString && resultLastCharacter
    this.pageNumberCorrect = result;
    return result;
  }

}

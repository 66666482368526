import { ContentType } from '@siq-js/core-lib';
import { CustomInjector } from 'app/core/models/custom-injector';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { UserService } from 'app/user/services/user.service';

@Injectable()
export class NewUserService {

  public static readonly securityQuestionsCount = 3;
  public static readonly defaultPath = 'new-user';
  private static readonly questionsPath = 'user/public/security-questions';

  private userService: UserService;
  private siqHttpService: SiqHttpService;

  constructor() {
    this.userService = CustomInjector.injector.get(UserService);
    this.siqHttpService = CustomInjector.injector.get(SiqHttpService);
  }

  public setPassword(payload: {password: string, newPassword: string, newPasswordConfirmation: string}): Observable<HttpResponse<any>> {
    return this.userService.setPassword(payload, true);
  }

  public getSecurityQuestions(): Observable<string[]> {
    return this.siqHttpService.get({endpoint: NewUserService.questionsPath})
      .pipe(
        map(res => res['questions'])
      );
  }

  public setSecurityQuestions(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    let payload = { answeredQuestions: [] };

    for (let i = 0; i < NewUserService.securityQuestionsCount; i++) {
      payload.answeredQuestions.push({
        question: (form.get('questions') as UntypedFormArray).controls[i].value,
        answer: (form.get('answers') as UntypedFormArray).controls[i].value,
      });
    }

    return this.siqHttpService.create({
      endpoint: NewUserService.questionsPath,
      body: payload,
      type: ContentType.JSON,
      suppressNotification: true
    });
  }
}

<div class="renderer-container" [ngClass]="{'can-sort': canSort}" (click)="toggleSort()">

  <label>{{ header }}</label>

  <ng-container *ngIf="canSort">

    <mat-icon *ngIf="activeSort !== 'asc'"
              [ngClass]="{'active': activeSort === 'desc'}"
    >arrow_downward</mat-icon>

    <mat-icon *ngIf="activeSort === 'asc'"
              [ngClass]="{'active': activeSort === 'asc'}"
    >arrow_upward</mat-icon>

  </ng-container>
</div>

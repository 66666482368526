import { Routes } from '@angular/router';
import { AppGuard } from 'app/guards/app-guard';
import { AuthGuard } from 'app/guards/auth-guard';
import { ApplicationHash } from '@siq-js/core-lib';
import { DaypartsListComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-list/dayparts-list.component';
import { DaypartsFormComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-form/dayparts-form.component';
import { DaypartsResultComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-result/dayparts-result.component';
import { DaypartsCloneResolverService } from 'app/siq-applications/modules/dayparts/services/dayparts-clone-resolver.service';

export const daypartsRoutes: Routes = [
  {
    path: 'app/dayparts',
    canActivate: [
      AppGuard,
      AuthGuard
    ],
    data: {
      applicationHash: ApplicationHash.DAYPARTS
    },
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: DaypartsListComponent
          }
        ]
      },
      {
        path: '~',
        children: [
          {
            path: '',
            component: DaypartsFormComponent
          }
        ]
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: DaypartsResultComponent
          },
          {
            path: 'clone',
            resolve: {nothing: DaypartsCloneResolverService},
            children: []
          },
          {
            path: 'export',
            component: DaypartsResultComponent
          }
        ]
      }
    ]
  }
];

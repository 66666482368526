/**
 * AG-Grid Charts
 */

export * as AgChartsExported from 'ag-charts-community';

export {
  AgCharts,
  AgBarSeriesOptions,
  AgCartesianChartOptions,
  AgChartOptions,
  AgLineSeriesOptions,
  AgScatterSeriesOptions,
  AgPieSeriesOptions,
  AgAreaSeriesOptions,
  AgCartesianSeriesOptions,
  AgPolarChartOptions,
  AgChartTooltipOptions,
  AgBarSeriesFormatterParams,
  AgTooltipRendererResult,
  time,
  _ModuleSupport,
  AgCartesianSeriesTooltipRendererParams
} from 'ag-charts-community';

export {
  AgChartsAngular
} from 'ag-charts-angular';

/**
 * Copied from ag-grid node_modules due to this not being "export"ed.
 * This comes from: "node_modules/ag-charts-community/dist/esm/es6/chart/interaction/highlightManager.d.ts"
 * Commented out final two properties "point" and "nodeMidPoint"; These were causing errors in IDE, but functionality is correct without these
 */
export interface HighlightNodeDatum {
  readonly series: any;
  readonly itemId?: any;
  readonly datum: any;
  readonly xKey?: string;
  readonly yKey?: string;
  readonly cumulativeValue?: number;
  readonly aggregatedValue?: number;
  readonly domain?: [number, number];
  // readonly point?: Readonly<SizedPoint>;
  // readonly nodeMidPoint?: Readonly<Point>;
}

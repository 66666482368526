import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReportBuilderFormComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form/report-builder-form.component';
import { MetricColumn } from 'app/siq-applications/modules/report-builder/models/form/metric-column.model';
import { DimensionColumn } from 'app/siq-applications/modules/report-builder/models/form/dimension-column.model';
import {
  YearOverYearModalComponent, YearOverYearModalConfig
} from 'app/siq-applications/modules/report-builder/components/year-over-year-modal/year-over-year-modal.component';
import { ColumnFilterModalComponent } from 'app/siq-applications/modules/report-builder/components/column-filter-modal/column-filter-modal.component';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FormColumn } from 'app/siq-applications/modules/report-builder/models/form/form-column.model';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { ReportBuilderFormColumnFilterComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form-column-filter/report-builder-form-column-filter.component';
import { ReportBuilderColumnType } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { WeekEndingDay } from '@siq-js/core-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { map, takeUntil } from 'rxjs/operators';
import { TimeAggregate } from 'app/siq-applications/modules/report-builder/models/time-aggregate.model';

@Component({
  selector: 'siq-report-builder-form-column-group',
  templateUrl: './report-builder-form-column-group.component.html',
  styleUrls: ['./report-builder-form-column-group.component.scss']
})
export class ReportBuilderFormColumnGroupComponent extends BaseSiqComponent implements OnInit {

  @Input() col: MetricColumn | DimensionColumn;
  @Input() parent: ReportBuilderFormComponent;

  @ViewChild(ReportBuilderFormColumnFilterComponent) filters: ReportBuilderFormColumnFilterComponent;

  public timeAggregatesOptions;
  public readonly columnTypeEnums = ReportBuilderColumnType;

  constructor(
    private config: ReportBuilderConfig,
    private utilsService: UtilsService,
    private datesService: DatesService
  ) {
    super();
  }

  ngOnInit() {
    this.datesService.weekEndingDay$.pipe(
      takeUntil(this.unsub$),
      map(we => we === WeekEndingDay.OLD_REPORT ? WeekEndingDay.SUN : we)
    ).subscribe((weekEndingDay: WeekEndingDay) => {
      this.updateWeekEndingDay(weekEndingDay);
    });
  }

  // Opens the year over year modal and updates the form
  public openYearOverYearModal(col: MetricColumn) {
    this.utilsService.openModal(YearOverYearModalComponent, <YearOverYearModalConfig>{
      model: col.yearOverYear,
      locked: this.parent.model.yoyLocked,
      globalDateRange: this.parent.formData.globalDateRange,
    }, YearOverYearModalComponent.MODAL_CONFIG)
      .afterClosed()
      .subscribe(model => {
        model && col.applyYearOverYear(model);
        this.parent.validateForm();
      });
  }

  // Opens the column filters modal and updates the form
  public openColumnFilterModal(col: MetricColumn) {
    this.utilsService.openModal(ColumnFilterModalComponent, {
      model: col.filters,
      schema: this.parent.schemaController$.getValue()
    })
      .afterClosed().subscribe((newFilters: FilterSelection[]) => {
      if (newFilters) {
        this.filters.setModel(newFilters);
      }
    });
  }

  // Clones a column
  public cloneMetric(col: MetricColumn) {
    this.parent.formData.columns.push(col.clone());
  }

  // Removes a column from the form
  public removeColumn(col: FormColumn) {
    this.parent.formData.removeColumn(col.key);
    this.parent.validateForm();
  }

  private updateWeekEndingDay(weekEndingDay: WeekEndingDay): void {
    this.timeAggregatesOptions = this.config.timeAggregates.filter((item: TimeAggregate) => !item.key.includes(DatesService.WEEK_END_PREFIX) || item.key === DatesService.WEEK_END_PREFIX + weekEndingDay);
    if ((this.col as MetricColumn).timeAggregates?.aggValue.key.includes(DatesService.WEEK_END_PREFIX)) {
      (this.col as MetricColumn).applyTimeAggregate(this.timeAggregatesOptions.find(i => i.key === DatesService.WEEK_END_PREFIX + weekEndingDay));
    }
  }

}

import { AuthGuard } from 'app/guards/auth-guard';
import { FavoritesDetailComponent } from 'app/activity/modules/favorites/favorites-detail/favorites-detail.component';
import { Route, Routes } from '@angular/router';
import { AllActivitiesComponent } from 'app/activity/components/all-activity-list/all-activities.component';

const activityRoute: Route = {
  path: 'activity',
  canActivate: [
    AuthGuard
  ],
  data: {
    name: 'Activities',
    icon: 'notifications_none'
  },
  children: [
    {
      path: '',
      component: AllActivitiesComponent,
    },
    {
      path: 'favorite/:id',
      component: FavoritesDetailComponent,
      outlet: 'm' // modal
    }
  ]
};

export const activityRoutes: Routes = [activityRoute];

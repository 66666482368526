<mat-chip
  *ngIf="ready$ | async"
  class="mat-chip-selected"
  [ngClass]="(model.include) ? 'include' : 'exclude'"
  matTooltipPosition="above"
  (click)="edit()"
  [matTooltip]="model | filterSelectionTooltip"
  color="default"
>

  <strong>
    {{ model | filterSelectionLabel}}
  </strong>
  <mat-icon (click)="$event.stopPropagation(); remove()">cancel</mat-icon>
</mat-chip>

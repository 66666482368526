import { Component, Input, OnInit } from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  AsyncTotalsAllowed,
  AsyncTotalsConfig,
  ColDef,
  ColGroupDef,
  Column,
  ColumnGroup,
  GridComponent,
  GridOptions,
  IAppRequest,
  IStatResponse,
  ProgressIndicatorColorType,
  ProgressIndicatorType,
  RefreshCellsParams,
  ValueFormatterParams,
  ValueGetterParams,
  VisualService
} from 'libs/visual-lib/src';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, Subject, filter, map, skip, switchMap, takeUntil, tap } from 'rxjs';
import {
  ActivityInterface,
  ActivityJson,
  CoreConstants
} from '@siq-js/core-lib';
import { BaseSiqComponent, NotificationService } from '@siq-js/angular-buildable-lib';
import * as _ from 'lodash';
import { stringToNum } from '@siq-js/core-lib';
import { CmsConfig, CmsField } from '@siq-js/cms-lib';
import { add, isLeapYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {IRowNode} from "ag-grid-enterprise";

interface AsyncPreliminaryCheck {
  payload: any;
  asyncResultsActivity: ActivityInterface;
  isCachedResults: boolean;
}

@Component({
  selector: 'siq-js-async-totals',
  templateUrl: './async-totals.component.html',
  styleUrls: ['./async-totals.component.scss']
})
export class AsyncTotalsComponent extends BaseSiqComponent implements OnInit {
  @Input() gridComponent: GridComponent;
  @Input() config: AsyncTotalsConfig;

  public tempSelectedStub: string;
  // For stub testing, files must go in "apps/dashboard/src/assets/" and be named "async-results-xxxxxxxxxxx.json"; The "async-results-" prefix MUST be present!
  public tempStubs: string[] = [
    '',
    'async-results-week-end-sun.json',
    'async-results-week-end-sat.json'
  ];
  public asyncStep: number;
  public asyncSteps: any[] = [ // TODO: Maybe create interface if needed
    {label: 'Generating Queries...', startValue: 0, maxValue: 60, increment: 2},
    {label: 'Executing Queries...', startValue: 60, maxValue: 95, increment: 3},
    {label: 'Processing Results...', startValue: 95, maxValue: 100},
    {label: 'Done!', startValue: 100, maxValue: 100}
  ];
  public asyncTotalsAllowed: AsyncTotalsAllowed;
  public asyncTotalsLoading$: BehaviorSubject<boolean|{isLoading: boolean, force: boolean}> = new BehaviorSubject<boolean|{isLoading: boolean, force: boolean}>(false);
  public cachedResultTimestamp: {timestamp: string, date: Date, formattedDate: string};
  public currentResults$: BehaviorSubject<ActivityInterface> = new BehaviorSubject<ActivityInterface>(null);
  public devMode: boolean = false;
  public isCachedResults: boolean = false;
  public isLoading: boolean = false;
  public tooltipText: string = '';

  private readonly ASYNC_NULL_DIM = 'ASYNC_NULL_DIM';
  private readonly TIME_AGGREGATE = 'time_agg';
  private readonly TOOLTIP_TEXT_OPTIONS = {
    ok: 'Calculate Dynamic Totals',
    pivotWarn: 'At least one Column Label must be specified'
  };
  private readonly YEAR_OVER_YEAR = 'yoy';
  private cachedResultsMap: Map<IAppRequest, ActivityInterface> = new Map<IAppRequest, ActivityInterface>();

  constructor(private notificationService: NotificationService) {
    super();
  }

  public static allowAsyncTotalsCalls(gridOptions: GridOptions): AsyncTotalsAllowed {
    let allCols: Column[];
    const result: AsyncTotalsAllowed = {
      show: false,
      enabled: false
    };

    // ICE-2592: TEMPORARILY DISABLE ALL ASYNC TOTALS FUNCTIONALITY
    // if (gridOptions.columnApi.isPivotMode()) {
    //   allCols = gridOptions.columnApi.getAllDisplayedColumns();
    // } else {
    //   allCols = gridOptions.columnApi.getColumns();
    // }
    //
    // for (let i = 0; i < allCols.length; i++) {
    //   if (allCols[i].getColDef().allowedAggFuncs && allCols[i].getColDef().allowedAggFuncs.length === 1
    //     && allCols[i].getColDef().allowedAggFuncs[0] === 'async') {
    //
    //     // At least one column is an async column, so set "allow" property which will cause the button to be shown
    //     result.show = true;
    //
    //     if (!gridOptions.columnApi.isPivotMode()) {
    //       result.enabled = allCols[i].isVisible();
    //     } else {
    //       result.enabled = !_.isEmpty(gridOptions.columnApi.getPivotColumns());
    //     }
    //
    //     if (result.show && result.enabled) break;
    //   }
    // }

    return result;
  }

  async getAsyncTotals(force = false) {
    const param = !!force ? {isLoading: true, force: true} : true;
    this.asyncTotalsLoading$.next(param);
  }

  async getAsyncTotalsFull(force = false) {
    let asyncResultsActivity: ActivityInterface|ActivityJson;
    let payload: any;
    const testWithStubData = this.tempSelectedStub !== '' && !_.isNil(this.tempSelectedStub);

    if (testWithStubData) {
      // Stub data is only the response (final activity), not the payload. Therefore we extract the payload from jobs[0].params
      asyncResultsActivity = await this.createAsyncTotals(payload); // payload is undefined at this point
      payload = (<ActivityJson><unknown>asyncResultsActivity).activity.jobs[0]?.params;
      this.isCachedResults = !_.isNil(this.checkCache(payload));
      this.finalizeAsyncResults(asyncResultsActivity);
    } else {
      const asyncPreliminaryCheck: AsyncPreliminaryCheck = this.getAsyncPreliminaryCheck();
      this.isCachedResults = asyncPreliminaryCheck.isCachedResults;
      payload = asyncPreliminaryCheck.payload;

      if (!asyncPreliminaryCheck.isCachedResults || !!force) {
        if (!!force) {
          this.isCachedResults = false;
        }
        // No cached results exist; POST to create the AsyncTotals Activity
        try {
          asyncResultsActivity = await this.createAsyncTotals(payload);
          this.finalizeAsyncResults(asyncResultsActivity);
        } catch (err) {
          // Error occurred (likely in the http request)
          // Turn off progress bar, enable Sigma (totals) button
          this.asyncTotalsLoading$.next(false);
          console.error('>>> ERR: %O', err);
        }
      } else {
        this.loadCachedResults(asyncPreliminaryCheck.asyncResultsActivity);
      }
    }

    if (!this.isCachedResults && payload) {
      // Add this payload/results combo to the cache Map
      this.cachedResultsMap.set(payload, <ActivityInterface>asyncResultsActivity);
    }
  }

  increaseProgressBar(increaseBy: number) {
    this.config.progressValue += increaseBy;
  }

  ngOnInit(): void {
    if (!this.config) {
      this.config = {
        progressIndicatorType: ProgressIndicatorType.BUFFER,
        progressIndicatorColorType: ProgressIndicatorColorType.ACCENT,
        progressValue: null,
        progressBufferValue: null,
        preventStyling: false
      };
    }

    this.asyncTotalsLoading$
    .pipe(
      takeUntil(this.unsub$)
    )
    .subscribe(loading => {
      let _force = false;
      if (typeof loading === 'object') {
        this.isLoading = loading.isLoading;
        _force = loading.force;
      } else {
        this.isLoading = loading;
      }
      if (this.isLoading) {
        this.asyncStep = 0;
        this.config.progressValue = 0;
        this.getAsyncTotalsFull(_force);
      } else {
        this.asyncStep = null;
        this.config.progressValue = null;
      }
    });

    this.gridComponent.checkAsyncTotalsCache$.pipe(
      skip(1),
      takeUntil(this.unsub$)
    ).subscribe(x => {
      /*
        Something happened to the grid that recalculated totals and cleared out async-totals.
        The GridComponent runs its "calcAggs()" function and then triggers the checkAsyncTotalsCache$ subscription.
       */
      this.isLoading = true;
      const asyncPreliminaryCheck: AsyncPreliminaryCheck = this.getAsyncPreliminaryCheck();
      if (asyncPreliminaryCheck.isCachedResults) {
        this.loadCachedResults(asyncPreliminaryCheck.asyncResultsActivity);
      }
      this.isLoading = false;
    });

    this.gridComponent.config.allowAsyncTotalsCalls$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((result: AsyncTotalsAllowed) => {
      /*
      If the AsyncTotals functionality has not been made available (by calling "GridTotalsService.provideAsyncTotalsFunctionality(gridSettings);"
      when creating the gridSettings: GridSettings object), then ensure that the button to trigger the API call is never shown.
       */
      if (!this.gridComponent.gridSettings.createAsyncTotals || typeof this.gridComponent.gridSettings.createAsyncTotals !== 'function') {
        result.show = false;
        console.warn('AsyncTotals have not been enabled for this Grid.');
      }

      this.asyncTotalsAllowed = result;

      // Set the tooltip text based on the result
      this.tooltipText = this.TOOLTIP_TEXT_OPTIONS.ok; // default
      if(this.gridComponent.gridOptions.pivotMode) {
        if (_.isEmpty(this.gridComponent.grid.api.getPivotColumns())) {
          this.tooltipText = this.TOOLTIP_TEXT_OPTIONS.pivotWarn;
        }
      }

    });
  }

  showProgressBar(show = true) {
    if (show) this.config.progressValue = 0;
    this.config.progressValue = show ? 0 : undefined;
    this.asyncTotalsLoading$.next(show);
  }

  setAsyncProgressBar() {
    if (_.isNil(this.asyncStep) && _.isNil(this.config.progressValue)) {
      this.asyncStep = 0;
      this.config.progressValue = 0;
    }

    // The end. Set step and value to null to stop the recursive call
    if (this.asyncStep === 3 && this.config.progressValue >= this.asyncSteps[this.asyncStep].maxValue) {
      this.asyncStep = null;
      this.config.progressValue = null;
    }

    const pollInterval = 1000;
    switch (this.asyncStep) {
      case 0:
        if (this.config.progressValue < this.asyncSteps[this.asyncStep].maxValue) {
          this.config.progressValue += this.asyncSteps[this.asyncStep].increment;
        }
        break;
      case 1:
        if (this.config.progressValue < this.asyncSteps[this.asyncStep].maxValue) {
          this.config.progressValue += this.asyncSteps[this.asyncStep].increment;
        }
        break;
      case 2:
        this.config.progressValue = this.asyncSteps[this.asyncStep].maxValue;
        break;
      case 3:
        this.config.progressValue = this.asyncSteps[this.asyncStep].maxValue;
        break;
    }

    if (!_.isNil(this.config.progressValue)) {
      setTimeout(() => {this.setAsyncProgressBar();}, pollInterval);
    }
  }

  setProgressBarValue(targetVal: number) {
    this.config.progressValue = targetVal;
  }

  updateProgressBar() {
    if (!this.config.progressValue) {
      this.showProgressBar(true);
    }
    this.config.progressValue += 25;
    if (this.config.progressValue >= 100) {
      setTimeout(() => {this.showProgressBar(false);}, 1000);
    }
  }

  private addDims = (startWith: string[][], newDimVals: string[]): string[][] => {
    const finalDimKeys: string[][] = [];
    startWith.forEach(dimArr => {
      newDimVals.forEach(ndv => {
        finalDimKeys.push(
          [...dimArr.slice(), ndv]
        );
      });
    });
    return finalDimKeys;
  }

  private checkCache(payload: IAppRequest): ActivityInterface {
    // Check the cache for this exact configuration
    let matchVal: ActivityInterface;
    this.cachedResultsMap.forEach((val, key) => {
      if (_.isEqual(payload, key)) {
        matchVal = <ActivityInterface>val;
      }
    });

    if (matchVal) {
      const _timestamp = matchVal.createdDate ? matchVal.createdDate : matchVal['activity'].createdDate; // matchVal.activity.createdDate could exist in testing stub files
      const _d = new Date(Number(_timestamp));
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.cachedResultTimestamp = {
        timestamp: matchVal.createdDate,
        date: _d,
        formattedDate: format(utcToZonedTime(_d, timeZone), 'yyyy-MM-dd hh:mm:ss a')
      };
      this.isCachedResults = true;

      // We do not have to make an API call ** UNLESS ** the results are too old
      return <ActivityInterface>matchVal;
    } else {
      this.isCachedResults = false;
      this.cachedResultTimestamp = null;
    }
  }

  private checkYoyOffsetMetricsPresent(factColIdMatrix: string[]) {
    return factColIdMatrix.some(factColId => {
      return factColId.indexOf('yoy_') > -1 && factColId.indexOf('offset_') > -1;
    });
  }

  private async createAsyncTotals(payload): Promise<ActivityInterface> {
    const grid = this.gridComponent; // pointer
    const totalsRequest$: Observable<HttpResponse<any>|Object> = payload ? grid.gridSettings.submitAsyncTotalsRequest(payload)
      : new Observable(observer => {
        observer.next({id: this.tempSelectedStub});
        observer.complete();
      });

    // POST to endpoint complete; waiting for response
    const _apiCallUnsub$: Subject<void> = new Subject<void>();
    const source = interval(1000);
    source.pipe(
      takeUntil(_apiCallUnsub$),
    ).subscribe(val => {
      this.setAsyncProgressValue();
    });

    let totalsRequestPromise$ = new Promise<ActivityInterface>((resolve, reject) => {
      totalsRequest$.pipe(
        takeUntil(grid.unsub$),
        map((resp: HttpResponse<any>|Object|any) => {
          return (resp.body && resp.status) ? resp.body.id : resp.id;
        }),
        tap(() => {
          // Unsubscribe from the temp subscription that showed progress while waiting for POST to finish
          _apiCallUnsub$.next();
          _apiCallUnsub$.complete();

          this.setAsyncStepAndStartValue(1);
        }),
        switchMap(id => grid.gridSettings.getAsyncTotalsResults(grid, id)),
        tap((resp) => {
          // Each time getAsyncTotalsResults() polls to check for completion, it emits a response. We use this to call setAsyncProgressValue()
          if (typeof resp === 'string') {
            // tslint:disable-next-line:no-console
            console.info('--------- sub-tap; setAsyncProgressValue... message: %O', resp);
            this.setAsyncProgressValue();
          }
        }),
        filter(resp => typeof resp === 'object'),
        map((activity: ActivityInterface) => activity)
      ).subscribe((activity: ActivityInterface) => {
        resolve(activity);
      }, (apiErr) => {
        // Unsubscribe from the temp subscription that showed progress while waiting for POST to finish
        _apiCallUnsub$.next();
        _apiCallUnsub$.complete();
        this.notificationService.error('Dynamic Totals encountered an unexpected error and could not complete at this time.', 'Dynamic Totals Error');
        reject(apiErr);
      });

    });
    return totalsRequestPromise$;
  }

  private createAsyncTotalsPayload(): IAppRequest {
    const grid = this.gridComponent; // pointer
    // Create Payload
    return grid.gridSettings.createAsyncTotalsPayload(grid);
  }

  private finalizeAsyncResults(asyncResultsActivity: ActivityInterface) {
    // Data received; begin processing
    this.setAsyncStepAndStartValue(2);

    // Due to testing stub files, next line ensures that we have "asyncResultsActivity" populated correctly
    if (asyncResultsActivity['activity']) asyncResultsActivity = asyncResultsActivity['activity'];

    this.processAsyncTotals(asyncResultsActivity);

    // Processing complete
    this.setAsyncStepAndStartValue(3);

    // Set these results into the BehaviorSubject; pinned-fact-cell.component and async-cell.component subscribe to currentResults$
    this.currentResults$.next(asyncResultsActivity);

    /*
      This .next() is inside of a setTimeout only for aesthetic reasons.
      It allows the async-totals progress bar(s) to complete visually instead of just disappearing.
      Not the best, but ok for now.
     */
    setTimeout(() => {
      this.asyncTotalsLoading$.next(false);
    }, 500);
  }

  private findViewMatrixRow(dimKeys: string[], sr: IStatResponse, yoyOffset = false, yoyOffsetFwd = false): string[] {
    const numericDateDimTypes: string[] = ['DATE_YEAR', 'DATE_YEARMONTH', 'DATE', 'DATE_QUARTER'];

    const dimKeysForValMatrix = dimKeys.map((dimKey, idx) => {
      if (dimKey === this.ASYNC_NULL_DIM) {
        return dimKey;
      } else {

        // This next line was failing when testing YOY; I split it into 2 different lines using _.assign() and it works.
        let cmsField = VisualService.dimensionToCmsField(sr.dimensions[idx]);
        cmsField = _.assign(cmsField, sr.dimensions[idx]);
        if (!yoyOffset) {
          return dimKey;
        } else {
          let finalVal = dimKey;
          if (numericDateDimTypes.includes(cmsField.type.toUpperCase())) {
            // Account for possibility of LeapYear dates
            const d = new Date(Number(dimKey)); // Ensure that value passed to Date constructor is a Number
            let d_adjusted = add(d, {years: (yoyOffsetFwd ? 1 : -1)});

            if (isLeapYear(d)) {
              // Check for Feb 29
              if (d.getMonth() === 1 && d.getDate() === 29) {
                // Set ly to be Feb 28 of previous year
                d_adjusted = new Date(d_adjusted.getFullYear(), 1, 28, d_adjusted.getHours(), d_adjusted.getMinutes(), d_adjusted.getSeconds(), d_adjusted.getMilliseconds());
              }
            } else if (isLeapYear(d_adjusted)) {
              // Check for Feb 29
              if (d_adjusted.getMonth() === 1 && d_adjusted.getDate() === 28) {
                // adjust forward 1 day to Feb 29
                d_adjusted = add(d_adjusted, {days: 1});
              }
            }

            // Special case for WEEK_END_* dims
            if (cmsField.id.toLowerCase().indexOf('week_end_') > -1) {
              // Check if we need to compensate for DST (Daylight Savings Time) (this happened in testing)
              const dstDiff = d.getTimezoneOffset() - d_adjusted.getTimezoneOffset();
              const dstAdjusted = dstDiff === 0 ? d_adjusted : add(d_adjusted, {minutes: dstDiff});

              const adjWeekEndingDayNum = dstAdjusted.getUTCDay();
              const rowWeekEndingDayNum = d.getUTCDay();
              let daysToAdd;
              if (yoyOffsetFwd) {
                daysToAdd = adjWeekEndingDayNum <= rowWeekEndingDayNum ? (7 - (rowWeekEndingDayNum - adjWeekEndingDayNum)) * -1 : Math.abs(adjWeekEndingDayNum - rowWeekEndingDayNum) * -1; // go backwards
              } else {
                daysToAdd = adjWeekEndingDayNum <= rowWeekEndingDayNum ? rowWeekEndingDayNum - adjWeekEndingDayNum : 7 - (adjWeekEndingDayNum - rowWeekEndingDayNum);
              }
              d_adjusted = add(d_adjusted, {days: daysToAdd});
            }

            finalVal = (d_adjusted.getTime()).toFixed(0); // convert JSDate to unix timestamp
          }
          return finalVal;
        }
      }
    });

    const dimIdxPointers: string[] = dimKeysForValMatrix.map((k, idx) => {
      let pointerIdx= '';
      sr.dimensionMatrix[idx].some((val, i) => {
        if (val === k || val === 'NO_DIM') {
          pointerIdx = i.toString();
        }
        return val === k;
      });
      return pointerIdx;
    });

    let vmRow: string[];
    sr.valuesMatrix.some(vmr => {
      let match = false;
      for (let i = 0; i < dimIdxPointers.length; i++) {
        match = vmr[i] === dimIdxPointers[i];
        if (!match) break;
      }
      if (match) {
        vmRow = vmr;
      }
      return match;
    });

    return vmRow;
  }

  private getAsyncPreliminaryCheck(): AsyncPreliminaryCheck {
    const asyncPreliminaryCheck: AsyncPreliminaryCheck = {
      asyncResultsActivity: undefined,
      isCachedResults: false,
      payload: this.createAsyncTotalsPayload()
    };

    asyncPreliminaryCheck.asyncResultsActivity = this.checkCache(asyncPreliminaryCheck.payload);
    asyncPreliminaryCheck.isCachedResults = !_.isNil(asyncPreliminaryCheck.asyncResultsActivity);

    return asyncPreliminaryCheck;
  }

  private getGroupRowNodeKeys(rn: IRowNode, dimensions: CmsField[], keys: string[][] = []): string[][] {
    const grid = this.gridComponent; // pointer
    // Look at the size of the dimensions param and establish the keys param
    if (!keys.length) {
      keys.push(...dimensions.map(d => undefined));
    }

    // The dim for this rowNode is what?
    const dimId = grid.getApi().grid.api.getRowGroupColumns()[rn.level].getColDef().colId; // gives the dimId
    const dimIdx = _.findIndex(dimensions, {id: dimId});

    keys[dimIdx] = [rn.key];
    if (rn.level > 0) {
      this.getGroupRowNodeKeys(rn.parent, dimensions, keys);
    }

    return keys;
  }

  private isMetricTimeAggregate(factColId: string): boolean {
    return _.startsWith(factColId, this.TIME_AGGREGATE + '_');
  }

  private isMetricYOY(factColId: string): boolean {
    return _.startsWith(factColId, this.YEAR_OVER_YEAR + '_');
  }

  private loadCachedResults(asyncResultsActivity: ActivityInterface) {
    this.setAsyncStepAndStartValue(2);
    this.finalizeAsyncResults(asyncResultsActivity);
  }

  private processAsyncTotals(activity: ActivityInterface) {
    const grid = this.gridComponent; // pointer

    // In apps/dashboard, the Activity.model has a helper fn: getResponse(lookup: number | string = 0), but we do not have access to that here
    const factColIdMatrix: string[] = activity.jobs[0].metaData.factColIdMatrix.split(',');
    const groupRowNodes: IRowNode[] = [];
    grid.grid.api.forEachNode(rowNode => {
      if (!!rowNode.group) {
        groupRowNodes.push(rowNode);
        this.setAsyncData(rowNode, activity, factColIdMatrix);
      }
    });

    /*
    // TESTING ASSIST (begin) - This was a complex bug to fix so leaving this debug code in place for any future needs
    // Use this to help debug time-agg dim in position/index 1 (sr.dimensionMatrix[1]
    let myDebug;
    const sr: IStatResponse = activity.jobs.length ? activity.jobs[0].response : null; // Note: sr has no helper functions, only properties (helper fns are defined in app/dashboard and inaccessible here)
    myDebug = sr.dimensionMatrix[1].map(timestamp => {
      // create object holding raw timestamp and Javascript Date
      return {timestamp: timestamp, date: new Date(Number(timestamp))};
    });

    // Sort the array of dates ASC so it is easier to find what we are looking for
    myDebug = myDebug.sort((a,b) => {
      return (a.date.getTime().toFixed(0) > b.date.getTime().toFixed(0)) ? 1 : ((b.date.getTime().toFixed(0) > a.date.getTime().toFixed(0)) ? -1 : 0);
    });
    console.log('myDebug: %O', myDebug);
    // TESTING ASSIST (end)
     */

    const grandTotalsRow = grid.grid.api.getPinnedTopRow(0);
    groupRowNodes.push(grandTotalsRow);
    this.setAsyncData(grandTotalsRow, activity, factColIdMatrix);

    this.setDynamicColumnData(grid, groupRowNodes);
    grid.grid.api.refreshCells(<RefreshCellsParams>{rowNodes: groupRowNodes, force: true});
  }

  private setDynamicColumnData(grid: GridComponent, groupRowNodes: IRowNode[]) {
    /**
     * Look through all of the colDefs in this grid, filter for any YOY coldefs with ids like #|#|lyd and #|#|lypd
     * (search for "|lyd" or "|lypd") or use "colId.split('|').length === 3 " and the last item is 'lyd' or 'lypd', then this is YOY cols
     * The end goal is to set the value in rowNode.data/rowNode.aggData for these, and THEN trigger the "currentResults.next()" sub
     */
    const yoyDeltaColIds = [];
    const yoyDeltaColIdParts= [];
    const yoyColInfo = [];
    const _findId = (c: ColDef | ColGroupDef) => {
      const _c = <any>c;
      if (_c.children) {
        // ColGroupDef
        (<ColGroupDef>c).children.forEach(child => {
          _findId(child);
        });
      } else {
        // ColDef
        const colIdParts = (<ColDef>c).colId.split('|');
        if (colIdParts.length === 3) {
          if (colIdParts[2] === 'lyd' || colIdParts[2] === 'lypd') {
            // this is a computed YOY col; save the colId
            yoyDeltaColIdParts.push(colIdParts);
            yoyDeltaColIds.push((<ColDef>c).colId);

            const yoyData = {
              colId: (<ColDef>c).colId,
              colIdParts: colIdParts,
              colDef: <ColDef>c
            };
            // We can check all of the Secondary Pivot Columns and match based on the original colId
            let secondaryPivotColumns = [];
            if (grid.grid.api.isPivotMode()) {
              secondaryPivotColumns = grid.grid.api.getPivotResultColumns().filter(col => {
                return col.getColDef().cellRendererParams.colDefMeta.colId === (<ColDef>c).colId;
              });
              yoyData['secondaryPivotColumns'] = secondaryPivotColumns;
            }
            yoyColInfo.push(yoyData);
          }
        }
      }
    };

    grid.grid.gridOptions.columnDefs.forEach((colDef: ColDef | ColGroupDef) => {
      _findId(colDef);
    });

    groupRowNodes.forEach(node => {
      const _data = !!node.group ? node.aggData : node.data;

      yoyColInfo.forEach(colInfo => {
        // Establish array of colDefs. These can be either primary or secondary (pivot) columns depending on the grid state
        const dynamicColDefs: ColDef[] = [];
        if (colInfo.secondaryPivotColumns?.length) {
          colInfo.secondaryPivotColumns.forEach((spCol: Column) => {
            dynamicColDefs.push(spCol.getColDef());
          });
        } else {
          dynamicColDefs.push(colInfo.colDef);
        }

        dynamicColDefs.forEach(dynamicColDef => {
          // Now we try to call the valueGetter and set the result as the data[prop] / aggData[prop]
          // Update the dataMap with the data used by this YOY col
          const vgParams: ValueGetterParams = {
            node: node,
            data: _data, // gets set in updateDataMapWithYOY
            colDef: dynamicColDef,
            column: null,
            api: grid.grid.api,
            context: null,
            getValue: (k: string) => dynamicColDef.valueGetter // gets set in updateDataMapWithYOY
          } as ValueGetterParams;

          if (grid.grid.api.isPivotMode()) {
            const primaryColId = dynamicColDef.pivotValueColumn?.getColId();
            const primaryColIdSegments = primaryColId.split('|');
            vgParams['_asyncTotalsSpecific'] = {
              curr: grid.grid.api.getPivotResultColumn(dynamicColDef.pivotKeys, primaryColIdSegments[0]),
              prev: grid.grid.api.getPivotResultColumn(dynamicColDef.pivotKeys, primaryColIdSegments[1])
            };
          }

          let valObj = (<((params: ValueGetterParams) => any)>dynamicColDef.valueGetter)(vgParams);

          if (typeof valObj !== 'object') {
            valObj = {
              val: valObj,
              toString: () => {
                return valObj;
              }
            };
          }

          const vfParams: ValueFormatterParams = {
            node: vgParams.node,
            data: vgParams.data,
            colDef: vgParams.colDef,
            column: vgParams.column,
            api: vgParams.api,
            columnApi: vgParams.columnApi,
            context: vgParams.context,
            value: valObj
          };

          _data[dynamicColDef.colId] = !_.isNil(vfParams?.value) ? vfParams.value['val'] : undefined; // original: _data[dynamicColDef.colId] = vfParams.value['val'];
        });
      });
    });
  }

  private selectVMRows(dimKeys: string[][], dimsTimeAggOnly: CmsField[], dimsNonTimeAgg: string[], sr: IStatResponse): string[][] {
    /*
      In case where sr.dimensions[0] is also a pivotColumn, then all values are present in dimKeys[0] (it is a string:[][]);
      These should each get their own row in dimKeysTemp.
     */
    let dimKeysTemp: string[][] = dimKeys[0].map(dimVal => [dimVal]);
    const vmRows: string[][] = [];

    for (let i = 1; i < dimKeys.length; i++) {
      dimKeysTemp = this.addDims(dimKeysTemp, dimKeys[i]);
    }
    if (dimsTimeAggOnly.length) {
      for (let i = dimsNonTimeAgg.length; i < sr.dimensions.length; i++) {
        // All values for this dimension are in dimensionMatrix[i]
        dimKeysTemp = this.addDims(dimKeysTemp, sr.dimensionMatrix[i]);
      }
    }
    dimKeysTemp.forEach(dk => {
      const match = this.findViewMatrixRow(dk, sr);
      if (match) {
        vmRows.push(match);
      }
    });
    return vmRows;
  }

  private setAsyncData(rowNode: IRowNode, activity: ActivityInterface, factColIdMatrix: string[]) {
    // Using ReportBuilder as the standard as it is the only app that currently makes use of async-totals.
    // If that changes, then this function may have to get modified.
    const grid = this.gridComponent; // pointer
    const sr: IStatResponse = activity.jobs.length ? activity.jobs[0].response : null; // Note: sr has no helper functions, only properties (helper fns are defined in app/dashboard and inaccessible here)
    const dimsNonTimeAgg: string[] = activity.jobs[0].metaData.dimsNonTimeAgg.split(',');
    const dimsTimeAggOnly: CmsField[] = !activity.jobs[0].metaData.dimsTimeAggOnly.trim().length ? []
      : activity.jobs[0].metaData.dimsTimeAggOnly.trim().split(',').map(dimRTaN => {
        // find a match for the dim using CmsService
        return (CoreConstants.cmsConfig as CmsConfig).findEntity<CmsField>(dimRTaN);
      });

    let dimKeys: string[][] = !!rowNode.group ? this.getGroupRowNodeKeys(rowNode, sr.dimensions) : this.setGrandTotalRowNodeKeysNew(activity);
    dimKeys = dimKeys.slice(0, dimsNonTimeAgg.length); // Trim off any TimAgg dims for now
    dimKeys = dimKeys.map((dimKeyVal, idx) => {
      if (dimKeyVal) return dimKeyVal;
      let newVal: string[];
      // if pivotMode AND this corresponding dim is a pivotCol, then use all values; else use ASYNC_NULL_DIM
      if (grid.getApi().grid.api.isPivotMode()) {
        // now check if this corresponding dim is a pivotCol
        const match = _.find(grid.getApi().grid.api.getPivotColumns(), {colDef: {colId: sr.dimensions[idx].id}});
        if (match) {
          // use all values
          newVal = [...sr.dimensionMatrix[idx]];
        } else {
          newVal = [this.ASYNC_NULL_DIM];
        }
      } else {
        newVal = [this.ASYNC_NULL_DIM];
      }
      return newVal;
    });

    const vmRows: string[][] = this.selectVMRows(dimKeys, dimsTimeAggOnly, dimsNonTimeAgg, sr);

    // Merge the array of arrays into a single string[]
    const dimKeysArr: string[] = [];
    dimKeys.forEach(_keys => {
      dimKeysArr.push(..._keys);
    });

    const factColIdMatrixTimeAggOnly = factColIdMatrix.filter(fcid => this.isMetricTimeAggregate(fcid));
    vmRows.forEach(vmRow => {
      let vmRowOffset: string[];
      let vmRowOffsetForward: string[];
      if (this.checkYoyOffsetMetricsPresent(factColIdMatrix)) {
        const tempDimArr = sr.dimensions.map((d, idx) => {
          return sr.dimensionMatrix[idx][vmRow[idx]];
        });
        vmRowOffset = this.findViewMatrixRow(tempDimArr, sr, true);

        if (_.isNil(rowNode.group) && grid.grid.api.isPivotMode()) {
          /*
            Special case for GrandTotals. When we iterate through rowNodes while repopulating the GrandTotals, if we are
            in pivotMode and a pivotColumn is a specific type (year, yearmon, quarter), then the sitation can arise where
            we are trying to insert a value for the LY (in the case of YOY) but the pivotValueColumn is actually the value
            of TY, so we will not find a match. To work around this, here we are getting a reference to the TY row rather
            than the LY row from the valueMatrix.
             */
          vmRowOffsetForward = this.findViewMatrixRow(tempDimArr, sr, true, true);
        }
      }

      // We have the corresponding row from valuesMatrix (vmRow)
      const firstFactIndex = dimsNonTimeAgg.length + dimsTimeAggOnly.length;

      factColIdMatrix.forEach((factColId, factIdx) => {
        const factColIdParts = this.splitFactColId(factColId);
        const groupId = factColIdParts.group;
        const colDefColId = factColIdParts.colId;
        const useThisRow = (factColIdParts.yoy && factColIdParts.offset && !_.isNil(rowNode.group)) ? vmRowOffset : vmRow;

        // If no match was found for these dims in the valuesMatrix, then skip the rest of this processing
        if (_.isNil(useThisRow)) return;

        // Skip any null or empty string values. This can happen in cases of (for example) time-agg queries.
        // A row may have only time-agg result but any other facts may be (null) in that row

        // Set a pointer to the correct attribute
        const dataPointer = !!rowNode.group ? rowNode.aggData : rowNode.data;

        // Create string[] of pivotKeys which can be used later
        const pivotKeys: string[] = [];
        const pivotColDims: string[] = grid.grid.api.getPivotColumns().map((col: Column) => col.getColDef().colId);
        if (pivotColDims.length) {
          sr.dimensions.forEach((dimName, dimIdx) => {
            if (pivotColDims.indexOf(dimName.id.toLowerCase()) > -1) {
              pivotKeys.push(sr.dimensionMatrix[dimIdx][useThisRow[dimIdx]]);
            }
          });
        }

        if (this.isMetricTimeAggregate(factColId)) {
          const finalVal = stringToNum(useThisRow[firstFactIndex + factIdx]);
          // finalVal can be null if we are processing a row (useThisRow) that has a value for a time-agg metric
          // but we are now looking at a standard metric (has the value '' in valuesMatrix and stringToNum converts this to null)
          if (_.isNil(finalVal)) return;

          const valueObj = {
            val: finalVal,
            toString: () => {
              return finalVal;
            }
          };

          const _factColIdMatrixTimeAggOnly = factColIdMatrix.filter(fcid => this.isMetricTimeAggregate(fcid));
          const correspondingTimeAggDimIdx = _.indexOf(_factColIdMatrixTimeAggOnly, factColId);
          const thisTimeAggDimIdx = dimsNonTimeAgg.length + correspondingTimeAggDimIdx;
          const dimensionMatrixRow = sr.dimensionMatrix[thisTimeAggDimIdx];
          const timeAggDimVal = dimensionMatrixRow[useThisRow[thisTimeAggDimIdx]];

          let match;
          const groupIds: string[] = [];
          const pointerColDefColId = colDefColId; // new pointer that can be referenced in the forEach() below
          if (grid.grid.api.isPivotMode()) {
            groupIds.push(
              ...grid.grid.api.getColumnGroupState().filter(colGroup => {
                return !colGroup.groupId.toLowerCase().includes('ag-grid-autocolumn');
              })
              .map(cg => cg.groupId)
            );
            groupIds.forEach(tempGroupId => {
              const colGroup: ColumnGroup = grid.grid.api.getColumnGroup(tempGroupId);
              if (!_.isEqual(pivotKeys, colGroup.getProvidedColumnGroup().getColGroupDef().pivotKeys)) return; // skip this group

              match = _.find(grid.grid.api.getColumnGroup(tempGroupId)?.getChildren(), {
                colDef:{
                  cellRendererParams: {colDefMeta: {timeAgg:{dimVal: timeAggDimVal}}},
                  pivotValueColumn: {parent: {
                      groupId: pointerColDefColId
                    }}
                }
              });
              if (match) {
                dataPointer[(<Column>match).getColId()] = valueObj;
              }
            });

          } else {
            match = _.find(grid.grid.api.getColumnGroup(groupId)?.getChildren(), {colDef:{cellRendererParams: {colDefMeta:{timeAgg:{dimVal: timeAggDimVal}}}}});
            if (match) {
              dataPointer[(<Column>match).getColId()] = valueObj;
            }
          }

        } else if (!this.isMetricYOY(factColId) || (this.isMetricYOY(factColId) && (useThisRow[firstFactIndex + factIdx] !== ''))) {
          const finalVal = stringToNum(useThisRow[firstFactIndex + factIdx]);
          // finalVal can be null if we are processing a row (useThisRow) that has a value for a standard metric
          // but we are now looking at a time-agg metric (has the value '' in valuesMatrix and stringToNum converts this to null)
          if (_.isNil(finalVal)) return;

          const valueObj = {
            val: finalVal,
            toString: () => {
              return finalVal;
            }
          };
          if (grid.grid.api.isPivotMode()) {
            /**
             * Example:
             * Given these sr.dimensions: [{id: 'dimA'}, {id: 'dimB'}, {id: 'dimX'}, {id: 'dimY'}]
             * If grid is pivoted on first and third dims...
             * 1. Get pivotKey dim ids: ['dimA', 'dimX']
             * 2. Get the index of each dim in sr.dimensions: ['0', '2']
             * 3. Get pivotKey dim values: ['abcd', 'xyz']
             * 4. Get secondaryPivotCol.colId using (pivotKey dim values) and (factColid): (['abcd', 'xyz'], colDefColId)
             */

            // 1. Get pivotKey dim ids: ['dimA', 'dimX']
            const _pivotKeys: string[] = grid.grid.api.getPivotColumns().map(c => {
              return c.getColDef().cellRendererParams.colDefMeta.ref.id;
            });

            // 2. Get the index of each dim in sr.dimensions: ['0', '2']
            const pivotDimIndexes: number[] = _pivotKeys.map(pivotKey => {
              return _.findIndex(sr.dimensions, {id: pivotKey});
            });

            // 3. Get pivotKey dim values: ['abcd', 'xyz'] (Using "pivotDimIndexes" and "useThisRow")
            const pivotKeyValues: any[] = pivotDimIndexes.map((pivotDimIdx, i) => {
              let pivotKeyVal;
              // Special case for YOY columns in GRAND-TOTAL ROW in pivotMode; Problem exists for YOY offset where the LY column
              // is for (for example) "2021-01" but TY is the actual pivotColumn so the pivotValueColumn is actually "2022-01"
              // so the value for LY was not getting inserted due to not being able to find the corresponding pivotValueColumn.
              if (!rowNode.group &&
                (['year', 'yearmon', 'yearmonday', 'quarter'].includes(_pivotKeys[i]) || _pivotKeys[i].toLowerCase().indexOf('week_end_') > -1) &&
                !!(factColIdParts.yoy) &&
                !!(factColIdParts.offset)
              ) {
                const _tempDimArr = sr.dimensions.map((d, idx) => {
                  // If vmRowOffsetForward is nil (undefined/null) then use '-1' for value which will result in no match being found in next step
                  return vmRowOffsetForward ? sr.dimensionMatrix[idx][vmRowOffsetForward[idx]] : '-1';
                });
                const matchVMRow = this.findViewMatrixRow(_tempDimArr, sr);
                // matchVMRow should now exist, but fallbacks are here just in case
                if (matchVMRow) {
                  pivotKeyVal = _tempDimArr[pivotDimIdx];
                } else if (vmRowOffset && sr.dimensionMatrix[pivotDimIdx][vmRowOffset[pivotDimIdx]] === _tempDimArr[pivotDimIdx]) {
                  pivotKeyVal = sr.dimensionMatrix[pivotDimIdx][vmRowOffset[pivotDimIdx]];
                } else {
                  pivotKeyVal = sr.dimensionMatrix[pivotDimIdx][useThisRow[pivotDimIdx]];
                }
              } else {
                pivotKeyVal = sr.dimensionMatrix[pivotDimIdx][useThisRow[pivotDimIdx]];
              }
              return pivotKeyVal;
            });

            // 4. Get secondaryPivotCol.colId using (pivotKey dim values) and (factColid): (['abcd', 'xyz'], colDefColId)
            const pivotColId = grid.grid.api.getPivotResultColumn(pivotKeyValues, colDefColId)?.getColId();
            if (pivotColId) {
              dataPointer[pivotColId] = valueObj;
            }
          } else {
            dataPointer[colDefColId] = valueObj;
          }
        }
      });
    });
  }

  private setAsyncProgressValue() {
    if (this.config.progressValue < this.asyncSteps[this.asyncStep].maxValue
      && !!this.asyncSteps[this.asyncStep].increment
    ) {
      this.config.progressValue += this.asyncSteps[this.asyncStep].increment;
    }
  }

  private setAsyncStepAndStartValue(step: number) {
    this.asyncStep = step;
    this.config.progressValue = this.asyncSteps[this.asyncStep].startValue;
  }

  private setGrandTotalRowNodeKeysNew(activity: ActivityInterface): string[][] {
    const sr: IStatResponse = activity.jobs.length ? activity.jobs[0].response : null;
    return sr.dimensions.map(d => undefined);
  }

  private splitFactColId(factColId: string): {yoy: string, offset: string, timeagg: string, group: string, colId: string} {
    /**
     * The factColId can have up to four parts in the format:
     * {yoy|timeagg}:{groupId}_{colId}
     *
     * Example:  "yoy_offset_5748567201284096_3" | "yoy_5748567201284096_3"
     */
    const out = {
      yoy: '',
      offset: '',
      timeagg: '',
      group: '',
      colId: ''
    };

    // In order to split on "_" need to first replace the underscore in "time_agg"
    let factColIdCopy = _.cloneDeep(factColId);
    factColIdCopy = factColIdCopy.replace('time_agg', 'time-agg');

    const parts: string[] = factColIdCopy.split('_');

    out.colId = parts[parts.length - 1];

    switch(parts[0]) {
      case 'yoy':
        out.group = parts[parts.length - 2];
        out.yoy = parts[0];

        if (parts.length > 3 && parts[1] === 'offset') {
          out.offset = parts[1];
        }
        break;

      case 'time-agg':
        out.timeagg = parts[0];
        out.group = parts[parts.length - 1];
        break;
    }

    return out;
  }

}

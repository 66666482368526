import {
  ExcelService,
  GridComponent,
  GridOptions,
  GridService,
  GridSettings,
  IStatResponse,
  TextColorType,
  VisualOptions,
  VisualService
} from '@siq-js/visual-lib';
import { AffinitiesResultComponent } from 'app/siq-applications/modules/affinities/components/affinities-result/affinities-result.component';
import { AffinitiesConfig, AffinitiesFacts } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { AppComponent } from 'app/app.component';
import { debounceTime, filter, takeUntil } from 'rxjs';
import { stringToNum } from '@siq-js/core-lib';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';

export interface AffinitiesGridParams {
  gridVisualOptions?: VisualOptions;
  parent: AffinitiesResultComponent;
  readyForExport$: BehaviorSubject<boolean>;
  statResponse: IStatResponse;
}

export class AffinitiesGridProcessor {

  public static generateGridVisualOptions(gridParams: AffinitiesGridParams): VisualOptions {
    let vo: VisualOptions = GridService.generateDefaultGridVisualOptions();
    const data = AffinitiesService.generateColumnData(gridParams.statResponse);

    return _.merge(vo, {
      initState: {
        dimensions: [],
        metrics: gridParams.statResponse.getFacts()
      },
      data: data,
      metrics: gridParams.statResponse.getFacts(),
      dimensions: [],
      parentActivity: gridParams.parent.reportActivity,
      statResponse: gridParams.statResponse,
      globalDateRange: <DateRangeInterface>{
        begin: gridParams.parent.formData.dateRanges.begin,
        end: gridParams.parent.formData.dateRanges.end
      },
      gridConfig: {
        persistState: true,
        onGridReady: (grid) => {
          const gridComponent = <GridComponent>VisualService.findVisualization(gridParams.gridVisualOptions.vizId);
          gridComponent.visualizationState$.pipe(
            debounceTime(250),
            filter(visualizationState => !_.isNil(visualizationState)),
            takeUntil(gridComponent.unsub$)
          ).subscribe(visualizationState => {
            if (!!visualizationState.advancedMetrics !== gridParams.parent.advancedMetrics) {
              gridParams.parent.showAdvancedMetrics({checked: visualizationState.advancedMetrics});
            }
          });
          AppComponent.resize$
          .pipe(debounceTime(100))
          .subscribe(() => gridComponent.autoSizeColumns());
        },
        hideTotals: true,
        defaultSortOptions: gridParams.parent.config.defaultSort,
        pinLeft: [0, 1],
        maxColumnsToFit: 8,
        tooltips: gridParams.parent.config.tooltips,
        afterRender: (grid) => {
          gridParams.parent.showAdvancedMetrics({checked: false});
        },
        customGridOptions: {
          excelStyles: [
            ...ExcelService.generateExcelStyles(),
            ...AffinitiesConfig.excelStyles
          ],
          rowStyle: {cursor: AffinitiesService.canDrill(gridParams.parent.formData) ? 'pointer': 'auto'}, // pointer style for drilldown,
          sideBar: false,
          onRowClicked: event => {
            if (!gridParams.parent.reportActivity.isMine()) return;
            if (!AffinitiesService.canDrill(gridParams.parent.formData)) return;
            // open the modal for affinities-drilldown, passing the left/right of row that was clicked.
            gridParams.parent.affinitiesService.openDrilldownModal(gridParams.parent.reportActivity, gridParams.parent.formData, event.data);
          }
        },
        configureColumns: (cols) => {
          cols.forEach(col => {
            if (col.colId === AffinitiesFacts.RIGHT || col.colId === AffinitiesFacts.LEFT) {
              col.chartDataType = 'category';
            }
            const cellClassRules = {
              ...ExcelService.getCellClassRules([
                ...gridParams.statResponse.getDimensions(),
                ...gridParams.statResponse.getFacts()
              ]),
              ...GridService.getDefaultFactCellClassRules()
            };

            _.each(AffinitiesConfig.customFacts, i => {
              cellClassRules[i] = (context) => {
                const fact = GridService.enumFromCellClass(context.colDef.cellClass, gridParams.statResponse.getFacts());
                return fact && fact.id === i;
              };
            });

            cellClassRules[TextColorType.SUCCESS.toString()] = (params) => {
              if (params.value === undefined) return true;

              const _val = _.get(params.value, 'val', params.value);
              const fact = GridService.enumFromCellClass(params.colDef.cellClass, gridParams.statResponse.getFacts());

              return gridParams.parent.affinitiesService.colorizeFact(fact.id, _val, TextColorType.SUCCESS, params.colDef.valueFormatter);
            };

            cellClassRules[TextColorType.WARN.toString()] = (params) => {
              if (params.value === undefined) return true;

              const _val = _.get(params.value, 'val', params.value);
              const fact = GridService.enumFromCellClass(params.colDef.cellClass, gridParams.statResponse.getFacts());

              return gridParams.parent.affinitiesService.colorizeFact(fact.id, _val, TextColorType.WARN, params.colDef.valueFormatter);
            };

            col.cellClassRules = cellClassRules;

            // Add filterValueGetter
            if (col.colId !== AffinitiesFacts.LEFT && col.colId !== AffinitiesFacts.RIGHT) {
              const metric = gridParams.statResponse.getFacts().find(f => f.id === col.colId);
              if (metric) {
                const formatterFn = GridService.generateFormatterFn(metric);
                col.filterValueGetter = params => {
                  const displayVal = formatterFn({value: {val: _.get(params.data, [col.colId, 'val'])}});
                  return stringToNum(displayVal);
                };
              }
            }
          });

          return _.reject(cols, {colId: 'AFFINITY'});

        },
        gridReadyForExport$: gridParams.readyForExport$,
      },
      title: _.slice(gridParams.parent.config.factMappings.get('standard'), 2).join(', ')

    });
  }

  public static processor(gridParams: AffinitiesGridParams): GridSettings {
    // Generate the GridOptions (overrides) which will get merged to the default GridOptions created by/in GridComponent
    let gridOptions: GridOptions = gridParams.gridVisualOptions.gridConfig.customGridOptions || {};

    return <GridSettings>{
      data: gridParams.gridVisualOptions.data, // set pointer for ease of use/readability later
      gridOptions: gridOptions,
      gridVisualOptions: gridParams.gridVisualOptions,
      parentActivity: gridParams.gridVisualOptions.parentActivity // set pointer for ease of use/readability later
    };
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { CmsConfig, CmsField, CmsMetric } from '@siq-js/cms-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import {
  AggregationType,
  BarColumnConfig,
  BarColumnParams,
  ColDef,
  ColGroupDef,
  GridColMetadata,
  GridComponent,
  GridService,
  SimpleLineConfig,
  SimpleLineParams,
  VisualOptions
} from '@siq-js/visual-lib';
import { Activity } from 'app/activity/models/activity.model';
import { DataType } from 'app/core/models/data-type.enum';
import { PromoResultGridParams, PromoResultGridProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/promo-result-grid-processor';
import { PromoResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/promo-result.component';
import { PromoTakerateDrilldownRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/promo-takerate-drilldown-renderer/promo-takerate-drilldown-renderer.component';
import { KpiModel, PromoSheetResult, SalesByDayTSDataSet, TakeRateHelperState, TakeRateHelperParams } from 'app/siq-applications/modules/promo/models/interfaces';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { ChartTypes, KPI, PromoDimensionKeys, PromoPeriods, PromoSheets, UnitTypes } from 'app/siq-applications/modules/promo/models/promo.enums';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { StatResponse } from 'app/core/models/stat-response.model';
import { TakeRateColumnChartProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/take-rate-column-chart-processor';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { SalesByDaySimpleLineProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/sales-by-day-simple-line-processor';
import { DrawerService } from 'app/core/modules/drawer/services/drawer.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { TakeRateHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-helper/take-rate-helper.component';
import { CloudExportable } from 'app/core/modules/cloud-export/models/cloud-export.interface';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import { ActivatedRoute } from '@angular/router';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';

@Component({
  selector: 'siq-js-take-rate-result',
  templateUrl: './take-rate-result.component.html',
  styleUrls: ['./take-rate-result.component.scss']
})
export class TakeRateResultComponent extends BaseSiqComponent implements OnInit, PromoSheetResult, CloudExportable {
  @Input() takeRateActivity: Activity;
  @Input() takeRatePSPWActivity: Activity;
  @Input() timeSeriesActivity: Activity;
  @Input() helperActivity: Activity;
  @Input() helperPSPWActivity: Activity;
  @Input() kpiModel: KpiModel;
  @Input() parent: PromoResultComponent;

  // Dropdown options
  public facts: CmsMetric[];
  public metrics: CmsMetric[];
  public kpiKeys: string[];
  public unitTypes = UnitTypes;
  public chartTypes = ChartTypes;

  // Dropdown selections
  public factKey: CmsMetric;
  public kpiKey: string;
  public unitType: UnitTypes = UnitTypes.ABSOLUTE;
  public chartType: ChartTypes = ChartTypes.TAKERATE;

  // grid
  public grid: GridComponent;
  public gridParams: PromoResultGridParams;
  public gridProcessor = PromoResultGridProcessor.processor;
  public isCloudExportable: boolean;
  public readyForExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // if grid is ready to be exported

  // chart
  public stackedColumnParams: BarColumnParams;
  public stackedColumnConfig: BarColumnConfig;
  public stackedColumnProcessor = TakeRateColumnChartProcessor.processor;
  public stackedColumnData$: BehaviorSubject<any[]>;
  public simpleLineData$: BehaviorSubject<SalesByDayTSDataSet>;
  public simpleLineParams: SimpleLineParams;
  public simpleLineConfig: SimpleLineConfig;
  public simpleLineProcessor = SalesByDaySimpleLineProcessor.processor;

  public dataTypes = DataType;
  public helperParams: TakeRateHelperParams;
  public preCustomName: string;
  public periodKey: PromoPeriods = PromoConfig.compPeriods[0];
  public periodKeyName: String;
  public yoyCustomName: string;

  public render$: Subject<void> = new Subject<void>();
  public renderedOnce = false;
  private _facts: CmsMetric[]; // base set of facts, this.facts is derived from this
  private takeRateData: any[];
  private takeRatePSPWData: any[];
  private timeSeriesData: any[];

  constructor(
    public config: PromoConfig,
    public cloudExportService: CloudExportService,
    public route: ActivatedRoute,
    private mixpanelService: MixpanelService,
    private promoService: PromoService,
    private gridService: GridService,
  ) {
    super();
    this.stackedColumnData$ = new BehaviorSubject<any[]>([]);
    this.simpleLineData$ = new BehaviorSubject(null);
  }

  addCellClassForFactKey(colDef: ColDef, id: string) {
    // Add a cellClass (based on the corresponding CmsMetric) so the Excel export is formatted correctly
    let _cmsMetric = CmsService.get().findEntity<CmsField>(id);
    if (!_cmsMetric) {
      _cmsMetric = CmsService.get().findEntity<CmsField>(PromoService.promoMetricKeyToCmsMetricKey.get(id));
    }
    if (_cmsMetric && !_.find(<string[]>colDef.cellClass, GridService.ENUM_ID_PREPEND + _cmsMetric.id)) {
      (<string[]>colDef.cellClass).push(GridService.ENUM_ID_PREPEND + _cmsMetric.id);
    }
  }

  attachCloudData(collection: VisualOptions[]) {
    if (!this.isCloudExportable) return;
    this.cloudExportService.attachCloudData(collection);
  }

  createDropdownOptions() {
    this.setupFacts();

    try {
      this.kpiKeys = Object.keys(this.kpiModel).filter(k => k !== PromoPeriods.PROMO);
    } catch {
      this.kpiKeys = null;
    }

    this.kpiKey = PromoPeriods.PROMO;
  }

  drilldown(basketSize: string) {
    const helperState: TakeRateHelperState = {
      factKey: this.factKey,
      basketSize: basketSize
    };

    if (DrawerService.getState()) {
      const comp = this.helperParams.helperComponent;
      if (comp.basketSize !== basketSize) {
        this.helperParams.helperComponent.basketSize = basketSize;
        comp.updateView();
      }
    } else {
      this.helperParams = {
        data: this.promoService.generateTakeRateData(this.helperActivity.getJob()),
        facts: this._facts,
        basketSizes: this.getAllBasketSizes(),
        activity: this.helperActivity,
        activityPSPW: this.helperPSPWActivity,
        initState: helperState,
        unitType: this.unitType,
        parent: this.parent,
        helperComponent: null // This is set upon injection in the helper component
      };

      DrawerService.injectComponent({
        component: TakeRateHelperComponent,
        data: this.helperParams
      });
    }

    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': 'Drill Down',
      'Usage': 'Promotion Measurement'
    });

    DrawerService.open();
    this.renderedOnce = false;
  }

  generateFileName(activityName: string): string {
    // TODO
    // const factName = FactService.find(this.factKey).name;
    // return `${activityName} - Take Rate (${factName})`;
    return null;
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
      'Usage': 'Promotion Measurement'
    });
  }

  private getAllBasketSizes(): string[] {
    const dimVals = new Set<string>();

    this.takeRateActivity.getJobs().forEach(j => {
      const sr = j.getResponse();
      sr.getDimensionValues()[0].forEach(dV => dimVals.add(dV));
    });

    return Array.from(dimVals.values());
  }

  isActive(): boolean {
    return this.parent.currentSheet === PromoSheets.TAKERATE;
  }

  async ngOnInit() {
    this.preCustomName = this.parent.formData[PromoConfig.periodNames[PromoPeriods.PRE]].customPeriodName;
    this.yoyCustomName = this.parent.formData[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName;
    this.periodKeyName = this.parent.formData[PromoConfig.periodNames[this.periodKey]].customPeriodName;

    this.createDropdownOptions();
    this.takeRateData = this.promoService.generateTakeRateData(this.takeRateActivity.getJob());
    this.timeSeriesData = this.promoService.generateTakeRateTSData(this.timeSeriesActivity.getResponse(), this.parent.formData).sort(
      (a, b) => {
        const key = PromoDimensionKeys.TIMESERIES_DAY;
        return UtilsService.isLessThan(a[key], b[key]);
      }
    );
    this.setupTable();
    this.setupChart();
    //cloud export should be here,  so inputs to other components siq-kpi-chip, like periodKeyName would be initialized sooner
    this.isCloudExportable = await CloudExportService.isExportable(this);
    if (this.isCloudExportable) {
      CloudExportService.setReadyForExportSubscription(this.readyForExport$, this);
    }
    this.ready();

    this.render$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsub$)
      )
      .subscribe(() => this.render());

  }

  render() {
    if (this.chartType === ChartTypes.TAKERATE) {
      this.renderTakeRate();
    } else if (this.chartType === ChartTypes.TIMESERIES) {
      this.renderTimeSeries();
    }
  }

  setActive() {
    DrawerService.clear();

    if (!this.renderedOnce) {
      this.updateView();
      this.renderedOnce = true;
    }
  }

  setupChart() {
    // Basket item count stacked column chart
    this.stackedColumnParams = {
      agChartOptions: null,
      parent: this,
      parentActivity: this.takeRateActivity,
      rawData$: this.stackedColumnData$,
      highlightDatum$: new BehaviorSubject(null)
    };
    this.stackedColumnConfig = TakeRateColumnChartProcessor.generateBarColumnConfig(this.stackedColumnParams);

    // Sales by day line chart
    this.simpleLineParams = {
      parent: this,
      rawDataSimpleLine$: this.simpleLineData$
    };
    this.simpleLineConfig = SalesByDaySimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParams, this.parent.formData);

  }

  setupFacts() {
    this._facts = PromoService.filterMetricsForTakeRateDropdown(
      (this.takeRateActivity.getResponse()).getFacts()
    );
    PromoService.detectDynamicFacts(this, this._facts, KPI.NUM_TRANSACTIONS);
  }

  setupTable() {
    this.gridParams = {
      parent: this,
      data: this.takeRateData,
      readyForExport$: this.readyForExport$
    };
    this.gridParams.gridVisualOptions = PromoResultGridProcessor.generateGridVisualOptions(this.gridParams);
  }

  switchChart(chartType: ChartTypes) {
    if (this.chartType === chartType) return;

    this.chartType = chartType;

    if (chartType === ChartTypes.TIMESERIES) {
      this.unitType = UnitTypes.ABSOLUTE;
    }

    this.updateUnitType();
  }

  public togglePeriod(checked: boolean) {
    // Cast checked to a number (0 or 1) and set the period key
    this.periodKey = PromoConfig.compPeriods[Number(checked)];
    this.periodKeyName = this.parent.formData[PromoConfig.periodNames[this.periodKey]].customPeriodName;
  }

  updateChart(sr: StatResponse) {
    const rawData = GridService.jobToArray(sr);
    if (this.chartType === ChartTypes.TAKERATE) {
      const columnChartData = TakeRateColumnChartProcessor.toStackedColumnData(rawData, PromoDimensionKeys.PERIOD, PromoDimensionKeys.BASKET_SIZE, this.factKey.id, true);
      this.stackedColumnData$.next(columnChartData);
    }
    if (this.chartType === ChartTypes.TIMESERIES) {
      const timeSeriesDataSet = SalesByDaySimpleLineProcessor.divideData(rawData, this.factKey.id, this.parent.formData);
      this.simpleLineData$.next(timeSeriesDataSet);

    }
  }

  updateUnitType() {
    this.setupFacts();
    this.updateView();
  }

  updateView() {
    this.render$.next();
  }

  private generateDeltaPromoColDef(period: PromoPeriods, metric: CmsMetric, colDefMeta: Map<string, GridColMetadata>): ColDef {
    const fact = new CmsMetric({
      display: 'Change',
      type: metric.type,
      aggType: AggregationType.SUM,
      id: 'PROMO_DELTA',
      active: true,
    });
    const periodKey = PromoService.generateValKey([period, metric.id]);
    const valKey = periodKey + '_D';
    const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, metric.id]);
    const colDef = this.gridService.generateMetricColumn(fact, colDefMeta, valKey);
    // Add a cellClass (based on the ref metric) so the Excel export is formatted correctly
    this.addCellClassForFactKey(colDef, this.factKey.id);
    this.promoService.setRendererFramework(colDef, fact);

    // Override props
    colDef.colId = valKey;
    colDef.cellClassRules = PromoService.getCellClassRules(fact);
    colDef.valueGetter = GridService.generateDeltaGetter(promoKey, periodKey);
    if (this.factKey.type === 'PERCENT') {
      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, this.factKey);
    }

    delete colDef.field;

    return colDef;
  }

  private generatePercentDeltaPromoColDef(period: PromoPeriods, metric: CmsMetric, colDefMeta: Map<string, GridColMetadata>): ColDef {
    const fact = new CmsMetric({
      display: '% Change',
      type: 'PERCENT',
      aggType: AggregationType.SUM,
      id: 'PROMO_PERCENT_DELTA',
      active: true,
    });
    const periodKey = PromoService.generateValKey([period, metric.id]);
    const valKey = periodKey + '_PD';
    const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, metric.id]);
    const colDef = this.gridService.generateMetricColumn(fact, colDefMeta, valKey);
    GridService.addCellClassForRefMetric(fact, colDef);
    this.promoService.setRendererFramework(colDef, fact);

    // Override props
    const percentDeltaGetter = GridService.generatePercentDeltaGetter(promoKey, periodKey);
    colDef.colId = valKey;
    colDef.cellClassRules = PromoService.getCellClassRules(fact);
    colDef.valueGetter = percentDeltaGetter;
    colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
    delete colDef.field;

    return colDef;
  }

  private generateTakeRateChartStatResponse(): StatResponse {

    const dims = [
      new CmsField({
        display: 'Period',
        type: 'STRING',
        id: PromoDimensionKeys.PERIOD,
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      }),
      new CmsField({
        display: null,
        type: null,
        id: PromoDimensionKeys.BASKET_SIZE,
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      })
    ];

    const facts = [_.cloneDeep(this.factKey)];

    const periods = PromoConfig.periodOrder.filter(p => {
      if (p === PromoPeriods.POST && !this.parent.formData?.customPromoDateRange.periodStart) return false;
      return true;
    });

    const dM = [];
    dM.push(periods.map(period => this.parent.formData[PromoConfig.periodNames[period]].customPeriodName));
    dM.push([]);

    const vM = [];
    this.getData().forEach((dp, basketSizeIndex) => {
      dM[1].push(dp[PromoDimensionKeys.BASKET_SIZE]);

      periods.forEach((period, periodIndex) => {
        const valKey = PromoService.generateValKey([period, this.factKey.id]);
        const val = _.get(dp, [valKey, 'val']) || 0;
        vM.push([
          periodIndex, // period
          basketSizeIndex, // basket size
          val || 0 // metric value
        ]);
      });
    });

    return new StatResponse(dims, dM, facts, vM);
  }

  private generateTakeRateColDefs(colDefMeta: Map<string, GridColMetadata>): ColDef[] {
    const defs: (ColDef | ColGroupDef)[] = [];

    const dim = new CmsField({
      id: PromoDimensionKeys.BASKET_SIZE,
      active: true,
      retailer: null,
      field: null,
      table: null,
      display: 'Basket Item Quantity',
      filter: null,
      type: 'INTEGER',
    });
    // Create the BASKET_SIZE main dimension column
    {
      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      colDef.cellRenderer = PromoTakerateDrilldownRendererComponent;
      colDef.cellClass = PromoTakerateDrilldownRendererComponent.CELL_CLASS;
      colDef.pinned = 'left';
      defs.push(colDef);
    }

    PromoConfig.periodOrder.forEach(period => {
      if (period === PromoPeriods.POST && !this.parent.formData.customPromoDateRange.periodStart) return;

      // Create Fact ColDef
      const children = [];
      const valKey = PromoService.generateValKey([period, this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, valKey);
      this.addCellClassForFactKey(colDef, this.factKey.id);

      colDef.field = colDef.colId = valKey;
      colDef.headerName = 'Amount';
      if (this.factKey.type === 'PERCENT') {
        colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, this.factKey);
      }
      children.push(colDef);

      const periodKey = PromoConfig.periodNames[period];
      let headerName = this.parent.formData[periodKey].customPeriodName;
      if (period !== PromoPeriods.PROMO) {
        // Additional columns for POST/PRE/YOY column groups
        headerName = 'vs ' + headerName;
        children.push(this.generateDeltaPromoColDef(period, this.factKey, colDefMeta));
        children.push(this.generatePercentDeltaPromoColDef(period, this.factKey, colDefMeta));
      }
      defs.push({
        headerName: headerName,
        children: children,
        openByDefault: true,
        marryChildren: true,
      } as ColGroupDef);
    });

    return defs;
  }

  private generateTimeSeriesChartStatResponse(): StatResponse {
    const sr = this.timeSeriesActivity.getResponse();

    const dims = [
      new CmsField({
        id: PromoDimensionKeys.TIMESERIES_DAY,
        display: 'Year-Month-Day',
        type: 'DATE',
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      }),
      new CmsField({
        display: 'Period',
        type: 'STRING',
        id: PromoDimensionKeys.PERIOD,
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      })
    ];

    const factIndex = sr.getFacts().findIndex(f => f.id === this.factKey.id);
    const facts = [
      this.factKey
    ];

    const dM = [
      sr.getDimensionValues()[0].map(timeStamp => {
        const yoyEndDate = DatesService.add(this.parent.activity.getDateRange(PromoPeriods.YOY).end, { days: 1 });
          const date = new Date(Number(timeStamp));
        if (DatesService.isBefore(date, yoyEndDate)) {
          return DatesService.format(DatesService.add(date, {years: 1}), 'T');
        }
        return timeStamp;
      }),
      sr.getDimensionValues()[2].map(period => this.parent.formData[PromoConfig.periodNames[period]].customPeriodName)
    ];

    const vM = sr.getValues().map(v => [v[0], v[2], v[factIndex + sr.getDimensions().length]]);

    return new StatResponse(dims, dM, facts, vM);
  }

  private generateTimeSeriesColDefs(colDefMeta: Map<string, GridColMetadata>): ColDef[] {
    const defs: ColDef[] = [];

    // Period group
    {
      const dim = new CmsField({
        display: 'Period',
        type: 'STRING',
        id: PromoDimensionKeys.PERIOD,
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      });
      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      colDef.hide = true;
      colDef.rowGroupIndex = 0;
      defs.push(colDef);
    }

    // WEEK dim
    {
      const weekKey: string = this.timeSeriesActivity.getResponse().getDimensions()[1].id;
      const dim = new CmsField({
        id: weekKey,
        display: null,
        type: 'DATE',
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      });
      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      colDef.rowGroupIndex = 1;
      colDef.hide = true;
      defs.push(colDef);
    }

    // YEARMONDAY dim
    {
      const dim = new CmsField({
        id: PromoDimensionKeys.TIMESERIES_DAY,
        display: 'Year-Month-Day',
        type: 'DATE',
        active: true,
        retailer: null,
        table: null,
        field: null,
        filter: null,
      });

      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      defs.push(colDef);
    }

    // Fact col
    {
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta);
      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, this.factKey);
      this.addCellClassForFactKey(colDef, this.factKey.id);
      defs.push(colDef);
    }

    return defs;
  }

  private getData() {
    switch (this.chartType) {
      case ChartTypes.TAKERATE:
        if (this.unitType === UnitTypes.PSPW) {
          if (!this.takeRatePSPWData) {
            this.takeRatePSPWData = this.promoService.generateTakeRateData(this.takeRatePSPWActivity.getJob());
          }
          const data = this.promoService.applyPerStorePerWeek(this.takeRatePSPWData);
          return data;
        }
        return this.takeRateData;
      case ChartTypes.TIMESERIES:

    }
  }

  private renderTakeRate() {

    // render grid
    const apiRef = this.grid.getApi();
    const colDefs = this.generateTakeRateColDefs(apiRef.colDefMeta);
    apiRef.grid.api.updateGridOptions({ columnDefs: colDefs });
    apiRef.grid.api.updateGridOptions({ rowData: this.getData() });
    this.grid.aggregate();
    PromoResultGridProcessor.resizeGrid(apiRef.grid.api);

    // chart
    this.updateChart(this.generateTakeRateChartStatResponse());

  }

  private renderTimeSeries() {
    // render grid
    const apiRef = this.grid.getApi();
    const colDefs = this.generateTimeSeriesColDefs(apiRef.colDefMeta);
    apiRef.grid.api.updateGridOptions({ columnDefs: colDefs });
    apiRef.grid.api.updateGridOptions({ rowData: this.timeSeriesData });
    this.grid.aggregate();
    PromoResultGridProcessor.resizeGrid(apiRef.grid.api);

    // chart
    this.updateChart(this.generateTimeSeriesChartStatResponse());
  }

}

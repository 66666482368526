import { Activity } from 'app/activity/models/activity.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ApplicationHash } from '@siq-js/core-lib';
import { ReportBuilderCriteriaRendererComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-criteria-renderer/report-builder-criteria-renderer.component';
import { AffinitiesCriteriaRendererComponent } from 'app/siq-applications/modules/affinities/components/affinities-criteria-renderer/affinities-criteria-renderer.component';
import { DaypartsCriteriaRendererComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-criteria-renderer/dayparts-criteria-renderer.component';
import { IncrementalsCriteriaRendererComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-criteria-renderer/incrementals-criteria-renderer.component';
import { PromoCriteriaRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/promo-criteria-renderer/promo-criteria-renderer.component';

@Component({
  selector: 'siq-title-criteria',
  templateUrl: './title-criteria.component.html',
  styleUrls: ['./title-criteria.component.scss']
})
export class TitleCriteriaComponent implements OnInit {

  public appCriteriaRenderer: Type<any>;
  @Input() activity: Activity;
  @Input() formControl: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = 'Title';
  @Output() modelChange = new EventEmitter<string>();
  @Input()
  get model(): string {
    return this.formControl.value;
  }
  set model(title: string) {
    this.formControl.setValue(title);
    this.modelChange.emit(this.model);
  }

  constructor() { }

  ngOnInit() {
    this.model = (this.activity ? this.activity.getName() : '');

    switch (this.activity?.getAppId()) {
      case ApplicationHash.AFFINITIES:
        this.appCriteriaRenderer = AffinitiesCriteriaRendererComponent;
        break;
      case ApplicationHash.DAYPARTS:
        this.appCriteriaRenderer = DaypartsCriteriaRendererComponent;
        break;
      case ApplicationHash.INCREMENTALS:
        this.appCriteriaRenderer = IncrementalsCriteriaRendererComponent;
        break;
      case ApplicationHash.PROMO:
        this.appCriteriaRenderer = PromoCriteriaRendererComponent;
        break;
      case ApplicationHash.REPORT_BUILDER:
        this.appCriteriaRenderer = ReportBuilderCriteriaRendererComponent;
        break;
    }
  }

}

import { Component } from '@angular/core';
import { CmsField } from '@siq-js/cms-lib';
import { Filter } from 'app/filter/models/filter';
import { FilterService } from 'app/filter/services/filter.service';
import { AgRendererComponent, ColDef, ICellRendererParams } from '@siq-js/visual-lib';

@Component({
  templateUrl: './value-count-renderer.component.html',
  styleUrls: ['./value-count-renderer.component.scss']
})
export class ValueCountRendererComponent implements AgRendererComponent {

  static ColDef: ColDef = {
    cellRenderer: ValueCountRendererComponent,
    headerName: 'Values',
    resizable: false,
    sortable: true,
    suppressMenu: true,
    suppressNavigable: true,
    suppressSizeToFit: true,
    unSortIcon: false,
    width: 120,
    valueGetter: params => {
      const id = (params.data as CmsField).id;
      const filter = FilterService.getFilter(id);
      return filter.async ? -Infinity : filter.size;
    }
  };

  public filter: Filter;

  constructor() { }

  agInit(params: ICellRendererParams) {
    const id = (params.data as CmsField).id;

    this.filter = FilterService.getFilter(id);
  }

  refresh(params): boolean {
    return true;
  }

}

export enum MixpanelEvent {
  // All possible mixpanel events
  
  ACTIVITY_CREATED = 'Activity Created',
  ACTIVITY_DELETED = 'Activity Deleted',
  ACTIVITY_EDITED = 'Activity Edited',
  ACTIVITY_EXPORTED = 'Activity Exported',
  ACTIVITY_SHARED = 'Activity Shared',
  FAVORITE_CREATED = 'Favorite Created',
  FAVORITE_DELETED = 'Favorite Deleted',
  FEATURE_SELECTED = 'Feature Selected',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  PLATFORM_ACTION = 'Action in Platform',
  REPORTS_CLONED = 'Reports Cloned',
  REPORTS_EXPORTED = 'Reports Exported',
  RESULTS_VIEWED = 'Results Viewed',
  SHARED_ACTIVITY_VIEWED = 'Shared Activity Viewed',
  USER_CREATED = 'User Created',
  USER_DELETED = 'User Deleted',
  USER_EDITED = 'User Edited',

  TEST = 'test' // Should only be used in test env

}

<div class="app-container-wrapper app-header">
  <section class="app-container app-header-container">
    <h5 class="app-title">Manage Users: {{ edit ? 'Edit' : 'Create' }} User</h5>
  </section>
</div>

<div class="app-container-wrapper no-bg">
  <div class="app-container limited-width-content content-padded">

    <mat-card>

      <form [formGroup]="form" (ngSubmit)="submit()">

        <mat-card-content *ngIf="userNotFound">
          No user can be found matching the requested ID.
        </mat-card-content>

        <mat-card-content *ngIf="!userNotFound">

          <div>
            <mat-form-field>
              <input matInput placeholder="First Name" [formControl]="form.controls['firstName']" required>
            </mat-form-field>

            <mat-error *ngIf="!form.controls.firstName.valid && form.controls.firstName.touched">
              First Name is required
            </mat-error>
            <mat-error *ngIf="form.controls.firstName.hasError('white-space-only')">
              {{ form.controls.firstName.errors['white-space-only'].message }}
            </mat-error>
          </div>

          <div>
            <mat-form-field>
              <input matInput placeholder="Last Name" [formControl]="form.controls['lastName']" required>
            </mat-form-field>

            <mat-error *ngIf="!form.controls.lastName.valid && form.controls.lastName.touched">
              Last Name is required
            </mat-error>
            <mat-error *ngIf="form.controls.lastName.hasError('white-space-only')">
              {{ form.controls.lastName.errors['white-space-only'].message }}
            </mat-error>
          </div>

          <div>
            <mat-form-field>
              <input matInput placeholder="Email Address" [formControl]="form.controls['email']" required>
            </mat-form-field>

            <mat-error *ngIf="!form.controls.email.valid && form.controls.email.touched">
              A valid email address is required
            </mat-error>
            <mat-error *ngIf="form.controls.email.hasError('white-space-only')">
              {{ form.controls.email.errors['white-space-only'].message }}
            </mat-error>
            <mat-error *ngIf="form.controls.email.hasError('emailIsForbidden')">
              This email is already taken. Please enter a new one.
            </mat-error>
          </div>

          <div>
            <mat-form-field color="accent">
              <mat-label>Select User Group</mat-label>
              <mat-select [formControl]="form.controls.accessGroup" required>
                <mat-option *ngFor="let ag of accessGroups$ | async" [value]="ag.id">
                  {{ ag.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <br />
            <mat-slide-toggle [formControl]="form.controls['active']">Active</mat-slide-toggle>
          </div>

          <br />

          <button
            mat-stroked-button
            type="button"
            color="accent"
            class="secondary-button"
            (click)="cancel()"
            [disabled]="submitting">Cancel
          </button>

          <button
            mat-raised-button
            type="submit"
            color="accent"
            [disabled]="!form.valid || submitting">Submit
          </button>

        </mat-card-content>

      </form>

    </mat-card>

  </div>
</div>
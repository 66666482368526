import { UntypedFormGroup } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { Input, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';

import * as _ from 'lodash';
import { Activity } from 'app/activity/models/activity.model';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

@Directive()
export abstract class SharingModalTabComponent {

  public form: UntypedFormGroup;

  @Input() modalData: any;

  protected constructor (
    private sharingService: SharingService
  ) {}

  public submit(updateReportDtoId: string, scheduled: boolean): Observable<HttpResponse<any>> {
    if (updateReportDtoId) {
      return this.sharingService
        .update(updateReportDtoId, {
          ...this.getCleanedFormValue(),
          scheduled: scheduled
        });
    } else {
      return this.sharingService
        .create(this.getCleanedFormValue());
    }
  }

  private time12to24(time: string) {
    const date = DatesService.parse(time, 'hh:mm a', new Date());
    return DatesService.format(date, 'HH:mm');
  }

  public getCleanedFormValue(): any {
    if (_.isNull(this.form.value.name) || _.isEmpty(this.form.value.name.trim())) {
      this.form.value.name = Activity.ActivityPlaceholder;
    }
    let obj = this.form.value;
    const start = _.get(obj, ['schedule', 'start']);
    if (start) obj.schedule.start = this.time12to24(obj.schedule.start);

    const dfs = (dic) => {
      /**
       * remove form fields with empty values. eg. null, undefined, empty string ''
       * @param {object} dic
       * @return {object}
       */
      for (let key of Object.keys(dic)) {
        if (_.isNil(dic[key]) || dic[key] === '') {
          delete dic[key];
        } else if (typeof dic[key] === 'object') {
          dic[key] = dfs(dic[key]);
        }
      }
      return dic;
    };
    return dfs(obj);
  }

}

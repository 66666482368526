import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { DimensionColumn } from 'app/siq-applications/modules/report-builder/models/form/dimension-column.model';
import { FormColumn } from 'app/siq-applications/modules/report-builder/models/form/form-column.model';
import { MetricColumn } from 'app/siq-applications/modules/report-builder/models/form/metric-column.model';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import * as _ from 'lodash';
import { DateRangeInterface, DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';
import { ActivityStatus } from 'app/activity/models/activity.model';
import { AppFormData, AppFormJson } from 'app/siq-applications/modules/shared/models/app-form-data.model';
import { ReportBuilderColumnType } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import { CustomInjector } from 'app/core/models/custom-injector';
import { DateUnit, WeekEndingDay } from '@siq-js/core-lib';
import { AnalysisType } from 'app/core/models/analysis-type.enum';
import { UtilsService } from 'app/core/services/utils/utils.service';

export interface ReportBuilderFormJson extends AppFormJson {
  columns: any[];
  globalDateRange: {
    begin?: number;
    end?: number;
    dynamicBegin?: string;
    dynamicEnd?: string;
  };
  globalFilters: FilterSelectionJson[];
  name: string;
  status: ActivityStatus;
  sssChecked: boolean
}

export class ReportBuilderFormData extends AppFormData<ReportBuilderFormJson> {

  public columns: FormColumn[] = [];
  public config: ReportBuilderConfig;
  public globalDateRange: DateRangeInterface; // current date value
  public globalFilters: FilterSelection[] = [];
  public isDateRangeValid?: boolean = true;
  public name: string;
  public schema: string;
  public status: ActivityStatus;
  public sssChecked: boolean = false;

  constructor(fv?: ReportBuilderFormJson) {
    super();
    this.config = CustomInjector.injector.get(ReportBuilderConfig);
    this.init(fv);
  }

  public init(fv?: ReportBuilderFormJson) {
    if (!fv) {
      this.schema = EnvConfigService.getConfig().primaryEntity;
      this.weekEndingday = this.datesService.getDefaultWeekEndingDay();
    } else {
      const cmsConfig = CmsService.get();
      this.status = fv.status;
      this.name = fv.name;
      this.globalDateRange = fv.globalDateRange.dynamicBegin
        ?
        {
          end: new Date(DatesService.dateStoMS(fv.globalDateRange.end)),
          begin: new Date(DatesService.dateStoMS(fv.globalDateRange.begin)),
          dynamicBegin: fv.globalDateRange.dynamicBegin,
          dynamicEnd: fv.globalDateRange.dynamicEnd,
        }
        :
        {
          end: new Date(DatesService.dateStoMS(fv.globalDateRange.end)),
          begin: new Date(DatesService.dateStoMS(fv.globalDateRange.begin))
        };
      this.globalDateRange.type = DateRangeInterfaceType.POPULATED;
      this.globalFilters = fv.globalFilters.map(f => new FilterSelection(f));
      this.schema = fv.schema;

      if (fv.weekEndingDay) {
        this.weekEndingday = fv.weekEndingDay;
      } else { // If there's no weekEndingDay, means it's an old report created before WE project.
        this.weekEndingday = WeekEndingDay.OLD_REPORT; // According to ICE-2295: Old reports before WE project used Saturday as default WE. RB TimeBrekDown used Sunday as default.
      }

      fv.columns.forEach(colJson => {
        const type = colJson.type;
        const cmsItem = cmsConfig.findEntity<CmsMetric>(colJson.ref);
        if (cmsItem) {
          if (type === ReportBuilderColumnType.METRIC) {
            const col = this.addColumn(cmsItem) as MetricColumn;
            col.columnGroupName = colJson.columnGroupName || '';
            // Re-apply year-over-year functionality
            if (colJson.yearOverYear) {
              const yoy = _.clone(colJson.yearOverYear);
              yoy.compDateRange = yoy.compDateRange.dynamicBegin
                ?
                {
                  begin: new Date(DatesService.dateStoMS(yoy.compDateRange.begin)),
                  end: new Date(DatesService.dateStoMS(yoy.compDateRange.end)),
                  dynamicBegin: yoy.compDateRange.dynamicBegin,
                  dynamicEnd: yoy.compDateRange.dynamicEnd,
                }
                :
                {
                  begin: new Date(DatesService.dateStoMS(yoy.compDateRange.begin)),
                  end: new Date(DatesService.dateStoMS(yoy.compDateRange.end))
                };
              col.applyYearOverYear(yoy);
            }

            // Re-apply time aggregates
            if (colJson.timeAggregates) {
              const timeAgg = this.config.timeAggregates.find(ta => ta.key === colJson.timeAggregates);
              col.timeAggregates = {
                aggValue: timeAgg,
                numUnits: timeAgg.calcFn(this.globalDateRange.begin, this.globalDateRange.end)
              };
            }

            // Re-apply filters
            if (colJson.filters) {
              col.filters = colJson.filters.map(f => new FilterSelection(f));
            }

          } else if (colJson.type === ReportBuilderColumnType.DIMENSION) {
            const col = this.addColumn(cmsItem) as DimensionColumn;
            col.columnGroupName = colJson.columnGroupName || '';
          }
        }
      });

      if (fv.sssChecked) {
        this.sssChecked = fv.sssChecked;
      }
    }
  }

  public toJson(): ReportBuilderFormJson {
    let date;
    if (this.globalDateRange) {
      date = DatesService.isDynamic(this.globalDateRange)
      ?
      { // dynamic dates
        begin: DatesService.dateMStoS(this.globalDateRange.begin?.getTime()), // translated dates in begin & end are needed to display
        end: DatesService.dateMStoS(this.globalDateRange.end?.getTime()),
        dynamicBegin: this.globalDateRange.dynamicBegin,
        dynamicEnd: this.globalDateRange.dynamicEnd
      }
      :
      { // static dates
        begin: DatesService.dateMStoS(this.globalDateRange.begin?.getTime()),
        end: DatesService.dateMStoS(this.globalDateRange.end?.getTime()),
      };
    } else {
      // added as fallback for case of fast leave of page during creation new draft ICE-1961
      date = {
        begin: DatesService.dateMStoS(new Date().getTime()),
        end: DatesService.dateMStoS(new Date().getTime()),
      };
    }
    const json: ReportBuilderFormJson = {
      name: this.name,
      columns: [],
      globalDateRange: date,
      globalFilters: this.globalFilters.map(f => f.toJson()),
      schema: this.schema,
      weekEndingDay: this.weekEndingday,
      status: this.status,
      sssChecked: this.sssChecked
    };

    json.columns = this.columns.map(c => c.toJson());

    return json;
  }

  public addColumn(ref: CmsField | CmsMetric, insertIndex?: number): MetricColumn | DimensionColumn {
    if (insertIndex > this.columns.length || insertIndex < 0) {
      return;
    }

    let col: MetricColumn | DimensionColumn;

    if (ref instanceof CmsMetric) {
      col = new MetricColumn(this, ref);
    } else if (ref instanceof CmsField) {
      if (ReportBuilderService.isDimYOYLocked(ref)) {
        this.updateYOY(false);
      }
      col = new DimensionColumn(this, ref);
    }

    if (_.isNil(insertIndex) || insertIndex === this.columns.length) {
      this.columns.push(col);
    } else {
      this.columns.splice(insertIndex, 0, col);
    }

    return col;
  }

  public removeColumn(key: string) {
    _.remove(this.columns, ['key', key]);
  }

  public updateIndices(newOrder: string[]) {
    this.columns = newOrder.map(key => this.getColumnBykey(key));
  }

  public getColumnBykey(k: string): FormColumn {
    return _.find(this.columns, ['key', k]);
  }

  public updateTimeAggregates() {
    this.columns.filter(c => c.isMetric()).forEach((c: MetricColumn) => {
      if (c.timeAggregates) {
        c.applyTimeAggregate(c.timeAggregates.aggValue);
      }
    });
  }

  public updateYOY(dynamicDatesChanged: boolean): boolean {
    let isChanged = false;
    this.columns.filter(c => c.isMetric()).forEach((c: MetricColumn) => {
      if (c.yearOverYear) {
        // start
        let startYOY = new Date(this.globalDateRange.begin.getTime());
        startYOY.setFullYear(this.globalDateRange.begin.getFullYear() - 1);
        // end
        let endYOY = new Date(this.globalDateRange.end.getTime());
        endYOY.setFullYear(this.globalDateRange.end.getFullYear() - 1);

        // ICD-113: Update YOY DD. 
        // If DD changed, no need to re-calc YOY. 
        // If not changed, which happens when opening the report the next days, re-calc YOY.
        if (DatesService.isDynamic(this.globalDateRange) && !dynamicDatesChanged) {
          let startDiff = DatesService.getDifference(DateUnit.DAY)(this.globalDateRange.begin, startYOY);
          startDiff = startDiff - Number(c.yearOverYear.compDateRange.dynamicBegin.split(DatesService.DD_HASH)[1]);
          startYOY = DatesService.add(startYOY, {days: startDiff});
          let endDiff = DatesService.getDifference(DateUnit.DAY)(this.globalDateRange.end, endYOY);
          endDiff = endDiff - Number(c.yearOverYear.compDateRange.dynamicEnd.split(DatesService.DD_HASH)[1]);
          endYOY = DatesService.add(endYOY, {days: endDiff});
        }

        const dr = c.yearOverYear.compDateRange;
        if (DatesService.isValidDate(startYOY) && DatesService.isValidDate(endYOY)) {
          if (!isChanged) isChanged = dr.begin.getTime() !== startYOY.getTime() || dr.end.getTime() !== endYOY.getTime();
          dr.begin = startYOY;
          dr.end = endYOY;
          dr.dynamicBegin = DatesService.DD_HASH + DatesService.getDifference(DateUnit.DAY)(this.globalDateRange.begin, startYOY).toString();
          dr.dynamicEnd = DatesService.DD_HASH + DatesService.getDifference(DateUnit.DAY)(this.globalDateRange.end, endYOY).toString();
        }
      }
    });
    return isChanged;
  }

  public isYoyLocked(): boolean {
    return !_.isEmpty(
      this.columns.filter(c => (c.ref instanceof CmsField) && ReportBuilderService.isDimYOYLocked(c.ref))
    );
  }

  public isRunnable(): boolean {
    // Need at least one metric and one dimension, and both begin and end date range are selected.
    return this.isDateRangeValid && !!_.find(this.columns, c => c.isMetric()) && !!_.find(this.columns, c => c.isDim());
  }

  public size(): number {
    return this.columns.reduce((p, c) => p += c.size(), 0);
  }

  public hasYOY(): boolean {
    return !!this.columns.filter(c => {
      if (c.isMetric()) {
        return (c as MetricColumn).yearOverYear;
      }
      return false;
    }).length;
  }

  public changeSchema(schema: string) {
    this.columns = this.columns
      .filter(col => {
        if (col.isMetric()) {
          const colSchema = (col as MetricColumn).ref;
          return CmsService.isValidMetric(colSchema, schema);
        }
        
        const colSchema = (col as DimensionColumn).ref;
        return CmsService.isValidField(colSchema, schema);
      });

    this.schema = schema;
  }

  public isValidSchema(schema: string): boolean {
    for (let col of this.columns) {
      if (col.isDim()) {
        // check each field column
        const colSchema = (col as DimensionColumn).ref;
        if (!CmsService.isValidField(colSchema, schema)) return false;
      } else if (col.isMetric()) {
        // also check each metric column's filters
        const filters = (col as MetricColumn).filters;
        for (let fs of filters) {
          if (!CmsService.isValidField(fs.field, schema)) return false;
        }
        if (!CmsService.isValidMetric((col as MetricColumn).ref, schema)) return false;
      }
    }

    for (let fs of this.globalFilters) {
      // check all global filters
      if (!CmsService.isValidField(fs.field, schema)) return false;

      // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        if (fs.values.find(val => !retailers.includes(val))) return false;
      }
    }

    return true;
  }

  // Overwrites function in AppFormData
  public getAnalysisType(): AnalysisType {
    return UtilsService.getAnalysisType(this.schema);
  }
}

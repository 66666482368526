import { Activity } from 'app/activity/models/activity.model';
import { Component, OnInit } from '@angular/core';
import { DropdownMenuItem } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.interface';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

@Component({
  selector: 'siq-activity-menu-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ActivityMenuItemComponent implements OnInit {

  public template: { [key: string]: string | boolean } = {};

  private data: DropdownMenuItem<Activity>;

  constructor() { }

  ngOnInit() {
    const activity = this.data.data as Activity;
    this.template.name = activity.getName();
    this.template.application = activity.getApplicationName();
    this.template.created = DatesService.formatDistance(activity.getCreationDate(), new Date(), {addSuffix: true});
    this.template.viewed = activity.isViewed();
    this.template.routerLink = this.data.routerLink || activity.getPath();
  }

}

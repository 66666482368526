import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ValidationMessage, ValidationMessageStatus } from 'app/core/models/validation-message';
import { PromoStepperComponent } from 'app/siq-applications/modules/promo/components/promo-stepper/promo-stepper.component';
import { PromoFormData } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoPeriods } from 'app/siq-applications/modules/promo/models/promo.enums';
import { DateRangeParameter } from '@siq-js/core-lib';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { BehaviorSubject } from 'rxjs';
import { DateRangeInterface, DateSelectionConfig } from 'app/siq-forms/modules/dates/models/interfaces';
import { DateSelectionComponent } from 'app/siq-forms/modules/dates/components/date-selection/date-selection.component';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';

@Component({
  selector: 'siq-js-comparison-dates-selection',
  templateUrl: './comparison-dates-selection.component.html',
  styleUrls: ['./comparison-dates-selection.component.scss'],
})
export class ComparisonDatesSelectionComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() baseDate: DateRangeParameter;
  @Input() dateSelectionConfig: DateSelectionConfig;
  @Input() datesUpdated: BehaviorSubject<boolean>;
  @Input() formData: PromoFormData;
  @Input() parent: PromoStepperComponent;
  @Output() datesSelected: EventEmitter<ValidationMessage> = new EventEmitter<ValidationMessage>();
  @ViewChild('preDateSelectorComponent') preDateSelectorComponent: DateSelectionComponent;
  @ViewChild('yoyDateSelectorComponent') yoyDateSelectorComponent: DateSelectionComponent;
  @ViewChild('customDateSelectorComponent') customDateSelectorComponent: DateSelectionComponent;
  public preDates: DateRangeInterface;
  public hideCustomDateSelector = true;
  public yoyDates: DateRangeInterface;
  public promoPeriods = PromoPeriods;

  // When customDateRange checkbox is checked and there are dates previously selected, validate form using these dates
  private currentDates: DateRangeInterface;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    const currDate = changes.baseDate.currentValue;
    const prevDate = changes.baseDate.previousValue;
    if (currDate && prevDate) {
      this.convertAndSetDates();
      if (this.formData.prePromoDateRange.periodStart) { // Emit valid status
        this.datesSelected.emit({
          message: `Date Ranges Selected`,
          status: ValidationMessageStatus.VALID
        });
      }
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.formData.isCloned) {
      setTimeout(() => { // setTimeout() used here to prevent ExpressionHasChangedAfterViewChecked error.
        this.preDateSelectorComponent.setPrimaryDate(DatesService.toDateRangeInterface(this.formData.prePromoDateRange));
        this.yoyDateSelectorComponent.setPrimaryDate(DatesService.toDateRangeInterface(this.formData.yoyDateRange));
        if (this.formData.customPromoDateRange.periodStart) {
          this.hideCustomDateSelector = false;
          this.customDateSelectorComponent.setPrimaryDate(DatesService.toDateRangeInterface(this.formData.customPromoDateRange));
        }
      });
    }
  }

  public onCheckboxChange(e: MatCheckboxChange) {
    this.hideCustomDateSelector = !e.checked;
    if (!e.checked) {
      this.formData.customPromoDateRange = {
        periodStart: '',
        periodEnd: '',
        customPeriodName: 'Custom Date Range',
      };
    }
    if (e.checked && this.currentDates) {
      this.onCustomDateChanged(this.currentDates);
    } else {
      PromoService.validateForm$.next(!this.hideCustomDateSelector);
      this.datesUpdated.next(true);
    }
  }

  public onCustomDateChanged(dates: DateRangeInterface) {
    this.currentDates = dates;
    this.formData.customPromoDateRange = { customPeriodName: this.formData.customPromoDateRange.customPeriodName, ...DatesService.paramify(dates) };
    PromoService.validateForm$.next(!this.hideCustomDateSelector);
    PromoService.isCustomDateRangeValid$.next(!!dates.isDateRangeValid); // dates.isDateRangeValid might be undefined when user selects date range from first date picker
    if (!dates.isDateRangeValid) return;
    this.datesUpdated.next(true);
  }

  public onPreDateChanged(dates: DateRangeInterface) {
    if (dates.isDateRangeValid === undefined) dates.isDateRangeValid = true; // isDateRangeValid is undefined when function is triggered from Promo Date Range date picker
    this.formData.prePromoDateRange = {customPeriodName: this.formData.prePromoDateRange.customPeriodName, ...DatesService.paramify(dates)};
    PromoService.validateForm$.next(!this.hideCustomDateSelector);
    PromoService.isPrePromoDateRangeValid$.next(!!dates.isDateRangeValid);
    if (!dates.isDateRangeValid) return;
    this.datesUpdated.next(true);
  }

  public onYoyDateChanged(dates: DateRangeInterface) {
    if (dates.isDateRangeValid === undefined) dates.isDateRangeValid = true;
    this.formData.yoyDateRange = {customPeriodName: this.formData.yoyDateRange.customPeriodName, ...DatesService.paramify(dates)};
    PromoService.validateForm$.next(!this.hideCustomDateSelector);
    PromoService.isYoyDateRangeValid$.next(!!dates.isDateRangeValid);
    if (!dates.isDateRangeValid) return;
    this.datesUpdated.next(true);
  }

  public onPeriodNameChange(period: PromoPeriods, name: string) {
    this.formData[PromoConfig.periodNames[period]].customPeriodName = name;
  }

  private convertAndSetDates() {
    this.preDates = DatesService.toDateRangeInterface(this.formData.prePromoDateRange);
    this.yoyDates = DatesService.toDateRangeInterface(this.formData.yoyDateRange);
    this.preDateSelectorComponent.setPrimaryDate(this.preDates);
    this.yoyDateSelectorComponent.setPrimaryDate(this.yoyDates);
  }

}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function forbiddenRegExpValidator(regExp: RegExp, errorMessage: string, errorName: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = regExp.test(control.value);
    return !forbidden ? null : {
      [errorName] : {
          value: control.value,
          message: errorMessage
      }
    }
  };
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DaypartsConfig } from 'app/siq-applications/modules/dayparts/models/dayparts-config.model';
import { DaypartsService } from 'app/siq-applications/modules/dayparts/services/dayparts.service';
import { BehaviorSubject } from 'rxjs';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ColDef } from '@siq-js/visual-lib';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { ActivityCriteriaGenerator } from 'app/activity/models/activity-criteria-generator';
import { ActivityService } from 'app/activity/services/activity.service';
import { DaypartsCriteriaRendererComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-criteria-renderer/dayparts-criteria-renderer.component';
import { DaypartsActivity } from 'app/siq-applications/modules/dayparts/models/dayparts-activity.model';
import { ApplicationHash } from '@siq-js/core-lib';

@Component({
  selector: 'siq-js-dayparts-list',
  templateUrl: './dayparts-list.component.html',
  styleUrls: ['./dayparts-list.component.scss']
})
export class DaypartsListComponent implements OnInit, OnDestroy {
  public actions = this.config.actions;
  // ActivityGrid interface fields
  public activityStream$: BehaviorSubject<DaypartsActivity[]>;
  public callbacks: ActivityCallbacks = {
    rerun: activity => this.activityService.rerun(activity),
    clone: activity => {
      this.daypartsService.cloneReport(activity.getId());
    }
  };
  public columns: (tabKey: ActivityTabKey) => ColDef[];

  constructor(
    public config: DaypartsConfig,
    private activityService: ActivityService,
    private daypartsService: DaypartsService
  ) {
    this.activityStream$ = DaypartsService.Activities$;
    this.columns = tabKey => ActivityCriteriaGenerator.generateColDefs(tabKey, [DaypartsCriteriaRendererComponent.ColDef]);
    this.activityService.getCurrentPageInfo().appId = ApplicationHash.DAYPARTS;
    this.activityService.getCurrentPageInfo().tab = ActivityTabKey.MY_REPORTS;
    ActivityService.refresh();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.activityService.clearCurrentPageInfo();
  }

}

import {ExcelStyle} from 'libs/visual-lib/src/lib/ag-grid';

export enum TextColorType {
  WARN = <any>'text-warn',
  SUCCESS = <any>'text-success',
}

export const ExcelCellStyles = <ExcelStyle[]>[
  {
    id: TextColorType.SUCCESS.toString(),
    font: {
      color: '#008000'
    }
  },
  {
    id: TextColorType.WARN.toString(),
    font: {
      color: '#ff0000'
    }
  }
];

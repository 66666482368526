<div class="renderer-container" (click)="$event.stopPropagation()">

  <mat-checkbox [checked]="state === stateEnums.ALL"
                (change)="update()"
                [indeterminate]="state === stateEnums.SOME"
  ></mat-checkbox>
    <mat-icon [matMenuTriggerFor]="dropdown">arrow_drop_down</mat-icon>

</div>

<mat-menu #dropdown="matMenu">
  <button mat-menu-item (click)="applyFilter(activityTableFilterEnums.ALL)">All</button>
  <button mat-menu-item (click)="applyFilter(activityTableFilterEnums.OPENED)">Opened</button>
  <button mat-menu-item (click)="applyFilter(activityTableFilterEnums.UNOPENED)">Unopened</button>
  <button mat-menu-item (click)="applyFilter(activityTableFilterEnums.FAVORITE)">Favorites</button>
</mat-menu>

/**
 * YS 3/11/2021
 *
 * BUSINESS RULES:
 * 1. recipient is in the same user group - allow
 * 2. recipient is not registered on the platform - allow
 * 3. recipient is registered on the platform but belongs to different user group - do NOT allow
 * 4. if a sender shares with an email that registered to a user of different user group, that is a hole in the requirements - do NOT allow
 *
 * VARIABLE MEANINGS:
 * users: list of users that are active and in the same group as current logged-in user
 * filteredUsers: list of users at run time, contains the users in the dropdown selection
 * recipientList: list of recipients that will receive shared/scheduled report
 *
 * SM 2/18/2023
 * NOTE: For business rules #4, notice that this.userService.isRegistered can find registered users by entered email addresses.
 *
 **/
import * as _ from 'lodash';
import * as Keycoder from 'keycoder';
import {
  COMMA,
  ENTER,
  SEMICOLON,
  SPACE
  } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
  } from '@angular/core';
import {
  first,
  firstValueFrom,
  map,
  skip,
  startWith,
  takeUntil
  } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Recipient } from 'app/activity/modules/sharing/models/recipient.model';
import { RecipientStatus } from 'app/activity/modules/sharing/models/recipient-status.enum';
import { Subject } from 'rxjs';
import { User } from 'app/user/models/user.model';
import { UserService } from 'app/user/services/user.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationResponse } from 'app/core/components/confirmation-modal/confirmation-response.interface';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { AuthService } from 'app/auth/services/auth.service';

@Component({
  selector: 'siq-recipient-list',
  templateUrl: './recipient-list.component.html',
  styleUrls: ['./recipient-list.component.scss']
})
export class RecipientListComponent implements OnInit, OnDestroy {

  public confirmEmails = new Set();
  public declineEmails = new Set();
  public filteredUsers: User[] = [];
  public inputControl = new UntypedFormControl();
  public users: User[] = [];
  get recipients(): Recipient[] {
    return this.recipientList;
  }
  set recipients(recipients: Recipient[]) {
    this.recipientList = recipients;
    this.isValid(recipients).then(valid => {
      //using promise to defer updating formControlRecipients and keep it all nicely synchronized
      this.updateErrors('invalidEmails', !valid);
      // only update formControlRecipients when recipients is valid
      if (valid) this.formControlRecipients.setValue(_.map(recipients, 'email'));
    });
  }

  private recipientList: Recipient[] = [];
  private separatorKeys: string[];
  private separatorRegExp: RegExp;
  private unsubscribe: Subject<void> = new Subject<void>();

  @Input() formControlRecipients: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = 'Add Recipient';
  @Input() promptForExternal = false;
  @Input() separatorKeysCodes: number[] = [ COMMA, ENTER, SEMICOLON, SPACE ];
  @Input() requireUserIncluded = false;

  @Output() modelChange = new EventEmitter<string[]>();

  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: true }) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor (
    private utilsService: UtilsService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    // Admin can share/schedule reports with other user groups in MT_Lite (SINGLE_ONLY)
    const allowAllUsers = EnvConfigService.isSingleMode() && await AuthService.CurrentUser$.getValue().isAdmin();
    if (allowAllUsers) {
      if (_.isEmpty(UserService.Users$.getValue())) {
        const users = firstValueFrom(UserService.Users$.pipe(skip(1))); //skip default value
        this.userService.retrieveUsers();
        this.users = await users;
      } else {
        this.users = UserService.Users$.getValue();
      }
    } else {
      this.users = AccessGroupService.getAccessGroupUsers();
    }

    // Filter out non-active users; these will be treated like "external" users for share/schedule
    this.users = this.users.filter(u => !!u.active);

    this.filteredUsers = this.filterUsers('');
    this.inputControl.valueChanges
      .pipe(
        startWith(''),
        map((value: string) => this.filterUsers(value)),
        takeUntil(this.unsubscribe)
      )
      .subscribe((users: User[]) => {
        this.filteredUsers = users;
        this.updateErrors('invalidEmails', !_.isEmpty(this.inputControl.value));
      });

    this.separatorKeys = _.filter(_.map(this.separatorKeysCodes, (keyCode: number) => {
      return _.escapeRegExp(_.escapeRegExp(Keycoder.toCharacter(keyCode, false) || Keycoder.charCodeToCharacter(keyCode)));
    }));
    this.separatorRegExp = RegExp('(' + _.join(this.separatorKeys, '|') + ')+', 'g');
    
    this.createRecipients(this.formControlRecipients.value, true);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public addRecipient(recipient: Recipient | User): void {
    if (_.isEmpty(recipient?.email?.trim())) return;
    if (recipient instanceof User) {
      recipient = new Recipient(this.users, recipient);
    }
    this.removeRecipient(recipient);
    this.recipients = _.union(this.recipients, [ recipient ]);
    this.input.nativeElement.value = '';
    this.inputControl.setValue(null);
    this.filteredUsers = this.filteredUsers.filter(u => u.email !== recipient.email);
  }

  public createRecipient(value: string): void {
    if (this.matAutocomplete.isOpen && this.filteredUsers.length > 0) return;
    if (this.filteredUsers.filter(u => u.email === value).length === 0) {
      // if autocomplete list is empty, add input value as a new User
      const delimiter = this.separatorKeys[0];
      this.createRecipients(_.split(_.replace(value, this.separatorRegExp, delimiter), delimiter));
    }
    this.matAutocompleteTrigger.closePanel();
  }

  public removeRecipient(recipient: Recipient): void {
    this.recipients = this.recipients.filter(r => r.email !== recipient.email);
    const curRecipientsEmails = new Set(this.recipients.map(r => r.email));
    this.filteredUsers = this.users.filter(u => !u.isInternal()).filter(u => !curRecipientsEmails.has(u.email));
  }

  private confirmExternalRecipient(recipient: Recipient): Promise<boolean> {
    return new Promise(((resolve, reject) => {
      this.utilsService.openModal(
          ConfirmationModalComponent,
          {
            header: 'Are you sure?',
            body: `You are sharing to ${recipient.email} who is not a user in the platform. They will only get an attachment. `
          },
          UtilsService.MODAL_CONFIG_SMALL
        )
        .afterClosed()
        .pipe(first())
        .subscribe(
          (response: ConfirmationResponse) => {
            if (response) resolve(response.value);
            this.confirmEmails.delete(recipient.email);
            this.declineEmails.delete(recipient.email);
            this.matAutocompleteTrigger.closePanel();
          },
          (error) => reject(error)
        );
    }));
  }

  // Decline emails registered to users in different user group
  private declineInternalRecipient(recipient: Recipient): Promise<boolean> {
    return new Promise(((resolve, reject) => {
      this.utilsService.openModal(
        ConfirmationModalComponent,
        {
          header: 'Warning:',
          body: `Because they are in different user groups, sharing with the following recipients is not allowed: ${recipient.email}`,
          buttons: [
            { label: 'Ok', response: { accepted: false, value: false } }
          ]
        },
        UtilsService.MODAL_CONFIG_SMALL
      )
        .afterClosed()
        .pipe(first())
        .subscribe(
          (response: ConfirmationResponse) => {
            if (response) resolve(response.value);
            this.confirmEmails.delete(recipient.email);
            this.declineEmails.delete(recipient.email);
            this.matAutocompleteTrigger.closePanel();
          },
          (error) => reject(error)
        );
    }));
  }

  private createRecipients(emails: string[], skipConfirm = false): void {
    _.each(emails, (email: string) => {
      const recipient = new Recipient(this.users, email);
      if (recipient.status !== RecipientStatus.VALID) {
        //keep invalid recipients, they will be red and submit button will be disabled
        this.addRecipient(recipient);
      } else {
        if (!recipient.email || recipient.email.trim().length === 0) return;
        if (this.confirmEmails.has(recipient.email)) return; //to prevent from calling multiple this.confirmExternalRecipient until user closes modal
        if (!recipient.external) {
          this.addRecipient(recipient);
        } else {
          this.userService.isRegistered(recipient.email).subscribe(isRegistered => {
            if (isRegistered) {
              //recipient is not in the list of available users but registered, it means he is from different access group or inactive
              if (this.declineEmails.has(recipient.email)) return; // Do not open another modal because one of the declined email has already opened.
              this.declineEmails.add(recipient.email);
              this.declineInternalRecipient(recipient).then(() => {
                this.input.nativeElement.value = '';
                this.inputControl.setValue(null);
              });
            } else {
              if (this.promptForExternal && !skipConfirm) {
                this.confirmEmails.add(recipient.email); //to prevent from calling multiple this.confirmExternalRecipient until user closes modal
                this.confirmExternalRecipient(recipient)
                  .then((accepted: boolean) => {
                    if (accepted) {
                      this.addRecipient(recipient);
                    } else {
                      this.clearInputControl();
                    }
                  });
              } else {
                this.addRecipient(recipient);
              }
            }
          });
        }
      }
    });
  }

  private clearInputControl() {
    this.input.nativeElement.value = '';
    this.inputControl.setValue(null);
  }

  /* YS: filterUser is always empty on localhost because the condition '&& !user.isInternal()'. */
  // filter users, if input value should is the start of user's firstName or lastName
  // @swiftiq.com user will not be shown in drop down but can be add recipient list
  private filterUsers(value: string): User[] {
    value = _.trim(_.toLower(value));

    return _.filter(this.users, (user: User) => {
      const added: boolean = _.includes(this.formControlRecipients.value, user.email);
      const firstName: boolean = user.firstName.toLowerCase().indexOf(value) === 0;
      const lastName: boolean = user.lastName.toLowerCase().indexOf(value) === 0;

      return !added && (firstName || lastName) && !user.isInternal();
    });
  }

  private isValid(recipients: Recipient[]): Promise<boolean> {
    let outstanding = recipients.length;
    // if recipients is empty, then it is not invalid
    if (outstanding === 0) {
      return Promise.resolve(false);
    }
    return Promise.resolve(!recipients.find(recipient => recipient.status !== RecipientStatus.VALID));
  }

  private updateErrors(errorKey: string, error: boolean | string = true): void {
    // @TODO: Determine if necessary for inputControl
    // @TODO: Ensure form field updates style when invalid
    UtilsService.setFormError(this.inputControl, errorKey, error);
    UtilsService.setFormError(this.formControlRecipients, errorKey, error);
  }
}

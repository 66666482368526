<div class="forgot-password-container">
  <mat-card class="forgot-password-wrapper">

    <mat-card-title>
      <img *ngIf="lightTheme" src="assets/img/PDI Logo-RGB-Two Color-with Tag-Digital.png" alt="PDI Technologies" class="logo" />
      <img *ngIf="!lightTheme" src="assets/img/PDI Logo-RGB-White-with Tag-Digital.png" alt="PDI Technologies" class="logo" />
      <h3>Password Recovery</h3>
    </mat-card-title>

    <form (ngSubmit)="submit()">
      <mat-card-content ng-form="model" [ngSwitch]="step">

        <!-- Enter email address of account wanting password reset (initial screen) -->
        <div *ngSwitchCase="0">
          <mat-form-field>
            <input matInput placeholder="Email" (input)="canSubmit()" [(ngModel)]="model.email" name="username" required>
          </mat-form-field>
          <button type="button" mat-button class="secondary-button" (click)="step = 2">Already have a reset code?</button>
        </div>

        <!-- Enter the answer to the security question -->
        <div *ngSwitchCase="1">
          <mat-form-field>
            <input matInput [placeholder]="model.securityQuestion" (input)="canSubmit()" [(ngModel)]="model.securityAnswer" name="security-answer" required>
          </mat-form-field>
        </div>

        <!-- Enter the token that was emailed to the user -->
        <div *ngSwitchCase="2">
          <h6>Please enter the code that was sent to your email.</h6>
          <mat-form-field>
            <input matInput placeholder="Code" (input)="canSubmit()" [(ngModel)]="model.token" name="code" required>
          </mat-form-field>
        </div>

        <!-- Enter a new password -->
        <div *ngSwitchCase="3">
          <mat-form-field>
            <input matInput placeholder="New Password" [(ngModel)]="model.password" (input)="canSubmit()" name="password" type="password" required>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Confirm Password" [(ngModel)]="model.confirmPassword" (input)="canSubmit()" name="confirm-password" type="password" required>
          </mat-form-field>

          <mat-card>
            <siq-password-rules
              [newPassword]="model.password"
              [confirmNewPassword]="model.confirmPassword"
            ></siq-password-rules>
          </mat-card>
        </div>

        <!-- Password successfully reset -->
        <div *ngSwitchCase="4">
          <h5>Your password has been successfully changed.</h5>
        </div>

        <!-- Neither any security questions or errors was returned (unable to proceed) -->
        <div *ngSwitchCase="5">
          <mat-error>You cannot reset your password because you have not set your security questions. Please contact an administrator for help.</mat-error>
        </div>

        <mat-error *ngIf="errorMessage"> {{ errorMessage }}</mat-error>
      </mat-card-content>

      <mat-card-actions>
        <button mat-stroked-button [routerLink]="'/' + loginRoute.path" *ngIf="step < 4" class="secondary-button">Cancel</button>
        <button mat-raised-button color="accent" [disabled]="submitDisabled"> {{ (step < 4) ? 'Submit' : ((step == 5) ? 'Exit' : 'Done') }}</button>
      </mat-card-actions>
    </form>

  </mat-card>
</div>

<div class="top-part">
  <form class="group-name-form" #form="ngForm">
    <mat-form-field>
      <mat-label>Comparison Group Name</mat-label>
      <input
        matInput
        name="name"
        required
        [(ngModel)]="cg.name"
        #name="ngModel"
      >
      <mat-error
        *ngIf="name.invalid && name.touched && name.errors.required"
      >Comparison Group Name is required
      </mat-error>

    </mat-form-field>
  </form>

  <div>
    <button mat-stroked-button (click)="addFilter()" type="button" class="secondary-button">Add a Filter</button>
    <span class="spacer"></span>
  <!--  <button mat-raised-button (click)="loadFilterset()" type="button" class="gray-button" [disabled]="!canLoadFilterset">Load Filterset</button>-->
  <!--  <button mat-raised-button (click)="saveFilterset()" type="button" class="gray-button" [disabled]="!canSaveFilterset">Save Filterset</button>-->
  </div>

  <div class="chip-container">
    <mat-chip
      [ngClass]="(cg.isValid) ? 'valid' : 'invalid'"
    >{{cg.isValid ? 'Valid' : 'Invalid'}}
    </mat-chip>
  </div>

  <button mat-icon-button color="warn" (click)="onRemoveCG()">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<div class="bot-part">
  <ng-container *ngIf="model$ | async as model">
    <mat-chip-list>
      <siq-filter-chip *ngFor="let filter of model"
                       [model]="filter"
                       [parent]="this"
      ></siq-filter-chip>
    </mat-chip-list>
  </ng-container>
</div>



import { Injectable } from '@angular/core';
import { CmsMetric } from '@siq-js/cms-lib';
import * as _ from 'lodash';
import { Column, GRID_DEFAULTS } from 'libs/visual-lib/src';

@Injectable({
  providedIn: 'root'
})
export class CellRendererService {

  public static getFormattedValue(params: any, data: any, dependsOnAsyncMetrics: boolean): string|number {
    // For metrics that are calculated based on other async metrics, use the valueGetter fn to determine/retrieve values
    let val;
    if (dependsOnAsyncMetrics) {
      params.data = data;
      val = params.colDef.valueGetter(params);
    } else {
      val = data[params.colDef.colId];
    }
    return params.colDef.valueFormatter({value: val});
  }

  public static getUnformattedValue(params: any, data: any, dependsOnAsyncMetrics: boolean): number {
    // For metrics that are calculated based on other async metrics, use the valueGetter fn to determine/retrieve values
    let val;
    if (dependsOnAsyncMetrics) {
      let temp = _.cloneDeep(params);
      temp.data = data;
      val = params.colDef.valueGetter(temp);
    } else {
      val = data[params.colDef.colId];
    }
    return val;
  }

  public static setDependsOnAsyncMetrics(metric: CmsMetric, params: any): boolean {
    let dependsOnAsyncMetrics = false;

    if (metric.id === 'RB_PERCENT_DELTA' || metric.id === 'RB_DELTA') {
      if (params.api.isPivotMode() && _.isNil(params.colDef.pivotValueColumn)) return dependsOnAsyncMetrics;

      const colId = params.api.isPivotMode() ? params.colDef.pivotValueColumn.colId : params.colDef.colId;
      const dependsOnColIds: string[] = colId.split('|');
      dependsOnColIds.pop(); // remove last (lyd, lypd)

      dependsOnColIds.some(id => {
        const match = _.find(params.api.getColumns(), {colDef: {colId: id}});
        dependsOnAsyncMetrics = (<Column><unknown>match)?.getAggFunc() === GRID_DEFAULTS.AGG_MAP.AS;
        return dependsOnAsyncMetrics;
      });
    }

    return dependsOnAsyncMetrics;
  }

  constructor() { }
}

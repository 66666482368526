import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'siq-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {
  @Input() location: string;

  constructor() { }

  ngOnInit() {
  }

}

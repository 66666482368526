import { Component } from '@angular/core';
import { ColDef, IHeaderAngularComp, IHeaderParams } from '@siq-js/visual-lib';
import { ChipListCellConfig } from 'app/core/components/cell-renderers/chip-list-cell/chip-list-cell-config.interface';
import { HeaderCellSortableComponent } from 'app/core/components/cell-renderers/header-cell-sortable/header-cell-sortable.component';
import * as _ from 'lodash';

@Component({
  selector: 'siq-chip-list-cell',
  templateUrl: './chip-list-cell.component.html',
  styleUrls: ['./chip-list-cell.component.scss']
})
export class ChipListCellComponent implements IHeaderAngularComp {
  public config: ChipListCellConfig;
  public list: any[] = [];

  public static generateColDef(name: string, field: string, config: ChipListCellConfig): ColDef {
    if (_.isNil(config.valueFormatter)) {
      config.valueFormatter = (params) => params.value;
    }

    let cd: ColDef = {
      field: field,
      headerComponent: HeaderCellSortableComponent,
      headerComponentParams: config.headerComponentParams,
      headerName: name,
      cellRenderer: ChipListCellComponent,
      cellRendererParams: config.cellRendererParams,
      valueFormatter: config.valueFormatter // Added this valueFormatter to support cloud-export (Excel)
    } as ColDef;

    // Apply config overrides
    for (let k of Object.keys(config)) {
      cd[k] = config[k];
    }

    return cd;
  }

  constructor() {
  }

  agInit(params: any) {
    /*
      params.value - This should hold an array: any[]
     */

    const _list: string[] = [];
    if (params.displayListLength) {
      _list.push(params.value.length.toString());
    } else if (params.displayValuePath) {
      params.value.forEach(o => {
        _list.push(_.get(o, params.displayValuePath));
      });
    } else {
      _list.push(...params.value);
    }
    this.list = _list;
  }

  refresh(params: IHeaderParams): boolean {
    return  true;
  }
}

import { Injectable } from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  EXCEL_EMPTY_CELL,
  ExcelDataType,
  ExcelCellStyles,
  ExcelDataTypeStatic,
  ExcelExportMultipleSheetParams,
  ExcelExportParams,
  ExcelStyle,
  GridService,
  VISUAL_CONFIG,
  ProcessHeaderForExportParams,
  CsvExportParams,
  VisualOptions,
  HEATMAP_ACTIVE,
  AgGridAngular
} from 'libs/visual-lib/src';
import { CmsConfig, CmsField, CmsMetric } from '@siq-js/cms-lib';
import { CoreConstants, DatesServiceCore } from '@siq-js/core-lib';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  private static readonly FILENAME_MAXLENGTH = 64;
  private static readonly DEFAULT_REPORT_NAME = 'Untitled Report';
  private static readonly DEFAULT_SHEET_NAME = 'Untitled Sheet';

  public static readonly EXCEL_FORMAT_OVERRIDE_PREPEND: string = 'EXCELFORMULA:';
  public static readonly EXCEL_STYLE_GENERIC_PERCENT: string = 'GENERIC:PERCENT';
  public static readonly EXCEL_STYLE_GENERIC_STRING: string = 'GENERIC:STRING';

  constructor() { }

  public static saveSheetsAsXLSX (
    params: ExcelExportMultipleSheetParams,
    agGridAngular: AgGridAngular
  ) {
    agGridAngular.api.exportMultipleSheetsAsExcel(params);
  }

  public static saveSheetAsXLSX (
    filename: string,
    gridVisualOptions: VisualOptions
  ) {
    const params = ExcelService.getExcelExportParams(gridVisualOptions, filename);
    gridVisualOptions.apiRef().grid.api.exportDataAsExcel(params);
  }

  public static exportSheetAsCSV(
    filename: string,
    gridVisualOptions: VisualOptions
  ) {
    const options: CsvExportParams = {
      fileName: ExcelService.generateFileName(filename),

      processHeaderCallback: (params: ProcessHeaderForExportParams) => {
        return (ExcelService.stringToUnicode(params.column.getColDef().headerName));
      },
      processCellCallback: params => {
        return GridService.processCellCallback(params, gridVisualOptions.apiRef().colDefMeta);
      }
    };
    gridVisualOptions.apiRef().grid.api.exportDataAsCsv(options);
  }

  /** Converts special HTML characters like '&Delta;' to unicode for exporting */
  public static stringToUnicode(string): string {
    return string
      .replace(/&Delta;/, '\u{0394}');
  }

  /**
   * Sheet name in excel export is sanitized by this method during sheet name creation
   * @param sheetName
   */
  public static sanitizeSheetName(sheetName: string): string {
    sheetName = _.isNil(sheetName) ? this.DEFAULT_SHEET_NAME : sheetName.trim();
    if (!sheetName) sheetName = this.DEFAULT_SHEET_NAME; // replace blank names with default
    return sheetName.replace(/[\/*?:[\]]/g, '').substr(0, 31);
  }

  public static createExcelStyle(obj: any): ExcelStyle {
    const excelStyle: ExcelStyle = {
      id: '',
      numberFormat: {
        format: ''
      }, // numberFormat must be object; export code attempts to access the 'format' property so cannot be null
      dataType: null // optional
    };
    return _.merge(excelStyle, obj);
  }

  /**
   * For items in the enums array (Dimension|Fact), return the
   * desired Excel dataType based on their 'excelFormat' attribute
   */
  public static getExcelDataType(excelFormat: string): ExcelDataType {
    const excelFormatForNumbers = ['$#', '%', '#,', '#']; // from most to least specific
    const isNumber = _.isNil(excelFormat) ? false : excelFormatForNumbers.some(val => {
      return excelFormat.includes(val);
    });

    return isNumber ? ExcelDataTypeStatic.EXCEL_NUMBER : ExcelDataTypeStatic.EXCEL_STRING;
  }

  public static generateExcelStyleGenericString(): ExcelStyle {
    return <ExcelStyle>{
      id: ExcelService.EXCEL_STYLE_GENERIC_STRING,
      dataType: ExcelDataTypeStatic.EXCEL_STRING
    };
  }

  public static generateExcelStylesForList(list: (CmsField | CmsMetric)[]): ExcelStyle[] {
    return _.map(list, e => {

      const matchDataType = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, { type: e.type });
      const excelFormat = matchDataType?.excelFormula ? matchDataType.excelFormula : '';

      return <ExcelStyle>{
        id: GridService.ENUM_ID_PREPEND + e.id,
        numberFormat: {
          // format: e.excelFormula // TODO: "excelFormula" is not present in the new CMS system yet
          format: excelFormat
        },
        dataType: ExcelService.getExcelDataType(excelFormat)
      };
    });
  }

  /**
   * new function for generating excel styles
   */
  public static generateExcelStyles(): ExcelStyle[] {

    const enums = [
      ...(<CmsConfig>CoreConstants.cmsConfig).fields,
      ...(<CmsConfig>CoreConstants.cmsConfig).metrics
    ];

    // generate styles for list
    const enumStyles: Array<ExcelStyle> = ExcelService.generateExcelStylesForList(enums);

    // generate generic strings style
    enumStyles.push(ExcelService.generateExcelStyleGenericString());

    // Not all CmsMetrics have a corresponding PERCENT_xxxxxx metric, but any CmsMetric
    // can have YOY applied (in RB) and thus *could* have "% Delta TY-LY CmsMetric"
    // so create a generic style for this.
    enumStyles.push(
      <ExcelStyle>{
        id: ExcelService.EXCEL_STYLE_GENERIC_PERCENT,
        dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
        numberFormat: {
          format: '#,##0.0%'
        }
      }
    );

    /*
     * This is a bit of a hack, but for any Number cells that render blank (and should render blank in Excel also)
     * they output with a value of '0', so set the font and the background color to white (#ffffff) making them invisible.
     *
     * The "accepted" way of doing this is to use a numberFormat ending in ';;@', but in testing this was not working.
     * This accomplishes the same end result.
     */
    enumStyles.push(
      ExcelService.createExcelStyle({
        id: EXCEL_EMPTY_CELL,
        dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
        font: {
          color: '#ffffff'
        }
      })
    );
    enumStyles.push(
      <ExcelStyle>{
        id: HEATMAP_ACTIVE,
        dataType: ExcelDataTypeStatic.EXCEL_NUMBER
      }
    );
    const enumsForFormatters = _.uniqBy(_.filter(enums, e => e.excelFormula), 'type');
    const excelFormats = _.map(enumsForFormatters, e => {
      return <ExcelStyle>{
        id: ExcelService.EXCEL_FORMAT_OVERRIDE_PREPEND + e.type,
        numberFormat: {
          format: e.excelFormula
        }
      };
    });
    return [...ExcelCellStyles, ...enumStyles, ...excelFormats];
  }

  /**
   * File Name of whole Report
   * @param desiredFileName
   * @private
   */
  public static generateFileName (desiredFileName: string): string {
    desiredFileName = _.isNil(desiredFileName)? this.DEFAULT_REPORT_NAME : desiredFileName.trim();
    if (!desiredFileName) desiredFileName = this.DEFAULT_REPORT_NAME; // replace blank names with default
    // ag-grid export does not like to have '.' in filename
    desiredFileName = desiredFileName.replace(/\./g,'-');
    //remove undesired characters(non-latin, ASCII range 0 to 127 are OK)
    desiredFileName = desiredFileName.replace(/[^\x00-\x7F]/g, "");
    //add unique postfix _dateTimeFormat
    desiredFileName = desiredFileName.concat('-', DatesServiceCore.format(new Date(), CoreConstants.dateTimeFormat));
    //check if name is too long
   return desiredFileName.substring(0, ExcelService.FILENAME_MAXLENGTH);
  }

  public static getCellClassRules (enums: (CmsMetric | CmsField)[] = []): any {

    const out = {};

    _.each(enums, e => {
      const ruleKey = GridService.ENUM_ID_PREPEND + e.id;
      out[ruleKey] = (context) => {
        const cellClass = _.get(context, 'node.rowGroupColumn.colDef.cellClass');
        return context.enumId === e.id || cellClass && cellClass.includes(ruleKey);
      };
    });

    return out;

  }

  // This function is also called from cloud-export.service.ts
  public static getExcelExportParams(gridVisualOptions: VisualOptions, filename? : string): ExcelExportParams{
    const params: ExcelExportParams = {
      fileName: ExcelService.generateFileName(filename),
      processHeaderCallback: (_params: ProcessHeaderForExportParams) => {
        return (ExcelService.stringToUnicode(_params.column.getColDef().headerName));
      },
      processCellCallback: _params => {
        return GridService.processCellCallback(_params, gridVisualOptions.apiRef().colDefMeta);
      }
    };
    params.sheetName = ExcelService.sanitizeSheetName(params.sheetName);
    return params;
  }

}

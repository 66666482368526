import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FiltersetGridConfig } from 'app/filtersets/models/interfaces';
import { AgGridAngular, ColDef, GridOptions, GridReadyEvent, GridService, GridThemes } from '@siq-js/visual-lib';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';
import { NameRendererComponent } from 'app/filtersets/components/renderers/name-renderer/name-renderer.component';
import { FiltersetFiltersRendererComponent } from 'app/filtersets/components/renderers/filterset-filters-renderer/filterset-filters-renderer.component';
import { CreatedRendererComponent } from 'app/filtersets/components/renderers/created-renderer/created-renderer.component';
import { DeleteRendererComponent } from 'app/filtersets/components/renderers/delete-renderer/delete-renderer.component';
import { FiltersetsService } from 'app/filtersets/services/filtersets.service';
import { map, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-js-filtersets-grid',
  templateUrl: './filtersets-grid.component.html',
  styleUrls: ['./filtersets-grid.component.scss']
})
export class FiltersetsGridComponent extends BaseSiqComponent implements OnInit {
  public agTheme: string = GridThemes.ALPINE; // default
  public gridOptions: GridOptions;

  @Input() config: FiltersetGridConfig;

  @ViewChild('filtersetsGrid') grid!: AgGridAngular;

  constructor(
    private filtersetsService: FiltersetsService
  ) {
    super();
  }

  ngOnInit(): void {

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });

    this.gridOptions = {
      rowData: [],
      sideBar: false,
      headerHeight: 52,
      rowHeight: 58,
      suppressContextMenu: true,
      domLayout: 'autoHeight',
      suppressCellFocus: true,
      suppressClickEdit: true,
      columnDefs: this.getColDef(this.config.forSelection),

      onSortChanged: (event) => {},
      onRowClicked: (event) => {
        if (this.config.forSelection) {
          // close and pass filtersets to filterGroup.model
          this.config.parent.closeModal(event.data);
        } else {
          // TODO: edit filterset
        }
      },
      onGridReady: (event: GridReadyEvent) => {
        // TODO:
        this.resizeColumn();
      }
    };
    this.getFiltersetsRowData();
    this.filtersetsService.deleteButtonClicked()
      .pipe(takeUntil(this.unsub$))
      .subscribe(response => { this.getFiltersetsRowData(); });
  }

  private getColDef(isForSelection: boolean): ColDef[] {
    const AG_CELL_VERTICAL_CENTERED = 'ag-cell-vertical-centered';
    let columnDefs: ColDef[] = [
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
        editable: !isForSelection,
        suppressKeyboardEvent: (params) => {
          return params.event.keyCode === 13 ||
            params.event.keyCode === 8 ||
            params.event.keyCode === 46
            ;
        },
        sortable: true,
        resizable: true,
        cellRenderer: NameRendererComponent
      },
      {
        field: 'filterSets',
        headerName: 'Filters',
        width: 600,
        sortable: false,
        resizable: true,
        autoHeight: true,
        cellRenderer: FiltersetFiltersRendererComponent,
        cellClass: AG_CELL_VERTICAL_CENTERED
      },
      {
        field: 'created',
        headerName: 'Created',
        width: 200,
        sortable: true,
        resizable: true,
        sort: 'desc',
        cellRenderer: CreatedRendererComponent,
        cellClass: AG_CELL_VERTICAL_CENTERED
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 100,
        sortable: false,
        resizable: true,
        cellRenderer: DeleteRendererComponent,
        cellClass: AG_CELL_VERTICAL_CENTERED
      }
    ];

    if (isForSelection) {
      columnDefs = [
        {
          field: 'name',
          headerName: 'Name',
          width: 200,
          editable: false,
          suppressKeyboardEvent: (params) => {
            return params.event.keyCode === 13;
          },
          sortable: true,
          resizable: true,
          cellRenderer: NameRendererComponent
        },
        {
          field: 'filterSets',
          headerName: 'Filters',
          width: 600,
          sortable: false,
          resizable: true,
          autoHeight: true,
          cellRenderer: FiltersetFiltersRendererComponent,
          cellClass: AG_CELL_VERTICAL_CENTERED
        },
        {
          field: 'created',
          headerName: 'Created',
          width: 200,
          sortable: true,
          sort: 'desc',
          resizable: true,
          cellRenderer: CreatedRendererComponent,
          cellClass: AG_CELL_VERTICAL_CENTERED
        }
      ];
    }

    return columnDefs;
  }

  private getFiltersetsRowData() {
    this.filtersetsService.retrieveFiltersets()
    .pipe(
      map( (fs: any ) => {
        const items = fs;
          items?.forEach(f => {
            f.filterSets?.forEach(ff => {
              ff.allowed = this.config.availableFilterIds.includes(ff.id);
            });
          });
        return items;
      } ),
      takeUntil(this.unsub$)
    )
    .subscribe((filterSets: any) => {
      /* TODO: Currently on list page all filters have isAllowed=true.
          In future if accessGroupChanged is considered on list page, update this condition accordingly.
      */
      if (this.config.forSelection) {
        filterSets = filterSets.filter(this.atLeastOneAllowed);
      }
      this.grid.api.updateGridOptions({ rowData: filterSets });
    });
  }

  private resizeColumn() {
    this.grid.api.sizeColumnsToFit();
  }

  private atLeastOneAllowed(f: any): boolean {
    let atLeastOneAllowed = false;
    f.filterSets?.forEach(ff => {
      if (ff.allowed) atLeastOneAllowed = true;
    });
    return atLeastOneAllowed;
  }
}

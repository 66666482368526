import { SiqFilter } from 'app/filter/models/interfaces';
import { PromoFormData } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { DateRangeParameter } from '@siq-js/core-lib';
import * as _ from 'lodash';
import {AnalysisType} from 'app/core/models/analysis-type.enum';

export class SovDrilldownParameters {

  public globalFilters: SiqFilter[]; // holds location filters info from step 5 and CG product filter info from step 6.
  public detailFilters: SiqFilter[];
  public promoDateRange: DateRangeParameter;
  public prePromoDateRange: DateRangeParameter;
  public yoyDateRange: DateRangeParameter;
  public customPromoDateRange: DateRangeParameter;
  public retailers: string[];
  public analysisType: AnalysisType;

  constructor(detailFilters: SiqFilter[], formData: PromoFormData) {
    this.detailFilters = detailFilters;
    this.retailers = [formData.schema];
    const formParams = formData.toParams();
    this.globalFilters = _.cloneDeep(formParams.locationFilters);
    this.promoDateRange = _.cloneDeep(formParams.promoDateRange);
    this.prePromoDateRange = _.cloneDeep(formParams.prePromoDateRange);
    this.yoyDateRange = _.cloneDeep(formParams.yoyDateRange);
    if (!!formData.customPromoDateRange.periodStart)
    this.customPromoDateRange = _.cloneDeep(formParams.customPromoDateRange);
    this.analysisType = formData.getAnalysisType();
  }
}

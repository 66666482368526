import * as _ from 'lodash';
import { ActivityInterface, ApplicationHash } from '@siq-js/core-lib';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import { BaseModel } from 'app/core/models/base-model';
import { SiqError } from 'app/core/models/siq-error.model';
import { StatResponse } from 'app/core/models/stat-response.model';
import { User } from 'app/user/models/user.model';
import { StatResponseErrors } from 'app/core/models/stat-response-errors.enum';
import { SharedReport } from 'app/activity/models/interfaces';
import { AuthService } from 'app/auth/services/auth.service';
import { CmsApplication } from '@siq-js/cms-lib';

export enum ActivityStatus {
  READY = 'ready',
  EMPTY = 'empty',
  EXPIRED = 'expired',
  RUNNING = 'running',
  ERROR = 'error',
  PREVIEW_RUNNING = 'prev_running',
  PREVIEW_READY = 'prev_ready',
  ALERT = 'alert'
}

export class Activity extends BaseModel implements ActivityInterface {
  public static readonly ActivityPlaceholder = 'Untitled Report';

  public accessGroupChanged: boolean;
  public appId: ApplicationHash;
  public application: CmsApplication;
  public cached: boolean;
  public complete: boolean;
  public createdDate: string;
  public errors: boolean;
  public errorsDetails: SiqError[];
  public formValues: any;
  public id: string;
  public jobCreationDate: string;
  public jobs: AppResponseDataset[];
  public metaData: Map<string, string>;
  public path: string;
  public sharedReport?: SharedReport;
  public sheets?: Activity[];
  public siteUser: User;

  constructor(activity?: Activity) {
    super();

    if (activity) {
      Object.keys(activity).map(k => {
        this[k] = activity[k];
        if (k === 'appId') {
          this.application = ApplicationService.find(this.appId);
        }
      });
    } else {
      this.id = null;
      this.appId = null;
      this.path = null;
      this.complete = false;
      this.errors = false;
      this.cached = false;
      this.formValues = {filters: []};
      this.jobs = [];
      this.jobCreationDate = null;
      this.siteUser = new User();
      this.createdDate = null;
      this.metaData = new Map<string, string>();
      this.errorsDetails = [];
      this.accessGroupChanged = false;
    }
  }

  diff(activity: Activity): boolean {
    if (this.getName() !== activity.getName()) return true;
    if (this.getId() !== activity.getId()) return true;
    if (this.getStatus() !== activity.getStatus()) return true;
    if (this.isFavorite() !== activity.isFavorite()) return true;
    if (this.isTrashed() !== activity.isTrashed()) return true;

    return false;
  }

  getAppId(): ApplicationHash {
    return this.appId;
  }

  getApplication(): CmsApplication {
    return this.application;
  }

  getApplicationName(): string {
    return this.getApplication()?.display;
  }

  getCreatedBy(): string {
    return _.get(this.getSiteUser(), 'firstName') + ' ' + _.get(this.getSiteUser(), 'lastName');
  }

  getCreationDate(): Date {
    return new Date(Number(this.jobCreationDate));
  }

  getErrorsDetails(): SiqError[] {
    return this.errorsDetails;
  }

  getFormValues(): any {

    let formClone = _.cloneDeep(this.formValues);

    if (_.isString(formClone)) {
      formClone = JSON.parse(formClone);
    }

    if (!formClone) {
      console.warn('Empty formData in Activity %O, skipping...', this.id);
      return;
    }

    if (_.get(formClone, 'filters')) {

      formClone.filters = _.reduce(formClone.filters, (p, c, k) => {
        p.push({
          name: k,
          ...c
        });
        return p;
      }, []);

    }
    if (formClone.minDate && formClone.maxDate) {

      formClone.filters.push({
        name: 'DATE',
        in: [formClone.minDate],
        out: [formClone.maxDate]
      });

      delete formClone.minDate;
      delete formClone.maxDate;

    }

    formClone.metaData = _.cloneDeep(this.metaData);

    return formClone;

  }

  getId(getSharedId = false): string {
    if (getSharedId) {
      return this.isSharedOrScheduled() ? this.sharedReport.id : this.id;
    }
    return this.id;
  }

  // Allows for lookup of individual jobs by index or job name
  getJob(lookup: number | string = 0): AppResponseDataset {
    if (typeof lookup === 'string') {
      return this.jobs.find(j => j.getName() === lookup);
    } else {
      return this.jobs[lookup];
    }
  }

  getJobs(): AppResponseDataset[] {
    return this.jobs;
  }

  getMetaData(): Map<string, string> {
    return this.metaData;
  }

  getMetaDataByKey(key: string): string {
    return this.metaData[key] || '';
  }

  getName(): string {
    // the metaData['name'] should always override the default or placeholder
    const tempName = this.getMetaDataByKey('name')  || Activity.ActivityPlaceholder;
    return tempName !== this.formValues.name ? tempName : this.formValues.name;
  }

  getPath(): string {
    if (!this.getApplication()) return '';

    return `${this.getApplication().path}/${this.getId()}`;
  }

  // Utility function - prevents bloated calls like `activity.getJobs()[i].getResponse() as StatResponse`
  getResponse<T = StatResponse>(lookup: number | string = 0): T {
    if (this.getJobs().length) {
      return this.getJob(lookup).getResponse<T>();
    }
    return null;
  }

  getSiteUser(): User {
    return this.siteUser;
  }

  getStatus(): ActivityStatus {
    if (this.accessGroupChanged) {
      return ActivityStatus.ALERT;
    }

    if (this.hasErrors()) {
      // Error statuses
      switch (this.getErrorsDetails()[0].getErrorCode()) {
        case StatResponseErrors.RB_RESPONSE_SIZE_EXCEEDED['errorCode']:
          if (this.appId === ApplicationHash.REPORT_BUILDER || this.appId === ApplicationHash.REPORT_BUILDER_SHEET) {
            return this.isComplete() ? ActivityStatus.PREVIEW_READY : ActivityStatus.PREVIEW_RUNNING;
          } else {
            return ActivityStatus.ERROR; // ICE-2831: Show error for all apps except RB during E14.
          }
        case StatResponseErrors.BIG_QUERY_RESOURCE_EXCEEDED['errorCode']:
        case StatResponseErrors.GENERAL_APP_ACTIVITY_ERROR['errorCode']:
        default:
          return ActivityStatus.ERROR;
      }
    }

    if (this.isComplete()) {
      if (this.isCached()) {
        return ActivityStatus.READY;
      } else {
        return ActivityStatus.EXPIRED;
      }
    } else {
      return ActivityStatus.RUNNING;
    }
  }

  hasErrors(): boolean {
    return !_.isEmpty(this.errorsDetails);
  }

  hasResults(): boolean {
    return this.complete && !this.hasErrors() && this.cached;
  }

  isCached(): boolean {
    return this.cached;
  }

  isComplete(): boolean {
    return this.complete;
  }

  isFavorite(): boolean {
    return this.getMetaDataByKey('favorite') === 'true';
  }

  isMine(): boolean {
    const user = AuthService.CurrentUser$.getValue();
    return user?.email === this.getSiteUser().email;
  }

  isSameAccessGroup(): boolean {
    const user = AuthService.CurrentUser$.getValue();
    return user.accessGroup === this.getSiteUser().accessGroup;
  }

  isScheduled(): boolean {
    return !!this.sharedReport && this.sharedReport.scheduled;
  }

  isShared(): boolean {
    return !!this.sharedReport && !this.sharedReport.scheduled;
  }

  isSharedOrScheduled(): boolean {
    return !!this.sharedReport;
  }

  isTrashed(): boolean {
    return this.getMetaDataByKey('trashed') === 'true';
  }

  isViewed(): boolean {
    if (this.isSharedOrScheduled()) {
      return this.sharedReport.viewed;
    } else {
      return this.getMetaDataByKey('read') === 'true';
    }
  }

  needsRefresh(): boolean {
    return this.isComplete() && !this.hasErrors() && !this.cached;
  }

  setMetaDataByKey(key: string, value: string): void {
    this.metaData[key] = value;
  }
}

import { Component, OnInit } from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'siq-js-comparison-dates-holder',
  templateUrl: './comparison-dates-holder.component.html',
  styleUrls: ['./comparison-dates-holder.component.scss']
})
export class ComparisonDatesHolderComponent implements OnInit {

  public showCDs = false;

  constructor() { }

  ngOnInit(): void {
  }

  onToggle(e: MatSlideToggleChange) {
    this.showCDs = e.checked;
  }

}

<ng-container *ngIf="col">

  <div class="drag-view">
    {{ col.ref.display }}
  </div>

  <!-- Menu for dims/metrics -->
  <mat-menu #options="matMenu">
    <ng-container *ngIf="col.type === columnTypeEnums.DIMENSION">
      <button mat-menu-item (click)="parent.globalFilter.addFilter(col.ref)"
              *ngIf="col.ref.filter && col.ref.filter !== 'DATE'">Filters</button>
    </ng-container>

    <!--Metric column specific options -->
    <ng-container *ngIf="col.type === columnTypeEnums.METRIC">
      <button mat-menu-item (click)="openYearOverYearModal(col)">Year Over Year</button>
      <button mat-menu-item [matMenuTriggerFor]="timeAggregatesMenu">Time Breakdown</button>
      <button mat-menu-item (click)="openColumnFilterModal(col)">Column Filters</button>
      <button mat-menu-item (click)="cloneMetric(col)">Clone</button>
    </ng-container>
    <button mat-menu-item (click)="removeColumn(col)">Remove</button>
  </mat-menu>

  <mat-menu #timeAggregatesMenu="matMenu">
    <button *ngFor="let opt of timeAggregatesOptions" mat-menu-item (click)="col.applyTimeAggregate(opt)"> {{ opt.name }}</button>
  </mat-menu>

  <div class="column-group-summary">

    <ng-container *ngIf="col.type === columnTypeEnums.METRIC">

      <div class="summary-yoy" *ngIf="col.yearOverYear">
        <span class="section-label" [ngClass]="{'yoy-locked': parent.model.yoyLocked}">
          <mat-icon *ngIf="parent.model.yoyLocked"
                    matTooltip="The comparative date range has been locked to a year before the global date range, because of certain dimensions present in this report"
          >info</mat-icon>
          Comparative Date Range:
        </span>
        <span class="yoy-date-range" (click)="openYearOverYearModal(col)">
          {{ col.yearOverYear.compDateRange.begin | date: 'MM/dd/yyyy' }} to
          {{ col.yearOverYear.compDateRange.end | date: 'MM/dd/yyyy' }}
        </span>
      </div>

      <siq-report-builder-form-column-filter
        [initModel]="col.filters"
        [schemaController]="parent.schemaController$"
        (emitter)="col.filters = $event"
      ></siq-report-builder-form-column-filter>
    </ng-container>

  </div>

  <div class="column-group-header">
    <input class="col-group-name" type="text" placeholder="Column Group Name" [(ngModel)]="col.columnGroupName"/>
  </div>

  <div class="column-group-options">
    <button mat-raised-button [matMenuTriggerFor]="options">Options</button>
  </div>

  <div class="column-headers">

  </div>

  <ng-container *ngIf="col.type === columnTypeEnums.DIMENSION">
    <!-- Dimension Column -->
    <div class="column">
      <div class="column-header">
        <span>{{ col.ref.display}}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="col.type === columnTypeEnums.METRIC">

    <ng-container *ngIf="!col.timeAggregates && !col.yearOverYear">
      <!-- Normal Metric column -->
      <div class="column">
        <div class="column-header">
          <span>{{ col.ref.display }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="col.yearOverYear">
      <!-- Year over year columns -->
      <div class="column" *ngIf="col?.yearOverYear?.ty">
        <div class="column-header">
          <span>{{ col.ref.display }} TY</span>
        </div>
      </div>
      <div class="column" *ngIf="col?.yearOverYear?.ly">
        <div class="column-header">
          <span>{{ col.ref.display }} LY</span>
        </div>
      </div>
      <div class="column" *ngIf="col?.yearOverYear?.lyd">
        <div class="column-header">
          <span>{{ col.ref.display }} TY vs LY</span>
        </div>
      </div>
      <div class="column" *ngIf="col?.yearOverYear?.lypd">
        <div class="column-header">
          <span>{{ col.ref.display }} % &Delta; TY vs LY</span>
        </div>
      </div>
    </ng-container>

    <!-- Time aggregate columns -->
    <ng-container *ngIf="col.timeAggregates">
      <div class="column">
        <div class="column-header">
          <span>{{ col.timeAggregates.aggValue.unit }} 1</span>
        </div>
      </div>
      <div class="column">
        <div class="column-header">
                  <span>
                    {{ col.timeAggregates.numUnits }} Column{{(col.timeAggregates.numUnits === 1)? '' : 's'}}
                  </span>
        </div>
      </div>
      <div class="column">
        <div class="column-header">
          <span>{{ col.timeAggregates.aggValue.unit }} {{ col.timeAggregates.numUnits }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

import { AfterViewInit, Component, forwardRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BaseVisualizationComponent } from 'libs/visual-lib/src/lib/components/base-visualization/base-visualization.component';
import {
  AgCartesianChartOptions,
  AgChartOptions,
  AgChartsAngular,
  AgLineSeriesOptions,
  AgScatterSeriesOptions,
  ChartsService,
  ChartThemes,
  _ModuleSupport
} from 'libs/visual-lib/src';
import * as _ from 'lodash';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';
import { isLessThan } from '@siq-js/core-lib';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { CmsMetric } from '@siq-js/cms-lib';
import {
  BarColumnParams,
  BaseChart,
  ScatterWithLineApi,
  ScatterWithLineChartSettings,
  ScatterWithLineConfig,
  ScatterWithLineParams
} from 'libs/visual-lib/src/lib/modules/charts-graphs/models';
import { VISUAL_CONFIG } from 'libs/visual-lib/src/lib/models';

interface ChartRange {
  lower: number;
  upper: number;
}

@Component({
  selector: 'siq-js-scatter-with-line',
  templateUrl: './scatter-with-line.component.html',
  styleUrls: ['./scatter-with-line.component.scss']
})
export class ScatterWithLineComponent extends BaseVisualizationComponent<any, any, ScatterWithLineConfig, ScatterWithLineApi> implements BaseChart, OnInit, OnDestroy, AfterViewInit {

  public static generateDefaultChartOptions(component: ScatterWithLineComponent): AgChartOptions {
    const typeSafe:AgCartesianChartOptions = {
      autoSize: true,
      theme: ChartThemes.DEFAULT,
      // data: [], //  The data will be set in each of the .series objects
      title: {
        enabled: true,
        text: '',
        fontSize: 18,
      },
      subtitle: {text: '',enabled:true},
      // type: '', //  The type will be set in each of the .series objects
      series: [],//there could be pushed data later to empty initialized series array
      axes: [
        {
          position: 'bottom',
          type: 'number',
          title: {
            enabled: true,
            text: ''
          },
          label: {
            rotation:-71,
            avoidCollisions: true
          } 
        },
        {
          position: 'left',
          type: 'number',
          title: {
            enabled: true,
            text: ''
          },
          label: {} // blank here, updated later
        },
      ],
      legend: {enabled: false}
    };
    return <AgChartOptions>typeSafe;
  }

  private static determineMedian(data: any[], key: string): any {
    if (!data.length) return 0;
    if (data.length % 2 === 0) {
      // even
      return (data[data.length/2][key] + data[(data.length / 2) - 1][key]) / 2;
    } else {
      // odd
      return data[(data.length - 1) / 2][key];
    }
  }

  private static determineRange(data: any[], key: string): ChartRange {
    if (!data.length) {
      return {
        lower: 0,
        upper: 0
      };
    }
    return {
      lower: data[0][key],
      upper: data[data.length - 1][key]
    };
  }

  @ViewChildren(forwardRef(() => AgChartsAngular)) public angularCharts: QueryList<AgChartsAngular>;
  public agChartOptions: AgChartOptions;
  public angularChart: AgChartsAngular;
  public chart; // Type determined at runtime
  public chartReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private newToggleTooltip: any;
  private originalToggleTooltip: any;

  @HostListener('click', ['$event'])
  onLegendClick(event: MouseEvent) {
    // https://pdisoftware.atlassian.net/browse/ICE-3344
    // median lines have to be recalculated when user clicks on legend item, because domain of axes could change
    // There is no event for clicking on legend item in current ag-grid version
    // (should be used instead of this HostListener after update to new version)
    this.updateMedianLines();
  }

  constructor() {
    super();
  }

  getApi(): ScatterWithLineApi {
    return {
      component: this,
      chartOptions: this.agChartOptions
    };
  }

  highlightDatum(seriesNodeDatum: _ModuleSupport.SeriesNodeDatum) {
    let matchDatum;

    const matchSeries = <AgScatterSeriesOptions>_.find(this.chart.series, {type: 'scatter'});

    if (matchSeries) {
      matchDatum = _.find(matchSeries['_contextNodeData'][0].nodeData, seriesNodeDatum);
    }

    if (matchDatum) {
      this.chart.highlightManager.updateHighlight(undefined, matchDatum);
    }
  }

  ngAfterViewInit(): void {
    // Get a reference to the actual chart
    this.angularChart = this.angularCharts.first;
    this.chart = this.angularChart.chart['chart']; // NOTE: "short cut / pointer" for shorter code elsewhere in this file

    this.updateMedianLines();
    //TODO: Ag Charts upgrade -> Investigate if charts works without it
    // this.setToggleTooltipFn();

    this.chartReady$.next(true);
    
    (<ScatterWithLineParams>this.data).chart = this; // set pointer to this chart instance to be used by processor/other classes

    (<ScatterWithLineParams>this.data).selectedMetricX$.pipe(
      takeUntil(this.unsub$),
      filter(selected => selected !== null)
    )
    .subscribe(selectedMetricX => {
      this.updateMetricX(selectedMetricX);
    });

    (<ScatterWithLineParams>this.data).selectedMetricY$.pipe(
      takeUntil(this.unsub$),
      filter(selected => selected !== null)
    )
    .subscribe(selectedMetricY => {
      this.updateMetricY(selectedMetricY);
    });

    (<ScatterWithLineParams>this.data).datasetChanged$.pipe(
      takeUntil(this.unsub$)
    )
    .subscribe(() => {
      const selectedMetricX = (<ScatterWithLineParams>this.data).selectedMetricX$.getValue();
      if (selectedMetricX) {
        this.updateMetricX(selectedMetricX); //recalculates median (for example after filter changed)
      }
    });

    (<ScatterWithLineParams>this.data).highlightDatum$.pipe(
      takeUntil(this.unsub$)
    )
    .subscribe(datum => {
      this.chart.highlightManager.updateHighlight(undefined);
      if (!datum) {
        return;
      }

      //transforms object's internal structure from key: {'val': value} to key: value
      Object.keys(datum).forEach((key) => { if (datum[key]?.val) datum[key] = datum[key].val; });
      const highlightedDatum = _.find(<_ModuleSupport.SeriesNodeDatum>this.chart.series[0]['_contextNodeData'][0].nodeData, {datum: datum});

      if (highlightedDatum) {
        this.highlightDatum(highlightedDatum);
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      const updatedChartOptions = <AgChartOptions>{...this.agChartOptions};
      updatedChartOptions.theme = ChartsService.getChartThemeObject(theme);
      updatedChartOptions.background = ChartsService.getChartBackgroundForTheme();
      this.agChartOptions = updatedChartOptions;

      // This triggers all functionality needed to keep the highlight working without impacting the data being displayed
      const metric = (<ScatterWithLineParams>this.data).selectedMetricY$.value;
      (<ScatterWithLineParams>this.data).selectedMetricY$.next(metric);
    });
  }

  ngOnDestroy():void {
    super.ngOnDestroy();
  }

  protected render(chartSettings: ScatterWithLineChartSettings): void {
    this.agChartOptions = _.merge(ScatterWithLineComponent.generateDefaultChartOptions(this), chartSettings.agChartOptions);
  }

  private setToggleTooltipFn(): void {
    /**
     * A little hacky, but...
     * Ag-Grid exposes a tooltipRenderer fn that gets triggered on mouseover of chart shape.
     * However there is nothing exposed in similar manner for when the tooltip is removed/mouseout.
     * This grabs the original "toggleTooltip" fn (in the ag-grid "Chart" object/class), calls it from
     * a new fn with additional logic, and attaches this new fn in place of the original.
     */
    const _data = (<BarColumnParams>this.data);
    this.originalToggleTooltip = this.chart.tooltip.toggle;

    ; // "toggleTooltip" is private to Chart so cannot use dot-accessor
    this.newToggleTooltip = (visible: boolean) => {
      this.originalToggleTooltip.apply(this.chart.tooltip, [visible]);
      if (!visible) {
        _data.highlightDatum$.next(null); // "toggleTooltip" is private to Chart so cannot use dot-accessor
      }
    };
    this.chart.tooltip.toggle = this.newToggleTooltip;
  }

  private updateMedianLines() {
    const newChartOptions = _.cloneDeep(this.agChartOptions);
    const xLineSeries: AgLineSeriesOptions = <AgLineSeriesOptions>_.find(newChartOptions.series, {type: 'line', marker: {shape: 'circle'}});
    const yLineSeries: AgLineSeriesOptions = <AgLineSeriesOptions>_.find(newChartOptions.series, {type: 'line', marker: {shape: 'triangle'}});
    const xKey = xLineSeries.xKey;
    const yKey = yLineSeries.yKey;

    /**
     * Inelegant behavior, but when we update the data and then update the chart, as the chart updates itself, it then
     * determines the new range of the axes (low, high). So only after the chart updates can we read this newly updated
     * range data and then reset the range of our median lines.
     */
    setTimeout(() => {
      this.chart.axes.forEach((axis) => {
        //domain is NaN when all series are hidden by user (chart is empty)
        if (axis.scale.niceDomain.length > 1 && !_.isNaN(axis.scale.niceDomain[0]) && !_.isNaN(axis.scale.niceDomain[1])) {
          if (axis.direction === 'x' && xLineSeries.data.length === 2) {
            xLineSeries.data[0][xKey] = axis.scale.niceDomain[0];
            xLineSeries.data[1][xKey] = axis.scale.niceDomain[1];
          }
          if (axis.direction === 'y' && yLineSeries.data.length === 2) {
            yLineSeries.data[0][yKey] = axis.scale.niceDomain[0];
            yLineSeries.data[1][yKey] = axis.scale.niceDomain[1];
          }
        }
      });
      this.agChartOptions = newChartOptions;
    }, 600);
  }

  private updateMetric(newOriginalChartOptions: AgChartOptions) {
    const newChartOptions = newOriginalChartOptions as AgCartesianChartOptions;
    const scatterSeries: AgScatterSeriesOptions = <AgScatterSeriesOptions>_.find(newChartOptions.series, {type: 'scatter'});
    const xLineSeries: AgLineSeriesOptions = <AgLineSeriesOptions>_.find(newChartOptions.series, {type: 'line', marker: {shape: 'circle'}});
    const yLineSeries: AgLineSeriesOptions = <AgLineSeriesOptions>_.find(newChartOptions.series, {type: 'line', marker: {shape: 'triangle'}});

    scatterSeries.yName = (<ScatterWithLineParams>this.data).selectedMetricY$.value.display;

    // Now update the Line series
    const xKey = xLineSeries.xKey;
    const yKey = xLineSeries.yKey;
    const dataSortedX = scatterSeries.data.slice().sort((a: any, b: any) => isLessThan(a[xKey], b[xKey]));
    const dataSortedY = scatterSeries.data.slice().sort((a: any, b: any) => isLessThan(a[yKey], b[yKey]));

    // Tooltip Renderer fns look to ScatterWithLineParams.metaData.MEDIAN_(X|Y)
    (<ScatterWithLineParams>this.data).metaData.MEDIAN_X = ScatterWithLineComponent.determineMedian(dataSortedX, xKey);
    (<ScatterWithLineParams>this.data).metaData.MEDIAN_Y = ScatterWithLineComponent.determineMedian(dataSortedY, yKey);

    const formatterX = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: (<ScatterWithLineParams>this.data).selectedMetricX$.value.type})?.formatter;
    const formatterY = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: (<ScatterWithLineParams>this.data).selectedMetricY$.value.type})?.formatter;
    const medianValX = (<ScatterWithLineParams>this.data).metaData.MEDIAN_X ? (<ScatterWithLineParams>this.data).metaData.MEDIAN_X : '';
    const medianValY = (<ScatterWithLineParams>this.data).metaData.MEDIAN_Y ? (<ScatterWithLineParams>this.data).metaData.MEDIAN_Y : '';

    xLineSeries.yName = 'Median ' + xLineSeries.yName + ': ' + (formatterY ? formatterY({value: medianValY}) : medianValY);
    yLineSeries.yName = 'Median ' + yLineSeries.xName + ': ' + (formatterX ? formatterX({value: medianValX}) : medianValX);

    const xRange: ChartRange = ScatterWithLineComponent.determineRange(dataSortedX, xLineSeries.xKey);
    const yRange: ChartRange = ScatterWithLineComponent.determineRange(dataSortedY, yLineSeries.yKey);

    const xLower = {
      [yKey]: (<ScatterWithLineParams>this.data).metaData.MEDIAN_Y,
      [xKey]: xRange.lower
    };
    const xUpper = {
      [yKey]: (<ScatterWithLineParams>this.data).metaData.MEDIAN_Y,
      [xKey]: xRange.upper
    };
    xLineSeries.data = [
      xLower,
      xUpper
    ];

    const yLower = {
      [yKey]: yRange.lower,
      [xKey]: (<ScatterWithLineParams>this.data).metaData.MEDIAN_X
    };
    const yUpper = {
      [yKey]: yRange.upper,
      [xKey]: (<ScatterWithLineParams>this.data).metaData.MEDIAN_X
    };
    yLineSeries.data = [
      yLower,
      yUpper
    ];

    newChartOptions.axes.forEach(axis => {
      axis.label.formatter = (axis.position === 'bottom') ? formatterX : formatterY;
      axis.title.text = (axis.position === 'bottom') ? this.data.selectedMetricX$.value.display : this.data.selectedMetricY$.value.display;
    });

    this.agChartOptions = newChartOptions;

    this.updateMedianLines();
  }

  private updateMetricX(selectedMetricX: CmsMetric) {
    const newChartOptions = _.cloneDeep(this.agChartOptions);

    newChartOptions.series.forEach(series => {
      series.xKey = selectedMetricX.id;
      series.xName = selectedMetricX.display;

      // Reset this due to decoration that takes place later.
      series.yName = (<ScatterWithLineParams>this.data).selectedMetricY$.value.display;
    });
    this.updateMetric(newChartOptions);

  }

  private updateMetricY(selectedMetricY: CmsMetric) {
    const newChartOptions = _.cloneDeep(this.agChartOptions);

    newChartOptions.series.forEach(series => {
      series.yKey = selectedMetricY.id;
      series.yName = selectedMetricY.display;
    });
    this.updateMetric(newChartOptions);
  }

}

import { Component, Injector, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivityTableComponent } from 'app/activity/components/activity-table/activity-table.component';
import { takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { ColumnState, IHeaderAngularComp, IHeaderParams } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-activity-column-header-renderer',
  templateUrl: './activity-column-header-renderer.component.html',
  styleUrls: ['./activity-column-header-renderer.component.scss']
})
export class ActivityColumnHeaderRendererComponent implements IHeaderAngularComp, OnDestroy {

  public activeSort: ColumnState['sort'];
  public canSort: boolean;
  public header: string;

  private id: string;
  private parent: ActivityTableComponent;
  private params: IHeaderParams;
  private _unsub$: Subject<void> = new Subject<void>();

  constructor(injector: Injector) {
    this.parent = injector.get(ActivityTableComponent);
  }

  ngOnDestroy() {
    this._unsub$.next();
    this._unsub$.complete();
  }

  agInit(params: IHeaderParams): void {
    this.header = params.displayName;
    this.canSort = params.column.getColDef().sortable;
    this.id = params.column.getColId();
    this.params = params;

    this.parent.sort$
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        const sortColumnStates = params.api.getColumnState();
        let ifExists = _.find(sortColumnStates, e => e.colId === this.id);

        try{
          if (ifExists) {
            this.activeSort = ifExists['sort'];
          } else {
            this.activeSort = null;
          }
        } catch {
          this.activeSort = null;
        }
      });
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  public toggleSort() {
    if (!this.canSort) return;

    let newSort;

    switch (this.activeSort) {
      case null:
        newSort = 'desc';
        break;
      case 'desc':
        newSort = 'asc';
        break;
      case 'asc':
        newSort = null;
        break;
    }

    this.params.setSort(newSort, false);
  }

}

import { AfterViewInit, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharingModalTabComponent } from 'app/activity/modules/sharing/components/sharing-modal-tab/sharing-modal-tab.component';
import { ValidationStatus } from 'app/core/models/validation-status.enum';
import { UntypedFormControl } from '@angular/forms';
import { ApplicationHash } from '@siq-js/core-lib';
import { NotificationService } from '@siq-js/angular-buildable-lib';
import { takeUntil } from 'rxjs';
import { SharingModalData } from 'app/activity/modules/sharing/services/sharing.service';

@Component({
  selector: 'siq-sharing-modal',
  templateUrl: './sharing-modal.component.html',
  styleUrls: ['./sharing-modal.component.scss']
})
export class SharingModalComponent implements AfterViewInit {
  public readonly limitedLengthTextConfig = {
    maxLength: 1000,
    rows: 6
  };

  public selected = new UntypedFormControl(0);
  public valid = false;
  public isNotPromo = false;
  public submitButtonText = 'share';

  @ViewChildren(SharingModalTabComponent) public tabs: QueryList<SharingModalTabComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: SharingModalData,
    private dialogRef: MatDialogRef<SharingModalComponent>,
    private notificationService: NotificationService
  ) {
    // TODO: remove isNotPromo flag when promo supports scheduling and cloud export
    this.isNotPromo = this.modalData.activity.getAppId() !== ApplicationHash.PROMO;
  }

  public ngAfterViewInit () {
    const subToValidityUpdates = () => {
      this.tabs.toArray()[this.selected.value].form.statusChanges
        .pipe(takeUntil(this.tabs.changes))
        .subscribe(s => {
          return this.valid = s === ValidationStatus.VALID.toString();
        });
    };

    this.selected.valueChanges.subscribe(() => {
      this.submitButtonText = this.selected.value ? 'schedule' : 'share';
      this.valid = this.tabs.toArray()[this.selected.value].form.valid;
      return subToValidityUpdates();
    });

    subToValidityUpdates();

  }

  public submit(): void {
    const title = this.modalData.activity.getName();
    this.notificationService
      .info(`Your report has been prompted to ${this.submitButtonText}`, title);

    let [updateReportDtoId, scheduled] = [undefined, false];
    if (this.modalData.share && !this.selected.value) {
      updateReportDtoId = this.modalData.share.sharedReport.id;
    } else if (this.modalData.schedule && this.selected.value) {
      updateReportDtoId = this.modalData.schedule.sharedReport.id;
      scheduled = true;
    }

    this.dialogRef.close(
      {
        callback: this.tabs.toArray()[this.selected.value].submit(updateReportDtoId, scheduled),
        sharedType: this.submitButtonText,
        title: title,
        recipients: this.tabs.toArray()[this.selected.value].form.controls.recipients.value
      }
    );
  }
}

import { NotificationType } from '../../../index'; // Must be relative path!

export class ResponseCode {
  private code: number;
  private data: any;
  private message: string;
  private title: string;
  private type: NotificationType;

  constructor(code?: number, message?: string, title?: string, type?: NotificationType, data?: any) {
    this.code = <number>code;
    this.data = data;
    this.message = <string>message;
    this.title = <string>title;
    this.type = <NotificationType>type;
  }

  public getCode(): number {
    return this.code;
  }

  public setCode(code: number): void {
    this.code = code;
  }

  public getData(): any {
    return this.data;
  }

  public setData(data: any): void {
    this.data = data;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): void {
    this.message = message;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public getType(): NotificationType {
    return this.type;
  }

  public setType(type: NotificationType): void {
    this.type = type;
  }
}

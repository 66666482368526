<div class="promo-helper-container" *ngIf="helperParams">
  <ng-container>
    <mat-card class="promo-section">
      <siq-js-bar-column
        *ngIf="columnParams && columnConfig "
        [config]="columnConfig"
        [data]="columnParams"
        [processor]="columnProcessor"
      ></siq-js-bar-column>
    </mat-card>
    <section class="promo-section">
      <div class="promo-panel">
        <div class="criteria-wrapper">
          <mat-form-field color="accent">
            <mat-select [(value)]="basketSize" (valueChange)="updateView()">
              <mat-option value="TOTAL">All</mat-option>
              <mat-option *ngFor="let size of basketSizes" [value]="size">
                {{ size }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field color="accent">
            <mat-select [(value)]="unitType" (valueChange)="updateUnitType()">
              <mat-option [value]="unitTypes.ABSOLUTE">
                Absolute
              </mat-option>
              <!-- Decision made to temporarily disable PSPW under "All" -->
              <!-- https://pdisoftware.atlassian.net/browse/ICE-1696?focusedCommentId=275195 -->
              <mat-option [value]="unitTypes.PSPW" *ngIf="basketSize !== 'TOTAL'">
                Per Store Per Week
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field color="accent">
            <mat-select [(value)]="factKey" (valueChange)="updateView()">
              <mat-option *ngFor="let fact of facts" [value]="fact">
                {{ fact.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="toggle no-select">
          <label>{{preCustomName}}</label>
          <mat-slide-toggle (change)="togglePeriod($event.checked)" color="accent"></mat-slide-toggle>
          <label>{{yoyCustomName}}</label>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="promo-helper-table">
        <siq-js-grid
          *ngIf="ready$.value && helperParams"
          [config]="gridParams.gridVisualOptions.gridConfig"
          [data]="gridParams"
          [processor]="helperProcessor"
          (featureUsed)="gridFeatureUsed($event)"
        ></siq-js-grid>
      </div>
    </section>
  </ng-container>
</div>




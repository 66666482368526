import { Component, Input, OnInit } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatSelectionListChange } from '@angular/material/list';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { DatePickerAction } from '@siq-js/core-lib';
import { DateShortcutsConfig, DynamicDateShortcut } from 'app/siq-forms/modules/dates/models/interfaces';
import { BehaviorSubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-js-date-shortcuts',
  templateUrl: './date-shortcuts.component.html',
  styleUrls: ['./date-shortcuts.component.scss']
})
export class DateShortcutsComponent extends BaseSiqComponent implements OnInit {
  @Input() config: DateShortcutsConfig;
  public changed = false; // determine whether to disable the apply & cancel buttons
  public datePickerAction = DatePickerAction;
  public previewClick$ = new BehaviorSubject<DynamicDateShortcut>(null);
  public shortcuts: DynamicDateShortcut[];

  constructor(private _calendar: MatCalendar<Date>) {
    super();
  }

  ngOnInit(): void {
    this.shortcuts = this.config.parent.shortcuts;
    this.previewClick$
      .pipe(
        skip(1),
        takeUntil(this.unsub$),
      )
      .subscribe(sc => {
        const parent = this.config.parent;
        if (parent.selectedShortcut?.begin?.getTime() === sc.begin.getTime() &&
            parent.selectedShortcut?.end?.getTime() === sc.end.getTime() &&
            parent.selectedShortcut?.name === sc.name
        ) {
          this.changed = false;
        } else {
          this.changed = true;
          parent.previewShortcut = sc;
        }
        // set shortcut preview on the calendar
        parent.isPreviewMode = true;
        parent.isPreviewTheme = true;
        parent.preViewStart = sc.begin;
        parent.preViewEnd = sc.end;
        this._calendar.activeDate = sc.end; // set the view of the calendar
      });
  }

  onClickAction(action: DatePickerAction) {
    this.config.datePickerAction$.next(action);
  }

  setPreview(e: MatSelectionListChange) {
    const sc: DynamicDateShortcut = e.options[0].value;
    this.previewClick$.next(sc);
  }

}

<p class="frequency-title">Frequency</p>
<div class="radios-block" style="margin-top: -20px;">
  <p class="radios-block-first">
    Repeat every
    <mat-form-field class="form-field-number">
      <input matInput type="number"
             [(ngModel)]="interval"
             (ngModelChange)="updateForm()"
             [ngClass]="{'positiveNumberFromError': formGroupRecurrence.controls.interval.hasError('positiveNumberInterval')}"
      >
    </mat-form-field>
    &nbsp;
    <mat-form-field class="form-field-frequency" color="accent">
      <mat-select [(value)]="frequencyMode" (valueChange)="updateFrequencyMode()">
        <mat-option *ngFor="let frequency of frequencyList" [value]="frequency">
          {{ frequency | pluralize : interval }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>
  <siq-frequency-week
    [frqPosted]="frqPosted"
    [formControlByWeek]="formGroupRecurrence.controls.byDay"
    *ngIf="frequencyMode===frequencyList[1]"
  ></siq-frequency-week>
  <siq-frequency-month
    [frqPosted]="frqPosted"
    [formControlByDay]="formGroupRecurrence.controls.byDay"
    [formControlByMonthDay]="formGroupRecurrence.controls.byMonthDay"
    *ngIf="frequencyMode===frequencyList[2]"
  ></siq-frequency-month>
</div>

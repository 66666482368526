import { Routes } from '@angular/router';
import { promoRoutes } from 'app/siq-applications/modules/promo/routes/promo-routes';
import { reportBuilderRoutes } from 'app/siq-applications/modules/report-builder/routes/report-builder-routes';
import { affinitiesRoutes } from 'app/siq-applications/modules/affinities/routes/affinities-routes';
import { incrementalsRoutes } from 'app/siq-applications/modules/incrementals/routes/incrementals-routes';
import { daypartsRoutes } from 'app/siq-applications/modules/dayparts/routes/dayparts-routes';

export const SiqApplicationsRoutes: Routes = [
  ...reportBuilderRoutes,
  ...affinitiesRoutes,
  ...incrementalsRoutes,
  ...daypartsRoutes,
  ...promoRoutes
];

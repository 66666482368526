import { AgRendererComponent, ICellRendererParams } from '@siq-js/visual-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';

export abstract class BaseCriteriaRendererComponent<T> extends BaseSiqComponent implements AgRendererComponent {
  public model: any = null;
  protected abstract setupModel(params: T): void;

  protected constructor (
    protected asyncStatusService: AsyncStatusService
  ) {
    super();
  }

  async agInit(params: ICellRendererParams) {
    await this.asyncStatusService.isReady({cms: true, envConfig: true});

    try {
      this.setupModel(params.data as T);
    } catch (e) {
      console.warn('Failed to setup model for ', params.data);
    }
  }

  refresh(params: any) {
    return true;
  }
}

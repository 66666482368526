import * as _ from 'lodash';
import * as EmailValidator from 'email-validator';
import { Injectable } from '@angular/core';
import { UserService } from 'app/user/services/user.service';
import { Recipient } from 'app/activity/modules/sharing/models/recipient.model';
import { User } from 'app/user/models/user.model';
import { EmailWhiteList } from 'app/activity/modules/sharing/models/email-white-list.interface';
import { RecipientStatus } from 'app/activity/modules/sharing/models/recipient-status.enum';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';

@Injectable()
export class RecipientService {

  /**
   * Updates recipient with data from UserService based on email
   */
  public static updateEmail(users: User[], recipient: Recipient, email: string): Recipient {
    const lowerCaseEmail = _.toLower(email);
    recipient.external = true;
    recipient.email = lowerCaseEmail;
    const user: User = UserService.extractUserFromUserListByEmail(users, lowerCaseEmail);
    RecipientService.updateAutoComputes(recipient, user);
    return recipient;
  }
  
  private static updateAutoComputes(recipient: Recipient, user: User): void {
    RecipientService.updateName(recipient, user);
    RecipientService.updateExternal(recipient, user);
    RecipientService.updateValidity(recipient, user);
  }

  private static updateExternal(recipient: Recipient, user: User) {
    if (!user?.active) {
      recipient.external = true; // user exists in db, but set to not active; treat user as external in this case
    } else {
      recipient.external = _.isEmpty(user) && RecipientService.validateEmail(recipient);
    }
  }

  private static updateName(recipient: Recipient, user: User): void {
    if (!!_.get(user, 'firstName') || !!_.get(user, 'lastName')) {
      recipient.name = _.trim(user.firstName + ' ' + user.lastName);
    } else {
      recipient.name = undefined;
    }
  }

  private static updateValidity(recipient: Recipient, user: User): void {
    if (user) {
      recipient.status = RecipientStatus.VALID;
    } else if (!EmailValidator.validate(recipient.email)) {
      recipient.status = RecipientStatus.INVALID;
    } else {
      recipient.status = RecipientService.validateEmail(recipient) ? RecipientStatus.VALID : RecipientStatus.UNAUTHORIZED;
    }
  }

  private static validateEmail(recipient: Recipient): boolean {
    const whiteList: EmailWhiteList = SharingService.getWhiteList();
    if (whiteList == null) return true;
    if (whiteList.domains == null ) return true;
    if (whiteList.addresses == null ) return true;
    if (whiteList.domains.length === 0 && whiteList.addresses.length === 0) return true;

    const domain: boolean = _.includes(whiteList.domains, _.replace(recipient.email, /.*@/, ''));
    const address: boolean = _.includes(whiteList.addresses, recipient.email);

    return domain || address;
  }
  
}

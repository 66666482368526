import * as _ from 'lodash';
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FilterLabels } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-labels.enum';
import { FilterNumberTypes } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-number-types.enum';
import { FilterTypes } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-types.interface';
import { IDoesFilterPassParams, IFilterAngularComp, IFilterParams } from 'libs/visual-lib/src';

@Component({
  selector: 'siq-formatted-number-filter',
  templateUrl: './formatted-number-filter.component.html',
  styleUrls: ['./formatted-number-filter.component.scss']
})
export class FormattedNumberFilterComponent implements IFilterAngularComp, AfterViewInit {
  public filterLabels = FilterLabels;
  public filterType: FilterTypes['number'] = FilterNumberTypes.EQUALS;
  public filterTypes: string[] = _.map(FilterNumberTypes);
  public isRange: boolean;
  public filterForm: FilterFormattedNumberForm;
  private _active = false;
  private params: IFilterParams;
  private textFormatter: any;
  private valueFormatter: any;

  @ViewChild('input', { read: ViewContainerRef }) public input;

  constructor() {
    this.setForm();
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    // Removed textFormatter as it is no longer in IFilterParams
    // see https://github.com/ag-grid/ag-grid/commit/d18c36b8dd3fa1f68286d624633714119697abac#diff-0f2325b35d7c55a94294c461ef492b81
    this.valueFormatter = this.params.colDef.valueFormatter;
  }

  applyFilter(): void {
    this.params.filterChangedCallback();
  }

  public clearFilter(): void {
    // Resets filter form
    this.filterType = FilterNumberTypes.EQUALS;
    this.isRange = false;
    this.setForm();

    // Deactivates filter
    this._active = false; // Updates the filter status
    this.params.filterChangedCallback(); // Triggers the filter to be removed from the grid
  }

  public setForm(): void {
    this.filterForm = {
      rangeLow: null,
      rangeHigh: null,
      text: ''
    };
  }

  isFilterActive(): boolean {
    return (this.filterForm.text !== null && this.filterForm.text !== undefined && this.filterForm.text !== '') || (_.isNumber(this.filterForm.rangeHigh) && _.isNumber(this.filterForm.rangeLow));
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let nodeVal = this.params.getValue(params.node);
    nodeVal = _.isObject(nodeVal) ? nodeVal : {val: nodeVal};

    let formattedVal = this.scrub(
      this.valueFormatter({value: nodeVal})
    );
    const numFormattedVal = Number(formattedVal);

    //range only
    if(this.filterType.includes(FilterNumberTypes.IN_RANGE)){
      return _.inRange(numFormattedVal, this.filterForm.rangeLow, this.filterForm.rangeHigh)
        && _.gt(numFormattedVal, this.filterForm.rangeLow);
    }

    //every other type
    if (_.isNumber(this.filterForm.text)) {
      this.filterForm.text = this.filterForm.text.toString();
    }

    return this.filterForm.text.toLowerCase()
    .split(' ')
    .every((filterWord) => {

      filterWord = this.scrub(
        this.valueFormatter({value: {val: Number(filterWord)} })
      );

      const numFilterWord = Number(filterWord);


      switch (this.filterType) {
        case FilterNumberTypes.EQUALS:
          return _.eq(numFormattedVal, numFilterWord);

        case FilterNumberTypes.GREATER_THAN:
          return _.gt(numFormattedVal, numFilterWord);

        case FilterNumberTypes.GREATER_THAN_OR_EQUAL:
          return _.gte(numFormattedVal, numFilterWord);

        case FilterNumberTypes.LESS_THAN:
          return _.lt(numFormattedVal, numFilterWord);

        case FilterNumberTypes.LESS_THAN_OR_EQUAL:
          return _.lte(numFormattedVal, numFilterWord);

        case FilterNumberTypes.NOT_EQUAL:
          return !_.isEqual(formattedVal.toString(), filterWord);
      }
    });
  }

  getModel(): any {
    return {value: this.filterForm.text, type: this.filterType, rangeLow: this.filterForm.rangeLow, rangeHigh: this.filterForm.rangeHigh};
  }

  setModel(model: any): void {
    if(model && model.type){
      this.filterType = model.type;
    }
    
    if(this.filterType == FilterNumberTypes.IN_RANGE) {
      this.filterForm.rangeLow = model?.rangeLow;
      this.filterForm.rangeHigh = model?.rangeHigh;
    } else {
      this.filterForm.text = model ? model.value : '';
    }
    this.onChangeFilterType(this.filterType);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input && this.input.element.nativeElement.focus();
    });
  }

  onChange(newValue): void {
    if (this.filterForm.text !== newValue && !_.isEqual(this.filterType, FilterNumberTypes.IN_RANGE)) {
      this.filterForm.text = newValue;
      this.params.filterChangedCallback();
    }
  }

  onChangeFilterType(newValue): void {
    this.isRange = _.isEqual(this.filterType, FilterNumberTypes.IN_RANGE);
    if (!this.isRange) {
      //not range
      this.filterForm.rangeLow = null;
      this.filterForm.rangeHigh = null;
      this.params.filterChangedCallback();
    } else  {
      //range
      this.filterForm.text = '';
    }
  }

  private scrub(num: number): string {
    return num ? num.toString()
    .replace(/%/g, '')
    .replace(/\$/g, '')
    .replace(/#/g, '')
    .replace(/,/g, '')
    .trim() : '';
  }
}

export interface FilterFormattedNumberForm {
  rangeLow: number;
  rangeHigh: number;
  text: string | number;
}

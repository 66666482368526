import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppComponent } from 'app/app.component';
import { debounceTime } from 'rxjs';

export interface INavSecondaryEvent {
  open: boolean,
  callback?: () => any
}

@Injectable()
export class NavSecondaryService {

  public static nav$: BehaviorSubject<INavSecondaryEvent> = new BehaviorSubject({ open: false });

  public static open(callback?: () => any) {
    this.nav$.next({
      open: true,
      callback: callback
    });
  }

  public static close(callback?: () => any) {
    this.nav$.next({
      open: false,
      callback: callback
    });
  }

  public static toggle(callback?: () => any) {
    this.nav$.next({
      open: !this.nav$.value.open,
      callback: callback
    });
  }

  constructor() {
    NavSecondaryService.nav$
      .pipe(debounceTime(100))
      .subscribe(() => AppComponent.resize$.next());
  }
}

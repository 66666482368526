import { AgGridModule } from '@siq-js/visual-lib';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { DataTableComponent } from 'app/grid/components/data-table/data-table.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    SiqMaterialModule,
    AgGridModule,
  ],
  declarations: [
    DataTableComponent,
  ],
  providers: [
  ],
  exports: [
    DataTableComponent,
  ]
})
export class GridModule { }

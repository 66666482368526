<div class="app-container-wrapper app-header">
  <section class="app-container app-header-container">
    <h5 class="app-title">Manage User Groups: {{ edit ? 'Edit' : 'Create' }} User Group</h5>
  </section>
</div>

<div class="app-container-wrapper no-bg">
  <div class="app-container limited-width-content content-padded">

    <mat-card class="ag-form-card">

      <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="form">

        <mat-card-content *ngIf="accessGroupNotFound">
          No User Group can be found matching the requested ID.
        </mat-card-content>

        <mat-card-content *ngIf="!accessGroupNotFound">

          <div>
            <mat-form-field>
              <input matInput placeholder="Group Name" [formControl]="form.controls['displayName']" required>
            </mat-form-field>

            <mat-error *ngIf="!form.controls.displayName.valid && form.controls.displayName.touched">
              Group Name is required
            </mat-error>
            <mat-error *ngIf="form.controls.displayName.hasError('white-space-only')">
              {{ form.controls.firstName.errors['white-space-only'].message }}
            </mat-error>
          </div>

          <div>
            <siq-limited-length-text
              [formControl]="form.controls.description"
              [rows]="limitedLengthTextConfig.rows"
              [maxLength]="limitedLengthTextConfig.maxLength"
              placeholder="Description (optional)"
              ngDefaultControl>
            </siq-limited-length-text>
          </div>

          <div *ngIf="singleRetailerOnly" class="retailer">
            <mat-form-field color="accent">
              <mat-label>Select Retailer</mat-label>
              <mat-select [formControl]="form.controls.retailers" required [compareWith]="singleRetailerComparator">
                <mat-option *ngFor="let retailer of singleModeAllRetailers" [value]="[retailer.val]">
                  {{ retailer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="retailersList && retailersList.length > 0" class="retailer">
            <mat-form-field color="accent">
              <mat-label>Select Retailers</mat-label>
              <mat-select [formControl]="form.controls.retailers" multiple required>
                <mat-option *ngFor="let retailer of retailersList" [value]="retailer.val" (click)="selectRetailers(retailer.val)">
                  {{ retailer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="!!selectedRetailerCount && !singleRetailerOnly">
            <div class="access-group-criteria-wrapper">
              <div class="title-bar">
                <span class="select-filter-label">Select Filters </span>
                
              </div>

              <div class="access-group-criteria">
                    <siq-filter-group
                      *ngIf="filterSchema"
                      [initModel]="accessGroup.filters"
                      [schemaController]="filterSchema"
                      [isUserGroup]=true
                      [mapFn]="mapFn"
                      (emitter)="filterGroupChanged($event)"
                      class="filter-group"
                    ></siq-filter-group>
              </div>
            </div>

          </div>

        </mat-card-content>

        <mat-action-row>
          <button
            mat-stroked-button
            type="button"
            color="accent"
            class="secondary-button"
            (click)="cancel()"
            [disabled]="submitting"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            type="submit"
            color="accent"
            [disabled]="!form.valid 
             || submitting"
          >
            Submit
          </button>
        </mat-action-row>

      </form>

    </mat-card>

    <mat-card *ngIf="accessGroup && accessGroup.usersGroup.length" class="ag-members">

      <mat-card-title>Members of {{ accessGroup.displayName }}</mat-card-title>

      <mat-card-content>

        <div #grid class="grid-container">
          <ag-grid-angular #accessGroupGrid
            *ngIf="gridOptions"
            class="{{ agTheme }}"
            [gridOptions]="gridOptions"
          ></ag-grid-angular>
        </div>

      </mat-card-content>

    </mat-card>

  </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDim'
})
export class FormatDimPipe implements PipeTransform {

  transform(value: string): string {
    if (value.indexOf('|') !== -1) {
      return value.split('|')[0] + '(*)';
    }
    return value;
  }

}

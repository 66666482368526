import { Component, ViewChild } from '@angular/core';
import { IncrementalsFormData, IncrementalsFormJson } from 'app/siq-applications/modules/incrementals/models/form/incrementals-form-data.model';
import { IncrementalsConfig } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { IncrementalsService } from 'app/siq-applications/modules/incrementals/services/incrementals.service';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { Observable } from 'rxjs';
import { IncrementalsQueryModeModalComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-query-mode-modal/incrementals-query-mode-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'siq-js-incrementals-form',
  templateUrl: './incrementals-form.component.html',
  styleUrls: ['./incrementals-form.component.scss']
})
export class IncrementalsFormComponent {

  @ViewChild('queryMode') queryMode: QueryModeComponent; // component for controlling current schema

  public formData: IncrementalsFormData;
  public queryModeModalConfig: QueryModeModalConfig = { // config for query modal when schema changes
    component: IncrementalsQueryModeModalComponent,
    dataGetter: () => this.formData,
    diffFn: (schema: string) => !this.formData.isValidSchema(schema)
  };
  public overrideSchema: string;

  constructor(
    public config: IncrementalsConfig,
    private incrementalsService: IncrementalsService
  ) {
    if (this.incrementalsService.clonedActivity) { // if this is a cloned form, use the cloned data
      const formValues: IncrementalsFormJson  = _.cloneDeep(JSON.parse(this.incrementalsService.clonedActivity.formValues));
      formValues.name = this.incrementalsService.clonedActivity.getName() + '(copy)';
      formValues.isCloned = true;
      this.formData = this.incrementalsService.createForm(formValues);
    } else { // otherwise create new formData
      this.formData = new IncrementalsFormData();
    }
    this.overrideSchema = this.formData.schema;
    this.formData.setWeekEndingDay$();
  }

  // Submit form
  public submit(): Observable<any> {
    const obs$: Observable<any> = this.incrementalsService.createReport(this.formData);

    // Pass the Observable along to allow for additional subs (like in incrementals-stepper)
    return obs$;
  }

}

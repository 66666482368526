<mat-card>
  <siq-js-bar-column
    *ngIf="ready$.value && columnParams && columnConfig "
    [config]="columnConfig"
    [data]="columnParams"
    [processor]="columnProcessor"
  ></siq-js-bar-column>
</mat-card>

<section class="promo-section">

  <div class="promo-panel">
    <mat-form-field class="datasets-dropdown" color="accent">
      <mat-select [(value)]="dataSetKey" (valueChange)="updateView()">
        <mat-option *ngFor="let option of dataSetKeys" [value]="option.key">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select [(value)]="unitType" (valueChange)="updateUnitType()">
        <mat-option [value]="unitTypes.ABSOLUTE">
          Absolute
        </mat-option>
        <mat-option [value]="unitTypes.PSPW">
          Per Store Per Week
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select [(value)]="factKey" (valueChange)="updateView()">
        <mat-option *ngFor="let fact of facts" [value]="fact">
          {{ fact.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="toggle no-select">
      <label>{{preCustomName}}</label>
      <mat-slide-toggle (change)="togglePeriod($event.checked)" color="accent"></mat-slide-toggle>
      <label>{{yoyCustomName}}</label>
    </div>
  </div>

  <div class="sov-table">
    <siq-js-grid
      *ngIf="ready$.value && gridParams"
      [config]="gridParams.gridVisualOptions.gridConfig"
      [data]="gridParams"
      [processor]="gridProcessor"
      (featureUsed)="gridFeatureUsed($event)"
    ></siq-js-grid>
  </div>

</section>


import { Component } from '@angular/core';
import { BaseFilterGroupComponent } from 'app/filter/components/base-filter-group/base-filter-group.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';

@Component({
  selector: 'siq-report-builder-form-column-filter',
  templateUrl: './report-builder-form-column-filter.component.html',
  styleUrls: ['./report-builder-form-column-filter.component.scss']
})
export class ReportBuilderFormColumnFilterComponent extends BaseFilterGroupComponent {

  constructor(
    protected asyncStatusService: AsyncStatusService,
    protected utils: UtilsService
  ) {
    super(asyncStatusService, utils);
  }
}

/**
 * There are many cases where there are objects are arranged in order, but also explicit grouping applied to them.
 * This covers these use-cases by defining a type "GroupedArray" & helper functions for this data structure.
 *
 * Generic type <T> is the type of the elements
 */

// A "group" can have a string label for the group, and an array of T children
export interface Group<T> {
  group: string;
  children: T[];
}

// A GroupedArray can be an array of either type T (elements at the root-level) or Groups of T (can mix & match)
export type GroupedArray<T> = (T | Group<T>)[];

// Package all helper functions into a named constant - can be called via GroupedArrayFunctions.xxx()
export const GroupedArrayFunctions = {
  filter: filter,
  map: map,
  flatten: flatten
};

/**
 * filter function - returns the same GroupedArray structure, but filtered through each leaf T element
 * @param arr: GroupedArray
 * @param fn: filter function to run on each T leaf element
 * @param includeEmpty: optional argument to retain empty groups
 */
function filter<T>(arr: GroupedArray<T>, fn: (el: T) => boolean, includeEmpty = false): GroupedArray<T> {
  const out: GroupedArray<T> = [];

  arr.forEach((el: any) => {
    if (!el) return;

    if (el.children) {

      // nested group iteration
      const filteredChildren = el.children.filter(child => fn(child));
      if (includeEmpty || filteredChildren.length) {
        out.push({
          group: el.group,
          children: filteredChildren
        });
      }
    } else {
      // single element iteration
      fn(el) && out.push(el);
    }
  });

  return out;
}

/**
 * map function - converts a GroupedArray of type <From> into a GroupedArray of type <To>
 * @param arr: GroupedArray to map
 * @param fn: fn converting a single <From> type -> <To> type
 */
function map<From, To>(arr: GroupedArray<From>, fn: (el: From) => To): GroupedArray<To> {
  return arr.map((el: any) => {
    if (el.children) {

      return {
        group: el.group,
        children: el.children.map(child => fn(child))
      };

    } else {
      return fn(el);
    }
  });
}

/**
 * Flattens a GroupedArray into an array of T
 * @param arr: GroupedArray to flatten
 */
function flatten<T>(arr: GroupedArray<T>): T[] {
  const out: T[] = [];

  arr.forEach((el: any) => {
    if (el.children) {
      out.push(...el.children);
    } else {
      out.push(el);
    }
  });

  return out;
}

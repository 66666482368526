import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { FormsModule } from '@angular/forms';
import { SameStoreSalesSelectionComponent } from './components/same-store-sales-selection/same-store-sales-selection.component';
import { SameStoreSalesService } from 'app/siq-forms/modules/same-store-sales/services/same-store-sales.service';

@NgModule({
  declarations: [SameStoreSalesSelectionComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule
  ],
  exports: [SameStoreSalesSelectionComponent],
  providers: [
    SameStoreSalesService
  ],
})
export class SameStoreSalesModule {}

<div class="login-container">

  <div class="login-content">

    <div class="login-form-wrapper">
      <siq-login-form *ngIf="currentUser"></siq-login-form>
    </div>

    <div *ngIf="!currentUser">
      Loading...
    </div>

  </div>

</div>

<button
  mat-button
  [matMenuTriggerFor]="rootMenu"
>
  <button mat-mini-fab
          *ngIf="notifyCount > 0"
          class="menu-reports-counter">
    {{notifyCount < 100 ? notifyCount : '99+'}}
  </button>
  <span>{{ rootLabel }}</span>
  <mat-icon *ngIf="!hideCaret">arrow_drop_down</mat-icon>
</button>

<mat-menu #rootMenu="matMenu">
  <siq-dropdown-menu-item [item]="branch" *ngFor="let branch of exposedTree" (onSubmenuOpened)="closeOpenedMenus($event)"></siq-dropdown-menu-item>
</mat-menu>

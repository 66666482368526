import { Component, Inject, OnInit } from '@angular/core';
import { CmsField } from '@siq-js/cms-lib';
import { QueryModeModalComponent, QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromoFormData } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { CmsService } from 'app/core/services/cms/cms.service';

interface ComparisonGroupSummary {
  cgName: string;
  filters: FilterSelection[];
}
interface SourceOfVolumeSummary {
  cgName: string;
  dim: CmsField;
}
interface Summary {
  upcFilters: FilterSelection[];
  locationFilters: FilterSelection[];
  cgs: ComparisonGroupSummary[];
  sovs: SourceOfVolumeSummary[];
}
@Component({
  selector: 'siq-js-promo-query-mode-modal',
  templateUrl: './promo-query-mode-modal.component.html',
  styleUrls: ['./promo-query-mode-modal.component.scss']
})
export class PromoQueryModeModalComponent extends QueryModeModalComponent implements OnInit {

  public userGroupChanged: boolean;
  public summary: Summary;

  constructor(
    protected dialogRef: MatDialogRef<PromoQueryModeModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected modalData: QueryModeModalData
  ) {
    super(dialogRef, modalData);
  }

  ngOnInit(): void {
    const form: PromoFormData = this.modalData.data;
    const schema = this.modalData.schema;
    this.userGroupChanged = !!this.modalData.userGroupChanged;

    const upcSummary = form.upcFilters.filter(fs => !CmsService.isValidField(fs.field, schema));
    const locationSummary = form.locationFilters.filter(fs => !CmsService.isValidField(fs.field, schema));
    const cgSummary = [];
    form.comparisonGroups.forEach(cg => {
      if (cg.isValid) {
        const wrongFilters = cg.filters.filter(fs => !CmsService.isValidField(fs.field, schema));
        if (wrongFilters.length) {
          cgSummary.push({
            cgName: cg.name,
            filters: wrongFilters
          } as ComparisonGroupSummary);
        }
      }
    });

    const sovSummary = [];
    form.sourcesOfVolume.forEach(sov => {
      if (sov.isValid) {
        if (!CmsService.isValidField(sov.dimension || sov.oldDimension, schema)) {
          sovSummary.push({
            cgName: sov.comparisonGroup?.name || sov.oldComparisonGroup?.name,
            dim: sov.dimension || sov.oldDimension
          } as SourceOfVolumeSummary);
        }
      }
    });

    this.summary = {
      upcFilters: upcSummary,
      locationFilters: locationSummary,
      cgs: cgSummary,
      sovs: sovSummary
    };

    this.ready();

  }

}

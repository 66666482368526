import { CmsField, CmsFieldJson } from '@siq-js/cms-lib';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { DateRangeInterface, DateRangeInterfaceJson } from 'app/siq-forms/modules/dates/models/interfaces';
import { AppFormData, AppFormJson } from 'app/siq-applications/modules/shared/models/app-form-data.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

export interface AffinitiesFormJson extends AppFormJson {
  name: string;
  isCloned: boolean;
  affinityType: CmsFieldJson;
  secondaryAffinityType?: CmsFieldJson; // asymmetric affinity
  filters: FilterSelectionJson[];
  leftFilters: FilterSelectionJson[];
  rightFilters: FilterSelectionJson[];
  dateRanges: DateRangeInterfaceJson;
}

export class AffinitiesFormData extends AppFormData<AffinitiesFormJson> {
  public name: string;
  public affinityType: CmsField;
  public secondaryAffinityType?: CmsField;
  public dateRanges: DateRangeInterface; // current date value
  public filters: FilterSelection[] = [];
  public leftFilters: FilterSelection[] = [];
  public rightFilters: FilterSelection[] = [];
  public schema: string;
  public isCloned: boolean;

  constructor() {
    super();
    this.weekEndingday = this.datesService.getDefaultWeekEndingDay();
  }

  /**
   * Used for QueryModeModalConfig diffFn. To determine whether the modal needs to be opened
   */
  public isValidSchema(schema: string): boolean {
    if (this.affinityType && !CmsService.isValidField(this.affinityType, schema)) return false;
    if (this.secondaryAffinityType && !CmsService.isValidField(this.secondaryAffinityType, schema)) return false;

    for (let fs of this.filters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;

      // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        if (fs.values.find(val => !retailers.includes(val))) return false;
      }
    }
    for (let fs of this.leftFilters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
    }
    for (let fs of this.rightFilters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
    }

    return true;
  }

  public toJson(): AffinitiesFormJson {
    const json: AffinitiesFormJson = {
      name: this.name,
      schema: this.schema,
      weekEndingDay: this.weekEndingday,
      dateRanges: DatesService.isDynamic(this.dateRanges)
        ?
        { // dynamic dates
          begin: DatesService.dateMStoS(this.dateRanges.begin.getTime()), // translated dates in begin & end are needed to display
          end: DatesService.dateMStoS(this.dateRanges.end.getTime()),
          dynamicBegin: this.dateRanges.dynamicBegin,
          dynamicEnd: this.dateRanges.dynamicEnd
        }
        :
        { // static dates
          begin: DatesService.dateMStoS(this.dateRanges.begin.getTime()),
          end: DatesService.dateMStoS(this.dateRanges.end.getTime()),
        },
      affinityType: this.affinityType?.toJson(),
      filters: this.filters.map(f => f.toJson()),
      leftFilters: this.leftFilters.map(f => f.toJson()),
      rightFilters: this.rightFilters.map(f => f.toJson()),
      isCloned: this.isCloned
    };
    // asymmetric affinity
    if (this.secondaryAffinityType) {
      json.secondaryAffinityType = this.secondaryAffinityType.toJson();
    }
    return json;
  }
}

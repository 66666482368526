import { Component, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export abstract class BaseSiqComponent implements OnDestroy {

  public ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public unsub$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
    this.ready$.complete();
  }

  protected ready() {
    this.ready$.next(true);
  }
}

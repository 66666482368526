<div>
  <mat-form-field class="example-full-width">
    <mat-label>Pre Promo Period</mat-label>
    <input
      matInput
      [value]="formData.prePromoDateRange.customPeriodName" 
      (change)="onPeriodNameChange(promoPeriods.PRE, $event.target.value)"
    >
  </mat-form-field>
</div>

<siq-js-date-selection
  #preDateSelectorComponent
  [config]="dateSelectionConfig"
  [noDefault]="true"
  (onDateChanged)="onPreDateChanged($event)"
  ></siq-js-date-selection>

<div class="push-down">
  <mat-form-field class="example-full-width">
    <mat-label>Year Over Year</mat-label>
    <input 
      matInput 
      [value]="formData.yoyDateRange.customPeriodName"
      (change)="onPeriodNameChange(promoPeriods.YOY, $event.target.value)"
    >
  </mat-form-field>
</div>

<siq-js-date-selection
  #yoyDateSelectorComponent
  [config]="dateSelectionConfig"
  [noDefault]="true"
  (onDateChanged)="onYoyDateChanged($event)"
  ></siq-js-date-selection>

<div class="push-down">
  <mat-checkbox (change)="onCheckboxChange($event)" [checked]="!hideCustomDateSelector">Custom Date Range</mat-checkbox>
</div>

<div [ngClass]="{'hide-custom-date': hideCustomDateSelector}">
  <mat-form-field class="example-full-width" [ngClass]="{'hide-custom-date': hideCustomDateSelector}">
    <mat-label>Custom Date Range</mat-label>
    <input 
      matInput 
      [value]="formData.customPromoDateRange.customPeriodName" 
      (change)="onPeriodNameChange(promoPeriods.POST, $event.target.value)"
    >
  </mat-form-field>
</div>

<siq-js-date-selection
  #customDateSelectorComponent
  [ngClass]="{'hide-custom-date': hideCustomDateSelector}"
  [config]="dateSelectionConfig"
  [noDefault]="true"
  (onDateChanged)="onCustomDateChanged($event)"
  ></siq-js-date-selection>

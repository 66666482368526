import * as _ from 'lodash';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityMenuItemComponent } from 'app/core/components/nav-primary/activity-menu/item/item.component';
import { ActivityMenuScheduledItemComponent } from 'app/core/components/nav-primary/activity-menu/scheduled-item/scheduled-item.component';
import { ActivityMenuSharedItemComponent } from 'app/core/components/nav-primary/activity-menu/shared-item/shared-item.component';
import { ActivityService } from 'app/activity/services/activity.service';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { DropdownMenuItem } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.interface';

@Component({
  selector: 'siq-activity-menu',
  templateUrl: './activity-menu.component.html',
  styleUrls: ['./activity-menu.component.scss']
})
export class ActivityMenuComponent implements OnInit, OnDestroy {

   public static readonly labels = {
    MY_REPORTS: 'My Reports',
    SHARED_REPORTS: 'Shared Reports',
    SCHEDULED_REPORTS: 'Scheduled Reports',
    ALL: 'View All Reports'
  };

  private static readonly menuLength = 5;
  private static readonly seeds: DropdownMenuItem<Activity|void>[] = [
    {
      label: ActivityMenuComponent.labels.MY_REPORTS,
      children: []
    },

     {
       label: ActivityMenuComponent.labels.SHARED_REPORTS,
       children: []
     },
     {
       label: ActivityMenuComponent.labels.SCHEDULED_REPORTS,
       children: []
     },

    ActivityMenuComponent.generateViewAllChild('View All Reports', ActivityTabKey.ALL_REPORTS)
  ];

  public tree = new BehaviorSubject<DropdownMenuItem<Activity|void>[]>(ActivityMenuComponent.seeds);
  public notifyCount = 0;

  private unsub = new Subject<void>();

  private static generateViewAllChild(
    label: string = ActivityMenuComponent.labels.ALL,
    tab?: ActivityTabKey
  ): DropdownMenuItem<void> {
    return {
      label: label,
      routerLink: 'activity',
      queryParams: {
        tab: tab
      },
      separator: true
    };
  }

  constructor(
    private activityService: ActivityService,
  ) {
    // needs all activities to populate the tree menus; good place to kick off all reports retrieving while the platform is initializing (loading screen).
    this.activityService.getAllActivities();
  }
  
  ngOnInit() {
    ActivityService.Activities$
    .pipe(
      map(activities => activities.sort((a, b) => Number(b.createdDate) - Number(a.createdDate)))
    )
    .subscribe((activities: Activity[]) => {
      const mine = activities.filter(a => !a.isTrashed() && a.isMine() && !a.isSharedOrScheduled());
      const scheduled = activities.filter(a => !a.isMine() && a.isScheduled() && !a.sharedReport.deleted);
      const shared = activities.filter(a => !a.isMine() && a.isShared() && !a.sharedReport.deleted);
      this.setMyReports(mine);
      this.setScheduledReports(scheduled);
      this.setSharedReports(shared);
      this.notifyCount = activities.filter(a => (
          a.isMine() && !a.isTrashed() && !a.isViewed()
            ||
            (!a.isMine() && !a.sharedReport.deleted && a.isSharedOrScheduled() && !a.sharedReport.viewed)
        )
      ).length;
    })
  }

  ngOnDestroy () {
    this.unsub.next();
  }

  // @TODO - Merge with other SetReports
  private setMyReports (activities: Activity[]) {
    const snapshot = _.clone(this.tree.value);

    // Referencing this by a non-trivial entity allows display order of menu items to be trivial without breaking this.
    const menuItem = _.find(snapshot, { label: ActivityMenuComponent.labels.MY_REPORTS });

    menuItem.children = activities
      .slice(0, ActivityMenuComponent.menuLength)
      .map((report) => <DropdownMenuItem<Activity>> { data: report, component: ActivityMenuItemComponent });

    if (ActivityMenuComponent.menuLength < activities.length) {
      menuItem.children.push(ActivityMenuComponent.generateViewAllChild('View All My Reports', ActivityTabKey.MY_REPORTS));
    }

    this.tree.next(snapshot);
  }

  // @TODO - Merge with other SetReports
  private setSharedReports (reports: Activity[]) {
    const snapshot = _.clone(this.tree.value);

    // Referencing this by a non-trivial entity allows display order of menu items to be trivial without breaking this.
    const menuItem = _.find(snapshot, { label: ActivityMenuComponent.labels.SHARED_REPORTS });

    // Display shared by user name
    menuItem.children = reports
      .slice(0, ActivityMenuComponent.menuLength)
      .map((report) => <DropdownMenuItem<Activity>> { data: report, component: ActivityMenuSharedItemComponent });

    if (ActivityMenuComponent.menuLength < reports.length) {
      menuItem.children.push(ActivityMenuComponent.generateViewAllChild('View All Shared Reports', ActivityTabKey.SHARED));
    }

    this.tree.next(snapshot);
  }

  // @TODO - Merge with other SetReports
  private setScheduledReports (reports: Activity[]) {
    const snapshot = _.clone(this.tree.value);

    // Referencing this by a non-trivial entity allows display order of menu items to be trivial without breaking this.
    const menuItem = _.find(snapshot, { label: ActivityMenuComponent.labels.SCHEDULED_REPORTS });

    // Display shared by user name
    menuItem.children = reports
      .slice(0, ActivityMenuComponent.menuLength)
      .map((report) => <DropdownMenuItem<Activity>> { data: report, component: ActivityMenuScheduledItemComponent });

    if (ActivityMenuComponent.menuLength < reports.length) {
      menuItem.children.push(ActivityMenuComponent.generateViewAllChild('View All Scheduled Reports', ActivityTabKey.SCHEDULED));
    }

    this.tree.next(snapshot);

  }

}

import { Injectable } from '@angular/core';
import { DatesServiceCore, DateUnit} from '@siq-js/core-lib';
import { DateModel } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-model';
import { FilterTypes } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-types.interface';
import * as _ from 'lodash';

@Injectable()
export class CustomFilterService {

  /**
   * Checks the start and end range parameter to ensure that the start  is equal to or before end.
   *
   * @param {DateModel | number} rangeStart Either a DateForm or number indicating the start.
   * @param {DateModel | number} rangeEnd Either a DateForm or number indicating the end.
   * @param {DateUnit} precision How precisely to compare dates (by year, month, day, etc.).
   * @returns {boolean} A boolean indicating if the range is valid.
   *
   * @static
   */
  public static checkRangeParameters(
    rangeStart: DateModel | number,
    rangeEnd: DateModel | number,
    precision?: DateUnit
  ): boolean {
    if (_.isObject(rangeStart) && _.isObject(rangeEnd)) { // Was passed date models
      return (new DateModel(rangeStart as DateModel))[precision] <= (new DateModel(rangeEnd as DateModel))[precision];
    } else if (_.isNumber(rangeStart) && _.isNumber(rangeEnd)) {
      return rangeStart <= rangeEnd;
    } else {
      return false;
    }
  }

  public static minTwoDigits(n: number): string {
    return (n < 10 ? '0' : '') + n;
  }

  public static getDateStr(model: DateModel, precision: DateUnit): string {
    switch (precision) {
      case DateUnit.YEAR:
        return model.year + '-01-01';
      case DateUnit.MONTH:
        return `${model.year}-${this.minTwoDigits(model.month)}-01`;
      case DateUnit.DAY:
        return `${model.year}-${this.minTwoDigits(model.month)}-${this.minTwoDigits(model.day)}`;
      default:
        console.warn('fail to define dateStr');
        return ''
    }
  }

  /**
   * Compares a date to a model and determines if it should be included in the filter set.
   *
   * @param {number} date The date in unix timestamp to compare.
   * @param {DateModel} model The model to compare the date against.
   * @param {FilterTypes['number']} filterType The type of filter to apply.
   * @param {DateUnit} precision The precision at which the dates should be compared.
   * @param {DateModel} modelRangeEnd The end date range, should the in-range filter be selected.
   * @returns {boolean} Indicates if the date should be included in the filter set.
   *
   * @static
   */
  public static dateCompare(
    date: number,
    model: DateModel,
    filterType: FilterTypes['number'],
    precision: DateUnit,
    modelRangeEnd: DateModel
  ): boolean {
    
    const dateStr = this.getDateStr(model, precision);
    const modelVal = DatesServiceCore.zonedTimeToUtc(dateStr, '-00:00').getTime();
    

    // Compares the date based on specified filter type
    switch (filterType) {
      case 'equals':
        return date == modelVal; // == to compare by value

      case 'notEqual':
        return date != modelVal;

      case 'lessThan':
        return date < modelVal;

      case 'lessThanOrEqual':
        return date <= modelVal;

      case 'greaterThan':
        return date > modelVal;

      case 'greaterThanOrEqual':
        return date >= modelVal;

      case 'inRange':
        const endStr = this.getDateStr(modelRangeEnd, precision)
        return date >= modelVal && date <= DatesServiceCore.zonedTimeToUtc(endStr, '-00:00').getTime();
    }
  }

  /**
   * Compares a number to another number or range and determines if it should be included in the filter set.
   *
   * @param {number} number The number to compare.
   * @param {number} startNumber The number to be compared against, or, in the case of ranges, the start number.
   * @param {FilterTypes['number']} filterType The type of filter to apply.
   * @param {number} endNumber The range's end number, should the in-range filter be selected.
   * @returns {boolean} Indicates if the date should be included in the filter set.
   *
   */
  public static numberCompare(number: number, startNumber: number, filterType: FilterTypes['number'], endNumber: number): boolean {
    switch (filterType) {
      case 'equals':
        return startNumber === number;

      case 'notEqual':
        return startNumber !== number;

      case 'lessThan':
        return number < startNumber;

      case 'lessThanOrEqual':
        return number <= startNumber;

      case 'greaterThan':
        return startNumber < number;

      case 'greaterThanOrEqual':
        return startNumber <= number;

      case 'inRange':
        return startNumber <= number && number <= endNumber;
    }
  }
}

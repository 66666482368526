import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from 'app/filter/services/filter.service';
import { FilterSelection } from 'app/filter/models/filter-selection';

@Pipe({
  name: 'filterSelectionTooltip'
})
export class FilterSelectionTooltipPipe implements PipeTransform {
  private static MAX_VALUES = 5;

  transform(model: FilterSelection): string {
    let items = [];

    // First check if nulls are enabled
    if (model.nulls) {
      items.push('Unknowns');
    }

    // Next determine the proper array to use
    let arr = model.values;
    let i = 0;

    // Fill up the items to display up to the threshold MAX_VALUES
    while (items.length < FilterSelectionTooltipPipe.MAX_VALUES && i < arr.length) {
      let filterValue = arr[i++];

      // If async, look up in cache, fall back to default filter value
      // if (model.field.async) {
        filterValue = FilterService.getFilter(model.field.id).values.getDisplay(filterValue) || filterValue;
      // }

      items.push(filterValue);
    }

    let text = items.join(', ');

    // Truncate with remaining items
    if (arr.length > i) {
      text += ` and ${arr.length - i} more`;
    }

    return text;
  }

}

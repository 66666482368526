import mixpanel from 'mixpanel-browser/src/loader-module';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { AuthService } from 'app/auth/services/auth.service';
import { UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelToken } from 'app/core/services/mixpanel/mixpanel-token.enum';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import * as _ from 'lodash';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

@Injectable()
export class MixpanelService {

  private static ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mixpanel: any;

  constructor(
    private httpService: SiqHttpService
  ) {
    this.mixpanel = mixpanel;
    AppLoadingService.ready$.subscribe(() => this.identify());
  }

  // Tracks an event. MixpanelEvent is an enum, with an optional properties (for the event) and callback (after tracking call) param
  async track(event: MixpanelEvent, properties: object = {}, callback?: () => {}) {
    let send = true;

    // Event specific logic
    switch (event) {
      case MixpanelEvent.TEST:
        if (AppSiqConstants.environment.production) {
          console.warn('Cannot track Test event in production');
          send = false;
        }
        break;
      case MixpanelEvent.ACTIVITY_CREATED:
        if (!(properties['form'] instanceof UntypedFormGroup)) {
          break;
        }
        let form = properties['form'] as UntypedFormGroup;
        if (form) {
          for (let k in form.controls) {
            properties[k] = form.controls[k].value;
          }
          delete properties['form'];
        }
        break;
    }

    if (send) {
      MixpanelService.ready$
        .pipe(
          filter(ready => !!ready),
          take(1)
        ).subscribe(() => {
          if (!_.has(properties, 'Platform Mode')) {
            properties['Platform Mode'] = EnvConfigService.getConfig().platformMode;
          }
          this.mixpanel.track(event.toString(), properties, callback);
      });
    }
  }

  // Takes in a User object and fires the proper identifying calls to mixpanel, followed by a 'Login' event
  private identify() {
    const env = /dot-(.+?)[.]appspot/.exec(this.httpService.getFullEndpoint(''))[1];
    this.mixpanel.init(AppSiqConstants.environment.production ? MixpanelToken.PRODUCTION : MixpanelToken.TEST);
    this.mixpanel.register({
      'Environment': env || AppSiqConstants.environment.host
    });

    AuthService.CurrentUser$.pipe(filter(user => !!user)).subscribe(user => {
      this.mixpanel.people.set_once({
        '$first_name': user.firstName,
        '$last_name': user.lastName,
        '$email': user.email
      }, () => {
        this.mixpanel.identify(user.email);
        MixpanelService.ready$.next(true);
      });
    });
  }

  logout() {
    // tracking will continue after next identify()
    MixpanelService.ready$.next(false);
  }

  // Allows the external setting of super-properties
  setSuperProperty(key: string, value: string, onlyOnce?: boolean) {
    let obj = {};
    obj[key] = value;
    if (onlyOnce) {
      this.mixpanel.register_once(obj);
    } else {
      this.mixpanel.register(obj);
    }
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum ActivityGridOptionsMenu {
  AUTOSIZE_COLUMNS = 'Autosize Columns',
  FIT_COLUMNS_TO_SCREEN = 'Fit Columns to Screen',
  DOWNLOAD_CSV = 'Download CSV',
  DOWNLOAD_EXCEL = 'Download Excel',
  SHARE_SCHEDULE = 'Share/Schedule',
  SHARE = 'Share',
  CLONE_CRITERIA = 'Clone Criteria',
  EDIT_CRITERIA = 'Edit Criteria'
}

@Component({
  selector: 'siq-js-activity-grid-options-menu',
  templateUrl: './activity-grid-options-menu.component.html',
  styleUrls: ['./activity-grid-options-menu.component.scss']
})
export class ActivityGridOptionsMenuComponent implements OnInit {

  @Input() config: string[];
  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}

<siq-app-header [config]="config" [config]="config" [showBack]="true"></siq-app-header>
<siq-query-mode
  #queryMode
  [disabled]="false"
  [disableMultiRetailer]="true"
  [modalConfig]="queryModeModalConfig"
  [overrideSchema]="overrideSchema">
</siq-query-mode>

<siq-js-promo-stepper
  *ngIf="ready$ | async"
  [formData]="formData"
  [accessGroupChanged]="accessGroupChanged"
  [queryMode]="queryMode"
  [editMode]="editMode"
  [overrideSchema]="overrideSchema"
>
</siq-js-promo-stepper>

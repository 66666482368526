import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { CmsService } from 'app/core/services/cms/cms.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { DateShortcutsService } from 'app/siq-forms/modules/dates/services/date-shortcuts.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

/**
 * Generates CriteriaChip models, typically used by criteria renderers
 */
export class CriteriaChipFactory {
  public static getType(type: string): string {
    return type
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  public static getDate(dr: DateRangeInterface): string {
    if (DatesService.isDynamic(dr)) {
      const sc = DateShortcutsService.findDynamicDateShortCut(DateShortcutsService.getDynamicDateShortcuts() , {in: dr.dynamicBegin, out: dr.dynamicEnd});
      if (sc) {
        return sc.name;
      }
    }
    return DatesService.format(dr.begin, AppSiqConstants._dateFormat) + ' - ' + DatesService.format(dr.end, AppSiqConstants._dateFormat);
  }

  public static getMetrics(metrics: CmsMetric[]): CriteriaChip {
    const FACT_THRESHOLD = 3;
    let toolTip = metrics.slice(0, FACT_THRESHOLD).map(m => m.display).join(', ');
    if (metrics.length > FACT_THRESHOLD) {
      toolTip += ` and ${metrics.length - FACT_THRESHOLD} more`;
    }

    return {
      label: `${metrics.length} Fact(s)`,
      toolTip: toolTip
    };
  }

  public static getDimensions(dimensions: CmsField[]): CriteriaChip {
    const DIM_THRESHOLD = 3;
    let toolTip = dimensions.slice(0, DIM_THRESHOLD).map(d => d.display).join(', ');
    if (dimensions.length > DIM_THRESHOLD) {
      toolTip += ` and ${dimensions.length - DIM_THRESHOLD} more`;
    }

    return {
      label: `${dimensions.length} Dimensions(s)`,
      toolTip: toolTip
    };
  }

  public static getFilters(filters: FilterSelection[], filterName?: string): CriteriaChip {
    const FILTER_THRESHOLD = 3;
    let toolTip = filters.slice(0, FILTER_THRESHOLD).map(fs => fs.field.display).join(', ');

    if (filters.length > FILTER_THRESHOLD) {
      toolTip += ` and ${filters.length - FILTER_THRESHOLD} more`;
    }

    if (filterName) {
      return {
        label: `${filters.length} ${filterName} Filter(s)`,
        toolTip: toolTip
      };
    } else {
      return {
        label: `${filters.length} Filter(s)`,
        toolTip: toolTip
      };
    }

  }

  public static getRetailers(filters: FilterSelection[], schema: string): CriteriaChip {
    if (schema !== EnvConfigService.getConfig().primaryEntity) {
      // Single retailer
      return {
        label: UtilsService.getRetailerDisplay(schema)
      };
    }

    const _filter = filters.find(fs => fs.id === CmsService.RETAILER_FIELD_ID);

    // Retailers explicitly specified
    if (_filter && !_filter.isEmpty()) {
      const retailers = _filter.values;
      if (_filter.include) {
        // include filter
        return {
          label: `${retailers.length} Retailers`,
          toolTip: UtilsService.getRetailerDisplays(retailers).join(', ')
        };
      } else {
        // exclude filter is specified, invert selection
        const allRetailers = EnvConfigService.getConfig().retailers;
        let invertedRetailers = allRetailers.filter(value => !retailers.includes(value)); // - retailers;
        return {
          label: `${invertedRetailers.length} Retailers`,
          toolTip: UtilsService.getRetailerDisplays(invertedRetailers).join(', ')
        };
      }
    }

    // No/empty filter means all retailers selected
    return {
      label: 'All Retailers',
      toolTip: UtilsService.getRetailerDisplays().join(', ')
    };
  }

  public static getUpcCount(upcCount: number): string {
    return `${upcCount} UPC(s)`;
  }
}

<div class="submit-wrapper">

  <div>

    <button
      type="button"
      mat-raised-button
      class="gray-button"
      (click)="clearForm()"
    >
      Clear Form
    </button>
    <button
      *ngIf="!hideReset"
      type="button"
      mat-raised-button
      class="gray-button"
      (click)="resetForm()"
    >
      Reset Form
    </button>

  </div>

  <div class="fill-space">
  </div>

  <mat-form-field *ngIf="nameFormControl">
    <input matInput placeholder="Name Activity (optional)" [formControl]="nameFormControl">
  </mat-form-field>

  <siq-submit-button
    [disabled]="!form.valid || submitting"
    [submitting]="submitting"
    color="accent"
    copy="Submit"
    *ngIf="!hideSubmit"
  ></siq-submit-button>

</div>

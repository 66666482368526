import { Injectable } from '@angular/core';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { ChartThemes } from 'libs/visual-lib/src/lib/modules/charts-graphs/models';
import { AgChartBackground, AgChartTheme, AgChartThemeName } from 'ag-charts-community';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  private static DARK_THEME_CHART_BACKGROUND = '#232629';

  // Cannot use the THEME_COLORS here because those are held in apps/dashboard, not a lib accessible from here
  static PALETTE_COLORS = [
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sky').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-grass').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sienna').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-cool-gray').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sunshine').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-slate').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-plum').trim(),
    window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-ocean').trim()
  ];
  static THEME_PALETTE = {
    fills: ChartsService.PALETTE_COLORS,
    strokes: ChartsService.PALETTE_COLORS
  };

  static getThemePdi() {
    return {
      baseTheme: <AgChartThemeName>ChartThemes.DEFAULT.toString(),
      palette: ChartsService.THEME_PALETTE
    };
  }
  static getThemePdiDark() {
    return {
      baseTheme: <AgChartThemeName>ChartThemes.DEFAULT_DARK.toString(),
      palette: ChartsService.THEME_PALETTE
    };
  }

  static getChartThemeName(theme: string): string {
    if (theme === ThemesService.THEMES.DARK) {
      return ChartThemes.DEFAULT_DARK;
    } else {
      return ChartThemes.DEFAULT;
    }
  }

  static getChartThemeObject(theme: string): AgChartTheme {
    if (theme === ThemesService.THEMES.DARK) {
      return ChartsService.getThemePdiDark();
    } else {
      return ChartsService.getThemePdi();
    }
  }

  static getChartBackgroundForTheme() {
    const agCB: AgChartBackground = {};
    if (ThemesService.theme$.value === ThemesService.THEMES.DARK) {
      agCB.fill = ChartsService.DARK_THEME_CHART_BACKGROUND;
    }
    return agCB;
  }
  constructor() { }
}

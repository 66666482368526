import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { Observable } from 'rxjs';
import { DaypartsFormData } from 'app/siq-applications/modules/dayparts/models/form/dayparts-form-data.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BaseStepperComponent } from 'app/core/components/stepper/base-stepper.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { ValidationMessageStatus, ValidationMessage } from 'app/core/models/validation-message';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { filter } from 'rxjs';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { DaypartsService } from 'app/siq-applications/modules/dayparts/services/dayparts.service';
import { Router } from '@angular/router';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { DaypartsQueryModeModalComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-query-mode-modal/dayparts-query-mode-modal.component';
import * as _ from 'lodash';
import { QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { CoreConstants, WeekEndingDay } from '@siq-js/core-lib';
import { FilterGroupComponent } from 'app/filter/components/filter-group/filter-group.component';
import { FilterService } from 'app/filter/services/filter.service';
import { DaypartsConfig } from 'app/siq-applications/modules/dayparts/models/dayparts-config.model';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';

@Component({
  selector: 'siq-js-dayparts-stepper',
  templateUrl: './dayparts-stepper.component.html',
  styleUrls: ['./dayparts-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class DaypartsStepperComponent extends BaseStepperComponent implements OnInit {
  @Input() formData: DaypartsFormData;
  @Input() queryMode: QueryModeComponent;
  @Input() overrideSchema: string;
  @Input() submit: () => Observable<any>;

  @ViewChild('filters') filters: FilterGroupComponent; // custom FilterGroupComponent for global filters

  public isDateRangeValid: boolean = true;
  public stepValidationMessages: ValidationMessage[] = [null, null]; // validation message for each step
  public validationMessageStatus = ValidationMessageStatus;

  constructor(
    protected asyncStatusService: AsyncStatusService,
    private daypartsService: DaypartsService,
    private config: DaypartsConfig,
    private mixpanelService: MixpanelService,
    private router: Router,
    private utilsService: UtilsService,
    private datesService: DatesService
  ) {
    super(asyncStatusService);

  }

  // function called when dates selected from the date selector
  public dateChanged(dates: DateRangeInterface) {
    this.formData.dateRanges = dates;
    let start: string, end: string;
    start = dates.begin ? DatesService.format(dates.begin, CoreConstants._shortDate) : '';
    end = dates.end ? DatesService.format(dates.end, CoreConstants._shortDate) : '';
    this.updateValidationMessage(0, ValidationMessageStatus.VALID, `${start} through ${end}`);
    this.validateForm(dates.isDateRangeValid);
  }

  /**
   * Function called when FilterGroup changes
   * @param filterModel: FilterSelection[]
   */
  public filterGroupChanged(filterModel: FilterSelection[], group: any) {
    this.datesService.updateCommunalDateRange(this.queryMode.schema$.getValue(), this.formData.filters, filterModel);
    this.formData.filters = filterModel;
    if (!this.formData.filters.length) {
      this.updateValidationMessage(1, ValidationMessageStatus.UNSET, 'Select Filter(s)');
    } else {
      this.updateValidationMessage(1, ValidationMessageStatus.VALID, `${this.formData.filters.length} Filter(s) Selected`);
    }
  }

  public init(): void {
    if (this.formData.isCloned) {
      this.processClonedForm();
    }
    this.purgeFilterValues();
    this.queryMode.schema$
      .pipe(filter(schema => !!schema))
      .subscribe(schema => {
        this.formData.schema = schema;
        this.datesService.datePickerSchema$.next(DatesService.getActiveSchemas(schema, this.formData.filters));
      });
  }

  // Submit form
  public onSubmit() {
    this.disableSubmitBtn = true;

    // The submit fn returns an Observable to which we can subscribe
    this.submit()
      .subscribe(res => {
        // form handles whatever needs to be done here
        // redirect back to the list
        this.router.navigate(['app/dayparts']);
      }, err => {
        console.log('Error submitting AffinitiesForm: %O', err);
        this.validateForm(false);
      });
  }

  public weekEndingDayChanged(we: WeekEndingDay) {
    if (we) {
      this.formData.weekEndingday = we;
    }
  }

  // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
  private purgeFilterValues() {
    FilterService.purgeFilterValues(this.filters, this.formData.filters);
  }

  // Process form when current form is cloned
  private processClonedForm(): void {
    const currSchema = UtilsService.getQueryModeDefaultSchema(this.overrideSchema);
    if (this.daypartsService.clonedActivity.accessGroupChanged && !this.formData.isValidSchema(currSchema)) { // AG has changed and form no longer valid
      this.utilsService.openModal(
        DaypartsQueryModeModalComponent,
        {
          data: _.cloneDeep(this.formData),
          schema: this.queryMode.schema$.getValue(),
          userGroupChanged: true
        } as QueryModeModalData
      );
      // reset form schema
      this.formData.schema = currSchema;
    }
    this.mixpanelService.track(MixpanelEvent.REPORTS_CLONED, {
      'Application': this.config.getApplication().display,
      'Cloned Activity ID': this.daypartsService.clonedActivity.getId(),
      'Name': this.formData.name,
      'JSON': this.formData.toJson(),
      'Type': 'Report'
    });
    this.daypartsService.clonedActivity = null; // clear activity
  }

  private validateForm(isValid: boolean) {
    this.isDateRangeValid = isValid;
    this.disableSubmitBtn = !isValid;
  }

}

import { CmsField } from '@siq-js/cms-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import * as _ from 'lodash';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { MetricColumn } from 'app/siq-applications/modules/report-builder/models/form/metric-column.model';
import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { YearOverYearEnum } from 'app/siq-applications/modules/report-builder/models/year-over-year.enum';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ColumnGroupParameters } from 'app/siq-applications/modules/report-builder/models/column-group-parameters';
import { RBMetricColumn, ReportBuilderColumnType } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { AggregationType } from 'app/core/models/aggregation-type.enum';
import { DateRangeParameter, DatesServiceCore, DateUnit } from '@siq-js/core-lib';

export class ReportBuilderParameters {
  public columnNames: Map<string, string>;
  public columnOrder: string[];
  public columnRequests: AppRequest[];
  public formValues: any;
  public reportBuilderTitle: string;

  constructor(formData: ReportBuilderFormData) {

    this.reportBuilderTitle = formData.name;
    this.columnRequests = formData.columns.filter(c => c.type === ReportBuilderColumnType.METRIC).map(c => this.columnToAppRequest(c as MetricColumn, formData));
    this.formValues = formData.toJson();
  }

  private columnToAppRequest(col: MetricColumn, formData: ReportBuilderFormData): AppRequest {
    const datasets: AppDataset[] = [];

    const dateRangeParam = DatesService.paramify(formData.globalDateRange, formData.weekEndingday);
    const dimensions = formData.columns
      .filter(c => c.type === AppSiqConstants.types.DIMENSION).map(c => UtilsService.paramify(c.ref));
    const facts = [
      UtilsService.paramify(col.ref)
    ];
    const filters = [
      ...col.filters,
      ...formData.globalFilters.filter(globalFilter => !_.find(col.filters, fs => globalFilter.id === fs.id)),
    ].map(f => UtilsService.paramify(f));

    if (formData.schema !== EnvConfigService.getConfig().primaryEntity) {
      // single-retailer analysis mode: add a retailer filter with the corresponding schema as the filter value
      filters.push(UtilsService.paramify(new FilterSelection({
        id: CmsService.RETAILER_FIELD_ID,
        values: [formData.schema],
        include: true,
        nulls: false
      })));
    }

    const params: ColumnGroupParameters = {
      dimensions: dimensions,
      facts: facts,
      filters: filters,
      dateRanges: [dateRangeParam],
      withWeights: col.ref.aggType === AggregationType.WEIGHTED_AVERAGE
    };

    if (col.timeAggregates && col.timeAggregates.aggValue) {
      // Time Aggregates case

      const taDim: CmsField = CmsService.get().findEntity<CmsField>(col.timeAggregates.aggValue.key);
      dimensions.push(UtilsService.paramify(taDim)); // Add another dimension
      datasets.push(new AppDataset(RBMetricColumn.TIME_AGGREGATE, params)); // Push a new dataset with a special name

    } else {

      datasets.push(new AppDataset(RBMetricColumn.NORMAL_METRIC, params)); // Normal metric column

      if (col.yearOverYear) {
        // Year over year requires another dataset (last year's values)
        const lyArr = [RBMetricColumn.YEAR_OVER_YEAR.toString()];

        for (let opt of [
          YearOverYearEnum.THIS_YEAR,
          YearOverYearEnum.LAST_YEAR,
          YearOverYearEnum.LAST_YEAR_CHANGE,
          YearOverYearEnum.LAST_YEAR_PERCENT_CHANGE
        ]) {
          // Note the selected YoY options in the job name
          if (col.yearOverYear[opt]) {
            lyArr.push(opt);
          }
        }

        // TODO: Fix this! For Leap year case, this offset can change when DD report shifts 2/29.
        const offset = DatesService.getDifference(DateUnit.DAY)(formData.globalDateRange.begin, col.yearOverYear.compDateRange.begin);
        const offsetEnd = DatesService.getDifference(DateUnit.DAY)(formData.globalDateRange.end, col.yearOverYear.compDateRange.end);
        lyArr.push('offset:' + offset);

        let lyDateParam: DateRangeParameter;
        if (DatesService.isDynamic(formData.globalDateRange)) { // dynamic dates, add suffix to the dynamic date string
          const dynamicDateParam = DatesService.paramify(formData.globalDateRange);
          lyDateParam = {
            periodStart: DatesServiceCore.getDynamicDateWithWeekEndingString(dynamicDateParam.periodStart += ` -${offset} DAY`, formData.weekEndingday),
            periodEnd: DatesServiceCore.getDynamicDateWithWeekEndingString(dynamicDateParam.periodEnd += ` -${offsetEnd} DAY`, formData.weekEndingday)
          };
        } else { // static dates
          lyDateParam = DatesService.paramify(col.yearOverYear.compDateRange, formData.weekEndingday);
        }

        const lyParams: ColumnGroupParameters = {
          facts: facts,
          dimensions: dimensions,
          filters: filters,
          dateRanges: [lyDateParam],
          withWeights: params.withWeights,
        };

        datasets.push(new AppDataset(lyArr.join('|'), lyParams));
      }
    }

    let metaData = new Map<string, string>();
    metaData.set('analysisType', formData.getAnalysisType());

    return new AppRequest(datasets, null, metaData);
  }

  /*
   * This function returns a pure JSON object which can be posted via HTTP.
   * The metaData in columnRequests being a Map was causing issues which necessitated this.
   */
  public toJson(): object {
    let out: any = {};
    out.columnNames = this.columnNames;
    out.columnOrder = this.columnOrder;
    out.formValues = this.formValues;
    out.reportBuilderTitle = this.reportBuilderTitle;
    out.columnRequests = this.columnRequests.map(cr => cr.asJsonObject());
    return out;
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivityGridComponent, UsesActivityGrid } from 'app/activity/components/activity-grid/activity-grid.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { Router } from '@angular/router';
import { ActivityService } from 'app/activity/services/activity.service';
import { ActivityCriteriaGenerator } from 'app/activity/models/activity-criteria-generator';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { ApplicationHash, DateUnit } from '@siq-js/core-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { PromoCriteriaRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/promo-criteria-renderer/promo-criteria-renderer.component';
import { PromoListBubbleProcessor } from 'app/siq-applications/modules/promo/components/promo-list/processors/promo-list-simple-bubble-processor';
import {
  BubbleParams,
  ColDef,
  DatasetDetail,
  GridService,
  IStatResponse
} from '@siq-js/visual-lib';
import { PromoActivity } from 'app/siq-applications/modules/promo/models/promo-activity.model';
import { debounceTime, filter, map, takeUntil } from 'rxjs';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { KPI, PromoListDropdownMetrics, PromoPeriods } from 'app/siq-applications/modules/promo/models/promo.enums';
import { Activity } from 'app/activity/models/activity.model';
import * as _ from 'lodash';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { KpiModel } from 'app/siq-applications/modules/promo/models/interfaces';
import { StatResponse } from 'app/core/models/stat-response.model';
import { KpiRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/kpi-renderer/kpi-renderer.component';
import { DateRangeInterface, DateRangeInterfaceType, DateSelectionConfig } from 'app/siq-forms/modules/dates/models/interfaces';
import { DateSelectionComponent } from 'app/siq-forms/modules/dates/components/date-selection/date-selection.component';

@Component({
  templateUrl: './promo-list.component.html',
  styleUrls: ['./promo-list.component.scss']
})
export class PromoListComponent extends BaseSiqComponent implements OnInit, OnDestroy, UsesActivityGrid {
  @ViewChild(DateSelectionComponent) dateSelectorComponent: DateSelectionComponent;

  public actions = this.config.actions;
  public activeDataset$: BehaviorSubject<DatasetDetail> = new BehaviorSubject<DatasetDetail>(null);
  public activeDatasetData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public activeDatasetDimensions$: BehaviorSubject<CmsField[]> = new BehaviorSubject<CmsField[]>(null);
  public activeDatasetMetrics$: BehaviorSubject<CmsMetric[]> = new BehaviorSubject<CmsMetric[]>(null);
  public activeDatasetStatResponse$: BehaviorSubject<IStatResponse> = new BehaviorSubject<IStatResponse>(null);
  public activityStream$: BehaviorSubject<PromoActivity[]> = new BehaviorSubject([]);

  public appPath: string;
  public bubbleParams: BubbleParams;
  public bubbleContainerStyles = {height: PromoConfig.BUBBLE_CHART_CONTAINER_HEIGHT + 'px'};

  public bubbleProcessor = PromoListBubbleProcessor.processor;

  public tabs: ActivityTabKey[] = ActivityGridComponent.TABS
    .map(t => t.id)
    .filter(t => t !== ActivityTabKey.ALL_REPORTS && t !== ActivityTabKey.SCHEDULED);

  public callbacks: ActivityCallbacks = {
    rerun: activity => this.activityService.rerun(activity),
    clone: activity => {
      this.promoService.cloneReport(activity.getId());
    }
  };

  public columns: (tabKey: ActivityTabKey) => ColDef[];
  public datasetChanged$: Subject<void> = new Subject<void>();
  // config for date selector component
  public dateSelectionConfig: DateSelectionConfig = {
    primaryDate: {
      rangeMode: true,
      allowShortcuts: false,
      hideTooltip: true
    }
  };
  public dateFilterEnabled = false;
  public dateRanges: DateRangeInterface;

  // Metrics dropdown options
  public metrics: {
    items: { key: string, name: string, }[]
    name: string,
  }[];
  public promoTypeFilter: string;

  public promoTypes: string[];
  public promoTypesReady = false;

  // Dropdown selections
  public xMetric: string;
  public yMetric: string;
  public zMetric: string;

  private render$: Subject<void> = new Subject<void>();
  private renderedStream$: BehaviorSubject<PromoActivity[]> = new BehaviorSubject([]); // rendered promo activities

  constructor(
    public activityService: ActivityService,
    public config: PromoConfig,
    private router: Router,
    private promoService: PromoService,
    private datesService: DatesService,
  ) {
    super();
    this.columns = tabKey => ActivityCriteriaGenerator.generateColDefs(tabKey, [
      ...this.config.kpiOrder.map(kpi => KpiRendererComponent.getColDef(kpi)),
      PromoCriteriaRendererComponent.ColDef
    ]);
    this.activityService.getCurrentPageInfo().appId = ApplicationHash.PROMO;
    this.activityService.getCurrentPageInfo().tab = ActivityTabKey.MY_REPORTS;
    ActivityService.refresh();
  }

  ngOnInit(): void {
    this.appPath = ApplicationService.find(ApplicationHash.PROMO).path;

    this.createDropdowns();

    this.setupChart();

    this.promoService.getActivityTypeList()
      .subscribe(list => {
        this.promoTypes = list.types;
        this.promoTypesReady = true;
      });

    this.processStream(PromoService.Activities$)
      .subscribe(activities => {
        this.activityStream$.next(activities); // Emit the filtered list to activityStream$
      });

    // Subscription to the local activityStream$. All ops that are contained to this component should be performed here
    this.renderedStream$
      .pipe(takeUntil(this.unsub$))
      .subscribe(activities => {
        this.render(); // Draw the bubble chart
      });

    // Stream that triggers rendering the bubble chart (ie from state changes)
    this.render$
      .pipe(
        debounceTime(50),
        takeUntil(this.unsub$)
      )
      .subscribe(() => {
        this.prepareData();
      });

    this.activeDataset$
      .pipe(
        filter(d => !!d),
        takeUntil(this.unsub$)
      )
      .subscribe(dataset => {
        this.datasetChanged(dataset);
        this.datasetChanged$.next();
        this.ready(); // datasets ready
      });

    this.datesService.datePickerSchema$.next([]);
  }

  ngOnDestroy(): void {
    this.activityService.clearCurrentPageInfo();
  }

  public onClearDates() {
    this.dateSelectorComponent.setPrimaryDate(
      {
        begin: undefined,
        end: undefined,
        type: DateRangeInterfaceType.FORCE_VALID
      }
    );
    this.updateListFilter();
  }

  // Publicly exposed function for triggering redrawing of the bubble chart
  public render() {
    this.render$.next();
  }

  public toForm() {
    this.router.navigate([`${this.appPath}/~`]);
  }

  // This fn is fired whenever the date selector changes. It always fires once in the beginning
  public updateDateFilter(dates: DateRangeInterface) {
    if (dates && dates.begin && dates.end) {
      this.dateRanges = dates;
      this.dateFilterEnabled = true;
    } else {
      this.dateRanges = undefined;
      this.dateFilterEnabled = false;
    }
    this.updateListFilter();
  }

  // refresh list of activities
  public updateListFilter() {
    PromoService.Activities$.next(PromoService.Activities$.getValue());
  }
  public updateRenderedStream(activities: Activity[]) {
    this.renderedStream$.next(activities as PromoActivity[]);
  }

  private createDropdowns() {
    this.metrics = this.config.kpiOrder.map(kpi => {
      const kpiName = PromoConfig.kpiNameMap[kpi];
      return {
        name: kpiName,
        items: _.values(PromoListDropdownMetrics).map(metric => {
          return {
            name: `${metric} (${kpiName})`,
            key: `${kpi}|${metric}`
          };
        })
      };
    });
    this.xMetric = this.metrics[5].items[2].key; // Avg Price % chg
    this.yMetric = this.metrics[1].items[2].key; // Units % chg
    this.zMetric = this.metrics[0].items[1].key; // Dollars chg

    this.promoTypeFilter = null;
  }

  private datasetChanged(dataset: DatasetDetail) {
    this.activeDatasetMetrics$.next(dataset.statResponse.facts);
    this.activeDatasetDimensions$.next(dataset.statResponse.dimensions);
    this.activeDatasetData$.next(dataset.data);
    this.activeDatasetStatResponse$.next(dataset.statResponse);

  }

  private generateFact(metricKey: string, newId: string): CmsMetric {
    const kpi = metricKey.split('|')[0];
    const metric = metricKey.split('|')[1] as PromoListDropdownMetrics;
    const kpiName = PromoConfig.kpiNameMap[kpi];
    const factName = `${metric} (${kpiName})`;

    let type: string;

    switch (metric) {
      case PromoListDropdownMetrics.PERCENT_DELTA:
        type = 'PERCENT';
        break;
      case PromoListDropdownMetrics.DELTA:
      case PromoListDropdownMetrics.PROMO:
        switch (kpiName) {
          case PromoConfig.kpiNameMap[KPI.TOTAL_AMOUNT]:
            type = 'DOLLAR_SALES';
            break;
          case PromoConfig.kpiNameMap[KPI.AVG_PRICE]:
          case PromoConfig.kpiNameMap[KPI.BASKET_TOTAL_AMOUNT]:
            type = 'DOLLAR';
            break;
          default:
            type = 'VOLUME';
            break;
        }
        break;
    }

    return new CmsMetric({
      id: newId,
      display: factName,
      active: true,
      aggType: null,
      type: type
    });

  }

  private getKPIMetricVal(kpiModel: KpiModel, metricKey: string): number {
    const kpi = metricKey.split('|')[0];
    const metric = metricKey.split('|')[1] as PromoListDropdownMetrics;
    const kpiVal = {
      PRE: { val: kpiModel['PROMO'][kpi].PRE },
      PROMO: { val: kpiModel['PROMO'][kpi].PROMO }
    };

    let val: number;

    switch (metric) {
      case PromoListDropdownMetrics.DELTA:
        val = GridService.generateDeltaGetter(PromoPeriods.PROMO, PromoPeriods.PRE)(kpiVal as any).val;
        break;
      case PromoListDropdownMetrics.PERCENT_DELTA:
        val = GridService.generatePercentDeltaGetter(PromoPeriods.PROMO, PromoPeriods.PRE)(kpiVal as any).val;
        break;
      case PromoListDropdownMetrics.PROMO:
        val = kpiVal.PROMO.val;
        break;
    }

    return val;
  }

  // process data for the bubble chart
  private prepareData() {
    const activities = this.renderedStream$.getValue();
    const facts = [
      this.generateFact(this.xMetric, 'X_METRIC'),
      this.generateFact(this.yMetric, 'Y_METRIC'),
      this.generateFact(this.zMetric, 'Z_METRIC')
    ];

    const dims = [new CmsField({
      id: 'PROMO_DIM',
      display: 'PROMO',
      active: true,
      retailer: '',
      table: '',
      field: '',
      filter: '',
      type: ''
    })];

    const dM = [[]];
    const vM = [];

    activities.filter(a => a.kpiModel).forEach(a => {
      const x = this.getKPIMetricVal(a.kpiModel, this.xMetric);
      const y = this.getKPIMetricVal(a.kpiModel, this.yMetric);
      const z = this.getKPIMetricVal(a.kpiModel, this.zMetric);

      if (_.isFinite(x) && _.isFinite(y) && _.isFinite(z)) {
        vM.push([dM[0].length, x, y, z]);
        dM[0].push(a);
      }
    });

    const sr = new StatResponse(dims, dM, facts, vM, []);
    const data = GridService.jobToArray(sr);
    const dataset: DatasetDetail = {
      data: data,
      statResponse: sr
    };

    this.activeDataset$.next(dataset);

  }

  // Applies a couple piped operations on an activity stream.
  private processStream(bs: Observable<PromoActivity[]>): Observable<PromoActivity[]> {
    return bs.pipe(
      filter(activities => !!activities), // nil check
      map(activities => {
        if (this.promoTypeFilter) { // promo type filter
          activities = activities.filter(a => a.promoType === this.promoTypeFilter);
        }

        if (this.dateFilterEnabled && this.dateRanges) { // date-range filter
          const minDate = this.dateRanges.begin;
          const maxDate: Date = this.dateRanges.end;
          activities = activities.filter(a => {
            const dr = a.getDateRange(PromoPeriods.PROMO);
            return (DatesService.isBefore(minDate, dr.begin) || DatesService.getIsSame(DateUnit.DAY)(minDate, dr.begin)) && (DatesService.isAfter(maxDate, dr.end) || DatesService.getIsSame(DateUnit.DAY)(maxDate, dr.end));
          });
        }

        return activities;
      }),
      takeUntil(this.unsub$)
    );
  }

  private setupChart() {
    this.bubbleParams = {
      parent: this,
      rawData: this.activeDatasetData$,
      dimensions: this.activeDatasetDimensions$,
      metrics: this.activeDatasetMetrics$,
      datasetChanged$: this.datasetChanged$,
      statResponse: this.activeDatasetStatResponse$,
      router: this.router,
      activityStream$: this.activityStream$,
      activityService: this.activityService
    };
  }
}

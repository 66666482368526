import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ColDef } from '@siq-js/visual-lib';
import { FavoriteRendererComponent } from 'app/activity/components/renderers/favorite-renderer/favorite-renderer.component';
import { NameRendererComponent } from 'app/activity/components/renderers/name-renderer/name-renderer.component';
import { StatusRendererComponent } from 'app/activity/components/renderers/status-renderer/status-renderer.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { CreatedRendererComponent } from 'app/activity/components/renderers/created-renderer/created-renderer.component';
import { ActionsRendererComponent } from 'app/activity/components/renderers/actions-renderer/actions-renderer.component';
import { CheckboxRendererComponent } from 'app/core/components/cell-renderers/checkbox-renderer/checkbox-renderer.component';
import { ActivityCheckboxHeaderRendererComponent } from 'app/activity/components/renderers/activity-checkbox-header-renderer/activity-checkbox-header-renderer.component';

export class ActivityCriteriaGenerator {
  public static generateColDefs(key: ActivityTabKey, innerColDefs: ColDef[]): ColDef[] {
    const colDefs = [
      FavoriteRendererComponent.ColDef,
      NameRendererComponent.ColDef,
      StatusRendererComponent.ColDef
    ];

    switch (key) {
      case ActivityTabKey.SCHEDULED:
        colDefs.push(SharingService.SchedulingColDef);
        break;
      case ActivityTabKey.SHARED:
        colDefs.push(SharingService.SharingColDef);
        break;
      default:
        colDefs.unshift(CheckboxRendererComponent.GenerateColDef({header: ActivityCheckboxHeaderRendererComponent}));
    }

    colDefs.push(...[
      ...innerColDefs,
      CreatedRendererComponent.ColDef,
      ActionsRendererComponent.ColDef
    ]);

    return colDefs;
  }
}

import { BehaviorSubject, Observable } from 'rxjs';

export class RequestBusItem<T> {

  public results: any;
  public shared: BehaviorSubject<any>;
  public subscribed = false;

  constructor(
    public id: string,
    private value: Observable<any>
  ) {
    this.shared = new BehaviorSubject(undefined);
  }

  public execute(): BehaviorSubject<T> {

    if (!this.subscribed) {

      this.subscribed = true;

      this.value.subscribe(res => {

        this.results = res;

        this.shared.next(res);

      });

    }

    return this.shared;

  }

}

<button mat-raised-button type="submit" [disabled]="disabled || submitting" color="{{ color }}" class="submit">
  <span
    class="copy submitting"
    [ngClass]="{ 'submitting' : submitting }"
  >
    {{ copy }}
    <mat-spinner *ngIf="submitting" strokeWidth="3" [diameter]="24" color="{{ spinnerColor }}"></mat-spinner>
  </span>

</button>

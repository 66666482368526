import { ApplicationHash } from '@siq-js/core-lib';
import { CmsApplicationJson } from './cms-application';

export const defaultApplicationSettings: CmsApplicationJson[] = [
  {
    id: ApplicationHash.REPORT_BUILDER,
    display: 'Report Builder',
    active: true,
    description: 'Create reports to track common business questions',
    color: '#2e7d32',
    path: 'app/report-builder'
  },
  {
    id: ApplicationHash.AFFINITIES,
    display: 'Affinities',
    active: true,
    description: 'Explore baskets for unique funding opportunities',
    color: '#5bb6ff',
    path: 'app/affinities'
  },
  {
    id: ApplicationHash.DAYPARTS,
    display: 'Dayparts',
    active: true,
    description: 'Discover time of day items are selling',
    color: '#f7a61c',
    path: 'app/dayparts'
  },
  {
    id: ApplicationHash.INCREMENTALS,
    display: 'Assortment',
    active: true,
    description: 'Formerly Incrementals - Analyze your product offering and incremental basket value',
    color: '#0072bc',
    path: 'app/assortment'
  },
  {
    id: ApplicationHash.PROMO,
    display: 'Activity Measurement',
    active: true,
    description: 'Formerly Promotion Measurement — Measure impact of in-store activities like promotions, new products, and more',
    color: '#06409e',
    path: 'app/measurement'
  }
];

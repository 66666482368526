import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { CmsService } from 'app/core/services/cms/cms.service';
import { DateRangeInterface, DateRangeInterfaceJson } from 'app/siq-forms/modules/dates/models/interfaces';
import { AppFormData, AppFormJson } from 'app/siq-applications/modules/shared/models/app-form-data.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

export interface DaypartsFormJson extends AppFormJson {
  dateRanges: DateRangeInterfaceJson;
  filters: FilterSelectionJson[];
  isCloned: boolean;
  name: string;
}

export class DaypartsFormData extends AppFormData<DaypartsFormJson> {
  public dateRanges: DateRangeInterface; // current date value
  public filters: FilterSelection[] = [];
  public isCloned: boolean; // used to decide whether to initialize the form
  public name: string;
  public schema: string;

  constructor() {
    super();
    this.weekEndingday = this.datesService.getDefaultWeekEndingDay();
  }

  public isValidSchema(schema: string): boolean {

    for (let fs of this.filters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
      // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        if (fs.values.find(val => !retailers.includes(val))) return false;
      }
    }
    return true;
  }

  public toJson(): DaypartsFormJson {
    const json: DaypartsFormJson = {
      dateRanges: DatesService.isDynamic(this.dateRanges)
        ?
        { // dynamic dates
          begin: DatesService.dateMStoS(this.dateRanges.begin.getTime()), // translated dates in begin & end are needed to display
          end: DatesService.dateMStoS(this.dateRanges.end.getTime()),
          dynamicBegin: this.dateRanges.dynamicBegin,
          dynamicEnd: this.dateRanges.dynamicEnd
        }
        :
        { // static dates
          begin: DatesService.dateMStoS(this.dateRanges.begin.getTime()),
          end: DatesService.dateMStoS(this.dateRanges.end.getTime()),
        },
      filters: this.filters.map(f => f.toJson()),
      isCloned: this.isCloned,
      name: this.name,
      schema: this.schema,
      weekEndingDay: this.weekEndingday
    };
    return json;
  }
}

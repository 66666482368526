import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/services/auth.service';
import { Router, Routes } from '@angular/router';
import { User } from 'app/user/models/user.model';
import { NavService } from 'app/core/services/nav/nav.service';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';
import { takeUntil } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'siq-nav-profile',
  templateUrl: './nav-profile.component.html',
  styleUrls: ['./nav-profile.component.scss']
})
export class NavProfileComponent extends BaseSiqComponent implements OnInit {

  public currentUser: User;
  public isInternalUser: boolean;
  public routes: Routes = [];

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    super();
  }

  logout() {
    this.authService.logout()
      .subscribe(() => this.router.navigate(['/login']));
  }

  async ngOnInit() {
    AuthService.CurrentUser$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((user: User) => {
      this.currentUser = user;
      this.setup();
    });
  }

  public toggleTheme(e: MatSlideToggleChange) {
    ThemesService.setTheme(e.checked ? ThemesService.THEMES.DARK : ThemesService.THEMES.LIGHT);
  }

  private async setup() {
    this.isInternalUser = this.currentUser?.isInternal();
    const isAdmin: boolean = await this.currentUser?.isAdmin();

    this.routes = NavService.getProfileMenuRoutes().filter(r => {
      if (isAdmin) return true;

      let adminsOnly = false;
      r.canActivate.forEach(guard => {
        if (adminsOnly) return;
        if (guard.name === 'AccessGroupAdminGuard') {
          adminsOnly = true;
        }
      });
      return !adminsOnly;
    });
  }
}

import { Activity } from 'app/activity/models/activity.model';
import { Component, Input } from '@angular/core';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'siq-activity-details-submit',
  templateUrl: './activity-details-submit.component.html',
  styleUrls: ['./activity-details-submit.component.scss']
})
export class ActivityDetailsSubmitComponent {

  @Input() title: string;
  @Input() hideSubmit = false;
  @Input() submitDisabled: Boolean;
  @Input() nameFormControl: UntypedFormControl;
  @Input() metaFormControl: UntypedFormControl;
  @Input() submitting = false;
  @Input() form: UntypedFormGroup;
  @Input() inputActivity: Activity;
  @Input() hideReset = false;

  constructor() { }

  clearForm(): void {
    EmitterService.get('app-form:clear').emit();
  }

  resetForm(): void {
    EmitterService.get('app-form:reset').emit();
  }
}

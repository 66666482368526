import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiqError } from 'app/core/models/siq-error.model';

@Component({
  selector: 'siq-js-error-overlay',
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss']
})
export class ErrorOverlayComponent implements OnInit {
  @Input() reportActivityName: string;
  @Input() appErrorMessage: SiqError;
  @Input() menuItems: string[];
  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}

<siq-app-header [config]="config"></siq-app-header>

<siq-query-mode #queryMode [disabled]="!formMode" [modalConfig]="queryModeModalConfig"></siq-query-mode>

<div class="sheets-container" *ngIf="ready$ | async">

  <div class="report-builder-toolbar">
    <mat-form-field floatLabel="never" color="accent">
      <input matInput [(ngModel)]="reportName" (change)="sync()" placeholder="Untitled Report" [disabled]="readonly" class="report-name"/>
    </mat-form-field>
    <div class="actions">
      <ng-container *ngIf="formMode">
        <button *ngIf="activeSheet && !activeSheet.draft"
                (click)="setActiveSheet(activeSheet, false)"
                class="back-to-results" mat-icon-button
                matTooltip="Go back to the table (your changes will be lost)">
          <mat-icon>undo</mat-icon>
        </button>
        <button mat-raised-button class="run-query-button" color="accent" (click)="runAllDrafts()" *ngIf="isRunAllDraftsVisible()" [disabled]="sheetForm?.model.running || !sheetForm?.model.valid">
          Run All Drafts
        </button>
        <button mat-raised-button class="run-query-button" color="accent" (click)="formSubmit()" [disabled]="!sheetForm?.model.valid || sheetForm?.model.running">
          <ng-container *ngIf="!activeSheet || activeSheet.draft">Add</ng-container>
          <ng-container *ngIf="activeSheet && !activeSheet.draft">Save</ng-container>
        </button>
      </ng-container>

      <ng-container *ngIf="!formMode">
        <siq-js-activity-grid-options-menu
          (clickEvent)="handleOptionMenuClick($event)"
          [config]="activeSheetOptionsConfig"
        ></siq-js-activity-grid-options-menu>
      </ng-container>
    </div>
  </div>

  <div class="sheet-content" *ngIf="activeSheet">
    <ng-container *ngFor="let sheet of sheets">
      <siq-js-grid
        *ngIf="sheet.ready"
        [ngClass]="{'active': activeSheet === sheet && !formMode, 'cursor-pointer-drill': canDrill}"
        [config]="sheet.gridVisualOptions.gridConfig"
        [data]="sheet"
        [processor]="processor"
        (featureUsed)="gridFeatureUsed($event)"
      ></siq-js-grid>
    </ng-container>

    <!-- OVERLAYS -->
    <ng-container *ngIf="!activeSheet.draft && !formMode" [ngSwitch]="activeSheet.status">
      <div class="error-overlay" *ngSwitchCase="statusEnums.ERROR">
        <mat-icon>error</mat-icon>
        <p class="main">
          Error Loading Report
        </p>
        <p>
          {{ activeSheet.errorMessage }}
        </p>
      </div>

      <div *ngIf="!formMode">
        <div class="access-group-changed-overlay" *ngSwitchCase="statusEnums.ALERT">
          <mat-icon>priority_high</mat-icon>
          <p class="main">The Access Group for this sheet has been modified, you can still edit or clone this sheet</p>
        </div>
      </div>

      <ng-container *ngSwitchDefault>
        <div class="loading-overlay" *ngIf="!activeSheet.ready">
          <mat-progress-spinner color="accent" mode="indeterminate" *ngIf="activeSheet.status !== statusEnums.ERROR"></mat-progress-spinner>
          <ng-container *ngIf="activeSheet.status === statusEnums.RUNNING">
            <p class="main">
              Running <b>{{ activeSheet.name }}</b>...
            </p>
            <p class="sub" *ngIf="!activeSheet.activity.complete">This sheet is still running - you will be notified when it completes</p>
          </ng-container>

          <ng-container *ngIf="activeSheet.status === statusEnums.READY">
            <p class="main">
              Loading <b>{{ activeSheet.name }}</b>...
            </p>
            <p class="sub" *ngIf="activeSheet.activity.complete">{{ activeSheet.model.loadedActivities }} out of {{ activeSheet.model.totalActivities }} column groups loaded</p>
          </ng-container>

          <ng-container *ngIf="activeSheet.status === statusEnums.PREVIEW_RUNNING">
            <p class="main">
              Preview Running...
            </p>
            <p class="sub">
              The whole report was too big to load, and a preview is being generated. Consider <a (click)="setActiveSheet(activeSheet, true)">editing the report</a>
            </p>
          </ng-container>

          <ng-container *ngIf="activeSheet.status === statusEnums.PREVIEW_READY">
            <p class="main">
              Preview Loading...
            </p>
            <p class="sub">
              Loading the results of your preview
            </p>
          </ng-container>
        </div>

        <div class="preview-overlay" *ngIf="!formMode && activeSheet.ready && activeSheet.status === statusEnums.PREVIEW_READY">
          <mat-icon>visibility</mat-icon>
          <p class="main">Preview Mode</p>
          <p class="sub">
            The full report was too big to load, consider
            <a (click)="setActiveSheet(this.activeSheet, true)">editing the report</a>
          </p>
        </div>

      </ng-container>
    </ng-container>

  </div>

  <div class="draft-container" [ngClass]="{hidden: (!formMode)}">
    <siq-report-builder-form
      #sheetForm
      [parent]="this"
      [schemaController$]="formSchemaController$"
    ></siq-report-builder-form>
  </div>

  <div class="sheets-footer no-select">

    <div class="sync-info">
      <ng-container *ngIf="readonly">
        <mat-icon matTooltip="This does not belong to you, and is in read-only mode" class="disabled">sync_disabled</mat-icon>
      </ng-container>
      <ng-container *ngIf="!readonly">
        <mat-icon *ngIf="!model.syncing"
                  (mouseenter)="updateLastSyncedTxt()"
                  [matTooltip]="lastSyncedTxt"
                  (click)="model.syncing = true; sync()">sync</mat-icon>
        <mat-progress-spinner *ngIf="model.syncing" [diameter]="24" color="accent" mode="indeterminate"></mat-progress-spinner>
      </ng-container>
    </div>

    <div class="sheet-label" *ngFor="let sheet of sheets; let i = index"
         [ngClass]="{active: this.activeSheet === sheet, draft: sheet.draft}"
         [ngStyle]="{'z-index': sheets.length - i}"
         (click)="setActiveSheet(sheet)">

      <visual-lib-progress-bar
        [color]="sheet.gc?.asyncTotalsComponent.config.progressIndicatorColorType"
        [mode]="sheet.gc?.asyncTotalsComponent.config.progressIndicatorType"
        [value]="sheet.gc?.asyncTotalsComponent.config.progressValue"
        [bufferValue]="sheet.gc?.asyncTotalsComponent.config.progressBufferValue"
        [isLoading]="sheet.gc?.asyncTotalsComponent.asyncTotalsLoading$.value"
        class="async-totals-loading"
      ></visual-lib-progress-bar>

      <ng-container *ngIf="model.sheetName !== undefined && activeSheet === sheet; else default">
        <input #sheetNameInput type="text"
               class="sheet-name-input"
               [maxLength]="32"
               [(ngModel)]="model.sheetName"
               (change)="sheet.updateName(model.sheetName)"
               (focusout)="sheet.updateName(model.sheetName)"/>
      </ng-container>

      <ng-template #default>
        <label *ngIf="sheet.draft" (click)="sheet.editName($event)">{{ sheet.data.name }} (Draft)</label>
        <label *ngIf="!sheet.draft" (click)="sheet.editName($event)"> {{ sheet.name || 'Untitled Sheet' }}</label>
        <label class="sheet-unread"
               *ngIf="(sheet.status === statusEnums.READY || sheet.status === statusEnums.PREVIEW_READY) && sheet.model && sheet.model.new"
        > &#9679;</label>
      </ng-template>

      <mat-icon *ngIf="sheet.error; else loadingSpinner" class="error">error_outline</mat-icon>
      <mat-progress-spinner
        #loadingSpinner *ngIf="!sheet.error && (sheet.status === statusEnums.PREVIEW_RUNNING || sheet.status === statusEnums.RUNNING)"
        color="accent" [diameter]="20" mode="indeterminate"></mat-progress-spinner>

      <mat-menu #sheetMenu="matMenu">
        <button mat-menu-item *ngIf="!sheet.draft || (sheet.draft && sheet.status === DRAFT_ALERT)" (click)="setActiveSheet(sheet, true)">Edit</button>
        <button mat-menu-item (click)="cloneSheet(sheet)">Clone</button>
        <button mat-menu-item (click)="deleteSheet(sheet)" [disabled]="!sheet.draft && !model.canDelete">Delete</button>
      </mat-menu>
      <mat-icon class="sheet-menu-btn" *ngIf="!readonly" [matMenuTriggerFor]="sheetMenu" (click)="$event.stopPropagation()">more_vert</mat-icon>
    </div>

    <div class="sheet-label add-sheet" *ngIf="!readonly" (click)="addBlankDraft()">
      <p><mat-icon>add</mat-icon></p>
    </div>

    <span class="report-desc" *ngIf="activeSheet?.ready && model?.numCols && model?.numRows">

      {{ model.numCols }} Columns x {{ model.numRows }} Rows

    </span>

  </div>
</div>

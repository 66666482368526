import { Component, Inject, OnInit } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { FiltersetGridConfig } from 'app/filtersets/models/interfaces';
import { FiltersetResponse } from 'app/filtersets/models/filterset-response.model';
import { FiltersetsService } from 'app/filtersets/services/filtersets.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CmsField } from '@siq-js/cms-lib';
import { FilterService } from 'app/filter/services/filter.service';

@Component({
  selector: 'siq-js-load-filterset-modal',
  templateUrl: './load-filterset-modal.component.html',
  styleUrls: ['./load-filterset-modal.component.scss']
})
export class LoadFiltersetModalComponent extends BaseSiqComponent implements OnInit {

  public filtersetGridConfig: FiltersetGridConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filtersetsService: FiltersetsService,
    private dialogRef: MatDialogRef<LoadFiltersetModalComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    let availableFilters: CmsField[] = FilterService.getAvailableFilters(this.data.currentSchema);
    if (this.data.mapFn) availableFilters = this.data.mapFn(availableFilters);
    this.filtersetGridConfig = {
      parent: this,
      forSelection: true,
      availableFilterIds: availableFilters.map(f => f.id)
    };
  }

 /*
 This function is triggered from filtersets-grid onRowClicked().
 The modal is opened by loadFilterset() from app/filter/components/filter-group/filter-group.component.ts.
 The row-clicked filterset are merged to current filters in loadFilterset().afterClosed().subscribe()
 */
  public closeModal(fs: FiltersetResponse) {
    this.dialogRef.close(this.filtersetsService.getFilerSelectionsFromFiltersets(fs.filterSets.filter(f => f.allowed)));
  }
}

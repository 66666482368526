import { GroupedArray } from '@siq-js/core-lib';
import { CmsConfig, CmsConfigJson } from '../models/cms-config';
import { CmsEntityJson } from '../models/cms-entity';
import { CmsKeys } from '../models/cms-keys';

export function mergeCmsConfig(defaults: CmsConfigJson, overrides: CmsConfigJson): CmsConfig {
  const configPairing = {
    [CmsKeys.APPLICATIONS_ORGANIZE]: CmsKeys.APPLICATIONS_CONFIG,
    [CmsKeys.FIELDS_ORGANIZE]: CmsKeys.FIELDS_CONFIG,
    [CmsKeys.METRICS_ORGANIZE]: CmsKeys.METRICS_CONFIG
  };

  for (const k in defaults) {
    switch (k) {
      case CmsKeys.APPLICATIONS_CONFIG:
      case CmsKeys.FIELDS_CONFIG:
      case CmsKeys.METRICS_CONFIG:
        // Merges all properties in the overrides.json into their respective entity in defaults.json

        const overridesArr: CmsEntityJson[] = overrides[k] || [];
        const defaultsArr: CmsEntityJson[] = defaults[k];

        // use a map to efficiently lookup overrides by IDs
        const _map: any = {};
        overridesArr.forEach(override => _map[override.id] = override);

        // iterate thru all default data and apply overrides (if they exist)
        defaultsArr.forEach(entity => {
          if (!_map[entity.id]) return;
          Object.assign(entity, _map[entity.id]);
        });

        break;
      case CmsKeys.METRICS_ORGANIZE:
      case CmsKeys.FIELDS_ORGANIZE:
      case CmsKeys.APPLICATIONS_ORGANIZE:
        // Determines ordering of entities. All specified order items go first, then all un-specified entities are appended at the bottom, alphabetically

        const configKey = configPairing[k];
        const ordered: GroupedArray<any> = overrides[k] || [];
        const allEntities = defaults[configKey] as CmsEntityJson[];

        // use set for quick lookup
        const set = new Set<string>();

        // populate set with all IDs from order overrides array
        ordered.forEach(orderItem => {
          if (typeof orderItem === 'string') {
            set.add(orderItem);
          } else {
            orderItem.children.forEach(child => set.add(child));
          }
        });

        // append all non-existant IDs to the end, sorted alphabetically by ID
        const unordered: string[] = [];
        allEntities.forEach(entity => {
          if (!set.has(entity.id)) {
            unordered.push(entity.id);
          }
        });

        // concat overrides order & un-ordered defaults
        defaults[k] = ordered.concat(unordered.sort());
        break;
    }
  }

  return new CmsConfig(defaults);
}

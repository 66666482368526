<div class="password-rules">
  <p>Your password must meet the following requirements:</p>
  <ul>
    <li [ngClass]="passwordRules.uppercase ? 'text-success' : 'text-warn'">At least one upper case letter</li>
    <li [ngClass]="passwordRules.lowercase ? 'text-success' : 'text-warn'">At least one lower case letter</li>
    <li [ngClass]="passwordRules.number ? 'text-success' : 'text-warn'">At least one number</li>
    <li [ngClass]="passwordRules.special ? 'text-success' : 'text-warn'">At least one special character (!@#$&%*-)</li>
    <li [ngClass]="passwordRules.eightLength ? 'text-success' : 'text-warn'">At least eight (8) characters long</li>
    <li [ngClass]="passwordRules.space ? 'text-success' : 'text-warn'">Cannot start or end with a space</li>
    <li [ngClass]="passwordRules.allowedChars ? 'text-success' : 'text-warn'">Can only contain letters, numbers, and the following: !@#$&%*-</li>
    <li [ngClass]="passwordRules.passwordsMatch ? 'text-success' : 'text-warn'">Both passwords Match</li>
  </ul>
</div>

<siq-app-header [config]="config"></siq-app-header>

<!-- Spinning wheel -->
<div class="loading-overlay" *ngIf="showSpinner"><mat-progress-spinner color="accent" mode="indeterminate" ></mat-progress-spinner></div>

<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="statusEnums.ALERT">

    <siq-js-alert-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="errorAndInvalidOptionMenuItems"
      [reportActivityName]="applicationName"
    ></siq-js-alert-overlay>

  </div>

  <div *ngSwitchCase="statusEnums.ERROR">

    <siq-js-error-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [appErrorMessage]="reportActivity.errorsDetails[0]"
      [reportActivityName]="applicationName"
      [menuItems]="errorAndInvalidOptionMenuItems"
    ></siq-js-error-overlay>

  </div>

  <ng-container *ngSwitchDefault>

    <!-- Query mode "Analysis Mode" -->
    <siq-query-mode #queryMode [disabled]="true"></siq-query-mode>
    <!-- Header with "Option" -->
    <siq-activity-results
      [suppressOptions]="false"
      [hideHeader]="false"
      [isReady] = "ready$ | async"
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="customOptionMenuItems"
    >
    </siq-activity-results>

    <div *ngIf="ready$ | async">

      <mat-grid-list cols="2" gutterSize="5" rowHeight="400px">

        <mat-grid-tile colspan="1" rowspan="1">
          <siq-js-simple-line
            *ngIf="simpleLineParamsHourly"
            [config]="simpleLineConfigHourly"
            [data]="simpleLineParamsHourly"
            [processor]="simpleLineProcessor"
          ></siq-js-simple-line>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <siq-js-simple-line
            *ngIf="simpleLineParamsDaily"
            [config]="simpleLineConfigDaily"
            [data]="simpleLineParamsDaily"
            [processor]="simpleLineProcessor"
          ></siq-js-simple-line>
        </mat-grid-tile>

      </mat-grid-list>

      <div class="grid-header">
        <div>
          <button
            mat-raised-button
            (click)="toggleDimensions()"
          >Swap Dimensions
          </button>
        </div>

        <div>
          <div *ngIf="grid">
            <siq-js-heatmap-selector
              [gridComponent]="grid"
              [inlineLegend]="true"
            ></siq-js-heatmap-selector>
          </div>
        </div>
      </div>

      <siq-js-grid
        *ngIf="ready$ | async"
        [config]="gridParams.gridVisualOptions.gridConfig"
        [data]="gridParams"
        [processor]="gridProcessor"
        (featureUsed)="gridFeatureUsed($event)"
      ></siq-js-grid>

    </div>

  </ng-container>

</ng-container>

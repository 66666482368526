import * as _ from 'lodash';
import { Activity } from 'app/activity/models/activity.model';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';
import { EmailWhiteList } from 'app/activity/modules/sharing/models/email-white-list.interface';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseType } from '@siq-js/core-lib';
import { NotificationService } from '@siq-js/angular-buildable-lib';
import { SharingModalComponent } from 'app/activity/modules/sharing/components/sharing-modal/sharing-modal.component';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { ColDef } from '@siq-js/visual-lib';
import { SharedReport } from 'app/activity/models/interfaces';
import { ActivityService } from 'app/activity/services/activity.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { AccessGroupService } from 'app/access-group/services/access-group.service';

export enum ShareScheduleEndpoints {
  POST_NEW_SHARED_ACTIVITY = 'reports',
  PUT_SHARED_ACTIVITY = 'reports',
}

export interface SharingModalData {
  activity: Activity,
  share: Activity,
  schedule: Activity,
}

@Injectable()
export class SharingService {

  public static WhiteList: EmailWhiteList;
  // public static Activities$: BehaviorSubject<Activity[]>; // REMOVED during ICE-4342. Now the /report-info/all endpoint doesn't return shared/scheduled activities belonging to an activity anymore.

  public static SharingColDef: ColDef = {
    headerName: 'Shared By',
    width: 100,
    resizable: false,
    valueGetter: params => {
      const user = (params.data as Activity).getSiteUser();
      return `${user.firstName} ${user.lastName}`;
    },
    pinned: 'right'
  };

  public static SchedulingColDef: ColDef = {
    headerName: 'Scheduled By',
    width: 120,
    resizable: false,
    valueGetter: params => {
      const user = (params.data as Activity).getSiteUser();
      return `${user.firstName} ${user.lastName}`;
    },
    pinned: 'right'
  };

  public static getWhiteList(): EmailWhiteList {
    if (_.isEmpty(this.WhiteList)) {
      this.WhiteList = {
        // TODO: this is old CMS service - need to fix
        // domains: _.map(_.get(CmsService.find({key: 'WHITELIST_DOMAINS'}), 'value.items'), domain => _.toLower(domain)),
        // addresses: _.map(_.get(CmsService.find({key: 'WHITELIST_ADDRESSES'}), 'value.items'), address => _.toLower(address))
      };
    }

    return this.WhiteList;
  }

  constructor(
    private siqHttpService: SiqHttpService,
    private utilsService: UtilsService,
    private notificationService: NotificationService
  ) {}

  public create(data: Object, suppressNotification = true): Observable<HttpResponse<any>> {
    const endpoint = ShareScheduleEndpoints.POST_NEW_SHARED_ACTIVITY;
    return this.siqHttpService.create({
      endpoint: endpoint,
      body: data,
      suppressNotification: suppressNotification,
      additionalConfig: {
        responseType: ResponseType.TEXT
      }
    });
  }

  public update(reportDtoId: string, data: Object, suppressNotification = true): Observable<HttpResponse<any>> {
    const endpoint = ShareScheduleEndpoints.PUT_SHARED_ACTIVITY + '/' + reportDtoId;
    return this.siqHttpService.update({
      endpoint: endpoint,
      body: data,
      suppressNotification: suppressNotification,
      additionalConfig: {responseType: ResponseType.TEXT}
    });
  }

  // Open Sharing/Scheduling Modal when share button clicked
  public share(activity: Activity): void {
    this.siqHttpService.get({
      endpoint: `${ActivityService.REPORTS_ENDPOINT}/shared-report-details/${activity.id}`,
      suppressNotification: true,
    })
      .pipe(
        catchError(err => {
          console.error(`Failed to get sharing details for activity ${activity.id}`, err);
          return of(null);
        })
      )
      .subscribe((report: SharedReport) => {
        // modalData will be used to auto-fill form values from last request
        const temp = new Activity();
        temp.sharedReport = report;
        const modalData = {
          activity: activity,
          share: temp.isShared() ? temp : null,
          schedule: temp.isScheduled() ? temp : null,
        } as SharingModalData;

        const modalConfig: MatDialogConfig = _.cloneDeep(UtilsService.MODAL_CONFIG_MEDIUM);
        modalConfig.panelClass = 'share-schedule'; // css class is set in sharing-modal.component.scss
        this.utilsService
          .openModal(SharingModalComponent, modalData, modalConfig)
          .afterClosed()
          .subscribe(dialogRes => {
            if (dialogRes && dialogRes.callback instanceof Observable) {
              dialogRes.callback.subscribe({
            next: (res) => {
                  if (res instanceof HttpResponse && res.status >= 200 && res.status <= 299) {
                    this.notificationService
                      .success(`Your report was successfully ${dialogRes.sharedType}d`, dialogRes.title);
                    const internalEmailsSet = new Set(AccessGroupService.getAccessGroupUsers()
                      .filter((user) => user.active)
                      .map((user) => user.email));
                    const internalEmailsCount = dialogRes.recipients.filter((email) => internalEmailsSet.has(email)).length;
                    UtilsService.trackEvent(MixpanelEvent.ACTIVITY_SHARED, {
                      'Application': activity.getApplicationName(),
                      'Activity ID': activity.getId(),
                      'Recipient Emails': dialogRes.recipients,
                      'Number of Recipients': dialogRes.recipients.length,
                      'Internal Recipients': internalEmailsCount,
                      'External Recipients': dialogRes.recipients.length - internalEmailsCount,
                      'Share/Schedule': dialogRes.sharedType
                    });
                    ActivityService.refresh();
                  } else {
                    this.notificationService
                      .error(`Your report failed to ${dialogRes.sharedType}`, dialogRes.title);
              }
            },
            error: (err) => {
              this.notificationService
                .error(err?.error ?? `Your report failed to ${dialogRes.sharedType}`, dialogRes.title);
                }
              });
            }
          });

      })
  }

  public markViewed(activity: Activity, source: string): void {
    activity.sharedReport.viewed = true;

    this.marker(activity.sharedReport, activity.sharedReport.id)
      .subscribe(() => ActivityService.refresh());

    UtilsService.trackEvent(MixpanelEvent.SHARED_ACTIVITY_VIEWED, {
      'Application': activity.getApplicationName(),
      'Activity ID': activity.getId(),
      'Share/Schedule': source,
      'Share/Schedule by': activity.getSiteUser().email
    });
  }

  public markDeleted(sharedReport: SharedReport): Observable<HttpResponse<any>> {
    sharedReport.deleted = true;
    return this.marker(sharedReport, sharedReport.id);
  }

  public marker(body: any, reportDtoId: string): Observable<HttpResponse<any>> {
    return this.siqHttpService.update({
      endpoint: ShareScheduleEndpoints.PUT_SHARED_ACTIVITY + '/' + reportDtoId,
      body: body,
      suppressNotification: true,
      fullURL: false,
      additionalConfig: {  responseType: ResponseType.TEXT }
    });
  }
}

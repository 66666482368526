import { Component, OnInit, Input } from '@angular/core';
import { ProgressIndicatorColorType, ProgressIndicatorType } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-progress-spinner',
  templateUrl: 'progress-spinner.component.html',
  styleUrls: ['progress-spinner.component.css']
})
export class ProgressSpinnerComponent implements OnInit {

  @Input() color?: ProgressIndicatorColorType;
  @Input() mode?: ProgressIndicatorType;
  @Input() overlay: boolean;
  @Input() progress?: string;
  @Input() show: boolean;

  constructor() { }

  ngOnInit() {
    this.mode = this.mode || ProgressIndicatorType.INDETERMINATE;
    this.color = this.color || ProgressIndicatorColorType.ACCENT;
  }

  /*
   * Example: To use in the project, place the following in a component template,
   * along with corresponding properties in the component.ts file
   *
   * <siq-progress-spinner
   *   [color]=statusIndicatorColorType   <-- optional
   *   [mode]=statusIndicatorType         <-- optional
   *   [progress]=progress                <-- optional
   *   [show]=show
   * ></siq-progress-spinner>
   *
   * In the component.ts file:
   *
   * private statusIndicatorType: ProgressIndicatorType;
   * private statusIndicatorColorType: ProgressIndicatorColorType;
   * private progress: string;
   * private show: boolean;
   *
   * ngOnInit() {
   *   this.statusIndicatorType = ProgressIndicatorType.DETERMINATE;
   *   this.statusIndicatorColorType = ProgressIndicatorColorType.ACCENT;
   *   this.progress = "65";
   * }
   *
   */
}

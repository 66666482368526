
export enum dayPeriod {
  AM = <any>'am',
  PM = <any>'pm'
}

export interface DateForm {
  years?: number;
  year?: number;
  y?: number;

  months?: number;
  month?: number;
  M?: number;

  days?: number;
  day?: number;
  d?: number;

  dates?: number;
  date?: number;
  D?: number;

  hours?: number;
  hour?: number;
  h?: number;

  minutes?: number;
  minute?: number;
  m?: number;

  seconds?: number;
  second?: number;
  s?: number;

  milliseconds?: number;
  millisecond?: number;
  ms?: number;
  period?: dayPeriod;
  dayOfWeek?: 1|2|3|4|5|6|7;
  daypart?: number;
}

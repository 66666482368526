import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationHash, CoreConstants, DateUnit, GroupedArray } from '@siq-js/core-lib';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { BaseStepperComponent } from 'app/core/components/stepper/base-stepper.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { PromoFormData } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { BehaviorSubject } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ValidationMessage, ValidationMessageStatus } from 'app/core/models/validation-message';
import { filter, map, takeUntil } from 'rxjs';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { EntitySelectorStatus } from 'app/siq-forms/components/entity-selector/entity-selector.component';
import { CmsField } from '@siq-js/cms-lib';
import { PromoFilterType } from 'app/siq-applications/modules/promo/models/promo.enums';
import { ComparisonGroup } from 'app/siq-applications/modules/promo/models/comparison-group';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { PromoQueryModeModalComponent } from 'app/siq-applications/modules/promo/components/promo-query-mode-modal/promo-query-mode-modal.component';
import * as _ from 'lodash';
import { QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { DateSelectionComponent } from 'app/siq-forms/modules/dates/components/date-selection/date-selection.component';
import { ValidationService } from 'app/siq-applications/services/validation/validation.service';

@Component({
  selector: 'siq-js-promo-stepper',
  templateUrl: './promo-stepper.component.html',
  styleUrls: ['./promo-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class PromoStepperComponent extends BaseStepperComponent implements OnInit, AfterViewInit {

  @Input() formData: PromoFormData;
  @Input() accessGroupChanged: boolean;
  @Input() queryMode: QueryModeComponent;
  @Input() editMode: Boolean = false;
  @Input() overrideSchema: string;
  @ViewChild('promoDateSelectionComponent') promoDateSelectionComponent: DateSelectionComponent;

  public activityTypeList: string[];
  public promoTypeMessage = 'Select Activity Type';
  public appName: string;
  public comparisonDateError: boolean;
  public comparisonGroupMessage = 'Create Comparison Group(s)';
  public comparisonGroups$ = new BehaviorSubject<ComparisonGroup[]>([]);
  public datesUpdated$ = new BehaviorSubject<boolean>(null);
  public entityData: GroupedArray<CmsField>; // original copy of grouped CmsField data coming from CMS
  public filteredData: GroupedArray<CmsField>; // filtered version of entityData

  public initModel: CmsField[];
  public initDatesModel: DateRangeInterface;
  public initPromoDatesModel: DateRangeInterface;
  public initYoyDatesModel: DateRangeInterface;
  public initPreDatesModel: DateRangeInterface;
  public initCustomDatesModel: DateRangeInterface;
  public transactionStepMessage = 'Select Transaction Filter(s)';
  public promoDatesRequiredError = true;
  public promoFilterType = PromoFilterType;
  public sovErrorMessage = 'Comparison Group used to create Source of Volume(s) has been removed. Please choose a new one.';
  public sovMessage = 'Create Source of Volume(s)';
  public sovs$ = new BehaviorSubject<SourceOfVolume[]>([]);
  public stepValidationMessages: ValidationMessage[] = [null, null, null, null, null, null, null];
  public upcRequiredError: boolean;
  public validationMessageStatus = ValidationMessageStatus;

  private isCustomDateRangeValid: boolean = false;
  private isPrePromoDateRangeValid: boolean = false;
  private isYoyDateRangeValid: boolean = false;

  constructor(
    protected asyncStatusService: AsyncStatusService,
    private promoService: PromoService,
    private router: Router,
    private utilsService: UtilsService,
    private datesService: DatesService,
  ) {
    super(asyncStatusService);
    // config for date selector component
    this.dateSelectionConfig = {
      primaryDate: {
        rangeMode: true,
        allowShortcuts: false,
        weekEndingDay: false
      }
    };
  }

  public activitiesTypeChanged(entitiesStatus: EntitySelectorStatus<CmsField>) {
    const { valid, selectedEntities } = entitiesStatus;
    if (valid) {
      this.formData.promoType = selectedEntities[0].display;
      this.updateValidationMessage(0, ValidationMessageStatus.VALID, 'Activity Type Selected');
    } else {
      this.formData.promoType = null;
      this.updateValidationMessage(0, ValidationMessageStatus.UNSET, this.promoTypeMessage);
    }
  }

  // Step 3: Promo Date Range
  public dateChanged(dates: DateRangeInterface) {
    const s = dates.begin, e = dates.end;
    let start: string, end: string;
    start = s ? DatesService.format(s, CoreConstants._shortDate) : '';
    end = e ? DatesService.format(e, CoreConstants._shortDate) : '';
    if (s || e) {
      this.updateValidationMessage(2, ValidationMessageStatus.VALID, `${start} through ${end}`);
    }

    if (dates.isDateRangeValid) {
      this.formData.promoDateRangeInterface = dates;
      this.formData.promoDateRange = {
        customPeriodName: this.formData.promoDateRange.customPeriodName,
        ...DatesService.paramify(dates)
      };

      // generate the default dates in step 4
      const range = DatesService.getDifference(DateUnit.DAY)(e, s);
      const pre: DateRangeInterface = {
        begin: DatesService.add(s, { days: -(range + 1) }),
        end: DatesService.add(s, { days: -1 })
      };
      const yoy: DateRangeInterface = {
        begin: DatesService.add(s, { years: -1 }),
        end: DatesService.add(e, { years: -1 })
      };
      // update
      this.formData.prePromoDateRange = { customPeriodName: this.formData.prePromoDateRange.customPeriodName, ...DatesService.paramify(pre) };
      this.formData.yoyDateRange = { customPeriodName: this.formData.yoyDateRange.customPeriodName, ...DatesService.paramify(yoy) };
      this.promoDatesRequiredError = false;
      PromoService.isPrePromoDateRangeValid$.next(true);
      PromoService.isYoyDateRangeValid$.next(true);
      this.datesUpdated$.next(true);
    } else { // invalid start or end date
      this.promoDatesRequiredError = true;
    }
    this.validateForm(PromoService.validateForm$.getValue());
  }

  // Used for location filters in step 5
  public filterGroupChanged(filterModel: FilterSelection[], group: string) {
    switch (group) {
      // location filter
      case PromoFilterType.LOCATION:
        this.formData.locationFilters = filterModel || this.formData.locationFilters;
        if (!this.formData.locationFilters.length) {
          this.updateValidationMessage(4, ValidationMessageStatus.UNSET, this.transactionStepMessage);
        } else {
          this.updateValidationMessage(4, ValidationMessageStatus.VALID, `${this.formData.locationFilters.length} Location Filter(s) Selected`);
        }
        break;
    }
  }

  public getActivityTypeList(): void {
    this.promoService.getActivityTypeList()
      .pipe(takeUntil(this.unsub$))
      .subscribe(
        activityList => {
          this.activityTypeList = activityList.types;
          this.filteredData = this.activityTypeList.map((value, index) => {
            return new CmsField({
              retailer: value,
              table: value,
              field: value,
              display: value,
              filter: null,
              type: value,
              active: true,
              id: index.toString()
            });
          });
        }, error => {
          console.log('Error occured' + error);
        });
  }

  public init() {
    if (this.formData.isCloned) {
      this.processClonedForm();
    } else {
      this.upcRequiredError = true;
    }
    this.queryMode.setSchema(this.overrideSchema);
    this.queryMode.schema$
      .pipe(filter(schema => !!schema))
      .subscribe(schema => {
        this.formData.schema = schema;
        this.datesService.datePickerSchema$.next(DatesService.getActiveSchemas(schema, []));
      });
    this.getActivityTypeList();
    this.initCG();
    this.initSOV();
    PromoService.validateForm$.next(!!this.formData.customPromoDateRange.periodStart); // To ensure validateForm$ has current value when cloning a report, or navigating away/back from/to promoStepper.
    PromoService.validateForm$.pipe(takeUntil(this.unsub$)).subscribe(useCustomDateRange => {
      this.validateForm(useCustomDateRange);
    });
    PromoService.isPrePromoDateRangeValid$.pipe(takeUntil(this.unsub$)).subscribe(v => {
      this.isPrePromoDateRangeValid = v;
      this.validateForm(PromoService.validateForm$.getValue());
    });
    PromoService.isYoyDateRangeValid$.pipe(takeUntil(this.unsub$)).subscribe(v => {
      this.isYoyDateRangeValid = v;
      this.validateForm(PromoService.validateForm$.getValue());
    });
    PromoService.isCustomDateRangeValid$.pipe(takeUntil(this.unsub$)).subscribe(v => {
      this.isCustomDateRangeValid = v;
      this.validateForm(PromoService.validateForm$.getValue());
    });
  }

  public transactionMapFn = (fields: CmsField[]) => fields.filter(f => (f.table === 'locations' || f.table === 'customer' || f.table === 'transaction') && !ValidationService.CORE_LOYALTY_FIELD_LIST.includes(f.id)); // mapping fn for location filters

  public onSubmit() {
    this.disableSubmitBtn = true;

    // The submit fn returns an Observable to which we can subscribe
    this.promoService.saveReport(this.formData, this.editMode)
      .subscribe(res => {
        // form handles whatever needs to be done here
        // redirect back to the list
        this.router.navigate([`${ApplicationService.find(ApplicationHash.PROMO).path}`]);
      }, err => {
        console.log(`Error submitting ${this.appName} Form: %O`, err);
        this.validateForm(PromoService.validateForm$.getValue());
      });
  }

  public validateUpcSelection(val: ValidationMessage) {
    this.updateValidationMessage(1, val.status, val.message);
    if (val.status === ValidationMessageStatus.VALID) {
      this.upcRequiredError = false;
      if (!this.promoDatesRequiredError) {
        this.validateForm(PromoService.validateForm$.getValue());
      }
    } else {
      this.upcRequiredError = true;
      this.validateForm(PromoService.validateForm$.getValue());
    }
  }

  private initCG() {
    this.comparisonGroups$
      .pipe(map(cgs => cgs.filter(cg => cg.isValid)))
      .subscribe(cgs => {
        this.formData.comparisonGroups = cgs;
        if (!!cgs.length) {
          this.updateValidationMessage(5, ValidationMessageStatus.VALID, `${cgs.length} Comparison Group(s) Added`);
        } else {
          this.updateValidationMessage(5, ValidationMessageStatus.UNSET, this.comparisonGroupMessage);
        }
      });
  }

  private initSOV() {
    this.sovs$
      .pipe(filter(sovs => !!sovs))
      .subscribe(sovs => {
        const sovCgIds = sovs.map(sov => sov.comparisonGroup?.id);
        const currCgIdSet = new Set(this.comparisonGroups$.getValue().map(cg => cg.id));
        let err = false;
        for (let i = 0; i < sovCgIds.length; i++) {
          if (sovCgIds[i] && !currCgIdSet.has(sovCgIds[i])) {
            err = true;
            break;
          }
        }
        if (err) {
          this.updateValidationMessage(6, ValidationMessageStatus.INVALID, this.sovErrorMessage);
        } else {
          const validSovs = sovs.filter(sov => sov.isValid);
          this.formData.sourcesOfVolume = validSovs;
          if (!!validSovs.length) {
            this.updateValidationMessage(6, ValidationMessageStatus.VALID, `${validSovs.length} Source of Volume(s) Added`);
          } else {
            this.updateValidationMessage(6, ValidationMessageStatus.UNSET, 'Create Source of Volume(s)');
          }
        }
      });
  }

  public ngAfterViewInit() {
    if (this.formData.isCloned) {
      setTimeout(() => { // setTimeout() used here to prevent ExpressionHasChangedAfterViewChecked error.
        this.promoDateSelectionComponent.setPrimaryDate(DatesService.toDateRangeInterface(this.formData.promoDateRange));
      });
    }
  }

  // Process form when current form is cloned
  private processClonedForm(): void {
    const currSchema = UtilsService.getQueryModeDefaultSchema(this.overrideSchema, true);
    if (this.accessGroupChanged && !this.formData.isValidSchema(currSchema)) { // AG has changed and form no longer valid
      this.utilsService.openModal(
        PromoQueryModeModalComponent,
        {
          data: _.cloneDeep(this.formData),
          schema: this.queryMode.schema$.getValue(),
          userGroupChanged: true
        } as QueryModeModalData
      );
      // reset form schema
      this.formData.schema = currSchema;
    }
    // set affinity type
    if (this.formData.promoType) {
      this.initModel = [new CmsField({
        retailer: null,
        table: null,
        field: null,
        display: this.formData.promoType,
        filter: null,
        type: null,
        active: true,
        id: 'cloned promo type'
      })];
    }

    this.promoService.clonedActivity = null; // clear activity
  }

  private validateForm(useCustomDateRange : boolean) {
    this.comparisonDateError =
      !this.isPrePromoDateRangeValid ||
      !this.isYoyDateRangeValid ||
      (useCustomDateRange && !this.isCustomDateRangeValid);
    this.disableSubmitBtn = this.upcRequiredError || this.promoDatesRequiredError || this.comparisonDateError;
  }

}

<div id="tabBody" class="ag-tab-body">
  <div class="ag-filter">
    <div>
      <div>
        <select class="ag-filter-select" [(ngModel)]="filterType">
          <option *ngFor="let filterType of filterTypes" [value]="filterType">{{ filterLabels[filterType] }}</option>
        </select>
      </div>
      <div class="ag-filter-body" (keydown.enter)="applyFilter()">
        <div>
          <input #month class="ag-filter-filter month" type="number" [(ngModel)]="form.month" maxLength="2" min="1" max="12" placeholder="mm">
          <span class="dateDivider">/</span>
          <input class="ag-filter-filter year"type="number" [(ngModel)]="form.year" maxLength="4" placeholder="yyyy" >
        </div>
        <div *ngIf="filterType === 'inRange'">
          <input class="ag-filter-filter month" type="number" [(ngModel)]="formRangeEnd.month" maxLength="2" min="1" max="12" placeholder="mm">
          <span class="dateDivider">/</span>
          <input class="ag-filter-filter year"type="number" [(ngModel)]="formRangeEnd.year" maxLength="4" placeholder="yyyy">
        </div>
      </div>
      <div class="ag-filter-apply-panel" id="applyPanel">
        <button type="button" id="clearButton" (click)="clearFilter()">Clear Filter</button>
        <button type="button" id="applyButton" (click)="applyFilter()">Apply Filter</button>
      </div>
    </div>
  </div>
</div>

<siq-js-comparison-group
  *ngFor="let cg of comparisonGroups"
  [cg]="cg"
  [initModel]="cg.filters"
  [schemaController]="schema$"
  [mapFn]="productMapFn"
  (cgRemoved)="onRemoveCG($event)"
  (cgNameChanged)="onCGNameChange($event)"
  (emitter)="onSelectFilters($event, cg.id)"
>
</siq-js-comparison-group>


<button
  *ngIf="comparisonGroups.length < cgMax"
  mat-stroked-button
  class="secondary-button"
  (click)="onAddCG()"
>
  Add a Comparison Group
</button>

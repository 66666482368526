import * as _ from 'lodash';
import { AbstractReportBuilderEntity } from 'app/siq-applications/modules/report-builder/models/abstract-report-builder-entity.model';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs';
import { ReportBuilderDraft } from 'app/siq-applications/modules/report-builder/models/results/report-builder-draft.model';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { ReportBuilderResultData } from 'app/siq-applications/modules/report-builder/models/results/report-builder-result-data.model';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { StatResponse } from 'app/core/models/stat-response.model';
import { GridComponent, VisualOptions, VisualService } from '@siq-js/visual-lib';
import { ReportBuilderGridProcessor } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-grid-processor';

export class ReportBuilderSheet extends AbstractReportBuilderEntity {
  public data: ReportBuilderResultData;
  public draft = false;
  public error: boolean; // error flag
  public errorMessage: string;
  public gc: GridComponent;
  public gridVisualOptions: VisualOptions;
  public model: {
    totalActivities?: number,
    loadedActivities?: number,
    new?: boolean
  };
  public name: string; // sheet name
  public preview: boolean; // if this sheet is a preview
  public ready: boolean; // if sheet is ready to render
  public readyForExport$: BehaviorSubject<boolean>; // if sheet is ready to be exported
  public sr: StatResponse;
  public status: ActivityStatus;
  public tableOptions: VisualOptions;

  constructor(public parent: ReportBuilderResultComponent,
    public activity: Activity) {
    super(parent);
    this.ready = false;
    this.readyForExport$ = new BehaviorSubject(false);

    this.name = (activity.getMetaDataByKey('name') || '').trim();

    this.model = {};

    this.checkStatus();
  }

  getForm(): ReportBuilderFormData {
    return this.parent.reportBuilderService.createForm(this.activity.getFormValues());
  }

  clone(): ReportBuilderDraft {
    const clonedFormData = this.parent.reportBuilderService.createForm(this.activity.getFormValues());
    clonedFormData.name = 'Copy of ' + this.name;
    const draft: ReportBuilderDraft = new ReportBuilderDraft(this.parent, clonedFormData);
    draft.status = this.activity.getStatus();
    return draft;
  }

  toJson() {
    return this.activity.getId();
  }

  setActive() {
    if (this.activity.getStatus() === ActivityStatus.PREVIEW_READY && !this.ready) {
      this.retrieveResults(true);
    }

    const grid = _.get(this, ['gridVisualOptions', 'apiRef', 'gridOptions']);
    if (grid) {
      this.parent.updateGridSize(grid);
    }
    this.markRead();
    this.parent.activeSheet = this;
  }

  editName(event) {
    if (this.parent.activeSheet !== this || this.parent.readonly) {
      event.stopPropagation();
      return this.parent.setActiveSheet(this);
    }
    this.parent.model.sheetName = this.activity.getMetaDataByKey('name') || '';
    setTimeout(() => this.parent.sheetNameInput.nativeElement.focus());
  }

  // Overrides super's behavior since a DELETE call needs to be performed first
  delete() {
    this.parent.reportBuilderService.deleteSheet(this.activity.getId(), this.parent.report.getId())
      .subscribe(() => {
        super.delete(); // Call original delete fn
      });
  }

  // Performs operations based on the current status of this sheet's activity
  public checkStatus() {
    this.status = this.activity.getStatus();
    switch (this.status) {
      case ActivityStatus.ERROR:
        this.error = true;
        if (!_.isEmpty(this.activity.getErrorsDetails())) {
          this.errorMessage = this.activity.getErrorsDetails()[0].getMessage(); // Set to newest (position zero)
        }
        break;
      case ActivityStatus.EMPTY:
      case ActivityStatus.ALERT:
        break;
      case ActivityStatus.READY:
        this.retrieveResults();
        break;
      case ActivityStatus.PREVIEW_READY:
        this.preview = true;
        if (this.parent.activeSheet === this) {
          this.retrieveResults(true);
        }
        break;
      case ActivityStatus.PREVIEW_RUNNING:
      case ActivityStatus.EXPIRED:
      case ActivityStatus.RUNNING:
        this.poll();
        break;
    }
  }

  public updateName(newName: string) {
    newName = newName.trim().replace(/[.]/g, '_');
    const parent = this.parent;
    if (this.name !== newName) {
      this.name = newName;
      parent.model.syncing = true;
      parent.reportBuilderService.updateEntityMeta(this.activity, {
        name: newName
      }).subscribe(() => {
        parent.model.syncing = false;
        parent.model.lastSynced = new Date();
        parent.updateLastSyncedTxt();
        delete parent.model.sheetName;
      });
    } else {
      delete parent.model.sheetName;
    }
  }

  // Updates the sheet's activity, and checks the status again
  public poll() {
    of(true).pipe(
      delay(this.parent.config.pollInterval),
      takeUntil(this.parent.unsub$)
    ).subscribe(() => {
      this.parent.reportBuilderService.getSheet(this.activity.getId(), this.parent.report.getId())
        .subscribe(activity => {
          this.activity = activity;
          this.checkStatus();
        });
    });
  }

  // Implementation of a "read" state for sheets
  public markRead() {
    if (!this.activity.isComplete()) return;
    this.model.new = false;
    if (!this.activity.isViewed()) {
      this.parent.activityService.markViewed(this.activity);
    }
  }

  public numCells(): number {
    const grid = this.gridVisualOptions.apiRef().grid;

    if (!grid) return 0;

    const columns = grid.api.getAllDisplayedColumns().length;
    let rows = 0;
    grid.api.forEachNode(node => rows++);

    return rows * columns;
  }

  // Grabs the results of all of the children app-activities in the sheet
  // and performs the necessary operations to create a renderable table
  // At this point, the *ENTIRE* sheet is presumed to be "ready"
  private retrieveResults(preview?: boolean) {
    this.data = new ReportBuilderResultData(this.activity.getId());
    this.data.sheet = this; // provide reference to this sheet that can be used within "this.data" (report-builder-result-data.model.ts)
    const nameMap = JSON.parse(this.activity.getMetaDataByKey('columnNames') || '{}');
    const columnIds = this.activity.getMetaDataByKey('columns').split(',');
    const columnOrder = this.activity.getMetaDataByKey('columnOrder').split(',');

    this.model = {
      new: !this.activity.getMetaDataByKey('read'),
      totalActivities: columnIds.length,
      loadedActivities: 0
    };

    // Initialize each column with its respective app activity ID
    columnOrder.forEach(id => this.data.addColumnGroup(id, nameMap[id]));

    // Map each column ID to an Observable for loading its response data
    const requests = columnIds.map(colId => {
      const obs: Observable<Activity> = preview ?
        this.parent.activityService.getActivityPreview(colId) :
        this.parent.activityService.getActivityResults(colId);

      return obs
        .pipe(map(res => {
          this.model.loadedActivities++;
          return res;
        }));
    });

    // Perform a forkJoin of the array of Observables, and stream in the data as they complete
    forkJoin(requests)
      .subscribe((columnGroupActivities: Activity[]) => {
        this.processActivities(columnGroupActivities); // Apply custom RB logic on each activity
        this.data.finalize();
        this.sr = this.data.toStatResponse(this.activity); // Creates a single usable StatResponse from all of the children activities
        this.gridVisualOptions = ReportBuilderGridProcessor.generateGridVisualOptions(this); // Create the VisualOptions for rendering

        // Establish pointer to the GridComponent associated with this Sheet.
        // This sheet-level reference to the GridComponent is needed by AsyncTotals!
        const _pollForVizId = async () => {
          if (!_.isNil(this.gridVisualOptions.vizId)) {
            // The GridComponent has been created and registered. Establish the pointer to it.
            this.gc = <GridComponent>VisualService.findVisualization(this.gridVisualOptions.vizId);
          } else {
            setTimeout(() => _pollForVizId(), 500);
          }
        };
        _pollForVizId();

        this.ready = true; // Mark this sheet as ready to render

        // If the data happens to load in while still on this sheet, automatically mark the sheet as "read"
        if (this.parent.activeSheet === this) {
          this.setActive();
        }
      });
  }

  // Iterates through an activity's jobs and does stuff depending on what type of column it is
  // (currently parsed through the job name)
  private processActivities(columnGroupActivities: Activity[]) {
    const jobIdTimeAggValToColIdxMap = new Map<string, number>();
    columnGroupActivities.forEach(columnGroupActivity => {

      const colGroupId = columnGroupActivity.getId();
      columnGroupActivity.getJobs().forEach((job) => {
        // 'report-builder-col' is the default column type for a metric column without any special modifications
        // The special column(s) will need a specific flag applied to them based on the job's name
        if (!ReportBuilderService.isNormalMetric(job)) {
          this.data.getColgroupById(colGroupId).flag = job.getName();
        }

        // Process the column based on its flag
        if (ReportBuilderService.isTimeAgg(job)) {
          ReportBuilderService.processTimeAggJob(this.data.dataMap, job, colGroupId, this.data, jobIdTimeAggValToColIdxMap);
        } else if (ReportBuilderService.isYOY(job)) {
          ReportBuilderService.processYearOverYearJob(this.data.dataMap, job, colGroupId, this.data);
        } else {
          ReportBuilderService.processMetricJob(this.data.dataMap, job, colGroupId, this.data);
        }
      });
    });
  }
}

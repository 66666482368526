import { Component } from '@angular/core';
import { AgRendererComponent } from '@siq-js/visual-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-js-created-renderer',
  templateUrl: './created-renderer.component.html',
  styleUrls: ['./created-renderer.component.scss']
})
export class CreatedRendererComponent extends BaseSiqComponent implements AgRendererComponent {

  public created: Date;
  constructor() {
    super();
  }

  agInit(params: any) {
    this.created = new Date(Number(params.data.created));
  }

  refresh(): boolean {
    return true;
  }

}

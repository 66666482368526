import { AbstractControl, ValidatorFn } from '@angular/forms';

export function positiveNumberValidator(max: Number, min: Number, errorName: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    // valid if control.value follow 4 rules
    // 1. only include 0-9 digits
    // 2. is a valid number
    // 3. <= max
    // 4. >= min
    const valid =
      /^[0-9]*$/.test(control.value) &&
      typeof control.value === 'number' &&
      control.value <= max &&
      control.value >= min;

    const error = {};
    error[errorName] = {value: control.value};

    // return error object with key='positiveNumber' if control.value is invalid
    return !valid ? error : null;
  };
}

import { Component } from '@angular/core';
import { AgRendererComponent, ColDef } from '@siq-js/visual-lib';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityTableComponent } from 'app/activity/components/activity-table/activity-table.component';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { ActivityCallbacks } from 'app/activity/models/interfaces';

@Component({
  selector: 'siq-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrls: ['./actions-renderer.component.scss']
})
export class ActionsRendererComponent implements AgRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: '',
    sortable: false,
    cellRenderer: ActionsRendererComponent,
    width: 36,
    resizable: false,
    pinned: 'right'
  };

  public activity: Activity;
  public actions: ActivityOptionsActions[];
  public callbacks: ActivityCallbacks;

  constructor(
    parent: ActivityTableComponent
  ) {
    this.actions = parent.parent.actions;
    this.callbacks = parent.parent.callbacks;
  }

  agInit(params: any) {
    this.activity = params.data as Activity;
  }

  refresh(): boolean {
    return true;
  }

}

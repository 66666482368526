import { GridApi, IRowNode } from 'ag-grid-enterprise';

/**
 * Ag-Grid
 * License key updated: 16 October 2023. Valid through 21 July 2024.
 */
export const AgGridLicenseKey = 'Using_this_AG_Grid_Enterprise_key_( AG-042928 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Excentus Corporation )_is_granted_a_( Single Application )_Developer_License_for_the_application_( PDI Insights Cloud )_only_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( PDI Insights Cloud )_need_to_be_licensed___( PDI Insights Cloud )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 July 2024 )____[v2]_MTcyMTUxNjQwMDAwMA==1f39d142f8321e3c7b25037ebce14e8c';

export {
  AgRendererComponent,
  AgGridAngular,
  AgGridModule,
  AgFilterComponent,
  IFilterAngularComp,
  IHeaderAngularComp,
  ILoadingOverlayAngularComp,
} from 'ag-grid-angular';
export {
  ChartRef,
  Column,
  ColumnGroup,
  ColDef,
  ColGroupDef,
  ColumnState,
  CsvExportParams,
  GridOptions,
  ExcelDataType,
  ExcelStyle,
  ExcelExportParams,
  ExcelExportMultipleSheetParams,
  GetContextMenuItemsParams,
  ICellRendererParams,
  IDoesFilterPassParams,
  ILoadingOverlayParams,
  IFilterParams,
  IHeaderParams,
  IToolPanel,
  IToolPanelParams,
  LicenseManager,
  Module,
  ModuleRegistry,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  RefreshCellsParams,
  RowDataTransaction,
  SideBarDef,
  ToolPanelDef,
  ValueFormatterParams,
  ValueGetterParams,
  CellRendererSelectorFunc,
  GroupCellRenderer,
  GridApi
} from 'ag-grid-enterprise';
export {
  FilterChangedEvent,
  ColumnPivotChangedEvent,
  ColumnPivotModeChangedEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  SortChangedEvent,
  CellMouseOutEvent,
  CellMouseOverEvent,
  ChartType,
  GridReadyEvent,
  ChartCreated,
  RowClickedEvent
} from 'ag-grid-community';

const markup = `
  <div
    class="css-spinner"
    style="width: 100px; height: 100px;"
  >
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 100 100"
      style="width: 100px; height: 100px;"
    >
      <circle
        cx="50%"
        cy="50%"
        r="45"
        style="animation-name: css-spinner-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;"
      ></circle>
    </svg>
  </div>
`;

export const AgGridConfig = {
  overlayLoadingTemplate: markup
};

// MOVED FROM CORE-LIB utils/ag-grid.ts:
/**
 * helper function for refreshing the all cells for the current row node
 * @param params
 */
export function refreshAgGridRow(params: {api: GridApi, node: IRowNode}) {
  params.api.refreshCells({
    rowNodes: [params.node],
    force: true
  });
}

export const HEATMAP_ACTIVE = 'heatmap-active';
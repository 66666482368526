import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationHash } from '@siq-js/core-lib';

export function registerAppIcons(registry: MatIconRegistry, domSanitizer: DomSanitizer, dir = 'assets') {
  const resourcePath = `${dir}/app-icons/`;
  for (const _enum in ApplicationHash) {
    const appId = ApplicationHash[_enum];
    try {
      registry.addSvgIcon(appId, domSanitizer.bypassSecurityTrustResourceUrl(`${resourcePath}/${appId}.svg`));
    } catch (e) {
      // failures are ok, as some app IDs do not have icons assigned
      // console.error(e);
    }
  }
}

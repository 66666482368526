import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';

export class AppRequest {
  private datasets: AppDataset[];
  private exclude?: boolean;
  private formValues: Object;
  private metaData: Map<string, string>;

  constructor(datasets?: AppDataset[], formValues?: Object, metaData?: Map<string, string>, exclude?: boolean) {
    this.datasets = datasets || [];
    this.formValues = formValues || {};
    this.metaData = metaData || new Map<string, string>();
    if (exclude) {
      this.exclude = exclude;
    }
  }

  getDatasets(): AppDataset[] {
    return this.datasets;
  }

  setDatasets(datasets: AppDataset[]): void {
    this.datasets = datasets;
  }

  getFormValues(): Object {
    return this.formValues;
  }

  setFormValues(formValues: Object): void {
    this.formValues = formValues;
  }

  getMetaData(): Map<string, string> {
    return this.metaData;
  }

  setMetaData(metaData: Map<string, string>): void {
    this.metaData = metaData;
  }

  setMetaDataEntry(key: string, value: string): void {
    if (key && value) {
      let map: Map<string, string> = this.getMetaData();
      map.set(key, value);
    }
  }

  /*
   * This function returns a pure JSON object which can be posted via HTTP.
   * The metaData being a Map was causing issues which necessitated this.
   */
  asJsonObject(): object {
    let out: any = {};
    out.datasets = this.getDatasets();
    out.formValues = this.getFormValues();
    out.exclude = this.exclude;
    out.metaData = {};

    if (this.getMetaData().size > 0) {
      let map: Map<string, string> = this.getMetaData();
      map.forEach((value, key) => {
        out.metaData[key] = value;
      });
    }

    return out;
  }
}

<p>Repeat on</p>

<div>
  <button mat-mini-fab
          class="weekday-button"
          [ngClass]="weekdaysMap[day] ? 'weekday-selected' : 'weekday-unselected'"
          *ngFor="let day of weekdays"
          (click)="addDay(day)">
    {{day | slice: 0:1}}
  </button>
</div>

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'libs/visual-lib/src/lib/ag-grid';
import { CoreConstants } from '@siq-js/core-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { GridComponent } from 'libs/visual-lib/src/lib/modules/grid/components';
import { CmsConfig, CmsMetric } from '@siq-js/cms-lib';
import { fabric } from 'fabric';
import { HeatmapService } from 'libs/visual-lib/src/lib/modules/grid/services/heatmap/heatmap.service';
import { takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { VisualizationState } from 'libs/visual-lib/src/lib/models';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'siq-js-heatmap-selector',
  templateUrl: './heatmap-selector.component.html',
  styleUrls: ['./heatmap-selector.component.scss']
})
export class HeatmapSelectorComponent extends BaseSiqComponent implements OnInit {

  @Input() gridComponent: GridComponent;
  @Input() inlineLegend: boolean;

  public selectedMetrics$: BehaviorSubject<CmsMetric[]> = new BehaviorSubject<CmsMetric[]>(null);
  public showHeatmapLegend = false;

  @ViewChild('heatmapLegend', { static: true }) heatmapLegend: ElementRef;

  constructor() {
    super();
  }

  clear(): void {
    // Clear the existing legend
    this.gridComponent.legend.clear();

    let _state: VisualizationState = _.cloneDeep(this.gridComponent.visualizationState$.value);
    _state.heatmapColDefs = [];

    this.gridComponent.legend.dispose();

    this.gridComponent.visualizationState$.next(_state);

    // Trigger subscribers
    this.gridComponent.heatmapColDef$.next(null);
  }

  ngOnInit(): void {
    this.gridComponent.heatmapColDef$.pipe(
      takeUntil(this.unsub$)
    )
    .subscribe((colDef: ColDef) => {
      // Only make the call if colDef not null
      if (colDef) {
        this.update(this.gridComponent.heatmapColDef);
      }
    });
  }

  selectHeatmapColDef(colDef: ColDef) {
    this.gridComponent.heatmapColDef$.next(colDef);
    this.gridComponent.featureUsed.next('Heatmap Metric');
  }

  update(colDef: ColDef) {
    let _colDef = <ColDef>_.find(this.gridComponent.getApi().grid.gridOptions.columnDefs, {colId: colDef.colId});
    if (!_colDef) {
      /**
       * Could not find based on colDef.colId. This could be due to RB column ids (generated numbers) or due to gridOptions.columnDefs having ColGroupDefs.
       * Check the code in HeatmapService to iterate through this and get a list of the actual columns. This functionality should get extracted to a fn.
       */
      const metricColData = GridService.getMetricColData(this.gridComponent.getApi().grid.gridOptions.columnDefs, this.gridComponent.gridSettings.gridVisualOptions.metrics);
      const match = _.find(metricColData, {key: colDef.colId});
      if (match) {
        _colDef = <ColDef>match.colDef;
      }
    }

    let selectedMetric = (CoreConstants.cmsConfig as CmsConfig).findEntity<CmsMetric>(colDef.colId);
    if (!selectedMetric) {
      // RB makes its own ids
      const match = this.gridComponent.colDefMeta.get(colDef.colId);
      if (match) {
        selectedMetric = <CmsMetric>match.ref;
      }
    }

    if (selectedMetric) {
      this.selectedMetrics$.next([selectedMetric]);
    }

    this.gridComponent.legend = new fabric.Canvas(this.heatmapLegend.nativeElement);
    this.showHeatmapLegend = true;
    HeatmapService.renderLegend(colDef, this.gridComponent);

    const _state: VisualizationState = _.cloneDeep(this.gridComponent.visualizationState$.value);
    _state.heatmapColDefs = [_colDef];

    if (this.gridComponent.gridSettings.gridVisualOptions.gridConfig.updateHeatmap) {
      // Run application specific functionality if provided
      this.gridComponent.gridSettings.gridVisualOptions.gridConfig.updateHeatmap(_state, colDef, selectedMetric);
    }
    this.gridComponent.visualizationState$.next(_state);
  }
}

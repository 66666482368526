<siq-schedule-frequency
  [frqPosted]="frqPosted"
  [recurrenceData]="recurrenceData"
  [formGroupRecurrence]="formGroupRecurrence"
></siq-schedule-frequency>

<siq-schedule-time
  [formControlStart]="formControlStart"
></siq-schedule-time>

<siq-schedule-ends
  [formControlEnd]="formControlEnd"
  [formControlCount]="formControlCount"
></siq-schedule-ends>

<mat-nav-list color="primary">

  <siq-application-button
    *ngFor="let item of items"
    [item]="item"
  ></siq-application-button>

  <div *ngFor="let retailer of retailers" style="margin-top: 20px">
    <a mat-list-item href="https://{{retailer.domain}}" target="_blank" *ngIf="!retailer.offline" class="retailer">
      <mat-icon>open_in_new</mat-icon>&nbsp;{{ retailer.name }}
    </a>
  </div>

</mat-nav-list>

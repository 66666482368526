export enum VizType {
  BUBBLE = 'bubble',
  SCATTER = 'scatter',
  TABLE = 'table',
  LINE = 'line',
  PIE = 'pie',
  BAR = 'bar',
  COLUMN = 'column',
  CALENDAR = 'calendar',
  HEATMAP = 'heatmap',
  STACKED_COLUMN_100 = 'stackedColumn100'
}

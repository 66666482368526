<div *ngIf="asyncTotalsAllowed?.show">

  <div class="get-totals" [ngClass]="{'devMode': devMode}">
    <span class="step-label">{{ asyncSteps[asyncStep]?.label }}</span>
    <span class="step-label cache-label" *ngIf="isCachedResults">
      <button mat-icon-button matTooltip="Force Refresh" matTooltipPosition="above"
        (click)="getAsyncTotals(true)">
        <mat-icon>autorenew</mat-icon>
      </button>
      Showing cached results (created {{cachedResultTimestamp.formattedDate}})
    </span>

    <ng-container *ngIf="devMode">
      <mat-form-field>
        <mat-label>Select Stub</mat-label>
        <mat-select [(value)]="tempSelectedStub">
          <mat-option *ngFor="let stub of tempStubs" [value]="stub">
            {{ stub.substring(14) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <span [matTooltip]="tooltipText" matTooltipPosition="above">
      <button
        (click)="getAsyncTotals()"
        mat-raised-button
        class="gray-button get-totals-button" [disabled]="isLoading || !asyncTotalsAllowed?.enabled"
      >
        &Sigma;
      </button>
    </span>
  </div>

</div>

<visual-lib-progress-bar
  [color]="config.progressIndicatorColorType"
  [mode]="config.progressIndicatorType"
  [value]="config.progressValue"
  [bufferValue]="config.progressBufferValue"
  [isLoading]="isLoading"
></visual-lib-progress-bar>

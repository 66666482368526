import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsConfig, CmsField } from '@siq-js/cms-lib';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { AccessGroup } from 'app/access-group/models/access-group.model';
import { CmsService } from 'app/core/services/cms/cms.service';
import { forbiddenRegExpValidator } from 'app/siq-forms/validators/forbidden-regexp.directive';
import { AuthService } from 'app/auth/services/auth.service';
import * as _ from 'lodash';
import { debounceTime, take, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { AccessGroupFormValues } from 'app/access-group/models/interfaces';
import { HeaderCellSortableComponent } from 'app/core/components/cell-renderers/header-cell-sortable/header-cell-sortable.component';
import { AgGridAngular, ColDef, FilterNames, GridOptions, GridReadyEvent, GridService } from '@siq-js/visual-lib';
import { AppComponent } from 'app/app.component';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalConfig } from 'app/core/components/confirmation-modal/confirmation-modal-config.interface';
import { ConfirmationResponse } from 'app/core/components/confirmation-modal/confirmation-response.interface';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterService } from 'app/filter/services/filter.service';
import { SiqFilter } from 'app/filter/models/interfaces';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

@Component({
  selector: 'siq-access-group-form',
  templateUrl: './access-group-form.component.html',
  styleUrls: ['./access-group-form.component.scss']
})
export class AccessGroupFormComponent extends BaseSiqComponent implements OnInit {

  @ViewChild('accessGroupGrid') grid!: AgGridAngular;
  public accessGroup: AccessGroup;
  public accessGroupNotFound: boolean;
  public agTheme: string;
  public singleModeAllRetailers: any[] = [];
  public filterObs$: Subject<any> = new Subject();
  public gridOptions: GridOptions;
  public edit: boolean;
  public filterSchema: string;
  public form: UntypedFormGroup;
  public initQuery: string;
  public readonly limitedLengthTextConfig = {
    maxLength: 1000,
    placeholder: 'Description (optional)',
    rows: 6
  };
  public retailersList: any[] = [];
  public selectedRetailerCount = 0;
  public singleRetailerOnly = false;
  public submitting: boolean;
  private DEFAULT_RETAILERS = [''];
  private sort$: Subject<void> = new Subject<void>(); // gets set by child header components
  private unModifiedState: AccessGroup;
  public mapFn = (fields: CmsField[]) => fields.filter(f => f.id !== CmsService.RETAILER_FIELD_ID); // mapping
  public EMPTY_ACCESS_GROUP = 'empty-access-group';

  constructor(
    private route: ActivatedRoute,
    private accessGroupService: AccessGroupService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private asyncStatusService: AsyncStatusService,
    private utils: UtilsService
  ) {
    super();
    this.accessGroup = new AccessGroup();
    this.edit = false;
    this.accessGroupNotFound = false;
  }

  cancel(): void {
    this.router.navigate(['/user-groups']);
  }

  filterGroupChanged(filterModel: FilterSelection[]): void {
    this.accessGroup.setFilters(filterModel);
    this.updateForm();
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ envConfig: true });

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });

    // TEMPORARY: For MVP the ACL will always act as if in MULTI mode.
    this.filterSchema = EnvConfigService.getConfig().primaryEntity;

    // don't show retailers selector for anonymous market instance (MULTI-MODE) and show different one for MT_Lite (SINGLE-MODE)
    if (EnvConfigService.isStandardMode()) {
      this.retailersList.push(
        { name: 'All Retailers', val: '' },
        ...EnvConfigService.getConfig().retailersMeta
      );
    }

    if (EnvConfigService.isSingleMode()) {
      this.singleRetailerOnly = true;
      this.singleModeAllRetailers = [...EnvConfigService.getConfig().retailersMeta];
    }
    
    this.setupForm();
    this.onFormValueChanges();
  }

  onFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsub$)
      )
      .subscribe(val => {
        for (let k of Object.keys(val)) {
          _.assign(this.accessGroup[k] = val[k]);
        }
      });
  }

  selectRetailers(val: string) {
    if (val === this.DEFAULT_RETAILERS[0]) {
      this.accessGroup.setRetailers(this.DEFAULT_RETAILERS);
    } else {
      this.accessGroup.setRetailers(
        this.form.controls.retailers.value.filter((r) => {
          return r !== this.DEFAULT_RETAILERS[0];
        })
      );
    }
    this.updateForm();
    this.selectedRetailerCount = this.accessGroup.getRetailers().length;
  }

  singleRetailerComparator = function(o1: string[], o2: string[]) {
    if (!o1 || !o2) {
      return false;
    }
    return _.first(o1) === _.first(o2);
  }

  submit() {
    this.submitting = true;

    if (this.showModal()) {
      this.showConfirmation();
    } else {
      this.submitForm();
    }
  }

  private showModal(): boolean {
    if (!this.edit) return false;
    let returnVal = false;
    for (let k of Object.keys(this.accessGroup)) {
      if (k !== 'displayName' && k !== 'description') {
        if (!_.isEqual(this.unModifiedState[k], this.accessGroup[k])) {
          returnVal = true;
          return returnVal;
        }
      }
    }
    return returnVal;
  }

  private submitForm(val?: string) {
    this.updateForm();

    const payload: AccessGroup = new AccessGroup(this.form.value);

    if (_.isNil(payload.getFormValues())) {
      payload.setFormValues({ filters: null });
    }

    let agFV: AccessGroupFormValues = payload.getFormValues();
    agFV.filters = JSON.stringify(payload.getFilters().map(f => UtilsService.paramify(f)));
    
    delete payload.filters;
    payload.setFormValues(agFV);
    // AG-145: Attach deleteReports field in request
    if (val) {
      payload.deleteReports = val === 'delete';
    }

    // If this is a new AccessGroup being created, it will have a value of -1 for the id. Remove this from the payload before POSTing.
    if (payload.id === -1 && !this.edit) {
      delete payload.id;
    }

    if(this.singleRetailerOnly && !this.form.value.retailers) {
      //when retailers field is disabled, value is not present in form.value/payload so it will be set here
      payload.setRetailers([...this.accessGroup.getRetailers()]);
    }

    this.accessGroupService.addOrEditAccessGroup(payload)
      .pipe(
        take(1)
      ).subscribe({
        next: (resp) => {
          if (resp.status === 200 || resp.status === 201) {
            // 200:POST, 201:PUT
            this.router.navigate(['/user-groups']);
          }
          this.submitting = false;
        },
        error: (error: any) => {
          console.error('ERROR in POST/PUT to access-group endpoint. e: %O', error);
          this.submitting = false;
        }
      });
  }

  private showConfirmation(): void {
    this.utils.openModal(
      ConfirmationModalComponent,
      {
        header: 'Are you sure?',
        body: `You may choose to either keep or permanently delete the user’s (or users') saved reports. Please note that choosing to keep the saved reports will only keep their frameworks; therefore, some or all reports may have their outputted data change (for example, a report for the midwest will lose Iowa’s sales if access to Iowa’s data is being removed from the access group).`,
        buttons: [
          { label: 'KEEP REPORTS', response: { accepted: true, value: 'keep' }},
          { label: 'DELETE REPORTS', response: { accepted: true, value: 'delete' }},
          { label: 'CANCEL', response: { accepted: false, value: null }},
        ]
      } as ConfirmationModalConfig,
      UtilsService.MODAL_CONFIG_MEDIUM
    )
    .afterClosed()
    .subscribe((res: ConfirmationResponse) => {
      if (res && res.accepted) {
        this.submitForm(res.value);
      } else {
        this.submitting = false;
        return;
      }
    });
  }

  private generateDefaultColDef(name: string, field: string): ColDef {
    const colDef: ColDef = {
      headerName: name,
      field: field,
      cellClass: 'simple-cell',
      headerComponent: HeaderCellSortableComponent,
      headerComponentParams: {
        parent: this
      },
      filter: (FilterNames.TEXT).toString(),
      menuTabs: ['filterMenuTab'],
      filterParams: {
        clearButton: true
      },
      valueFormatter: (params) => params.value // Added this valueFormatter to support cloud-export (Excel)
    } as ColDef;
    return colDef;
  }

  private mapAccessGroupToForm(): void {
    const retailersValue = this.accessGroup.getRetailers() ?? (this.singleRetailerOnly ? null : this.DEFAULT_RETAILERS);
    const retailersValidators = this.singleRetailerOnly ? [this.retailerReqdValidator()] : [this.retailerReqdValidator(), Validators.minLength(1)];
    /*
     * Use FormBuilder to create a FormGroup object which serves as the model.
     * "this.form" is/shows the full FormGroup object, whereas
     * "this.form.value" is/shows the object model.
     */
    this.form = this.formBuilder.group({
      description: [this.accessGroup.getDescription() || ''],
      displayName: [this.accessGroup.getDisplayName() || '', [Validators.required, forbiddenRegExpValidator(/^\s+$/, `Input can not be white space only.`, 'white-space-only')]],
      filters: [this.accessGroup.getFilters() || [], []],
      id: [this.accessGroup.getId() || -1],
      retailers: [retailersValue, retailersValidators],
      usersGroup: [this.accessGroup.getUsersGroup() || [], []]
    });
  }

  private setAccessGroup() {
    this.route.params.subscribe(async params => {
      this.edit = params['id'] !== '~';
      if (this.edit) {
        try {
          this.accessGroup = new AccessGroup(await this.accessGroupService.getAccessGroup(params['id']).toPromise());
        } catch (err) {
          this.accessGroupNotFound = true;
          throw new Error(err);
        }
        if (this.accessGroup) {
          if (!this.accessGroup.getRetailers().length) {
            if (!this.singleRetailerOnly) {
              this.accessGroup.setRetailers(this.DEFAULT_RETAILERS); // set one empty string to signify All Retailers
            }
          }
          if (!_.isEmpty(this.accessGroup.getFilters())) {
            const updatedFilters: FilterSelection[] = [
              ...this.accessGroup.getFilters().map((f) => FilterService.convertSiqFilterToFilterSelection(<SiqFilter><unknown>f))
            ];
            this.accessGroup.setFilters(updatedFilters);

          }

          this.updateForm();
          this.selectedRetailerCount = this.accessGroup.getRetailers().length;

          // Show the members of this AG in a grid
          this.setupGrid();
          this.setListData();
          // Copy initial state to compare which fields have changed
          this.unModifiedState = _.cloneDeep(this.accessGroup);
        } else {
          this.accessGroupNotFound = true;
        }
        if (this.singleRetailerOnly) {
          // When the existing user group is being updated in SINGLE_ONLY, the retailer must not be changed
          this.form.controls['retailers'].disable();
          // Admin group has no retailers attribute
          if (_.isEmpty(this.accessGroup.getRetailers())) {
            this.singleModeAllRetailers.unshift({ name: 'All Retailers', val: '' });
            this.selectRetailers('');
          }
        }
        if (this.accessGroup.retailers[0] === this.EMPTY_ACCESS_GROUP) {
          this.selectedRetailerCount = 0;
        }
      } else {
        if (!this.singleRetailerOnly) {
          this.selectRetailers(this.DEFAULT_RETAILERS[0]); // set the default to All Retailers
        }
      }
      
    });
  }

  private setColDefs(): ColDef[] {
    const colDefs: ColDef[] = [];
    colDefs.push(this.generateDefaultColDef('First Name', 'firstName'));
    colDefs.push(this.generateDefaultColDef('Last Name', 'lastName'));
    colDefs.push(this.generateDefaultColDef('Email', 'email'));

    return colDefs;
  }

  private setListData() {
    const setWhenReady = () => {
      if (_.get(this.grid, 'api')) {
        this.accessGroupService.autoSizeColumns(this.grid.api);
        this.grid.api.updateGridOptions({ rowData: this.accessGroup.getUsersGroup() });
      } else {
        setTimeout(setWhenReady, 200);
      }
    };
    setWhenReady();
  }

  private setupForm(): void {
    this.mapAccessGroupToForm();
    this.setAccessGroup();
  }

  private setupGrid(): void {
    const component: AccessGroupFormComponent = this;

    this.gridOptions = {
      sideBar: false,
      headerHeight: 52,
      rowHeight: 58,
      suppressContextMenu: true,
      domLayout: 'autoHeight',
      suppressCellFocus: true,
      columnDefs: this.setColDefs(),
      animateRows: true,
      suppressDragLeaveHidesColumns: true,
      onGridReady: (grid: GridReadyEvent) => {
        this.sort$.next();
        this.accessGroupService.autoSizeColumns(this.grid.api);

        AppComponent.resize$
          .pipe(
            debounceTime(100),
            takeUntil(this.unsub$)
          )
          .subscribe(() => this.accessGroupService.autoSizeColumns(this.grid.api));

        const globalListener = (event, args) => {
          if (!_.get(component.gridOptions, 'api')) return;
          switch (event) {
            case 'filterChanged':
              component.filterObs$.next(grid.api.getFilterModel());
              break;
          }
        };

        grid.api.addGlobalListener(globalListener);
        AccessGroupService.refresh();
      }
    };
  }

  private updateForm(): void {
    let patchVals = {};
    for (let k of Object.keys(this.accessGroup)) {
      patchVals[k] = this.accessGroup[k];
    }

    this.form.patchValue(patchVals);
  }
  private validateRetailers(control: FormGroup): ValidationErrors | null {
    if (_.isNil(control.value[0]) || control.value[0] === this.EMPTY_ACCESS_GROUP) return { required: true };
    else return null;
  }

  private retailerReqdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.validateRetailers(control as FormGroup);
    };
  }
}

import * as _ from 'lodash';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { ActivityService } from 'app/activity/services/activity.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { ApplicationHash } from '@siq-js/core-lib';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';

@Component({
  selector: 'siq-activity-options',
  templateUrl: './activity-options.component.html',
  styleUrls: ['./activity-options.component.scss']
})
export class ActivityOptionsComponent implements OnInit {

  public isErrorOrDraft: boolean;
  public isTrashed: boolean;
  public isNotPromo: boolean;
  public canShare: boolean;
  public isSharedOrScheduled: boolean;
  public actionsEnums = ActivityOptionsActions;
  public actionsMap = {};
  public share_display: string;
  @Input() public actions: ActivityOptionsActions[];
  @Input() public activity: Activity;
  @Input() public callbacks: ActivityCallbacks = {};

  constructor(
    public router: Router,
    public sharingService: SharingService,
    private activityService: ActivityService,
    private mixpanelService: MixpanelService,
  ) { }

  ngOnInit() {
    if (!this.actions || _.isEmpty(this.actions)) {
      this.actions = _.map(ActivityOptionsActions) as ActivityOptionsActions[];
    }
    this.isErrorOrDraft = this.activity.getStatus() ? this.activity.getStatus().includes('error') || this.activity.getStatus().includes('empty') : true;
    this.actions.forEach(action => this.actionsMap[action] = true);
    this.isSharedOrScheduled = this.activity.isSharedOrScheduled();
    this.isTrashed = this.activity.isTrashed();
    this.isNotPromo = this.activity.getAppId() !== ApplicationHash.PROMO;
    this.canShare = this.activity.isMine() &&
      this.actionsMap[ActivityOptionsActions.SHARE] &&
      !this.isSharedOrScheduled &&
      !this.activity.accessGroupChanged;
    this.share_display = this.isNotPromo ? 'Share/Schedule' : 'Share';
  }

  public clone () {
    if (this.callbacks.clone) {
      this.callbacks.clone(this.activity);
    } else {
      this.router.navigate([ `${ this.activity.getPath() }/clone` ]);
    }
  }

  public delete () {

    let action: Observable<any>;

    if (this.callbacks.delete) {
      action = this.callbacks.delete([ this.activity ]);
    } else {
      action = this.performDeleteAction(true);
    }

    action
      .subscribe(() => {
        this.activityService
          .remove({endpoint: `reports/${this.activity.id}`, suppressNotification: true})
          .subscribe(() => {
            this.postDeleteAction(true);
          });
      });
  }

  public details () {
    if (this.callbacks.details) {
      this.callbacks.details(this.activity);
    } else {
      this.router.navigate([ this.router.url, { outlets: { m: 'detail/' + this.activity.getId() } } ]);
    }
  }

  // public pin () {
  //   if (this.callbacks.pin) {
  //     this.callbacks.pin(this.activity);
  //   } else {
  //     this.utils.openModal(DashboardsPinModalComponent, this.activity, UtilsService.MODAL_CONFIG_MEDIUM);
  //   }
  // }

  public results () {
    const source = !this.activity.isSharedOrScheduled() ? '' : (this.activity.isShared() ? 'share' : 'schedule');
    if (this.callbacks.results) {
      this.callbacks.results(this.activity);
    } else {
      this.router.navigate([ this.activity.getPath() ], source ? {queryParams: { source: source } } : {});
    }
  }

  public undelete () {

    let action: Observable<any>;

    if (this.callbacks.unDelete) {
      action = this.callbacks.unDelete([ this.activity ]);
    } else {
      action = this.performDeleteAction(false);
    }

    action
      .subscribe(() => {
        this.postDeleteAction(false);
      });
  }

  /**
   * Update the activity just deleted/undeleted in ActivityService.Activities$
   */
  private postDeleteAction(deleting: boolean) {
    const list = ActivityService.Activities$.getValue();
    const a = list.find(a => a.id === this.activity.id);
    a.setMetaDataByKey('trashed', deleting.toString());
    ActivityService.Activities$.next(list);
    if (deleting) {
      this.mixpanelService.track(
        MixpanelEvent.ACTIVITY_DELETED,
        {
          'Action': 'Report Deleted',
          'Report ID': this.activity.getId(),
          'Report Name': this.activity.getName(),
          'Report Created By': this.activity.getCreatedBy(),
          'Type': 'Report',
        }
      );
    }
  }

  private performDeleteAction (shouldBeTrashed: boolean): Observable<any> {
    return this.activityService.updateMetadata(this.activity, { trashed: shouldBeTrashed });
  }

  public remove() {
    if (this.activity.isSharedOrScheduled()) {
      this.sharingService.markDeleted(this.activity.sharedReport)
        .subscribe(() => {
          ActivityService.refresh()
        });
    }
  }

  public share() {
    this.sharingService.share(this.activity);
  }

}

<mat-divider *ngIf="item.separator"></mat-divider>
<ng-container *ngIf="item.component">

  <siq-dynamic-component [component]="item.component" [data]="item"></siq-dynamic-component>

</ng-container>

<ng-container *ngIf="!item.component">

  <ng-container *ngIf="!item.children">

    <button
      mat-menu-item
      [routerLink]="item.routerLink || []"
      [queryParams]="item.queryParams"
      (click)="clickHandler()"
    >
      <span
        [matBadge]="item.notifyCount"
        [matBadgeColor]="item.notifyColor"
        [matBadgeHidden]="!item.notifyCount"
        [matBadgeOverlap]="false"
        matBadgePosition="before"
        matBadgeSize="medium"
      >{{ item.label }}</span>
    </button>

  </ng-container>

  <ng-container *ngIf="item.children">

    <button
      mat-menu-item
      (click)="clickHandler()"
      (mouseenter)="mouseEnterHandler()"
      [matMenuTriggerFor]="item.children.length ? subMenu: null"
      [disabled]="!item.children.length"
      #menuTrigger="matMenuTrigger"
    >
      <span
        [matBadge]="item.notifyCount"
        [matBadgeColor]="item.notifyColor"
        [matBadgeHidden]="!item.notifyCount"
        [matBadgeOverlap]="false"
        matBadgePosition="before"
        matBadgeSize="medium"
      >{{ item.label }}</span>
    </button>

    <mat-menu #subMenu="matMenu">
      <siq-dropdown-menu-item [item]="branch" *ngFor="let branch of item.children"></siq-dropdown-menu-item>
    </mat-menu>

  </ng-container>

</ng-container>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {
  BarColumnComponent,
  ScatterWithLineComponent,
  SimpleLineComponent,
  SimpleScatterComponent
} from 'libs/visual-lib/src/lib/modules/charts-graphs/components';
import { SimpleBubbleComponent } from 'libs/visual-lib/src/lib/modules/charts-graphs/components/simple-bubble/simple-bubble.component';
import { AgChartsAngularModule } from 'ag-charts-angular';


const chartsGraphsComponents: any[] = [
  BarColumnComponent,
  ScatterWithLineComponent,
  SimpleLineComponent,
  SimpleScatterComponent,
  SimpleBubbleComponent
];

@NgModule({
  declarations: [
    ...chartsGraphsComponents,
  ],
  imports: [
    CommonModule,
    AgChartsAngularModule,
    BrowserModule
  ],
  exports: [
    ...chartsGraphsComponents
  ],
  providers: []
})
export class ChartsGraphsModule { }

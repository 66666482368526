import {
  AgTooltipRendererResult,
  ChartSettings,
  GridService,
  SimpleLineConfig,
  SimpleLineParams,
  SimpleLineProcessor,
  ChartsService,
  AgCartesianChartOptions,
  AgLineSeriesOptions
} from '@siq-js/visual-lib';
import { CoreConstants, isLessThan } from '@siq-js/core-lib';
import { CmsConfig, CmsField, CmsMetric } from '@siq-js/cms-lib';

export class DaypartsSimpleLineProcessor extends SimpleLineProcessor {

  public static generateSimpleLineConfig(params: SimpleLineParams): SimpleLineConfig {
    const selectedDim = params.selectedDimension$.getValue();
    const dimFormatter = GridService.getVisualType(selectedDim).formatter;
    const metricFormatter = GridService.getVisualType(params.metrics[0]).formatter;

    let _data = GridService.jobToArray(params.statResponse)
    .map((value: any) => {
      params.statResponse.getFacts().forEach(cmsMetric => {
        value[cmsMetric.id] = value[cmsMetric.id].weight || value[cmsMetric.id].val || value[cmsMetric.id];
      });

      // Convert the value for the Dimension to be a string (needed by ag-grid axis label)
      value[selectedDim.id] = value[selectedDim.id].toString();
      return value;
    })
    .sort((a: any, b: any) => isLessThan(a[selectedDim.id], b[selectedDim.id]));

    let series: AgLineSeriesOptions[] = [];
    params.metrics.forEach(cmsMetric => {
      series.push(
        {
          type: 'line',
          xKey: selectedDim.id,
          xName: selectedDim.display,
          yKey: cmsMetric.id,
          yName: cmsMetric.display,
          visible: true,
          tooltip: { renderer: (params) => {
            return  DaypartsSimpleLineProcessor.customTooltipRenderer(params);
            }
          }
        }
      );
    });

    let config: SimpleLineConfig = {
      agChartOptions: <AgCartesianChartOptions>{
        theme: ChartsService.getThemePdi(),
        autoSize: true,
        data: params.statResponse ? _data : [],
        series: series,
        legend: {
          enabled: false
        },
        axes: [
          {
            type: 'category',
            position: 'bottom',
            tick: {
              interval: 1,
              width: 1,
              size: 0
            },
            label: {
              formatter: (params) => { // This formats the x-axis label
                // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
                return dimFormatter({value: params.value});
              },
              rotation: -71
            },
            title: {
              enabled: true,
              fontWeight: 'bold',
              text: params.statResponse.getDimensions()[0]?.display
            },
          },
          {
            type: 'number',
            position: 'left',
            tick: {
              minSpacing: 30,
            },
            label: {
              formatter: (params) => { // This formats the x-axis label
                // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
                return metricFormatter({value: params.value});
              }
            },
            title: {
              enabled: true,
              fontWeight: 'bold',
              text: params.statResponse.getFacts()[0]?.display
            },
            gridLine: {
              style: [
                {
                  stroke: 'lightgray',
                  lineDash: [5, 0]
                }
              ],
            }
          }
        ]
      },
    }



    return config;
  }

  /**
   * @param params: SimpleLineParams
   * The output of this fn is the input for SimpleLineComponent.render(chartSettings: ChartSettings)
   */
  public static processor(params: SimpleLineParams): ChartSettings {
    return <ChartSettings>{
      parentActivity: params.parentActivity,
      selectedDimension$: params.selectedDimension$,
      selectedMetrics$: params.selectedMetrics$
    };
  }

  private static customTooltipRenderer(params): AgTooltipRendererResult {
    let dim: CmsField = (CoreConstants.cmsConfig as CmsConfig).findEntity<CmsField>(params.xKey);
    let metric: CmsMetric = (CoreConstants.cmsConfig as CmsConfig).findEntity<CmsMetric>(params.yKey);
    const dimFormatter = GridService.getVisualType(dim).formatter;
    const metricFormatter = GridService.getVisualType(metric).formatter;

    return <AgTooltipRendererResult>{
      content: dimFormatter({value: params.datum[params.xKey]}).toString() + ': ' + metricFormatter({value: params.datum[params.yKey]}).toString()
    };
  }
}

import { Component, ViewChild } from '@angular/core';
import { AffinitiesConfig } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { AffinitiesFormData, AffinitiesFormJson } from 'app/siq-applications/modules/affinities/models/form/affinities-form-data.model';
import { AffinitiesQueryModeModalComponent } from 'app/siq-applications/modules/affinities/components/affinities-query-mode-modal/affinities-query-mode-modal.component';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'siq-js-affinities-form',
  templateUrl: './affinities-form.component.html',
  styleUrls: ['./affinities-form.component.scss'],
})
export class AffinitiesFormComponent {

  @ViewChild('queryMode') queryMode: QueryModeComponent; // component for controlling current schema

  public formData: AffinitiesFormData;
  public queryModeModalConfig: QueryModeModalConfig = { // config for query modal when schema changes
    component: AffinitiesQueryModeModalComponent,
    dataGetter: () => this.formData,
    diffFn: (schema: string) => !this.formData.isValidSchema(schema)
  };
  public overrideSchema: string;

  constructor(
    public config: AffinitiesConfig,
    private affinitiesService: AffinitiesService
  ) {
    if (this.affinitiesService.clonedActivity) { // if this is a cloned form, use the cloned data
      const formValues: AffinitiesFormJson  = _.cloneDeep(JSON.parse(this.affinitiesService.clonedActivity.formValues));
      formValues.name = this.affinitiesService.clonedActivity.getName() + '(copy)';
      formValues.isCloned = true;
      this.formData = this.affinitiesService.createForm(formValues);
    } else { // otherwise create new formData
      this.formData = new AffinitiesFormData();
    }
    this.overrideSchema = this.formData.schema;
    this.formData.setWeekEndingDay$();
  }

  // Submit form
  public submit(): Observable<any> {
    const obs$: Observable<any> = this.affinitiesService.createReport(this.formData);

    // Pass the Observable along to allow for additional subs (like in affinities-stepper)
    return obs$;
  }
}

import { Injectable } from '@angular/core';
import { CmsConfig, CmsField, CmsMetric } from '@siq-js/cms-lib';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  AggregationType,
  AsyncCellComponent,
  ColDef,
  ColGroupDef,
  ColumnHeaderComponent,
  EXCEL_EMPTY_CELL,
  FilterNames,
  GroupCellRenderer,
  GRID_DEFAULTS,
  GridColMetadata,
  GridColType,
  GridOptions,
  IStatResponse,
  MetricColData,
  PinnedDimensionCellComponent,
  PinnedFactCellComponent,
  ProcessCellForExportParams,
  ValueGetterParams,
  VisualDataType,
  VISUAL_CONFIG,
  CellRendererSelectorFunc,
  ExcelService,
  VisualOptions,
  AsyncTotalsAllowed, GridThemes
} from 'libs/visual-lib/src';
import * as _ from 'lodash';
import { CoreConstants, isLessThan } from '@siq-js/core-lib';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  public static readonly AG_GRID_AUTOCOLUMN_ID = 'ag-Grid-AutoColumn';
  public static readonly ENUM_ID_PREPEND = 'ENUM:';
  public static FORMATTER_FN_SUBSTITUTIONS: Map<string, string[]> = new Map<string, string[]>();
  public static readonly NULL_POINT = { val: null, weight: 0 };

  public static addCellClassForRefMetric(refMetric: CmsMetric, colDef: ColDef) {
    // Add a cellClass (based on the ref metric) so the Excel export is formatted correctly
    const p = 'PERCENT_';
    const className = refMetric.id.indexOf(p) === -1 ? (p + refMetric.id) : refMetric.id;
    const match = (CoreConstants.cmsConfig as CmsConfig).findEntity<CmsMetric>(className);
    if (match) {
      (<string[]>colDef.cellClass).push(GridService.ENUM_ID_PREPEND + className);
    } else {
      (<string[]>colDef.cellClass).push(ExcelService.EXCEL_STYLE_GENERIC_PERCENT);
    }
  }

  public static enumFromCellClass(
    cellClassArray: string[],
    additionalDecoratedEnums: (CmsMetric | CmsField)[] = []
  ): CmsMetric | CmsField {

    let out;

    const enumClassString = _.find(cellClassArray, c => _.startsWith(c, GridService.ENUM_ID_PREPEND));

    if (enumClassString) {

      const enumId = enumClassString.split(':')[1];

      out = _.find([
        // ...DimensionService.items.value,
        // ...FactService.items.value,
        ...additionalDecoratedEnums
      ], { id: enumId });
    }

    return out;

  }

  public static generateDefaultGridVisualOptions(): VisualOptions {
    // Create VisualOptions object with required default fields
    /*
      gridConfig.asyncTotalsCalls$ (attribute): GridComponent checks itself and determines whether to show and enable the Async Totals button
      gridConfig.asyncTotalsCallsOverride (attribute): Allows code to programmatically turn on/off AsyncTotals despite whether or not the GridComponent
        meets criteria for when the AsyncTotals button should typically be displayed. In short, this allows a programmatic way to prevent the
        AsyncTotals button from ever showing as part of a specific grid.
     */
    return <VisualOptions>{
      dimensions: [],
      metrics: [],
      gridConfig: {
        allowAsyncTotalsCalls$: new BehaviorSubject<AsyncTotalsAllowed>({show: false, enabled: false}),
        allowAsyncTotalsCallsOverride: true
      }
    };
  }

  public static getDefaultFactCellClassRules(): any {
    const out = {};

    out['numeric'] = () => true;
    out[EXCEL_EMPTY_CELL] = (params) => {
      return _.isNil(params.value) || params.value === '';
    };

    return out;
  }

  static generateDeltaGetter(currKey: string, prevKey: string, valKey = 'val'): (params: ValueGetterParams) => any {
    return (params) => {

      // If this code is being run as part of Excel Export, run this code block
      if (params['type'] && params['type'] === 'excel') {
        const _value = (typeof params['value'] === 'object') ? params['value'][valKey] : params['value'];
        return {
          [valKey]: _value,
          toString: () => {
            // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[valKey])
            return _value;
          }
        };
      }

      // This allows generateDeltaGetter to be called in any context (not just ag-grid)
      currKey = params['_asyncTotalsSpecific'] ? params['_asyncTotalsSpecific'].curr.colId : currKey;
      prevKey = params['_asyncTotalsSpecific'] ? params['_asyncTotalsSpecific'].prev.colId : prevKey;
      const curr = GridService.getDeltaGetterVal(params, currKey);
      const prev = GridService.getDeltaGetterVal(params, prevKey);

      if (_.isNil(curr) && _.isNil(prev)) {
        // When switching from pivotMode to non pivotMode, the code execution was getting here, but the params.data already has the values needed
        // If not found, then return the null object as last resort
        if (params.data && params.data[params.colDef?.colId]) {
          return params.data[params.colDef.colId];
        }

        return {
          [valKey]: null,
          toString: () => {
            // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[valKey])
            return null;
          }
        };
      }

      // If _ty or _ly are null or undefined, use zero (0) so the calculated column will have a value.
      const currVal = GridService.getCurrOrPrevVal(curr, valKey);
      const prevVal = GridService.getCurrOrPrevVal(prev, valKey);

      return {
        [valKey]: currVal - prevVal,
        toString: () => {
          // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[valKey])
          return currVal - prevVal;
        }
      };
    };
  }

  // Takes in a FactResponse and returns a formatter function
  static generateFormatterFn(ref: CmsMetric): ((params) => any) {
    const key = GridService.getDataKey(ref);
    return params => {
      const dataPoint = params.value;
      if (_.isNil(dataPoint)) {
        // Check for (and address) any explicit substitutions
        let _returnVal = null;
        GridService.FORMATTER_FN_SUBSTITUTIONS.forEach((_value: string[], _key: string) => {
          if (!_.isNil(_returnVal)) return;
          if (!!params.node?.group) return;

          // for IDs match ValidationService.LOYALTY_FACT_LIST but not 'AS' aggType, return 'N/A'
          // for Nil values of ref.aggType column, the total (column header) needs to be ‘-∑-’, other values need to be ‘N/A’.
          if (params.node?.rowPinned !== 'top' && _value.includes(ref.id)) {
            if (params.retailerHasCustomerData) {
              // If this record is either (SINGLE_RETAILER + retailer has customer data) -OR- (MULTI_RETAILER + retailer dim value is a retailer that has customer data (params.retailerHasCustomerData) then use number zero
              _returnVal = 0;
            } else {
              _returnVal = _key;
            }
          }

          // for any others with aggType of 'AS' and _returnVal is null/undefined
          if (ref.aggType === 'AS' && _.isNil(_returnVal)) return;
        });
        return _returnVal;
      }
      const value = _.isObject(dataPoint) ? dataPoint[key] : dataPoint;
      if (!_.isFinite(value) && ref.type !== 'STRING') {
        return '';
      }
      return GridService.getVisualType(ref).formatter({ value: value });
    };
  }

  static generatePercentDeltaGetter(currKey: string, prevKey: string, valKey = 'val'): (params: ValueGetterParams) => any {
    return (params) => {
      // If this code is being run as part of Excel Export, run this code block
      if (params['type'] && params['type'] === 'excel') {
        const v = (typeof params['value'] === 'object') ? params['value'][valKey] : params['value'];
        return {
          [valKey]: v,
          toString: () => {
            // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[valKey])
            return v;
          }
        };
      }

      // This allows generateDeltaGetter to be called in any context (not just ag-grid)
      currKey = params['_asyncTotalsSpecific'] ? params['_asyncTotalsSpecific'].curr.colId : currKey;
      prevKey = params['_asyncTotalsSpecific'] ? params['_asyncTotalsSpecific'].prev.colId : prevKey;
      const curr = GridService.getDeltaGetterVal(params, currKey);
      const prev = GridService.getDeltaGetterVal(params, prevKey);

      /*
      NOTE - This function can be called as part of the async-totals process. When this happens, the calling context comes from
      cell-renderer.service, and this changes the (above) "params: ValueGetterParams" in a small but important way.
      The "getDeltaGetterVal()" function ultimately calls "params.getValue()". This function call runs different code depending on
      from where it was called. If examining this object you will see "params.getValue[[BoundThis]]: CellComp". In this case, the
      "getValue()" function will look at the colId of the cell and try to access the value using this (for instance, data[colId] or
      aggData[colId]). If this happens, then both "curr" and "prev" will be same, and their "val" property will be a calculated result.
      Then, this "val" property will be read (below) and we end up with "currVal" and "prevVal" being identical.
       */
      if (_.isNil(curr) && _.isNil(prev)) {
        // When switching from pivotMode to non pivotMode, the code execution was getting here, but the params.data already has the values needed
        // If not found, then return the null object as last resort
        if (params.data && params.data[params.colDef?.colId]) {
          return params.data[params.colDef.colId];
        }

        return {
          ty: null,
          ly: null,
          [valKey]: null,
          tyw: null,
          lyw: null,
          toString: () => {
            // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[valKey])
            return null;
          }
        };
      }

      const currVal = GridService.getCurrOrPrevVal(curr, valKey);
      const prevVal = GridService.getCurrOrPrevVal(prev, valKey);

      return {
        ty: currVal,
        ly: prevVal,
        [valKey]: !!prevVal ? ((currVal - prevVal) / prevVal) : null, // This prevents Divide-by-zero error
        tyw: _.get(curr, 'weight') || null,
        lyw: _.get(prev, 'weight') || null,
        toString: () => {
          // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[valKey])
          return !!prevVal ? ((currVal - prevVal) / prevVal) : 1; // This prevents Divide-by-zero error in Excel and charts if prevVal is zero
        }
      };
    };
  }

  static getCurrOrPrevVal(currOrPrev, theValKey): number {
    if (_.isObject(currOrPrev)) {
      return _.get(currOrPrev, theValKey) || 0;
    } else {
      return currOrPrev || 0;
    }
  }

  static generateValueGetterFn(ref: CmsMetric, valKey?: string): ((params) => any) {
    return params => {
      const _data = params.data || params.node.data;
      let obj = _.get(_data, valKey || ref.id);

      // PivotMode could result in no match. If so look for the 'pivot_xyz' colId
      if (_.isNil(obj) && !_.isNil(_data)) {
        obj = _.get(_data, params.colDef.colId);
      }

      //after AG GRID update params.data could be(always are?) undefined while in pivot mode
      if (_.isNil(obj)) {
        obj = params.value; // params.value is object containing val
      }

      const valueKey = this.getVisualType(ref).key || 'val';

      return GridService.getValueGetterFinalVal(obj, valueKey);
    };
  }

  static getGridThemeName(theme: string): string {
    if (theme === ThemesService.THEMES.DARK) {
      return GridThemes.ALPINE_DARK;
    } else {
      return GridThemes.ALPINE;
    }
  }

  static getValueGetterFinalVal(entity: any, valueKey: string): number {
    // Determine the "finalVal". If 'entity' is an Object then try to access the entity[valueKey]
    // If the "finalVal" is an object, then return null as object cannot be returned (it will break Excel export)
    let finalVal;
    if (typeof entity === 'object') {
      finalVal = entity ? entity[valueKey] : null;
    } else {
      finalVal = entity;
    }
    return typeof finalVal === 'object' ? null : finalVal;
  }

  public static getAgg(metric: CmsMetric, fn?: boolean) {
    const aggStr = GRID_DEFAULTS.AGG_MAP[metric.aggType];
    if (!aggStr) return null;
    if (fn) {
      return GRID_DEFAULTS.CUSTOM_AGG_FUNCTIONS[aggStr];
    } else {
      return aggStr;
    }
  }

  public static getDataKey(ref: CmsMetric | CmsField): string {
    if (ref instanceof CmsMetric && ref.aggType === AggregationType.NO_AGG) {
      return 'val';
    }
    return this.getVisualType(ref).key || 'val';
  }

  public static getFilterByFactType(factType: string): string {
    // Check VISUAL_CONFIG.VISUAL_DATA_TYPES to find the corresponding filterType; Use NUMBER as fallback
    const matchVisualDataType: VisualDataType = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, { type: factType });
    let out: string;
    if (!_.isNil(matchVisualDataType)) {
      out = matchVisualDataType.filterType ? matchVisualDataType.filterType.toString() : FilterNames.NUMBER.toString();
    } else {
      out = FilterNames.NUMBER.toString();
    }
    return out;
  }

  public static getMetricColData(columnDefs: (ColDef | ColGroupDef)[], metrics: CmsMetric[]): MetricColData[] {
    const metricColData = [];
    const _findId = (c: ColDef | ColGroupDef) => {
      const _c = <any>c;
      if (_c.children) {
        // ColGroupDef
        (<ColGroupDef>c).children.forEach(child => {
          _findId(child);
        });
      } else {
        // ColDef
        if ((<any>c).aggFunc) {
          const m = _.find(metrics, {id: (<ColDef>c).field});

          metricColData.push(<MetricColData>{
            key: (<ColDef>c).colId,
            colDef: <ColDef>c,
            metric: m
          });
        }
      }
    };

    columnDefs.forEach((c: ColDef | ColGroupDef) => {
      _findId(c);
    });

    return metricColData;
  }

  public static getVisualType(entity: CmsMetric | CmsField | string): VisualDataType {
    let res: VisualDataType;
    if (entity instanceof CmsMetric) {
      res = VISUAL_CONFIG.VISUAL_DATA_TYPES.find(dt => dt.type === entity.type);
    } else if (entity instanceof CmsField) {
      if (entity.id === 'DAY') {
        // TODO: Temporary fix until DAY dimension is changed to a number
        return VISUAL_CONFIG.VISUAL_DATA_TYPES.find(dt => dt.type === 'DAY');
      }
      res = VISUAL_CONFIG.VISUAL_DATA_TYPES.find(dt => dt.type === entity.type);
    } else {
      res = VISUAL_CONFIG.VISUAL_DATA_TYPES.find(dt => dt.type === entity);
    }
    return res || VISUAL_CONFIG.VISUAL_DATA_TYPES.find(dt => dt.type === 'STRING');
  }

  static jobToArray(job: IStatResponse, suppressDimVisualParse = false): any[] {

    const dimsAndFacts: (CmsMetric | CmsField)[] = _.concat(job.dimensions as any[], job.facts as any[]);
    const visualTypes = dimsAndFacts.map(t => GridService.getVisualType(t));
    const dimensionValues = job.getDimensionValues();

    return job.getValues().map((rawData: string[]) => {
      // rawData is an entry in the valuesMatrix - an array of strings
      const output = {};
      rawData.forEach((cellData: string, i) => {
        const visualType = visualTypes[i];
        const entity = dimsAndFacts[i];
        let val;
        if (entity instanceof CmsField) {
          // cellData is a dimensionValues index, need to grab the actual string value from the dimensionMatrix
          if (entity.isNull()) {
            return;
          }
          val = suppressDimVisualParse ? dimensionValues[i][cellData] :  visualType.parse(dimensionValues[i][cellData]);
        } else if (entity instanceof CmsMetric) {
          // cellData is a fact value
          val = { val: visualType.parse(cellData) };
        }
        output[dimsAndFacts[i].id] = val;
      });
      return output;
    });
  }

  public static processCellCallback(params: ProcessCellForExportParams, colDefMeta: Map<string, GridColMetadata>, gridOptions?: GridOptions): string {

    const pivotPrefix = ' -> '; // Primary dimension values are prefixed by this to denote a row group
    const colDef = params.column.getColDef();
    let primaryColDef;
    if (params.api.isPivotMode() && colDef.pivotValueColumn) {
      primaryColDef = colDef.pivotValueColumn.getColDef();
    }
    primaryColDef = primaryColDef || colDef;

    const meta = colDefMeta.get(primaryColDef.colId);

    // AG-Grid's export doesn't use any valueFormatters, so they need to be manually applied where necessary
    let val = params.value;

    if (params.node.isRowPinned()) { // Totals row case
      if (params.column.isLastLeftPinned() && !colDef.cellRenderer) { //PinnedDimensionCellComponent is only used when no cellRenderer is defined
        return 'Totals';
      } else if (meta) {
        if (meta.ref instanceof CmsField) {
          return '';
        } else if (meta.ref instanceof CmsMetric && !meta.ref.canAgg()) {
          return '';
        }
        if (_.isNil(val)) {
          val = params.node.data[params.column.getColDef().colId];
          if (_.isNil(params.value)
            || (typeof params.value == 'object' && params.value.toString && _.isNil(params.value.toString()))
          ) {
            params.value = val;
          }
        }
      }
    }

    // If there is no val, then return empty string (cannot send null to Excel)
    if (_.isNil(val) || (val.toString && _.isNil(val.toString()))) {
      // Final check for value
      return '';
    }

    const pivotValFormatters = {};

    colDefMeta.forEach((v, k) => {
      if (v.gridColType === GridColType.FIELD) {
        pivotValFormatters[k] = GridService.getVisualType(v.ref).formatter;
      }
    });

    const pv = typeof params.value === 'object' && !_.isNil(params.value) ? params.value.val : params.value;
    if (pv && pv.toString().indexOf(pivotPrefix) !== -1) {

      const vals = pv.replace(pivotPrefix, '').split(pivotPrefix);
      const colIds = params.api.getRowGroupColumns().map(c => c.getColDef().colId);

      let valsFormatted = '';

      for (let i = 0; i < vals.length; i++) {
        valsFormatted += pivotPrefix + pivotValFormatters[colIds[i]]({ value: vals[i] });
      }

      return valsFormatted;

    }

    let changeParam = false;
    try {
      // params.value cal be null, the cause may be because of there is used custom element inside ag-grid in Totals
      if (_.isNil(params.value) &&  !_.isNil(val)) {
        params.value = val;
        changeParam = true;
      }

      if (params.type === 'excel' && meta?.ref instanceof CmsMetric) {
        // For CmsMetrics, return the raw value via the colDef.valueGetter if possible
       const retVal = typeof colDef.valueGetter === 'function' ? colDef.valueGetter(params as any) : params.value || '';
       // After upgrading to Ag Grid 30, retVal returns 'undefined' for Dayparts. In this case, returning 'params.value'
       return !_.isNil(retVal) ? retVal : params.value || '';
      } else {
        return typeof colDef.valueFormatter === 'function' ? colDef.valueFormatter(params as any) : params.value || '';
      }

    } catch (e) {
      return params.value || '';
    } finally {
      if (changeParam) {
        params.value = null;
      }
    }
  }

  static setMetricAsyncSettings(colDef: ColDef): void {
    if (colDef.cellRendererParams?.colDefMeta?.ref.aggType === AggregationType.ASYNC) {
      colDef.cellRenderer = AsyncCellComponent;
      colDef.allowedAggFuncs = ['async'];
      if (!colDef.cellClass) {
        colDef.cellClass = ['async-metric'];
      }
      colDef.cellClass = [...new Set([...(<string[]>colDef.cellClass), 'async-metric'])];
    }
  }

  static dimensionCellRendererSelector: CellRendererSelectorFunc = (params) => {
    if(params.colDef.cellRenderer) {
      return { component: params.colDef.cellRenderer };
    }
    // If the row is pinned then it is a "Totals" row
    if (params.node.isRowPinned && params.node.rowPinned == 'top') {
      return { component: PinnedDimensionCellComponent };
    }
    else if (!!params.node.group && !!params.column?.getColDef()?.showRowGroup) {
      // If params.node.group is true, then this is a rowGroup
      // The 'showRowGroup' (string | boolean) ag-grid property if "truthy" then use GroupCellRenderer
      // This is a Dim that is actively grouping rows
      return { component: GroupCellRenderer };
    }
    else {
      return undefined;
    }
  }

  static factCellRendererSelector: CellRendererSelectorFunc = (params) => {
    if(params.colDef.cellRenderer) {
      return { component: params.colDef.cellRenderer };
    }
    if(params.node.isRowPinned && params.node.rowPinned == 'top') {
      return { component: PinnedFactCellComponent };
    }
    else {
      return undefined;
    }
  }

  private static getDeltaGetterVal(params: ValueGetterParams, key: string) {
    const fallbackGetter = (k: string) => {
      // Check all possible locations of the data
      let _data = params[k];
      if (!_data && params.data) {
        _data = params.data[k];
      } else if (!_data && params.node?.data) {
        _data = params.node.data[k];
      } else if (!_data && params.node?.aggData) {
        _data = params.node.aggData[k];
      }
      return _data;
    };

    const getValue = params.getValue || fallbackGetter;

    let _val = getValue(key);
    const keyValue = params.getValue ? params.getValue(key) : params[key];
    if (!_.isEqual(keyValue, fallbackGetter(key))) {
      // This object is from/for the cellRenderer; Instead use the one from the fallbackGetter
      _val = fallbackGetter(key);
    }

    // Use !_.isNil() check since _val can be an object or a number, and if it is a number it could be zero (0)
    // so a regular "truthy" check could give unexpected results
    _val = !_.isNil(_val) ? _val : fallbackGetter(key);

    return _val;
  }

  constructor() { }

  generateDimensionColumn(dimension: CmsField, colDefMeta: Map<string, GridColMetadata>, key?: string): ColDef {
    // Explicitly set valueGetter due to ag-grid not finding/setting values sometimes (seen w/ keys w/ parenthesis)
    const vizType = GridService.getVisualType(dimension);
    const column: ColDef = {
      headerName: dimension.display,
      colId: dimension.id,
      field: dimension.id,
      valueFormatter: params => vizType.formatter(params),
      headerComponent: <any>ColumnHeaderComponent,
      enableRowGroup: true,
      enablePivot: true,
      cellClass: [GridService.ENUM_ID_PREPEND + dimension.id], // TODO: EXCEL EXPORT
      comparator: (a, b) => isLessThan(a, b),
      pivotComparator: (a, b) => isLessThan(vizType.parse(a), vizType.parse(b)),
      menuTabs: ['filterMenuTab'],
      cellRendererSelector: GridService.dimensionCellRendererSelector,
      filterParams: {
        clearButton: true
      },
      chartDataType: 'category',
      cellRendererParams: {
        colDefMeta: {
          colId: dimension.id,
          ref: dimension,
          gridColType: GridColType.FIELD
        }
      }
    };

    column.filter = (vizType.filterType || FilterNames.TEXT).toString();

    this.setGridColMetaData(colDefMeta, key || dimension.id, {
      gridColType: GridColType.FIELD,
      ref: dimension
    });

    return column;
  }

  generateMetricColumn(metric: CmsMetric, colDefMeta: Map<string, GridColMetadata>, key?: string): ColDef {
    const formatterFn = GridService.generateFormatterFn(metric);
    const valueGetterFn = GridService.generateValueGetterFn(metric, key);
    const column: ColDef = {
      chartDataType: 'series',
      enableValue: true,
      headerName: metric.display,
      colId: metric.id,
      field: metric.id,
      valueFormatter: params => {
        return formatterFn(params);
      },
      valueGetter: params => {
        return valueGetterFn(params);
      },
      headerComponent: <any>ColumnHeaderComponent,
      menuTabs: ['filterMenuTab'],
      cellRendererSelector: GridService.factCellRendererSelector,
      cellClass: [GridService.ENUM_ID_PREPEND + metric.id, 'numeric'], // TODO: EXCEL EXPORT
      cellClassRules: GridService.getDefaultFactCellClassRules(), // TODO: EXCEL EXPORT
      comparator: (a, b) => {
        const aa = typeof a === 'object' ? _.get(a, 'val') : a;
        const bb = typeof b === 'object' ? _.get(b, 'val') : b;
        return isLessThan(aa, bb);
      },
      filterParams: {
        clearButton: true
      },
      headerTooltip: metric.display,
      cellRendererParams: {
        colDefMeta: {
          colId: metric.id,
          ref: metric,
          gridColType: GridColType.METRIC
        }
      }
    };

    switch (metric.type) {
      case '':
      case 'METADATA':
        column.hide = true;
        break;

      default:

        if (_.isString(column.cellClass)) {
          column.cellClass = column.cellClass.split(' ');
        }

        if (_.isArray(column.cellClass)) {
          column.cellClass = column.cellClass.concat(['numeric']);
        }

        column.filter = GridService.getFilterByFactType(metric.type);

    }
    column.aggFunc = GridService.getAgg(metric);
    column.enableValue = true;

    GridService.setMetricAsyncSettings(column);

    // TODO: Eventually we may be able to remove/eliminate this because now attaching ColDef.cellRendererParams.colDefMeta (above)
    this.setGridColMetaData(colDefMeta, key || metric.id, {
      gridColType: GridColType.METRIC,
      ref: metric
    });

    return column;
  }

  private setGridColMetaData(colDefMeta: Map<string, GridColMetadata>, key: string, gridColMetaData: GridColMetadata): void {
    colDefMeta.set(key, gridColMetaData);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromoRoutingModule } from './routes/promo-routing.module';
import { FormsModule } from '@angular/forms';
import { ActivityModule } from 'app/activity/activity.module';
import { CoreModule } from 'app/core/core.module';
import { FilterModule } from 'app/filter/filter.module';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';
import { SiqFormsModule } from 'app/siq-forms/siq-forms.module';
import { GridModule } from 'app/grid/grid.module';
import { PromoListComponent } from './components/promo-list/promo-list.component';
import { PromoFormComponent } from './components/promo-form/promo-form.component';
import { PromoResultComponent } from './components/promo-result/promo-result.component';
import { PromoQueryModeModalComponent } from './components/promo-query-mode-modal/promo-query-mode-modal.component';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { PromoStepperComponent } from './components/promo-stepper/promo-stepper.component';
import { PromoCriteriaRendererComponent } from './components/renderers/promo-criteria-renderer/promo-criteria-renderer.component';
import { UpcSelectionComponent } from './components/promo-stepper/upc-selection/upc-selection.component';
import { ComparisonDatesSelectionComponent } from './components/promo-stepper/comparison-dates-selection/comparison-dates-selection.component';
import { ComparisonGroupsHolderComponent } from './components/promo-stepper/comparison-groups-holder/comparison-groups-holder.component';
import { ComparisonGroupComponent } from 'app/siq-applications/modules/promo/components/promo-stepper/comparison-groups-holder/comparison-group/comparison-group.component';
import { SovHolderComponent } from './components/promo-stepper/sov-holder/sov-holder.component';
import { SovComponent } from './components/promo-stepper/sov-holder/sov/sov.component';
import { VisualLibModule, AgGridModule } from '@siq-js/visual-lib';
import { KpiRendererComponent } from './components/renderers/kpi-renderer/kpi-renderer.component';
import { TakeRateResultComponent } from './components/promo-result/take-rate-result/take-rate-result.component';
import { SovResultComponent } from './components/promo-result/sov-result/sov-result.component';
import { PromoTakerateDrilldownRendererComponent } from './components/renderers/promo-takerate-drilldown-renderer/promo-takerate-drilldown-renderer.component';
import { KpiChipComponent } from './components/promo-result/kpi-chip/kpi-chip.component';
import { PromoSovDrilldownRendererComponent } from './components/renderers/promo-sov-drilldown-renderer/promo-sov-drilldown-renderer.component';
import { SovHelperComponent } from './components/promo-result/sov-helper/sov-helper.component';
import { TakeRateHelperComponent } from './components/promo-result/take-rate-helper/take-rate-helper.component';
import { PromoCloneResolverService } from 'app/siq-applications/modules/promo/services/promo-clone-resolver.service';

@NgModule({
  declarations: [PromoListComponent, PromoFormComponent, PromoResultComponent, PromoQueryModeModalComponent, PromoStepperComponent, UpcSelectionComponent, ComparisonDatesSelectionComponent, ComparisonGroupComponent, ComparisonGroupsHolderComponent, SovHolderComponent, SovComponent, PromoCriteriaRendererComponent, KpiRendererComponent, TakeRateResultComponent, SovResultComponent, PromoTakerateDrilldownRendererComponent, KpiChipComponent, PromoSovDrilldownRendererComponent, SovHelperComponent, TakeRateHelperComponent],
  imports: [
    CommonModule,
    ActivityModule,
    FormsModule,
    SharedModule,
    CoreModule,
    GridModule,
    VisualLibModule,
    SiqFormsModule,
    AgGridModule,
    FilterModule,
    DatesModule,
    PromoRoutingModule,
  ],
  providers: [
    PromoConfig,
    PromoService,
    PromoCloneResolverService
  ]
})
export class PromoModule { }

<button mat-icon-button mat-dialog-close="cancel" *ngIf="!data.cancelText" class="close-button">
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-title *ngIf="data.header">{{ data.header }}</div>

<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close="cancel" color="accent" class="secondary-button" *ngIf="data.cancelText">{{ data.cancelText }}</button>
  <button mat-raised-button mat-dialog-close="confirm" color="warn">{{ data.confirmText }}</button>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'siq-js-filtersets-legend',
  templateUrl: './filtersets-legend.component.html',
  styleUrls: ['./filtersets-legend.component.scss']
})
export class FiltersetsLegendComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<mat-radio-group [(ngModel)]="monthMode" (change)="updateForm()">
  <mat-radio-button value="byMonthDay">
    <mat-form-field class="form-field-number" color="accent">
      <mat-select [(value)]="monthDayValue" (valueChange)="updateForm()">
        <mat-option *ngFor="let monthDay of monthDays" [value]="monthDay">
          {{monthDay}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    of every month
  </mat-radio-button>
  <!--<br>-->
  <!--<mat-radio-button value="byDay">-->
    <!--<mat-form-field class="form-field-number">-->
      <!--<mat-select [(value)]="monthWeekIndex" (valueChange)="updateForm()">-->
        <!--<mat-option *ngFor="let weekIndex of weekIndexes" [value]="weekIndex">-->
          <!--{{weekIndex}}-->
        <!--</mat-option>-->
      <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--<mat-form-field class="form-field-frequency">-->
      <!--<mat-select [(value)]="monthWeekValue" (valueChange)="updateForm()">-->
        <!--<mat-option *ngFor="let weekday of weekdays" [value]="weekday">-->
          <!--{{weekday}}-->
        <!--</mat-option>-->
      <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--of every month-->
  <!--</mat-radio-button>-->
</mat-radio-group>

import { Routes } from '@angular/router';
import { AppGuard } from 'app/guards/app-guard';
import { AuthGuard } from 'app/guards/auth-guard';
import { ApplicationHash } from '@siq-js/core-lib';
import { IncrementalsListComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-list/incrementals-list.component';
import { IncrementalsFormComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-form/incrementals-form.component';
import { IncrementalsResultComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-result/incrementals-result.component';
import { IncrementalsCloneResolverService } from 'app/siq-applications/modules/incrementals/services/incrementals-clone-resolver.service';

export const incrementalsRoutes: Routes = [
  {
    path: 'app/incrementals',
    redirectTo: 'app/assortment'
  },
  {
    path: 'app/assortment',
    canActivate: [
      AppGuard,
      AuthGuard
    ],
    data: {
      applicationHash: ApplicationHash.INCREMENTALS
    },
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: IncrementalsListComponent
          }
        ]
      },
      {
        path: '~',
        children: [
          {
            path: '',
            component: IncrementalsFormComponent
          }
        ]
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: IncrementalsResultComponent
          },
          {
            path: 'clone',
            resolve: {nothing: IncrementalsCloneResolverService},
            children: []
          },
          {
            path: 'export',
            component: IncrementalsResultComponent
          }
        ]
      }
    ]
  }
];

import { Component, Injector } from '@angular/core';
import { GridComponent } from 'libs/visual-lib/src/lib/modules/grid/components/grid/grid.component';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { filter, takeUntil } from 'rxjs';
import { CmsMetric } from '@siq-js/cms-lib';
import { CellRendererService } from 'libs/visual-lib/src/lib/modules/grid/services/cell-renderer/cell-renderer.service';
import * as _ from 'lodash';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'siq-js-async-cell',
  templateUrl: './async-cell.component.html',
  styleUrls: ['./async-cell.component.scss']
})
export class AsyncCellComponent extends BaseSiqComponent implements AgRendererComponent {

  public asyncResultsPopulated: boolean;
  public dependsOnAsyncMetrics: boolean;
  public group: boolean;
  public isLoading: boolean;
  public isPinned: boolean;
  public table: GridComponent;
  public value: string;
  public valueClass: string;
  private _params: any;
  private readonly pinnedTotalsCssClass = 'pinned-totals';

  constructor(
    private injector: Injector
  ) {
    super();
    this.table = this.injector.get(GridComponent);
    this.valueClass = '';
  }

  agInit (params: any) {
    // Grand Totals Row identifier
    this.isPinned = params.node.rowPinned === 'top';
    if (this.isPinned) {
      this.valueClass = this.pinnedTotalsCssClass;
    }

    if (!_.isNil(params.valueFormatted)) {
      this.value = params.valueFormatted;
    } else if (params.column.colDef.valueFormatter && params.node.data && !_.isNil(params.node.data[params.colId])) {
      this.value = params.column.colDef.valueFormatter({value: params.node.data[params.colId]['val']});
    } else {
      this.value = undefined;
    }

    this._params = params;
    this.group = params.node.group;
    if (!!this.group) {
      // Set subscription for AsyncTotals but only for rowGroups
      this.setAsyncSubscription();
    }

    const metaKey = params.api.isPivotMode() && params.colDef.pivotValueColumn ? params.colDef.pivotValueColumn.colId : params.colDef.colId;
    const fact = this.table.colDefMeta.get(metaKey).ref as CmsMetric;
    this.dependsOnAsyncMetrics = CellRendererService.setDependsOnAsyncMetrics(fact, params);

    this.asyncResultsPopulated = !_.isNil(this.value);
  }

  refresh (): boolean {
    return false;
  }

  private setAsyncSubscription() {
    this.table.asyncTotalsComponent.currentResults$.pipe(
      filter(data => !! data),
      takeUntil(this.unsub$)
    ).subscribe(activity => {
      const colId = this._params.colDef.colId;
      const _data = !!this._params.node.group ? this._params.node.aggData : this._params.node.data;

      // Use the ColDef.valueFormatter function (if it exists)
      const _v = (typeof _data[colId] === 'object') ? _data[colId]?.val : _data[colId];
      if (typeof _v === 'string') {
        this.value = _v; // already formatted
      } else {
        this.value = this._params.colDef.valueFormatter ? this._params.colDef.valueFormatter({value: _v}) : _v;
      }
      this.asyncResultsPopulated = !_.isNil(this.value);
    });
  }
}

import { Component, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterGroupComponent } from 'app/filter/components/filter-group/filter-group.component';
import { FilterSelection } from 'app/filter/models/filter-selection';

@Component({
  selector: 'siq-column-filter-modal',
  templateUrl: './column-filter-modal.component.html',
  styleUrls: ['./column-filter-modal.component.scss']
})
export class ColumnFilterModalComponent {
  @ViewChild('filters') filterGroupComponent: FilterGroupComponent;

  constructor(
    public dialogRef: MatDialogRef<ColumnFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      model: FilterSelection[],
      schema: string
    }
  ) {
  }

  apply() {
    this.dialogRef.close(this.filterGroupComponent.getModel());
  }

}

import {
  VisualOptions,
  IStatResponse,
  VisualService,
  GridComponent,
  GridSettings,
  GridOptions,
  GridService,
  GridApi,
  ColDef,
} from '@siq-js/visual-lib';
import { AppComponent } from 'app/app.component';
import { DrawerService } from 'app/core/modules/drawer/services/drawer.service';
import { SovHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-helper/sov-helper.component';
import { SovResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-result/sov-result.component';
import { TakeRateResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-result/take-rate-result.component';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs';
import { TakeRateHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-helper/take-rate-helper.component';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { PromoDimensionKeys } from 'app/siq-applications/modules/promo/models/promo.enums';
import * as _ from 'lodash';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export interface PromoResultGridParams {
  data?: any[];
  gridVisualOptions?: VisualOptions;
  parent: TakeRateResultComponent | SovResultComponent | SovHelperComponent | TakeRateHelperComponent;
  readyForExport$: BehaviorSubject<boolean>;
  statResponse?: IStatResponse;
  promoUPCs?: Set<string>;
  sov$?: BehaviorSubject<SourceOfVolume>;
}

export class PromoResultGridProcessor {
  private static PDI_LIGHT_BLUE = THEME_COLORS.SKY;

  public static generateGridVisualOptions (gridParams: PromoResultGridParams): VisualOptions {
    let vo: VisualOptions = GridService.generateDefaultGridVisualOptions();

    let globalDateRange: DateRangeInterface = {};
    if (gridParams.parent instanceof SovResultComponent) {
      globalDateRange.begin = gridParams.parent.parent.formData.promoDateRangeInterface.begin;
      globalDateRange.end = gridParams.parent.parent.formData.promoDateRangeInterface.end;

    } else if (gridParams.parent instanceof TakeRateResultComponent) {
      globalDateRange.begin = gridParams.parent.parent.formData.promoDateRangeInterface.begin;
      globalDateRange.end = gridParams.parent.parent.formData.promoDateRangeInterface.end;

    } else if (gridParams.parent instanceof TakeRateHelperComponent) {
      globalDateRange.begin = gridParams.parent.helperParams.parent.formData.promoDateRangeInterface.begin;
      globalDateRange.end = gridParams.parent.helperParams.parent.formData.promoDateRangeInterface.end;

    } else if (gridParams.parent instanceof SovHelperComponent) {
      globalDateRange.begin = gridParams.parent.params.parent.parent.formData.promoDateRangeInterface.begin;
      globalDateRange.end = gridParams.parent.params.parent.parent.formData.promoDateRangeInterface.end;
    }

    let autoGroupColumnDef: ColDef = {};
    if (gridParams.parent instanceof TakeRateHelperComponent) {
      autoGroupColumnDef = {
        filter: 'agGroupColumnFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerComponent: null, // disable our custom ColumnHeaderComponent
        headerName: gridParams.parent.helperParams.activity.getJob().getResponse().getDimensions().find(d => d.id.includes(PromoDimensionKeys.UPC_DESC)).display,
        cellRenderer: params => params.value
      }
    }

    return _.merge(vo, {
      hidePanel: true,
      hideTitle: true,
      metrics: [],
      dimensions: [],
      globalDateRange: globalDateRange,
      gridConfig: {
        persistState: true,
        onGridReady: (grid) => {
          const gridComponent = <GridComponent>VisualService.findVisualization(gridParams.gridVisualOptions.vizId);
          gridParams.parent.grid = gridComponent;

          AppComponent.resize$
          .pipe(
            takeUntil(gridParams.parent.unsub$),
            debounceTime(100)
          )
          .subscribe(() => PromoResultGridProcessor.resizeGrid(grid.api));

          DrawerService.drawerResized$
          .pipe(takeUntil(gridParams.parent.unsub$))
          .subscribe(() => PromoResultGridProcessor.resizeGrid(grid.api));
        },
        afterRender: (grid) => {
          gridParams.parent.updateView();
        },
        customGridOptions: {
          sideBar: false,
          autoGroupColumnDef: autoGroupColumnDef,
          getRowStyle: params => {
            // if promoUPCs are not empty & the dimension is UPC
            if (gridParams.promoUPCs) {
              const currSov = gridParams.sov$.value;
              if (currSov.dimension.id.includes(PromoDimensionKeys.UPC)) {

                if (params.data && gridParams.promoUPCs.has(params.data[currSov.dimension.id])) {
                  return {
                    border: '1px solid ' + PromoResultGridProcessor.PDI_LIGHT_BLUE
                  };
                }
              }
            }
          }
        },
        gridReadyForExport$: gridParams.readyForExport$,
        maxColumnsToFit: 20,
        tableHeight: '100%'
      }
    });
  }

  public static resizeGrid(gridApi: GridApi) {
    gridApi.autoSizeAllColumns();
    gridApi.sizeColumnsToFit();
  }

  public static processor(gridParams: PromoResultGridParams): GridSettings {
    // Generate the GridOptions (overrides) which will get merged to the default GridOptions created by/in GridComponent
    let gridOptions: GridOptions = gridParams.gridVisualOptions.gridConfig.customGridOptions || {};

    const gridSettings: GridSettings = <GridSettings>{
      data: gridParams.gridVisualOptions.data, // set pointer for ease of use/readability later
      gridOptions: gridOptions,
      gridVisualOptions: gridParams.gridVisualOptions,
      parentActivity: gridParams.gridVisualOptions.parentActivity // set pointer for ease of use/readability later
    };

    // To enable AsyncTotals for Promo (if needed?), we just need to uncomment the following line. Everything else is already setup.
    // GridTotalsService.provideAsyncTotalsFunctionality(gridSettings);
    return gridSettings;
  }
}

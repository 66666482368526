<mat-dialog-content>
  <siq-filter-group
    *ngIf="data"
    #filters
    [schemaController]="data.schema"
    [initModel]="data.model"
    [filterFormArray]="data.model"
    [isColumnFilter]="true"
  ></siq-filter-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="null" color="accent" class="secondary-button">Cancel</button>
  <button mat-raised-button (click)="apply()" color="accent">Apply</button>
</mat-dialog-actions>

import * as _ from 'lodash';
import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { ApplicationHash, DateUnit, WeekEndingDay } from '@siq-js/core-lib';
import { TimeAggregate } from 'app/siq-applications/modules/report-builder/models/time-aggregate.model';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { Injectable } from '@angular/core';
import { Activity } from 'app/activity/models/activity.model';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { SortOptions, VisualizationState } from '@siq-js/visual-lib';
import { UtilsService } from 'app/core/services/utils/utils.service';

@Injectable()
export class ReportBuilderConfig extends SiqApplicationConfig {

  // interface fields
  public appId = ApplicationHash.REPORT_BUILDER;
  public pollInterval = 2000;
  public syncDebounceTime = 1000;
  public actions: ActivityOptionsActions[] = [
    ActivityOptionsActions.CLONE,
    ActivityOptionsActions.RESULTS,
    ActivityOptionsActions.SHARE,
    ActivityOptionsActions.DELETE
  ];
  public updateGridSizeEvents = [
    'filterChanged',
    'columnVisible',
    'columnRowGroupChanged',
    'columnPivotChanged',
    'columnPivotModeChanged',
    'columnEverythingChanged',
    'columnGroupChanged',
    'rowGroupOpened'
  ];

  // public customDateRanges: (min: moment.Moment, max: moment.Moment) => any;
  public defaultSort: SortOptions;
  public timeAggregates: TimeAggregate[];
  public placeholder = Activity.ActivityPlaceholder; // instance copy of static field for use in template

  constructor() {
    super();
    this.defaultSort = {
      sort: 'desc',
      byFn: (s: VisualizationState) => {
        return '0'; // Always sort by the first metric
      }
    };

    this.timeAggregates = [
      {
        name: 'Year',
        key: 'year',
        unit: 'Year',
        calcFn: (s, e) => DatesService.getDifference(DateUnit.YEAR)(e, s) + 1
      },
      {
        name: 'Year-Month',
        key: 'yearmon',
        unit: 'MM-YY',
        calcFn: (s, e) => DatesService.getDifference(DateUnit.MONTH)(e, s) + 1
      },
      {
        name: 'Year-Month-Day',
        key: 'yearmonday',
        unit: 'MM/DD/YY',
        calcFn: (s, e) => DatesService.getDifference(DateUnit.DAY)(e, s) + 1
      },
      {
        name: 'Quarter',
        key: 'quarter',
        unit: 'Q',
        calcFn: (s, e) => DatesService.getDifference(DateUnit.QUARTER)(e, s) + 1
      },
      {
        name: 'Month',
        key: 'month',
        unit: 'Month',
        calcFn: (s, e) => _.min([DatesService.getDifference(DateUnit.MONTH)(e, s) + 1, 12])
      },
      ...this.addWeekEndingTimeAggregates(),
      {
        name: 'Day',
        key: 'day',
        unit: 'Day',
        calcFn: (s, e) => _.min([DatesService.getDifference(DateUnit.DAY)(e, s) + 1, 31])
      },
      {
        name: 'Day of Week',
        key: 'dayofweek',
        unit: 'Day',
        calcFn: (s, e) => _.min([DatesService.getDifference(DateUnit.DAY)(e, s) + 1, 7])
      },
      {
        name: 'Hour of the Day',
        key: 'hour',
        unit: 'Hour',
        calcFn: (s, e) => 24
      },
      {
        name: 'Day Part',
        key: 'day_part',
        unit: 'Day Part',
        calcFn: (s, e) => 5
      }
    ];
  }

  private addWeekEndingTimeAggregates(): TimeAggregate[] {
    const res: TimeAggregate[] = [];
    for (let day in WeekEndingDay) {
      res.push({
        name: 'Week Ending ' + UtilsService.capitalizeFirstLetter(WeekEndingDay[day]),
        key: DatesService.WEEK_END_PREFIX + WeekEndingDay[day],
        unit: 'Week',
        calcFn: (s, e) => DatesService.getDifference(DateUnit.WEEK)(e, s) + 1
      });
    }
    return res;
  }
}

import { Component, OnDestroy } from '@angular/core';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { UsesActivityGrid } from 'app/activity/components/activity-grid/activity-grid.component';
import { ReportActivity } from 'app/siq-applications/modules/report-builder/models/report-activity.model';
import { ColDef } from '@siq-js/visual-lib';
import { ReportBuilderCriteriaRendererComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-criteria-renderer/report-builder-criteria-renderer.component';
import { Router } from '@angular/router';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { ActivityCriteriaGenerator } from 'app/activity/models/activity-criteria-generator';
import { ActivityService } from 'app/activity/services/activity.service';
import { ApplicationHash } from '@siq-js/core-lib';

@Component({
  selector: 'siq-report-builder-list',
  templateUrl: './report-builder-list.component.html',
  styleUrls: ['./report-builder-list.component.scss']
})
export class ReportBuilderListComponent implements OnDestroy, UsesActivityGrid {

  // ActivityGrid interface fields
  public activityStream$: BehaviorSubject<ReportActivity[]>;
  public actions = this.config.actions;
  public columns: (tabKey: ActivityTabKey) => ColDef[];

  public callbacks: ActivityCallbacks = {
    rerun: activity => this.activityService.rerun(activity),
    clone: activity => {
      this.reportBuilderService.cloneReport(activity.getId());
    }
  };

  private ngUnsubscribe = new Subject<void>();

  constructor(
    public config: ReportBuilderConfig,
    private activityService: ActivityService,
    public reportBuilderService: ReportBuilderService,
  ) {
    this.activityStream$ = ReportBuilderService.Activities$;
    this.columns = tabKey => ActivityCriteriaGenerator.generateColDefs(tabKey, [ReportBuilderCriteriaRendererComponent.ColDef]);
    this.activityService.getCurrentPageInfo().appId = ApplicationHash.REPORT_BUILDER;
    this.activityService.getCurrentPageInfo().tab = ActivityTabKey.MY_REPORTS;
    ActivityService.refresh();
  }

  ngOnDestroy() {
    this.activityService.clearCurrentPageInfo();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

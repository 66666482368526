<h2 mat-dialog-title>Select a Filter</h2>

<button type="button" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<mat-tab-group
  *ngIf="tabs"
  color="accent"
  (selectedTabChange)="showFilters($event.index)"
>

  <mat-tab
    *ngFor="let tab of tabs"
    [label]="tab.group"
  >
    <mat-dialog-content>
      <siq-data-table
        [gridOptions]="tab.gridOptions"
        class="hide-horizontal-scroll"
        tableType="scrollable"
        noCard="1"
      ></siq-data-table>
    </mat-dialog-content>
  </mat-tab>

</mat-tab-group>

<mat-dialog-actions>

  <button type="button" mat-raised-button mat-dialog-close color="accent" class="secondary-button" cdkFocusInitial>
    Cancel
  </button>

</mat-dialog-actions>

<h6 *ngIf="!hideHeader && activity.value.id !== '-1'">

  <span>{{ activity.value.metaData?.name }}</span>

  <ng-container *ngIf="!suppressOptions">

    <div *ngIf="isReady">
      <siq-js-activity-grid-options-menu
        (clickEvent)="clickEvent.emit($event)"
        [config]="menuItems"
      ></siq-js-activity-grid-options-menu>
    </div>

  </ng-container>

</h6>

import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomInjector {

  public static injector: Injector;

  constructor(injector: Injector) {
    CustomInjector.injector = injector;
  }

}

import { FilterSelectionJson, FilterSelection } from 'app/filter/models/filter-selection';
import { CmsFieldJson, CmsField } from '@siq-js/cms-lib';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { DateRangeInterface, DateRangeInterfaceJson } from 'app/siq-forms/modules/dates/models/interfaces';
import { AppFormData, AppFormJson } from 'app/siq-applications/modules/shared/models/app-form-data.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

export interface IncrementalsFormJson extends AppFormJson {
  dateRanges: DateRangeInterfaceJson;
  incrementalType: CmsFieldJson;
  isCloned: boolean;
  locationFilters: FilterSelectionJson[];
  name: string;
  productFilters: FilterSelectionJson[];
}

export class IncrementalsFormData extends AppFormData<IncrementalsFormJson> {
  public dateRanges: DateRangeInterface; // current date value
  public incrementalType: CmsField;
  public isCloned: boolean;
  public locationFilters: FilterSelection[] = [];
  public name: string;
  public productFilters: FilterSelection[] = [];
  public schema: string;

  constructor() {
    super();
    this.weekEndingday = this.datesService.getDefaultWeekEndingDay();
  }

  /**
   * Used for QueryModeModalConfig diffFn. To determine whether the modal needs to be opened
   */
  public isValidSchema(schema: string): boolean {
    if (this.incrementalType && !CmsService.isValidField(this.incrementalType, schema)) return false;

    for (let fs of this.productFilters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
    }
    for (let fs of this.locationFilters) {
      if (!CmsService.isValidField(fs.field, schema)) return false;
      // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        if (fs.values.find(val => !retailers.includes(val))) return false;
      }
    }

    return true;
  }

  public toJson(): IncrementalsFormJson {
    const json: IncrementalsFormJson = {
      dateRanges: DatesService.isDynamic(this.dateRanges)
        ?
        { // dynamic dates
          begin: DatesService.dateMStoS(this.dateRanges.begin.getTime()), // translated dates in begin & end are needed to display
          end: DatesService.dateMStoS(this.dateRanges.end.getTime()),
          dynamicBegin: this.dateRanges.dynamicBegin,
          dynamicEnd: this.dateRanges.dynamicEnd
        }
        :
        { // static dates
          begin: DatesService.dateMStoS(this.dateRanges.begin.getTime()),
          end: DatesService.dateMStoS(this.dateRanges.end.getTime()),
        },
      incrementalType: this.incrementalType?.toJson(),
      isCloned: this.isCloned,
      locationFilters: this.locationFilters.map(f => f.toJson()),
      name: this.name,
      productFilters: this.productFilters.map(f => f.toJson()),
      schema: this.schema,
      weekEndingDay: this.weekEndingday,
    };
    return json;
  }

}

import { ActivatedRoute } from '@angular/router';
import { ApplicationHash } from '@siq-js/core-lib';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SiqApplicationActivity } from 'app/siq-applications/modules/shared/models/siq-application-activity';
import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { SiqApplicationService } from 'app/siq-applications/modules/shared/services/siq-application.service';
import { takeUntil } from 'rxjs';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ContentChildren,
  } from '@angular/core';
import { ActivityService } from 'app/activity/services/activity.service';

@Component({
  selector: 'siq-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss']
})
export class ActivityFormComponent extends SiqApplicationActivity implements OnInit, OnDestroy {

  @Input() config: SiqApplicationConfig;
  @Input() disableHide = false;
  @Input() hideExpandAll: boolean;
  @Input() hideHeader: boolean;

  public isExpanded = false;
  public hideForm = false;
  public shouldBeBoxed = false;
  public isCloning = false;
  public isResults = false;

  @ContentChildren(MatExpansionPanel, { descendants: true }) public matExpansionPanelChildren;

  constructor(
    public route: ActivatedRoute,
    activityService: ActivityService
  ) {

    super(route, activityService);

    this.activity
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.redraw();
      });

  }

  ngOnDestroy() {
    this.destroy();
  }

  redraw () {

    const params = this.route.snapshot.params;

    this.isCloning = params.id && params.action === 'clone';
    this.isResults = params.id && params.action !== 'clone';

    if (this.isResults) {
      this.hideForm = !this.disableHide;
    }

    if (!this.config) {
      this.shouldBeBoxed = !!params.id && params.action !== 'clone';
    } else {
      this.shouldBeBoxed = false;
    }

    this.isExpanded = !this.shouldBeBoxed || !!(this.shouldBeBoxed && params.action);

  }

  closed(): void {
    this.isExpanded = false;
  }

  opened(): void {
    this.isExpanded = true;
  }

  clearForm(): void {
    EmitterService.get('app-form:clear').emit();
  }

  resetForm(): void {
    EmitterService.get('app-form:reset').emit();
  }
}

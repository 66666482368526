import { Component } from '@angular/core';
import { CriteriaChipFactory } from 'app/core/models/factories/criteria-chip-factory';
import * as _ from 'lodash';
import { ColDef } from '@siq-js/visual-lib';
import { AccessGroup } from 'app/access-group/models/access-group.model';
import { BaseCriteriaRendererComponent } from 'app/core/components/cell-renderers/base-criteria-renderer/base-criteria-renderer';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Model {
  retailers: CriteriaChip;
  filters?: CriteriaChip;
}

@Component({
  selector: 'siq-access-group-criteria-renderer',
  templateUrl: './access-group-criteria-renderer.component.html',
  styleUrls: ['./access-group-criteria-renderer.component.scss']
})
export class AccessGroupCriteriaRendererComponent extends BaseCriteriaRendererComponent<AccessGroup> {

  public static readonly ColDef: ColDef = {
    headerName: 'Criteria',
    sortable: false,
    cellRenderer: AccessGroupCriteriaRendererComponent,
    width: 330
  };

  public model: Model;
  public isMultiMode: boolean = false;

  constructor(
    asyncStatusService: AsyncStatusService
  ) {
    super(asyncStatusService);
  }

  protected setupModel(accessGroup: AccessGroup): void {
    this.isMultiMode = EnvConfigService.isMultiMode();
    const model: Model = {
      retailers: this.getRetailers(accessGroup)
    };

    if (!_.isEmpty(accessGroup.getFilters())) {
      model.filters = CriteriaChipFactory.getFilters(accessGroup.getFilters());
    }
      this.model = model;
    }

  private getRetailers(accessGroup: AccessGroup): CriteriaChip {
    let retailerLabel = !accessGroup.getRetailers().length ? 'All' : accessGroup.getRetailers().length;
    retailerLabel += ' Retailers';

    return {
      toolTip: UtilsService.getRetailerDisplays(accessGroup.getRetailers()).join(', '),
      label: retailerLabel.toString()
    };
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { daypartsRoutes } from 'app/siq-applications/modules/dayparts/routes/dayparts-routes';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(daypartsRoutes)
  ]
})
export class DaypartsRoutingModule { }

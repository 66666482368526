<div class="dayparts-form floating-box">

  <div class="stepper-header-outside">
    <mat-slide-toggle [(ngModel)]="expanded" [color]="'accent'">Expand</mat-slide-toggle>
  </div>

  <mat-vertical-stepper [ngClass]="{'expand-all': expanded}" color="accent">
    <mat-step [hasError]="!isDateRangeValid">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 1: Date Range</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[0] && isDateRangeValid ? stepValidationMessages[0] : {status: validationMessageStatus.UNSET, message: 'Select Date Range'}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container">
        <siq-js-date-selection
          *ngIf="formData && dateSelectionConfig"
          [config]="dateSelectionConfig"
          [initModel]="formData.dateRanges"
          (onDateChanged)="dateChanged($event)"
          (onWeekEndingDayChanged)="weekEndingDayChanged($event)"
        ></siq-js-date-selection>
      </div>
      <div *ngIf="!expanded" class="step-button-bar shift-end">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 2: Filter (optional)</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[1] ? stepValidationMessages[1] : {status: validationMessageStatus.UNSET, message: 'Select Filter(s)'}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container filters-container">
        <siq-filter-group
          #filters
          [schemaController]="queryMode.schema$"
          [initModel]="formData.filters"
          (emitter)="filterGroupChanged($event, '')"
        ></siq-filter-group>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class="step-button-bar" [ngClass]="expanded ? 'shift-end' : 'spread'">
        <button mat-button matStepperPrevious class="secondary" *ngIf="!expanded">Back</button>
        <div>
          <mat-form-field class="user-activity-name">
            <mat-label>Name Activity (Optional)</mat-label>
            <input matInput type="text" [(ngModel)]="formData.name">
            <button mat-button *ngIf="formData.name" matSuffix mat-icon-button aria-label="Clear" (click)="formData.name=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button
            mat-raised-button
            type="submit"
            [disabled]="disableSubmitBtn"
            (click)="onSubmit()"
            class="mat-accent"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>

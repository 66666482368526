import { TitleCasePipe } from '@angular/common';
import { CmsConfig, CmsField } from '@siq-js/cms-lib';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { GridApi, GridOptions, GridReadyEvent } from '@siq-js/visual-lib';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'app/core/services/utils/utils.service';
import {
  EditFilterModalComponent,
  FilterModalData
} from 'app/filter/components/edit-filter-modal/edit-filter-modal.component';
import { ValueCountRendererComponent } from 'app/filter/components/add-filter-modal/cell-renderers/value-count-renderer/value-count-renderer.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { AddFilterData } from 'app/filter/components/base-filter-group/base-filter-group.component';
import { AppComponent } from 'app/app.component';

interface Tab {
  group: string;
  gridOptions: GridOptions;
}

@Component({
  selector: 'siq-add-filter-modal',
  templateUrl: './add-filter-modal.component.html',
  styleUrls: ['./add-filter-modal.component.scss']
})
export class AddFilterModalComponent implements OnInit {
  public tabs: Tab[];
  private gridApi: GridApi;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AddFilterData,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<AddFilterModalComponent>
  ) { }

  ngOnInit(): void {
    this.tabs = this.setupFilterGroups();
  }

  addFilter(field: CmsField) {
    this.utils.openModal(EditFilterModalComponent, <FilterModalData>{
      field: field,
      config: {
        include: true,
        canToggle: true,
        nullsAllowed: true,
        isColumnFilter: !!this.data?.isColumnFilter
      },
      schemaController: this.data.schemaController
    }, UtilsService.MODAL_CONFIG_MEDIUM)
      .afterClosed()
      .subscribe((filterSelection: FilterSelection) => {
        if (filterSelection && !filterSelection.isEmpty()) {
          this.dialogRef.close(filterSelection);
        }
      });
  }

  private createGridOptions(filters: CmsField[]): GridOptions {

    // sort filters alphabetically by display name
    filters.sort((a, b) => UtilsService.isLessThan(a.display, b.display));

    const grid: GridOptions = {
      rowHeight: 48,
      columnDefs: [
        {
          headerName: 'Name',
          field: 'display'
        },
        ValueCountRendererComponent.ColDef
      ],
      defaultColDef: {
        resizable: false,
        sortable: true
      },
      animateRows: true,
      sortingOrder: ['desc', 'asc', null],
      suppressContextMenu: true,
      rowData: filters,
      onRowClicked: r => this.addFilter(r.data),
      onGridReady: (_grid: GridReadyEvent) => {
        Object.assign(grid, _grid);
        this.gridApi = _grid.api;
        try {
          _grid.api.sizeColumnsToFit();
        } catch { }
      }
    };

    return grid;
  }

  showFilters(i: number): void {
    AppComponent.resize$.next();
  }

  /**
   * Groups all filters available as specified in AG-274
   */
  private setupFilterGroups(): Tab[] {
    // use nested map to group filters
    const _map: {
      [r in string]: { // outer-level: retailer key
        [ta in string]: CmsField[] // inner-level: table key, points to list of filters
      }
    } = {};

    const tabs: Tab[] = [];

    // helper function for processing a single retailer's filters
    const processRetailerGroup = (r: string) => {
      if (!_map[r]) return;

      if (r === CmsConfig.CORE_SCHEMA_ID) {
        // special handling for core, concat all filters together, and return one group
        const items: CmsField[] = [];

        for (const ta in _map[r]) {
          items.push(..._map[r][ta]);
        }

        tabs.push({
          group: 'Aggregated',
          gridOptions: this.createGridOptions(items),
        });
      } else {
        // all other retailers/schemas are handled the same way
        // add product & locations filters first, then any additional

        // use set to manage table keys, maintains insert order!
        const tableKeys = new Set<string>();
        tableKeys.add('locations'); // locations always first
        tableKeys.add('product'); // product always second

        const allKeys = Object.keys(_map[r]).sort();
        allKeys.forEach(k => tableKeys.add(k)); // add all table keys - product & locations will automatically be ignored

        for (const ta of tableKeys.keys()) {
          if (!_map[r][ta]) continue;

          const tableName = (new TitleCasePipe()).transform(ta);
          const groupName = r === EnvConfigService.getConfig().primaryEntity ? `${UtilsService.getBrandDisplay(r)} (${tableName})` : `${UtilsService.getRetailerDisplay(r)} (${tableName})`;

          tabs.push({
            group: groupName,
            gridOptions: this.createGridOptions(_map[r][ta]),
          });
        }
      }

      delete _map[r];
    };

    this.data.availableFilters.forEach((f) => {
      const r = f.retailer;
      const ta = f.table;
      _map[r] = _map[r] || {};
      _map[r][ta] = _map[r][ta] || [];
      _map[r][ta].push(f);
    });

    processRetailerGroup(EnvConfigService.getConfig().primaryEntity);
    processRetailerGroup(CmsConfig.CORE_SCHEMA_ID);

    for (const r in _map) {
      processRetailerGroup(r);
    }

    return tabs;
  }
}

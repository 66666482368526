import {
  CellMouseOutEvent,
  CellMouseOverEvent,
  ColDef,
  ColGroupDef,
  ExcelService,
  GridComponent,
  GridOptions,
  GridService,
  GridSettings,
  IStatResponse,
  VisualOptions,
  VisualService
} from '@siq-js/visual-lib';
import { IncrementalsResultComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-result/incrementals-result.component';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { IncrementalsConfig } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { debounceTime, takeUntil } from 'rxjs';
import { AppComponent } from 'app/app.component';
import { stringToNum } from '@siq-js/core-lib';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';

export interface IncrementalsGridParams {
  data?: any[];
  gridVisualOptions?: VisualOptions;
  parent: IncrementalsResultComponent;
  readyForExport$: BehaviorSubject<boolean>;
  statResponse: IStatResponse;
}

export class IncrementalsGridProcessor {
  public static generateGridVisualOptions(gridParams: IncrementalsGridParams): VisualOptions {
    let vo: VisualOptions = GridService.generateDefaultGridVisualOptions();

    return _.merge(vo, {
      data: gridParams.data,
      hidePanel: true,
      hideTitle: true,
      metrics: gridParams.parent.displayFacts,
      dimensions: gridParams.statResponse.getDimensions(),
      globalDateRange: <DateRangeInterface>{
        begin: gridParams.parent.formData.dateRanges.begin,
        end: gridParams.parent.formData.dateRanges.end
      },
      gridConfig: {
        persistState: true,
        onGridReady: (grid) => {
          const gridComponent = <GridComponent>VisualService.findVisualization(gridParams.gridVisualOptions.vizId);
          gridParams.parent.grid = gridComponent;

          gridParams.parent.highlightDatum$.pipe(
            takeUntil(gridParams.parent.unsub$)
          )
          .subscribe(datum => {
            if (_.isNil(datum)) {
              grid.api.getSelectedNodes().forEach(selectedNode => {
                selectedNode.setSelected(false);
              });
            } else {
              grid.api.forEachNode(node => {
                const dimId = gridParams.statResponse.dimensions[0].id;
                if (_.isEqual(node.data[dimId], datum[dimId])) {
                  node.setSelected(true);
                }
              });
            }
            if (IncrementalsResultComponent.hideMetricsTemp) {
              grid.api.setColumnVisible('INC_PERCENT_DISTRIBUTION', false);
            }
          });

          AppComponent.resize$
          .pipe(debounceTime(100))
          .subscribe(() => gridComponent.autoSizeColumns());
        },
        afterRender: (grid) => {
          // TODO: migrate from V1
          // this.attachCloudData();
          // this.hideTotalsRow();
        },
        gridReadyForExport$: gridParams.readyForExport$,
        defaultSortOptions: gridParams.parent.config.defaultSort,
        pinLeft: [0],
        tooltips: gridParams.parent.config.tooltips,
        customGridOptions: {
          sideBar: false,
          excelStyles: [
            ...ExcelService.generateExcelStyles()
          ],
          onCellMouseOver(event: CellMouseOverEvent): void {
            gridParams.parent.highlightDatum$.next(event.data);
          },
          onCellMouseOut(event: CellMouseOutEvent): void {
            gridParams.parent.highlightDatum$.next(null);
          },
          onFilterChanged(event) {
            gridParams.parent.onFilterChanged();
          }
        },
        configureColumns: cols => {
          const cellClassRules = {
            ...ExcelService.getCellClassRules([
              ...gridParams.statResponse.getDimensions(),
              ...gridParams.statResponse.getFacts()
            ]),
            ...GridService.getDefaultFactCellClassRules()
          };

          _.each(gridParams.parent.displayFacts, f => {
            cellClassRules[GridService.ENUM_ID_PREPEND + f.id] = (context) => {
              const fact = GridService.enumFromCellClass(context.colDef.cellClass, gridParams.statResponse.getFacts());
              return fact && fact.id === f.id;
            };
          });

          // Generate col group for each col and push it to colDefs
          const colDefs: ColDef[] = [];
          const dim = gridParams.statResponse.getDimensions()[0];

          // Add INCREMENTALS specific metrics (like INC_xyz)
          gridParams.gridVisualOptions.apiRef().grid.gridOptions.excelStyles.push(
            ...ExcelService.generateExcelStylesForList(gridParams.statResponse.getFacts())
          );

          cols.forEach(col => {
            // Add filterValueGetter
            const metric = gridParams.statResponse.getFacts().find(f => f.id === col.colId);
            if (metric) {
              const formatterFn = GridService.generateFormatterFn(metric);
              col.filterValueGetter = params => {
                const displayVal = formatterFn({value: _.get(params.data, [col.colId])});
                return stringToNum(displayVal);
              };
            }

            col.cellClassRules = cellClassRules;
            // set header name for dimension col
            if (col.colId === dim.id) {
              col.headerName = IncrementalsConfig.INCREMENTAL_GROUP;
              col.cellClass = [ExcelService.EXCEL_STYLE_GENERIC_STRING]; // This prevents an error when opening Excel but causes all values to be left-aligned (as strings) for this column (but only in Excel, not in browser)
            }
            const cg: ColGroupDef = {
              marryChildren: true,
              groupId: col.colId,
              openByDefault: true,
              children: [col]
            };
            // set headerName for per store week metrics, default is "Absolute"
            cg.headerName = IncrementalsConfig.PER_STORE_WEEK_METRICS.indexOf(col.headerName) > -1 ? IncrementalsConfig.ABSOLUTE : '';
            colDefs.push(cg);
          });
          return colDefs;
        }

      }
    });
  }

  public static processor(gridParams: IncrementalsGridParams): GridSettings {
    // Generate the GridOptions (overrides) which will get merged to the default GridOptions created by/in GridComponent
    let gridOptions: GridOptions = gridParams.gridVisualOptions.gridConfig.customGridOptions || {};

    const gridSettings: GridSettings = <GridSettings>{
      data: gridParams.gridVisualOptions.data, // set pointer for ease of use/readability later
      gridOptions: gridOptions,
      gridVisualOptions: gridParams.gridVisualOptions,
      parentActivity: gridParams.gridVisualOptions.parentActivity || gridParams.parent.parentActivity // set pointer for ease of use/readability later
    };

    // To enable AsyncTotals for Incrementals, we just need to uncomment the following line. Everything else is already setup.
    // GridTotalsService.provideAsyncTotalsFunctionality(gridSettings);
    return gridSettings;
  }
}

import { BaseModel } from 'app/core/models/base-model';

export class SqlEditorParameters extends BaseModel {
  private allowLargeResults: boolean;
  private dryRun: boolean;
  private maxResults?: number;
  private query: string;

  constructor(query: string, dryRun?: boolean) {
    super();

    this.query = query;
    this.dryRun = dryRun;

    if (!dryRun) {
      this.allowLargeResults = true;
    }
  }

  getQuery(): string {
    return this.query;
  }
}

<div class="panel-subheader">
  <button mat-stroked-button (click)="addFilter()" type="button" class="secondary-button thin-button">{{addFilterBtnDisplay || 'Add a Filter'}}</button>
  <span class="spacer"></span>
  <button *ngIf="!isUserGroup" mat-stroked-button (click)="loadFilterset()" type="button" class="secondary-button thin-button" >Load Filterset</button>
  <div class="button-space"></div>
  <button *ngIf="!isUserGroup" mat-stroked-button (click)="saveFilterset()" type="button" class="secondary-button thin-button" [disabled]="!(model$ | async)?.length">Save Filterset</button>
</div>

<ng-container *ngIf="model$ | async as model">
  <mat-chip-list>
    <siq-filter-chip *ngFor="let filter of model"
                     [model]="filter"
                     [parent]="this"
    ></siq-filter-chip>
  </mat-chip-list>

  <p *ngIf="!model.length">No Filters</p>
</ng-container>

import { ApplicationHash } from '@siq-js/core-lib';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { PromoFormJson } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { KpiModel } from 'app/siq-applications/modules/promo/models/interfaces';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { KPI, PromoActivityName, PromoPeriods } from 'app/siq-applications/modules/promo/models/promo.enums';
import { DateRangeParameter } from '@siq-js/core-lib';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import * as _ from 'lodash';

export class PromoActivity extends Activity {

  public takeRateActivity: Activity;
  public takeRateJobs: Set<string>;
  public takeRatePSPWActivity: Activity;
  public takeRatePSPWJobs: Set<string>;
  public sovActivity: Activity;
  public sovJobs: Set<string>;
  public sovPSPWActivity: Activity;
  public sovPSPWJobs: Set<string>;
  public kpiModel: any = null;
  public promoType: string;

  private static parseKPI(json: any): KpiModel {
    const model: any = {};

    for (let k in json) {
      const keys = k.split('-');

      const period = keys[0] as PromoPeriods;
      let group = keys[1];
      if (group === 'CG') {
        const cgArr = keys.slice(1, keys.length - 1);
        group = cgArr.join('-');
      }

      const kpi = keys[keys.length - 1] as KPI;

      model[group] = model[group] || {};
      model[group][kpi] = model[group][kpi] || {};
      model[group][kpi][period] = Number(json[k]);
    }

    return model;
  }

  constructor(activity?: Activity) {
    super(activity);
    this.takeRateActivity = _.find(this.sheets, a => a.getAppId() === ApplicationHash.PROMO_TAKERATE_SHEET && a.getMetaDataByKey('name') === PromoActivityName.TAKE_RATE_ABS);
    this.sovActivity = _.find(this.sheets, a => a.getAppId() === ApplicationHash.PROMO_SOV_SHEET && a.getMetaDataByKey('name') === PromoActivityName.SOV_ABS);
    this.takeRatePSPWActivity = _.find(this.sheets, a => a.getAppId() === ApplicationHash.PROMO_TAKERATE_SHEET && a.getMetaDataByKey('name') === PromoActivityName.TAKE_RATE_PSPW);
    this.sovPSPWActivity = _.find(this.sheets, a => a.getAppId() === ApplicationHash.PROMO_SOV_SHEET && a.getMetaDataByKey('name') === PromoActivityName.SOV_PSPW);

    const jobsTR = this.takeRateActivity?.getMetaDataByKey('columns');
    const jobsTRPSPW = this.takeRatePSPWActivity?.getMetaDataByKey('columns');
    const jobsSOV = this.sovActivity?.getMetaDataByKey('columns');
    const jobsSOVPSPW = this.sovPSPWActivity?.getMetaDataByKey('columns');

    this.takeRateJobs = new Set(jobsTR?.length ? jobsTR.split(',') : null);
    this.takeRatePSPWJobs = new Set(jobsTRPSPW?.length ? jobsTRPSPW.split(',') : null);
    this.sovJobs = new Set(jobsSOV?.length ? jobsSOV.split(',') : null);
    this.sovPSPWJobs = new Set(jobsSOVPSPW?.length ? jobsSOVPSPW.split(',') : null);
    this.promoType = this.getMetaDataByKey('promoType') || PromoConfig.NULL_PROMO_TYPE;

    if (this.getMetaDataByKey('kpiValues')) {
      const kpiModel = PromoActivity.parseKPI(JSON.parse(this.getMetaDataByKey('kpiValues')));
      if (!_.isEmpty(kpiModel)) {
        this.kpiModel = kpiModel;
      }
    }
  }

  getStatus(): ActivityStatus {
    // Added try/catch to handle scenario where err was causing activity list to fail
    // which then broke delete/recover functionality and the rendering of the grid itself
    try {
      if (this.accessGroupChanged) {
        return ActivityStatus.ALERT;
      }

      if (this.takeRateActivity.getStatus() === ActivityStatus.ERROR
        || this.takeRateActivity.getStatus() === ActivityStatus.ERROR) {
        return ActivityStatus.ERROR;
      }

      if (this.takeRateActivity.getStatus() === ActivityStatus.READY
        && this.sovActivity.getStatus() === ActivityStatus.READY && this.kpiModel) {
          if (!this.takeRateJobs.size && !this.sovJobs.size) {
            return ActivityStatus.ERROR;
          }
        return ActivityStatus.READY;
      }

      if (this.takeRateActivity.getStatus() === ActivityStatus.RUNNING
        || this.sovActivity.getStatus() === ActivityStatus.RUNNING || !this.kpiModel) {
          if (this.takeRateJobs.size && !this.sovJobs.size) { // Added in ICE-2831. Possible improvement documented in ICE-2809
            return ActivityStatus.READY;
          }
        return ActivityStatus.RUNNING;
      }

      if (this.takeRateActivity.getStatus() === ActivityStatus.EXPIRED
        || this.sovActivity.getStatus() === ActivityStatus.EXPIRED) {
        return ActivityStatus.EXPIRED;
      }
    } catch (err) {
      return ActivityStatus.ERROR;
    }
  }

  isComplete(): boolean {
    try {
      if (this.takeRateActivity.getStatus() === ActivityStatus.RUNNING) return false;
      if (!this.kpiModel) return false;
      if (this.sovActivity.getStatus() === ActivityStatus.RUNNING) return false;
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  getName(): string {
    return this.getMetaDataByKey('name') || Activity.ActivityPlaceholder;
  }

  getFormValues(): any {
    return JSON.parse(this.formValues);
  }

  public getKPIGrowth(kpi: KPI): number {
    if (this.kpiModel) {
      const pre = _.get(this.kpiModel, ['PROMO', kpi, PromoPeriods.PRE]);
      const promo = _.get(this.kpiModel, ['PROMO', kpi, PromoPeriods.PROMO]);

      let growth = (promo - pre) / pre;
      if (_.isFinite(growth)) return growth;
    }
    return null;
  }

  public getDateRange(period: PromoPeriods): DateRangeInterface {
    let dates: DateRangeParameter;
    const fv: PromoFormJson = this.getFormValues();
    switch (period) {
      case PromoPeriods.PRE:
        dates = fv.prePromoDateRange;
        break;
      case PromoPeriods.PROMO:
        dates = fv.promoDateRange;
        break;
      case PromoPeriods.YOY:
        dates = fv.yoyDateRange;
        break;
      case PromoPeriods.POST:
        dates = fv.customPromoDateRange;
        break;
    }

    if (!dates) return null;

    return DatesService.toDateRangeInterface(dates);
  }

}

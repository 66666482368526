import { Component } from '@angular/core';
import { User } from 'app/user/models/user.model';
import { AppComponent } from 'app/app.component';
import { NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { AuthService } from 'app/auth/services/auth.service';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'siq-nav-primary',
  templateUrl: './nav-primary.component.html',
  styleUrls: ['./nav-primary.component.scss']
})

export class NavPrimaryComponent extends BaseSiqComponent {
  public currentUser: User;
  public headerImageUrl: string;
  public readonly headerImageUrlLight = 'assets/img/PDI Logo-RGB-Two Color-Digital.png';
  public readonly headerImageUrlDark = 'assets/img/PDI Logo-RGB-White-Digital.png';

  public visible: {
    primary: boolean,
    // secondary: boolean
  };

  constructor() {
    super();

    this.visible = AppComponent.visible;

    AuthService.CurrentUser$.subscribe((user: User) => this.currentUser = user);
    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe(theme => {
      if (theme === ThemesService.THEMES.DARK) {
        this.headerImageUrl = this.headerImageUrlDark;
      } else {
        this.headerImageUrl = this.headerImageUrlLight;
      }
    });

  }

  toggleSidenav() {
    NavSecondaryService.toggle();
  }

}

import {
  GridOptions,
  ColDef,
  ColumnState,
  ExcelService,
  GridComponent,
  GridSettings,
  VisualizationState,
  VisualOptions,
  AgGridAngular,
  ColumnHeaderComponent
} from '@siq-js/visual-lib';
import { StatResponse } from 'app/core/models/stat-response.model';
import { BehaviorSubject } from 'rxjs';
import { DaypartsResultComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-result/dayparts-result.component';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { VisualService } from 'libs/visual-lib/src/lib/services/visual.service';
import { debounceTime, filter, takeUntil } from 'rxjs';
import { CmsMetric } from '@siq-js/cms-lib';
import * as _ from 'lodash';
import { AppComponent } from 'app/app.component';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { CmsService } from 'app/core/services/cms/cms.service';
import { ApplyColumnStateParams } from 'ag-grid-community/dist/lib/columns/columnModel';

export interface DaypartsGridParams {
  gridVisualOptions?: VisualOptions;
  parent: DaypartsResultComponent;
  statResponse: StatResponse;
  readyForExport$: BehaviorSubject<boolean>;
}

export class DaypartsGridProcessor {

  public static generateGridVisualOptions(gridParams: DaypartsGridParams): VisualOptions {
    const data = GridService.jobToArray(gridParams.statResponse);
    let vo: VisualOptions = GridService.generateDefaultGridVisualOptions();

    return _.merge(vo, {
      data: data,
      dimensions: gridParams.statResponse.getDimensions().filter(d => !d.isNull()),
      globalDateRange: <DateRangeInterface>{
        begin: gridParams.parent.formData.dateRanges.begin,
        end: gridParams.parent.formData.dateRanges.end
      },
      gridConfig: {
        persistState: true,
        onGridReady: (grid) => {
          // Hook into the parent (GridComponent) state$
          const gridComponent = <GridComponent>VisualService.findVisualization(gridParams.gridVisualOptions.vizId);
          gridParams.parent.grid = gridComponent;
          gridComponent.grid.api.updateGridOptions({ pivotMode: true });

          // Make sure this grid.autoGroupColumDef property is set
          gridComponent.grid.autoGroupColumnDef = gridParams.gridVisualOptions.gridConfig.customGridOptions.autoGroupColumnDef;

          // default to the first ColDef (matches the metric set in initState below)
          const cd = <ColDef>_.find(gridComponent.grid.gridOptions.columnDefs, {colId: gridParams.gridVisualOptions.metrics[0].id});
          gridComponent.heatmapColDef$.next(cd);

          gridComponent.visualizationState$.pipe(
            debounceTime(250),
            filter(visualizationState => !_.isNil(visualizationState)),
            takeUntil(gridComponent.unsub$)
          )
          .subscribe(state => {
            if (state.heatmapColDefs && (state.metrics[0].id !== state.heatmapColDefs[0].colId)) {
              const cmsMetric = CmsService.get().findEntity<CmsMetric>(state.heatmapColDefs[0].colId);
              state.metrics = [cmsMetric];
              gridComponent.visualizationState$.next(state);
            } else {
              if (state.heatmapColDefs && (state.heatmapColDefs[0].colId !== gridComponent.heatmapColDef$.value?.colId)) {
                // The HeatmapSelectorComponent uses 'this.gridComponet.heatmapColDef' as the value of the dropdown.
                // As such in order to work correctly, it needs a reference to the exact ColDef (not a copy of it).
                const byRefColDef = <ColDef>_.find(gridComponent.getApi().grid.gridOptions.columnDefs, {colId: state.heatmapColDefs[0].colId});
                gridComponent.heatmapColDef$.next(byRefColDef);
              } else {
                DaypartsGridProcessor.setupPivotTable(gridComponent.grid, state);
              }
            }
          });

          AppComponent.resize$
          .pipe(debounceTime(100))
          .subscribe(() => gridComponent.autoSizeColumns());
        },
        allowHeatmap: true,
        customGridOptions: {
          sideBar: false,
          autoGroupColumnDef: {
            cellRendererParams: {
              suppressCount: true // This prevents the count in parenthesis in the group coldef (like "12 AM (7)")
            },
            sort: 'asc',
            headerName: gridParams.statResponse.getDimensions().filter(d => !d.isNull())[0].display,
            headerComponent: <any>ColumnHeaderComponent,
          },
          excelStyles: [
            ...ExcelService.generateExcelStyles()
          ],
          // TODO: If desired, we can place the gridHeatmap ToolPanel in Dayparts and remove the floating HeatmapSelector (above the grid)
          // sideBar: {
          //   defaultToolPanel: 'gridHeatmap',
          //   toolPanels: ['gridHeatmap']
          // }
        },
        maxColumnsToFit: 8,
        gridReadyForExport$: gridParams.readyForExport$,
        updateHeatmap(state: VisualizationState, colDef: ColDef, selectedMetric: CmsMetric): VisualizationState {
          // Dayparts only shows one single metric at a time
          state.metrics = [selectedMetric];
          return state;
        }
      },
      initState: {
        dimensions: gridParams.statResponse.getDimensions().filter(d => !d.isNull()),
        metrics: [gridParams.statResponse.getFacts()[0]],
        allowHeatmap: true
      },
      metrics: gridParams.statResponse.getFacts(),
      parentActivity: gridParams.parent.reportActivity
    } as VisualOptions);
  }

  public static processor(gridParams: DaypartsGridParams): GridSettings {
    // Generate the GridOptions (overrides) which will get merged to the default GridOptions created by/in GridComponent
    let gridOptions: GridOptions = gridParams.gridVisualOptions.gridConfig.customGridOptions || {};

    // ColDefs generated automatically by GridComponent.generateDefaultColDefs

    return <GridSettings>{
      data: gridParams.gridVisualOptions.data, // set pointer for ease of use/readability later
      gridOptions: gridOptions,
      gridVisualOptions: gridParams.gridVisualOptions,
      parentActivity: gridParams.gridVisualOptions.parentActivity // set pointer for ease of use/readability later
    };
  }

  public static setupPivotTable(grid: AgGridAngular, state: VisualizationState) {
    grid.api.updateGridOptions({pinnedTopRowData: []});
    if (!grid.api.getRowGroupColumns().length
      || grid.api.getRowGroupColumns()[0].getColDef().colId !== state.dimensions[0].id
      || grid.autoGroupColumnDef?.headerName !== state.dimensions[0].display
    ) {

      /**
       * Clear out the headerName and then set the RowGroupColumns to empty array. This will
       * case ag-grid change detection to see that the RowGroupColumns has changed and will
       * then update the actual autoGroupColumnDef. Previously we had just been overwriting
       * the .headerName and setting the new RowGroupColumn without first clearing ([]) and
       * it seems that ag-grid did not recognize these changes because there had been one RowGroupColumn
       * and after the updates there still was one but the grid was unaware the contents had changed.
       * First clearing this out ([]) then setting a new RowGroupColumn has fixed this and the grid
       * is now behaving as expected.
       */
      if (grid.autoGroupColumnDef) {
        grid.autoGroupColumnDef.headerName = state.dimensions[0].display;
        grid.autoGroupColumnDef.sort = 'asc';

        // This works, but it is DEPRECATED
        grid.api.setAutoGroupColumnDef(grid.autoGroupColumnDef);

        /*
        // However, these suggested replacements for "setAutoGroupColumnDef" do NOT work (do not support this property)
        grid.api.setGridOption(autoGroupColumnDef, grid.autoGroupColumnDef);
        grid.api.updateGridOptions({ autoGroupColumnDef: grid.autoGroupColumnDef });
        */
      }

      grid.api.setRowGroupColumns([]);

      grid.api.setRowGroupColumns([
        grid.api.getColumn(state.dimensions[0].id)
      ]);

      // Force ascending sort as default
      const columnState: ColumnState = {
        colId: state.dimensions[0].id,
        sort: 'asc'
      }
      const columnStateParams: ApplyColumnStateParams = {
        state: [columnState]
      };
      grid.api.applyColumnState(columnStateParams);
    }

    if (state.dimensions[1]) {
      if (!grid.api.getPivotColumns().length
        || grid.api.getPivotColumns()[0].getColDef().colId !== state.dimensions[1].id) {
        grid.api.setPivotColumns([state.dimensions[1].id]);
      }
    }

    if (grid.api.getValueColumns().length > 1
      || grid.api.getValueColumns()[0].getColDef().colId !== state.metrics[0].id) {
      grid.api.setValueColumns([state.metrics[0].id]);
    }
    
    if (grid.api.getPivotColumns()[0].getColDef().colId === 'hour') {
      grid.api.sizeColumnsToFit();
    }
    AppComponent.resize$.next();

  }

}

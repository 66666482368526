import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentageFilterComponent } from './components/percentage-filter/percentage-filter.component';
import { FormsModule } from '@angular/forms';
import { CustomFilterService } from 'libs/visual-lib/src/lib/modules/custom-filters/services/custom-filter.service';
import { DayFilterComponent } from './components/day-filter/day-filter.component';
import { DayOfWeekFilterComponent } from './components/day-of-week-filter/day-of-week-filter.component';
import { DaypartFilterComponent } from './components/daypart-filter/daypart-filter.component';
import { FormattedNumberFilterComponent } from './components/formatted-number-filter/formatted-number-filter.component';
import { HourFilterComponent } from './components/hour-filter/hour-filter.component';
import { MonthFilterComponent } from './components/month-filter/month-filter.component';
import { YearFilterComponent } from './components/year-filter/year-filter.component';
import { YearMonthDayFilterComponent } from './components/year-month-day-filter/year-month-day-filter.component';
import { YearMonthFilterComponent } from './components/year-month-filter/year-month-filter.component';

const customFiltersComponents: any[] = [
  PercentageFilterComponent,
  DayFilterComponent,
  DayOfWeekFilterComponent,
  DaypartFilterComponent,
  FormattedNumberFilterComponent,
  HourFilterComponent,
  MonthFilterComponent,
  YearFilterComponent,
  YearMonthDayFilterComponent,
  YearMonthFilterComponent
];

@NgModule({
  declarations: [
    ...customFiltersComponents
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    ...customFiltersComponents
  ],
  providers: [
    CustomFilterService,
  ]
})
export class CustomFiltersModule { }

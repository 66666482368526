import {
  AgCartesianChartOptions,
  ChartSettings,
  ChartsService,
  GridService,
  SimpleLineConfig,
  SimpleLineParams,
  SimpleLineProcessor,
  time
} from '@siq-js/visual-lib';
import { filter, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { SalesByDayTSDataSet } from 'app/siq-applications/modules/promo/models/interfaces';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoDimensionKeys, PromoPeriods } from 'app/siq-applications/modules/promo/models/promo.enums';
import { isLessThan } from '@siq-js/core-lib';
import { SovHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-helper/sov-helper.component';
import { PromoFormData } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export class SalesByDaySimpleLineProcessor extends SimpleLineProcessor {
  private static LIGHT_BLUE = THEME_COLORS.SKY;
  private static ORANGE = THEME_COLORS.SIENNA;
  private static GREEN = THEME_COLORS.GRASS;
  private static YELLOW = THEME_COLORS.SUNSHINE;
  private static PROMO_VAL_KEY = PromoPeriods.PROMO + '|' + 'value';
  private static YOY_VAL_KEY = PromoPeriods.YOY + '|' + 'value';

  public static generateSimpleLineConfig(params: SimpleLineParams, form: PromoFormData): SimpleLineConfig {
    const dataSet: SalesByDayTSDataSet = params.rawDataSimpleLine$.value;
    const xFormatter = GridService.getVisualType('DATE').formatter;
    const component = (params.component instanceof SovHelperComponent) ? params.component : params.parent;
    const yFormatter = GridService.getVisualType(component.factKey).formatter;

    const config: SimpleLineConfig = {
      agChartOptions: <AgCartesianChartOptions> {
        theme: ChartsService.getThemePdi()
      }
    };

    let typeSafe: AgCartesianChartOptions = {
      height: 400,
      title: {
        enabled: true,
        text: 'Sales By Day',
        fontSize: 25,
        fontWeight: 'bold'
      },
      legend: { position: 'top' },
      series: [],
      axes: [
        {
          position: 'bottom',
          type: 'time',
          tick : {
            maxSpacing: 100,
            minSpacing: 25,
          },
          label: {
            rotation: 45,
            formatter: (params) => { // This formats the x-axis label
              // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
              return xFormatter({value: params.value}, true);
            },
          },
          title: { enabled: true, text: 'Year-Month-Day', fontWeight: 'bold' },
          gridLine: {
            style: [
              {
                  stroke: 'lightgray',
                  lineDash: [5, 5],
              },
          ],
          }
        },
        {
          position: 'left',
          type: 'number',
          tick : {
            maxSpacing: 30,
            minSpacing: 15,
          },
          label: {
            formatter: (params) => { // This formats the x-axis label
              // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
              return yFormatter({value: params.value}, true);
            },
          },
          title: { enabled: true, text: component.factKey.display, fontWeight: 'bold' },
        },
      ]
    };

    config.agChartOptions = typeSafe;
    return config;
  }

  public static processor = (params: SimpleLineParams): ChartSettings => {

      params.rawDataSimpleLine$.pipe(
        filter((data: SalesByDayTSDataSet) => !!data && data.promoYOYData.length > 0),
        takeUntil(params.parent.unsub$)
      )
      .subscribe((data: SalesByDayTSDataSet) => {
        const form = params.parent.parent.formData;
        const component = (params.component instanceof SovHelperComponent) ? params.component : params.parent;
        const xFormatter = GridService.getVisualType('DATE').formatter;
        let newChartOptions = _.cloneDeep(params.chart.getApi().chartOptions) as AgCartesianChartOptions;
        newChartOptions.series = [];
        if (data.preDatesData.length) {
          (<AgCartesianChartOptions>newChartOptions).series.push({
            type: 'line',
            data: data.preDatesData,
            xKey: 'day',
            yKey: 'value',
            yName: form[PromoConfig.periodNames[PromoPeriods.PRE]].customPeriodName,
            stroke: SalesByDaySimpleLineProcessor.GREEN,
            marker: {
              stroke: SalesByDaySimpleLineProcessor.GREEN,
              fill: SalesByDaySimpleLineProcessor.GREEN,
            },
            tooltip: {
              renderer: (p) => {
                const formatter = GridService.getVisualType(component.factKey).formatter;
                return (
                  '<div class="ag-chart-tooltip-title" style="background-color:' + p.color + '">' +
                    xFormatter({value: p.datum['day']}) +
                  '</div>' +
                  '<div class="ag-chart-tooltip-content">' +
                    p.yName + ': ' + formatter({value: p.datum['value']}) +
                  '</div>'
                );
              }
            }
          });
        }

        if (data.customDatesData.length && form.customPromoDateRange.periodStart) {
          (<AgCartesianChartOptions>newChartOptions).series.push(
            { // custom period
              type: 'line',
              data: data.customDatesData,
              xKey: 'day',
              yKey: 'value',
              yName: form[PromoConfig.periodNames[PromoPeriods.POST]].customPeriodName,
              stroke: SalesByDaySimpleLineProcessor.LIGHT_BLUE,
              marker: {
                stroke: SalesByDaySimpleLineProcessor.LIGHT_BLUE,
                fill: SalesByDaySimpleLineProcessor.LIGHT_BLUE,
              },
              tooltip: {
                renderer: (p) => {
                  const formatter = GridService.getVisualType(component.factKey).formatter;
                  return (
                    '<div class="ag-chart-tooltip-title" style="background-color:' + p.color + '">' +
                      xFormatter({value: p.datum['day']}) +
                    '</div>' +
                    '<div class="ag-chart-tooltip-content">' +
                      p.yName + ': ' + formatter({value: p.datum['value']}) +
                    '</div>'
                  );
                }
              }
            }
          );
        }

        if (data.promoYOYData.length) {
          (<AgCartesianChartOptions>newChartOptions).series.push(
            { // promo period
              type: 'line',
              data: data.promoYOYData,
              xKey: 'day',
              yKey: SalesByDaySimpleLineProcessor.PROMO_VAL_KEY,
              yName: form[PromoConfig.periodNames[PromoPeriods.PROMO]].customPeriodName,
              stroke: SalesByDaySimpleLineProcessor.YELLOW,
              marker: {
                stroke: SalesByDaySimpleLineProcessor.YELLOW,
                fill: SalesByDaySimpleLineProcessor.YELLOW,
              },
              tooltip: {
                renderer: (p) => {
                  const index = data.dataMap.get(p.datum[p.xKey]);
                  const dataObject = data.promoYOYData[index];
                  const formatter = GridService.getVisualType(component.factKey).formatter;
                  return (
                    '<div class="ag-chart-tooltip-title" style="background-color:' + p.color + '">' +
                      xFormatter({value: p.datum['day']}) +
                    '</div>' +
                    '<div class="ag-chart-tooltip-content">' +
                      '<span>' + form[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName + ': ' + formatter({value: dataObject[SalesByDaySimpleLineProcessor.YOY_VAL_KEY]}) + '</span>' +
                      '</br>' +
                      '<span style="color:' + p.color + '">' + p.yName + ': ' + formatter({value: dataObject[SalesByDaySimpleLineProcessor.PROMO_VAL_KEY]}) + '</span>' +
                    '</div>'
                  );
                }
              }
            }
          );
          (<AgCartesianChartOptions>newChartOptions).series.push(
            { // yoy period
              type: 'line',
              data: data.promoYOYData,
              xKey: 'day',
              yKey: SalesByDaySimpleLineProcessor.YOY_VAL_KEY,
              yName: form[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName,
              stroke: SalesByDaySimpleLineProcessor.ORANGE,
              marker: {
                stroke: SalesByDaySimpleLineProcessor.ORANGE,
                fill: SalesByDaySimpleLineProcessor.ORANGE,
              },
              tooltip: {
                renderer: (p) => {
                  const index = data.dataMap.get(p.datum[p.xKey]);
                  const dataObject = data.promoYOYData[index];
                  const day = DatesService.add(Number(p.datum['day']), {years: -1}).getTime();
                  const formatter = GridService.getVisualType(component.factKey).formatter;
                  return (
                    '<div class="ag-chart-tooltip-title" style="background-color:' + p.color + '">' +
                      xFormatter({value: day}) +
                    '</div>' +
                    '<div class="ag-chart-tooltip-content">' +
                      '<span style="color:' + p.color + '">' + p.yName + ': ' + formatter({value: dataObject[SalesByDaySimpleLineProcessor.YOY_VAL_KEY]}) + '</span>' +
                      '</br>' +
                      '<span>' + form[PromoConfig.periodNames[PromoPeriods.PROMO]].customPeriodName + ': ' + formatter({value: dataObject[SalesByDaySimpleLineProcessor.PROMO_VAL_KEY]}) + '</span>' +
                    '</div>'
                  );
                }
              }
            }
          );
        }

        const fact = (params.component instanceof SovHelperComponent) ? params.component.factKey : params.parent.factKey;

        let matchAxisY = newChartOptions.axes[1];
        newChartOptions.axes[1].title.text = fact.display;

        const formatterY = GridService.getVisualType(fact).formatter;

        matchAxisY.label.formatter = (params) => formatterY({value: params.value}, true);

        if (params.chart) {
          params.chart.agChartOptions = newChartOptions;
        }
      });

    return null;
  }

  /**
   * For the sales by day chart, it includes up to 4 series of line charts(custom, pre-promo, promo, yoy)
   * This function divide the raw data into 3 sets of data, so that each series has their own data.
   * (promo and yoy shares the same data for the convenience of rendering the tooltip that shows both data points)
   * @param rawData
   * @param factId: the current selected fact's id.
   */
  public static divideData(rawData: any[], factId: string, form: PromoFormData): SalesByDayTSDataSet {
    const customDatesData = [];
    const preDatesData = [];
    const promoYOYData = [];
    const promoYOYDataMap = new Map<number, number>(); // key is the timestamp, val is the index in the promoYOYData
    rawData.sort((a, b) => {
      return isLessThan(a[PromoDimensionKeys.TIMESERIES_DAY], b[PromoDimensionKeys.TIMESERIES_DAY]);
    });

    rawData.forEach(datum => {
      const timestamp = Number(datum[PromoDimensionKeys.TIMESERIES_DAY]);
      switch (datum[PromoDimensionKeys.PERIOD]) {
        case form[PromoConfig.periodNames[PromoPeriods.POST]].customPeriodName: // "Custom Period"
          customDatesData.push({
            day: timestamp,
            value: datum[factId].val
          });
          break;
        case form[PromoConfig.periodNames[PromoPeriods.PRE]].customPeriodName: // "Pre Promo Period"
          preDatesData.push({
            day: timestamp,
            value: datum[factId].val
          });
          break;
        case form[PromoConfig.periodNames[PromoPeriods.PROMO]].customPeriodName: // "Promo Period"
          const valueKeyPromo = this.PROMO_VAL_KEY;
          if (promoYOYDataMap.has(timestamp)) {
            promoYOYData[promoYOYDataMap.get(timestamp)][valueKeyPromo] = datum[factId].val;
          } else {
            promoYOYDataMap.set(timestamp, promoYOYDataMap.size);
            const d = {
              day: timestamp,
            };
            d[valueKeyPromo] = datum[factId].val;
            promoYOYData.push(d);
          }
          break;
        case form[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName: // "Prior Year"
          const valueKeyYOY = this.YOY_VAL_KEY;
          if (promoYOYDataMap.has(timestamp)) {
            promoYOYData[promoYOYDataMap.get(timestamp)][valueKeyYOY] = datum[factId].val;
          } else {
            promoYOYDataMap.set(timestamp, promoYOYDataMap.size);
            const d = {
              day: timestamp,
            };
            d[valueKeyYOY] = datum[factId].val;
            promoYOYData.push(d);
          }
          break;

      }
    });

    return {
      customDatesData: customDatesData,
      preDatesData: preDatesData,
      promoYOYData: promoYOYData,
      dataMap: promoYOYDataMap
    };
  }
}

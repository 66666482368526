<div id="tabBody" class="ag-tab-body">
  <div class="ag-filter">
    <div>
      <div class="ag-filter-select">
        <select [(ngModel)]="filterType">
          <option *ngFor="let filterType of filterTypes" [value]="filterType">{{ filterLabels[filterType] }}</option>
        </select>
        <br/>
        <input type="radio" name="time" [(ngModel)]="twentyFourHourTime" [value]="false" checked> 12 hr&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" name="time" [(ngModel)]="twentyFourHourTime" [value]="true"> 24 hr
      </div>
      <div class="ag-filter-body" (keydown.enter)="applyFilter()">
        <div>
          <input #hour class="ag-filter-filter hour" type="number" [(ngModel)]="form.hour" maxLength="2" min="{{ twentyFourHourTime ? 0 : 1 }}" max="{{ twentyFourHourTime ? 23 : 12 }}" placeholder="hh">
          <span *ngIf="!twentyFourHourTime"><input type="radio" name="period" [(ngModel)]="form.period" value="am" checked> AM&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" name="period" [(ngModel)]="form.period" value="pm"> PM</span>
        </div>
        <div *ngIf="filterType === 'inRange'">
          <input class="ag-filter-filter hour" type="number" [(ngModel)]="formRangeEnd.hour" maxLength="2" min="{{ twentyFourHourTime ? 0 : 1 }}" max="{{ twentyFourHourTime ? 23 : 12 }}" placeholder="hh">
          <span *ngIf="!twentyFourHourTime"><input type="radio" name="periodRangeEnd" [(ngModel)]="formRangeEnd.period" value="am" checked> AM&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" name="periodRangeEnd" [(ngModel)]="formRangeEnd.period" value="pm"> PM</span>
        </div>
      </div>
      <div class="ag-filter-apply-panel" id="applyPanel">
        <button type="button" id="clearButton" (click)="clearFilter()">Clear Filter</button>
        <button type="button" id="applyButton" (click)="applyFilter()">Apply Filter</button>
      </div>
    </div>
  </div>
</div>

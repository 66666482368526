import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DaypartsConfig } from 'app/siq-applications/modules/dayparts/models/dayparts-config.model';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, finalize, switchMap, takeUntil } from 'rxjs';
import { DaypartsService } from 'app/siq-applications/modules/dayparts/services/dayparts.service';
import { ActivityStatus } from 'app/activity/models/activity.model';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { ActivityService } from 'app/activity/services/activity.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { BehaviorSubject, of } from 'rxjs';
import { StatResponse } from 'app/core/models/stat-response.model';
import { NonStatResponse } from 'app/core/models/non-stat-response.model';
import { CloudExportable } from 'app/core/modules/cloud-export/models/cloud-export.interface';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import {
  GridComponent,
  HeatmapSelectorComponent,
  SimpleLineConfig,
  VisualOptions
} from '@siq-js/visual-lib';
import {
  DaypartsGridParams,
  DaypartsGridProcessor
} from 'app/siq-applications/modules/dayparts/components/dayparts-result/processors/dayparts-grid-processor';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { VisualService } from 'libs/visual-lib/src/lib/services/visual.service';
import { DaypartsSimpleLineProcessor } from 'app/siq-applications/modules/dayparts/components/dayparts-result/processors/dayparts-simple-line-processor';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { SimpleLineParams } from 'libs/visual-lib/src/lib/modules/charts-graphs/models';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { DaypartsFormData } from 'app/siq-applications/modules/dayparts/models/form/dayparts-form-data.model';
import { ActivityGridOptionsMenu } from 'app/siq-applications/modules/shared/components/activity-grid-options-menu/activity-grid-options-menu.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { DaypartsActivity } from 'app/siq-applications/modules/dayparts/models/dayparts-activity.model';
import { EmitterService } from 'app/core/services/emitter/emitter.service';

@Component({
  selector: 'siq-js-dayparts-result',
  templateUrl: './dayparts-result.component.html',
  styleUrls: ['./dayparts-result.component.scss']
})
export class DaypartsResultComponent extends BaseSiqComponent implements OnInit, CloudExportable {

  @ViewChild('queryMode') queryMode: QueryModeComponent;
  @ViewChildren(HeatmapSelectorComponent) heatmapSelectorList: QueryList<HeatmapSelectorComponent>;

  // If you modify customOptionMenuItems, make sure to modify handleOptionMenuClick() function accordingly
  public customOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
    ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
    ActivityGridOptionsMenu.DOWNLOAD_CSV,
    ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
    ActivityGridOptionsMenu.SHARE_SCHEDULE,
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  // only items present in customOptionMenuItems, handleOptionMenuClick() can be used
  public errorAndInvalidOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  public applicationName: string = null;
  public formData: DaypartsFormData;
  public grid: GridComponent;
  public gridParams: DaypartsGridParams;
  public gridProcessor = DaypartsGridProcessor.processor;
  public gridVisualOptions: VisualOptions;
  public isCloudExportable: boolean;
  public readyForExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // if grid is ready to be exported
  public reportActivity: DaypartsActivity;
  public results: (StatResponse | NonStatResponse)[];
  public selectedDimensionDaily$: BehaviorSubject<CmsField> = new BehaviorSubject<CmsField>(null);
  public selectedDimensionHourly$: BehaviorSubject<CmsField> = new BehaviorSubject<CmsField>(null);
  public selectedMetrics$: BehaviorSubject<CmsMetric[]> = new BehaviorSubject<CmsMetric[]>(null);
  public simpleLineConfigDaily: SimpleLineConfig;
  public simpleLineConfigHourly: SimpleLineConfig;
  public simpleLineParamsDaily: SimpleLineParams;
  public simpleLineParamsHourly: SimpleLineParams;
  public simpleLineProcessor = DaypartsSimpleLineProcessor.processor;
  public statusEnums = ActivityStatus;
  public status: ActivityStatus;
  private reportId: string;
  public showSpinner: boolean;

  constructor(
    public activityService: ActivityService,
    public cloudExportService: CloudExportService,
    public config: DaypartsConfig,
    public route: ActivatedRoute,
    public router: Router,
    private asyncStatusService: AsyncStatusService,
    private daypartsService: DaypartsService,
    private mixpanelService: MixpanelService,
    private sharingService: SharingService
  ) {
    super();
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ cms: true, envConfig: true });

    this.showSpinner = true;
    this.route.paramMap // check URL
      .pipe(
        takeUntil(this.unsub$)
      )
      .subscribe(params => {
        this.reportId = params.get('id');
        this.poll();
      });

    this.isCloudExportable = await CloudExportService.isExportable(this);
    if (this.isCloudExportable) {
      CloudExportService.setReadyForExportSubscription(this.readyForExport$, this);
    }

    this.connectHeatmapSelectorToCharts();
  }

  attachCloudData(collection: VisualOptions[]) {
    if (!this.isCloudExportable) return;
    this.cloudExportService.attachCloudData(collection);
  }

  gridFeatureUsed(feature: string) {
    // Pivot mode is not a feautre in Dayparts but the way data are always presented,
    // do not track this event
    if (feature != 'Pivot Mode') {
      this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
        'Application': this.config.getApplication().display,
        'Feature': feature,
      });
    }
  }

  public toggleDimensions() {
    const gc = <GridComponent>VisualService.findVisualization(this.gridParams.gridVisualOptions.vizId);
    let _state = gc.visualizationState$.getValue();
    UtilsService.swap(_state.dimensions);
    gc.visualizationState$.next(_state);
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': 'Swap Dimensions'
    });
  }

  private init(reportActivity: DaypartsActivity) {
    this.reportActivity = reportActivity;
    this.formData = this.daypartsService.createForm(JSON.parse(reportActivity.formValues));
    this.queryMode.setSchema(this.formData.schema);
    setTimeout(() => {
      this.mixpanelService.track(MixpanelEvent.RESULTS_VIEWED, {
        'Application': this.config.getApplication().display,
        'Application ID': this.config.appId,
        'Application Version': this.config.version,
        'Activity ID': reportActivity.getId(),
        'Viewed From': 'Application'
      });
    });

    this.activityService.markViewed(this.reportActivity, this.route.snapshot.queryParamMap.get('source'));

    if (!this.reportActivity.isMine()) { // user is not report owner, cannot share/schedule report
      this.customOptionMenuItems = [
        ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
        ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
        ActivityGridOptionsMenu.DOWNLOAD_CSV,
        ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
        ActivityGridOptionsMenu.CLONE_CRITERIA
      ];
    }
  }

  private connectHeatmapSelectorToCharts() {
    /*
    The HeatmapSelector (child) component is not available in ngOnInit, and would typically become available in ngAfterInit.
    However, it is hidden by an "ngIf" in the template, so it actually only becomes accessible after "ngAfterInit" AND when
    its "ngIf" check is fulfilled. This "ngIf" is reliant on the DaypartsSimpleLineParams being set, which we know will have
    been set once "this.ready$" is true, so we put this logic in a subscription to "this.ready$".
     */
    const accessHeatmapSelectorMetricsSub = this.ready$.subscribe(ready => {
      if (ready) {
        // The Grid is ready and in the DOM; The HeatmapSelector references this grid so it too is now available in the DOM.
        const heatmapSelectorListSub = this.heatmapSelectorList.changes.subscribe((comps: QueryList <HeatmapSelectorComponent>) => {
          if (comps.first) {
            /*
            We now have reference to the HeatmapSelector.
            Create a sub that will pass changes to the selectedMetrics$ in HeatmapComponent along to the local
            this.selectedMetrics$ BehaviorSubject. This was done in this manner so that the local this.selectedMetrics$
            could be set as part of the DaypartsSimpleLineParams object, then its value updated when the HeatmapSelector
            component is eventually available in the DOM (due to accessing this via ViewChildren and it having its own ngIf
            in the template).
             */
            comps.first.selectedMetrics$.pipe(
              takeUntil(this.unsub$)
            )
            .subscribe(selectedMetrics => {
              this.selectedMetrics$.next(selectedMetrics);
            });

            heatmapSelectorListSub.unsubscribe();
          }
        });

        accessHeatmapSelectorMetricsSub.unsubscribe();
      }
    });
  }

  private processReport() {
    this.results = this.reportActivity.getJobs().map(job => job.getResponse());
    let resultsSRList: StatResponse[] = this.results as StatResponse[];

    // Setup the Grid
    this.gridParams = {
      parent: this,
      statResponse: resultsSRList[2],
      readyForExport$: this.readyForExport$
    };

    this.gridParams.gridVisualOptions = DaypartsGridProcessor.generateGridVisualOptions(this.gridParams);

    // Setup the Charts
    // Set the first Dimension to be used on the x-axis in the SimpleLineCharts
    this.selectedDimensionHourly$.next(resultsSRList[0].getDimensions()[0]);
    this.selectedDimensionDaily$.next(resultsSRList[1].getDimensions()[0]);

    this.simpleLineParamsHourly = {
      dimensions: resultsSRList[0].getDimensions(),
      metrics: resultsSRList[0].getFacts(),
      parent: this,
      parentActivity: this.reportActivity,
      selectedDimension$: this.selectedDimensionHourly$,
      selectedMetrics$: this.selectedMetrics$, // internal BehaviorSubject that will get updated via sub to this.heatmapSelectorList.first.selectedMetrics$
      statResponse: resultsSRList[0]
    };
    this.simpleLineConfigHourly = DaypartsSimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParamsHourly);

    this.simpleLineParamsDaily = {
      dimensions: resultsSRList[1].getDimensions(),
      metrics: resultsSRList[1].getFacts(),
      parent: this,
      parentActivity: this.reportActivity,
      selectedDimension$: this.selectedDimensionDaily$,
      selectedMetrics$: this.selectedMetrics$, // internal BehaviorSubject that will get updated via sub to this.heatmapSelectorList.first.selectedMetrics$
      statResponse: resultsSRList[1]
    };
    this.simpleLineConfigDaily = DaypartsSimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParamsDaily);
  }

  handleOptionMenuClick(clickedItem: string) {
    switch (clickedItem) {
      case ActivityGridOptionsMenu.AUTOSIZE_COLUMNS.trim():
        this.autoSize();
        break;
      case ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN.trim():
        this.fitColumnsToScreen();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_CSV.trim():
        this.exportSheetCSV();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_EXCEL.trim():
        this.exportSheetXLSX();
        break;
      case ActivityGridOptionsMenu.SHARE_SCHEDULE.trim():
        this.share();
        break;
      case ActivityGridOptionsMenu.CLONE_CRITERIA.trim():
        this.cloneCriteria();
      case ActivityGridOptionsMenu.EDIT_CRITERIA.trim():
        this.editCriteria();
      default:
        break;
    }
  }

  public autoSize() {
    const grid = this.gridParams.gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
    setTimeout(() => grid.api.autoSizeAllColumns(), 150);
  }

  public fitColumnsToScreen() {
    const grid = this.gridParams.gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
  }

  public exportSheetCSV() {
    this.daypartsService.exportSheetAsCSV(this.reportActivity, this.gridParams.gridVisualOptions);
  }

  public exportSheetXLSX() {
    this.daypartsService.exportSheetAsExcel(this.reportActivity, this.gridParams.gridVisualOptions);
  }

  public share() {
    this.sharingService.share(this.reportActivity);
  }

  public cloneCriteria() {
    this.daypartsService.cloneReport(this.reportActivity.getId());
  }

  public editCriteria() {

  }

  private poll() {
    of(true)
      .pipe(
        delay(this.config.pollInterval),
        takeUntil(this.unsub$),
        switchMap(_ =>
          this.daypartsService.getReport(this.reportId) // Retrieve report
          .pipe(
            finalize(() => {
              // stop progress bar
              EmitterService.get('topLoading').emit(false);
            })
          )
        )
      )
      .subscribe(a => {
        this.reportActivity = a;
        this.applicationName = this.reportActivity.getName();
        this.checkStatus();
      }, error => {
        // stop progress bar
        EmitterService.get('topLoading').emit(false);
      });
  }

  private checkStatus() {
    this.status = this.reportActivity.getStatus();
    switch (this.status) {
      case ActivityStatus.ERROR:
      case ActivityStatus.ALERT:
        this.showSpinner = false;
        break;
      case ActivityStatus.READY:
        this.init(this.reportActivity);
        this.processReport();
        this.showSpinner = false;
        this.ready();
        break;
      case ActivityStatus.EXPIRED:
      case ActivityStatus.RUNNING:
        this.poll();
        break;
    }
  }
}

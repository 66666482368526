import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

@Component({
  selector: 'siq-frequency-week',
  templateUrl: './frequency-week.component.html',
  styleUrls: ['./frequency-week.component.scss']
})
export class FrequencyWeekComponent implements OnInit {
  @Input() frqPosted: string;
  @Input() formControlByWeek: UntypedFormControl;
  public weekdays = DatesService.getWeekdays('EEEEEE').map(a => a.toUpperCase());
  public weekdaysMap = this.weekdays.reduce((ret, day) => { ret[day] = false; return ret; }, {});

  ngOnInit() {
    if (this.frqPosted === 'WEEKLY') {
      if (!_.isNil(this.formControlByWeek.value)) {
        this.formControlByWeek.value.split(',').map((key) => this.weekdaysMap[key] = true);
      }
    } else {
      const curWeekday = this.weekdays[(new Date()).getDay()];
      this.weekdaysMap[curWeekday] = true;
    }
    this.updateForm();
  }

  addDay(day) {
    if (this.weekdaysMap[day]) {
      this.weekdaysMap[day] = false;
    } else {
      this.weekdaysMap[day] = true;
    }
    this.updateForm();
  }

  updateForm() {
    if (this.weekdaysMap) {
      this.formControlByWeek.setValue(this.weekdays.filter((day) => this.weekdaysMap[day]).join(','));
    }
  }
}

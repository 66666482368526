import { ActivityOptionsComponent } from './components/activity-options/activity-options.component';
import { ActivityRoutingModule } from 'app/activity/routes/activity-routing.module';
import { ActivityService } from 'app/activity/services/activity.service';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { FavoritesModule } from './modules/favorites/favorites.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SharingModule } from 'app/activity/modules/sharing/sharing.module';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { ActivityGridComponent } from './components/activity-grid/activity-grid.component';
import { AgGridModule } from '@siq-js/visual-lib';
import { ActivityTableComponent } from './components/activity-table/activity-table.component';
import { FavoriteRendererComponent } from './components/renderers/favorite-renderer/favorite-renderer.component';
import { NameRendererComponent } from './components/renderers/name-renderer/name-renderer.component';
import { StatusRendererComponent } from './components/renderers/status-renderer/status-renderer.component';
import { AllActivitiesComponent } from 'app/activity/components/all-activity-list/all-activities.component';
import { ApplicationTypeRendererComponent } from './components/renderers/application-type-renderer/application-type-renderer.component';
import { ApplicationNameRendererComponent } from './components/renderers/application-name-renderer/application-name-renderer.component';
import { CreatedRendererComponent } from 'app/activity/components/renderers/created-renderer/created-renderer.component';
import { ActivityColumnHeaderRendererComponent } from 'app/activity/components/renderers/activity-column-header-renderer/activity-column-header-renderer.component';
import { ActionsRendererComponent } from 'app/activity/components/renderers/actions-renderer/actions-renderer.component';
import { ActivityCheckboxHeaderRendererComponent } from './components/renderers/activity-checkbox-header-renderer/activity-checkbox-header-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    ActivityRoutingModule,
    FavoritesModule,
    SiqMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharingModule,
    CoreModule,
    AgGridModule,
  ],
  declarations: [
    ActivityOptionsComponent,
    ActivityGridComponent,
    ActivityTableComponent,
    FavoriteRendererComponent,
    NameRendererComponent,
    CreatedRendererComponent,
    ActionsRendererComponent,
    ActivityColumnHeaderRendererComponent,
    StatusRendererComponent,
    AllActivitiesComponent,
    ApplicationTypeRendererComponent,
    ApplicationNameRendererComponent,
    ActivityCheckboxHeaderRendererComponent,
  ],
  exports: [
    ActivityGridComponent,
  ],
  providers: [
    ActivityService
  ]
})
export class ActivityModule {
}

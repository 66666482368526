import { Component, OnDestroy } from '@angular/core';
import { AffinitiesConfig } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { UsesActivityGrid } from 'app/activity/components/activity-grid/activity-grid.component';
import { BehaviorSubject } from 'rxjs';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ColDef } from '@siq-js/visual-lib';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { ActivityService } from 'app/activity/services/activity.service';
import { ActivityCriteriaGenerator } from 'app/activity/models/activity-criteria-generator';
import { AffinitiesCriteriaRendererComponent } from 'app/siq-applications/modules/affinities/components//affinities-criteria-renderer/affinities-criteria-renderer.component';
import { AffinitiesActivity } from 'app/siq-applications/modules/affinities/models/affinities-activity.model';
import { ApplicationHash } from '@siq-js/core-lib';

@Component({
  templateUrl: './affinities-list.component.html',
  styleUrls: ['./affinities-list.component.scss']
})
export class AffinitiesListComponent implements OnDestroy, UsesActivityGrid {

  // ActivityGrid interface fields
  public activityStream$: BehaviorSubject<AffinitiesActivity[]>;
  public actions = this.config.actions;
  public columns: (tabKey: ActivityTabKey) => ColDef[];

  public callbacks: ActivityCallbacks = {
    rerun: activity => this.activityService.rerun(activity),
    clone: activity => {
      this.affinitiesService.cloneReport(activity.getId());
    }
  };

  constructor(
    public config: AffinitiesConfig,
    private activityService: ActivityService,
    private affinitiesService: AffinitiesService,
  ) {
    this.activityStream$ = AffinitiesService.Activities$;
    this.columns = tabKey => ActivityCriteriaGenerator.generateColDefs(tabKey, [AffinitiesCriteriaRendererComponent.ColDef]);
    this.activityService.getCurrentPageInfo().appId = ApplicationHash.AFFINITIES;
    this.activityService.getCurrentPageInfo().tab = ActivityTabKey.MY_REPORTS;
    ActivityService.refresh();
  }

  ngOnDestroy() {
    this.activityService.clearCurrentPageInfo();
  }
}

import * as _ from 'lodash';
import { AbstractReportBuilderEntity } from 'app/siq-applications/modules/report-builder/models/abstract-report-builder-entity.model';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { ReportBuilderSheet } from 'app/siq-applications/modules/report-builder/models/results/report-builder-sheet.model';
import { ActivityStatus } from 'app/activity/models/activity.model';

export class ReportBuilderDraft extends AbstractReportBuilderEntity {
  public draft = true;

  public data: ReportBuilderFormData;
  public status: ActivityStatus; // Will only get populated if a ReportBuilderSheet is cloned into a draft
  private originalFormData: any; // For change detection

  constructor(public parent: ReportBuilderResultComponent,
              formData?: ReportBuilderFormData) {
    super(parent);

    if (!formData) {
      this.data = parent.reportBuilderService.createForm();
      this.data.name = 'Sheet ' + (parent.sheets.length + 1);
    } else {
      this.data = formData;
      this.status = formData.status;
    }
  }

  getForm(): ReportBuilderFormData {
    return this.data;
  }

  clone(): ReportBuilderDraft {
    const clonedFormData = this.parent.reportBuilderService.createForm(this.data.toJson());
    clonedFormData.name = 'Copy of ' + this.data.name;
    return new ReportBuilderDraft(this.parent, clonedFormData);
  }

  toJson() {
    return this.data.toJson();
  }

  setActive() {
    this.originalFormData = this.data.toJson();
    this.parent.activeSheet = this;
  }

  editName(event) {
    if (this.parent.activeSheet !== this || this.parent.readonly) {
      event.stopPropagation();
      return this.parent.setActiveSheet(this);
    }
    this.parent.model.sheetName = this.data.name;
    setTimeout(() => this.parent.sheetNameInput.nativeElement.focus());
  }

  updateName(newName: string) {
    newName = newName.trim().replace(/[.]/g, '_');
    if (this.data.name !== newName) {
      this.data.name = newName;
      this.parent.sync();
    }

    delete this.parent.model.sheetName;
  }

  // Checks if the current formData differs from the original, and propagates a sync() call
  public sync(force = false) {
    if (force || !_.isEqual(this.originalFormData, this.data.toJson())) {
      this.parent.sync();
    }
  }

  // Converts this draft to a ReportBuilderSheet
  public convertToSheet(sheetId: string) {
    const index = _.findIndex(this.parent.sheets, s => s === this); // Find this sheet's index
    this.parent.reportBuilderService.getSheet(sheetId, this.parent.report.getId())
      .subscribe(sheetActivity => {
        // Get the activity and replace this with a ReportBuilderSheet
        const sheet = new ReportBuilderSheet(this.parent, sheetActivity);
        this.parent.sheetForm.model.running = false;
        const _sheets = this.parent.sheets.slice();
        _sheets[index] = sheet;
        this.parent.sheets$.next(_sheets);
        this.parent.setActiveSheet(sheet);
        this.sync(true);
      });
  }
}

<div class="promo-helper-container" *ngIf="drilldowns">

  <mat-form-field class="drilldowns" color="accent">
    <mat-select [(value)]="drilldownKey" (valueChange)="updateDrilldown(drilldownKey)">
      <mat-option *ngFor="let dr of drilldowns" [value]="dr.label" [disabled]="!dr.data">
        {{ dr.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="currDrilldown">

    <mat-card class="promo-section">
      <siq-js-simple-line
        *ngIf="simpleLineParams && simpleLineConfig"
        [ngClass]="{'hide': chartType !== chartTypes.TIMESERIES}"
        [config]="simpleLineConfig"
        [data]="simpleLineParams"
        [processor]="simpleLineProcessor"
      ></siq-js-simple-line>

      <siq-js-bar-column
        *ngIf="columnParams && columnConfig "
        [ngClass]="{'hide': chartType !== chartTypes.DISTRIBUTION}"
        [config]="columnConfig"
        [data]="columnParams"
        [processor]="columnProcessor"
      ></siq-js-bar-column>

      <div class="chart-selection-panel">
        <button mat-stroked-button
          (click)="switchChart(chartTypes.TIMESERIES)"
          class="chart-option thin-button"
          [ngClass]="chartType === chartTypes.TIMESERIES ? 'selected' : 'secondary-button'"
        >Sales By Day</button>

        <button mat-stroked-button
          (click)="switchChart(chartTypes.DISTRIBUTION)"
          class="chart-option thin-button"
          [ngClass]="chartType === chartTypes.DISTRIBUTION ? 'selected' : 'secondary-button'"
        >Sales By Store</button>
      </div>

    </mat-card>

    <section class="promo-section">

      <div class="promo-panel">

        <mat-form-field *ngIf="chartType === chartTypes.DISTRIBUTION" color="accent">
          <mat-select [(value)]="distDim" (valueChange)="updateView()">
            <mat-option *ngFor="let dim of distDims" [value]="dim">
              {{ dim.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-select [disabled]="chartType === chartTypes.TIMESERIES" [(value)]="unitType" (valueChange)="updateUnitType()">
            <mat-option [value]="unitTypes.ABSOLUTE">
              Absolute
            </mat-option>
            <mat-option [value]="unitTypes.PSPW">
              Per Store Per Week
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="facts" color="accent">
          <mat-select [(value)]="factKey" (valueChange)="updateView()">
            <mat-option *ngFor="let fact of facts" [value]="fact">
              {{ fact.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="toggle no-select" [ngClass]="{'hidden': chartType === chartTypes.TIMESERIES}">
          <label>{{preCustomName}}</label>
          <mat-slide-toggle (change)="togglePeriod($event.checked)" color="accent"></mat-slide-toggle>
          <label>{{yoyCustomName}}</label>
        </div>
        <div class="clearfix"></div>
      </div>

      <div class="promo-helper-table">
        <siq-js-grid
          *ngIf="ready$.value && gridParams"
          [ngClass]="{'distribution': this.chartType === chartTypes.DISTRIBUTION, 'time-series': this.chartType === chartTypes.TIMESERIES}"
          [config]="gridParams.gridVisualOptions.gridConfig"
          [data]="gridParams"
          [processor]="gridProcessor"
          (featureUsed)="gridFeatureUsed($event)"
        ></siq-js-grid>
      </div>
    </section>


  </ng-container>
</div>

import { Component } from '@angular/core';
import { Activity } from 'app/activity/models/activity.model';
import { AgRendererComponent, ColDef } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-application-name-renderer',
  templateUrl: './application-name-renderer.component.html',
  styleUrls: ['./application-name-renderer.component.scss']
})
export class ApplicationNameRendererComponent implements AgRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: 'Type',
    cellRenderer: ApplicationNameRendererComponent,
    width: 130,
    resizable: false,
    sortable: true,
    valueGetter: params => {
      const activity = params.data as Activity;
      return activity.getApplicationName();
    },
    pinned: 'right'
  };

  public appName: string;

  constructor() { }

  agInit(params: any) {
    this.appName = params.value;
  }

  refresh(): boolean {
    return true;
  }

}

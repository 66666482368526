/**
 * This interface should be implemented by any class that can be serialized into (and reconstructed from) a typed JSON format <T>
 *
 * Simple example, a serializable class Foo:
 *
 * 1. Create an interface for the serialized JSON
 *
 * interface FooJson {
 *   bar: string;
 * }
 *
 * 2. Create the class, implementing Serializable<{{interface}}>.
 *
 * class Foo implements Serializable<FooJson> {
 *   bar: string;
 * }
 *
 * 3. Add the reconstruction logic in the constructor, this ensures that we will always be able to construct a copy of Foo with a JSON object typed as FooJson
 *
 *    constructor(json: FooJson) {
 *        this.bar = json.bar;
 *    }
 *
 * 4. Implement the serialization logic in the toJson() function
 *
 *    toJson(): FooJson {
 *        return { bar: this.bar };
 *    }
 *
 * Final product:
 *
 * interface FooJson {
 *   bar: string;
 * }
 *
 * class Foo implements Serializable<FooJson> {
 *   bar: string;
 *
 *   constructor(json: FooJson) {
 *     this.bar = json.bar;
 *   }
 *
 *   toJson(): FooJson {
 *     return { bar: this.bar };
 *   }
 * }
 */

export interface Serializable<T> {
  toJson(): T;
}

import { Injectable } from '@angular/core';
import { CmsService } from 'app/core/services/cms/cms.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { GridService } from '@siq-js/visual-lib';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { ContentType } from '@siq-js/core-lib';

export enum Validation {
  LOYALTY = 'loyalty'
}

@Injectable({ providedIn: 'root' })
export class ValidationService {
  public static readonly CUSTOMER_ENDPOINT = 'app/has-loyalty-info';
  public static readonly LOYALTY_FACT_LIST = ['NUM_CUSTOMERS', 'AVG_PER_CUSTOMER_QUANTITY', 'AVG_PER_CUSTOMER_AMOUNT', 'SELLING_UPC_PER_CUSTOMER', 'AVG_PER_STORE_NUM_CUSTOMERS', 'AVG_PER_CUSTOMER_NUM_TRANSACTIONS'];
  public static readonly CORE_LOYALTY_STATUS = 'loyalty_status';
  public static readonly CORE_CUSTOMER_ID = 'customerid';
  public static readonly CORE_LOYALTY_FIELD_LIST = [ValidationService.CORE_LOYALTY_STATUS, ValidationService.CORE_CUSTOMER_ID]; // used in promo stepper component -> transactionMapFn
  public static readonly SR_LOYALTY_FIELD_LIST = ['|transaction|loyalty_status', '|customer|customerid'];
  public static readonly loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static customerDataLookup: Map<string, boolean> = new Map(); // Key is retailer and value is true if this retailer has customer data(translation.customer table)
  public availableValidations: Map<string, (args: any)=>any> = new Map(); // key is validation name, value is validation logic/function

  constructor(
    private siqHttp: SiqHttpService,
  ) {
    /*
      Add entry to Map in GridService to handle these explicit substitutions in formatter functions generated in GridService.
      Done this way to avoid circular reference where libs/visual-lib was importing GridService.LOYALTY_FACT_LIST.
    */
    GridService.FORMATTER_FN_SUBSTITUTIONS.set('N/A', ValidationService.LOYALTY_FACT_LIST);
    this.init();
  }

  public retrieveData(retailers: string[]): Observable<boolean> {
    this.getData(retailers);
    ValidationService.loaded$.next(true);
    return ValidationService.loaded$;
  }

  // Add all extra business validations/logics here
  private init() {
    this.availableValidations.set(Validation.LOYALTY, this.processLoyaltyEntities.bind(this)); // loyalty logics ICE-1650 & ICE-3552
  }

  private processLoyaltyEntities(schema: string) {
    this.processAggLoyaltyStatusDimensions(schema);
    this.processAggCustomerIdDimension(schema);
    this.processCustomerMetrics(schema);
  }

  private processCustomerMetrics(schema: string) {
    const cmsConfig = CmsService.get();
    const metrics = ValidationService.LOYALTY_FACT_LIST.map(id => cmsConfig.findEntity(id)).filter(m => !!m);

    metrics.forEach(metric => {
      if (schema == EnvConfigService.getConfig().primaryEntity) { // MRV
        if (CmsService.LOYALTY_FACT_EXCLUDE_LIST_MRV.includes(metric.id)) {
          metric.active = false;
        } else {
          metric.active = ValidationService.customerDataLookup.get(schema);
        }
      } else {
        metric.active = ValidationService.customerDataLookup.get(schema);
      }
    });
  }

  /**
   * In MRV,go through all dimensions, and if at least one retailer loyalty status dimension is present, then show the Aggregated loyalty status dimension.
   * If not, then hide the Aggregated loyalty status dimension(Use the CmsConfig -> lookupCache to get the dimensions and set active = false.
   * In doing so, the corresponding filters will disappeared too because in BaseFilterGroup -> addFilter, the available filters are generated based on the active dimensions ).
   * In SRV, hide Aggregated loyalty status dimension
   */
  private processAggLoyaltyStatusDimensions(schema: string) {
    const envConfig = EnvConfigService.getConfig();
    const fields = CmsService.get().fields;

    if (envConfig.retailers.length === 1) { // only have access to 1 retailer, hide agg loyalty dims
      this.showHideDim(ValidationService.CORE_LOYALTY_STATUS, false);
      return;
    }

    const ids = schema === envConfig.primaryEntity ? ValidationService.SR_LOYALTY_FIELD_LIST : ValidationService.SR_LOYALTY_FIELD_LIST.map(id => schema + id);
    for (let i = 0; i < fields.length; i ++) {
      for (let j = 0; j < ids.length; j++) {
        // if MRV && at least one retailer loyalty field is present show agg loyalty fields.
        if (fields[i].id.includes(ids[j])) {
          this.showHideDim(ValidationService.CORE_LOYALTY_STATUS, schema === envConfig.primaryEntity);
          return;
        }
      }
    }
    this.showHideDim(ValidationService.CORE_LOYALTY_STATUS, false);
    return;
  }

  // ICE-3766: “Aggregated Customer ID” should be visible in the same analysis modes as Customer metrics.
  private processAggCustomerIdDimension(schema: string) {
    this.showHideDim(ValidationService.CORE_CUSTOMER_ID, ValidationService.customerDataLookup.get(schema));
  }

  private showHideDim(id: string, show: boolean) {
    const dim = CmsService.get().findEntity(id);
    if (dim) {
      dim.active = show;
    }
  }

  /**
   * Get retailers data from BE, if they have Loyalty ID
   * @param retailers
   * @private
   */
  private getData(retailers: string[]) {
    const segmentRetailers = { retailers: retailers };
    this.siqHttp.create({
      endpoint: ValidationService.CUSTOMER_ENDPOINT,
      body: segmentRetailers,
      suppressNotification: true,
      type: ContentType.JSON}).pipe(map(res => res.body), catchError(this.siqHttp.handleHttpError)).subscribe((retailersLoyalty: boolean[]) => {
      // this is an error
      if (retailers.length != retailersLoyalty.length) {
        throw new Error("retailers and retailersLoyalty arrays are different, retailers: " + retailers.length + " retailersLoyalty: " + Object.entries(retailersLoyalty));
      }
      /*
        Set primary entity to true as default value
      */
      ValidationService.customerDataLookup.set(EnvConfigService.getConfig().primaryEntity, true);
      for (let i = 0; i < retailers.length; i++) {
        ValidationService.customerDataLookup.set(retailers[i], retailersLoyalty[i]);
      }
    });
  }

}

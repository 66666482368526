export enum StatResponseErrors {
  BIG_QUERY_RESOURCE_EXCEEDED = <any>{
    errorCode: 'E11',
    description: 'Resources exceed on Job!  Please limit.',
    blocker: true
  },
  GENERAL_APP_ACTIVITY_ERROR = <any>{
    errorCode: 'E12',
    description: 'App activity failed, please try again.',
    blocker: true
  },
  RB_RESPONSE_SIZE_EXCEEDED = <any>{
    errorCode: 'E14',
    description: 'Response Size Exceeded exceed on RB!',
    blocker: true
  },
  ACTIVITY_NOT_FOUND = <any>{
    errorCode: 'E15',
    description: 'AppActivity Not Found !!(sheet or columns?)',
    blocker: true
  },
  SHEET_TIME_OUT = <any>{
    errorCode: 'E16',
    description: 'Report builder sheet too big to preview in time limit',
    blocker: true
  },
}

import { Component } from '@angular/core';
import { AgRendererComponent, ICellRendererParams } from '@siq-js/visual-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { DeleteModalComponent } from 'app/filtersets/components/delete-modal/delete-modal.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'siq-js-delete-renderer',
  templateUrl: './delete-renderer.component.html',
  styleUrls: ['./delete-renderer.component.scss']
})
export class DeleteRendererComponent extends BaseSiqComponent implements AgRendererComponent {

  private data: any;
  private MatDialogRef: MatDialogRef<DeleteModalComponent>;

  constructor(
    private utils: UtilsService
  ) {
    super();
  }

  agInit(iCellRendererParams: ICellRendererParams) {
    this.data = iCellRendererParams.data;
  }

  public delete() {
    this.MatDialogRef = this.utils.openModal(DeleteModalComponent, this.data, UtilsService.MODAL_CONFIG_SMALL);
  }

  refresh(): boolean {
    return true;
  }

}

import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

export interface QueryModeModalConfig {
  component: ComponentType<any>;
  diffFn?: (schema: string) => boolean;
  dataGetter: () => any;
}

export interface QueryModeModalData {
  data: any;
  schema: string;
  userGroupChanged?: boolean; // additional functionality added for ICE-104
}

export abstract class QueryModeModalComponent extends BaseSiqComponent {

  protected constructor(
    protected dialogRef: MatDialogRef<QueryModeModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected modalData?: QueryModeModalData
  ) {
    super();
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}

import { AfterViewInit, Component, forwardRef, OnInit, QueryList, ViewChildren } from '@angular/core';

import {
  AgCartesianChartOptions,
  AgChartOptions,
  BarColumnApi,
  BarColumnConfig,
  BarColumnParams,
  BarColumnType,
  BarColumnTypeDisplay,
  BaseChart,
  BaseVisualizationComponent,
  ChartSettings,
  ChartsService,
  _ModuleSupport,
  HighlightNodeDatum
} from 'libs/visual-lib/src';

import * as _ from 'lodash';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { AgChartsAngular } from 'ag-charts-angular';
import { ThemesService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-js-bar-column',
  templateUrl: './bar-column.component.html',
  styleUrls: ['./bar-column.component.scss']
})
export class BarColumnComponent extends BaseVisualizationComponent<any, any, BarColumnConfig, BarColumnApi> implements BaseChart, OnInit, AfterViewInit {
  @ViewChildren(forwardRef(() => AgChartsAngular)) public angularCharts: QueryList<AgChartsAngular>;
  public static DimensionLabelRotation = -45;

  public static generateDefaultChartOptions(component: BarColumnComponent): AgChartOptions {
    const typeSafe : AgCartesianChartOptions = {
      autoSize: true,
      theme: ChartsService.getThemePdi(),
      // data: [],
      series: [], //there could be pushed data later to empty initialized series array
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            formatter: (params) => {
              return params.value;
            },
            rotation: BarColumnComponent.DimensionLabelRotation
          },
          title: {
            enabled: true,
            text: 'Dimension Axis Title'
          }
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: (params) => {
              return params.value;
            }
          },
          title: {
            enabled: true,
            text: 'Metric Axis Title'
          }
        },
      ],
      legend: { position: 'bottom' }
    };
    return <AgChartOptions>typeSafe;
  }

  public angularChart: AgChartsAngular;
  public agChartOptions: AgChartOptions;
  public barColumnType = BarColumnType;
  public barColumnTypeDisplay = BarColumnTypeDisplay;
  public chart: any; // Type determined at runtime
  public chartReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private originalToggleTooltip;
  private newToggleTooltip;

  constructor() {
    super();
  }

  getApi(): BarColumnApi {
    return {
      component: this,
      chartOptions: this.agChartOptions
    };
  }

  highlightDatum(seriesNodeDatum: _ModuleSupport.SeriesNodeDatum) {
    let matchDatum: HighlightNodeDatum;

    let matchSeries = _.find(this.chart.series, {type: 'column'}); //AgColumnSeriesOptions
    if (!matchSeries) {
      matchSeries = _.find(this.chart.series, {type: 'bar'}); //AgBarSeriesOptions
    }

    if (matchSeries) {
      matchDatum = _.find(matchSeries['_contextNodeData'][0].nodeData, seriesNodeDatum);
    }

    if (matchDatum) {
      this.chart.highlightManager.updateHighlight(undefined,matchDatum);
    }
  }

  ngAfterViewInit(): void {
    // This gets a reference to the actual chart
    this.angularChart = this.angularCharts.first;
    this.chart = this.angularChart.chart['chart']; // NOTE: "short cut / pointer" for shorter code elsewhere in project; This is a CartesianChart but we cannot import that type in latest ag-grid/ag-charts

    //TODO: Ag Charts upgrade -> Investigate if charts works without it
    // this.setToggleTooltipFn();

    this.chartReady$.next(true);
    (<BarColumnParams>this.data).chart = this; // set pointer to this chart instance to be used by processor/other classes

    if ((<BarColumnParams>this.data).highlightDatum$) {
      (<BarColumnParams>this.data).highlightDatum$.pipe(
        takeUntil(this.unsub$)
      )
      .subscribe((datum: any) => {
        this.chart.highlightManager.updateHighlight(undefined);
        if (!datum) {
          return;
        }

        //transforms object's internal structure from key: {'val': value} to key: value
        Object.keys(datum).forEach((key) => { if (datum[key]?.val) datum[key] = datum[key].val; });
        const highlightedDatum = _.find(<_ModuleSupport.SeriesNodeDatum>this.chart.series[0]['_contextNodeData'][0].nodeData, {datum:datum});

        if (highlightedDatum) {
          this.highlightDatum(highlightedDatum);
        }
      });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      const updatedChartOptions = <AgChartOptions>{...this.agChartOptions};
      updatedChartOptions.theme = ChartsService.getChartThemeObject(theme);
      updatedChartOptions.background = ChartsService.getChartBackgroundForTheme();
      this.agChartOptions = updatedChartOptions;
    });
  }

  protected render(chartSettings: ChartSettings): void {
    // Override config object with custom options
    this.agChartOptions = _.merge(BarColumnComponent.generateDefaultChartOptions(this), this.config.agChartOptions);

    if (chartSettings.selectedMetricsChangeCallback) {
      (<BarColumnParams>this.data).selectedMetrics$.pipe(
        takeUntil(this.unsub$)
      )
      .subscribe(selectedMetrics => {
        const newChartOptions = chartSettings.selectedMetricsChangeCallback(<BarColumnParams>this.data, this.agChartOptions);
        this.agChartOptions = newChartOptions;
      });
    }
  }

  private setToggleTooltipFn(): void {
    /**
     * A little hacky, but...
     * Ag-Grid exposes a tooltipRenderer fn that gets triggered on mouseover of chart shape.
     * However there is nothing exposed in similar manner for when the tooltip is removed/mouseout.
     * This grabs the original "toggleTooltip" fn (in the ag-grid "Chart" object/class), calls it from
     * a new fn with additional logic, and attaches this new fn in place of the original.
     */
    const _data = (<BarColumnParams>this.data);
    this.originalToggleTooltip = this.chart.tooltip;
    this.newToggleTooltip = (visible) => {
      this.originalToggleTooltip.apply(this.chart, [visible]);
      if (!visible) {
        _data.highlightDatum$.next(null);
      }
    };
    this.chart.tooltip= this.newToggleTooltip;
  }
}

<mat-grid-list cols="3" gutterSize="30px" rowHeight="260px" *ngIf="applications">

  <mat-grid-tile *ngFor="let app of applications" [routerLink]="'/' + app?.path">

    <mat-card>
      <mat-card-header [ngStyle]="{'background-color': app.color}">
        <mat-icon svgIcon="{{app.id}}"></mat-icon>
      </mat-card-header>

      <mat-card-content>
        <div class="description">
          <span class="app-name">{{ app.display }}</span>
          <p>{{ app.description }}</p>
        </div>
      </mat-card-content>
    </mat-card>

  </mat-grid-tile>

</mat-grid-list>

import { guid, Serializable } from '@siq-js/core-lib';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { ComparisonGroupParam } from 'app/siq-applications/modules/promo/models/interfaces';

export interface ComparisonGroupJson {
  filters: FilterSelectionJson[];
  id: string;
  isValid: boolean;
  name: string;
}
export class ComparisonGroup implements Serializable<ComparisonGroupJson> {
  public filters: FilterSelection[];
  public id: string;
  public isValid: boolean;
  public name: string;
  constructor(json?: ComparisonGroupJson) {
    this.name = json?.name || '';
    if (json?.filters?.length) {
      this.filters = json.filters.map(f => new FilterSelection(f));
    } else {
      this.filters = [];
    }
    this.id = json?.id || guid();

    this.isValid = json?.isValid || false;
  }

  public toJson(): ComparisonGroupJson {
    return {
      filters: this.filters.map(f => f.toJson()),
      id: this.id,
      isValid: this.isValid,
      name: this.name
    };
  }

  public toParam(): ComparisonGroupParam {
    return {
      id: this.id,
      name: this.name,
      filters: this.filters.map(f => UtilsService.paramify(f)),
    };
  }
}

import {
  AgBarSeriesOptions,
  AgCartesianChartOptions,
  AgChartOptions,
  AgTooltipRendererResult,
  BarColumnComponent,
  BarColumnConfig,
  BarColumnParams,
  BarColumnProcessor,
  ChartSettings,
  GridService,
  VISUAL_CONFIG,
  AgCartesianSeriesTooltipRendererParams
} from '@siq-js/visual-lib';
import { CoreConstants, getCmsMetricDataValue, isLessThan } from '@siq-js/core-lib';
import { CmsConfig, CmsField, CmsMetric } from '@siq-js/cms-lib';
import * as _ from 'lodash';
import { filter, takeUntil } from 'rxjs';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export class IncrementalsBarColumnProcessor extends BarColumnProcessor {

  public static MAX_COLUMNS = 20;
  private static PDI_LIGHT_BLUE = THEME_COLORS.SKY;
  private static PDI_DARK_BLUE = THEME_COLORS.SUNSHINE;

  public static generateBarColumnConfig(params: BarColumnParams): BarColumnConfig {
    const selectedDim = params.selectedDimension$.getValue();
    const _data = IncrementalsBarColumnProcessor.getDataSubset(params);

    let config: BarColumnConfig = {
      agChartOptions: BarColumnComponent.generateDefaultChartOptions(<BarColumnComponent>params.parent)
    };

    config.agChartOptions.padding = {
      top: 0,
      right: 0,
      bottom: 1,
      left: 0
    };
    config.agChartOptions.legend.enabled = false;

    let axes = (config.agChartOptions as AgCartesianChartOptions).axes;

    let axisCategory = _.find(axes, {type: 'category'});
    let axisMetric = _.find(axes, {type: 'number'});
    let axisUpdates = {
      label: {
        formatter: (params) => { // This formats the x-axis label
          // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
          return '';
        }
      },
      title: {
        enabled: false
      },
      gridLine: {
        style: [
          {
            stroke: 'rgba(0, 0, 0, 0)' // transparent; no show
          }
        ],
      },
      tick: {
        width: 0
      }
    };
    _.merge(axisCategory, axisUpdates);
    _.merge(axisMetric, axisUpdates);

    const yKeys = [];
    const yNames = [];

    params.metrics$.pipe(
      takeUntil(params.parent.unsub$),
      filter(metrics => metrics !== null)
    )
    .subscribe(metrics => {
      metrics.forEach(cmsMetric => {
        yKeys.push(cmsMetric.id);
        yNames.push(cmsMetric.display);
      });
    });

    yKeys.forEach((_key, idx) => {
      (<AgCartesianChartOptions>config.agChartOptions).series.push(
        <AgBarSeriesOptions>{
          type: 'bar',
          grouped: true,
          xKey: selectedDim.id,
          xName: selectedDim.display,
          yKey: _key,
          yName: yNames[idx],
          visible: true,
          fill: IncrementalsBarColumnProcessor.PDI_LIGHT_BLUE,
          fillOpacity: 0.5,
          strokeWidth: 0,
          highlightStyle: {
            item: {
              fill: IncrementalsBarColumnProcessor.PDI_DARK_BLUE,
              fillOpacity: 1,
              strokeWidth: 0,
            }
          },
          tooltip: {renderer: (tooltipParams: AgCartesianSeriesTooltipRendererParams) => IncrementalsBarColumnProcessor.customTooltipRenderer(tooltipParams, params) }
        }
      );
    });

    return config;
  }

  public static processor = (params: BarColumnParams): ChartSettings => {
    params.datasetChanged$.pipe(
      takeUntil(params.parent.unsub$)
    )
    .subscribe(() => {
      if (_.isEmpty(params.metrics$.value)) return;
      IncrementalsBarColumnProcessor.updateEverything(params);
    });

    return <ChartSettings>{
      agChartOptions: params.agChartOptions,
      parentActivity: params.parentActivity,
      selectedDimension$: params.selectedDimension$,
      selectedMetrics$: params.selectedMetrics$,
      selectedMetricsChangeCallback: IncrementalsBarColumnProcessor.selectedMetricsChangeCallback
    };
  }

  public static selectedMetricsChangeCallback(params: BarColumnParams, chartOptions: AgChartOptions): AgChartOptions {
    const newChartOptions = _.cloneDeep(chartOptions);
    newChartOptions.data = IncrementalsBarColumnProcessor.getDataSubset(params);

    // For this particular Column chart there will only ever be ONE metric present in selectedMetrics$, so using built-in .forEach() to iterate over one
    params.selectedMetrics$.value.forEach(cmsMetric => {
      (<AgBarSeriesOptions>newChartOptions.series[0]).yKey = cmsMetric.id;
      (<AgBarSeriesOptions>newChartOptions.series[0]).yName = cmsMetric.display;
    });

    return newChartOptions;
  }

  private static customTooltipRenderer(tooltipParams: AgCartesianSeriesTooltipRendererParams, barColumnParams: BarColumnParams): AgTooltipRendererResult {
    const dim: CmsField = (CoreConstants.cmsConfig as CmsConfig).findEntity<CmsField>(tooltipParams.xKey);
    const dimFormatter = GridService.getVisualType(dim).formatter;
    const metricFormatter = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: barColumnParams.selectedMetrics$.value[0].type})?.formatter;

    barColumnParams.highlightDatum$.next(tooltipParams.datum);

    return <AgTooltipRendererResult>{
      content: dimFormatter({value: tooltipParams.datum[tooltipParams.xKey]}).toString() + ': ' + metricFormatter({value: tooltipParams.datum[tooltipParams.yKey]}).toString()
    };
  }

  private static getDataSubset(params: BarColumnParams): any[] {
    if (_.isEmpty(params.selectedMetrics$.value) || _.isEmpty(params.selectedDimension$.value)) {
      // return empty array due to metrics and/or dims being null
      return [];
    }

    return IncrementalsBarColumnProcessor.processData(
      params.rawData$.value,
      params,
      params.selectedMetrics$.value[0]
    );
  }

  private static processData(data: any[], params: BarColumnParams, selectedMetric: CmsMetric): any[] {
    return data.map((value: any) => {
      const dimId = params.selectedDimension$.value.id;
      value[dimId] = value[dimId].toString();

      const dimKeys = [dimId];
      Object.keys(value).filter(key => !dimKeys.includes(key)).forEach(metricKey => {
        value[metricKey] = getCmsMetricDataValue(value, metricKey);
      });

      return value;
    })
    .sort((a: any, b: any) => isLessThan(a[selectedMetric.id], b[selectedMetric.id]))
    .reverse() // Makes sorting go from highest to lowest; less overlap in scatterplot
    .slice(0, IncrementalsBarColumnProcessor.MAX_COLUMNS);
  }

  private static updateEverything(params: BarColumnParams) {
    let newChartOptions: AgChartOptions = _.cloneDeep(<AgCartesianChartOptions>params.chart.getApi().chartOptions);

    /**
     * This update is taking place *BEFORE* the parent component sets the new value of params.selectedMetric$
     * to avoid circular updates. However, the new value has been set for the full list of metrics, so we can
     * use the first item in params.parent.activeDatasetMetrics$.value
     */
    const newSelectedMetric = params.selectedMetrics$.value[0];

    // Process the data
    const data = IncrementalsBarColumnProcessor.processData(
      params.rawData$.value,
      params,
      newSelectedMetric
    );
    newChartOptions.data = data.length ? data : [{}];

    let matchSeries = <AgBarSeriesOptions[]>newChartOptions.series;
    matchSeries[0].yKey = newSelectedMetric.id;
    matchSeries[0].yName = newSelectedMetric.display;

    if (params.chart) {
      params.chart.agChartOptions = newChartOptions;
    }
  }
}

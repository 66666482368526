import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { DateUnit } from '@siq-js/core-lib';

export enum StartsMode {
  DEFAULT = 'when_data_is_ready',
  START = 'time'
}

@Component({
  selector: 'siq-schedule-time',
  templateUrl: './schedule-time.component.html',
  styleUrls: ['./schedule-time.component.scss']
})
export class ScheduleTimeComponent implements OnInit {

  @Input() formControlStart: UntypedFormControl;
  public startsMode = StartsMode;
  public start = StartsMode.START;
  public format = DatesService.format;
  public setHours = DatesService.setValue(DateUnit.HOUR);
  public setMinutes = DatesService.setValue(DateUnit.MINUTE);
  public times = (Array.from(Array(24).keys())).map(n => {

    return this.format(this.setMinutes(this.setHours(new Date(), n), 0), 'hh:mm a');

  });
  public time = this.times[7];

  ngOnInit() {
    if (!_.isNil(this.formControlStart.value)) {
      this.start = StartsMode.START;
      this.time = this.time24to12(this.formControlStart.value);
    }
    this.updateForm();
  }

  updateForm() {
    this.formControlStart.setValue(this.start === StartsMode.START ? this.time : null);
  }

  time24to12(time: string) {
    return this.format(this.setMinutes(this.setHours(new Date(), Number(time.split(':')[0])), 0), 'hh:mm a');
  }

}

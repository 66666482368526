import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SiqInternalRoutes } from 'app/siq-internal/routes/siq-internal-routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(SiqInternalRoutes)
  ],
  exports: [ RouterModule ]
})

export class SiqInternalRoutingModule { }

import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent, ColDef, ICellRendererParams } from '@siq-js/visual-lib';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { Subject } from 'rxjs';
import { ActivityService } from 'app/activity/services/activity.service';
import { takeUntil } from 'rxjs';

class Status {
  classname: string;
  color: string;
  text: string;
  icon: string;
  constructor (classname, color, text, icon) {
    this.classname = 'report-status ' + classname;
    this.color = color;
    this.text = text;
    this.icon = icon;
  }
}

@Component({
  selector: 'siq-status-renderer',
  templateUrl: './status-renderer.component.html',
  styleUrls: ['./status-renderer.component.scss']
})
export class StatusRendererComponent implements AgRendererComponent, OnDestroy {

  public static readonly ColDef: ColDef = {
    headerName: 'Status',
    sortable: false,
    cellRenderer: StatusRendererComponent,
    width: 50,
    resizable: false,
    pinned: 'left',
    cellStyle: {'text-align': 'center'}
  };

  public static StatusIconMap: {[status in ActivityStatus]?: Status} = {
    [ActivityStatus.RUNNING]: new Status('status-running', 'deepskyblue', 'Running', 'hourglass_empty'),
    [ActivityStatus.ERROR]: new Status('status-error', 'red', 'Failure', 'close'),
    [ActivityStatus.READY]: new Status('status-ready', 'green', 'Success', 'check'),
    [ActivityStatus.EMPTY]: new Status('status-empty', 'grey', 'Report Empty', 'edit'), // Report Builder is empty
    [ActivityStatus.EXPIRED]: new Status('status-expired', 'blue', 'Expired', 'refresh'),
    [ActivityStatus.ALERT]: new Status('status-alert', 'orange', 'Error', 'priority_high')
  };

  public iconStatus: Status;
  public activityStatus: ActivityStatus;
  public rerunning: boolean;
  private _unsub$ = new Subject<void>();
  private id: string;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    const activity = params.data as Activity;
    this.id = activity.getId();
    this.activityStatus = activity.getStatus();
    this.iconStatus = StatusRendererComponent.StatusIconMap[this.activityStatus];

    ActivityService.RerunningActivityIds
      .pipe(
        takeUntil(this._unsub$)
      )
      .subscribe(ids => this.checkRerunning(ids));
  }

  refresh(params: ICellRendererParams): boolean {
    const a: Activity = params.data;
    const newVal = a.getStatus();
    const isChanged = newVal !== this.activityStatus;
    if (isChanged) {
      this.iconStatus = StatusRendererComponent.StatusIconMap[newVal];
    }
    return isChanged;
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  private checkRerunning(ids: Set<string>) {
    this.rerunning = ids.has(this.id);

    if (this.rerunning) {
      this.iconStatus = StatusRendererComponent.StatusIconMap[ActivityStatus.RUNNING];
    }
  }
}

export enum ApplicationHash {
  AFFINITIES = 'e317e2e3-afbd-465a-a2ad-afcc6e8251cb',
  DAYPARTS = '7cce1d0a-f035-4144-b3c7-d65b2b024a86',
  INCREMENTALS = '29cf3f8d-56c4-4a4e-9c54-14e592c9f22b',
  REPORT_BUILDER = '04851e56-b99b-11e7-abc4-cec278b6b50a',
  PROMO = 'b1af4ed0-bfbe-4d33-89cd-d4a88cc73494',
  REPORT_BUILDER_SHEET = 'ac27f6ce-b823-11e8-96f8-529269fb1459',
  PROMO_TAKERATE_SHEET = '4e0e624d-5f11-486d-b584-554927ac4033',
  PROMO_SOV_SHEET = '5a3cf2ad-0259-4d67-99f3-e5cd1fa0dcbc',
  ALL = 'all-reports' // FE usage only
}

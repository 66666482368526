import * as _ from 'lodash';
import { YearOverYearEnum } from 'libs/visual-lib/src/lib/modules/grid/models/year-over-year.enum';
import { num } from '@siq-js/core-lib';

export const EXCEL_EMPTY_CELL = 'EMPTY_CELL';

const calWeightedAvg = (arrWeightedAvg: { val: number, weight: number }[]) => {
  const [rawTotal, weightsTotal] = arrWeightedAvg.reduce((ret, v) => {
    if (!_.isNil(v)) {
      const temp_val = v.val || 0;
      const temp_weight = v.weight || 0;
      const temp_weight_multiplier = v.weight || 1;
      ret = [ret[0] + temp_val * temp_weight_multiplier, ret[1] + temp_weight];
    }
    return ret;
  }, [0, 0]);

  // Prevent division by zero resulting in NaN
  const newVal = rawTotal / (!!weightsTotal ? weightsTotal : 1);
  return newVal ? { val: newVal, weight: weightsTotal} : null;
};

export const GRID_DEFAULTS = {
  AGG_MAP: {
    'LS': 'sum',
    'WE': 'weightedAvg',
    'PS': 'percentShare',
    'PD': 'percentDelta', // SIQV3-2062: When this was changed to 'noAgg', then the Sigma showed as expected, but after retrieving async-results, no data was put into the cell; just showed Sigma still
    'AS': 'async',
    'NA': 'noAgg'
  },
  CUSTOM_AGG_FUNCTIONS: {
    noAgg: (params: {values: any[]}) => {
      if (params.values && params.values.length === 1) {
        return ({val: params.values[0]});
      }
    },
    // weightedAvg: calWeightedAvg, // remove next AG Grid update - current 27.2
    weightedAvg: (params: {values: any[]}) => {
      const arrWeightedAvg = params.values.map(v => {
        return {
          val: v[0],
          weight: v[1]
        };
      });
      return calWeightedAvg(arrWeightedAvg);
    },
    async: (params: {values: any[]}) => {
      return ((params.values.length === 1) ? params.values[0] : null);
    },
    sum: (params: {values: any[]}) => {
      const sum = _.sumBy(params.values, (x) => {
        return _.isObject(x) ? _.get(x, 'val', 0) : x;
      });
      return {
        val: sum,
        toString: () => {
          // The toString() fn provides just the raw value (not an object) needed by ag-grid charts (this[val])
          return sum;
        }
      };
    },
    percentDelta: (params: {values: any[]}) => {
      const ly: number = _.sumBy(params.values, (v) => v[YearOverYearEnum.LAST_YEAR] || 0);
      const ty: number = _.sumBy(params.values, (v) => v[YearOverYearEnum.THIS_YEAR] || 0);

      let out: any = {
        ly: ly,
        ty: ty,
        val: !!ly ? ((ty - ly) / ly) : null, // This prevents Divide-by-zero error
        toString: () => {
          // The toString() fn provides just the raw value (not an object) needed by ag-grid charts
          return !!ly ? ((ty - ly) / ly) : 1; // This prevents Divide-by-zero error in Excel and charts if ly is zero
        }
      };

      const weightedAvgLYdata = calWeightedAvg(params.values.map(row =>({val: row['ly'], weight: row['lyw']})));
      const weightedAvgTYdata = calWeightedAvg(params.values.map(row =>({val: row['ty'], weight: row['tyw']})));

      if (weightedAvgLYdata && weightedAvgTYdata) {
        const [newly, newlyw] = weightedAvgLYdata ? [weightedAvgLYdata.val, weightedAvgLYdata.weight] : [null, null];
        const [newty, newtyw] = weightedAvgTYdata ? [weightedAvgTYdata.val, weightedAvgTYdata.weight] : [null, null];

        out = {
          ly: newly,
          ty: newty,
          lyw: newlyw,
          tyw: newtyw,
          val: (newly === null || newty === null) ? null : ((newty - newly) / newly),
          toString: () => {
            // The toString() fn provides just the raw value (not an object) needed by ag-grid charts
            return (newly === null || newty === null) ? null : num((((newty - newly) / newly) * 100), 1);
          }
        };
      }

      return out;
    },
    // percentShare: (arr: { val: number, share: number}[], global: boolean) => {
    //   const total = _.sumBy(arr, v => _.get(v, 'val') || 0);
    //   arr.forEach(v => {
    //     if (v) {
    //       v.share = v.val / total;
    //     }
    //   });
    //   return {
    //     val: total,
    //     share: global ? 1 : total // Default to 100% for highest-level calc
    //   };
    // },
    percentShare: (params: {values: { val: number, share: number}[]}) => {
      /**
       * TODO: Custom aggregation functions in ag-grid now must adhere to the interface IAggFunc:
       * interface IAggFunc {
       *   (input: any[]): any;
       * }
       *
       * The extra param for "global" breaks this interface so we will have to find a new approach.
       * This seems to only be used by PROMO as of now (based on searching for "percentShare" in apps/dashboard path
       */
      const global = false; // TODO: This is temporary (see above commented out function and comment)
      const total = _.sumBy(params.values, v => _.get(v, 'val') || 0);
      params.values.forEach(v => {
        if (v) {
          v.share = v.val / total;
        }
      });
      return {
        val: total,
        share: global ? 1 : total // Default to 100% for highest-level calc
      };
    }
  },
  // FONT_FAMILY: window.getComputedStyle(document.documentElement).getPropertyValue('--font-family').trim(), // css3 variable from :root{} section in variables.scss
  FONT_FAMILY: '"Roboto Mono", "Helvetica Neue", sans-serif',
  DEFAULT_TABLE_HEIGHT: 750,
  TABLE_HEADER_HEIGHT: 36,
  TABLE_ROW_HEIGHT: 24
};

import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { NonStatResponse } from 'app/core/models/non-stat-response.model';
import { SiqError } from 'app/core/models/siq-error.model';
import { StatResponse } from 'app/core/models/stat-response.model';

export class AppResponseDataset extends AppDataset {

  static fromJson<T>(j: any, parseKeys?: string[]): AppResponseDataset {
    return <AppResponseDataset><any> super.typedObjectFromJson(j, AppResponseDataset, parseKeys);
  }

  constructor(
    private cached = false,
    private complete = false,
    private errors = false,
    private errorsDetails: SiqError[] = [],
    private response: (StatResponse | NonStatResponse) = new StatResponse(),
    private metaData = new Map<string, string>()
  ) {
    super();
  }

  public isCached(): boolean {
    return this.cached;
  }

  public setCached(cached: boolean): void {
    this.cached = cached;
  }

  public isComplete(): boolean {
    return this.complete;
  }

  public setComplete(complete: boolean): void {
    this.complete = complete;
  }

  public hasErrors(): boolean {
    return this.errors;
  }

  public setErrors(errors: boolean): void {
    this.errors = errors;
  }

  public getErrorsDetails(): SiqError[] {
    return this.errorsDetails;
  }

  public setErrorsDetails(errorsDetails: SiqError[]): void {
    this.errorsDetails = errorsDetails;
  }

  public getResponse<T = StatResponse>(): T {
    return (<any>this.response) as T;
  }

  public setResponse(response: StatResponse | NonStatResponse): void {
    this.response = response;
  }

  public getMetaData(): Map<string, string> {
    return this.metaData;
  }

  public setMetaData(metaData: Map<string, string>): void {
    this.metaData = metaData;
  }

  public getMetaDataByKey(key: string): string {
    return this.metaData[key] || '';
  }
}

import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DatePartsFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/date-parts-filter/date-parts-filter.component';
import { DatePart } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-part.enum';

@Component({
  selector: 'siq-day-of-week-filter',
  templateUrl: './day-of-week-filter.component.html',
  styleUrls: ['./day-of-week-filter.component.scss', '../../styles/custom-filters.scss']
})
export class DayOfWeekFilterComponent extends DatePartsFilterComponent {
  public weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  @ViewChild('dayOfWeek', { read: ViewContainerRef, static: true }) private _input;

  constructor() {
    super();
    this.setDatePart(DatePart.DAY_OF_WEEK);
  }

  // Focuses on the month element upon initialization of the filter controller
  // AfterViewInit interface implemented by inherited class
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => {
      this._input && this._input.element.nativeElement.focus();
    });
  }
}

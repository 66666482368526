import { CmsMetric } from '@siq-js/cms-lib';
import * as _ from 'lodash';
import { FormColumn } from 'app/siq-applications/modules/report-builder/models/form/form-column.model';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { TimeAggregate } from 'app/siq-applications/modules/report-builder/models/time-aggregate.model';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { ReportBuilderColumnType } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

export class MetricColumn extends FormColumn {

  public ref: CmsMetric;
  public type = ReportBuilderColumnType.METRIC;

  public filters: FilterSelection[];

  public timeAggregates: {
    aggValue: TimeAggregate,
    numUnits: number
  } = null;

  public yearOverYear: {
    compDateRange: DateRangeInterface, // comparative date range
    ty: boolean, // this year values
    ly: boolean, // last year values
    lyd: boolean, // last year delta
    lypd: boolean, // last year percent delta
  } = null;

  constructor(parent: ReportBuilderFormData, fact: CmsMetric) {
    super(parent);

    this.ref = fact;
    this.filters = [];
  }

  public size(): number {
    if (this.timeAggregates) {
      return this.timeAggregates.numUnits;
    }

    if (this.yearOverYear) {
      const yoy = this.yearOverYear;
      return Number(!!yoy.ty) + Number(!!yoy.ly) + Number(!!yoy.lyd) + Number(!!yoy.lypd);
    }

    return 1;
  }

  public toJson(): any {
    const json: any = {
      type: this.type,
      ref: this.ref.id,
      columnGroupName: this.columnGroupName,
    };

    if (this.yearOverYear) {
      json.yearOverYear = {
        ty: this.yearOverYear.ty,
        ly: this.yearOverYear.ly,
        lyd: this.yearOverYear.lyd,
        lypd: this.yearOverYear.lypd,
        compDateRange: DatesService.isDynamic(this.yearOverYear.compDateRange)
          ?
          { // dynamic dates
            begin: DatesService.dateMStoS(this.yearOverYear.compDateRange.begin.getTime()), // translated dates in begin & end are needed to display
            end: DatesService.dateMStoS(this.yearOverYear.compDateRange.end.getTime()),
            dynamicBegin: this.yearOverYear.compDateRange.dynamicBegin,
            dynamicEnd: this.yearOverYear.compDateRange.dynamicEnd
          }
          :
          {
            begin: DatesService.dateMStoS(this.yearOverYear.compDateRange.begin.getTime()),
            end: DatesService.dateMStoS(this.yearOverYear.compDateRange.end.getTime())
          }
      };
    }

    if (this.timeAggregates) {
      json.timeAggregates = this.timeAggregates.aggValue.key;
    }

    json.filters = this.filters;

    return json;
  }

  public applyTimeAggregate(timeAgg: TimeAggregate) {
    this.columnGroupName = `${this.ref.display} by ${timeAgg.name}`;
    const dates = this.parent.globalDateRange;
    this.yearOverYear = null;
    this.timeAggregates = {
      aggValue: timeAgg,
      numUnits: timeAgg.calcFn(dates.begin, dates.end)
    };
  }

  public applyYearOverYear(m: any) {
    if (!m.ty && !m.ly && !m.lyd && !m.lypd) {
      this.yearOverYear = null;
    } else {
      if (this.columnGroupName === '' || !!this.timeAggregates) {
        //when column group name is empty or set by applyTimeAggregate, set it to default
        this.columnGroupName = this.ref.display;
      }
      this.timeAggregates = null;

      this.yearOverYear = {
        ty: m.ty,
        ly: m.ly,
        lyd: m.lyd,
        lypd: m.lypd,
        compDateRange: {
          begin: m.compDateRange.begin,
          end: m.compDateRange.end,
          dynamicBegin: m.compDateRange.dynamicBegin,
          dynamicEnd: m.compDateRange.dynamicEnd
        }
      };
    }
  }

  public clone(): MetricColumn {
    const newCol = new MetricColumn(this.parent, this.ref);

    newCol.columnGroupName = this.columnGroupName;

    const yoy = this.yearOverYear;
    if (yoy) {
      newCol.yearOverYear = {
        ty: yoy.ty,
        ly: yoy.ly,
        lyd: yoy.lyd,
        lypd: yoy.lypd,
        compDateRange: DatesService.isDynamic(yoy.compDateRange)
          ?
          { // dynamic dates
            begin: yoy.compDateRange.begin,
            end: yoy.compDateRange.end,
            dynamicBegin: yoy.compDateRange.dynamicBegin,
            dynamicEnd: yoy.compDateRange.dynamicEnd
          }
          :
          {
            begin: yoy.compDateRange.begin,
            end: yoy.compDateRange.end
          }
      };
    }

    const ta = this.timeAggregates;
    if (ta) {
      newCol.timeAggregates = {
        aggValue: _.cloneDeep(this.timeAggregates.aggValue),
        numUnits: this.timeAggregates.numUnits
      };
    }

    newCol.filters = _.cloneDeep(this.filters.slice());

    return newCol;
  }
}

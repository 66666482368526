import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'app/core/services/utils/utils.service';

@Pipe({
  name: 'retailerDisplay'
})
export class RetailerDisplayPipe implements PipeTransform {

  transform(retailer: string): string {
    return UtilsService.getRetailerDisplay(retailer);
  }

}

import { CmsService } from 'app/core/services/cms/cms.service';
import * as _ from 'lodash';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { AppService } from 'app/app.service';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { IdleService } from 'app/core/services/idle/idle.service';
import { NavService } from 'app/core/services/nav/nav.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { UserService } from 'app/user/services/user.service';
import { ActivityService } from 'app/activity/services/activity.service';
import { AuthService } from 'app/auth/services/auth.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { User } from 'app/user/models/user.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import {
  AgGridLicenseKey,
  LicenseManager,
  ProgressIndicatorColorType,
  ProgressIndicatorType
} from '@siq-js/visual-lib';
import { CoreConstants } from '@siq-js/core-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import { PollingService } from 'app/core/services/polling/polling.service';

@Component({
  selector: 'siq-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseSiqComponent implements OnInit {

  public static readonly resize$: Subject<void> = new Subject<void>();
  public static readonly globalClick$: Subject<MouseEvent> = new Subject<MouseEvent>();
  public static visible = {
    primary: false
  };

  public curYear: number = (new Date()).getFullYear();
  public isLoggedIn: boolean;
  public isLoading: boolean = false;
  public progressIndicatorType: ProgressIndicatorType = ProgressIndicatorType.INDETERMINATE;
  public progressIndicatorColorType: ProgressIndicatorColorType = ProgressIndicatorColorType.ACCENT;

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  constructor (
    vcr: ViewContainerRef,
    cmsConfigService: CmsService,
    pollingService: PollingService,
    envConfigService: EnvConfigService,
    applicationService: ApplicationService,
    mixpanelService: MixpanelService,
    navService: NavService,
    userService: UserService,
    private renderer: Renderer2,
    private idleService: IdleService,
    private toastrService: ToastrService,
    elementRef: ElementRef
  ) {
    super();
    const envFromBuild = elementRef.nativeElement.getAttribute('data-project-id');

    if (!_.isNil(envFromBuild)) {
      AppService.updateEnvironmentSettings(envFromBuild);
    }

    // Set the CoreConstants.environment.dashboard to be the same as AppSiqConstants.environment
    CoreConstants.environment.dashboard = AppSiqConstants.environment;

    AuthService.CurrentUser$.subscribe((user: User) => {
      AppComponent.visible.primary = user && user.isValid();
      this.isLoggedIn = AppComponent.visible.primary;
    });

    fromEvent(window, 'resize').pipe(
      debounceTime(500)
    )
    .subscribe(window => {
      AppComponent.resize$.next();
    });

  }

  ngOnInit() {

    this.toastrService.overlayContainer = this.toastContainer;

    this.idleService.setup(this.renderer);

    // June 2019 ag-grid license key
    LicenseManager.setLicenseKey(AgGridLicenseKey);

    // Subscribe to set the top progress bar
    EmitterService.get('topLoading')
    .pipe(
      takeUntil(this.unsub$)
    ).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public sidenavOpenedChange(event) {
    setTimeout(() => {
      AppComponent.resize$.next();
    }, 500);
  }

  public onGlobalClick($event) {
    AppComponent.globalClick$.next($event);
  }
} 

import * as _ from 'lodash';
import { Activity } from 'app/activity/models/activity.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownMenuItem } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.interface';
import { ProgressIndicatorColorType } from '@siq-js/visual-lib';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'siq-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  styleUrls: ['./dropdown-menu-item.component.scss']
})
export class DropdownMenuItemComponent implements OnInit {

  private static readonly defaults = {
    notifyColor: ProgressIndicatorColorType.ACCENT
  };

  @Input() public item: DropdownMenuItem<Activity|void>;
  @Output() public onSubmenuOpened = new EventEmitter<any>();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  constructor() { }

  ngOnInit () {
    _.defaults(this.item, DropdownMenuItemComponent.defaults);
  }

  public clickHandler () {
    if (this.item.onClick) {
      this.item.onClick(this.item);
    }
  }

  public mouseEnterHandler() {
    // Emit message (received by DropdownMenuComponent) to close any already open menus
    this.onSubmenuOpened.emit(this.item);
    this.menuTrigger.openMenu();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { CoreModule } from 'app/core/core.module';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateSelectionComponent } from './components/date-selection/date-selection.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateShortcutsComponent } from './components/date-shortcuts/date-shortcuts.component';
import { ComparisonDatesHolderComponent } from './components/comparison-dates-holder/comparison-dates-holder.component';
import { ComparisonDatesComponent } from './components/comparison-dates-holder/comparison-dates/comparison-dates.component';
import { DateShortcutsService } from 'app/siq-forms/modules/dates/services/date-shortcuts.service';

@NgModule({
  declarations: [
    DateSelectionComponent,
    DatePickerComponent,
    DateShortcutsComponent,
    ComparisonDatesHolderComponent,
    ComparisonDatesComponent
  ],
  exports: [
    DateSelectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SiqMaterialModule,
    CoreModule,
    MatDatepickerModule
  ],
  providers: [
    DatesService, DateShortcutsService
  ]
})
export class DatesModule { }

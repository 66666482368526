<div class="chips">
  <mat-chip-list>
    <ng-container *ngFor="let f of model.filterSetsDisplay">
      <mat-chip
        class="mat-chip-small"
        [ngClass]="f.allowed ? (f.include ? 'include' : 'exclude') : 'not-allowed'"
        [matTooltip]="f.tooltip">
        {{ f.name }}
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

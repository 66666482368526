<div #grid class="grid-container">
  <ag-grid-angular #activityTableGrid
    *ngIf="gridOptions"
    [className]="agTheme"
    [gridOptions]="gridOptions"
  ></ag-grid-angular>
</div>

<div class="actions no-select" *ngIf="numSelectedIds && (parent.selectedTab === tabKeys.ALL_REPORTS || parent.selectedTab === tabKeys.MY_REPORTS || parent.selectedTab === tabKeys.DELETED)">
  <label>{{ numSelectedIds }} Selected</label>
  <button
    mat-icon-button
    matTooltip="Delete"
    matTooltipPosition="above"
    (click)="toggleTrashed(true)"
    *ngIf="parent.selectedTab !== tabKeys.DELETED"
  >
    <mat-icon>delete</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="Restore"
    matTooltipPosition="above"
    (click)="toggleTrashed(false)"
    *ngIf="parent.selectedTab === tabKeys.DELETED"
  >
    <mat-icon>undo</mat-icon>
  </button>
</div>

import { Component } from '@angular/core';
import { AgRendererComponent, ColDef } from '@siq-js/visual-lib';
import { Activity } from 'app/activity/models/activity.model';

@Component({
  selector: 'siq-application-type-renderer',
  templateUrl: './application-type-renderer.component.html',
  styleUrls: ['./application-type-renderer.component.scss']
})
export class ApplicationTypeRendererComponent implements AgRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: 'Type',
    cellRenderer: ApplicationTypeRendererComponent,
    width: 130,
    resizable: false,
    valueGetter: params => {
      const activity = params.data as Activity;
      if (!activity.isSharedOrScheduled()) {
        return ''
      } else {
        if (activity.isShared()) {
          return 'Shared'
        } else if (activity.isScheduled()) {
          return 'Scheduled'
        }
      }
    },
    pinned: 'right'
  };

  public appType: string;

  constructor() { }

  agInit(params: any) {
    this.appType = params.value;
  }

  refresh(): boolean {
    return true;
  }

}

import * as _ from 'lodash';

// Generates a UID for each visualization
export function guid() {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  // TODO: add a 'prefix?: string' parameter, and find usages expecting 'siq-visual-' and update accordingly
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function isLessThan<T>(bigger: any, smaller: any): (number | null) {
  if (_.isNil(smaller) && _.isNil(bigger)) return 0;
  if (_.isNil(bigger)) return -1;
  if (_.isNil(smaller)) return 1;

  if (typeof bigger === 'string') {
    const numA = Number(bigger);
    const numB = Number(smaller);
    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    return bigger.localeCompare(smaller);
  }

  if (typeof bigger === 'number') {
    smaller = Number(smaller);
    if (!_.isFinite(bigger) && !_.isFinite(smaller)) return 0;
    if (!_.isFinite(bigger)) return -1;
    if (!_.isFinite(smaller)) return 1;
    return bigger - smaller;
  }

  if (bigger instanceof Date) {
    return bigger.getTime() - smaller.getTime();
  }
  return null;
}

// Formats a number input to either one of two styles:
// 1. If truncate is passed in, it will abbreviate large quantities, i.e. 123456 becomes 123K
// 2. Otherwise, formats the input to a comma separated number to the fixed number of decimals
export function num(input: number, dec: number, truncate?: boolean): string {
  const UNITS = ['', 'K', 'MM', 'B'];

  if (_.isNil(input)) return '';

  input = Number(input);

  if (input < 0) {
    const c = num(Math.abs(input), dec, truncate);
    // Prevent zero from getting a negative indicator prefix
    const prefix = Math.abs(Number(c)) !== 0 ? '-' : '';
    return prefix + num(Math.abs(input), dec, truncate);
  }

  if (truncate) {

    let i = 0; // Current index of UNITS
    while (input >= 1000) {
      input /= 1000;
      i++;
    }

    const extraDecimal = Number(input.toFixed(1)) % 1;
    return input.toFixed(!!extraDecimal ? 1 : 0) + UNITS[i];
  }

  return input.toFixed(dec || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getCmsMetricDataValue(dataObj: any, key: string): (number | string) {
  // if (!_.isNil(_.get(value[cmsMetric.id], 'weight'))) {
  //   value[cmsMetric.id] = _.get(value[cmsMetric.id], 'weight')
  // } else if (!_.isNil(_.get(value[cmsMetric.id], 'val'))) {
  //   value[cmsMetric.id] = _.get(value[cmsMetric.id], 'val')
  // } else {
  //   value[cmsMetric.id] = value[cmsMetric.id]
  // }

  let finalVal;
  if (!_.isNil(_.get(dataObj[key], 'weight'))) {
    finalVal = _.get(dataObj[key], 'weight');
  } else if (!_.isNil(_.get(dataObj[key], 'val'))) {
    finalVal = _.get(dataObj[key], 'val');
  } else {
    finalVal = dataObj[key];
  }
  return finalVal;
}

/**
 * Taken from apps/dashboard/src/app/core/services/utils/utils.service.ts
 * @param str
 */
export function stringToNum(str: string): (number | null) {
  if (!str) {
    return null;
  }
  const _num = Number(str.replace(/[,$%]/g, ''));
  if (isNaN(_num)) {
    console.warn(str + ' is not a valid number!');
    return null;
  }
  return _num;
}

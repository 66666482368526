import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';

@Injectable()
export class PromoCloneResolverService implements Resolve<void> {

  constructor(private promoService: PromoService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> | Promise<void> | void {
    this.promoService.cloneReport(route.paramMap.get('id'));
  }
}

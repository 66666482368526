import { Component } from '@angular/core';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import { ColDef } from '@siq-js/visual-lib';
import { IncrementalsService } from 'app/siq-applications/modules/incrementals/services/incrementals.service';
import { ActivityCriteriaRendererComponent } from 'app/activity/shared/renderers/activity-criteria-renderer/activity-criteria-renderer.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { CriteriaChipFactory } from 'app/core/models/factories/criteria-chip-factory';
import { IncrementalsActivity } from 'app/siq-applications/modules/incrementals/models/incrementals-activity.model';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Model {
  dateRange: string;
  retailers: CriteriaChip;
  incrementalType: CriteriaChip;
  filters: CriteriaChip;
}

@Component({
  selector: 'siq-js-incrementals-criteria-renderer',
  templateUrl: './incrementals-criteria-renderer.component.html',
  styleUrls: ['./incrementals-criteria-renderer.component.scss']
})
export class IncrementalsCriteriaRendererComponent extends ActivityCriteriaRendererComponent {
  public static readonly ColDef: ColDef = {
    headerName: 'Criteria',
    sortable: false,
    cellRenderer: IncrementalsCriteriaRendererComponent,
    width: 300
  };
  public model: Model;
  public isMultiMode: boolean = false;

  constructor(
    private incrementalsService: IncrementalsService,
    asyncStatusService: AsyncStatusService
  ) {
    super(asyncStatusService);
  }

  protected setupModel(activity: IncrementalsActivity) {
    this.isMultiMode = EnvConfigService.isMultiMode();
    if (activity.sheets.length > 0) {
      const form = this.incrementalsService.createForm(activity.sheets[0].getFormValues());
      this.model = {
        dateRange: CriteriaChipFactory.getDate(form.dateRanges),
        retailers: CriteriaChipFactory.getRetailers(form.locationFilters, form.schema),
        incrementalType: {
          label: `${UtilsService.capitalizeFirstLetter(form.incrementalType.display)} Assortment`},
        filters: CriteriaChipFactory.getFilters([...form.locationFilters, ...form.productFilters])
      };
    } else {
      this.model = null;
    }
    this.ready();
  }

  refresh(params: any) {
    return true;
  }

}

<siq-progress-spinner [show]="model?.running" [overlay]="true"></siq-progress-spinner>

<siq-app-header *ngIf="!parent" [config]="config" [showBack]="true"></siq-app-header>

<siq-query-mode #queryMode *ngIf="!parent" [modalConfig]="queryModeModalConfig"></siq-query-mode>

<div class="form-container" *ngIf="formData" (click)="handleClick($event)">

  <div class="title-section report-builder-toolbar" *ngIf="!parent">
    <mat-form-field class="title-input" floatLabel="never">
      <input matInput [placeholder]="namePlaceholder" [(ngModel)]="formData.name"
             (focus)="namePlaceholder = config.placeholder" (focusout)="namePlaceholder = ''"
             autocomplete="off" class="report-name"
      />
    </mat-form-field>

    <div class="fill-space"></div>

    <button mat-raised-button class="run-query-button" color="accent" (click)="submit()" [disabled]="!model.valid || model.running">
      Run
    </button>
  </div>

  <div class="main-wrapper">

    <div class="main-side-wrapper">

      <div class="search-section">
        <mat-icon class="search-icon" (click)="searchBar.focus()">search</mat-icon>
        <input [(ngModel)]=model.searchStr class="search-bar" #searchBar placeholder="Search Fields & Metrics" type="text"
               (keyup)="search(model.searchStr)"
        />
        <mat-icon *ngIf="model.searchStr" (click)="search()" class="cancel-icon">cancel</mat-icon>
      </div>

      <div class="entity-section" *ngIf="model.displayedMetrics || model.displayedFields">

        <mat-expansion-panel class="section-label" [expanded]="model.dimExpanded"
                             *ngIf="model.displayedFields?.length"
                             (opened)="toggleAllPanels(columnTypeEnums.DIMENSION, true)" (closed)="toggleAllPanels(columnTypeEnums.DIMENSION, false)"
        >
          <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px" color="accent">Dimensions</mat-expansion-panel-header>
        </mat-expansion-panel>

        <div class="entity-list" *ngIf="model.displayedFields?.length">

          <ng-container *ngFor="let fieldOrderItem of model.displayedFields">

            <mat-expansion-panel #dimDropdowns class="item-group" *ngIf="fieldOrderItem.children; else single"
                                 (opened)="updatePanelExpandedModel(columnTypeEnums.DIMENSION)" (closed)="updatePanelExpandedModel(columnTypeEnums.DIMENSION)"
            >

              <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">{{ fieldOrderItem.group }}</mat-expansion-panel-header>

              <div dragula="bag" [attr.data-id]="'dim-bag'">

                <div class="report-builder-list-item" *ngFor="let field of fieldOrderItem.children" [attr.data-key]="field.id">
                  <span class="list-item-label" [innerHTML]="field.display | searchMatch : model.searchStr"></span>
                  <div class="actions">
                    <button class="action-buttons" (click)="addColumn(field)" [disabled]="model.alreadyAddedMap[field.id]" mat-flat-button color="primary">Add</button>
                    <button *ngIf="field.filter && field.filter !== 'DATE'" class="action-buttons" (click)="globalFilter.addFilter(field)" mat-flat-button color="primary">Filter</button>
                  </div>
                </div>

              </div>

            </mat-expansion-panel>

            <ng-template #single>
              <div dragula="bag" [attr.data-id]="'dim-bag'" class="single-item">
                <div class="report-builder-list-item" [attr.data-key]="fieldOrderItem.id">
                  <span class="list-item-label" [innerHTML]="fieldOrderItem.display | searchMatch : model.searchStr"></span>
                  <div class="actions">
                    <button class="action-buttons" (click)="addColumn(fieldOrderItem)" [disabled]="model.alreadyAddedMap[fieldOrderItem.id]" mat-flat-button color="primary">Add</button>
                    <button *ngIf="fieldOrderItem.filter && fieldOrderItem.filter !== 'DATE'" class="action-buttons" (click)="globalFilter.addFilter(fieldOrderItem)" mat-flat-button color="primary">Filter</button>
                  </div>
                </div>
              </div>
            </ng-template>

          </ng-container>

        </div>

        <mat-expansion-panel class="section-label" [expanded]="model.metricExpanded"
                             *ngIf="model.displayedMetrics?.length"
                             (opened)="toggleAllPanels(columnTypeEnums.METRIC, true)" (closed)="toggleAllPanels(columnTypeEnums.METRIC, false)"
        >
          <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">Metrics</mat-expansion-panel-header>
        </mat-expansion-panel>

        <div class="entity-list" *ngIf="model.displayedMetrics?.length">

          <ng-container *ngFor="let metricOrderItem of model.displayedMetrics">

            <mat-expansion-panel #metricDropdowns class="item-group" *ngIf="metricOrderItem.children; else single"
                                 (opened)="updatePanelExpandedModel(columnTypeEnums.METRIC)" (closed)="updatePanelExpandedModel(columnTypeEnums.METRIC)"
            >
              <mat-expansion-panel-header  collapsedHeight="36px" expandedHeight="36px">{{ metricOrderItem.group }}</mat-expansion-panel-header>

              <div dragula="bag" [attr.data-id]="'metric-bag'">
                <div class="report-builder-list-item" *ngFor="let metric of metricOrderItem.children" [attr.data-key]="metric.id">
                  <span class="list-item-label" [innerHTML]="metric.display | searchMatch: model.searchStr"></span>
                  <div class="actions">
                    <button class="action-buttons" (click)="addColumn(metric)" [disabled]="model.alreadyAddedMap[metric.id]" mat-flat-button color="primary">Add</button>
                  </div>
                </div>
              </div>

            </mat-expansion-panel>

            <ng-template #single>
              <div dragula="bag" [attr.data-id]="'metric-bag'" class="single-item">
                <div class="report-builder-list-item" [attr.data-key]="metricOrderItem.id">
                  <span class="list-item-label" [innerHTML]="metricOrderItem.display | searchMatch: model.searchStr"></span>
                  <div class="actions">
                    <button class="action-buttons" (click)="addColumn(metricOrderItem)" [disabled]="model.alreadyAddedMap[metricOrderItem.id]" mat-flat-button color="primary">Add</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>

        </div>

        <div class="no-search-results" *ngIf="!model.displayedMetrics?.length && !model.displayedFields?.length">
          <span>
            <i>No matching results</i>
          </span>
          <br/>
          <a (click)="search()">Clear Search</a>
        </div>

      </div>

    </div>

    <div class="main-section">

      <div class="main-section-toolbar">

        <div class="filter-section" *ngIf="formData.globalFilters && schemaController$">
          <mat-expansion-panel #filterDropdown>
            <mat-expansion-panel-header collapsedHeight="55px" expandedHeight="55px">
              <div class="filters-bag" dragula="bag" [attr.data-id]="'filters-bag'">
              </div>
              <mat-panel-title>Filters</mat-panel-title>
              <mat-panel-description>
                <!-- Grabs the summary by hooking into the summary$ BehaviorSubject in globalFilter -->
                <span class="filter-summary" *ngIf="globalFilter.summary$ | async as summary; else noFilters">
                  {{ summary }}
                </span>
                <ng-template #noFilters>
                  <span>Limit results by selecting filters (optional)</span>
                </ng-template>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filter-body">
              <siq-report-builder-global-filter
                #globalFilter
                [schemaController]="schemaController$"
                (emitter)="updateGlobalFilters($event)"
                [initModel]="formData.globalFilters"
              ></siq-report-builder-global-filter>
            </div>
          </mat-expansion-panel>
        </div>

        <div class="same-store-sales-container" *ngIf="globalFilter">
          <siq-js-same-store-sales-selection
            #sameStoreSalesComponent
            [isInsideFilterModal]="false"
            [globalFilter]="globalFilter"
            [schemaController$]="schemaController$"
            (checkChanged)="formData.sssChecked = $event"
            ></siq-js-same-store-sales-selection>
        </div>

        <siq-js-date-selection
          *ngIf="formData && dateSelectionConfig"
          [config]="dateSelectionConfig"
          [initModel]="formData.globalDateRange"
          (onDateChanged)="globalDateChanged($event)"
          (onWeekEndingDayChanged)="weekEndingDayChanged($event)"
        ></siq-js-date-selection>

      </div>

      <div class="table-wrapper">

        <div class="table-section" dragula="bag" [attr.data-id]="'main-bag'">

          <siq-report-builder-form-column-group
            *ngFor="let col of formData.columns"
            [attr.data-key]="col?.key"
            [col]="col"
            [parent]="this"
          ></siq-report-builder-form-column-group>

          <div class="placeholder no-select">
            <div class="placeholder-text-container">
              <p>
                Add/Drag
                <br/>
                Dimension or
                <br/>
                Metric here
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

import { Component } from '@angular/core';
import { Activity } from 'app/activity/models/activity.model';
import { AgRendererComponent, ColDef } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-created-renderer',
  templateUrl: './created-renderer.component.html',
  styleUrls: ['./created-renderer.component.scss']
})
export class CreatedRendererComponent implements AgRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: 'Created',
    cellRenderer: CreatedRendererComponent,
    width: 130,
    resizable: false,
    sortable: true,
    sort: 'desc',
    valueGetter: params => {
      const activity = params.data as Activity;
      return activity.getCreationDate();
    },
    pinned: 'right'
  };

  public created: Date;

  constructor() { }

  agInit(params: any) {
    this.created = params.value;
  }

  refresh(): boolean {
    return true;
  }

}

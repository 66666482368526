import { Component, Input, OnInit } from '@angular/core';
import { EditFilterModalComponent } from 'app/filter/components/edit-filter-modal/edit-filter-modal.component';

@Component({
  selector: 'siq-bulk-upload-modal',
  templateUrl: './bulk-upload-modal.component.html',
  styleUrls: ['./bulk-upload-modal.component.scss']
})
export class BulkUploadModalComponent implements OnInit {

  @Input() parent: EditFilterModalComponent;

  public inputTextValue = '';
  public notFound = false;
  public codeCount = 0;
  get inputText(): string {
    return this.inputTextValue;
  }
  set inputText(input: string) {
    this.inputTextValue = input;
    this.notFound = false;
    this.codeCount = this.stringToWordArray(input).length;
  }

  constructor() { }

  ngOnInit() { }

  /**
   * Help Function, parse input string into words, RegExp(/(;|\n|\r)+/g)
   *
   * @param {string} str
   * @return {string[]}
   */
  public stringToWordArray(str): string[] {
    return str.replace(/(;|\n|\r)+/g, ';')
      .split(';')
      .map(s => s.trim())
      .filter(s => s.length > 0);
  }

  /**
   * Search BulkUpload inputs
   */
  public search() {
    const searchTexts = this.stringToWordArray(this.inputText);
    this.parent.bulkSearch(Array.from(new Set(searchTexts)));
  }

  /**
   * Hide BulkUpload Component
   */
  public cancel() {
    this.notFound = false;
    this.inputText = '';
    this.parent.toggleBulkUpload();
    this.parent.bulkUploadCheckbox = false;
  }

  /**
   * Download CSV, create link virtual HTML component and click() it
   */
  public downloadWordArrayAsCSV() {
    const downloadFileName =  'PDI_bulk_upload_failures.csv';
    const wordsArray = this.inputText;
    const link_element = document.createElement('a');
    link_element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(wordsArray));
    link_element.setAttribute('download', downloadFileName);
    link_element.click();
  }

}

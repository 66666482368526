import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { Injectable } from '@angular/core';
import { ApplicationHash } from '@siq-js/core-lib';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';

@Injectable()
export class DaypartsConfig extends SiqApplicationConfig {

  public appId = ApplicationHash.DAYPARTS;
  public actions: ActivityOptionsActions[] = [
    ActivityOptionsActions.CLONE,
    ActivityOptionsActions.RESULTS,
    ActivityOptionsActions.SHARE,
    ActivityOptionsActions.DELETE
  ];
  public pollInterval = 2000;
  constructor() {
    super();
  }
}

import { AfterViewInit, Component, Input } from '@angular/core';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { BaseFilterGroupComponent } from 'app/filter/components/base-filter-group/base-filter-group.component';
import { LoadFiltersetModalComponent } from 'app/filtersets/components/load-filterset-modal/load-filterset-modal.component';
import { SaveFiltersetModalComponent } from 'app/filtersets/components/save-filterset-modal/save-filterset-modal.component';
import { FilterSelection } from 'app/filter/models/filter-selection';

@Component({
  selector: 'siq-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent extends BaseFilterGroupComponent implements AfterViewInit{
  @Input() isColumnFilter: boolean; // Passed as "true" from ReportBuilder (column filter)
  @Input() isUserGroup: boolean; // When creating new user group, hide Load Filterset and Save Filterset
  public lwhEnabled: boolean;

  constructor(
    protected asyncStatusService: AsyncStatusService,
    protected utils: UtilsService
  ) {
    super(asyncStatusService, utils);
  }

  public loadFilterset() {

    this.utils.openModal(
      LoadFiltersetModalComponent,
      {
        currentSchema: this.currentSchema,
        mapFn: this.mapFn
      },
      UtilsService.MODAL_CONFIG_MEDIUM
    )
    .afterClosed()
    .subscribe((output: FilterSelection[]) => {
      if (output) {
        this.setModel(
          output
        );
      }
    });
  }

  public saveFilterset() {
    this.utils.openModal(SaveFiltersetModalComponent, {
      formArr: this.getModel(),
      lwhEnabled: this.lwhEnabled
    }, UtilsService.MODAL_CONFIG_MEDIUM);
  }

  ngAfterViewInit(): void {
    // Set the protected "_isColumnFilter" (from the base class of this class) so it can be referenced from within the base class
    this._isColumnFilter = this.isColumnFilter;
  }
}

import { Injectable } from '@angular/core';
import { FilterService } from 'app/filter/services/filter.service';
import { CmsService } from '../cms/cms.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs';
import { ValidationService } from 'app/siq-applications/services/validation/validation.service';

interface Status {
  applications?: boolean;
  cms?: boolean;
  envConfig?: boolean;
  filterValues?: boolean;
  validations?: boolean;
}

@Injectable()
export class AsyncStatusService {

  // Helpful static object for all required utils
  public static AllServices: Status = {
    cms: true,
    envConfig: true,
    applications: true,
    filterValues: true,
    validations: true
  };

  private readonly status$: BehaviorSubject<Status>;

  constructor() {
    // Initialize status with empty object (all falsey values)
    this.status$ = new BehaviorSubject<Status>({});

    // Set up individual subscriptions to each data service
    this.initCmsSub();
    this.initEnvConfigSub();
    this.initApplicationsSub();
    this.initFilterValuesSub();
    this.initValidationsSub();
  }

  /**
   * Returns a Promise that resolves when all utils in the params are done loading
   * @param criteria : Object with string -> boolean mapping for utils required for the promise to resolve
   */
  public async isReady(criteria: Status): Promise<any> {
    return new Promise<void>(
      (resolve, reject) => {
        const unsub$ = new Subject<void>();

        // Create a temporary internal subscription to statusSubject
        this.status$.pipe(
          takeUntil(unsub$)
        ).subscribe(status => {
          for (let k in criteria) {
            if (criteria[k] && !status[k]) return;
          }

          unsub$.next();
          unsub$.complete();
          resolve();
        });
      }
    );
  }

  private initCmsSub() {
    CmsService.data$
      .pipe(filter(data => !!data))
      .subscribe(() => this.update({ cms: true }));
  }

  private initEnvConfigSub() {
    EnvConfigService.data
      .pipe(filter(data => !!data))
      .subscribe(() => this.update({ envConfig: true }));
  }

  private initFilterValuesSub() {
    FilterService.loaded$
      .pipe(filter(loaded => !!loaded))
      .subscribe(() => this.update({ filterValues: true }));
  }

  private initValidationsSub() {
    ValidationService.loaded$
    .pipe(filter(loaded => !!loaded))
    .subscribe(() => this.update({ validations: true }));
  }

  private initApplicationsSub() {
    // ApplicationService.items is created as empty array, then populated after BootstrapService.data is populated.
    // Ensure it is populated (!!data.length) before marking as complete.
    ApplicationService.Applications$
      .pipe(filter(data => !!data))
      .subscribe(() => this.update({ applications: true }));
  }

  private update(status: Status) {
    const newStatus = this.status$.getValue();
    Object.assign(newStatus, status);
    this.status$.next(newStatus);
  }
}

import { AgRendererComponent } from '@siq-js/visual-lib';
import { Component, Injector } from '@angular/core';
import { SovResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-result/sov-result.component';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';

@Component({
  selector: 'siq-js-promo-sov-drilldown-renderer',
  templateUrl: './promo-sov-drilldown-renderer.component.html',
  styleUrls: ['./promo-sov-drilldown-renderer.component.scss']
})

export class PromoSovDrilldownRendererComponent implements AgRendererComponent {

  public static readonly CELL_CLASS = 'no-padding';

  public dimValue: string;
  public parent: SovResultComponent;
  public label: string;
  public canDrill: boolean;
  private params: any;

  constructor(
    injector: Injector
  ) {
    this.parent = injector.get(SovResultComponent);
  }

  agInit(params) {
    this.dimValue = params.value;
    this.params = params;

    const findSov = this.parent.findSov.bind(this.parent);
    const sov: SourceOfVolume = findSov(this.parent.dataSetKey);
    this.canDrill = sov.canDrill;

    let label = `${this.dimValue} (${sov.dimension.display}`;

    if (sov.comparisonGroup) {
      label += ` in ${sov.comparisonGroup.name})`;
    } else {
      label += ')';
    }

    this.label = label;
  }

  refresh(): boolean {
    return true;
  }

  public drilldown() {
    const _data = this.params.data;
    if (!_data) return;

    this.parent.drilldown(this.label, _data);
  }
}

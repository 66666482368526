import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiqApplicationsRoutingModule } from 'app/siq-applications/routes/siq-applications-routing.module';
import { ActivityModule } from 'app/activity/activity.module';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { ReportBuilderModule } from 'app/siq-applications/modules/report-builder/report-builder.module';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { AffinitiesModule } from 'app/siq-applications/modules/affinities/affinities.module';
import { IncrementalsModule } from 'app/siq-applications/modules/incrementals/incrementals.module';
import { DaypartsModule } from 'app/siq-applications/modules/dayparts/dayparts.module';
import { PromoModule } from 'app/siq-applications/modules/promo/promo.module';

@NgModule({
  imports: [
    CommonModule,
    SiqApplicationsRoutingModule,
    ActivityModule,
    SharedModule,
    ReportBuilderModule,
    AffinitiesModule,
    IncrementalsModule,
    DaypartsModule,
    PromoModule
  ],
  providers: [
    ApplicationService
  ]
})

export class SiqApplicationsModule {

  constructor() { }

}

import { Injectable } from '@angular/core';
import { User } from 'app/user/models/user.model';
import * as _ from 'lodash';
import { AuthService } from 'app/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WhatfixService {

  constructor() {
    AuthService.CurrentUser$.subscribe((user: User) => {
      this.setCurrentUser(user);
    });
  }

  setCurrentUser(user: User): void {
    /*
     * The Whatfix third-party-library needs to check a window variable in order to get data
     * it needs to track which users have viewed what (to help with analytics and tracking).
     */

    window['SwiftIQ'] = window['SwiftIQ'] || {};
    window['SwiftIQ']['whatfix'] = window['SwiftIQ']['whatfix'] || {};

    if (!_.isNil(user)) {

      const whatFix: any = {
        email: user ? user.email : '',
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        name: [user.firstName, user.lastName].join(' ')
      };
      window['SwiftIQ']['whatfix'] = _.merge(window['SwiftIQ']['whatfix'], whatFix);

    } else {

      delete window['SwiftIQ']['whatfix'];

    }

  }
}

import { Injectable } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { VisualizationState } from 'libs/visual-lib/src/lib/models';
import { GridComponent, VisualOptions } from 'libs/visual-lib/src/lib/modules/grid';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import {CmsField, CmsMetric} from '@siq-js/cms-lib';


@Injectable({
  providedIn: 'root'
})
export class VisualService {

  public static PREFIXES = {
    'grid': 'grid-',
    'line': 'line-'
  };
  public static visualizations: BehaviorSubject<Map<string, BaseSiqComponent>> = new BehaviorSubject<Map<string, BaseSiqComponent>>(new Map<string, BaseSiqComponent>());

  public static deregister(id: string): void {
    const mapTemp = VisualService.visualizations.getValue();
    mapTemp.delete(id);
    VisualService.visualizations.next(mapTemp);
  }

  public static findVisualization(id: string): any {
    const mapTemp = VisualService.visualizations.getValue();
    return mapTemp.get(id);
  }

  public static register(id: string, component: any): void {
    const mapTemp = VisualService.visualizations.getValue();
    mapTemp.set(id, component);
    VisualService.visualizations.next(mapTemp);
  }

  public static selectFacts(ids: string[], voRef: VisualOptions, additionalUpdate?: (currState: VisualizationState) => void) {
    const facts = voRef.metrics;
    VisualService.updateState(state => {
      state.metrics = ids.map(id => {
        const fact = _.find(facts, f => f.id === id);
        if (fact) {
          return fact;
        } else {
          console.error(id + 'is an invalid key!');
          return null;
        }
      });
      if (additionalUpdate) {
        additionalUpdate(state);
      }
    }, voRef);
  }

  public static factToCmsMetric(fact: any): CmsMetric {
    return new CmsMetric({
      id: fact.n,
      type: fact.t,
      display: fact.d || fact.n,
      aggType: fact.tt,
      active: true
    });
  }

  public static dimensionToCmsField(dim: any): CmsField {
    return new CmsField({
      id: dim.id,
      retailer: dim.r,
      table: dim.ta || '',
      field: dim.n,
      filter: dim.f || '',
      display: dim.d || dim.n,
      type: dim.t,
      active: true
    });
  }

  private static updateState(updateFn: (currState: VisualizationState) => void, voRef: VisualOptions) {
    const gc = <GridComponent>VisualService.findVisualization(voRef.vizId);
    const _state = gc.visualizationState$.getValue();
    updateFn(_state);
    // ! Didn't migrate the validateFacts fn which checks viz.multipleFactsAllowed(state)
    gc.visualizationState$.next(_state);
  }

  constructor() { }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from 'app/user/routes/user-routing.module';
import { UserListComponent } from 'app/user/components/user-list/user-list.component';
import { UserService } from 'app/user/services/user.service';
import { CoreModule } from 'app/core/core.module';
import { UserFormComponent } from 'app/user/components/user-form/user-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserDeleteComponent } from 'app/user/components/user-delete/user-delete.component';
import { AgGridModule } from '@siq-js/visual-lib';
import { GridModule } from 'app/grid/grid.module';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    GridModule,
    AgGridModule,
  ],
  exports: [],
  declarations: [
    UserListComponent,
    UserFormComponent,
    UserDeleteComponent
  ],
  providers: [
    UserService
  ]
})
export class UserModule { }

<mat-tab-group
  color="accent"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)">
  <mat-tab label="Share">
    <mat-dialog-content>
      <siq-share-report
        [modalData]="modalData"
        [limitedLengthTextConfig]="limitedLengthTextConfig">
      </siq-share-report>
    </mat-dialog-content>
  </mat-tab>

  <mat-tab label="Schedule" *ngIf="isNotPromo">
    <mat-dialog-content>
      <siq-schedule-report
        [modalData]="modalData"
        [limitedLengthTextConfig]="limitedLengthTextConfig">
      </siq-schedule-report>
    </mat-dialog-content>
  </mat-tab>
</mat-tab-group>

<mat-dialog-actions>
  <button type="button" mat-stroked-button mat-dialog-close color="accent" class="secondary-button">
    Cancel
  </button>

  <button type="button" mat-flat-button (click)="submit()" color="accent" [disabled]="!valid">
    {{submitButtonText | capitalize}}
  </button>
</mat-dialog-actions>

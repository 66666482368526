import * as _ from 'lodash';
import { BaseModel } from 'app/core/models/base-model';
import { RecipientStatus } from 'app/activity/modules/sharing/models/recipient-status.enum';
import { User } from 'app/user/models/user.model';
import { RecipientService } from '../services/recipient.service';

export class Recipient extends BaseModel {

  public email: string;
  public external: boolean;
  public name: string;
  public status: RecipientStatus;

  get nameWithEmail(): string {
    let returnValue = this.email;
    if (this.name) {
      returnValue = this.name + ' <' + returnValue + '>';
    }

    return returnValue;
  }

  constructor(users?: User[], data?: Recipient | User | string) {
    super();

    if (data) {
      const email = typeof(data) == 'string' ? data : data.email?.toString();
      if(email){
        RecipientService.updateEmail(users ?? [], this, email);
      }
    }

  }

  public isEqual(recipient: Recipient): boolean {
    return _.toLower(this.email) === _.toLower(recipient.email);
  }

}

import * as _ from 'lodash';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, defaultIfEmpty, forkJoin, of, switchMap, take } from 'rxjs';
import {
  catchError,
  expand,
  filter,
  map,
  reduce,
  share,
  tap
} from 'rxjs';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationExtras} from '@angular/router';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { ActivityFactory } from 'app/activity/models/activity.factory';
import {
  ActivityJson,
  ApplicationHash,
  CrudConfig
} from '@siq-js/core-lib';
import {
  NotificationService,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  ResponseCodesConfig } from '@siq-js/angular-buildable-lib';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { PollingService } from 'app/core/services/polling/polling.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';

export enum ActivityResultType {
  NO_RESULTS,
  WITH_RESULTS,
  PARTIAL,
  PREVIEW,
  POLL,
  CLONE
}

export interface GetActivityParams {
  resultType: ActivityResultType;
  id: string;
  suppressTopLoadingBar?: boolean;
  partialIndices?: number[];
}

export interface CurrentPageInfo {
  appId: ApplicationHash,
  tab: ActivityTabKey,
}

@Injectable()
export class ActivityService extends SiqHttpService {

  public static readonly RerunningActivityIds: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());
  public static readonly Activities$: BehaviorSubject<Activity[]> = new BehaviorSubject([]);
  public static readonly REPORTS_ENDPOINT = 'reports-info';
  private static readonly ENDPOINT = 'swiftiq-activity';
  private static readonly END_OF_RESULTS = 'END_OF_RESULTS';
  private static readonly RefreshDebouncer$: Subject<boolean> = new Subject<boolean>();

  private overrideCodes: ResponseCode[] = [];
  public RefreshDebouncerSubscription: Subscription;
  private NOT_FOUND = 'not-found';

  private currentPageInfo: CurrentPageInfo = {appId: null, tab: null}; // Holds the current page appid and tab info. List pages need to update this info correctly using getCurrentPageInfo() for the retrieveActivities() to work properly.

  private handleError(err: HttpErrorResponse | any, params: GetActivityParams) {
    if (!params.suppressTopLoadingBar) {
      EmitterService.get('topLoading').emit(false);
    }
    const navigationExtras: NavigationExtras = { state: { 'errMsg': err.error } };
    this.router.navigate([this.NOT_FOUND], navigationExtras)
    
    return new Observable<any>(obs => {
      obs.error(err);
      obs.complete();
    });
  }

  /**
   * Creates a BehaviorSubject stream from the master activity list stream
   * @param mapFn: pure function that transforms the master activity list into the desired output array of activities
   */
  public static createStream<T extends Activity = Activity>(mapFn: (activities: Activity[]) => T[]): BehaviorSubject<T[]> {

    const bs$ = new BehaviorSubject<T[]>([]);

    ActivityService.Activities$
      .pipe(
        map(list => mapFn(list)),
        filter(list => {
          // nil check
          if (!list) return false;

          // Only emit value if there is a diff between two lists
          // if (onlyDiff && this.diff(bs$.getValue(), list)) return true;

          return true;
        })
      )
      .subscribe(list => bs$.next(list));

    return bs$;
  }

  /**
   * Checks if a list of activities are different from another
   * @param arr1: first array of activities
   * @param arr2: second array of activities
   */
  public static diff(arr1: Activity[], arr2: Activity[]): boolean {
    if (!arr1 || !arr2) return true; // init null state
    if (arr1.length !== arr2.length) return true; // length check

    // Since there aren't that many activities (not enough to cause memory concerns), use a map to for quick matching
    // of an activity in arr1 to arr2. key is activity id, value is the actual activity
    const _map: {
      [key: string]: Activity
    } = {};

    arr1.forEach(a => _map[a.getId(true)] = a); // load up the map with all activities from arr1

    // Iterate thru every activity in arr2
    for (let a2 of arr2) {
      const a1 = _map[a2.getId(true)]; // Look up its corresponding activity in the map

      if (a1 && a1.diff(a2)) {
        // if a1 differs from a2 (or doesnt exist), exit out of loop and return true
        return true;
      } else {
        // if there is no diff between the two, remove the entry from the map and continue
        delete _map[a1.getId(true)];
      }
    }

    // At this point, if _map still has entries, there is a diff. Otherwise, if its empty, both arr1 and arr2 are equal
    return !!Object.keys(_map).length;
  }

  /**
   * Since "rerunning" an activity is basically just submitting a GET request & waiting for the response, this op is purely
   * client-side. Adds/removes an activity ID from the BehaviorSubject which manages which activities are currently in the state
   * of "rerunning"
   * @param id : activity ID
   * @param rerunning : boolean status of whether it is rerunning or not
   */
  static setRerunning(id: string, rerunning: boolean) {
    const set = ActivityService.RerunningActivityIds.getValue();
    if (rerunning) {
      set.add(id);
    } else {
      set.delete(id);
    }
    ActivityService.RerunningActivityIds.next(set);
  }

  public static refresh(doRefresh = true) {
    this.RefreshDebouncer$.next(doRefresh);
  }

  public static filterIncompleteActivities(activities: Activity[]): Activity[] {
    return activities.filter(a => !a.isComplete() && a.getStatus() !== ActivityStatus.ALERT && a.getStatus() !== ActivityStatus.ERROR && !a.isTrashed()); // excluding trashed activities polling
  }

  constructor(
    http: HttpClient,
    protected notificationService: NotificationService,
    public router: Router,
    private sharingService: SharingService,
    private asyncStatusService: AsyncStatusService,
  ) {
    super(http, notificationService);
    
    this.RefreshDebouncerSubscription = ActivityService.RefreshDebouncer$
      .pipe(
        switchMap(doRetrieve => this.retrieveActivities(doRetrieve)) // use switchMap here to cancel previous unfinished request. Note polling will not cancel itself since it's called only when request finishes and ActivityService.Activities$.next() is called.
      )
      .subscribe(activities => {
        this.processResults(activities);
      })
  }


  public getCurrentPageInfo() {
    return this.currentPageInfo;
  }

  public clearCurrentPageInfo() {
    this.currentPageInfo.appId = null;
    this.currentPageInfo.tab = null;
  }

  public rerun(activity: Activity): Observable<any> {
    ActivityService.setRerunning(activity.getId(), true);

    return this.get({ endpoint: `${ActivityService.ENDPOINT}/${activity.getId()}?refresh=true` })
      .pipe(
        tap(() => ActivityService.setRerunning(activity.getId(), false)),
      );
  }

  // public convertResponse(j: any[]): Activity[] {
  //   return j.map(json => this.createTypedActivity(json));
  // }

  createActivity(appRequest: AppRequest, path: string, suppressNotification?: boolean): Observable<HttpResponse<any>> {
    // use .share() to mark this as a "hot" observable (ie can have multiple subscribers
    // but only runs once, not once per subscriber
    return this.create({
      endpoint: path,
      body: appRequest.asJsonObject(),
      suppressNotification: suppressNotification
    })
      .pipe(
        share(),
        tap(() => ActivityService.refresh())
      );
  }

  async getActivityAsync(params: GetActivityParams): Promise<Activity> {
    return new Promise<Activity>((res, rej) => {
      this.getActivity(params)
        .subscribe(activity => res(activity));
    });
  }

  getActivity<T extends Activity = Activity>(params: GetActivityParams): Observable<T> {
    if (!params.suppressTopLoadingBar) {
      EmitterService.get('topLoading').emit(true);
    }

    let endpoint = `${ActivityService.ENDPOINT}/${params.id}`;
    switch (params.resultType) {
      case ActivityResultType.NO_RESULTS: // No results, nothing new needs to happen
        break;
      case ActivityResultType.CLONE:
        endpoint = `${endpoint}?cloning=true`;
        break;
      case ActivityResultType.WITH_RESULTS:
        endpoint = `${endpoint}?results=true`;
        break;
      case ActivityResultType.POLL:
        endpoint = `${endpoint}?poll=true&results=true`;
        break;
      case ActivityResultType.PARTIAL:
        if (!params.partialIndices) {
          throw new Error('No partial indices passed in!');
        }
        const indices = params.partialIndices.join(',');
        endpoint = `${endpoint}?results=${indices}`;
        break;
      case ActivityResultType.PREVIEW:
        endpoint = `${endpoint}?results=true&preview=true`;
        break;
    }

    /* paginated api */
    if (endpoint.includes('results=true')) {
      return this.getAllPages(endpoint, params);
    }

    return this.get({ endpoint: endpoint })
      .pipe(
        tap(() => !params.suppressTopLoadingBar && EmitterService.get('topLoading').emit(false)),
        map(activityJson => ActivityFactory.createActivity<T>(activityJson)),
        catchError((err) => this.handleError(err, params))
      );
  }

  getActivityPreview(id: string, suppressTopLoadingBar?: boolean): Observable<Activity> {
    return this.getActivity({
      id: id,
      resultType: ActivityResultType.PREVIEW,
      suppressTopLoadingBar: suppressTopLoadingBar
    });
  }

  getActivityResults(id: string, suppressTopLoadingBar?: boolean): Observable<Activity> {
    return this.getActivity({
      id: id,
      resultType: ActivityResultType.POLL, // changed from WITH_RESULTS to POLL as when ACtivity is re-run, sheet-activities were coming back complete:false but code was moving forward to process non-existant result data
      suppressTopLoadingBar: suppressTopLoadingBar
    });
  }

  getActivityResultsPartial(id: string, jobIndexes: number[], suppressTopLoadingBar?: boolean): Observable<Activity> {
    return this.getActivity({
      id: id,
      resultType: ActivityResultType.PARTIAL,
      partialIndices: jobIndexes,
      suppressTopLoadingBar: suppressTopLoadingBar
    });
  }

  getAllPages<T extends Activity = Activity>(endpoint: string, params: GetActivityParams): Observable<T> {
    let activity: Activity;
    return this.get({ endpoint: endpoint })
    .pipe(
      switchMap(activityJson => {
        activity = ActivityFactory.createActivity<T>(activityJson);
        const obs = activity.hasErrors() ? [] : activity.getJobs().map(job => this.getCurrentJobPages(job, endpoint));
        return forkJoin(obs).pipe(defaultIfEmpty([] as AppResponseDataset[][]));
      }),
      map((res: AppResponseDataset[][]) => {
        for (const jobs of res) {
          activity.jobs = [...activity.jobs, ...jobs];
        }
        return activity;
      }),
      tap(() => !params.suppressTopLoadingBar && EmitterService.get('topLoading').emit(false)),
      catchError((err) => this.handleError(err, params))
    )
  }

  markViewed(activity: Activity, src?: string) {
    if (activity.isSharedOrScheduled()) {
      // Sharing/scheduling
      this.sharingService.markViewed(activity, src);
    } else {
      // app activity
      if (!activity.isViewed()) this.updateMetadata(activity, { read: true }, true);
    }
  }

  updateMetadata(
    activity: Activity,
    metaDataToUpdate: any,
    suppressNotification?: boolean,
    overwrite?: boolean
  ): Observable<HttpResponse<any>> {

    if (!!metaDataToUpdate) {
      if (metaDataToUpdate.trashed) {
        this.overrideResponseCode(
          200,
          NotificationType.SUCCESS,
          'Report Deleted',
          'Your report has been successfully deleted.'
        );
      } else if (metaDataToUpdate.trashed === false) {
        this.overrideResponseCode(
          200,
          NotificationType.SUCCESS,
          'Report Restored',
          'Your report has been successfully restored.'
        );
      }
    }

    metaDataToUpdate = _.assign(activity.getMetaData(), metaDataToUpdate);

    let metaObj = {};
    let exclusionList = [
      'parsedMinDate',
      'parsedMaxDate',
      'minTimestamp',
      'maxTimestamp'
    ];

    _.map(metaDataToUpdate, (val, key: string) => {
      if (exclusionList.indexOf(key) === -1) {
        metaObj[key] = !_.isNil(val) ? _.trim(val.toString()) : val;
      }
    });

    let payload = <any>{
      id: activity.getId(),
      metaData: overwrite ? metaDataToUpdate : metaObj
    };

    return this.update({
      endpoint: 'app-activity-meta/' + activity.getId(),
      body: payload,
      suppressNotification: !!suppressNotification
    });
  }

  public getAllActivities() {
    const obs1 = this.get({
      endpoint: `${ActivityService.REPORTS_ENDPOINT}/all`,
      suppressNotification: true,
    });
    // List of active applications needs to be ready for ActivityFactory.createActivity to work
    const obs2 = ApplicationService.Applications$.pipe(filter(apps => !!apps), take(1));

    // Using forkJoin to kick off both obs at the same time to increase speed
    forkJoin([obs1, obs2])
    .pipe(
      map(arr => arr[0]),
      map((list: ActivityJson[]) => {
        return list
          .filter(json => {
            return json.activity && json.activity.appId && json.activity.id;
          })
          .map(json => ActivityFactory.createActivity(json))
      }),
      catchError(err => {
        console.error(err);
        return of([]);
      })
    ).subscribe((activities: Activity[]) => {
      ActivityService.Activities$.next(activities);
    });
  }

  private canRetrieve(): boolean {
    return !!this.currentPageInfo.appId && !!this.currentPageInfo.tab;
  }

  /**
   * Process results from retrieveActivities(). Kickoff polling & update Activities$
   */
  private processResults(activities: Activity[]) {
    // update Activities$ based on current results
    this.updateAllActivities(activities);

    // get incomplete activities
    const incompleteList = ActivityService.filterIncompleteActivities(activities).map(a => a.getId());
    if (incompleteList.length) {
      // kickoff polling
      const set = new Set([...PollingService.PollFor$.getValue(), ...incompleteList]);
      PollingService.PollFor$.next(set);
    }
  }

  /**
   *  Update/add/remove the relevant records in ActivityService.Activities$ based on latest results from refresh
   * @param newList: list containing latest results after refresh
   */
  private updateAllActivities(newList: Activity[]) {
    const oldList = ActivityService.Activities$.getValue();
    const idSet = new Set(newList.map(a => a.id));
    let res: Activity[];
    let temp: Activity[];
    let changed = true;
    switch (this.currentPageInfo.tab) {
      case ActivityTabKey.MY_REPORTS:
        temp = oldList.filter(a => !(idSet.has(a.id) || ((a.appId === this.currentPageInfo.appId || this.currentPageInfo.appId === ApplicationHash.ALL) && !a.isTrashed() && a.isMine() && !a.isSharedOrScheduled())));
        res = [...temp, ...newList];
        break;
      case ActivityTabKey.SHARED:
        temp = oldList.filter(a => !(idSet.has(a.id) || ((a.appId === this.currentPageInfo.appId || this.currentPageInfo.appId === ApplicationHash.ALL) && !a.isMine() && a.isShared() && !a.sharedReport.deleted)));
        res = [...temp, ...newList];
        break;
      case ActivityTabKey.SCHEDULED:
        temp = oldList.filter(a => !(idSet.has(a.id) || ((a.appId === this.currentPageInfo.appId || this.currentPageInfo.appId === ApplicationHash.ALL) && !a.isMine() && a.isScheduled() && !a.sharedReport.deleted)));
        res = [...temp, ...newList];
        break;
      case ActivityTabKey.DELETED:
        temp = oldList.filter(a => !(idSet.has(a.id) || ((a.appId === this.currentPageInfo.appId || this.currentPageInfo.appId === ApplicationHash.ALL) && a.isMine() && a.isTrashed())));
        res = [...temp, ...newList];
        break;
      case ActivityTabKey.ALL_REPORTS:
        res = newList;
        break;
      default:
        res = oldList;
        changed = false;
        break;
      }
      if (changed) {
        ActivityService.Activities$.next(res);
      }
  }


  // Get all paginated results for a job
  private getCurrentJobPages(parentJob: AppResponseDataset, endpoint: string): Observable<AppResponseDataset[]>{
    const jobId = parentJob.getResponse().jobId;
    const token = parentJob.getResponse().getNextPageToken();
    if (_.isNil(token) || token.includes(ActivityService.END_OF_RESULTS)) {
      return of([]);
    } else {
      return this.get({ endpoint: endpoint + '&nextPageToken=' + token })
      .pipe(
        expand(activityJson => {
          const _activity = ActivityFactory.createActivity(activityJson);
          const thisToken = _activity.getJobs().find(j => j.getResponse()?.jobId === jobId).getResponse()?.getNextPageToken();
          return _.isNil(thisToken) || thisToken.includes(ActivityService.END_OF_RESULTS) ?
            EMPTY : this.get({endpoint: endpoint + '&nextPageToken=' + thisToken});
        }),
        reduce((accData, data) => accData.concat(data), []),
        map(activityJson => {
          return activityJson.map(json => {
            const activity = ActivityFactory.createActivity(json);
            return activity.getJobs().find(j => j.getResponse()?.jobId === jobId);
          })
        }),
      )
    }
  }

  /**
   * Retrieves all relevant activities for the user
   */
  private retrieveActivities(doRetrieve: boolean): Observable<Activity[]> {
    if (!doRetrieve || !this.canRetrieve()) {
      return of([]);
    }

    let endpoint = ActivityService.REPORTS_ENDPOINT;
    let params: HttpParams;

    if (this.currentPageInfo.appId === ApplicationHash.ALL) {
      endpoint += `/all`
    } else {
      params = new HttpParams().append('appid', this.currentPageInfo.appId);
    }

    if (this.currentPageInfo.tab !== ActivityTabKey.ALL_REPORTS) {
      endpoint += `/${this.currentPageInfo.tab}`
    }

    const config: CrudConfig  = {
      endpoint: endpoint,
      params: params
    }

    return this.get(config)
      .pipe(
        map((list: ActivityJson[]) => {
          return list
            .filter(json => {
              return json.activity && json.activity.appId && json.activity.id;
            })
            .map(json => ActivityFactory.createActivity(json))
        }),
        catchError(err => {
          this.notificationService.error('Failed to retrieve reports.', 'Error');
          return of([]);
        })
      )
  }

  public notifyActivityComplete(activity: Activity) {
    const application = activity.getApplication();

    let message = 'View the results now or check your Activity History later';

    if (activity.getName()) {
      message += '<hr/>' + activity.getName();
    }

    this.notificationService.success(
      message,
      application?.display + ' Activity Complete',
      {
        data: {
          url: application.path,
          onClick: () => {
            this.router.navigate([activity.getPath()]);
          }
        },
        enableHTML: true
      });
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    return new ResponseCodes(this.overrideCodes);
  }

  public overrideResponseCode(code: number, type: NotificationType, header: string, message: string): void {
    _.remove(this.overrideCodes, ['code', code]);
    this.overrideCodes.push(
      new ResponseCode(
        code,
        '<hr/>' + message,
        header,
        type
      )
    );
  }
}

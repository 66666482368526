<div *ngIf="model" [ngClass]="model.css" [matTooltip]="toolTip"
     class="kpi-chip-container" matTooltipClass="multi-line-tooltip">
  <div class="label-section">
    <label>{{ model.factName }}</label>
  </div>

  <div class="metrics-section">
    <label class="main-value">{{ model.promoValue }}</label>
    <br/>

    <ng-container [ngSwitch]="model.css">

      <label class="sub-value" *ngSwitchCase="cssEnums.POSITIVE">
        &uarr; {{ model.growthValue }}
      </label>

      <label class="sub-value" *ngSwitchCase="cssEnums.NEGATIVE">
        &darr; {{ model.growthValue }}
      </label>

      <label class="sub-value" *ngSwitchCase="cssEnums.NONE">
        -
      </label>

    </ng-container>

  </div>
</div>

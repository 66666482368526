<div class="renderer-container" *ngIf="ready$ | async">
  <ng-container *ngIf="model; else empty">
    <mat-chip class="mat-chip-xsmall default">{{ model.retailer | retailerDisplay }}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" [matTooltip]="model.upcFilters.toolTip">{{model.upcFilters.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" *ngIf="model.upcCount">{{model.upcCount}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default">{{ model.promoDate }}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" matTooltipClass="multi-line-tooltip" [matTooltip]="model.otherDates.toolTip">{{model.otherDates.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" *ngIf="model.locationFilters" [matTooltip]="model.locationFilters.toolTip">{{model.locationFilters.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" *ngIf="model.comparisonGroup" [matTooltip]="model.comparisonGroup.toolTip">{{model.comparisonGroup.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" *ngIf="model.sourceOfVolume" [matTooltip]="model.sourceOfVolume.toolTip">{{model.sourceOfVolume.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" *ngIf="model.promoType">{{ model.promoType }}</mat-chip>
  </ng-container>
  <ng-template #empty>
    <mat-chip class="mat-chip-xsmall default">Empty Report</mat-chip>
  </ng-template>
</div>

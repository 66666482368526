<div *ngIf="!isDisplayOnly" class="cell-container" (click)="$event.stopPropagation(); filtersetName.focus()">
  <mat-form-field floatLabel="never">
    <input #filtersetName matInput matNativeControl placeholder="Filterset Name"
      [(ngModel)]="filterSetName"
      (change)="updateName()"
    >
  </mat-form-field>
</div>

<div *ngIf="isDisplayOnly" class="cell-container" >
  {{ filterSetName }}
</div>

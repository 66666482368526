import {
  AgBarSeriesFormatterParams,
  AgCartesianChartOptions,
  BarColumnConfig,
  BarColumnParams,
  BarColumnProcessor,
  ChartSettings,
  ChartsService,
  GridService,
  AgBarSeriesOptions
} from '@siq-js/visual-lib';
import { SovResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-result/sov-result.component';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { filter, takeUntil } from 'rxjs';
import { SovHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-helper/sov-helper.component';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { PromoDimensionKeys } from 'app/siq-applications/modules/promo/models/promo.enums';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export interface SovGrowersDecliners {
  growers: any[];
  decliners: any[];
}

export class SovColumnChartProcessor extends BarColumnProcessor {

  public static X_LABEL_LENGTH = 10;
  private static PDI_LIGHT_BLUE = THEME_COLORS.SKY;
  private static PDI_LIGHT_BLUE_OUTLINE = THEME_COLORS.SKY;
  private static PDI_DARK_BLUE = THEME_COLORS.INDIGO;
  private static GROWERS_DEFAULT_FILL_COLOR = THEME_COLORS.GRASS;
  private static GROWERS_DEFAULT_STROKE_COLOR = THEME_COLORS.GRASS;
  private static DECLINERS_DEFAULT_FILL_COLOR = THEME_COLORS.SIENNA;
  private static DECLINERS_DEFAULT_STROKE_COLOR = THEME_COLORS.SIENNA;

  public static generateBarColumnConfig(barColParams: BarColumnParams): BarColumnConfig {

    let config: BarColumnConfig = {
      agChartOptions: <AgCartesianChartOptions>{
      }
    };

    let typeSafe: AgCartesianChartOptions = {
      height: 450,
      title: {
        enabled: true,
        fontSize: 25,
        fontWeight: 'bold'
      },
      theme: ChartsService.getThemePdi(),
      series: [],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            rotation: 45,
            formatter: (params) => {
              if (params.value.length > SovColumnChartProcessor.X_LABEL_LENGTH) {
                return params.value.slice(0, SovColumnChartProcessor.X_LABEL_LENGTH) + '...';
              } else {
                return params.value;
              }
            }
          }
        },
        {
          type: 'number',
          position: 'left',
          tick: {
            minSpacing: 10,
            maxSpacing: 50
          },
        },
      ],
      legend: {enabled: false}
    };

    config.agChartOptions = typeSafe;
    return config;
  }

  public static processor = (params: BarColumnParams): ChartSettings => {

    params.rawData$.pipe(
      filter(data => !!data),
      takeUntil(params.parent.unsub$)
    )
    .subscribe(data => {
      const parent = (<SovResultComponent>params.parent);
      const factKey = (params.component instanceof SovHelperComponent) ? params.component.factKey : parent.shareDeltaFact;
      let newChartOptions = {...params.chart.getApi().chartOptions} as AgCartesianChartOptions;
      const periodKey = (params.component instanceof SovHelperComponent) ? params.component.periodKey : parent.periodKey;
      newChartOptions.title.text = `Top 10 Share Gains and Losses (vs ${parent.parent.formData[PromoConfig.periodNames[periodKey]].customPeriodName})`;

      // let dataSeries: AgBarSeriesOptions = {};
      const findSov = parent.findSov.bind(parent);
      const sov: SourceOfVolume = findSov(parent.dataSetKey);
      const dim = sov.dimension;
      const xKey: string = (params.component instanceof SovHelperComponent) ? params.component.distDim.id : dim.id;
      const dataSet = SovColumnChartProcessor.transformData(data, factKey.id, xKey);


      let dataSeries: AgBarSeriesOptions = {
        type : 'bar',
        data : dataSet,
        xKey : xKey,
        yKey : factKey.id,
        formatter : (p: AgBarSeriesFormatterParams<string[]>) => {
          let fill = '';
          let stroke = '';
          if (p.datum[factKey.id] >= 0) {
            fill = SovColumnChartProcessor.GROWERS_DEFAULT_FILL_COLOR;
            stroke = SovColumnChartProcessor.GROWERS_DEFAULT_STROKE_COLOR;
          } else {
            fill = SovColumnChartProcessor.DECLINERS_DEFAULT_FILL_COLOR;
            stroke = SovColumnChartProcessor.DECLINERS_DEFAULT_STROKE_COLOR;
          }
          // if promoUPCs are not empty & the dimension is UPC
          if (params.promoUPCs && dim.id.includes(PromoDimensionKeys.UPC) && params.promoUPCs.has(p.datum[dim.id])) {
            fill = SovColumnChartProcessor.PDI_LIGHT_BLUE;
            stroke = SovColumnChartProcessor.PDI_LIGHT_BLUE_OUTLINE;
          }
          if (p.highlighted) {
            fill = '#F3F300';
          }

          return  {
            fill: fill,
            stroke: stroke,
            strokeWidth: 1
          };
        },
        tooltip : {
          renderer: (p) => {
            const fact = (params.component instanceof SovHelperComponent) ? params.component.factKey : parent.shareDeltaFact;
            const formatterY = GridService.getVisualType(fact).formatter;
            return (
              '<div class="ag-chart-tooltip-title" style="background-color:' + p.color + '">' +
              p.datum[p.xKey] +
              '</div>' +
              '<div class="ag-chart-tooltip-content">' +
              '<span>' + fact.display + ': ' + formatterY({value: p.datum[fact.id]}) + '</span>' +
              '</div>'
            );
          }
        }
      }

      newChartOptions.series = [dataSeries];
      let xAxis = newChartOptions.axes[0];
      let yAxis = newChartOptions.axes[1];
      const yFormatter = GridService.getVisualType(factKey).formatter;

      xAxis.title = {
        text: (params.component instanceof SovHelperComponent) ? params.component.distDim.display : dim.display,
        enabled: true,
        fontWeight: 'bold'
      };
      yAxis.title = {
        text: (params.component instanceof SovHelperComponent) ? factKey.display + ' Change' : factKey.display,
        enabled: true,
        fontWeight: 'bold'
      }

      yAxis.label.formatter = (p) => yFormatter({value: p.value}, true);
      if (params.chart) {
        params.chart.agChartOptions = newChartOptions;
      }

    });

    return <ChartSettings>{
      agChartOptions: params.agChartOptions,
      parentActivity: params.parentActivity,
    };
  }

  /**
   * Transform data for the chart to use
   */
  public static transformData(rawData: any[], key: string, xKey: string): any[] {
    const data = [];

    rawData.forEach(datum => {
      const val = GridService.getValueGetterFinalVal(datum[key], 'val');
      datum[key] = val;
      if (!isNaN(datum[xKey])) datum[xKey] = datum[xKey].toString();
      data.push(datum);
    });
    return data;
  }
}

import { Routes, Route } from '@angular/router';
import { ProfileComponent } from 'app/profile/components/profile.component';
import { AuthGuard } from 'app/guards/auth-guard';

const profileRoute: Route = {
  path: 'profile',
  data: {
    name: 'Profile',
    icon: 'person'
  },
  canActivate: [
    AuthGuard
  ],
  children: [
    {
      path: '',
      component: ProfileComponent
    }
  ]
};

export const ProfileRoutes: Routes = [
  profileRoute
];

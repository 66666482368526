<button
  mat-button
  [matMenuTriggerFor]="userMenu"
>
  {{ currentUser ? currentUser.firstName : 'User' }}
</button>

<mat-menu #userMenu>

  <button
    mat-menu-item
    *ngFor="let r of routes"
    routerLink="{{ r.path }}"
  >
    <mat-icon *ngIf="r.data && r.data.icon">{{ r.data.icon }}</mat-icon>
    {{ r.data && r.data.name ? r.data.name : 'No Route Text!' }}
  </button>

  <button
    mat-menu-item
    routerLink="about"
    *ngIf="isInternalUser"
  >
    <mat-icon>info</mat-icon>About this Version
  </button>

  <button
    mat-menu-item
    (click)="logout()"
  >
    <mat-icon>power_settings_new</mat-icon>Log Out
  </button>

  <hr/>

  <div class="mat-menu-item theme-selector">
    <!-- Using ".mat-menu-item" class here to make format of this div match css of the other menu items -->
    <mat-slide-toggle
      (change)="toggleTheme($event)"
      color="accent"
    >Use Dark Mode</mat-slide-toggle>
  </div>
</mat-menu>

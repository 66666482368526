import { Pipe, PipeTransform } from '@angular/core';
import { CmsMetric } from '@siq-js/cms-lib';
import { GridService } from '@siq-js/visual-lib';
import { CmsService } from '../services/cms/cms.service';

@Pipe({
  name: 'valueFormatter'
})
export class ValueFormatterPipe implements PipeTransform {

  transform(value: any, metricId: string): any {
    const metric = CmsService.get().findEntity<CmsMetric>(metricId);
    const vizType = GridService.getVisualType(metric);
    return vizType.formatter({value: value});
  }

}

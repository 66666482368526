<ng-container *ngIf="!hideMRV">
  
  <mat-card *ngIf="!isInsideFilterModal" mat-button class="same-store-sales-large-checkbox-container">
    <mat-card-content>
      <mat-checkbox [checked]="checked" (change)="change($event)">Same Store Sales</mat-checkbox>
    </mat-card-content>
    <div matSuffix class="icons">
      <mat-icon
        class="info-icon"
        *ngIf="tooltip"
        [matTooltip]="tooltip.msg"
        matTooltipClass="multi-line-tooltip"
        [ngClass]="{'read': tooltip.read}"
        (mouseover)="tooltip.read = true;"
      >
        info
      </mat-icon>
    </div>
  </mat-card>
  
  <div *ngIf="isInsideFilterModal" class="same-store-sales-card">
    <mat-checkbox [checked]="checked" (change)="change($event)">Same Store Sales</mat-checkbox>
    <div>You may modify this list, but doing so will uncheck the "Same Store Sales" checkbox.</div>
  </div>

</ng-container>

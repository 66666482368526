import { IDoesFilterPassParams } from 'libs/visual-lib/src';
import { DateCategoryFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/date-category-filter/date-category-filter.component';
import { DatePart } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-part.enum';
import { CustomFilterService } from 'libs/visual-lib/src/lib/modules/custom-filters/services/custom-filter.service';
import { Directive } from "@angular/core";

@Directive()
export abstract class DatePartsFilterComponent extends DateCategoryFilterComponent {
  private _datePart: DatePart;

  // Focuses on the month element upon initialization of the filter controller
  // AfterViewInit interface implemented by inherited class
  // tslint:disable-next-line:use-life-cycle-interface
  abstract ngAfterViewInit(): void;

  constructor() {
    super();
  }

  /**
   * Checks a single row, as passed to it, to see if it falls inside of the specified filter criteria.
   *
   * @param {IDoesFilterPassParams} params A IDoesFilterPassParams object containing a reference to a single cell.
   * @returns {boolean} A boolean variable indicating if the row passes the filter criteria or not.
   */
  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return CustomFilterService.numberCompare(
      this.params.getValue(params.node,this.params.column),
      Number(this.getForm()[this.getDatePart()]),
      this.getFilterType(),
      this.getFormRangeEnd()[this.getDatePart()]
    );
  }

  /**
   * Returns the end of range date model used for date range filter type.
   *
   * @returns {DateForm} The date model object based on the filter form.
   *
   */
  public getDatePart(): DatePart {
    return this._datePart;
  }

  /**
   * Sets the part of the date.
   *
   * @param {DatePart} datePart The precision to be set.
   *
   */
  public setDatePart(datePart: DatePart): void {
    this._datePart = datePart;
  }
}

import { AgRendererComponent, ColDef, ICellRendererParams } from '@siq-js/visual-lib';
import { Subject } from 'rxjs';
import { HeaderCellSortableComponent } from 'app/core/components/cell-renderers/header-cell-sortable/header-cell-sortable.component';

class Status {
  classname: string;
  color: string;
  text: string;
  icon: string;
  constructor (classname, color, text, icon) {
    this.classname = classname;
    this.color = color;
    this.text = text;
    this.icon = icon;
  }
}

enum DefaultStatus {
  READY = 'ready',
  EXPIRED = 'expired',
  RUNNING = 'running',
  ERROR = 'error',
  ALERT = 'alert',
  EMPTY = 'empty'
}

export abstract class StatusRendererBaseComponent implements AgRendererComponent {

  public static readonly STATUS_CSS_PREFIX: string = 'status-';
  public static readonly ColDef: ColDef = {
    sortable: true,
    cellRenderer: null, // This gets set by the derived class
    cellStyle: {'text-align': 'center'},
    headerName: 'Status',
    headerComponent: HeaderCellSortableComponent,
    pinned: false,
    resizable: false,
    suppressSizeToFit: true,
    width: 50,
    minWidth: 50
  };

  public static StatusIconMap: Map<string, Status> = new Map([
    [DefaultStatus.RUNNING, new Status(StatusRendererBaseComponent.STATUS_CSS_PREFIX + DefaultStatus.RUNNING, 'deepskyblue', 'Running', 'hourglass_empty')],
    [DefaultStatus.ERROR, new Status(StatusRendererBaseComponent.STATUS_CSS_PREFIX + DefaultStatus.ERROR, 'red', 'Failure', 'close')],
    [DefaultStatus.READY, new Status(StatusRendererBaseComponent.STATUS_CSS_PREFIX + DefaultStatus.READY, 'green', 'Success', 'check')],
    [DefaultStatus.EMPTY, new Status(StatusRendererBaseComponent.STATUS_CSS_PREFIX + DefaultStatus.EMPTY, 'gray', 'Report Empty', 'edit')], //Report Builder is empty
    [DefaultStatus.EXPIRED, new Status(StatusRendererBaseComponent.STATUS_CSS_PREFIX + DefaultStatus.EXPIRED, 'blue', 'Expired', 'refresh')],
    [DefaultStatus.ALERT, new Status(StatusRendererBaseComponent.STATUS_CSS_PREFIX + DefaultStatus.ALERT, 'orange', 'Error', 'priority_high')]
  ]);

  public iconStatus: Status;
  public rerunning: boolean;
  private _unsub$ = new Subject<void>();
  private id: string;

  public static getColDef(component: any): ColDef {
    StatusRendererBaseComponent.ColDef.cellRenderer = component;
    return StatusRendererBaseComponent.ColDef;
  }

  public static setSortable(componentInstance: any): void {
    // componentInstance is "this" from the calling component
    StatusRendererBaseComponent.ColDef.headerComponent = HeaderCellSortableComponent;
    StatusRendererBaseComponent.ColDef.headerComponentParams = {
      parent: componentInstance
    };
  }

  constructor() { }

  // must be implemented by the derived class
  abstract agInit(params: ICellRendererParams): void;

  refresh(): boolean {
    return true;
  }
}

import { Component, Injector, Input, OnInit, StaticProvider, Type } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DRAWER_DATA, DrawerService, IDrawerComponent } from 'app/core/modules/drawer/services/drawer.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DrawerState } from 'app/core/modules/drawer/models/drawer-state.enums';
import { DrawerRules } from 'app/core/modules/drawer/models/drawer.interfaces';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'siq-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [
    trigger('resize', [
      state(DrawerState.PEEK, style({ width: '0' })),
      state(DrawerState.DEFAULT, style({ width: 'calc(65vw - 45px)' })),
      state(DrawerState.FULL, style({ width: 'calc(90vw - 45px)' })),
      transition('* <=> *', [
        animate('50ms')
      ])
    ])
  ]
})
export class DrawerComponent implements OnInit {

  public rules: DrawerRules;
  public state: DrawerState;
  public stateEnums = DrawerState;

  public outletParams: {
    comp: Type<any>,
    providers: Injector,
    rules: (_state: DrawerState) => DrawerRules
  };

  private update$: Subject<void> = new Subject();

  @Input() drawer: MatDrawer;

  constructor(
    private drawerService: DrawerService,
    private injector: Injector
  ) {

  }

  ngOnInit() {
    DrawerService.drawerState$.subscribe(_state => {
      this.updateState(_state);
    });
    DrawerService.drawerComponent$.subscribe((params: IDrawerComponent) => this.updateComponent(params));

    this.update$
      .pipe(
        debounceTime(50)
      )
      .subscribe(() => {
        this.updateRules();
      });
  }

  public onResize() {
    DrawerService.drawerResized$.next();
  }

  public close() {
    if (!this.rules.close) return;

    DrawerService.drawerState$.next(null);
  }

  public expand() {
    if (!this.rules.expand) return;

    const order = DrawerService.stateOrder;
    const nextState = order[order.indexOf(this.state) + 1];
    DrawerService.drawerState$.next(nextState);
  }

  public contract() {
    if (!this.rules.contract) return;

    const order = DrawerService.stateOrder;
    const prevState = order[order.indexOf(this.state) - 1];
    DrawerService.drawerState$.next(prevState);
  }

  private updateState(_state: DrawerState) {
    switch (_state) {
      case null:
        this.outletParams = null;
        this.drawer.close();
        break;
      case DrawerState.DEFAULT:
      case DrawerState.FULL:
      case DrawerState.PEEK:
        this.drawer.open();
        break;
    }

    this.state = _state;
    this.update$.next();
  }

  private updateComponent(params: IDrawerComponent) {
    this.outletParams = null;

    if (params && params.component) {
      setTimeout(() => {
        const dataProvider: StaticProvider = { provide: DRAWER_DATA, useValue: params.data || {}};
        this.outletParams = {
          comp: params.component,
          providers:  Injector.create({ providers: [dataProvider], parent: this.injector }),
          rules: params.drawerRules
        };

        this.update$.next();
      });
    }
  }

  private updateRules() {
    const _state = this.state;
    if (!_state) {
      this.rules = null;
      return;
    }

    const rules = {
      contract: true,
      close: true,
      expand: true
    };

    if (this.outletParams && this.outletParams.rules) {
      _.extend(rules, this.outletParams.rules(_state));
    }

    if (_state === DrawerState.FULL) {
      rules.expand = false;
    }

    if (_state === DrawerState.PEEK) {
      rules.contract = false;
    }

    this.rules = rules;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';
import { positiveNumberValidator } from 'app/siq-forms/validators/positive-number.directive';

export enum EndsMode {
  DEFAULT = 'never',
  END = 'end',
  COUNT = 'count'
}

@Component({
  selector: 'siq-schedule-ends',
  templateUrl: './schedule-ends.component.html',
  styleUrls: ['./schedule-ends.component.scss']
})
export class ScheduleEndsComponent implements OnInit {

  @Input() formControlEnd: UntypedFormControl;
  @Input() formControlCount: UntypedFormControl;
  public dateFormControl = new UntypedFormControl(new Date());
  public endsMode = EndsMode.DEFAULT;
  public count = 1;

  ngOnInit() {
    if (!_.isNil(this.formControlEnd.value)) {
      this.endsMode = EndsMode.END;
      this.dateFormControl.setValue(new Date(this.formControlEnd.value.split('-')));
    } else if (!_.isNil(this.formControlCount.value)) {
      this.endsMode = EndsMode.COUNT;
      this.count = this.formControlCount.value;
    }
    this.updateForm();
    this.dateFormControl.valueChanges.subscribe(() => { this.updateForm() });
  }

  updateDateForm() {
    this.endsMode = EndsMode.END;
    this.updateForm();
  }

  dateFormatted(date) {
    if (this.dateFormControl.valid) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
  }

  updateCountForm() {
    this.endsMode = EndsMode.COUNT;
    this.updateForm();
  }

  updateForm() {
    if (this.endsMode !== EndsMode.COUNT) {
      this.formControlCount.clearValidators();
    } else {
      this.formControlCount.setValidators([
        positiveNumberValidator(10000, 1, 'positiveNumberCount')
      ]);
    }
    this.formControlEnd.setValue(this.endsMode === EndsMode.END ? this.dateFormatted(this.dateFormControl.value) : null);
    this.formControlCount.setValue(this.endsMode === EndsMode.COUNT ? this.count : null);
  }
}

import { AfterViewInit, Directive } from '@angular/core';
import { DateModel } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-model';
import { DateForm } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-form.interface';
import { FilterLabels } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-labels.enum';
import { FilterTypes } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-types.interface';
import * as _ from 'lodash';
import { dayPeriod } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-form.interface';
import { FilterNumberTypes } from 'libs/visual-lib/src/lib/modules/custom-filters/models/filter-number-types.enum';
import { DateCategory } from 'libs/visual-lib/src/lib/modules/custom-filters/models/date-category-model';
import { IDoesFilterPassParams, IFilterAngularComp, IFilterParams } from 'libs/visual-lib/src';

@Directive()
export abstract class DateCategoryFilterComponent implements IFilterAngularComp, AfterViewInit {

  private static DEFAULT_FORM: DateForm = {
    year: null,
    month: null,
    day: null,
    hour: null,
    minute: null,
    second: null,
    period: dayPeriod.AM,
    dayOfWeek: null,
    daypart: null
  };
  protected filterLabels = FilterLabels;
  public filterType: FilterTypes['number'] = FilterNumberTypes.EQUALS;
  public filterTypes = [ 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange' ];
  public form: DateForm = {};
  protected formRangeEnd: DateForm = {};
  protected params: IFilterParams;
  private _active = false;
  private _model: DateModel;
  private _modelRangeEnd: DateModel;

  // Focuses on the month element upon initialization of the filter controller
  abstract ngAfterViewInit(): void;


  /**
   * Checks a single row, as passed to it, to see if it falls inside of the specified filter criteria.
   *
   * @param {IDoesFilterPassParams} params A IDoesFilterPassParams object containing a reference to a single cell.
   * @returns {boolean} A boolean variable indicating if the row passes the filter criteria or not.
   *
   */
  abstract doesFilterPass(params: IDoesFilterPassParams): boolean;

  constructor() {
    _.assign(this.form, _.clone(DateCategoryFilterComponent.DEFAULT_FORM));
    _.assign(this.formRangeEnd, _.clone(DateCategoryFilterComponent.DEFAULT_FORM));
  }

  
  agInit(params: IFilterParams): void {
    this.params = params;
  }

  /**
   * Activates the filter after verifying the entered parameters.
   */
  public applyFilter(): void {
    // Activates the filter if the criteria is valid
    this._model = new DateModel(this.form);
    this._modelRangeEnd = new DateModel(this.formRangeEnd);
    const isValid = this.filterType === FilterNumberTypes.IN_RANGE ? (this._model.isValid() && this._modelRangeEnd.isValid()) : this._model.isValid();
    if (isValid) {
      this._active = true; // Updates the filter status
      this.params.filterChangedCallback(); // Applies the filter to the grid
    }
  }

  /**
   * Deactivates the filter, and resets the filter form to the default state.
   */
  public clearFilter(): void {
    // Resets filter form
    this.setFilterType(FilterNumberTypes.EQUALS);
    this.setForm();
    this.setFormRangeEnd();
    this._model = new DateModel(this.form); //apply empty filter
    this._modelRangeEnd = new DateModel(this.formRangeEnd); //apply empty filter

    // Deactivates filter
    this._active = false; // Updates the filter status
    this.params.filterChangedCallback(); // Triggers the filter to be removed from the grid
  }

  /**
   * Gets the filter type.
   *
   * @returns {FilterTypes['number']} Returns a FilterTypes object indicating the currently selected filter type.
   *
   */
  public getFilterType(): FilterTypes['number'] {
    return this.filterType;
  }

  /**
   * Returns the date model.
   *
   * @returns {DateCategory} The date-category-filter model object based on the filter form.
   */
  public getModel(): DateCategory {
    return { model: this._model, modelRangeEnd: this._modelRangeEnd, filterType: this.filterType }; //FilterType is here to be restored from TableState along with the value
  }

  /**
   * Returns the date form.
   *
   * @returns {DateForm} The date form object based on the filter form.
   */
  public getForm(): DateForm {
    return this.form;
  }

  /**
   * Returns the end of range date form used for date range filter type.
   *
   * @returns {DateForm} The date form object based on the filter form.
   */
  public getFormRangeEnd(): DateForm {
    return this.formRangeEnd;
  }

  /**
   * Indicates if the filter is currently active and applied to the grid.
   *
   * @returns {boolean} A boolean variable indicating if the filter is applied.
   *
   */
  public isFilterActive(): boolean {
    return this._active;
  }

  /**
   * Set the filter type.
   *
   * @param {FilterTypes['number']} type The filter type to be set.
   */
  public setFilterType(type: FilterTypes['number']): void {
    this.filterType = type;
  }

  /**
   * Sets the date model to contain the specified variables. If a variable is not explicitly
   * included in the model object, the original value will remain.
   *
   * @param {DateCategory} model A date-category-filter model object to replace the current date model.
   *
   */
  public setModel(model: DateCategory): void {
    if(model && model.filterType) {
      this.filterType = model.filterType;
    }
    if (!this._model && model?.model) {
      //this._model starts as undefined and cannot be merged
      this._model = new DateModel(model?.model);
    } else {
      _.merge(this._model, new DateModel(model?.model));
    }
    if (!this._modelRangeEnd && model?.modelRangeEnd) {
      //this._modelRangeEnd starts as undefined and cannot be merged
      this._modelRangeEnd = new DateModel(model?.modelRangeEnd);
    } else {
      _.merge(this._modelRangeEnd, new DateModel(model?.modelRangeEnd));
    }
    this.fillForm(this.form, model?.model);
    this.fillForm(this.formRangeEnd, model?.modelRangeEnd);
    this.applyFilter();
  }

  /**
   * Sets the date form to contain the specified variables. If a variable is not explicitly
   * included in the model object, the original value will remain.
   *
   * @param {DateForm} form A date model object to replace the current date model.
   */
  public setForm(form: DateForm = _.clone(DateCategoryFilterComponent.DEFAULT_FORM)): void {
    _.assign(this.form, form);
  }

  /**
   * Sets the range end date form used for date range filter type to contain the specified variables. If a variable is not explicitly
   * included in the model object, the original value will remain.
   *
   * @param {DateForm} form A date model object to replace the current date model.
   *
   */
  public setFormRangeEnd(form: DateForm = _.clone(DateCategoryFilterComponent.DEFAULT_FORM)): void {
    _.merge(this.formRangeEnd, form);
  }

  /** passing undefined/null will clear form */
  private fillForm(form: DateForm, data: DateModel) {
    form.year = data?.year;
    form.month = data?.month;
    form.day = data?.day;
    form.hour = data?.hour;
    form.period = data?.period;
    form.dayOfWeek = data?.dayOfWeek;
    form.daypart = data?.daypart;
  }
}

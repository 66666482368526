<h3 mat-dialog-title>{{ model.op | capitalize }} Filterset</h3>

<mat-dialog-content>

  <mat-form-field class="full-width">
    <input
      matInput
      type="text"
      placeholder="Filterset Name"
      [(ngModel)]="model.name"
      #name="ngModel"
      maxlength="50"
      minlength="3"
      required
    />
    <mat-hint [ngStyle]="{color: 'red'}" *ngIf="model.name.trim().length === 0 && model.name.length !== 0">
      Input can not contain white space only.
    </mat-hint>
    <mat-error *ngIf="model.name.length < 3">
      Filterset names must be at least three characters.
    </mat-error>
    <mat-error *ngIf="model.name.length > 50">
      Filterset names may not exceed 50 characters.
    </mat-error>
  </mat-form-field>

  <span class="text-warn" *ngIf="lwhEnabled">Saved Filter Sets do not support "Locations with History." Stores will be stored as a static list.</span>

  <div *ngFor="let fs of model.filterModels" class="filter">
    <strong>{{ fs.name }} </strong>
    <mat-chip [ngClass]="fs.include ? 'include' : 'exclude'">{{ fs.desc }}</mat-chip>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close class="secondary-button">Cancel</button>
  <button mat-raised-button (click)="save()" [disabled]="!name.valid || (model.name.trim().length === 0 && model.name.length !== 0)" color="accent">Save</button>
</mat-dialog-actions>



import { CmsField, CmsFieldJson } from '@siq-js/cms-lib';
import { guid, Serializable } from '@siq-js/core-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ComparisonGroup, ComparisonGroupJson } from 'app/siq-applications/modules/promo/models/comparison-group';
import { SourceOfVolumeParam } from 'app/siq-applications/modules/promo/models/interfaces';

export interface SourceOfVolumeJson {
  comparisonGroup: ComparisonGroupJson;
  dimension: CmsFieldJson;
  id: string;
  isValid: boolean;
}
export class SourceOfVolume implements Serializable<SourceOfVolumeJson> {
  public comparisonGroup: ComparisonGroup;
  public oldComparisonGroup: ComparisonGroup;
  public dimension: CmsField;
  public oldDimension: CmsField;
  public id: string;
  public isValid: boolean;
  public canDrill = true;

  constructor(json?: SourceOfVolumeJson) {
    this.isValid = json?.isValid || false;
    this.id = json?.id || guid();
    if (json?.dimension) {
      const cmsConfig = CmsService.get();
      this.dimension = cmsConfig.findEntity<CmsField>(json.dimension.id);
      this.canDrill = !!this.dimension.filter
    }
    if (json?.comparisonGroup) {
      this.comparisonGroup = new ComparisonGroup(json.comparisonGroup);
    }
  }

  public setComparisonGroup(cg: ComparisonGroup) {
    this.comparisonGroup = cg;
  }

  public setDimension(dim: CmsField) {
    this.dimension = dim;
  }

  public toJson(): SourceOfVolumeJson {
    return {
      dimension: this.dimension.toJson(),
      comparisonGroup: this.comparisonGroup.toJson(),
      id: this.id,
      isValid: this.isValid
    };
  }

  public toParam(): SourceOfVolumeParam {
    return {
      sovId: this.id,
      comparisonGroupId: this.comparisonGroup.id,
      dimension: UtilsService.paramify(this.dimension)
    };
  }
}

import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { GroupedArray, GroupedArrayFunctions, WeekEndingDay } from '@siq-js/core-lib';
import { BaseSiqComponent, NotificationService } from '@siq-js/angular-buildable-lib';
import { NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { QueryModeModalConfig, QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { ReportBuilderFormFilterComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form-filter/report-builder-form-filter.component';
import { ReportBuilderQueryModeModalComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-query-mode-modal/report-builder-query-mode-modal.component';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { DimensionColumn } from 'app/siq-applications/modules/report-builder/models/form/dimension-column.model';
import { ReportBuilderColumnType } from 'app/siq-applications/modules/report-builder/models/form/enums';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { ReportBuilderConfig } from 'app/siq-applications/modules/report-builder/models/report-builder-config';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { DragulaService } from 'ng2-dragula';
import { BehaviorSubject, mergeMap, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { DateSelectionComponent } from 'app/siq-forms/modules/dates/components/date-selection/date-selection.component';
import { DateRangeInterface, DateSelectionConfig } from 'app/siq-forms/modules/dates/models/interfaces';
import { FilterService } from 'app/filter/services/filter.service';
import { SameStoreSalesSelectionComponent } from 'app/siq-forms/modules/same-store-sales/components/same-store-sales-selection/same-store-sales-selection.component';
import { SameStoreSalesService } from 'app/siq-forms/modules/same-store-sales/services/same-store-sales.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface TemplateModel {
  alreadyAddedMap: { [key in string]: boolean };
  dimExpanded: boolean;
  metricExpanded: boolean;
  running: boolean;
  valid: boolean;
  yoyLocked: boolean;
  searchStr: string;
  displayedMetrics: GroupedArray<CmsMetric>;
  displayedFields: GroupedArray<CmsField>;
}

@Component({
  selector: 'siq-report-builder-form',
  templateUrl: './report-builder-form.component.html',
  styleUrls: ['./report-builder-form.component.scss']
})

export class ReportBuilderFormComponent extends BaseSiqComponent implements OnInit, OnDestroy {

  public readonly columnTypeEnums = ReportBuilderColumnType;

  @ViewChild(DateSelectionComponent) dateSelectionComponent: DateSelectionComponent;
  @ViewChildren('dimDropdowns') dimDropdowns: QueryList<MatExpansionPanel>;
  @ViewChild('filterDropdown') filterDropdown: MatExpansionPanel;
  @ViewChild('sameStoreSalesComponent', { static: false }) sameStoreSalesComponent: SameStoreSalesSelectionComponent;
  public formData: ReportBuilderFormData;
  public namePlaceholder = Activity.ActivityPlaceholder;

  public dateSelectionConfig: DateSelectionConfig = {
    primaryDate: {
      rangeMode: true,
      allowShortcuts: true,
      shortcutPosition: 'left',
      weekEndingDay: true
    }
  };

  @ViewChild('globalFilter') globalFilter: ReportBuilderFormFilterComponent; // custom FilterGroupComponent for global filters
  @ViewChildren('metricDropdowns') metricDropdowns: QueryList<MatExpansionPanel>;

  public model: TemplateModel;

  @Input() parent: ReportBuilderResultComponent;
  @ViewChild('queryMode') queryMode: QueryModeComponent; // component for controlling current schema

  public queryModeModalConfig: QueryModeModalConfig = {
    component: ReportBuilderQueryModeModalComponent,
    dataGetter: () => this.formData,
    diffFn: (schema: string) => !this.formData.isValidSchema(schema)
  };
  @Input() schemaController$: BehaviorSubject<string>;
  public satPickerInit = true;

  private validate$: Subject<void> = new Subject<void>();

  constructor(
    public config: ReportBuilderConfig,
    private reportBuilderService: ReportBuilderService,
    private utilsService: UtilsService,
    private dragulaService: DragulaService,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanelService: MixpanelService,
    private asyncStatusService: AsyncStatusService,
    private cmsService: CmsService,
    private notificationService: NotificationService,
    private datesService: DatesService,
  ) {
    super();

    this.model = {
      alreadyAddedMap: {},
      dimExpanded: false,
      metricExpanded: false,
      running: false,
      valid: false,
      yoyLocked: false,
      searchStr: '',
      displayedFields: [],
      displayedMetrics: []
    };
  }

  // Adds a column to the form - either through click or drag
  public addColumn(entity: CmsField | CmsMetric, insertIndex?: number) {
    this.formData.addColumn(entity, insertIndex);
    this.validateForm();
  }

  public globalDateChanged(dates: DateRangeInterface): void {
    if (dates) {
      const ddChanged =
        dates.dynamicBegin !== this.formData.globalDateRange?.dynamicBegin ||
        dates.dynamicEnd !== this.formData.globalDateRange?.dynamicEnd;
      this.formData.globalDateRange = dates;
      this.updateGlobalDateRange(ddChanged);
      this.formData.isDateRangeValid = dates.isDateRangeValid;
      this.model.valid = this.formData.isRunnable();
      if (dates.isDateRangeValid) { // This block will also handle initialization of SSS during cloning/editing
        SameStoreSalesService.setDates(dates);
        if (SameStoreSalesService.getChecked()) {
          this.sameStoreSalesComponent.apply(true, true);
        }
      }
    }
  }

  public weekEndingDayChanged(we: WeekEndingDay) {
    if (we) {
      this.formData.weekEndingday = we;
    }
  }

  public handleClick($event: MouseEvent) {
    let el = $event.srcElement as Element;
    while (el) {
      if (el.classList.contains('filter-section')) {
        return;
      } else {
        el = el.parentElement;
      }
    }

    this.filterDropdown.close();
  }

  // Calling this fn without params creates a blank form
  public importFormData(formData: ReportBuilderFormData = this.reportBuilderService.createForm()) {
    this.model.alreadyAddedMap = {};
    this.formData = formData;
    SameStoreSalesService.setChecked(formData.sssChecked);
    SameStoreSalesService.schema = formData.schema;

    setTimeout(() => {
      if (formData) {
        this.formData.setWeekEndingDay$(); // This has to be done in settimeout otherwise it will trigger the datepicker init() before the correct initModel from new formData get passed in
        this.globalFilter.setModel(formData.globalFilters);
      }
      this.validateForm();
    });

    this.formData.columns.filter(c => c.type === AppSiqConstants.types.DIMENSION)
      .forEach(c => this.model.alreadyAddedMap[c.ref.id] = true);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    // dragula needs to be explicitly destroyed to prevent any errors when re-initializing this component
    this.validate$.complete();
    this.dragulaService.destroy('bag');
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ cms: true, envConfig: true });
    this.setup();
    NavSecondaryService.close();
  }

  // Performs a search and updates the model accordingly
  public search(str = '') {
    // Blank search => display all
    this.model.searchStr = str;
    const searchStr = str.toLowerCase();
    const cmsConfig = CmsService.get();

    this.model.displayedMetrics = GroupedArrayFunctions.filter(cmsConfig.metricsOrder, metric => {
      if (!metric.active) return false;
      return metric.display.toLowerCase().includes(searchStr);
    });

    const schema = this.schemaController$.getValue();
    this.model.displayedFields = GroupedArrayFunctions.filter(cmsConfig.fieldsOrder, field => {
      if (!field.active) return false;
      return CmsService.isValidField(field, schema) && field.display.toLowerCase().includes(searchStr);
    });

    setTimeout(() => {
      this.toggleAllPanels(ReportBuilderColumnType.DIMENSION, searchStr !== '');
      this.toggleAllPanels(ReportBuilderColumnType.METRIC, searchStr !== '');
    });
  }

  // Starts off the process of creating a report. Will take either 2 or 3 steps:
  public submit() {
    let reportId: string;

    this.model.running = true;
    this.reportBuilderService.createReport(this.formData)
      .pipe(
        mergeMap(json => {
          reportId = json['appActivityId'];
          return this.reportBuilderService.initializeSheetMetadata(this.formData, json.sheets[0]);
        })
      )
      .subscribe(() => this.router.navigate(['app/report-builder']));
  }

  // Expands or collapses all child dim or metric panels
  public toggleAllPanels(type: ReportBuilderColumnType, open: boolean) {
    let panels: QueryList<MatExpansionPanel>;

    switch (type) {
      case ReportBuilderColumnType.DIMENSION:
        panels = this.dimDropdowns;
        this.model.dimExpanded = open;
        break;
      case ReportBuilderColumnType.METRIC:
        panels = this.metricDropdowns;
        this.model.metricExpanded = open;
        break;
      default:
        console.warn('No panel specified!');
        return;
    }

    panels.forEach(panel => {
      open ? panel.open() : panel.close();
    });
  }

  // Updates any date-range sensitive fields/columns
  public updateGlobalDateRange(ddChanged: boolean) {
    this.checkLockedYOY();
    if (
      this.formData.globalDateRange.isDateRangeValid &&
      (!this.satPickerInit || DatesService.isDynamic(this.formData.globalDateRange))
    ) {
      if (this.formData.updateYOY(ddChanged)) {
        this.notificationService.info('All comparative date ranges refreshed', 'Global Date Range Changed');
      }
    }
    // Do not trigger updateYOY if this is the initial loading of the date selector
    this.satPickerInit = false;
    this.formData.updateTimeAggregates();
    this.validateForm();
  }

  public updateGlobalFilters(filters: FilterSelection[]) {
    this.datesService.updateCommunalDateRange(this.schemaController$.getValue(), this.formData.globalFilters, filters);
    this.formData.globalFilters = filters;
    // SSS, check store id filters matches with sss
    const storeIdFilters = filters.filter(f => f.field.field === SameStoreSalesService.STORE_ID);
    const sssRetailers = Array.from(SameStoreSalesService.openStoreIds.keys());
    if (sssRetailers.length && storeIdFilters.length !== sssRetailers.length) { // in MRV if user removes any storeId filters, uncheck
      SameStoreSalesService.setChecked(false);
    }
  }

  // This function checks whether all dim or metric expansion panels are expanded/collapsed and updates the parent panel
  public updatePanelExpandedModel(type: ReportBuilderColumnType) {
    let panels: QueryList<MatExpansionPanel>;
    let modelKey: string;

    switch (type) {
      case ReportBuilderColumnType.DIMENSION:
        panels = this.dimDropdowns;
        modelKey = 'dimExpanded';
        break;
      case ReportBuilderColumnType.METRIC:
        panels = this.metricDropdowns;
        modelKey = 'metricExpanded';
        break;
      default:
        console.warn('No panel specified!');
        return;
    }

    const allExpanded = panels.reduce((flag, p) => flag && p['expanded'], true);
    const allClosed = panels.reduce((flag, p) => flag && !p['expanded'], true);

    if (allExpanded) {
      this.model[modelKey] = true;
    }

    if (allClosed) {
      this.model[modelKey] = false;
    }
  }

  // Trigger the ReportBuilderFormData's validation process
  public validateForm() {
    this.validate$.next();
  }

  private checkLockedYOY() {
    this.model.yoyLocked = !!this.formData.columns.find(c => c.ref instanceof CmsField && ReportBuilderService.isDimYOYLocked(c.ref));
  }

  // Performs the operations necessary to gracefully transition from an ng2-dragula drop event to
  // successfully updating the ReportBuilderFormData data structure (with the correct index)
  private dragAdd(el: HTMLElement, containerEl: HTMLElement) {
    const children = containerEl.children as HTMLCollection;
    let relativeIndex: number;

    // Iterate through the target container's children
    // At this point any columns already synced with ReportBuilderFormData will have the 'column-group-class'
    // But there will be always ONE element (the one that was dropped via dragula), with the 'report-builder-list-item' class
    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains('report-builder-list-item')) {
        relativeIndex = i; // Note the index
        break;
      }
    }

    // Add the column
    const entity = CmsService.get().findEntity<CmsField | CmsMetric>(el.dataset.key);
    this.addColumn(entity, relativeIndex);

    // At this point, the template will detect the change to ReportBuilderFormData, and draw a new column
    // At the index that the dragula element was dropped in, so we no longer need the dragula element
    el.remove();
  }

  private setup() {
    // setup drag & drop for dims/facts/filters
    this.setupDragula();

    if (!this.parent) {
      this.importFormData();
    }
    // this seems can be deleted
    this.datesService.datePickerSchema$.next(DatesService.getActiveSchemas(this.formData?.schema, this.formData?.globalFilters));

    // form validation sub
    this.validate$.pipe(
      debounceTime(100),
      takeUntil(this.unsub$)
    )
      .subscribe(() => {
        this.model.alreadyAddedMap = {};
        for (let col of this.formData.columns) {
          if (col instanceof DimensionColumn) {
            this.model.alreadyAddedMap[col.ref.id] = true;
          }
        }

        this.checkLockedYOY();
        this.model.valid = this.formData.isRunnable();
      });

    // if there is no parent (ie this is NOT a sheet), just use the QueryModeComponent in the template as the schemaController
    if (!this.parent) {
      this.schemaController$ = this.queryMode.schema$;
    }

    this.schemaController$
      .pipe(
        filter(schema => !!schema),
        takeUntil(this.unsub$)
      )
      .subscribe(schema => {
        SameStoreSalesService.setSchema(schema)
        this.datesService.datePickerSchema$.next(DatesService.getActiveSchemas(schema, this.formData.globalFilters));

        this.search(); // apply blank search - get all fields/metrics
        // ICE-104: Alert user on which dimensions & filters are being removed when report criteria changes
        if (this.parent?.activeSheet) {
          if (this.parent.activeSheet.status === ActivityStatus.ALERT || (this.parent.activeSheet.draft && this.parent.report.accessGroupChanged)) {
            if (!this.formData.isValidSchema(schema)) {
              this.utilsService.openModal(
                ReportBuilderQueryModeModalComponent,
                {
                  data: _.cloneDeep(this.formData),
                  schema: schema,
                  userGroupChanged: true
                } as QueryModeModalData
              );
            }
          }
        }
        this.formData && this.formData.changeSchema(schema); // incompatible form parameters need to be pruned
        this.purgeFilterValues();
        this.validateForm();
      });

  }

  // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
  private purgeFilterValues() {
    FilterService.purgeFilterValues(this.globalFilter, this.formData.globalFilters);
  }

  // Sets up all drag-and-drop functionality in the component
  private setupDragula() {
    this.dragulaService.createGroup('bag', {
      // If a dimension or metric element is dragged from their respective sections, copy the element instead of moving it
      copy: (el, src: HTMLElement): boolean => src.dataset.id === 'dim-bag' || src.dataset.id === 'metric-bag',
      moves: (el: HTMLElement, container: HTMLElement): boolean => {
        // Disable moving the placeholder column
        const classList: DOMTokenList = el.classList;
        if (classList.contains('placeholder')) {
          return false;
        }

        // Nothing can move out of the filters bag (even though there should never be anything persisting there)
        if (container.dataset.id === 'filters-bag') {
          return false;
        }

        // Dimensions that have already been added as a column cannot be re-dragged from the dimension section (IE no dupes)
        if (container.dataset.id === 'dim-bag') {
          const dimKey = el.dataset.key;
          if (this.model.alreadyAddedMap[dimKey]) return false;
        }
        return true;
      },
      accepts: (el: HTMLElement, target: HTMLElement, src, sibling): boolean => {
        // Things may only be dropped into the filters bag if they have a filterPair
        if (target.dataset.id === 'filters-bag') {
          const id: string = el.dataset.key;
          const entity = CmsService.get().findEntity<CmsField>(id);
          return entity.filter && entity.filter !== 'DATE';
        }

        // Lock the placeholder column as the last element by disabling anything else being moved to the end
        if (target.dataset.id === 'main-bag' && sibling === null) {
          return false;
        }

        // Dimensions and metrics sections CANNOT accept drag events
        if (target.dataset.id === 'dim-bag' || target.dataset.id === 'metric-bag') {
          return false;
        }

        return true;
      }
    });

    this.dragulaService.drop('bag').pipe(takeUntil(this.unsub$)).subscribe(context => {
      // Context is [bag id, element, target container, src container, ?]
      const target = context.target as HTMLElement;
      if (!target) {
        return;
      }

      const el = context.el as HTMLElement;
      const source = context.source as HTMLElement;

      // Currently, the only acceptable behavior is if something is dragged INTO the main-bag, or
      // if the main-bag items are re-ordered. In any case, the "target" will always be the main-bag
      if (target.dataset.id === 'main-bag') {
        switch (source.dataset.id) { // Do different things depending on *WHERE* the element was dragged from
          case 'main-bag': // Re-ordering columns
            this.updateColumnIndices();
            break;
          case 'dim-bag': // Dragging a dimension to main-bag
            this.dragAdd(el, target);
            break;
          case 'metric-bag': // Dragging a fact to main-bag
            this.dragAdd(el, target);
            break;
        }
      } else if (target.dataset.id === 'filters-bag') {
        const field = CmsService.get().findEntity<CmsField>(el.dataset.key);
        target.innerHTML = ''; // Remove the filter element that was just added
        this.globalFilter.addFilter(field); // Trigger internal logic to add global filter
      }
    });
  }

  // Updates the form's columns' indices by querying the DOM and checking the order of the children
  private updateColumnIndices() {
    const tableContainer = document.querySelector('.table-section');
    const newOrder = [];
    for (let i = 0; i < tableContainer.children.length; i++) {
      const el = tableContainer.children[i] as HTMLElement;
      if (el.dataset.key) {
        newOrder.push(el.dataset.key);
      }
    }

    this.formData.updateIndices(newOrder);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'bytes'
})
export class BytesPipe extends DecimalPipe implements PipeTransform {

  private readonly constant = 1024;
  private readonly measures: string[] = ['KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: any, measure?: any): any {
    /*
     * Taken from SIQV2: https://github.com/SwiftIQ/dashboard/blob/b8c3b3aca6e0747188204a54c154e5397229f2d5/filter/bytes/bytes.js
     */
    let count = 0;
    let denominator = 1;
    let outputText = '';
    let outputValue: number;

    if (measure) {
      count = this.measures.indexOf(measure.toString().toUpperCase()) + 1;

      if (count > 0) {
        for (let i = 0; i < count; i++) {
          denominator = denominator * this.constant;
        }

        outputValue = bytes / denominator;
        outputText = new DecimalPipe('en-US').transform(outputValue, '1.3-3') + measure;
      } else {
        outputText = new DecimalPipe('en-US').transform(bytes) + ' bytes';
      }

    } else {

      // No measure specified
      let again = true;
      while (again) {
        denominator = 1;
        count += 1;

        for (let n = 0; n < count; n++) {
          denominator = denominator * this.constant;
        }

        outputValue = bytes / denominator;

        if (outputValue < 100) {
          again = false;
        } else if (count > 100) {
          again = false;
        }
      }

      if (count - 1 > this.measures.length) {
        outputText = new DecimalPipe('en-US').transform(bytes) + ' bytes';
      } else {
        outputText = new DecimalPipe('en-US').transform(outputValue) + ' ' + this.measures[count - 1];
      }
    }

    return outputText;
  }

}

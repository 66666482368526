import { Component, Injector } from '@angular/core';
import { GridComponent } from 'libs/visual-lib/src/lib/modules/grid/components';
import { CmsMetric } from '@siq-js/cms-lib';
import { AggregationType } from 'libs/visual-lib/src/lib/modules/grid';
import * as _ from 'lodash';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { filter, takeUntil } from 'rxjs';
import { IStatResponse } from 'libs/visual-lib/src/lib/models';
import { CellRendererService } from 'libs/visual-lib/src/lib/modules/grid/services/cell-renderer/cell-renderer.service';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'siq-js-pinned-fact-cell',
  templateUrl: './pinned-fact-cell.component.html',
  styleUrls: ['./pinned-fact-cell.component.scss']
})
export class PinnedFactCellComponent extends BaseSiqComponent implements AgRendererComponent {

  public asyncResultsPopulated: boolean;
  public canShowValue: boolean;
  public dependsOnAsyncMetrics: boolean;
  public isAsyncTotallingType: boolean;
  public isIncrementalFact: boolean;
  public table: GridComponent;
  public tooltip: string = null;
  public totallingType: string;
  public value: string;
  private _params: any;

  constructor(private injector: Injector) {
    super();
    this.table = injector.get(GridComponent);
  }

  agInit(params: any) {
    this._params = params;
    this.table.asyncTotalsComponent.currentResults$.pipe(
      filter(data => !! data),
      takeUntil(this.unsub$)
    ).subscribe(activity => {
      const sr: IStatResponse = activity.jobs.length ? activity.jobs[0].response : null;
      this.asyncResultsPopulated = !!sr?.valuesMatrix.length;

      if (params.api.isPivotMode() && !params.colDef.pivotValueColumn) return '';

      this.setDependentProperties();
      if (this.dependsOnAsyncMetrics && params.api.isPivotMode()) {
        const primaryColId = this._params.colDef.pivotValueColumn?.getColId();
        const primaryColIdSegments = primaryColId.split('|');
        this._params['_asyncTotalsSpecific'] = {
          curr: this._params.api.getPivotResultColumn(this._params.colDef.pivotKeys, primaryColIdSegments[0]),
          prev: this._params.api.getPivotResultColumn(this._params.colDef.pivotKeys, primaryColIdSegments[1])
        };
        this.value = <string>CellRendererService.getFormattedValue(this._params, this._params.data, this.dependsOnAsyncMetrics);
      } else {
        const _v = this._params.data[this._params.colDef.colId];
        if (typeof _v === 'string') {
          // This value has already been run through the formatter resulting in a string
          this.value = _v;
        } else {
          const vObj = typeof _v === 'object' && !_.isNil(_v) ? {value: _v.val} : {value: _v};
          this.value = params.colDef.valueFormatter(vObj);
        }
      }

      this.canShowValue = this.showValue();
    });

    const pivoted = params.api.isPivotMode();

    this.setDependentProperties();

    // Set constants that are referenced in code below
    const metaKey = this.getMetaKey();
    const fact = this.getCmsMetric(metaKey);

    // TODO: Incrementals + CMS integration
    // INCREMENTAL FACTS (held in INC_ENUMS) have totallingType: AS, but are not treated as async here. Their values can be shown immediately.
    // this.isIncrementalFact = (<any>Object).values(INC_ENUMS).includes(fact.id);

    if (!fact.canAgg() && fact.id !== 'RB_PERCENT_DELTA' && !this.isIncrementalFact) return this.value = '';

    // Display value
    if (pivoted) {
      if (!params.data) return;
      const rawValue = params.data[params.colDef.colId];
      // Only run rawValue through valueFormatter if it is not already of type 'string'
      this.value = typeof rawValue === 'string' ? rawValue : params.colDef.valueFormatter({value: rawValue});
    } else {
      this.value = params.valueFormatted;
    }

    if (fact.aggType === AggregationType.WEIGHTED_AVERAGE && params.value && params.value.weight) {
      const numFormatter = GridService.getVisualType('VOLUME').formatter;
      this.value += '*';
      this.tooltip = numFormatter({value: params.value.weight}) + ' Occurrences';
    }

    this.canShowValue = this.showValue();
  }

  refresh(): boolean {
    return true;
  }

  showValue(): boolean {
    /*
      Many factors can contribute to whether or not the value should be shown in this cell.
      The html template was getting very unwieldy, so moving the logic from there to here.
     */
    let show: boolean;
    if (!this.isAsyncTotallingType && !this.dependsOnAsyncMetrics) {
      return true;
    }

    // Now apply each of these checks sequentially to arrive at the ultimate result
    show = this.isAsyncTotallingType || this.dependsOnAsyncMetrics;
    show = show && (this.asyncResultsPopulated || this.isIncrementalFact);
    show = show && !!this.value;
    return show;
  }

  private getCmsMetric(metaKey: string): CmsMetric {
    return this.table.colDefMeta.get(metaKey).ref as CmsMetric;
  }

  private getMetaKey(): string {
    return (this._params.api.isPivotMode() && this._params.colDef.pivotValueColumn) ? this._params.colDef.pivotValueColumn.colId : this._params.colDef.colId;
  }

  private setDependentProperties() {
    // Check the metadata map if the fact can be aggregated
    const metaKey = this.getMetaKey();
    const fact = this.getCmsMetric(metaKey);
    this.dependsOnAsyncMetrics = CellRendererService.setDependsOnAsyncMetrics(fact, this._params);
    this.isAsyncTotallingType = fact.aggType === AggregationType.ASYNC;
  }

}

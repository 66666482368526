<siq-app-header [config]="config"></siq-app-header>

<!-- Spinning wheel -->
<div class="loading-overlay" *ngIf="showSpinner"><mat-progress-spinner color="accent" mode="indeterminate" ></mat-progress-spinner></div>

<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="statusEnums.ALERT">

    <siq-js-alert-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="errorAndInvalidOptionMenuItems"
      [reportActivityName]="applicationName"
    ></siq-js-alert-overlay>

  </div>

  <div *ngSwitchCase="statusEnums.ERROR">

    <siq-js-error-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [appErrorMessage]="customError ?? sheetActivity.errorsDetails[0]"
      [reportActivityName]="applicationName"
      [menuItems]="errorAndInvalidOptionMenuItems"
    ></siq-js-error-overlay>

  </div>

  <ng-container *ngSwitchDefault>

    <!-- Query mode "Analysis Mode" -->
    <siq-query-mode #queryMode [disabled]="true"></siq-query-mode>
    <!-- Header with "Option" -->
    <siq-activity-results
      [suppressOptions]="false"
      [hideHeader]="false"
      [isReady] = "ready$ | async"
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="customOptionMenuItems"
    >
    </siq-activity-results>

    <div *ngIf="ready$ | async">
      <div class="xy-container">

        <div class="item">
          <div class="select-wrapper">
            <mat-form-field color="accent">
              <mat-label>Y Axis Fact</mat-label>
              <mat-select [value]="yAxisFact$.value" (selectionChange)="setYAxisFact($event)">
                <ng-container *ngFor="let yFact of activeDatasetMetrics$.value">
                  <mat-option *ngIf="yFact.id !== xAxisFact$.value.id" [value]="yFact">
                    {{ yFact.display }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-chart-wrapper">
            <siq-js-bar-column
              #assortmentCharts
              *ngIf="columnParamsY"
              [config]="barColumnConfigY"
              [data]="columnParamsY"
              [processor]="processorColumnChartY"
            ></siq-js-bar-column>
          </div>
        </div>

        <div class="item">
          <div class="select-wrapper">
            <mat-form-field color="accent">
              <mat-label>X Axis Fact</mat-label>
              <mat-select [value]="xAxisFact$.value" class="dev-test" (selectionChange)="setXAxisFact($event)">
                <ng-container *ngFor="let xFact of activeDatasetMetrics$.value">
                  <mat-option *ngIf="xFact.id !== yAxisFact$.value.id" [value]="xFact">
                    {{ xFact.display }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-chart-wrapper">
            <siq-js-bar-column
              #assortmentCharts
              *ngIf="columnParamsX"
              [config]="barColumnConfigX"
              [data]="columnParamsX"
              [processor]="processorColumnChartX"
            ></siq-js-bar-column>
          </div>
        </div>

      </div>

      <!-- This graph only here for demo purpose; Delete before merging -->
      <div class="scatter-container">
        <siq-js-scatter-with-line
          #assortmentCharts
          *ngIf="scatterParams"
          [data]="scatterParams"
          [processor]="processorScatterWithLine"
        ></siq-js-scatter-with-line>
      </div>

    </div>

    <mat-form-field appearance="fill" *ngIf="ready$ | async" color="accent">
      <mat-label>Select Metric</mat-label>
      <mat-select [(value)]="selected" (selectionChange)="onSelectMetric($event.value)">
        <mat-option *ngFor="let val of dropdownValues" [value]="val">
          {{val}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <!-- the siq-js-grid *ngIf has to check both ready$ and gridParams due to activeDataset$ subscription; typically would only check ready$ -->
    <siq-js-grid
      *ngIf="(ready$ | async) && gridParams"
      [config]="gridParams.gridVisualOptions.gridConfig"
      [data]="gridParams"
      [processor]="gridProcessor"
      (featureUsed)="gridFeatureUsed($event)"
    ></siq-js-grid>

  </ng-container>

</ng-container>

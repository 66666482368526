<div id="tabBody" class="ag-tab-body">
  <div class="ag-filter">
    <div>
      <div>
        <select class="ag-filter-select" [(ngModel)]="filterType">
          <option *ngFor="let filterType of filterTypes" [value]="filterType">{{ filterLabels[filterType] }}</option>
        </select>
      </div>
      <div class="ag-filter-body" (keydown.enter)="applyFilter()">
        <div>
          <select #daypart class="ag-filter-select" [(ngModel)]="form.daypart">
            <option selected disabled>Daypart</option>
            <option *ngFor="let dp of dayparts; let index='index'" [value]="index">{{ dp }}</option>
          </select>
        </div>
      </div>
      <div class="ag-filter-apply-panel" id="applyPanel">
        <button type="button" id="clearButton" (click)="clearFilter()">Clear Filter</button>
        <button type="button" id="applyButton" (click)="applyFilter()">Apply Filter</button>
      </div>
    </div>
  </div>
</div>

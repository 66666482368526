<div class="app-container-wrapper app-header">
  <section class="app-container app-header-container">
    <h5 *ngIf="app" class="app-title">
      <a *ngIf="(params && params.id && !params.action) || showBack; else noLink;"
         [routerLink]="'/' + app.path">
        <mat-icon>arrow_back</mat-icon>
        {{ app.display }}
      </a>
      <ng-template #noLink>
        <span>{{ app.display }}</span>
      </ng-template>
    </h5>
    <ng-content></ng-content>
  </section>
</div>

<h1 mat-dialog-title>Delete User</h1>

<div mat-dialog-content>
  Are you sure you want to delete user {{ name }}?
</div>

<div mat-dialog-actions>
  <button
    mat-stroked-button
    mat-dialog-close
    [disabled]="submitting"
    color="accent"
    class="secondary-button"
  >
    Cancel
  </button>

  <button
    mat-raised-button
    (click)="remove()"
    [disabled]="submitting"
    color="warn"
    class="delete"
    cdkFocusInitial
  >
    Delete
  </button>
</div>

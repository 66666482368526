import { Routes, Route } from '@angular/router';
import { AuthGuard } from 'app/guards/auth-guard';
import { AccessGroupListComponent } from 'app/access-group/components/access-group-list/access-group-list.component';
import { AccessGroupFormComponent } from 'app/access-group/components/access-group-form/access-group-form.component';
import { AccessGroupAdminGuard } from 'app/guards/access-group-admin-guard';

let detailChildren = [
  {
    path: '',
    component: AccessGroupFormComponent,
    canActivate: [
      AccessGroupAdminGuard
    ]
  }
];

const accessGroupsRoute: Route = {
  path: 'user-groups',
  canActivate: [
    AuthGuard,
    AccessGroupAdminGuard
  ],
  data: {
    name: 'User Groups',
    icon: 'build'
  },
  children: [
    {
      path: '',
      component: AccessGroupListComponent
    },
    {
      path: ':id',
      children: [
        ...detailChildren,
        {
          path: 'form',
          children: [
            ...detailChildren
          ]
        }
      ]
    }
  ]
};

export const AccessGroupRoutes: Routes = [
  accessGroupsRoute
];

import { Component } from '@angular/core';
import { AgRendererComponent } from '@siq-js/visual-lib';
import { FiltersetsService } from 'app/filtersets/services/filtersets.service';
import { ICellRendererParams } from '@siq-js/visual-lib';
import { FiltersetInterface, FiltersetPayloadInterface } from 'app/filtersets/models/interfaces';
import * as _ from 'lodash';

@Component({
  selector: 'siq-name-renderer',
  templateUrl: './name-renderer.component.html',
  styleUrls: ['./name-renderer.component.scss']
})
export class NameRendererComponent implements AgRendererComponent {

  public filterSetName: string;
  public isDisplayOnly: boolean;
  private filtersetId: number;
  private iCellRendererParams: ICellRendererParams;

  constructor(
     private filtersetsService: FiltersetsService
  ) { }

  agInit(iCellRendererParams: ICellRendererParams) {
    this.filterSetName = iCellRendererParams.data.name;
    this.filtersetId = iCellRendererParams.data.id;
    this.isDisplayOnly = !iCellRendererParams.colDef.editable;
    this.iCellRendererParams = iCellRendererParams;
  }

  refresh(): boolean {
    return true;
  }

  public updateName() {
    this.iCellRendererParams.data.name = _.isEmpty(this.filterSetName) ? 'Untitled Filterset' : this.filterSetName;
    this.filtersetsService.saveFilterset(this.getPayLoadFromGridRowData(this.iCellRendererParams.data));
  }

  private getPayLoadFromGridRowData(data: any): FiltersetPayloadInterface {
    let thisFilterSets: FiltersetInterface[] = [];
    data.filterSets.forEach(f => {
      thisFilterSets.push(
        {
          id: f.id,
          filterType: f.filterType,
          values: f.values,
          outNull: f.outNull,
          inNull: f.inNull,
          include: f.include
        }
      );
    });

    return  <FiltersetPayloadInterface> {
      id: data.id,
      name: data.name,
      filterSets: thisFilterSets
    };
  }
}

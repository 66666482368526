import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SharingModalTabComponent } from 'app/activity/modules/sharing/components/sharing-modal-tab/sharing-modal-tab.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { forbiddenRegExpValidator } from 'app/siq-forms/validators/forbidden-regexp.directive';

@Component({
  selector: 'siq-share-report',
  templateUrl: './share-report.component.html',
  styleUrls: ['./share-report.component.scss'],
  providers: [{provide: SharingModalTabComponent, useExisting: ShareReportComponent}]
})
export class ShareReportComponent extends SharingModalTabComponent implements OnInit {

  @Input() limitedLengthTextConfig: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    sharingService: SharingService
  ) {
    super(sharingService);

    this.form = this.formBuilder.group({
      appId:        [null, Validators.required],
      activityId:   [null, Validators.required],
      name:         [null, forbiddenRegExpValidator(/[<>/"\?\\*:]/i, `Input can not contain special characters: < > / " ? \\ * :`, 'invalid-file-name')],
      message:      [null, forbiddenRegExpValidator(/^\s+$/, `Input can not be white space only.`, 'white-space-only')],
      recipients:   [null, Validators.required],
      deepLink:     [null, Validators.required]
    });
  }

  ngOnInit() {
    this.form.controls.activityId.setValue(_.toNumber(_.get(this.modalData.activity, 'id')));
    this.form.controls.appId.setValue(this.modalData.activity.getAppId());
    this.form.controls.deepLink.setValue(this.modalData.activity.getPath());
  }

}

import { Component, OnDestroy } from '@angular/core';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'siq-expand-all',
  templateUrl: './expand-all.component.html',
  styleUrls: ['./expand-all.component.scss']
})
export class ExpandAllComponent implements OnDestroy {

  public allFormPanelsExpanded = false;
  private ngUnsubscribe = new Subject<void>();

  constructor() {
    EmitterService.get('form-panels:all-forms-expanded')
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(resp => {
        this.allFormPanelsExpanded = resp;
    });
  }

  expandAll(expand?: boolean): void {
    EmitterService.get('form-panels:expand').emit(!!expand);
  }

  ngOnDestroy () {
    this.ngUnsubscribe.next();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudExportComponent } from 'app/core/modules/cloud-export/components/cloud-export.component';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CloudExportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    CloudExportComponent
  ],
  providers: [
    CloudExportService
  ]
})
export class CloudExportModule { }

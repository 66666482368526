import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CmsField } from '@siq-js/cms-lib';
import { AddFilterModalComponent } from 'app/filter/components/add-filter-modal/add-filter-modal.component';
import {
  EditFilterModalComponent,
  FilterModalData
} from 'app/filter/components/edit-filter-modal/edit-filter-modal.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { CmsService } from 'app/core/services/cms/cms.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs';
import { FilterService } from 'app/filter/services/filter.service';

export type SchemaController = string | BehaviorSubject<string>;
export interface AddFilterData {
  availableFilters: CmsField[];
  schemaController: SchemaController;
  isColumnFilter: boolean;
}

/**
 * This is the base component for modifying filter selections in a form or any other components
 * Only common functionality should belong here (ie adding/removing/editing a filter)
 * Any specific logic is best left to the components that extend this
 */
@Directive()
export abstract class BaseFilterGroupComponent extends BaseSiqComponent implements OnInit, OnDestroy {
  @Input() mapFn?: (fields: CmsField[]) => CmsField[]; // Optional mapping Fn to display specific filters for a component
  @Input() addFilterBtnDisplay?: string;
  @Input() schemaController: SchemaController;
  @Input() initModel: FilterSelection[]; // Initial model to render (this ONLY used as an input - there is NO 2-way binding)
  @Output() emitter: EventEmitter<FilterSelection[]> = new EventEmitter<FilterSelection[]>();

  public model$: BehaviorSubject<FilterSelection[]>;
  protected currentSchema: string; // internal model for keeping track of current schema key
  protected _isColumnFilter: boolean; // internal var that gets set by the derived class "FilterGroupComponent extends BaseFilterGroupComponent" to make the same value available here

  protected constructor(
    protected asyncStatusService: AsyncStatusService,
    protected utils: UtilsService
  ) {
    super();
  }

  async ngOnInit() {
    if (!this.schemaController) {
      return console.error('No schemaController specified!');
    }
    this.model$ = new BehaviorSubject<FilterSelection[]>(this.initModel || []);
    await this.asyncStatusService.isReady({ cms: true, envConfig: true });
    if (typeof this.schemaController === 'string') {
      this.currentSchema = this.schemaController;
    } else {
      this.schemaController
      .pipe(
        filter(schema => !!schema),
        takeUntil(this.unsub$)
      )
      .subscribe(newSchema => {
        // Schema selection change sub
        this.currentSchema = newSchema;

        // Remove all invalid filters
        this.setModel(
          model => model.filter(fs => CmsService.isValidField(fs.field, this.currentSchema))
        );
      });
    }
  }

  ngOnDestroy() {
    this.model$.complete();
    super.ngOnDestroy();
  }

  public addFilter(...args) {
    let availableFilters: CmsField[] = FilterService.getAvailableFilters(this.currentSchema);

    // remove any current filters present
    // TODO: add another layer of present filters (in the case of multiple FilterGroups present)
    availableFilters = availableFilters
    .filter(
      f => !this.getModel().find(
        model => f.id === model.id
      )
    );

    if (this.mapFn) availableFilters = this.mapFn(availableFilters);

    const data: AddFilterData = {
      availableFilters: availableFilters,
      schemaController: this.schemaController,
      isColumnFilter: !!this._isColumnFilter
    };

    this.utils.openModal(
      AddFilterModalComponent,
      data,
      UtilsService.MODAL_CONFIG_MEDIUM
    )
    .afterClosed()
    .subscribe((output: FilterSelection) => {
      if (output && !output.isEmpty()) {
        this.setModel(
          model => model.concat(output)
        );
      }
    });
  }

  /**
   *
   * @param field
   * @param isColumnFilter - Used to tell the EditFilterModalComponent to show/hide parts for column filter(s)
   */
  public editFilter(field: CmsField, isColumnFilter = false) {
    const filterSelection = this.model$.getValue().find(f => f.id === field.id);

    this.utils.openModal(EditFilterModalComponent, <FilterModalData>{
      field: field,
      config: {
        include: true,
        canToggle: true,
        nullsAllowed: true,
        isColumnFilter: isColumnFilter
      },
      model: filterSelection,
      schemaController: this.schemaController
    }, UtilsService.MODAL_CONFIG_MEDIUM)
    .afterClosed()
    .subscribe((output: FilterSelection) => {

      if (!output) {
        // cancelled - no change required
        return;
      }

      if (output.isEmpty()) {
        // new model is empty - remove filter
        return this.removeFilter(field.id);
      }

      // otherwise replace filter
      this.setModel(model => {
        const i = model.findIndex(f => f.id === field.id);
        model[i] = output;
        return model;
      });
    });
  }

  public removeFilter(filterId: string) {
    this.setModel(
      (model => model.filter(f => f.id !== filterId))
    );
  }

  /**
   * primary method of setting/modifying the underlying model of filter selections
   * @param chg: can be either an explicit FilterSelection[] to set it to, or a function that returns a FilterSelection[]
   * @param suppressOnChange: if true, does not trigger onChange output emitter
   */
  public setModel(chg: ((model: FilterSelection[]) => FilterSelection[]) | FilterSelection[], suppressOnChange = false) {
    let newModel: FilterSelection[];
    if (typeof chg === 'function') {
      newModel = chg(this.getModel());
    } else {
      newModel = chg;
    }
    this.model$.next(newModel);
    if (!suppressOnChange) {
      this.emitter.emit(newModel);
    }
  }

  public getModel(): FilterSelection[] {
    return this.model$.getValue();
  }
}

import { BaseModel } from 'app/core/models/base-model';

export class AppDataset extends BaseModel {
  private name: string;
  private params: any; // Formerly AppParameters (old class which was deprecated and removed)

  static fromJson<T>(j: any, parseKeys?: string[]): AppDataset {
    return <AppDataset><any> super.typedObjectFromJson(j, AppDataset, parseKeys);
  }

  constructor(name?: string, params?: any) {
    super();

    this.name = name || '';
    this.params = params || {};
  }

  getName(): string {
    return this.name;
  }

  setName(value: string) {
    this.name = value;
  }

  getParams(): any {
    return this.params;
  }

  setParams(value: any) {
    this.params = value;
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { QueryModeModalComponent, QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DaypartsFormData } from 'app/siq-applications/modules/dayparts/models/form/dayparts-form-data.model';
import { CmsService } from 'app/core/services/cms/cms.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

@Component({
  selector: 'siq-js-dayparts-query-mode-modal',
  templateUrl: './dayparts-query-mode-modal.component.html',
  styleUrls: ['./dayparts-query-mode-modal.component.scss']
})
export class DaypartsQueryModeModalComponent extends QueryModeModalComponent implements OnInit {
  public userGroupChanged: boolean;
  public filterSummary: FilterSelection[];
  public filterValueSummary: FilterSelection[] = [];

  constructor(
    protected dialogRef: MatDialogRef<DaypartsQueryModeModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected modalData: QueryModeModalData
  ) {
    super(dialogRef, modalData);
  }
  ngOnInit(): void {
    const form: DaypartsFormData = this.modalData.data;
    const schema = this.modalData.schema;
    this.userGroupChanged = !!this.modalData.userGroupChanged;

    this.filterSummary = form.filters.filter(fs => !CmsService.isValidField(fs.field, schema));

    form.filters.forEach(fs => {
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        const tmp = new FilterSelection(fs.toJson());
        tmp.values = fs.values.filter(val =>!retailers.includes(val));
        if (tmp.values.length) {
          this.filterValueSummary.push(tmp);
        }
      }
    });
    this.ready();
  }

}

// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  AggregationType,
  BaseVisualizationComponent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnHeaderComponent,
  ExcelService,
  GetContextMenuItemsParams,
  GRID_DEFAULTS,
  GridComponentAPI,
  GridColMetadata,
  GridColType,
  GridConfig,
  GridFilterComponents,
  GridOptions,
  GridService,
  GridSettings,
  HeatmapPanelComponent,
  HeatmapService,
  LegendData,
  PaginationConfig,
  RefreshCellsParams,
  SideBarDef,
  SortOptions,
  TableStateManager,
  ToolPanelDef,
  VisualizationState,
  ChartCreated,
  ColumnState,
  VisualOptions,
  GridThemes,
  ChartThemes,
  DownloadOverlayComponent,
  ChartsService,
  HEATMAP_ACTIVE,
  AgGridAngular,
  GridReadyEvent,
  ColumnRowGroupChangedEvent
} from 'libs/visual-lib/src';
import { Component, EventEmitter, Injector, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { isLessThan } from '@siq-js/core-lib';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { BehaviorSubject, Subject } from 'rxjs';
import { CmsMetric } from '@siq-js/cms-lib';
import { debounceTime, filter, takeUntil } from 'rxjs';
import { Scale } from 'chroma-js';
import { fabric } from 'fabric';
import { AsyncTotalsComponent } from 'libs/visual-lib/src/lib/modules/grid/components/async-totals/async-totals/async-totals.component';

@Component({
  selector: 'siq-js-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent extends BaseVisualizationComponent<any, any, GridConfig, GridComponentAPI> implements OnInit {

  private static LOADING_MSG = 'Please wait while the file is prepared for downloading...';
  private static async beforeGridExport(component: GridComponent) {
    if (component.gridSettings.gridVisualOptions.gridConfig.beforeGridExport) {
      await component.gridSettings.gridVisualOptions.gridConfig.beforeGridExport();
    }

    // Grid is ready; TableState has been applied; calcAggs() has run;
    // Set the Subject to notify that data is ready for CloudExport
    GridComponent.setGridReadyForExport(component.gridSettings.gridVisualOptions);
  }

  private static generateDefaultGridOptions(component: GridComponent): GridOptions {
    const NO_ROWS_TEMPLATE =
      '<div class="no-rows">' +
      '<h5>No Results</h5>' +
      '<h6>Although this activity has run to completion, the criteria used to create it produced no results</h6>' +
      '</div>';

    /**
     * Ag-Grid now seems to call the Aggregation Stage when it instantiates the Grid itself. As such, adding the "aggFuncs"
     * property to the GridOptions prevents errors pertaining to unrecognized aggregation functions from showing in the console.
     */
    return {
      aggFuncs: GRID_DEFAULTS.CUSTOM_AGG_FUNCTIONS,
      enableCharts: true,
      customChartThemes: {
        [ChartThemes.PDI]: ChartsService.getThemePdi(),
        [ChartThemes.PDI_DARK]: ChartsService.getThemePdiDark()
      },
      chartThemes: [
        ChartThemes.PDI,
        ChartThemes.PDI_DARK,
        ChartThemes.MATERIAL,
        ChartThemes.PASTEL,
        ChartThemes.SOLAR,
        ChartThemes.VIVID
      ],
      animateRows: true,
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true
      },
      valueCache: true,
      onChartCreated(event: ChartCreated) {
        //convert custom object returned from custom aggregation function to raw value for the chart
        const chart = event.api.getChartRef(event.chartId).chart;
        const series = chart.series;
        if(series && series.length > 0){
          series.forEach(s => {
            let shouldUpdate = false;
            s.data.forEach(d => {
              //somewhere [0] somewhere random property name
              if(_.isObject(d)) {
                if(d[0] && _.isObject(d[0]) && _.has(d[0], 'val')) {
                  d[0] = d[0].val;
                  shouldUpdate = true;
                }
                Object.keys(d).forEach(key => {
                  if(_.has(d, [key, 'val'])) {
                    _.set(d, key, _.get(d, [key, 'val']));
                    shouldUpdate = true;
                  }
                });
              }
            });
            if(shouldUpdate) {
              s.update({});
            }
          });
        }
      },
      suppressDragLeaveHidesColumns: true,
      suppressContextMenu: false,
      enableRangeSelection: true,
      pagination: true, // prior to set page size this has to be enabled
      suppressPaginationPanel: true, // defaultly the pagination is disabled
      paginationPageSize: 1000, // default page size specified in ICE-2378
      loadingOverlayComponent: DownloadOverlayComponent,
      loadingOverlayComponentParams: {
        loadingMessage: this.LOADING_MSG,
      },
      processCellForClipboard: function(cell) {
        if (_.isNil(cell.value)) { // empty cell
          return '';
        } else if (typeof cell.value === 'object') { // json object, use key to get value
          const colDef = cell.column.getColDef();
          const col = component.colDefMeta.get(colDef.pivotValueColumn ? colDef.pivotValueColumn.getColId() : colDef.colId);
          const key = GridService.getDataKey(col.ref);
          return cell.value[key];
        } else { // raw value
          return _.isNaN(cell.value) ? '' : cell.value;
        }
      },
      suppressAggFuncInHeader: true,
      overlayNoRowsTemplate: NO_ROWS_TEMPLATE,
      headerHeight: GRID_DEFAULTS.TABLE_HEADER_HEIGHT,
      rowHeight: GRID_DEFAULTS.TABLE_ROW_HEIGHT,
      excelStyles: ExcelService.generateExcelStyles(),
      autoGroupColumnDef: {
        sortingOrder: ['asc', 'desc'],
        pinned: 'left', // Pins the entire pivot column to the left, useful for very large visualizations with many dimension values
        // cellClassRules: ExcelService.getCellClassRules([  // TODO: Restore eventually
        //   // TODO: CMS integration
        //   // ...component.visual.getStatResponse().dimensions,
        //   // ...component.visual.getStatResponse().metrics
        // ]),
        headerComponent: ColumnHeaderComponent,
        cellRendererSelector: GridService.dimensionCellRendererSelector,
        cellRendererParams: {
          suppressCount: false
        },
        comparator: (a, b, aRow, bRow) => {
          if (_.isNil(a)) return -1;
          if (_.isNil(b)) return 1;
          const metaMap = component.colDefMeta;
          const key = component.grid.api.getRowGroupColumns()[aRow.level].getColDef().colId;
          const parseFn = GridService.getVisualType(metaMap.get(key).ref).parse;
          return isLessThan(parseFn(a), parseFn(b));
        }
      },
      processPivotResultColGroupDef: (colGroupDef) => {
        const originalColDef = component.grid.api.getPivotColumns()[colGroupDef.pivotKeys.length - 1].getColDef();
        if (originalColDef.valueFormatter && typeof originalColDef.valueFormatter === 'function') {
          colGroupDef.headerName = originalColDef.valueFormatter({value: colGroupDef.headerName} as any);
        }
      },
      /**
       * TODO: Restore
       * Determine whether this should be restored here, or whether the ChartComponent should add this (when developed)
       */
      // processChartOptions: (params: ProcessChartOptionsParams) => {
      //   return GridChartsService.processChartOptions(params);
      // },
      onGridReady: (grid:GridReadyEvent) => {
        /**
         * Set gridVisualOptions.vizId so that it is accessible immediately within any of the gridVisualOptions created in the XxxxProcessor files
         */
        component.gridSettings.gridVisualOptions.vizId = component.id;

        /**
         * Set the "apiRef" field to be a fn that when called runs "component.getApi()" and returns the results of that fn.
         * This way anytime the ".apiRef()" fn is executed, it will return an object holding all the latest/live data in GridComponent.
         */
        component.gridSettings.gridVisualOptions.apiRef = () => component.getApi();

        if (component.gridSettings.gridVisualOptions.gridConfig.onGridReady) {
          component.gridSettings.gridVisualOptions.gridConfig.onGridReady(grid);
        }

        component.addAggFuncs();
        component.init();
        component.checkForAsyncColumns();

        // Attach aggregation hook
        component.calcAggs$.pipe(
          debounceTime(100),
          takeUntil(component.unsub$)
        ).subscribe(async() => {
          component.calcAggs();

          // Grid is ready; TableState has been applied; calcAggs() has run;
          await GridComponent.beforeGridExport(component);
        });

        // Attach Event Listeners
        component.grid.api.updateGridOptions({
          onColumnPivotModeChanged: e => {
            if (grid.api.isPivotMode()) {
              component.featureUsed.next('Pivot Mode');
            }
            // Suppress the row group count if in pivot mode
            component.gridOptions.autoGroupColumnDef.cellRendererParams.suppressCount = e.api.isPivotMode();
            component.aggregate();
          },
          onColumnPivotChanged: e => {
            component.aggregate();
          },
          onColumnValueChanged: e => {
            component.aggregate();
          },
          onColumnRowGroupChanged: e => {
            component.onColumnRowGroupChangedCallback(e);
          },
          onFilterChanged: e => {
            const customOnFilterChangedFn = component.gridOptions.onFilterChanged; // get any custom fn from VisualOptions -> GridConfig -> customGridOptions
            component.filterObs$.next(grid.api.getFilterModel());
            component.aggregate();
            if (customOnFilterChangedFn) {
              customOnFilterChangedFn(e);
            }
          }
        });

        if (component.config.afterRender) {
          component.config.afterRender(grid);
          component.checkForAsyncColumns();
        }

        if (component.config.defaultSortOptions) {
          component.applyDefaultSorting(component.config.defaultSortOptions);
        }

        // tableStateManager can be initialized in report builder. TableStateManager initialization could removed from Report Builder onGridReady, the code here and there should be the same
        if(_.isNil(component.tableStateManager)) {
          component.tableStateManager = new TableStateManager(component);
          component.getApi().tableStateManager = component.tableStateManager;
          component.getApi().tableStateManager.status.pipe(takeUntil(component.unsub$)).subscribe(value => {
            if (value.justLoaded) {
              // grid sort and filtering will be refreshed and tablestate values will be used
              value.tableState?.pivotSortModel?.forEach(pivotSortModel => {
                component.grid.api.getAllGridColumns().forEach(gridCol => {
                  if (pivotSortModel.colId === gridCol.getColId()) {
                    gridCol.setSort(pivotSortModel.sort);
                  }
                });
              });
              component.grid.api.refreshHeader();
            }
          });
        }
      },
      sideBar: {
        toolPanels: [
          'columns',
          'filters',
          {
            id: 'gridHeatmap',
            labelDefault: 'Heatmap',
            labelKey: 'gridHeatmap',
            iconKey: 'colorPicker', // camelCase not kebab-case
            toolPanel: 'gridHeatmapToolPanel',
            toolPanelParams: {
              gridComponent: component
            }
          }
        ]
      }
    };
  }

  private static setGridReadyForExport(visualOptions: VisualOptions): void {
    // Grid is ready; TableState has been applied; calcAggs() has run;
    // Set the Subject to notify that data is ready for CloudExport
    if (!_.isNil(visualOptions.gridConfig.gridReadyForExport$)) {
      visualOptions.gridConfig.gridReadyForExport$.next(true);
    }
  }

  @Output() featureUsed: EventEmitter<string> = new EventEmitter<string>(); // only used for mixpanel tracking, could be moved to BaseVisualizationComponent
  @ViewChild(AsyncTotalsComponent) asyncTotalsComponent: AsyncTotalsComponent;
  @ViewChild('gridGrid') grid!: AgGridAngular;
  public agTheme: string = GridThemes.ALPINE; // default
  public checkAsyncTotalsCache$: Subject<void> = new Subject<void>();
  public colDefMeta: Map<string, GridColMetadata>;
  public filterObs$: Subject<any>;
  public frameworkComponents = GridFilterComponents;
  public gridHeatmapToolPanel: ToolPanelDef;
  public gridOptions: GridOptions;
  public gridSettings: GridSettings;
  public heatmapColDef: ColDef;
  public heatmapColDef$: BehaviorSubject<ColDef>;
  public legend: fabric.Canvas;
  public legends: Map<string, LegendData>;
  public metricColDefs: ColDef[];
  public paginationConfig: PaginationConfig;
  public paginationPageLoaded: Object;
  public parentActivity: any; // TODO: Type as Activity if available
  public renderHeatmap$: BehaviorSubject<boolean>;
  public scales: Map<string, Scale>;
  public sortObs$: Subject<ColumnState[]>;
  public visualizationState$: BehaviorSubject<VisualizationState>;
  public tooltipsMap: Map<string, string | ((params: any) => string)>;
  private aggMap: any = {};
  private calcAggs$: Subject<void> = new Subject<void>();
  public tableStateManager: TableStateManager;

  constructor(private renderer: Renderer2, public injector: Injector, private gridService: GridService) {
    super();
    this.colDefMeta = new Map<string, GridColMetadata>();
    this.filterObs$ = new Subject<any>();
    this.sortObs$ = new Subject<ColumnState[]>();
    this.renderHeatmap$ = new BehaviorSubject<boolean>(false);
    this.heatmapColDef$ = new BehaviorSubject<ColDef>(null);
    this.frameworkComponents = _.extend(this.frameworkComponents, {gridHeatmapToolPanel: HeatmapPanelComponent});
  }

  public aggregate() {
    this.checkForAsyncColumns();
    this.calcAggs$.next();
  }

  public calcAggs() {
    if (this.config.hideTotals) {
      this.grid.api.updateGridOptions({ pinnedTopRowData: [] });
      return;
    }
    const aggMap: any = {};
    if (this.grid.api.isPivotMode()) {
      const currRowGroups = this.grid.api.getRowGroupColumns().map(c => c.getColId());
      if (!currRowGroups.length) {
        this.grid.api.updateGridOptions({ pinnedTopRowData: [] });
      }
      /*
        Remove the onColumnRowGroupChanged (EventListener) so that modifications to columns (setRowGroupColumns) do not trigger
        infinite loops (GlobalListener would detect this change and call calcAggs...loop)
       */
      this.grid.api.setGridOption('onColumnRowGroupChanged', e => {});
      /* 
          Temporarily eliminating all row group column so that only the top "Totals" row remain,
          and the metrics aggregation will be performed during the process
      */
      this.grid.api.setRowGroupColumns([]);
      // get the aggData from the top "Totals" row
      const aggData = this.grid.api.getModel().getRow(0).aggData;

      this.grid.api.getAllGridColumns().forEach((col: Column) => {
        if (!_.isEmpty(this.grid.api.getPivotColumns())) {
          if (col.getColDef().pivotKeys && col.getColDef().pivotValueColumn) {
            const pivotCol = this.grid.api.getPivotResultColumn(
              col.getColDef().pivotKeys,
              col.getColDef().pivotValueColumn.getColId()
            );
            if (pivotCol) {
              aggMap[pivotCol.getColId()] = pivotCol.getColDef().aggFunc === GRID_DEFAULTS.AGG_MAP.AS ? null : aggData[col.getColId()];
            }
          }
        } else {
          // Setting this, but if there are no ColumnLabels set, these values (although set correctly) are not shown in pinnedTopRow
          aggMap[col.getColId()] = col.getColDef().aggFunc === GRID_DEFAULTS.AGG_MAP.AS ? null : aggData[col.getColId()];
        }
      });

      // Restore the row groups
      this.grid.api.setRowGroupColumns(currRowGroups);
      // Add the listener back into the gridOptions. SetTimeOut is needed to make sure it doesn't pick up previous setRowGroupColumns()
      setTimeout(() => {
        this.grid.api.setGridOption('onColumnRowGroupChanged', e => {this.onColumnRowGroupChangedCallback(e)});
      });
    } else {
      this.grid.api.getColumns()
      .filter(col => {
        const id = col.getColId();
        const meta = this.colDefMeta.get(id);
        return (meta && meta.ref && (meta.ref instanceof CmsMetric));
      })
      .forEach(col => {
        const id = col.getColId();
        const fact = this.colDefMeta.get(id).ref as CmsMetric;
        if (fact.aggType === AggregationType.ASYNC) {
          return;
        }
        const aggFunc = GridService.getAgg(fact, true);
        const aggArr = [];
        this.grid.api.forEachNodeAfterFilter(r => {
          if (r.data) {
            return aggArr.push(r.data[id] || 0);
          }
        });
        if (aggFunc) {
          aggMap[id] = aggFunc({values: aggArr}, true); // wrap aggArr in object to match new Ag-Grid format (v25 upgrade)
        }
      });
    }

    this.updateAggMap(aggMap);
    if (this.gridSettings.gridVisualOptions.gridConfig.allowAsyncTotalsCalls$?.value.show) {
      this.checkAsyncTotalsCache();
    }
  }

  public checkAsyncTotalsCache() {
    this.checkAsyncTotalsCache$.next();
  }

  public getApi(): GridComponentAPI {
    return {
      colDefMeta: this.colDefMeta,
      component: this,
      grid: this.grid,
      tableStateManager: this.tableStateManager
    };
  }

  public getContextMenuItems(params: GetContextMenuItemsParams): string[] {
    // see ag-grid-enterprise/src/menu/menuItemMapper.ts for options
    const contextMenu = ['copy', 'autoSizeAll', 'separator'];
    if (params.api.isPivotMode()) {
      contextMenu.push('pivotChart');
    } else {
      contextMenu.push('chartRange');
    }
    return contextMenu;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });
    // Load tooltips (if passed in)
    this.tooltipsMap = this.gridSettings.gridVisualOptions.gridConfig.tooltips || null;

    // TODO: BIG TODO... Confirm this works. IF IT DOES... then we can add a toggle somewhere (above grid, in tool panel, etc to trigger this subject update)
    this.renderHeatmap$.pipe(
      takeUntil(this.unsub$)
    )
    .subscribe((asHeatmap: boolean) => {
      if (asHeatmap) {
        this.initHeatmap();

        /**
         * This should show the desired ColDef as heatmap, provided it has a value.
         * If it does not, the heatmap-selector should now be visible and the user can select a metric.
         */
        this.heatmapColDef$.next(this.heatmapColDef);
      }
    });

    this.heatmapColDef$.pipe(
      takeUntil(this.unsub$)
    )
    .subscribe((colDef: ColDef) => {
      this.heatmapColDef = colDef;
    });

    this.paginationConfig = this.gridSettings.gridVisualOptions.gridConfig.paginationConfig;
  }

  public initHeatmap() {
    HeatmapService.setupHeatmap(this);
    this.gridSettings.gridVisualOptions.colorScales = this.scales;
    this.gridSettings.gridVisualOptions.legends = this.legends;
  }

  // Toggles column visibility based on the state
  public syncFactsToState(state: VisualizationState) {
    if (this.config.unlinkState) {
      return;
    }
    const visibleFacts = [];
    const hiddenFacts = [];
    this.colDefMeta.forEach((v, id) => {
      if (v.gridColType === GridColType.METRIC) {
        if (_.find(state.metrics, f => f.id === id) || !state.metrics.length) {
          visibleFacts.push(id);
        } else {
          hiddenFacts.push(id);
        }
      }
    });

    this.grid.api.setColumnsVisible(visibleFacts, true);
    this.grid.api.setColumnsVisible(hiddenFacts, false);
  }

  protected render(gridSettings: GridSettings) {
    this.gridSettings = gridSettings;
    this.parentActivity = gridSettings.parentActivity;

    // If sideBar settings were passed in, hold onto these temporarily
    const overrideSideBar: (SideBarDef | string | string[] | boolean) = _.cloneDeep(gridSettings.gridOptions.sideBar);
    if (overrideSideBar) {
      delete gridSettings.gridOptions.sideBar;
    }

    // Override config object with custom options
    this.gridOptions = _.merge(GridComponent.generateDefaultGridOptions(this), gridSettings.gridOptions);
    if (overrideSideBar) {
      this.configureSidebar(overrideSideBar);
    }
    // Generate default ColDef collection (one ColDef for each dimension and metric)
    this.gridOptions.columnDefs = this.gridOptions.columnDefs || this.generateDefaultColDefsCollection();
    if (!!this.gridSettings.gridVisualOptions.gridConfig.allowHeatmap) {
      this.initHeatmap();
    }
    const _state = this.gridSettings.gridVisualOptions.initState || {
      dimensions: this.gridSettings.gridVisualOptions.dimensions,
      metrics: this.gridSettings.gridVisualOptions.metrics,
      allowHeatmap: this.gridSettings.gridVisualOptions.gridConfig.allowHeatmap
    };
    this.visualizationState$ = new BehaviorSubject(_state);
    this.ready();
  }

  private onColumnRowGroupChangedCallback(e: ColumnRowGroupChangedEvent) {
    if (e.columns?.length) {
      this.hideRowGroupColumns();
      this.aggregate();
    } else {
      if (this.gridSettings.gridVisualOptions.gridConfig.allowAsyncTotalsCalls$?.value.show) {
        this.checkAsyncTotalsCache();
      }
    }
  }

  private addAggFuncs() {
    const aggFuncs = GRID_DEFAULTS.CUSTOM_AGG_FUNCTIONS;
    for (const k in GRID_DEFAULTS.CUSTOM_AGG_FUNCTIONS) {
      this.grid.api.addAggFunc(k, aggFuncs[k]);
    }
  }

  private applyColorScales(cd: ColDef | ColDef[]) {
    if (this.gridSettings.gridVisualOptions.colorScales) {
      const _apply = ((colDef: ColDef) => {
        colDef.cellStyle = (params) => {
          if (params.node.rowPinned || _.isNil(params.value)) return;
          // RowGroups are objects with val attr: { val: 99999 }; leaf nodes just have raw values
          const val = _.isObject(params.value) ? params.value.val : params.value;
          const useColId = colDef.pivotKeys ? colDef.pivotValueColumn.getColDef().colId : colDef.colId;
          const color: any = this.gridSettings.gridVisualOptions.colorScales.get(useColId)(val);

          return {
            'background-color': _.isNil(val) || !_.isFinite(val) ? 'transparent' : color.hex(),
            'color': HeatmapService.getContrastColor(color.hex())
          };
        };

        /*
          Add a class to the colDef to which the heatmap coloring has been applied.
          NOTE: This class is defined in 'global-mixins.scss'
         */
        const cssClasses = <string[]>colDef.cellClass;
        cssClasses?.push(HEATMAP_ACTIVE);
        const heatmapActiveCellClass = _.find(this.grid.excelStyles, (excelStyle) => {
          return excelStyle.id === HEATMAP_ACTIVE;
        });

        if (this.heatmapColDef) {
          // use "_.findLast" instead of "_.find" here to get the last class applied (takes precedence)
          const metricCellClassEnumId = _.findLast(<string[]>this.heatmapColDef.cellClass, (c) => {
            return c.substring(0, 5) === GridService.ENUM_ID_PREPEND;
          });

          const match = _.find(this.grid.excelStyles, (excelStyle) => {
            return excelStyle.id === metricCellClassEnumId;
          });

          if (match) {
            heatmapActiveCellClass.numberFormat = match.numberFormat;
          }
        }

      });

      /**
       * RESET ALL ColDefs
       * While we currently only support showing one metric with heatmap applied at a time (due to selecting the metric
       * ColDef and having only one Legend visible at a time), this code block resets all the backgrounds.
       *
       * In the future if we want to support multiple metrics and legends with heatmap simultaneously, this code block
       * would have to get removed.
       */
      const _removeTempClass = (tempCD: ColDef) => {
        let cssClasses = <string[]>tempCD.cellClass;
        const idxHeatmapTestClass = cssClasses?.indexOf(HEATMAP_ACTIVE);
        if (idxHeatmapTestClass > -1) {
          cssClasses = _.pull(cssClasses, HEATMAP_ACTIVE);
        }
      };

      const metricColData = GridService.getMetricColData(this.grid.gridOptions.columnDefs, this.gridSettings.gridVisualOptions.metrics);
      const defaultCellStyle = {'color': '', 'background-color': ''};
      metricColData.forEach(obj => {
        const _colDef = this.grid.api.getColumn(obj.colDef.colId).getColDef();

        this.grid.api.getColumn(obj.colDef.colId).getColDef().cellStyle = (params) => {
          return defaultCellStyle;
        };

        // Remove the temp class on any colDefs to which the heatmap coloring had been applied
        _removeTempClass(_colDef);

        if (this.grid.api.isPivotMode() && !_.isNil(this.grid.api.getPivotResultColumns())) {
          this.grid.api.getPivotResultColumns().forEach((column: Column) => {
            column.getColDef().cellStyle = () => {
              return defaultCellStyle;
            };

            // Remove the temp class on any colDefs to which the heatmap coloring had been applied
            _removeTempClass(column.getColDef());
          });
        }
      });

      if (Array.isArray(cd)) {
        cd.forEach(colDef => _apply(colDef));
      } else {
        _apply(cd);
      }
    }
  }

  // Applies a default sort (if passed in) only if there is no current sort
  private applyDefaultSorting(options: SortOptions) {
    if (!this.grid.api.getColumnState().find(c => c.sort)) {
      let sortModel: ColumnState;

      if (options.byKey) {
        sortModel = {
          colId: options.byKey,
          sort: options.sort
        };
      } else if (options.byFn) {
        sortModel = {
          colId: options.byFn(this.visualizationState$.getValue()),
          sort: options.sort
        };
      }
      this.grid.api.applyColumnState({state: [sortModel]});
    }
  }

  public autoSizeColumns() {
    if (!this.grid || !this.grid.api || this.config.manualSizing || this.grid.api.isDestroyed()) {
      return;
    }

    const totalColumns = this.grid.api.getAllDisplayedColumns().length;

    // Stretch/compress all visible columns to fit the container
    this.grid.api.sizeColumnsToFit();

    // If there are too many columns to fit in the inputted threshold (or one doesn't exist), auto-size each column and allow overflow
    if (!this.config.maxColumnsToFit || totalColumns > this.config.maxColumnsToFit) {
      this.grid.api.autoSizeAllColumns();
    }
  }

  /**
   * TODO: AsyncTotals - This will likely be moved to/become part of a dedicated Async module
   */
  private checkForAsyncColumns(): void {
    if (!this.config.allowAsyncTotalsCalls$) return;

    this.config.allowAsyncTotalsCalls$.next(
      AsyncTotalsComponent.allowAsyncTotalsCalls(this.grid.gridOptions)
    );
  }

  private configureSidebar(overrideSideBar: (boolean | string | string[] | SideBarDef)) {
    /**
     * The sideBar setting can have a boolean | string | string[] | SideBarDef value.
     * By default, our grid is setup to have all the toolPanels visible. If something different is desired, this
     * function handles the resetting of this value to the desired outcome.
     */
    const newToolPanels: (string | ToolPanelDef)[] = [];
    switch(typeof(overrideSideBar)) {
      case 'boolean':
        this.grid.sideBar = overrideSideBar;
        break;
      case 'string':
        // display only one
        const match: (ToolPanelDef | string) = (<SideBarDef>this.grid.sideBar).toolPanels.find((val: (ToolPanelDef | string)) => {
          const _newId = (typeof val === 'string') ? val : val.id;
          return _newId === overrideSideBar;
        });
        newToolPanels.push(match);
        break;
      default:
        // string[] | SideBarDef
        const items: any[] = Array.isArray(overrideSideBar) ? overrideSideBar : (<SideBarDef>overrideSideBar).toolPanels;
        items.forEach( (tp: string | ToolPanelDef) => {
          const _newId = (typeof tp === 'string') ? tp : tp.id;
          const match = (<SideBarDef>this.grid.sideBar).toolPanels.find(val => {
            const panelId = (typeof val === 'string') ? val : val.id;
            return panelId === _newId;
          });
          if (match) {
            newToolPanels.push(match);
          }
        });
        break;
    }
    if (newToolPanels.length) {
      (<SideBarDef>this.grid.sideBar).toolPanels = newToolPanels;
      if (typeof(overrideSideBar) === 'object' && !Array.isArray(overrideSideBar) && overrideSideBar.defaultToolPanel) {
        (<SideBarDef>this.grid.sideBar).defaultToolPanel = overrideSideBar.defaultToolPanel;
      }
    }
  }

  private generateDefaultColDefsCollection(): ColDef[] {
    // Generate a column definition object for every possible fact/dimension
    const columnDefs: ColDef[] = [];

    // TAKEN (and modified slightly) FROM visual-table.component: init()
    this.gridSettings.gridVisualOptions.dimensions.forEach(d => columnDefs.push(this.gridService.generateDimensionColumn(d, this.colDefMeta)));
    this.gridSettings.gridVisualOptions.metrics.forEach(f => {
      const colDef = this.gridService.generateMetricColumn(f, this.colDefMeta);
      columnDefs.push(colDef);
    });

    return columnDefs;
  }

  // When a field is selected as a row group, remove the redundant column
  private hideRowGroupColumns() {
    this.grid.api.getRowGroupColumns()
    .forEach(col => {
      this.grid.api.setColumnVisible(col.getColId(), false);
    });
  }

  private init() {
    // Override the default metadata if an external one is passed in
    this.colDefMeta = this.config.customColDefMeta || this.colDefMeta;

    // If configureColumns is passed in through the options, apply it to the columnDefs
    if (this.config.configureColumns) {
      const configColDefs = this.config.configureColumns(this.gridOptions.columnDefs);
      this.gridOptions.columnDefs = this.config.configureColumns(this.gridOptions.columnDefs);
      this.grid.api.updateGridOptions({ columnDefs: configColDefs });
    }

    this.metricColDefs = this.gridOptions.columnDefs.filter(cd => !!cd['aggFunc']);
    if (_.isEmpty(this.metricColDefs)) {
      const _findId = (c: ColDef | ColGroupDef) => {
        const _c = <any>c;
        if (_c.children) {
          // ColGroupDef
          (<ColGroupDef>c).children.forEach(child => {
            _findId(child);
          });
        } else {
          // ColDef
          if ((<any>c).aggFunc) {
            // Metric
            this.metricColDefs.push(c);
          }
        }
      };

      this.grid.gridOptions.columnDefs.forEach((c: ColDef | ColGroupDef) => {
        _findId(c);
      });
    }

    this.grid.api.updateGridOptions({rowData: this.gridSettings.data});

    this.setStateSub();

    this.setContainerHeight();
  }

  onPaginationChanged() {
    this.paginationPageLoaded = !this.paginationPageLoaded;
  }

  // Pins the indexed columns specified in the options
  private pinColumns() {
    const pinnedIndices = this.config.pinLeft;
    if (pinnedIndices) {
      this.grid.api.getAllDisplayedColumns().forEach((col, i) => {
        if (pinnedIndices.includes(i)) {
          this.grid.api.setColumnPinned(col.getColId(), 'left');
        }
      });
    }
  }

  // Resizes the ag-grid container if its small enough to display without scroll
  private setContainerHeight() {
    const containerElement = document.querySelector('#' + this.id + ' .grid-container');
    const tableBodyElement = document.querySelector('#' + this.id + ' .ag-center-cols-container');
    const headerElement = document.querySelector('#' + this.id + ' .ag-header');

    if (this.config?.tableHeight) {
      let heightStr = this.config.tableHeight;
      if (typeof heightStr === 'number') {
        heightStr = Math.max(GRID_DEFAULTS.DEFAULT_TABLE_HEIGHT, heightStr) + 'px';
      }
      this.renderer.setStyle(containerElement, 'height', heightStr);
    } else {
      this.renderer.setStyle(containerElement, 'height', GRID_DEFAULTS.DEFAULT_TABLE_HEIGHT + 'px');
    }

    if (!this.config?.tableHeight) {
      // Calculate the height of the table's headers and body, and set it to that if it's smaller than the max height
      const agGridTotalHeight = tableBodyElement.clientHeight + headerElement.clientHeight + 52;
      if (GRID_DEFAULTS.DEFAULT_TABLE_HEIGHT > agGridTotalHeight) {
        this.renderer.setStyle(containerElement, 'height', GRID_DEFAULTS.DEFAULT_TABLE_HEIGHT + 'px');
      }
    }
    this.autoSizeColumns();

  }

  private setStateSub(): void {
    this.visualizationState$
    .pipe(
      debounceTime(250),
      filter(visualizationState => !_.isNil(visualizationState)),
      takeUntil(this.unsub$)
    )
    .subscribe(state => {
      if (!this.gridSettings.gridVisualOptions.gridConfig.hideTotals) {
        this.aggregate();
      } else {
        // If totals are hidden, we still need to mark tha the grid is ready for export
        GridComponent.setGridReadyForExport(this.gridSettings.gridVisualOptions);
      }
      this.syncFactsToState(state); // Toggle visibility of columns based on state

      if (!_.isNil(state.heatmapColDefs)) {
        this.refreshColumns(state.heatmapColDefs);
      }

      this.pinColumns(); // Pin columns (if any)
      this.autoSizeColumns();
    });
  }

  private updateAggMap(aggMap: any) {
    this.aggMap = aggMap;
    if (!_.isNil(aggMap)) {
      aggMap.suppressRowClick = true;
    }
    this.grid.api.updateGridOptions({pinnedTopRowData: [aggMap]});
  }

  refreshColumns(heatmapColDefs: ColDef[]) {
    // Currently only one ColDef at a time supported, but param is an array to make future expansion easier
    const colDefs: ColDef[] = [];
    const metricColData = GridService.getMetricColData(this.grid.gridOptions.columnDefs, this.gridSettings.gridVisualOptions.metrics);

    if (!heatmapColDefs.length) {
      colDefs.push(...this.gridOptions.columnDefs);
    } else {
      // This is a (slightly modified) Copy/Paste from HeatmapSelector
      let _colDef = <ColDef>_.find(this.gridOptions.columnDefs, {colId: heatmapColDefs[0].colId});
      if (!_colDef) {
        /**
         * Could not find based on colDef.colId. This could be due to RB column ids (generated numbers) or due to gridOptions.columnDefs having ColGroupDefs.
         * Check the code in HeatmapService to iterate through this and get a list of the actual columns. This functionality should get extracted to a fn.
         */
        const match = _.find(metricColData, {key: heatmapColDefs[0].colId});
        if (match) {
          _colDef = <ColDef>match.colDef;
        }
      }

      /*
        Add the colDef for non-pivot mode no matter what; allows user to toggle between pivot/non-pivot mode
        and have the colorization be maintained in both.
       */
      colDefs.push(
        this.grid.api.getColumn(_colDef.colId).getColDef() // (so that if pivot is removed, the correct non-pivot colId will also be colored for heatmap
      );

      if (this.grid.api.isPivotMode() && !_.isNil(this.grid.api.getPivotResultColumns())) {
        colDefs.push(
          ...this.grid.api.getPivotResultColumns()
          .filter(secCol => secCol.getColDef().pivotValueColumn.getColId() === _colDef.colId)
          .map(secondaryColumn => secondaryColumn.getColDef())
        );
      }
    }

    this.applyColorScales(colDefs);

    const allMetricColIds: string[] = [];
    if (this.grid.api.isPivotMode() && !_.isNil(this.grid.api.getPivotResultColumns())) {
      allMetricColIds.push(
        ...this.grid.api.getPivotResultColumns()
        .filter(secCol => !_.isNil(secCol.getColDef().aggFunc)) // filter out to get only metrics
        .map(secondaryColumn => secondaryColumn.getColId())
      );
    } else {
      allMetricColIds.push(
        ...metricColData.map(mcd => mcd.colDef.colId)
      );
    }

    this.grid.api.refreshCells(<RefreshCellsParams>{
      columns: allMetricColIds,
      force: true // skip change detection as no values have changed, only background color
    });
  }
}

import {
  AgCartesianChartOptions,
  AgLineSeriesOptions,
  AgChartOptions,
  AgScatterSeriesOptions,
  AgTooltipRendererResult,
  ScatterWithLineChartSettings,
  ScatterWithLineParams,
  ScatterWithLineProcessor,
  VISUAL_CONFIG,
  ChartsService
} from '@siq-js/visual-lib';
import { getCmsMetricDataValue } from '@siq-js/core-lib';
import { takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { IncrementalsResultComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-result/incrementals-result.component';
import { DefaultMetrics } from 'app/siq-applications/modules/incrementals/models/interfaces';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export class
IncrementalsScatterWithLineProcessor extends ScatterWithLineProcessor {

  public static MEDIAN_X;
  public static MEDIAN_Y;
  private static DATA_POINT_COLOR = THEME_COLORS.SKY;
  private static DATA_POINT_COLOR_HIGHLIGHT = THEME_COLORS.SUNSHINE;
  private static MEDIAN_COLOR = THEME_COLORS.PLUM;

  public static processor (scatterWithLineParams?: ScatterWithLineParams): ScatterWithLineChartSettings {
    /**
     * This is where to manipulate the _data if necessary
     */
    const _data = IncrementalsScatterWithLineProcessor.processData(scatterWithLineParams);

    let chartOptions: AgChartOptions = {
      // No "data: []" here as each entry in the ".series" collection will set its own data attribute
      series: [],
      axes : [
        {
          tick :{
            minSpacing: 50,
            maxSpacing: 100
          },
          type: 'number',
          position: 'bottom',
          label: {
            formatter: (params) => {
              return params.value;
            }
          },
          title: {
            enabled: true,
            fontWeight : 'bold'
          }
        },
        {
          tick :{
            minSpacing: 20,
            maxSpacing: 50
          },
          type: 'number',
          position: 'left',
          label: {
            formatter: (params) => {
              return params.value;
            }
          },
          title: {
            enabled: true,
            fontWeight : 'bold'
          }
        },
      ],
      legend: {
        enabled: true,
        position: 'bottom'
      },
      theme: ChartsService.getThemePdi(),
    };
    let agc: AgScatterSeriesOptions = {
      type: 'scatter',
      xKey: scatterWithLineParams.selectedMetricX$.value.id,
      yKey: scatterWithLineParams.selectedMetricY$.value.id,
      xName: scatterWithLineParams.selectedMetricX$.value.display,
      yName: scatterWithLineParams.selectedMetricY$.value.display,
      marker: {
        fill: IncrementalsScatterWithLineProcessor.DATA_POINT_COLOR,
        stroke: IncrementalsScatterWithLineProcessor.DATA_POINT_COLOR,
        size: 12,
        strokeWidth: 1,
        strokeOpacity: 0
      },
      highlightStyle: {
        item: {
          fill: IncrementalsScatterWithLineProcessor.DATA_POINT_COLOR_HIGHLIGHT,
          fillOpacity: 1,
        }
      },
      data: _data,
      tooltip: {
        renderer: (params) => {
          scatterWithLineParams.highlightDatum$.next(params.datum);
          const selectedMetricX = scatterWithLineParams.selectedMetricX$.value;
          const selectedMetricY = scatterWithLineParams.selectedMetricY$.value;

          const formatterY = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: selectedMetricY.type})?.formatter;
          const formatterX = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: selectedMetricX.type})?.formatter;
          /**
           * Instead of returning AgTooltipRendererResult, return string (see below)
           * return <AgTooltipRendererResult>{
           *   content: params.xValue
           * };
           */
          // Returning a string in order to customize the background-color of the title
          return (
            '<div class="ag-chart-tooltip-title" style="background-color:' + IncrementalsScatterWithLineProcessor.DATA_POINT_COLOR + '">' +
            params.datum[scatterWithLineParams.dimensions.value[0].id] +
            '</div>' +
            '<div class="ag-chart-tooltip-content">' +
            selectedMetricX.display + ': ' + (formatterX ? formatterX({value: params.datum[params.xKey]}) : params.datum[params.xKey].toFixed(2)) +
            '<br/>' +
            selectedMetricY.display + ': ' + (formatterY ? formatterY({value: params.datum[params.yKey]}) : params.datum[params.yKey].toFixed(2)) +
            '</div>'
          );
        }
      }
    };
    let agc2: AgLineSeriesOptions =       {
      type: 'line',
      xKey: scatterWithLineParams.selectedMetricX$.value.id,
      yKey: scatterWithLineParams.selectedMetricY$.value.id,
      xName: scatterWithLineParams.selectedMetricX$.value.display,
      yName: scatterWithLineParams.selectedMetricX$.value.display,
      stroke: IncrementalsScatterWithLineProcessor.MEDIAN_COLOR,
      marker: {
        stroke: IncrementalsScatterWithLineProcessor.MEDIAN_COLOR,
        fill: IncrementalsScatterWithLineProcessor.MEDIAN_COLOR,
        enabled: true,
        shape: 'triangle' // vertical line from the X axis spanning the Y-range
      },
      lineDash: [2, 6],
      strokeWidth: 3,
      data: [], // populated later
      tooltip: {
        renderer: (params) => {
          const formatter = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: scatterWithLineParams.selectedMetricX$.value.type})?.formatter;
          const medianVal = scatterWithLineParams.metaData.MEDIAN_X ? scatterWithLineParams.metaData.MEDIAN_X : '';
          return <AgTooltipRendererResult>{
            content: <any>( formatter ? formatter({value: medianVal}) : medianVal)
          };
        }
      }
    };
    let agc3: AgLineSeriesOptions = {
      type: 'line',
      xKey: scatterWithLineParams.selectedMetricX$.value.id,
      yKey: scatterWithLineParams.selectedMetricY$.value.id,
      yName: scatterWithLineParams.selectedMetricY$.value.display,
      stroke: IncrementalsScatterWithLineProcessor.MEDIAN_COLOR,
      marker: {
        stroke: IncrementalsScatterWithLineProcessor.MEDIAN_COLOR,
        fill: IncrementalsScatterWithLineProcessor.MEDIAN_COLOR,
        enabled: true,
        shape: 'circle' // horizontal line from the Y axis spanning the X-range
      },
      lineDash: [2, 6],
      strokeWidth: 3,
      data: [], // populated later
      tooltip: {
        renderer: (params) => {
          const formatter = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: scatterWithLineParams.selectedMetricY$.value.type})?.formatter;
          const medianVal = scatterWithLineParams.metaData.MEDIAN_Y ? scatterWithLineParams.metaData.MEDIAN_Y : '';
          return <AgTooltipRendererResult>{
            content: <any>( formatter ? formatter({value: medianVal}) : medianVal)
          };
        }
      }
    };
    (<AgCartesianChartOptions>chartOptions).series.push(<AgScatterSeriesOptions>agc, <AgLineSeriesOptions>agc2, <AgLineSeriesOptions>agc3);

    scatterWithLineParams.datasetChanged$.pipe(
      takeUntil(scatterWithLineParams.parent.unsub$)
    )
    .subscribe(() => {
      if (_.isEmpty(scatterWithLineParams.metrics.value)) return;
      IncrementalsScatterWithLineProcessor.updateEverything(scatterWithLineParams.rawData.value, scatterWithLineParams);
    });

    return <ScatterWithLineChartSettings>{
      agChartOptions: chartOptions,
      parentActivity: null,
      selectedMetricsX$: scatterWithLineParams.selectedMetricX$,
      selectedMetricsY$: scatterWithLineParams.selectedMetricY$,
      metaData: scatterWithLineParams.metaData
    };
  }

  private static processData(scatterWithLineParams: ScatterWithLineParams): any[] {
    // Process the data
    return scatterWithLineParams.rawData.value.map((value: any) => {
      scatterWithLineParams.dimensions.value.forEach(cmsField => {
        value[cmsField.id] = value[cmsField.id].toString();
      });

      const dimKeys = scatterWithLineParams.dimensions.value.map(cmsField => cmsField.id);
      Object.keys(value).filter(key => !dimKeys.includes(key)).forEach(metricKey => {
        value[metricKey] = getCmsMetricDataValue(value, metricKey);
      });

      return value;
    });
  }

  private static updateEverything(rawData: any[], scatterWithLineParams: ScatterWithLineParams) {
    let newChartOptions: AgChartOptions = _.cloneDeep(scatterWithLineParams.chart.getApi().chartOptions);

    // Process the data
    const _data = IncrementalsScatterWithLineProcessor.processData(scatterWithLineParams);

    // declare the new selected metrics
    const defaultMetrics: DefaultMetrics = (<IncrementalsResultComponent>scatterWithLineParams.parent).getDefaultMetrics();
    const newSelectedMetricX = scatterWithLineParams.selectedMetricX$.getValue() ?? defaultMetrics.xMetric;
    const newSelectedMetricY = scatterWithLineParams.selectedMetricY$.getValue() ?? defaultMetrics.yMetric;

    let matchSeries: AgScatterSeriesOptions = <AgScatterSeriesOptions>_.find(newChartOptions.series, {type: 'scatter'});
    matchSeries.data = _data;

    matchSeries.xKey = newSelectedMetricX.id;
    matchSeries.xName = newSelectedMetricX.display;
    matchSeries.yKey = newSelectedMetricY.id;
    matchSeries.yName = newSelectedMetricY.display;

    if (scatterWithLineParams.chart) {
      scatterWithLineParams.chart.agChartOptions = newChartOptions;
    }
  }
}

import { Component } from '@angular/core';
import { CriteriaChipFactory } from 'app/core/models/factories/criteria-chip-factory';
import { ColDef, ICellRendererParams } from '@siq-js/visual-lib';
import { ReportActivity } from 'app/siq-applications/modules/report-builder/models/report-activity.model';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { ActivityCriteriaRendererComponent } from 'app/activity/shared/renderers/activity-criteria-renderer/activity-criteria-renderer.component';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import * as pluralize from 'pluralize';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Model {
  dateRange: string;
  dimensions: CriteriaChip;
  metrics: CriteriaChip;
  filters: CriteriaChip;
  retailers: CriteriaChip;
  sheets: CriteriaChip;
}

@Component({
  selector: 'siq-report-builder-criteria-renderer',
  templateUrl: './report-builder-criteria-renderer.component.html',
  styleUrls: ['./report-builder-criteria-renderer.component.scss']
})
export class ReportBuilderCriteriaRendererComponent<ReportBuilderActivity> extends ActivityCriteriaRendererComponent {

  public static readonly ColDef: ColDef = {
    headerName: 'Criteria',
    sortable: false,
    cellRenderer: ReportBuilderCriteriaRendererComponent,
    width: 330
  };

  public model: Model;
  public isMultiMode: boolean = false;

  constructor(
    private reportBuilderService: ReportBuilderService,
    asyncStatusService: AsyncStatusService
  ) {
    super(asyncStatusService);
  }

  protected setupModel(activity: ReportActivity): void {
    this.isMultiMode = EnvConfigService.isMultiMode();
    this.model = this.createModel(activity);
    this.ready();
  }

  refresh(params: ICellRendererParams): boolean {
    const a: ReportActivity = params.data;
    const newModel = this.createModel(a);
    const isChanged = this.isModelChanged(newModel);
    if (isChanged) {
      this.model = newModel;
    }
    this.ready();
    return isChanged;

  }

  private createModel(activity: ReportActivity): Model {
    let model;
    try {
      if (activity.sheets.length > 0) {
        const form = this.reportBuilderService.createForm(activity.sheets[0].getFormValues());
        model = {
          sheets: this.getSheetAndDraft(activity),
          dateRange: CriteriaChipFactory.getDate(form.globalDateRange),
          dimensions: CriteriaChipFactory.getDimensions(form.columns.filter(c => c.isDim()).map(c => c.ref)),
          metrics: CriteriaChipFactory.getMetrics(form.columns.filter(c => c.isMetric()).map(c => c.ref)),
          filters: CriteriaChipFactory.getFilters(form.globalFilters),
          retailers: CriteriaChipFactory.getRetailers(form.globalFilters, form.schema)
        };
      }
    } catch (e) {
      console.warn('Failed to setup model for ', activity);
    }
    return model;
  }

  private isModelChanged(newModel: Model): boolean {
    let isChanged = false;
    if (!newModel) {
      return true;
    }

    if (this.model?.sheets.label !== newModel?.sheets.label) {
      isChanged = true;
    } else if (this.model?.dateRange !== newModel?.dateRange) {
      isChanged = true;
    } else if (this.model?.dimensions.label !== newModel?.dimensions.label) {
      isChanged = true;
    } else if (this.model?.metrics.label !== newModel?.metrics.label) {
      isChanged = true;
    } else if (this.model?.filters.label !== newModel?.filters.label) {
      isChanged = true;
    } else if (this.model?.retailers.label !== newModel?.retailers.label) {
      isChanged = true;
    }

    return isChanged;
  }

  private getSheetAndDraft(activity: ReportActivity): CriteriaChip {
    const draftCounts = ReportBuilderService.getCountOfDraftsInReportBuilderActivity(activity.getMetaDataByKey('state') || '[]');
    const draftStr = draftCounts > 0 ? pluralize('draft', draftCounts, true) : '';

    return {
      label: `${pluralize('sheet', activity.sheets.length, true)} ${draftStr}`
    };
  }
}

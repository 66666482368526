import { Component, Input, OnInit } from '@angular/core';
import { BaseSiqComponent, NotificationService } from '@siq-js/angular-buildable-lib';
import { Activity } from 'app/activity/models/activity.model';
import { StatResponse } from 'app/core/models/stat-response.model';
import { PromoResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/promo-result.component';
import { PromoSheetResult, SOVData, SOVDrilldown, SOVHelperParams } from 'app/siq-applications/modules/promo/models/interfaces';
import {
  KPI,
  PromoDimensionKeys,
  PromoPeriods,
  PromoSheets,
  UnitTypes
} from 'app/siq-applications/modules/promo/models/promo.enums';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { debounceTime, takeUntil } from 'rxjs';
import { PromoResultGridParams, PromoResultGridProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/promo-result-grid-processor';
import { AggregationType, BarColumnConfig, BarColumnParams, ColDef, ColGroupDef, GridColMetadata, GridComponent, GridService } from '@siq-js/visual-lib';
import { PromoSovDrilldownRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/promo-sov-drilldown-renderer/promo-sov-drilldown-renderer.component';
import { SovColumnChartProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/sov-column-chart.processor';
import { DrawerService } from 'app/core/modules/drawer/services/drawer.service';
import { DrawerState } from 'app/core/modules/drawer/models/drawer-state.enums';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { DrawerRules } from 'app/core/modules/drawer/models/drawer.interfaces';
import { SovHelperComponent } from 'app/siq-applications/modules/promo/components/promo-result/sov-helper/sov-helper.component';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';

@Component({
  selector: 'siq-js-sov-result',
  templateUrl: './sov-result.component.html',
  styleUrls: ['./sov-result.component.scss']
})
export class SovResultComponent extends BaseSiqComponent implements OnInit, PromoSheetResult {
  @Input() public activities: Activity[];
  @Input() public activitiesPSPW: Activity[];
  @Input() parent: PromoResultComponent;

  // This is the model that manages all current drilldowns for this Promo instance. Passed into the SOVHelperComponent
  // Made readonly to prevent re-assigning this pointer
  public readonly drilldowns: SOVDrilldown[] = [];

  // Holds selected value in dropdown
  public dataSetKey: string;
  public factKey: CmsMetric;
  public unitType = UnitTypes.ABSOLUTE;
  public periodKey: PromoPeriods = PromoConfig.compPeriods[0];

  // Dropdown options
  public dataSetKeys: {key: string, display: string}[];
  public facts: CmsMetric[];
  public unitTypes = UnitTypes;

  // grid
  public grid: GridComponent;
  public gridParams: PromoResultGridParams;
  public gridProcessor = PromoResultGridProcessor.processor;
  public readyForExport$: BehaviorSubject<boolean>; // if grid is ready to be exported

  // chart
  public columnParams: BarColumnParams;
  public columnConfig: BarColumnConfig;
  public columnProcessor = SovColumnChartProcessor.processor;
  public columnData$ = new BehaviorSubject<any>(null);
  public shareDeltaFact = new CmsMetric({
    display: 'Share Change',
    type: 'PERCENT',
    aggType: AggregationType.SUM,
    id: 'PROMO_DELTA',
    active: true,
  });

  public dataSets: SOVData = {};
  public defaultUPCActivity: Activity;
  public helperParams: SOVHelperParams;
  public promoUPCs: string[]; // List of promo UPCs - used to decorate visualizations
  public promoUPCSet: Set<string>; // Faster lookup for promoUPCs
  public preCustomName: string;
  public render$: Subject<void> = new Subject<void>();
  public renderedOnce = false;
  public sov$: BehaviorSubject<SourceOfVolume> = new BehaviorSubject<SourceOfVolume>(null); // Used for the table to know the current SOV selection for highlighting.
  public yoyCustomName: string;

  // This map is a key (drilldown label) to SOVDrilldown mapping for fast lookup in templates
  public drilldownsMap: {
    [key in string]: SOVDrilldown
  } = {};

  public pollDrilldowns$: Subject<void> = new Subject();

  // Passes in drilldown IDs for the helper component to display
  private drilldownTrigger$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private activityService: ActivityService,
    private config: PromoConfig,
    private gridService: GridService,
    private mixpanelService: MixpanelService,
    private promoService: PromoService,
    private notificationService: NotificationService
  ) {
    super();
  }

  createDropdownOptions() {
    const dataSetKeys = [];

    for (let dataSetKey in this.dataSets) {

      let display: string;

      if (dataSetKey === PromoConfig.DEFAULT_SOV_JOB_NAME) {
        display = dataSetKey;
      } else {
        const sov = this.findSov(dataSetKey);
        display = `${sov.dimension.display} in ${sov.comparisonGroup.name}`;
      }

      dataSetKeys.push({
        key: dataSetKey,
        display: display
      });
    }

    this.dataSetKeys = dataSetKeys;
    this.dataSetKey = PromoConfig.DEFAULT_SOV_JOB_NAME;
    this.setupFacts();
    this.factKey = this.facts.find(f => f.id === PromoConfig.defaultFact);
  }

  public drilldown(label: string, dataNode?: any) {
    if (this.drilldownsMap[label] && this.drilldownsMap[label].data) {
      if (this.isActive()) {
        this.viewDrilldown(label);
      } else {
        this.parent.setActiveSheet(PromoSheets.SOV);
        DrawerService.clear().then(() => {
          setTimeout(() => this.viewDrilldown(label));
        });
      }
      return;
    }

    if (!this.hasCompleteDrilldowns()) {
      DrawerService.peek();
    }

    let sov = this.findSov(this.dataSetKey);
    const dV = dataNode[sov.dimension.id];

    const drilldown: SOVDrilldown = {
      id: null,
      label: label
    };

    this.drilldowns.push(drilldown);
    this.drilldownsMap[label] = drilldown;

    this.promoService.drilldownSOV(dV, sov, this.parent.formData)
      .subscribe(res => {
        drilldown.id = res.appActivityId;
        this.pollDrilldowns$.next();
      });
  }

  public findSov(key: string): SourceOfVolume {
    if (key === PromoConfig.DEFAULT_SOV_JOB_NAME) {
      const fakeSOV = new SourceOfVolume();
      fakeSOV.comparisonGroup = null;
      fakeSOV.dimension = this.defaultUPCActivity.getResponse().getDimensions()[0];
      return fakeSOV;
    }
    return this.parent.formData.sourcesOfVolume.find(sov => sov.id === key);
  }

  generateFileName(activityName: string): string {
    // TODO
    return null;
    // const label = this.dataSetKeys.find(dsk => dsk.key === this.dataSetKey).display;
    // const factName = FactService.find(this.factKey).name;
    // return `${activityName} - ${label} (${factName})`;
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
      'Usage': 'Source of Volume'
    });
  }

  public isActive(): boolean {
    return this.parent.currentSheet === PromoSheets.SOV;
  }

  ngOnInit(): void {
    this.preCustomName = this.parent.formData[PromoConfig.periodNames[PromoPeriods.PRE]].customPeriodName;
    this.yoyCustomName = this.parent.formData[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName;
    this.defaultUPCActivity = this.activities.find(a => this.getDataSetKey(a.getJob()) === PromoConfig.DEFAULT_SOV_JOB_NAME);
    this.promoUPCs = this.defaultUPCActivity.getResponse().getDimensionValues()[0];
    this.promoUPCSet = new Set(this.promoUPCs);
    const dataSets: SOVData = {};
    this.activities.forEach(a => {
      const dataSetKey = this.getDataSetKey(a.getJob());
      dataSets[dataSetKey] = {absData: null, pspwData: null};
      dataSets[dataSetKey].absData = this.promoService.generateSOVData(a.getJob(), this.promoUPCSet);
      // Get UPC(Only) (if there's such dimension) for highlighting
      const dim = this.findSov(dataSetKey).dimension;
      if (dim.field === PromoDimensionKeys.UPC) {
        const promoUPCOnly = this.promoUPCs.map(upc_desc => upc_desc.split(' - ')[0]);
        this.promoUPCs = [...this.promoUPCs, ...promoUPCOnly];
        this.promoUPCSet = new Set(this.promoUPCs); // update Set
      }
    });
    this.dataSets = dataSets;
    this.createDropdownOptions();
    this.setupTable();
    this.setupChart();
    this.sov$.next(this.findSov(this.dataSetKey));

    this.render$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsub$)
      )
      .subscribe(() => this.render());

      this.pollDrilldowns$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsub$)
      )
      .subscribe(() => this.poll());

      this.ready();
  }

  render() {
    const apiRef = this.grid.getApi();
    const colDefs = this.generateColDefs(apiRef.colDefMeta);
    let data: any[];

    if (this.unitType === UnitTypes.ABSOLUTE) {
      data = this.dataSets[this.dataSetKey].absData;
    } else { // PSPW
      const pspwA = this.activitiesPSPW.find(a => a.getJob().getName().includes(this.dataSetKey));
      if (!this.dataSets[this.dataSetKey].pspwData) {
        this.dataSets[this.dataSetKey].pspwData = this.promoService.generateSOVData(pspwA.getJob(), this.promoUPCSet);
      }
      data = this.promoService.applyPerStorePerWeek(this.dataSets[this.dataSetKey].pspwData);
    }
    this.sov$.next(this.findSov(this.dataSetKey));

    apiRef.grid.api.updateGridOptions({ rowData: data });
    apiRef.grid.api.updateGridOptions({ columnDefs: [] });
    apiRef.grid.api.updateGridOptions({ columnDefs: colDefs });
    this.grid.aggregate();
    PromoResultGridProcessor.resizeGrid(apiRef.grid.api);

    // chart
    const rawData = GridService.jobToArray(this.generateChartStatResponse(this.findSov(this.dataSetKey).dimension, data))
      .filter(bar => bar.PROMO_DELTA?.val);
    this.columnData$.next(rawData);
  }

  setActive() {
    if (!this.renderedOnce) {
      this.updateView();
      this.renderedOnce = true;
    }

    this.helperParams = {
      drilldowns: this.drilldowns,
      drilldownTrigger$: this.drilldownTrigger$,
      parent: this,
      helperComponent: null // This is set upon injection in the helper component
    };

    DrawerService.injectComponent({
      component: SovHelperComponent,
      data: this.helperParams,
      drawerRules: (_state) => this.drawerRules(_state)
    });

    if (this.drilldowns.length) {
      DrawerService.peek();
    } else {
      DrawerService.clear();
    }
  }

  setupChart() {
    this.columnParams = {
      agChartOptions: null,
      parent: this,
      parentActivity: null,
      rawData$: this.columnData$,
      highlightDatum$: new BehaviorSubject(null),
      promoUPCs: this.promoUPCSet
    };
    this.columnConfig = SovColumnChartProcessor.generateBarColumnConfig(this.columnParams);

  }

  // There are only 2 metrics to toggle between, and both are compatible with PSPW, so nothing else needs to be done
  setupFacts() {
    if (!this.facts) this.facts = [
      new CmsMetric({
        id: KPI.TOTAL_AMOUNT,
        display: 'Total Dollars',
        type: 'DOLLAR',
        aggType: 'LS',
        active: true,
      }),
      new CmsMetric({
        id: KPI.TOTAL_QUANTITY,
        display: 'Total Units',
        type: 'VOLUME',
        aggType: 'LS',
        active: true,
      })
    ];
  }

  setupTable() {
    this.gridParams = {
      parent: this,
      data: this.dataSets[this.dataSetKey].absData,
      readyForExport$: this.readyForExport$,
      promoUPCs: this.promoUPCSet,
      sov$: this.sov$
    };
    this.gridParams.gridVisualOptions = PromoResultGridProcessor.generateGridVisualOptions(this.gridParams);
  }

  public togglePeriod(checked: boolean) {
    // Cast checked to a number (0 or 1) and set the period key
    this.periodKey = PromoConfig.compPeriods[Number(checked)];
    this.updateView();
  }

  // Since all metrics are compatible with PSPW, updateUnitType() in this component is just an alias for updateView()
  updateUnitType() {
    this.updateView();
  }

  updateView() {
    this.render$.next();
  }

  private drawerRules(_state: DrawerState): DrawerRules {
    if (_state === DrawerState.PEEK) {
      return {
        close: false,
        expand: this.hasCompleteDrilldowns()
      };
    } else {
      return {
        close: false
      };
    }
  }

  private generateChartStatResponse(dim: CmsField, data: any[]): StatResponse {
    const facts = [this.shareDeltaFact];

    const allDimVals = [];
    let vM = [];

    // calculate all delta values
    const psKey = this.config.percentShareFactMap[this.factKey.id];
    const deltaGetter = GridService.generateDeltaGetter(
      PromoService.generateValKey([PromoPeriods.PROMO, psKey]),
      PromoService.generateValKey([this.periodKey, psKey])
    );
    data.forEach((dp, i) => {
      allDimVals.push(dp[dim.id]); // Add the dimension value to the global list
      vM.push([i, deltaGetter(dp).val]);
    });

    vM.sort((a, b) => b[1] - a[1]); // Sort the data by delta value

    if (vM.length > 20) { // If the data set is larger than 20 elements, we only want the TOP 10 & BOTTOM 10
      vM = vM.slice(0, 10).concat(vM.slice(-10));
    }

    const dM = [[]]; // The complete list of dim vals can have 1000s of values, and we only need the 20 in vM
    vM.forEach((row, i) => {
      dM[0].push(allDimVals[row[0]]);
      row[0] = i;
    });

    return new StatResponse([dim], dM, facts, vM);
  }

  private generateColDefs(colDefMeta: Map<string, GridColMetadata>): ColDef[] {
    const defs: ColDef[] = [];
    const sov = this.findSov(this.dataSetKey);
    const absoluteMetrics: ColGroupDef = {
      headerName: this.factKey.display,
      children: []
    };

    {
      const colDef = this.gridService.generateDimensionColumn(sov.dimension, colDefMeta);
      colDef.cellRenderer = PromoSovDrilldownRendererComponent;
      colDef.cellClass = PromoSovDrilldownRendererComponent.CELL_CLASS;
      defs.push(colDef);
    }

    {
      const key = PromoService.generateValKey([this.periodKey, this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, key);
      colDef.field = colDef.colId = key;
      colDef.headerName = this.parent.formData[PromoConfig.periodNames[this.periodKey]].customPeriodName + ' Amount';
      absoluteMetrics.children.push(colDef);
    }

    {
      const key = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, key);
      colDef.field = colDef.colId = key;
      colDef.headerName = this.parent.formData[PromoConfig.periodNames[PromoPeriods.PROMO]].customPeriodName + ' Amount';
      absoluteMetrics.children.push(colDef);
    }

    {
      // Delta col def
      const fact = new CmsMetric({
        display: 'Change',
        type: this.factKey.type,
        aggType: AggregationType.SUM,
        id: 'PROMO_DELTA',
        active: true,
      });
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta);
      const compKey = PromoService.generateValKey([this.periodKey, this.factKey.id]);
      const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
      // Add a cellClass (based on the ref metric) so the Excel export is formatted correctly
      (<string[]>colDef.cellClass).push(GridService.ENUM_ID_PREPEND + this.factKey.id);
      this.promoService.setRendererFramework(colDef, fact);
      delete colDef.field;
      colDef.colId = fact.id;
      colDef.valueGetter = GridService.generateDeltaGetter(promoKey, compKey);
      colDef.cellClassRules = PromoService.getCellClassRules(fact);
      colDef.sort = 'desc';
      absoluteMetrics.children.push(colDef);
    }

    defs.push(absoluteMetrics);

    {
      // Percent Delta col def
      const fact = new CmsMetric({
        display: null,
        type: 'PERCENT',
        aggType: AggregationType.SUM,
        id: 'PROMO_PERCENT_DELTA',
        active: true,
      });
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta);
      const compKey = PromoService.generateValKey([this.periodKey, this.factKey.id]);
      const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
      GridService.addCellClassForRefMetric(fact, colDef);
      this.promoService.setRendererFramework(colDef, fact);

      const percentDeltaGetter = GridService.generatePercentDeltaGetter(promoKey, compKey);

      delete colDef.field;
      colDef.colId = fact.id;
      colDef.valueGetter = percentDeltaGetter;
      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
      colDef.headerName = '% Change';
      colDef.cellClassRules = PromoService.getCellClassRules(fact);
      absoluteMetrics.children.push(colDef);
    }

    const percentShareMetrics: ColGroupDef = {
      headerName: PromoConfig.kpiNameMap[this.factKey.id] + ' Share',
      children: []
    };
    const psKey = this.config.percentShareFactMap[this.factKey.id];

    {
      // % Share Pre-promo amount
      const fact = new CmsMetric({
        display: null,
        type: 'PERCENT',
        aggType: AggregationType.SUM,
        id: 'PERCENT_TOTAL',
        active: true,
      });
      const key = PromoService.generateValKey([this.periodKey, psKey]);
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta, key);
      GridService.addCellClassForRefMetric(this.factKey, colDef);

      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
      colDef.field = colDef.colId = key;
      colDef.headerName = this.parent.formData[PromoConfig.periodNames[this.periodKey]].customPeriodName + ' Amount';
      percentShareMetrics.children.push(colDef);
    }

    {
      // % Share Promo amount
      const fact = new CmsMetric({
        display: null,
        type: 'PERCENT',
        aggType: AggregationType.SUM,
        id: 'PERCENT_TOTAL',
        active: true,
      });
      const key = PromoService.generateValKey([PromoPeriods.PROMO, psKey]);
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta, key);
      GridService.addCellClassForRefMetric(this.factKey, colDef);

      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
      colDef.field = colDef.colId = key;
      colDef.headerName = this.parent.formData[PromoConfig.periodNames[PromoPeriods.PROMO]].customPeriodName + ' Amount';
      percentShareMetrics.children.push(colDef);
    }

    {
      // % Share Delta
      const fact = this.shareDeltaFact;
      const colDef = this.gridService.generateMetricColumn(fact, colDefMeta);
      GridService.addCellClassForRefMetric(fact, colDef);
      this.promoService.setRendererFramework(colDef, fact);

      const compKey = PromoService.generateValKey([this.periodKey, psKey]);
      const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, psKey]);
      delete colDef.field;
      colDef.colId = fact.id;
      colDef.valueGetter = GridService.generateDeltaGetter(promoKey, compKey);
      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
      colDef.headerName = 'Share Change';
      colDef.cellClassRules = PromoService.getCellClassRules(fact);
      percentShareMetrics.children.push(colDef);
    }

    defs.push(percentShareMetrics);

    return defs;
  }

  private getDataSetKey(job: AppResponseDataset): string {
    return job.getName().replace('SOV-', '');
  }

  private hasCompleteDrilldowns(): boolean {
    return !!this.drilldowns.find(dr => !!dr.data);
  }

  private poll() {
    const req = [];

    this.drilldowns.filter(dr => !dr.data).forEach(dr => {
      req.push(this.activityService.getActivity({
        id: dr.id,
        resultType: ActivityResultType.POLL,
        suppressTopLoadingBar: true,
      }));
    });

    forkJoin(req)
      .subscribe((activities: Activity[]) => {
        let continuePolling = false; // Flag to true if at least one activity is still incomplete

        activities.forEach(async a => {
          if (a && a.isComplete()) {
            // If activity is complete, process the activity and inject the processed dataset into the drilldown model

            const results = await this.activityService.getActivityAsync({
              id: a.getId(),
              suppressTopLoadingBar: true,
              resultType: ActivityResultType.POLL
            });
            const drilldown = _.find(this.drilldowns, dr => dr.id === results.getId());

            if (drilldown) {
              const enableHelperTable = !this.hasCompleteDrilldowns();

              drilldown.data = this.promoService.generateSOVHelperData(results, this.parent.activity, this.parent.formData);
              drilldown.activity = results;
              this.notificationService.success(
                `Your drilldown for ${drilldown.label} is done!`,
                'Drilldown Done',
                {
                  data: {
                    // TODO: extend functionality for viewing from outside SOV sheet
                    onClick: () => this.drilldown(drilldown.label)
                  }
                });

              if (enableHelperTable) {
                this.drilldownTrigger$.next(drilldown.label);

                if (this.isActive()) {
                  DrawerService.peek();
                }
              }
            } else {
              console.warn('No matching SOVDrilldown model found!', results);
            }

          } else {
            // If any activity in the poll list is still incomplete, set flag to continue polling
            continuePolling = true;
          }
        });

        if (continuePolling) {
          // Recursively call itself after an interval
          setTimeout(() => this.pollDrilldowns$.next(), 5000); // TODO: lower this once BE is optimized with transaction locks
        }
      });
  }

  private viewDrilldown(label: string) {
    const _state = DrawerService.getState();

    if (!_state || _state === DrawerState.PEEK) {
      DrawerService.open().then(() => this.drilldownTrigger$.next(label));
    } else {
      // Drawer already open
      this.drilldownTrigger$.next(label);
    }
  }

}

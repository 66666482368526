import { registerAppIcons } from '@siq-js/assets-lib';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { ApplicationHash, GroupedArrayFunctions, GroupedArray } from '@siq-js/core-lib';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CmsApplication } from '@siq-js/cms-lib';

@Injectable({ providedIn: 'root' })
export class ApplicationService {

  public static Applications$: BehaviorSubject<GroupedArray<CmsApplication>> = new BehaviorSubject<GroupedArray<CmsApplication>>(null);
  public static flattenedApplications: CmsApplication[];

  public static find(id: ApplicationHash): CmsApplication {
    return ApplicationService.flattenedApplications.find(app => app.id === id);
  }

  constructor(
    private asyncStatusService: AsyncStatusService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
  ) {
    registerAppIcons(matIconRegistry, domSanitizer);
    this.init();
  }

  private async init() {
    await this.asyncStatusService.isReady({ cms: true });

    const appsOrder = CmsService.get().applicationsOrder;
    const activeApps = GroupedArrayFunctions.filter(appsOrder, (el) => el.active);
    ApplicationService.flattenedApplications = GroupedArrayFunctions.flatten(activeApps);
    ApplicationService.Applications$.next(activeApps);
  }
}

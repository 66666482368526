import * as _ from 'lodash';
import { BaseModel } from 'app/core/models/base-model';
import { NewUserService } from 'app/core/services/new-user/new-user.service';
import { UserPayloadInterface } from 'app/user/models/user-payload-interface';
import { AuthService } from 'app/auth/services/auth.service';
import { CustomInjector } from 'app/core/models/custom-injector';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { isDevMode } from '@angular/core';

export class User extends BaseModel {
  active = true;
  address: string = null;
  address2: string = null;
  agencyId: number = null;
  authenticated = false;
  city: string = null;
  clientId: string = null;
  clientSecret: string = null;
  company: string = null;
  country: string = null;
  email: string = null;
  firstName: string = null;
  id: number = null;
  lastName: string = null;
  organizationId: number = null;
  password: string = null;
  passwordExpired = false;
  phoneNumber: string = null;
  properties: any = {};
  securityQuestionCount: number = null;
  securityQuestions = [];
  siqApi: any = {};
  token: string = null;
  zipCode: string = null;
  accessGroup: string = null;
  siteUserId?: string;

  static fromJson<T>(j: any, parseKeys?: string[]): User {
    return <User><any> super.typedObjectFromJson(j, User, parseKeys);
  }

  constructor(userObj?: any) {

    super();

    if (userObj) {
      _.extend(_.assign(this, userObj), userObj);
    }

  }

  public getUserAsPayload(removeId?: boolean): UserPayloadInterface {

    const payload = <UserPayloadInterface>{
      active: this.active,
      address: this.address,
      address2: this.address2,
      agencyId: this.agencyId,
      authenticated: this.authenticated,
      city: this.city,
      company: this.company,
      country: this.country,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      organizationId: this.organizationId,
      password: this.password,
      phoneNumber: this.phoneNumber,
      zipCode: this.zipCode,
      accessGroup: this.accessGroup,
      userRole: 'DEVELOPER'
    };

    if (!removeId) {
      payload.id = this.id;
    }

    return payload;

  }

  public getName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public isInternal(): boolean {
    //return (this.email.indexOf('@swiftiq.com') > -1) || ((location.hostname === "localhost" || location.hostname === "127.0.0.1") && (isDevMode()));
    return (this.email.indexOf('@swiftiq.com') > -1);
  }

  public isValid(): boolean {
    return !this.passwordExpired && this.securityQuestionCount === NewUserService.securityQuestionsCount;
  }

  public async isAdmin(): Promise<boolean> {
    await CustomInjector.injector.get(AsyncStatusService).isReady({envConfig: true});

    return new Promise(
      (resolve, reject) => {
        try {
          const isAdmin = this.active && (this.accessGroup.toString() === AuthService.AdminGroupId);
          resolve(isAdmin);
        } catch (e) {
          reject();
        }
      });
  }

}

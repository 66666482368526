import { Pipe, PipeTransform } from '@angular/core';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

@Pipe({
  name: 'dateToString'
})
export class DateToStringPipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    return DatesService.format(value, (args || AppSiqConstants._dateFormat));
  }

}

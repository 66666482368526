<div
  class="heatmap-legend"
  [ngClass]="{'inline': inlineLegend}"
>
  <div>
    <mat-form-field color="accent">
      <mat-label>Heatmap Metric</mat-label>
      <mat-select [(value)]="gridComponent.heatmapColDef" (selectionChange)="selectHeatmapColDef($event.value)">
        <mat-option *ngFor="let colDef of gridComponent.metricColDefs" [value]="colDef">
          {{ colDef.headerName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="legend">
    <canvas #heatmapLegend></canvas>
  </div>

  <div class="push-down">
    <button
      mat-stroked-button
      (click)="clear()"
      *ngIf="gridComponent.heatmapColDef && !inlineLegend"
    >
      Clear
    </button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from 'app/profile/routes/profile-routing.module';
import { CoreModule } from 'app/core/core.module';
import { ProfileComponent } from 'app/profile/components/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ProfileComponent
  ]
})
export class ProfileModule { }

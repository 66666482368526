import { Activity } from 'app/activity/models/activity.model';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, tap } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestBus } from 'app/core/models/request-bus';
import { NotificationService, ResponseCodes } from '@siq-js/angular-buildable-lib';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';

@Injectable()
export class SiqApplicationService {

  private static readonly RequestBus = new RequestBus<Activity>({
    maxConcurrency: 1
  });

  public ngUnsubscribe: Subject<void> = new Subject<void>();

  protected http: SiqHttpService;
  private overrideCodes = new ResponseCodes();

  constructor(
    protected activityService: ActivityService,
    protected notificationService: NotificationService
  ) {
  }

  createActivity(appRequest: AppRequest, path: string, suppressNotification?: boolean): Observable<HttpResponse<any>> {
    return this.activityService
      .createActivity(appRequest, path, suppressNotification)
      .pipe(tap(resp =>  {
        if (!suppressNotification) {
          this.httpNotify(resp.status, this.overrideCodes);
        }
      }));

    }

  public httpNotify (status: number, codes?: ResponseCodes ) {
    this.notificationService.showHttpNotification(status, codes);
  }

  public getActivity (id: string, resultType: ActivityResultType = ActivityResultType.NO_RESULTS): Observable<Activity> {

    let resultsObservable: Observable<Activity>;

    if (resultType !== ActivityResultType.NO_RESULTS) {

      resultsObservable = Observable.create(obs => {

        const request = () => {
          this.activityService.getActivity({
            id: id,
            resultType: resultType
          })
            .pipe(first())
            .subscribe(res => {
              if (res.isComplete()) {
                obs.next(res);
              } else {
                setTimeout(() => request(), 5000);
              }
            });
        };

        request();

      });
    } else {
      resultsObservable = this.activityService.getActivity({
        id: id,
        resultType: ActivityResultType.WITH_RESULTS
      });
    }

    return SiqApplicationService.RequestBus.request(
      id,
      resultsObservable
    ).pipe(filter(i => !!i));
  }

  getActivityResults (id: string, suppressTopLoadingBar?: boolean): Observable<Activity> {
    return SiqApplicationService.RequestBus.request(
      'RESULTS_' + id,
      new Observable(obs => {

        const request = this.activityService.getActivityResults(id, suppressTopLoadingBar);

        const doRequest = () => {
          request.subscribe(res => {
            if (res.isComplete()) {
              obs.next(res);
            } else {
              setTimeout(doRequest, 5000);
            }
          });
        };

        doRequest();

      })
    ).pipe(filter(i => !!i));
  }

  getActivityResultsPartial (id: string, jobIndexes: number[], suppressTopLoadingBar?: boolean): Observable<Activity> {
    return this.activityService.getActivityResultsPartial(id, jobIndexes, suppressTopLoadingBar);
  }

  public init(bs: BehaviorSubject<Activity>, activityId?: string): void {
  }
}

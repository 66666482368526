import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityModule } from 'app/activity/activity.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { CoreModule } from 'app/core/core.module';
import { GridModule } from 'app/grid/grid.module';
import { SiqFormsModule } from 'app/siq-forms/siq-forms.module';
import { FilterModule } from 'app/filter/filter.module';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';
import { AffinitiesRoutingModule } from './routes/affinities-routing.module';
import { AffinitiesListComponent } from './components/affinities-list/affinities-list.component';
import { AffinitiesFormComponent } from './components/affinities-form/affinities-form.component';
import { AffinitiesConfig } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { AffinitiesQueryModeModalComponent } from './components/affinities-query-mode-modal/affinities-query-mode-modal.component';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { AffinitiesCriteriaRendererComponent } from './components/affinities-criteria-renderer/affinities-criteria-renderer.component';
import { AffinitiesStepperComponent } from './components/affinities-stepper/affinities-stepper.component';
import { AffinitiesResultComponent } from './components/affinities-result/affinities-result.component';
import { AffinitiesDrilldownComponent } from './components/affinities-drilldown/affinities-drilldown.component';
import { AgGridModule, VisualLibModule } from '@siq-js/visual-lib';
import { AffinitiesCloneResolverService } from 'app/siq-applications/modules/affinities/services/affinities-clone-resolver.service';

@NgModule({
  declarations: [
    AffinitiesListComponent,
    AffinitiesFormComponent,
    AffinitiesQueryModeModalComponent,
    AffinitiesCriteriaRendererComponent,
    AffinitiesStepperComponent,
    AffinitiesResultComponent,
    AffinitiesDrilldownComponent
  ],
  imports: [
    ActivityModule,
    FormsModule,
    CommonModule,
    SharedModule,
    CoreModule,
    GridModule,
    SiqFormsModule,
    AgGridModule,
    FilterModule,
    DatesModule,
    AffinitiesRoutingModule,
    VisualLibModule
  ],
  providers: [
    AffinitiesConfig,
    AffinitiesService,
    AffinitiesCloneResolverService
  ]
})
export class AffinitiesModule { }

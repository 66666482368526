import { Component } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AgRendererComponent } from 'ag-grid-angular';

 /**
  * @deprecated Not in use any more
  */
@Component({
  selector: 'siq-js-grouping-disabled',
  templateUrl: './grouping-disabled.component.html',
  styleUrls: ['./grouping-disabled.component.scss']
})
export class GroupingDisabledComponent extends BaseSiqComponent implements AgRendererComponent {

  public value: string;

  constructor() {
    super();
  }

  agInit(params) {
    this.value = params.value;
  }

  refresh() {
    return true;
  }
}

<ng-container [ngSwitch]="validationMessage.status">
  <ng-container *ngSwitchCase="validationMessageStatus.VALID">
    <div class="step-form-message-container valid-form-status">
      <span>{{validationMessage.message}}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="validationMessageStatus.INVALID">
    <div class="step-form-message-container invalid-form-status">
      <span>{{validationMessage.message}}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="step-form-message-container unset-form-status">
      <span>{{validationMessage.message}}</span>
    </div>
  </ng-container>

</ng-container >
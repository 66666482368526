export enum ReportBuilderColumnType {
  METRIC = 'metric',
  DIMENSION = 'dimension'
}

export enum RBMetricColumn {
  TIME_AGGREGATE = 'time-agg',
  YEAR_OVER_YEAR = 'yoy',
  NORMAL_METRIC = 'report-builder-col'
}

import { Component, OnInit, Inject } from '@angular/core';
import { QueryModeModalComponent, QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmsField } from '@siq-js/cms-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { CmsService } from 'app/core/services/cms/cms.service';
import { IncrementalsFormData } from 'app/siq-applications/modules/incrementals/models/form/incrementals-form-data.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Summary {
  incrementalType: CmsField;
  productFilters: FilterSelection[];
  locationFilters: FilterSelection[];
  locationFilterValues: FilterSelection[];
}

@Component({
  selector: 'siq-js-incrementals-query-mode-modal',
  templateUrl: './incrementals-query-mode-modal.component.html',
  styleUrls: ['./incrementals-query-mode-modal.component.scss']
})
export class IncrementalsQueryModeModalComponent extends QueryModeModalComponent implements OnInit {
  public userGroupChanged: boolean;
  public summary: Summary;

  constructor(
    protected dialogRef: MatDialogRef<IncrementalsQueryModeModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected modalData: QueryModeModalData
  ) {
    super(dialogRef, modalData);
  }

  ngOnInit(): void {
    const form: IncrementalsFormData = this.modalData.data;
    const schema = this.modalData.schema;
    this.userGroupChanged = !!this.modalData.userGroupChanged;

    // generate each fields for Summary
    const incrementalSummary = form.incrementalType ? (CmsService.isValidField(form.incrementalType, schema) ? null : form.incrementalType) : null;
    const productFilterSummary = form.productFilters.filter(fs => !CmsService.isValidField(fs.field, schema));
    const locationFilterSummary = form.locationFilters.filter(fs => !CmsService.isValidField(fs.field, schema));

    const filterValueSummary = [];
    form.locationFilters.forEach(fs => {
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        const tmp = new FilterSelection(fs.toJson());
        tmp.values = fs.values.filter(val =>!retailers.includes(val));
        if (tmp.values.length) {
          filterValueSummary.push(tmp);
        }
      }
    });

    this.summary = {
      incrementalType: incrementalSummary,
      productFilters: productFilterSummary,
      locationFilters: locationFilterSummary,
      locationFilterValues: filterValueSummary
    };

    this.ready();
  }

}

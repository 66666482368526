<siq-app-header [config]="config"></siq-app-header>

<!-- Spinning wheel -->
<div class="loading-overlay" *ngIf="showSpinner">
  <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
</div>

<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="statusEnums.ALERT">

    <siq-js-alert-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="errorAndInvalidOptionMenuItems"
      [reportActivityName]="applicationName"
    ></siq-js-alert-overlay>

  </div>
  <div *ngSwitchCase="statusEnums.ERROR">

    <siq-js-error-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [appErrorMessage]="reportActivity.errorsDetails[0]"
      [reportActivityName]="applicationName"
      [menuItems]="errorAndInvalidOptionMenuItems"
    ></siq-js-error-overlay>

  </div>

  <ng-container *ngSwitchDefault>

    <!-- Query mode "Analysis Mode" -->
    <siq-query-mode #queryMode [disabled]="true"></siq-query-mode>
    <!-- Header with "Option" -->
    <siq-activity-results
      [suppressOptions]="false"
      [hideHeader]="false"
      [isReady] = "ready$ | async"
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="customOptionMenuItems"
    >
    </siq-activity-results>

    <mat-toolbar *ngIf="ready$ | async">
      <mat-slide-toggle [(ngModel)]="advancedMetrics" (change)="showAdvancedMetrics($event)">Advanced Metrics
      </mat-slide-toggle>
    </mat-toolbar>

    <siq-js-grid
      *ngIf="ready$ | async"
      [config]="gridParams.gridVisualOptions.gridConfig"
      [data]="gridParams"
      [processor]="gridProcessor"
      (featureUsed)="gridFeatureUsed($event)"
    ></siq-js-grid>

  </ng-container>

</ng-container>

<mat-form-field>
  <textarea
    matInput
    [formControl]="formControl"
    [placeholder]="placeholder"
    [errorStateMatcher]="matcher"
    [rows]="rows"
  ></textarea>
  <mat-error *ngIf="formControl.hasError('white-space-only')">
    {{ formControl.errors['white-space-only'].message }}
  </mat-error>
  <mat-hint align="end" [class]="formControl.valid ? '' : 'error'">{{ model?.length || 0 }}/{{ maxLength }}</mat-hint>
</mat-form-field>

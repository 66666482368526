import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicRoutingModule } from 'app/public/routes/public-routing.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { ForgotPasswordService } from 'app/public/services/forgot-password.service';
import { FormsModule } from '@angular/forms';
import { CoreModule } from 'app/core/core.module';
import { PasswordRulesComponent } from 'app/siq-forms/components/password-rules/password-rules.component';
import { UnsubscribedComponent } from './components/unsubscribed/unsubscribed.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PublicRoutingModule,
    SiqMaterialModule,
    FormsModule
  ],
  declarations: [ ForgotPasswordComponent, UnsubscribedComponent, MaintenanceComponent ],
  providers: [ ForgotPasswordService ]
})
export class PublicModule { }

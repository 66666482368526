import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { Injectable } from '@angular/core';
import { ApplicationHash } from '@siq-js/core-lib';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { SortOptions } from '@siq-js/visual-lib';

export enum INC_ENUMS {
  STORE_COUNT = 'INC_STORE_COUNT',
  NUM_BASKETS = 'INC_NUM_BASKETS',
  PERCENT_DISTRIBUTION = 'INC_PERCENT_DISTRIBUTION',
  TOTAL_BASKET_CNT = 'INC_TOTAL_BASKET_CNT',
  TOTAL_ITEM_BASKET_CNT = 'INC_TOTAL_ITEM_BASKET_CNT',
  TOTAL_NONITEM_BASKET_CNT = 'INC_TOTAL_NONITEM_BASKET_CNT',
  AVG_BASKET_CNT = 'INC_AVG_BASKET_CNT',
  AVG_ITEM_BASKET_CNT = 'INC_AVG_ITEM_BASKET_CNT',
  AVG_NONITEM_BASKET_CNT = 'INC_AVG_NONITEM_BASKET_CNT',
  TOTAL_BASKET_AMT = 'INC_TOTAL_BASKET_AMT',
  TOTAL_ITEM_BASKET_AMT = 'INC_TOTAL_ITEM_BASKET_AMT',
  TOTAL_NONITEM_BASKET_AMT = 'INC_TOTAL_NONITEM_BASKET_AMT',
  AVG_BASKET_AMT = 'INC_AVG_BASKET_AMT',
  AVG_ITEM_BASKET_AMT = 'INC_AVG_ITEM_BASKET_AMT',
  AVG_NONITEM_BASKET_AMT = 'INC_AVG_NONITEM_BASKET_AMT',
  PERCENT_ITEM_BASKET_CNT = 'INC_PERCENT_ITEM_BASKET_CNT',
  PERCENT_ITEM_BASKET_AMT = 'INC_PERCENT_ITEM_BASKET_AMT'
}
@Injectable()
export class IncrementalsConfig extends SiqApplicationConfig {
  public static readonly ABSOLUTE = 'Absolute';
  public static readonly INCREMENTAL_GROUP = 'Incremental Group';
  public static readonly NUMBER_SELLING_STORE_WEEKS = 'Number of Selling Store Weeks';
  public static readonly PER_STORE_WEEK = 'Per Store Per Week';
  public static readonly PERCENT_STORE_DISTRIBUTION = 'Percent Store Distribution';

  public static PER_STORE_WEEK_METRICS = [
    'Number of Transactions',
    'Unit Sales - All Items',
    'Unit Sales - List Item',
    'Unit Sales - Other Items',
    'Dollar Sales - All Items',
    'Dollar Sales - List Item',
    'Dollar Sales - Other Items'
  ];
  public static readonly PER_STORE_WEEK_DATA = 'PerStorePerWeek';
  public static readonly CUSTOM_ERROR_MSG = 'Current report’s size exceeded the limit. Please narrow the size of the report by applying more filters.';
  public actions: ActivityOptionsActions[] = [
    ActivityOptionsActions.CLONE,
    ActivityOptionsActions.RESULTS,
    ActivityOptionsActions.SHARE,
    ActivityOptionsActions.DELETE
  ];
  public appId = ApplicationHash.INCREMENTALS;
  public defaultSort: SortOptions;
  public factOrder: string[];

  public pollInterval = 2000;
  public tooltips: Map<string, string | ((params: any) => string)>;

  constructor() {
    super();

    this.defaultSort = {
      sort: 'desc',
      byKey: INC_ENUMS.NUM_BASKETS
    };

    this.factOrder = [
      INC_ENUMS.STORE_COUNT,
      INC_ENUMS.PERCENT_DISTRIBUTION,
      INC_ENUMS.NUM_BASKETS,
      INC_ENUMS.TOTAL_BASKET_CNT,
      INC_ENUMS.TOTAL_ITEM_BASKET_CNT,
      INC_ENUMS.TOTAL_NONITEM_BASKET_CNT,
      INC_ENUMS.TOTAL_BASKET_AMT,
      INC_ENUMS.TOTAL_ITEM_BASKET_AMT,
      INC_ENUMS.TOTAL_NONITEM_BASKET_AMT,
      INC_ENUMS.AVG_BASKET_CNT,
      INC_ENUMS.AVG_ITEM_BASKET_CNT,
      INC_ENUMS.AVG_NONITEM_BASKET_CNT,
      INC_ENUMS.AVG_BASKET_AMT,
      INC_ENUMS.AVG_ITEM_BASKET_AMT,
      INC_ENUMS.AVG_NONITEM_BASKET_AMT,
      INC_ENUMS.PERCENT_ITEM_BASKET_CNT,
      INC_ENUMS.PERCENT_ITEM_BASKET_AMT
    ];
    this.setupTooltips();

  }
  setupTooltips() {
    this.tooltips = new Map<string, string>();

    this.tooltips.set('Store Count',
      'Number of stores where the list item has sold at least once during the selected time frame');

    this.tooltips.set('Number of Transactions',
      'The total number of transactions where the list item(s) sold');

    this.tooltips.set('Percent Store Distribution',
      'Percentage of stores where the list item has sold at least once during the selected time frame');

    this.tooltips.set('Unit Sales - All Items',
      'Total volume sales of all items in baskets that include the list item(s)');

    this.tooltips.set('Unit Sales - List Item',
      'Total volume sales of the list item(s)');

    this.tooltips.set('Unit Sales - Other Items',
      'Total volume sales of other item(s) in baskets that include the list item(s)');

    this.tooltips.set('Dollar Sales - All Items',
      'Total dollar sales of all items in baskets that include the list item(s)');

    this.tooltips.set('Dollar Sales - List Item',
      'Total dollar sales of the list item(s)');

    this.tooltips.set('Dollar Sales - Other Items',
      'Total dollar sales of other item(s) in baskets that include the list item(s)');

    this.tooltips.set('Avg Basket Size (#) - All Items',
      'Average number of all items sold per basket where the list item(s) have been sold');

    this.tooltips.set('Avg Basket Size (#) - List Item',
      'Average number of the list item(s) sold per basket');

    this.tooltips.set('Avg Basket Size (#) - Other Items',
      'Average number of other item(s) sold per basket where the list item(s) have been sold');

    this.tooltips.set('Avg Basket Size ($) - All Items',
      'Average dollar amount of all items sold per basket where the list item(s) have been sold');

    this.tooltips.set('Avg Basket Size ($) - List Item',
      'Average dollar amount of the list item(s) sold per basket');

    this.tooltips.set('Avg Basket Size ($) - Other Items',
      'Average dollar amount of other item(s) sold per basket where the list item(s) have been sold');

    this.tooltips.set('% of Basket Size (#) - List Item',
      'Percentage of the volume (# of items) the list item(s) makes compared to all items sold in a basket');

    this.tooltips.set('% of Basket Size ($) - List Item',
      'Percentage of the dollar sales ($ of items) the list item(s) makes compared to all items sold in a basket');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from 'app/core/modules/drawer/components/drawer.component';
import { DrawerService } from 'app/core/modules/drawer/services/drawer.service';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';

@NgModule({
  declarations: [
    DrawerComponent
  ],
  exports: [
    DrawerComponent
  ],
  providers: [
    DrawerService
  ],
  imports: [
    CommonModule,
    SiqMaterialModule
  ]
})
export class DrawerModule { }

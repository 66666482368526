import { CmsApplication, CmsApplicationJson } from '@siq-js/cms-lib';

export class FiltersetAppHeaderModel {
  mockModel: any;

  constructor() {
    this.mockModel = new CmsApplication(this.getJson());
  }

  private getJson(): CmsApplicationJson {
    return {
      display: 'Filtersets',
      path: ''
    } as CmsApplicationJson;
  }

  public getApplication() {
    return this.mockModel;
  }
}

<mat-action-row>
  <button
    *ngIf="showPrev"
    mat-button
    color="accent"
    type="button"
    (click)="onClick('prev')"
  >
    Previous
  </button>

  <button
    *ngIf="showNext"
    mat-button
    color="accent"
    type="button"
    (click)="onClick('next')"
  >
    Next
  </button>    
</mat-action-row>
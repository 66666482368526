<div class="container">

  <div class="text-wrapper">
    <label>{{ dimValue }}</label>
  </div>

  <div class="actions">
    <mat-icon class="add-icon hover-only" *ngIf="canDrill && !parent.drilldownsMap[label]" (click)="drilldown()">+</mat-icon>
    <ng-container *ngIf="parent.drilldownsMap[label]">
      <mat-progress-spinner *ngIf="!parent.drilldownsMap[label].activity" [diameter]="18" color="accent" mode="indeterminate"></mat-progress-spinner>
      <mat-icon class="done-icon" *ngIf="parent.drilldownsMap[label].activity" (click)="drilldown()">done</mat-icon>
    </ng-container>
  </div>

</div>

import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityResultsActions } from 'app/siq-applications/modules/shared/components/activity-results/activity-results-actions.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FavoritesDetailComponent } from 'app/activity/modules/favorites/favorites-detail/favorites-detail.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { SiqApplicationActivity } from 'app/siq-applications/modules/shared/models/siq-application-activity';
import { SiqApplicationService } from 'app/siq-applications/modules/shared/services/siq-application.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { AuthService } from 'app/auth/services/auth.service';
import { ActivityService } from 'app/activity/services/activity.service';

@Component({
  selector: 'siq-activity-results',
  templateUrl: './activity-results.component.html',
  styleUrls: ['./activity-results.component.scss']
})

export class ActivityResultsComponent extends SiqApplicationActivity implements OnInit {

  @Input() actions: ActivityResultsActions[];
  @Input() suppressOptions: boolean;
  @Input() hideHeader: boolean;
  @Input() menuItems: string[];
  @Input() isReady: boolean;
  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  public activityResultsActions = ActivityResultsActions;
  public readonly: boolean;

  constructor(
    public router: Router,
    private utils: UtilsService,
    public route: ActivatedRoute,
    public sharingService: SharingService,
    public authService: AuthService,
    activityService: ActivityService
  ) {
    super(route, activityService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.actions || _.isEmpty(this.actions)) {
      this.actions = _.map(ActivityResultsActions) as ActivityResultsActions[];
    }
    this.activity.subscribe((a) => {
      this.readonly = !a.isMine();
    });
  }

  favorite () {
    this.utils.openModal(FavoritesDetailComponent, this.activity.value);
  }

  share(): void {
    this.sharingService.share(this.activity.getValue());
  }
}

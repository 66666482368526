import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityModule } from 'app/activity/activity.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { CoreModule } from 'app/core/core.module';
import { GridModule } from 'app/grid/grid.module';
import { SiqFormsModule } from 'app/siq-forms/siq-forms.module';
import { FilterModule } from 'app/filter/filter.module';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';
import { IncrementalsService } from 'app/siq-applications/modules/incrementals/services/incrementals.service';
import { IncrementalsConfig } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { IncrementalsRoutingModule } from './routes/incrementals-routing.module';
import { IncrementalsListComponent } from './components/incrementals-list/incrementals-list.component';
import { IncrementalsFormComponent } from './components/incrementals-form/incrementals-form.component';
import { IncrementalsCriteriaRendererComponent } from './components/incrementals-criteria-renderer/incrementals-criteria-renderer.component';
import { IncrementalsQueryModeModalComponent } from './components/incrementals-query-mode-modal/incrementals-query-mode-modal.component';
import { IncrementalsStepperComponent } from './components/incrementals-stepper/incrementals-stepper.component';
import { VisualLibModule, AgGridModule } from '@siq-js/visual-lib';
import { IncrementalsResultComponent } from './components/incrementals-result/incrementals-result.component';
import { IncrementalsCloneResolverService } from 'app/siq-applications/modules/incrementals/services/incrementals-clone-resolver.service';

@NgModule({
  declarations: [
    IncrementalsListComponent,
    IncrementalsFormComponent,
    IncrementalsCriteriaRendererComponent,
    IncrementalsQueryModeModalComponent,
    IncrementalsStepperComponent,
    IncrementalsResultComponent
  ],
  imports: [
    ActivityModule,
    FormsModule,
    CommonModule,
    SharedModule,
    CoreModule,
    GridModule,
    SiqFormsModule,
    AgGridModule,
    FilterModule,
    DatesModule,
    IncrementalsRoutingModule,
    VisualLibModule
  ],
  providers: [
    IncrementalsService,
    IncrementalsConfig,
    IncrementalsCloneResolverService
  ]
})
export class IncrementalsModule { }

import {
  add,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  Duration,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  formatDistance,
  getDay,
  getMonth,
  getQuarter,
  getWeek,
  getYear,
  Interval,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameYear,
  parse,
  setDay,
  setHours,
  setMinutes,
  setMonth,
  setQuarter,
  setWeek,
  setYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear
} from 'date-fns';
// import { CoreConstants } from '../models/core-constants'; //TODO: i.e use CoreConstants._dateFormat instead AppSiqConstants._dateFormat since 'core' is single point of truth
// import { DateRangeInterface, DateRangeInterfaceJson } from 'app/siq-forms/modules/dates/models/interfaces'; //NOTE: cannot move due to DatePickerComponent
import { DateUnit, WeekEndingDay } from '@siq-js/core-lib';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export class DatesServiceCore {

  constructor() {
  }

  public static dateMStoS(ms: number): number {
    if (!ms)
      return ms; //0, null or undefined
    return ms / 1000;
  }

  public static dateStoMS(s: number): number {
    if (!s)
      return s; //0, null or undefined
    return s * 1000;
  }

  public static getEndOf(unit: DateUnit) {
    switch (unit) {
      case DateUnit.YEAR:
        return endOfYear;
      case DateUnit.MONTH:
        return endOfMonth;
      case DateUnit.WEEK:
        return endOfWeek;
      case DateUnit.DAY:
        return endOfDay;
      default:
        console.warn('No endOf function is found for unit:', unit);
        break;
    }
  }

  public static getDifference(unit: DateUnit) {
    switch (unit) {
      case DateUnit.YEAR:
        return differenceInYears;
      case DateUnit.MONTH:
        return differenceInMonths;
      case DateUnit.WEEK:
        return differenceInWeeks;
      case DateUnit.DAY:
        return differenceInDays;
      case DateUnit.QUARTER:
        return differenceInQuarters;
      default:
        console.warn('No difference function is found for unit:', unit);
        break;
    }
  }

  public static getValue(unit: DateUnit) {
    switch (unit) {
      case DateUnit.YEAR:
        return getYear;
      case DateUnit.MONTH:
        return getMonth;
      case DateUnit.WEEK:
        return getWeek;
      case DateUnit.DAY_OF_WEEK:
        return getDay;
      case DateUnit.QUARTER:
        return getQuarter;
      default:
        console.warn('No getvalue function is found for unit:', unit);
        break;
    }
  }

  public static setValue(unit: DateUnit) {
    switch (unit) {
      case DateUnit.YEAR:
        return setYear;
      case DateUnit.MONTH:
        return setMonth;
      case DateUnit.WEEK:
        return setWeek;
      case DateUnit.DAY:
        return setDay;
      case DateUnit.QUARTER:
        return setQuarter;
      case DateUnit.HOUR:
        return setHours;
      case DateUnit.MINUTE:
        return setMinutes;
      default:
        console.warn('No setvalue function is found for unit:', unit);
        break;
    }
  }
  
  public static getStartOf(unit: DateUnit) {
    switch (unit) {
      case DateUnit.YEAR:
        return startOfYear;
      case DateUnit.MONTH:
        return startOfMonth;
      case DateUnit.WEEK:
        return startOfWeek;
      case DateUnit.DAY:
        return startOfDay;
      case DateUnit.QUARTER:
        return startOfQuarter;
      case DateUnit.TODAY:
        return startOfToday;
      default:
        console.warn('No startof function is found for unit:', unit);
        break;
    }
  }

  public static getIsSame(unit: DateUnit) {
    switch (unit) {
      case DateUnit.YEAR:
        return isSameYear;
      case DateUnit.MONTH:
        return isSameMonth;
      case DateUnit.WEEK:
        return isSameWeek;
      case DateUnit.DAY:
        return isSameDay;
      case DateUnit.QUARTER:
        return isSameQuarter;
      case DateUnit.HOUR:
        return isSameHour;
      case DateUnit.MINUTE:
        return isSameMinute;
      default:
        console.warn('No isSame function is found for unit:', unit);
        break;
    }
  }


  public static format(date: Date|number, formatStr: string, options?: Object): string {
    return format(date, formatStr, options);
  }

  public static formatDistance(date: Date | number, baseDate: Date | number, options?: Object): string {
    return formatDistance(date, baseDate, options);
  }

  public static eachDayOfInterval(interval: Interval, options?: Object): Date[] {
    return eachDayOfInterval(interval, options);
  }

  public static getWeekdays(format = 'EEEE'): string[] {
    const now = new Date();
    return this.eachDayOfInterval({start: this.getStartOf(DateUnit.WEEK)(now), end: this.getEndOf(DateUnit.WEEK)(now)}).map(d => this.format(d, format));
  }

  // https://github.com/marnusw/date-fns-tz#utctozonedtime
  public static utcToZonedTime(date: Date | number | string, timeZone: string): Date {
    return utcToZonedTime(date, timeZone)
  }
  // https://github.com/marnusw/date-fns-tz#zonedtimetoutc
  public static zonedTimeToUtc(date: Date | number | string, timeZone: string): Date {
    return zonedTimeToUtc(date, timeZone)
  }

  public static parse(dateStr: string, formatStr: string, refDate: Date|number, options?: Object): Date {
    return parse(dateStr, formatStr, refDate, options);
  }

  public static add(date: Date|number, duration: Duration): Date {
    return add(date, duration);
  }

  public static isBefore(date: Date|number, dateToCompare: Date|number): boolean {
    return isBefore(date, dateToCompare);
  }
  
  public static isAfter(date: Date|number, dateToCompare: Date|number): boolean {
    return isAfter(date, dateToCompare);
  }

  public static getDynamicDateWithWeekEndingString(dynamicDate: string, weekEndingDay: WeekEndingDay): string {
    return dynamicDate + ' WE_' + (weekEndingDay === WeekEndingDay.OLD_REPORT ? WeekEndingDay.SAT : weekEndingDay).toUpperCase();
  }

}

<img src="assets/img/PDI Logo-RGB-Two Color-with Tag-Digital.png" />

<ng-container *ngIf="type === maintenanceType.DATA">
  <div>
    <h2>Data Maintenance</h2>

    <div class="message">
      <p>
        We are currently updating some of the core data that drives the Insights Cloud platform.
      </p>
      <p>
        Once maintenance has been completed, access to the Insights Cloud platform will be restored.
      </p>
      <p>
        Thank you for your patience and understanding.
      </p>
      <p *ngIf="!!entities?.length">
        Data Updates:
        <span class="data-entities">
          <span *ngFor="let entity of entities" class="entity">{{ entity }}</span>
        </span>
      </p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === maintenanceType.OFFLINE">
  <div>
    <h2>Offline</h2>

    <div class="message">
      <p>
        The Insights Cloud platform is currently offline.
      </p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === maintenanceType.ROUTINE || !type">
  <div>
    <h2>Routine Maintenance</h2>

    <div class="message">
      <p>
        The Insights Cloud platform is currently undergoing routine maintenance.
      </p>
      <p>
        We apologize for the inconvenience and are working to quickly restore access.
      </p>
      <p>
        Thank you for your patience and understanding.
      </p>
    </div>
  </div>
</ng-container>
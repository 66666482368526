<siq-app-header *ngIf="filtersLoaded | async" [config]="appModelConfig"></siq-app-header>
<div class="app-container-wrapper no-bg">
  <section class="app-container limited-width-content content-padded">
    <mat-card>
      <mat-card-content>
        <siq-js-filtersets-grid *ngIf="filtersLoaded | async" [config]="filtersetGridConfig"></siq-js-filtersets-grid>
        <siq-js-filtersets-legend></siq-js-filtersets-legend>
      </mat-card-content>
    </mat-card>
  </section>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

@Pipe({
  name: 'relative'
})
export class RelativePipe implements PipeTransform {

  transform(value: Date, args?: any): any {

    if (value instanceof Date) {
      return DatesService.formatDistance(value, new Date(), { addSuffix: true });
    }

    return null;

  }

}

import { BaseChartSettings } from 'libs/visual-lib/src/lib/modules/charts-graphs/models';

export class SimpleBubbleProcessor {

  /**
   *
   * @param rawData
   * This static function should be overridden by derived classes
   */
  public static processor = (rawData: (any | any[])): BaseChartSettings => {
    return <BaseChartSettings>{
      agChartOptions: null,
      parentActivity: null
    }
  };
}

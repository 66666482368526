import { Component, OnInit, Inject } from '@angular/core';
import { QueryModeModalComponent, QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmsField } from '@siq-js/cms-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { AffinitiesFormData } from 'app/siq-applications/modules/affinities/models/form/affinities-form-data.model';
import { CmsService } from 'app/core/services/cms/cms.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Summary {
  affinityType: CmsField;
  secondaryAffinityType: CmsField;
  filters: FilterSelection[];
  filterValues: FilterSelection[];
  leftFilters: FilterSelection[];
  rightFilters: FilterSelection[];
}

@Component({
  selector: 'siq-js-affinities-query-mode-modal',
  templateUrl: './affinities-query-mode-modal.component.html',
  styleUrls: ['./affinities-query-mode-modal.component.scss']
})
export class AffinitiesQueryModeModalComponent extends QueryModeModalComponent implements OnInit {
  public userGroupChanged: boolean;
  public summary: Summary;

  constructor(
    protected dialogRef: MatDialogRef<AffinitiesQueryModeModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected modalData: QueryModeModalData
  ) {
    super(dialogRef, modalData);
  }

  ngOnInit(): void {
    const form: AffinitiesFormData = this.modalData.data;
    const schema = this.modalData.schema;
    this.userGroupChanged = !!this.modalData.userGroupChanged;

    // generate each fields for Summary
    const leftSummary = form.affinityType ? (CmsService.isValidField(form.affinityType, schema) ? null : form.affinityType) : null;
    // no rightSummary if 'standard' affinity option is selected
    const rightSummary = form.secondaryAffinityType ? (CmsService.isValidField(form.secondaryAffinityType, schema) ? null : form.secondaryAffinityType) : null;
    const transactionSummary = form.filters.filter(fs => !CmsService.isValidField(fs.field, schema));
    const leftFilterSummary = form.leftFilters.filter(fs => !CmsService.isValidField(fs.field, schema));
    const rightFilterSummary = form.rightFilters.filter(fs => !CmsService.isValidField(fs.field, schema));

    const filterValueSummary = [];
    form.filters.forEach(fs => {
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        const tmp = new FilterSelection(fs.toJson());
        tmp.values = fs.values.filter(val =>!retailers.includes(val));
        if (tmp.values.length) {
          filterValueSummary.push(tmp);
        }
      }
    });

    this.summary = {
      affinityType: leftSummary,
      secondaryAffinityType: rightSummary,
      filters: transactionSummary,
      filterValues: filterValueSummary,
      leftFilters: leftFilterSummary,
      rightFilters: rightFilterSummary
    };

    this.ready();
  }

}

export const THEME_COLORS = {
  INDIGO: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo').trim(),
  SKY: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sky').trim(),
  SIENNA: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sienna').trim(),
  SUNSHINE: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sunshine').trim(),
  GRASS: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-grass').trim(),
  PLUM: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-plum').trim(),
  OCEAN: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-ocean').trim(),
  WHITE: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-white').trim(),
  COOL_GRAY: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-cool-gray').trim(),
  SLATE: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-slate').trim(),
  GRANITE: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-granite').trim(),
  INDIGO_FOOTER: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo-footer').trim(),
  ALERT_RED: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-alert-red').trim(),
  SUCCESS_GREEN: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-success-green').trim(),
  SCREEN_GRAY: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-screen-gray').trim(),
  LIGHTER_TEXT: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-lighter-text').trim(),
  ACTION_BLUE: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-action-blue').trim(),
  INDIGO_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo-contrast-1').trim(),
  INDIGO_CONTRAST_2: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo-contrast-2').trim(),
  INDIGO_CONTRAST_3: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo-contrast-3').trim(),
  INDIGO_CONTRAST_4: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo-contrast-4').trim(),
  SKY_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sky-contrast-1').trim(),
  SKY_CONTRAST_2: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sky-contrast-2').trim(),
  INDIGO_FOOTER_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-indigo-footer-contrast-1').trim(),
  OCEAN_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-ocean-contrast-1').trim(),
  PLUM_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-plum-contrast-1').trim(),
  GRANITE_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-granite-contrast-1').trim(),
  SUNSHINE_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sunshine-contrast-1').trim(),
  SIENNA_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-sienna-contrast-1').trim(),
  COOL_GRAY_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-cool-gray-contrast-1').trim(),
  ALERT_RED_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-alert-red-contrast-1').trim(),
  SUCCESS_GREEN_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-success-green-contrast-1').trim(),
  SCREEN_GRAY_CONTRAST_1: window.getComputedStyle(document.documentElement).getPropertyValue('--pdi-palette-screen-gray-contrast-1').trim(),
};

import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import {DateUnit} from '@siq-js/core-lib';
import { DatesFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/dates-filter/dates-filter.component';

@Component({
  selector: 'siq-year-month-filter',
  templateUrl: './year-month-filter.component.html',
  styleUrls: ['./year-month-filter.component.scss', '../../styles/custom-filters.scss']
})
export class YearMonthFilterComponent extends DatesFilterComponent {
  @ViewChild('month', { read: ViewContainerRef, static: true }) private _input;

  constructor() {
    super();
    this.setPrecision(DateUnit.MONTH);
  }

  // Focuses on the month element upon initialization of the filter controller
  // AfterViewInit interface implemented by inherited class
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => {
      this._input && this._input.element.nativeElement.focus();
    });
  }
}

import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { FilterSelection } from 'app/filter/models/filter-selection';
import * as _ from 'lodash';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { ComponentType } from '@angular/cdk/portal';
import { ConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { CurrencyPipe } from '@angular/common';
import { EnvironmentSettingsInterface } from 'environments/environment-settings.interface';
import { first, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { TextColorType } from '@siq-js/visual-lib';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Params } from '@angular/router';
import { ConfirmationModalConfig } from 'app/core/components/confirmation-modal/confirmation-modal-config.interface';
import { ConfirmationResponse } from 'app/core/components/confirmation-modal/confirmation-response.interface';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { CustomInjector } from 'app/core/models/custom-injector';
import { EnvConfigService, PLATFORM_MODE } from 'app/core/services/env-config/env-config.service';
import { FilterService } from 'app/filter/services/filter.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { AnalysisType } from 'app/core/models/analysis-type.enum';

@Injectable()
export class UtilsService {

  public static readonly REC_SEP = String.fromCharCode(30);

  public static readonly MODAL_CONFIG_LARGE: MatDialogConfig = {
    width: '80vw',
    role: 'dialog'
  };
  public static readonly MODAL_CONFIG_MEDIUM: MatDialogConfig = {
    width: '55vw',
    role: 'dialog'
  };
  public static readonly MODAL_CONFIG_SMALL: MatDialogConfig = {
    width: '300px',
    role: 'dialog'
  };

  private static mixPanelService: MixpanelService;

  // Units used for abbreviating large numbers
  public static readonly UNITS: { value: number, symbol: string }[] = [
    { value: 1E9, symbol: 'B' },
    { value: 1E6, symbol: 'MM' },
    { value: 1E3, symbol: 'K' }
  ];

  public static queryModeSchema: string; // Gets updated everytime the QueryModeComponent.schema$ BehaviorSubject is updated

  public static scrubUrl(url: string): string {
    if (url.includes('?')) {
      return url.slice(0, url.indexOf('?'));
    }
    return url;
  }

  public static rebuildParams(url: string): Params {
    const params = {};

    if (url.includes('?')) {
      /*
      Split using a "Lookahead" regular expression
      &(?!=) means look for an ampersand & but also check that what follows immediately (lookahead)
      is NOT an equals sign. Put this together and it makes: &(?!=)
       */
      const queryStrings = url.slice(url.indexOf('?') + 1).split(/&(?!=)/g);

      queryStrings.forEach(qs => {
        /*
        Split using a "Negative Lookbehind" regular expression
        (?<!&) is a group which check before (lookbehind) (?<)
        and see if the ampersand is NOT right before the equals sign. Put together this makes: (?<!&)=
         */
        const [key, value] = qs.split(/(?<!&)=/g);
        params[key] = value;
      });
    }
    return params;
  }

  // Simple fn for swapping 2 els in an array
  public static swap(arr: any[]) {
    const temp = arr[0];
    arr[0] = arr[1];
    arr[1] = temp;
  }

  public static obsToBehaviorSubject<T>(obs: Observable<T>, initValue: T): BehaviorSubject<T> {
    const bs = new BehaviorSubject<T>(initValue);
    obs.subscribe(
      (val: T) => bs.next(val),
      (err: any) => bs.error(err),
      () => bs.complete()
    );
    return bs;
  }

  // This fn takes in an observable stream and the value to update it with, and returns a promise that
  // resolves once it has successfully been updated & the change has been detected
  // Useful for when some logic needs to run immediately after updating an observable
  public static updateObsAndReturnPromise(obs: Subject<any>, val: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        obs.pipe(
          first(),
          take(1)
        )
          .subscribe(_val => {
            resolve(val);
          });

        obs.next(val);
      } catch (e) {
        console.error(e);
        reject();
      }
    });

  }

  public static processDrilldownValue(val: string, field: CmsField | string): string {

    let dimKey: string;

    if (field instanceof CmsField) {
      dimKey = field.id;
    } else {
      dimKey = field;
    }
    switch (dimKey) {
      case 'PROD_UPC_DESC':
        return val.split('-')[0].trim();
      case 'PROD_SIZE':
        return val.split(' ').join('|');
      default:
        return val;
    }
  }

  public static openConfirmationModal<T>(
    config: ConfirmationModalConfig,
    modalSize: MatDialogConfig = UtilsService.MODAL_CONFIG_SMALL
  ): Promise<T> {
    return new Promise(((resolve, reject) => {
      CustomInjector.injector.get(UtilsService)
        .openModal(
          ConfirmationModalComponent,
          config,
          modalSize
        )
        .afterClosed()
        .pipe(
          first()
        )
        .subscribe(
          (response: ConfirmationResponse) => response && resolve(response.value),
          (error) => reject(error)
        );
    }));
  }

  // helper fn for getting a single retailer enum's display
  public static getRetailerDisplay(ret: string): string {
    const meta = EnvConfigService.getConfig().allAvailableRetailersMeta.find(retMeta => retMeta.val === ret);
    return (meta ? meta.name : ret);
  }

  // helper fn for getting a brand display
  public static getBrandDisplay(b: string): string {
    const meta = EnvConfigService.getConfig().primaryEntityMeta;
    return (meta ? meta.brandDisplayName : b);
  }

  public static getQueryModeDefaultSchema(overrideSchema = '', disableMultiRetailer = false): string {
    let res: string;
    const envConfig = EnvConfigService.getConfig();
    const schemas = envConfig.retailers || [];
    if (schemas.length === 1) {
      // If there is only 1 available schema to the user, just default it to that one
      res = schemas[0];
    } else if (!!overrideSchema) {
      /*
        ICE-4180 ICE-4310: cloning a report scenario (overrideSchema exists). Current user group has +2 retailers.
        Clone a report with single retailer:
          - Report is valid: return the same retailer (overrideSchema).
          - Report is invalid: return primaryEntity (Multi-retailers).
        Clone a report with multiple retailer (overrideSchema === envConfig.primaryEntity): return primaryEntity no matter report is valid or not.
      */
      res = schemas.includes(overrideSchema) ? overrideSchema : envConfig.primaryEntity;
    } else {
      res = disableMultiRetailer || envConfig.platformMode === PLATFORM_MODE.SINGLE_ONLY ? schemas[0] : envConfig.primaryEntity;
    }
    return res;
  }

  // helper fn for getting an array of retailer enums. filters out ones that weren't matched
  // defaultGlobal is an optional flag for defaulting to all retailers in the event of an empty array passed in
  public static getRetailerDisplays(retailers: string[] = [], defaultGlobal = true): string[] {
    if (!retailers.length && defaultGlobal) {
      retailers = EnvConfigService.getConfig().retailers;
    }
    return retailers.map(r => this.getRetailerDisplay(r)).filter(name => !!name);
  }

  public static trackEvent(event: MixpanelEvent, data: any) {
    if (UtilsService.mixPanelService) {
      return UtilsService.mixPanelService.track(event, data);
    } else {
      console.warn(`A UtilsService instance must be created before events can be tracked.`);
    }
  }

  public static scrubFormattedVal(str: string): number {
    return Number(str.replace(/[^0-9.-]/g, ''));
  }

  static dollar(input: any = 0, minIntegerDigits: string = '1', minFractionDigits: string = '0', maxFractionDigits: string = '3'): string {
    /*
      From DecimalPipe:
      @param digitsInfo a `string` which has a following format: <br>
      <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
      - `minIntegerDigits` is the minimum number of integer digits to use. Defaults to `1`.
      - `minFractionDigits` is the minimum number of digits after the decimal point. Defaults to `0`.
      - `maxFractionDigits` is the maximum number of digits after the decimal point. Defaults to `3`.
     */
    const digitsInfo = minIntegerDigits + '.' + minFractionDigits + '-' + maxFractionDigits;
    return new CurrencyPipe('en-US').transform((input !== '' ? input : 0), 'USD', 'symbol', digitsInfo);
  }

  /**
   * Accepts an email as a string, and returns an error message for it. This function is not a validator, and will always return an error
   * regardless of if thereis any actual errors with the email.
   *
   * @param {string} email The email address to be validated.
   * @returns {string} An descriptive error message for the email supplied.
   */
  static getEmailErrorMessage(email: string): string {
    if (!/@/.test(email))
      return 'The email address entered is missing the at symbol (@).';
    else if (/@[^@]*@/.test(email))
      return 'The email addressed entered contains multiple at symbols (@).';
    else if (/^@/.test(email))
      return 'The email address entered is missing the local address (the part before the at symbol (@)).';
    else if (/@$/.test(email))
      return 'The email address entered is missing the domain (the part after the at symbol (@)).';
    else
      return 'Please enter a valid email.';
  }

  static getNumberDisplayClass(number: any): string {
    let n = Number(number);
    let out = '';

    if (_.isFinite(n) && n !== 0) {
      out = n < 0 ? TextColorType.WARN.toString() : TextColorType.SUCCESS.toString();
    }

    return out;
  }

  // Generates a UID for each visualization
  static guid() {
    let s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    // TODO: add a 'prefix?: string' parameter, and find usages expecting 'siq-visual-' and update accordingly
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  // Formats a number input to either one of two styles:
  // 1. If truncate is passed in, it will abbreviate large quantities, i.e. 123456 becomes 123K
  // 2. Otherwise, formats the input to a comma separated number to the fixed number of decimals
  static num(input: number, dec: number, truncate?: boolean): string {
    const UNITS = ['', 'K', 'MM', 'B'];

    if (_.isNil(input)) return '';

    input = Number(input);

    if (input < 0) {
      const c = this.num(Math.abs(input), dec, truncate);
      // Prevent zero from getting a negative indicator prefix
      const prefix = Math.abs(Number(c)) !== 0 ? '-' : '';
      return prefix + this.num(Math.abs(input), dec, truncate);
    }

    if (truncate) {

      let i = 0; // Current index of UNITS
      while (input >= 1000) {
        input /= 1000;
        i++;
      }

      const extraDecimal = Number(input.toFixed(1)) % 1;
      return input.toFixed(!!extraDecimal ? 1 : 0) + UNITS[i];
    }

    return input.toFixed(dec || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Assuming a and b are the same type, returns if a is less than b
  static isLessThan(a: any, b: any): number {
    if (_.isNil(a)) return -1;
    if (_.isNil(b)) return 1;

    if (typeof a === 'string') {
      const numA = Number(a);
      const numB = Number(b);
      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      }
      return a.localeCompare(b);
    }

    if (typeof a === 'number') {
      if (!_.isFinite(a)) return -1;
      if (!_.isFinite(b)) return 1;
      return a - b;
    }

    if (a instanceof Date) {
      return a.getTime() - b.getTime();
    }
  }

  static percent(input: any, dec: number): string {
    return UtilsService.num(input, dec) + '%';
  }

  /**
   * Takes a form element, and sets a single error without disrupting the other errors.
   *
   * @param {AbstractControl} form A form element to have the error applied to.
   * @param {string} errorKey The key of the error.
   * @param {boolean | string} [errorState=false] The error specified. If set to false, will remove the error. Defaults to false.
   */
  static setFormError(form: AbstractControl, errorKey: string, error: boolean | string = true): void {
    // Gets current error state
    let errors: ValidationErrors = _.clone(form.errors) || {};

    // Adds or removes the error keys from the current errors object
    if (error !== false) {
      errors[errorKey] = error;
    } else {
      delete errors[errorKey];
    }

    // Sets the new error state
    form.setErrors(!_.isEmpty(errors) ? errors : null);
  }

  /**
   * Accepts an email input element and will return a user friendly error  message. Actual validation is done by the built in Angular
   * validator, and requires the element to be properly configured.
   *
   * @param {elementTypeEmail} target The dom element to evaluate.
   * @returns {string} A descriptive error message for the email supplied, if there is an error, otherwise it returns an empty string.
   * @throws {TypeError} Thrown when the input type is not a email field.
   */
  // Ideally the datatype wouldn't be any, however I couldn't get HTMLElement to work or
  // figure out a better datatype to use.
  static validateEmailField(target: any): string {
    if (target.type !== 'email') {
      throw TypeError();
    } else if (target.valid) {
      return '';
    } else {
      return this.getEmailErrorMessage(target.value);
    }
  }

  /**
   * if the affinity type is UPC description, only take the number inside the "()"
   * Example: Beer Deposit 24pk (19120)
   * @param upcNumberAndDescription
   */
  static getUpcFromUpcDescription(upcNumberAndDescription: string): string {
    return upcNumberAndDescription.substring(upcNumberAndDescription.lastIndexOf('(') + 1, upcNumberAndDescription.lastIndexOf(')'));
  }

  /**
   * Capitalizes first letter of word.
   *
   * @param word
   * @return First capitalized letter. For non-string and Nil returns ''.
   */
  static capitalizeFirstLetter(word: string): string {
    if (_.isNil(word)) return '';
    if (typeof word !== 'string') return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  /**
   * Accepts a hexcode for a color and determine a light or dark contrast color for best readability.
   * Used to apply text over shaded cells in heatmaps.
   * Sourced from: https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
   */
  static getContrastColor(hexColor: string): string {
    const DARK = '#000';
    const LIGHT = '#fff';
    const hex = hexColor.replace(/#/, '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    const val = 1 - ([
      0.299 * r,
      0.587 * g,
      0.114 * b
    ].reduce((total, currentValue) => total + currentValue) / 255);

    return val < 0.5 ? DARK : LIGHT;
  }

  /**
   * Takes a query param instance & returns a JSON object ready for use with the BE's query engine
   * @param model dim/fact reference or filter selection model
   */
  static paramify(model: CmsField | CmsMetric | FilterSelection): any {

    if (model instanceof CmsField) {
      return {
        n: model.field,
        r: model.retailer,
        ta: model.table
      };
    }

    if (model instanceof CmsMetric) {
      return model.id;
    }

    if (model instanceof FilterSelection) {
      /** Ticket ICE-59
       * Backend hard-coded (non-dynamic) dims do not have an associated filter with same id.
       * Instead these are set to point to another existing filter, so use the "filter" field
       * as the fallback in this case.
      */
      const entity = CmsService.get().findEntity<CmsField | CmsMetric>(model.field.id);

      return {
        n: FilterService.getAllFilters().find(f => f.id === model.field.id) ? model.field.field : model.field.filter.toLowerCase(),
        ta: model.field.table,
        r: model.field.retailer,
        in: (model.include && model.values) || [],
        out: (!model.include && model.values) || [],
        inNull: model.nulls && model.include,
        outNull: model.nulls && !model.include,
        t: entity ? entity.type : null
      };
    }
  }

  public static getAnalysisType(sheetSchema?: string): AnalysisType {
    const schema = sheetSchema ?? this.queryModeSchema;
    return !!schema
      ? schema === EnvConfigService.getConfig().primaryEntity ? AnalysisType.MULTI_RETAILER : AnalysisType.SINGLE_RETAILER
      : AnalysisType.NOT_SPECIFIED;
  }

  public static setQueryModeSchema(schema: string): void {
    UtilsService.queryModeSchema = schema;
  }

  public static areSetsEqual(set1: Set<any>, set2: Set<any>) {
    // Check if both sets are of the same size
    if (set1.size !== set2.size) {
        return false;
    }
    // Check every element of set1 is in set2
    for (let item of set1) {
        if (!set2.has(item)) {
            return false;
        }
    }
    return true;
}

  constructor(
    private dialog: MatDialog,
    mixPanelService: MixpanelService
  ) {
    if (!UtilsService.mixPanelService) {
      UtilsService.mixPanelService = mixPanelService;
    }
  }

  getEnvironment(): EnvironmentSettingsInterface {
    return AppSiqConstants.environment;
  }

  openModal(component: ComponentType<any>, data?: any, config?: MatDialogConfig): MatDialogRef<any> {

    config = config || UtilsService.MODAL_CONFIG_MEDIUM;
    config.data = data || {};

    return this.dialog.open(component, config);

  }

  confirmDialog(
    message: string = 'Are you sure?',
    header?: string,
    confirmText: string = 'Confirm',
    cancelText?: string
  ): MatDialogRef<any> {

    let config = {
      panelClass: 'siq-confirm-dialog',
      width: '320px',
      data: {
        message: message,
        header: header,
        confirmText: confirmText,
        cancelText: cancelText,
      }
    };

    return this.dialog.open(ConfirmDialogComponent, config);

  }
}

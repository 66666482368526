<div class="outer">

  <div class="full-container">
    <div class="logo">
      <a [routerLink]="'/' + loginRoute.path"
         class="login-link"
          title="{{ loginRoute.data.name }}">
        {{ loginRoute.data.name }}
      </a>
    </div>

    <div class="message-container">

      <div *ngIf="!complete; else success">
        <div class="title">Unsubscribe from Scheduled Report</div>

        <div class="body" *ngIf="!error">Please confirm that you would like to unsubscribe from <span class="report-name">{{ reportName }}</span> by clicking below.</div>
        <div class="body" *ngIf="error">We experienced an issue when attempting to unsubscribe you from <span class="report-name">{{ reportName }}</span>.<br/><br/>Please try again or contact <a href="mailto:support@swiftiq.com">support@swiftiq.com</a> for additional assistance.</div>

        <div class="closing">
          <button
            mat-flat-button
            color="accent"
            type="button"
            title="Unsubscribe"
            (click)="unsubscribeReport()"
          >
            Unsubscribe
          </button>
        </div>
      </div>

      <ng-template #success>
        <div class="title" *ngIf="!notFound">You've successfully been unsubscribed from this scheduled report.</div>
        <div class="title" *ngIf="notFound">You are already unsubscribed from this scheduled report.</div>

        <div class="body">If you’ve reached this message in error, please contact the original sender of the report to be re-subscribed.</div>

        <div class="closing">
          Thanks,<br>
          The PDI Team
        </div>
      </ng-template>


    </div>

  </div>

</div>

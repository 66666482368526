import { Serializable, WeekEndingDay } from '@siq-js/core-lib';
import { AnalysisType } from 'app/core/models/analysis-type.enum';
import { CustomInjector } from 'app/core/models/custom-injector';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';

export interface AppFormJson {
  schema: string;
  weekEndingDay?: WeekEndingDay; // Promo doesn't use it.
}

export abstract class AppFormData<T> implements Serializable<T> {
  protected abstract isValidSchema(schema: string): boolean;
  public abstract toJson(): T;
  public datesService: DatesService;
  public schema: string;
  public weekEndingday: WeekEndingDay;

  constructor() {
    this.datesService = CustomInjector.injector.get(DatesService);
  }

  public getAnalysisType(): AnalysisType {
    return UtilsService.getAnalysisType();
  }

  /** sets value from this to DatesService */
  public setWeekEndingDay$() {
    this.datesService.weekEndingDay$.next(this.weekEndingday);
  }
}

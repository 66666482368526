<div class="incrementals-form floating-box">

  <div class="stepper-header-outside">
    <mat-slide-toggle [(ngModel)]="expanded" [color]="'accent'">Expand</mat-slide-toggle>
  </div>

  <mat-vertical-stepper [ngClass]="{'expand-all': expanded}" color="accent">
    <mat-step [hasError]="stepOneError">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 1: {{appName}} Type</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[0] ? stepValidationMessages[0] : {status: validationMessageStatus.UNSET, message: 'Select an '+appName +' Type'}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container">
        <siq-js-entity-selector
            [entityData]="filteredData"
            [allowDuplicates]="true"
            [minCount]="1"
            [maxCount]="1"
            [placeholders]="[appName+' Type']"
            [initModel]="initModel"
            (formEmitter)="IncrementalTypeChanged($event)"
          >
          </siq-js-entity-selector>
      </div>
      <div *ngIf="!expanded" class="step-button-bar shift-end">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [hasError]="!isDateRangeValid">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 2: Date Range</h4>
          <siq-js-step-form-message
              [validationMessage]="stepValidationMessages[1] && isDateRangeValid ? stepValidationMessages[1] : {status: validationMessageStatus.UNSET, message: 'Select Date Range'}"
            >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container">
        <siq-js-date-selection
          *ngIf="formData && dateSelectionConfig"
          [config]="dateSelectionConfig"
          [initModel]="formData.dateRanges"
          (onDateChanged)="dateChanged($event)"
          (onWeekEndingDayChanged)="weekEndingDayChanged($event)"
        ></siq-js-date-selection>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread push-down">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [hasError]="stepThreeError">
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 3: Product Filters</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[2] ? stepValidationMessages[2] : {status: validationMessageStatus.INVALID, message: productStepMessage}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container filter-groups-container">
        <siq-filter-group
          [schemaController]="queryMode.schema$"
          [mapFn]="productMapFn"
          [initModel]="formData.productFilters"
          (emitter)="filterGroupChanged($event, incrementalFilterType.PRODUCT)"
        ></siq-filter-group>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <header class="step-header">
          <h4>Step 4: Transaction Filters</h4>
          <siq-js-step-form-message
            [validationMessage]="stepValidationMessages[3] ? stepValidationMessages[3] : {status: validationMessageStatus.UNSET, message: transactionStepMessage}"
          >
          </siq-js-step-form-message>
        </header>
      </ng-template>
      <div class="step-container filter-groups-container">
        <siq-filter-group
          #transactionFilters
          [schemaController]="queryMode.schema$"
          [mapFn]="transactionMapFn"
          [initModel]="formData.locationFilters"
          (emitter)="filterGroupChanged($event, incrementalFilterType.LOCATION)"
        ></siq-filter-group>
      </div>
      <div *ngIf="!expanded" class="step-button-bar spread">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class="step-button-bar" [ngClass]="expanded ? 'shift-end' : 'spread'">
        <button mat-button matStepperPrevious *ngIf="!expanded">Back</button>

        <div>
          <mat-form-field class="user-activity-name">
            <mat-label>Name Activity (Optional)</mat-label>
            <input matInput type="text" [(ngModel)]="formData.name">
            <button mat-button *ngIf="formData.name" matSuffix mat-icon-button aria-label="Clear" (click)="formData.name=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="disableSubmitBtn"
            (click)="onSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>

</div>

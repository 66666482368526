import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedRoutingModule } from 'app/unauthorized/routes/unauthorized-routing.module';
import { UnauthorizedComponent } from 'app/unauthorized/components/unauthorized/unauthorized.component';

@NgModule({
  imports: [
    CommonModule,
    UnauthorizedRoutingModule
  ],
  declarations: [UnauthorizedComponent]
})
export class UnauthorizedModule { }

import { environment } from 'environments/environment';
import { DataType } from 'app/core/models/data-type.enum';

export const AppSiqConstants = {
  environment: environment,
  null: 'NULL',
  noAppIcon: 'input',
  contexts: {
    DISPLAY_GROUP: 'displayGroup',
    BOOTSTRAP: 'bootstrap'
  },
  types: {
    APPLICATION: 'application',
    DIMENSION: DataType.DIMENSION,
    FACT: DataType.FACT,
    FILTER: DataType.FILTER,
  },
  dateFormat: 'YYYY-MM-DD',
  _dateFormat: 'yyyy-MM-dd',
  _dateTimeFormat: 'yyyy-MM-dd_hh-mm-ss',
  shortDate: 'MM/DD/YY',
  _shortDate: 'MM/dd/yy',
  nullDimReplacementString: '--UNKNOWN--'
};

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AuthService } from 'app/auth/services/auth.service';
import { UserPayloadInterface } from 'app/user/models/user-payload-interface';
import { forbiddenRegExpValidator } from 'app/siq-forms/validators/forbidden-regexp.directive';
import { User } from 'app/user/models/user.model';
import { UserService } from 'app/user/services/user.service';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { Subject, Observable, map, take, filter, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalConfig } from 'app/core/components/confirmation-modal/confirmation-modal-config.interface';
import { ConfirmationResponse } from 'app/core/components/confirmation-modal/confirmation-response.interface';
import { NotificationService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})

export class UserFormComponent implements OnInit, OnDestroy {
  public accessGroups$ = AccessGroupService.AccessGroups$;
  public edit: boolean;
  public form: UntypedFormGroup;
  public userNotFound: boolean;
  public submitting: boolean;
  private _unsub$: Subject<void> = new Subject<void>();
  private user: User;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private utils: UtilsService,
    private accessGroupService: AccessGroupService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService) {
    this.user = new User();
    this.edit = false;
    this.userNotFound = false;
  }

  cancel(): void {
    this.router.navigate(['/users']);
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  ngOnInit() {
    this.accessGroupService.retrieveAccessGroups();
    UserService.refresh();
    this.setupForm();
  }

  submit() {
    this.submitting = true;

    if (this.user.accessGroup && this.user.accessGroup !== this.form.value.accessGroup) {
      this.showConfirmation();
    } else {
      this.submitForm();
    }
  }

  private submitForm(value?: string) {
    this.mapFormToUser();
    if (!this.edit) {
      const user = AuthService.CurrentUser$.getValue();
      this.user.organizationId = user.organizationId;
    }

    let payload: UserPayloadInterface = this.user.getUserAsPayload(!this.edit);
    // AG-145: Attach deleteReports field in request
    if (value) {
      payload.deleteReports = value === 'delete';
    }

    this.userService.addOrEditUser(payload)
      .subscribe(resp => {
        this.submitting = false;

        if (resp.status === 200) {

          let currentUser = AuthService.CurrentUser$.getValue();
          if (this.user.id?.toString() === currentUser.siteUserId.toString()) {
            // Current User is updating own profile; take any new values and overwrite old values
            const newValues = {
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              active: this.user.active,
              accessGroup: this.user.accessGroup
            };

            currentUser = _.mergeWith(currentUser, newValues, (o,s) => _.isNil(s) ? o : s );

            // now set the current user
            AuthService.CurrentUser$.next(currentUser);
            AuthService.CurrentUser$.pipe(
              take(1)
            )
            .subscribe(user => {
              // Now we can navigate and have the route guard(s) work as expected
              this.router.navigate(['/users']);
            });
          } else {
            this.router.navigate(['/users']);
          }
        }
      }, error => {
        this.submitting = false;
        const message = error?.error ?? error;
        if (message) {
          this.notificationService.error(message, 'Error');
          console.error(message);
        }
      });
  }

  private showConfirmation(): void {
    this.utils.openModal(
      ConfirmationModalComponent,
      {
        header: 'Are you sure?',
        body: `You may choose to either keep or permanently delete the user’s (or users') saved reports. Please note that choosing to keep the saved reports will only keep their frameworks; therefore, some or all reports may have their outputted data change (for example, a report for the midwest will lose Iowa’s sales if access to Iowa’s data is being removed from the access group).`,
        buttons: [
          { label: 'KEEP REPORTS', response: { accepted: true, value: 'keep' }},
          { label: 'DELETE REPORTS', response: { accepted: true, value: 'delete' }},
          { label: 'CANCEL', response: { accepted: false, value: null }},
        ]
      } as ConfirmationModalConfig,
      UtilsService.MODAL_CONFIG_MEDIUM
    )
    .afterClosed()
    .subscribe((res: ConfirmationResponse) => {
      if (res && res.accepted) {
        this.submitForm(res.value);
      } else {
        this.submitting = false;
        return;
      }
    });
  }

  private mapFormToUser(): void {
    for (let k of Object.keys(this.form.value)) {
      _.assign(this.user[k] = this.form.value[k]);
    }
  }

  private mapUserToForm(): void {
    /*
     * Use FormBuilder to create a FormGroup object which serves as the model.
     * "this.form" is/shows the full FormGroup object, whereas
     * "this.form.value" is/shows the object model.
     */
    this.form = this.formBuilder.group({
      firstName: [this.user.firstName || '', [Validators.required, forbiddenRegExpValidator(/^\s+$/, `Input can not be white space only.`, 'white-space-only')]],
      lastName: [this.user.lastName || '', [Validators.required, forbiddenRegExpValidator(/^\s+$/, `Input can not be white space only.`, 'white-space-only')]],
      email: [this.user.email || '', [Validators.required, Validators.email, forbiddenRegExpValidator(/^\s+$/, `Input can not be white space only.`, 'white-space-only')]],
      accessGroup: [this.user.accessGroup, [Validators.required]],
      active: [this.user.active || false]
    });
  }

  private forbiddenEmails(control: UntypedFormControl): Promise<any> | Observable<any> {
    return UserService.Users$.pipe(
      filter(users => !!users?.length),
      take(1),
      map(users => {
        const user = UserService.extractUserFromUserListByEmail(users, control.value);
        if (!user) return null;
        return this.edit && (user.email === this.user.email) ? null : { 'emailIsForbidden': true };
      })
    );
  }

  private setupForm(): void {
    this.mapUserToForm();
    this.setUser();
  }

  private setUser() {
    this.route.params.pipe(takeUntil(this._unsub$)).subscribe(params => {

      if (params['id'] !== '~') {
        this.edit = true;
        this.userService.getUser(params['id'])
          .subscribe(
            userList => {
              this.user = userList[0];
              if (this.user) {
                this.updateForm();
              }
            },
            err => {
              this.userNotFound = true;
              throw new Error(err);
            });
      } else {
        this.user = new User();
        this.edit = false;
        this.updateForm();
      }
    });

  }

  private updateForm(): void {
    let patchVals = {};
    for (let k of Object.keys(this.user)) {
      patchVals[k] = this.user[k];
    }

    this.form.patchValue(patchVals);
    this.form.get('email').setAsyncValidators(this.forbiddenEmails.bind(this));
  }
}

import * as _ from 'lodash';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { Injectable } from '@angular/core';
import { NON_PROD_ENVIRONMENTS } from 'app/core/models/constants/non-prod-environments';

/*
 * This AppService class was created to handle setting the potentially dynamic environment settings.
 * This class is loaded in AppModule **before** the Angular application is bootstrapped. If dynamic
 * settings are retrieved by the APP_INITIALIZER in AppModule, then the updateEnvironmentSettings(...)
 * in this class is called and the settings updated.
 */
@Injectable()
export class AppService {

  public static updateEnvironmentSettings(projectId: string): void {

    // Even though this.appConstants is a constant, we can update the value here before the application starts
    AppSiqConstants.environment.api = 'https://api-app-dot-' + projectId + '.appspot.com/api';
    AppSiqConstants.environment.host = window.location.host;
    AppSiqConstants.environment.projectId = projectId;

    // Temporary until there is a better system of identifying which env is production
    const projectStr = projectId.substring(0, projectId.indexOf('-siq'));
    AppSiqConstants.environment.production = !_.find(NON_PROD_ENVIRONMENTS, projectStr);
  }

  constructor() { }

}

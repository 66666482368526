import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

const matComponents: any[] = [
  ProgressBarComponent
];

@NgModule({
  declarations: [
    ...matComponents
  ],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  exports: [
    ...matComponents
  ]
})
export class MaterialModule { }

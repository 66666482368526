/**
 * Data flow:
 * HTTP json ( Temp data from take-rate-helper-activity.ts ) =>
 * takeRateHelperActivity ( promo-result.component.ts ) =>
 * helperParams (promo-result.component.ts, drilldown() and drawer service) =>
 * helperParams (take-rate-helper.component.ts)
 * */

import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DRAWER_DATA, DrawerService } from 'app/core/modules/drawer/services/drawer.service';
import { StatResponse } from 'app/core/models/stat-response.model';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoService } from 'apps/dashboard/src/app/siq-applications/modules/promo/services/promo.service';
import {
  KEY_CODE,
  PromoDimensionKeys,
  PromoPeriods,
  UnitTypes
} from 'app/siq-applications/modules/promo/models/promo.enums';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs';
import { PromoResult, TakeRateHelperParams } from 'app/siq-applications/modules/promo/models/interfaces';
import {
  AggregationType, BarColumnConfig,
  BarColumnParams,
  ColDef,
  GridColMetadata,
  GridComponent,
  GridService,
  AgGridAngular
} from '@siq-js/visual-lib';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import {
  PromoResultGridParams,
  PromoResultGridProcessor
} from 'app/siq-applications/modules/promo/components/promo-result/processors/promo-result-grid-processor';
import * as _ from 'lodash';
import { TakeRateHelperChartProcessor } from 'app/siq-applications/modules/promo/components/promo-result/processors/take-rate-helper-chart.processor';
import { PromoTakerateDrilldownRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/promo-takerate-drilldown-renderer/promo-takerate-drilldown-renderer.component';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';

@Component({
  selector: 'siq-js-take-rate-helper',
  templateUrl: './take-rate-helper.component.html',
  styleUrls: ['./take-rate-helper.component.scss']
})

export class TakeRateHelperComponent extends BaseSiqComponent implements OnInit, OnDestroy, PromoResult {

  // grid
  public grid: GridComponent;
  public gridParams: PromoResultGridParams;
  public helperProcessor = PromoResultGridProcessor.processor;
  public readyForExport$: BehaviorSubject<boolean>; // if grid is ready to be exported

  // chart
  public columnParams: BarColumnParams; // 'data' passed to BaseVisualizationComponent
  public columnConfig: BarColumnConfig; // 'config' passed to BaseVisualizationComponent
  public columnProcessor = TakeRateHelperChartProcessor.processor; // 'processor' passed to BaseVisualizationComponent
  public columnData$ = new BehaviorSubject<any>(null);
  public dimKey = 'xKey'; // chart dimension name

  public renderedOnce = false;

  // Dropdown options
  public facts: CmsMetric[];
  public basketSizes: string[];
  public unitTypes = UnitTypes;

  // Selected option
  public factKey: CmsMetric;
  public basketSize: string;
  public unitType: UnitTypes = UnitTypes.ABSOLUTE;
  public render$: Subject<void> = new Subject<void>();
  public unsub$: Subject<void> = new Subject<void>();

  public preCustomName: string;
  public yoyCustomName: string;

  private periodKey: PromoPeriods = PromoConfig.compPeriods[0];
  private takeRateHelperPSPWData;

  constructor(
    @Inject(DRAWER_DATA) public helperParams: TakeRateHelperParams,
    private config: PromoConfig,
    private mixpanelService: MixpanelService,
    private promoService: PromoService,
    private gridService: GridService
  ) {
    super();
    helperParams.helperComponent = this; // Inject a pointer of this component for easy reference in the sheet
  }

  ngOnInit() {
    this.preCustomName = this.helperParams.parent.formData[PromoConfig.periodNames[PromoPeriods.PRE]].customPeriodName;
    this.yoyCustomName = this.helperParams.parent.formData[PromoConfig.periodNames[PromoPeriods.YOY]].customPeriodName;
    this.createDropdownOptions();
    this.setupFacts();
    this.setupTable();
    this.setupChart();
    this.ready();

    this.render$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsub$)
      )
      .subscribe(() => this.render());

    DrawerService.drawerResized$
      .pipe(
        takeUntil(this.unsub$)
      )
      .subscribe(() => {
        this.updateView();
      });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    this.render$.complete();
  }

  // Initialize dropdown selection values
  createDropdownOptions() {
    this.basketSize = this.helperParams.initState.basketSize;
    this.unitType = this.helperParams.unitType;
    this.basketSizes = this.helperParams.basketSizes.sort((a, b) => Number(a) - Number(b));
    this.factKey = this.helperParams.initState.factKey;
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
      'Usage': 'Promotion Measurement'
    });
  }

  render() {
    // Decision made to temporarily disable PSPW under "All"
    // https://pdisoftware.atlassian.net/browse/ICE-1696?focusedCommentId=275195
    if (this.basketSize === PromoTakerateDrilldownRendererComponent.TOTAL_KEY) {
      this.unitType = UnitTypes.ABSOLUTE;
    }

    // render grid
    const apiRef = this.grid.getApi();
    const colDefs = this.generateTakeRateHelperColDefs(apiRef.colDefMeta);

    let data = _.cloneDeep(this.helperParams.data);

    if (this.unitType === UnitTypes.PSPW) {
      if (!this.takeRateHelperPSPWData) {
        this.takeRateHelperPSPWData = this.promoService.generateTakeRateData(this.helperParams.activityPSPW.getJob());
      }
      data = this.promoService.applyPerStorePerWeek(this.takeRateHelperPSPWData);
    }

    if (this.basketSize !== PromoTakerateDrilldownRendererComponent.TOTAL_KEY) {
      data = data.filter(dp => {
        if (dp[PromoDimensionKeys.BASKET_SIZE] !== this.basketSize) return false;
        const compKey = PromoService.generateValKey([this.periodKey, this.factKey.id]); // Pre-Promo or YOY
        const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
        return dp[compKey] || dp[promoKey];
      });
    }

    apiRef.grid.api.updateGridOptions({ columnDefs: colDefs, rowData: data });
    this.grid.aggregate();

    if (this.basketSize === PromoTakerateDrilldownRendererComponent.TOTAL_KEY) {
      apiRef.grid.api.setRowGroupColumns([PromoDimensionKeys.UPC_DESC]);
    } else {
      apiRef.grid.api.setRowGroupColumns([]);
    }

    let vizData = data;
    if (this.basketSize === PromoTakerateDrilldownRendererComponent.TOTAL_KEY) {
      vizData = this.getAggData(this.grid.getApi().grid);
    }
    this.updateChart(this.generateTakeRateChartStatResponse(vizData));
  }

  private generateTakeRateChartStatResponse(data: any[]): StatResponse {
    const dims = [
      new CmsField({
        id: this.dimKey,
        display: null,
        type: 'STRING',
        retailer: null,
        table: null,
        field: null,
        active: true,
        filter: null,
      })
    ];

    const dM = [[]];
    let vM = [];

    const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
    const compKey = PromoService.generateValKey([this.periodKey, this.factKey.id]);
    data.forEach((dp, i) => {
      dM[0].push(dp[PromoDimensionKeys.UPC_DESC]);
      const compVal = _.get(dp, [compKey, 'val']) || 0;
      const promoVal = _.get(dp, [promoKey, 'val']) || 0;
      vM.push([i, promoVal - compVal]);
    });

    vM.sort((a, b) => b[1] - a[1]); // Sort by metric (desc)

    if (vM.length > 20) { // If the data set is larger than 20 elements, we only want the TOP 10 & BOTTOM 10
      vM = vM.slice(0, 10).concat(vM.slice(-10));
    }

    let sr = new StatResponse(dims, dM, this.facts, vM);
    return sr;
  }

  private generateTakeRateHelperColDefs(colDefMeta: Map<string, GridColMetadata>): ColDef[] {
    const defs = [];
    const valKey_pre_yoy = PromoService.generateValKey([this.periodKey, this.factKey.id]);
    const valKey_promo = PromoService.generateValKey([PromoPeriods.PROMO, this.factKey.id]);
    {
      // main dim column
      const dim =  _.cloneDeep(this.helperParams.activity.getJob().getResponse().getDimensions().find(d => d.id.includes(PromoDimensionKeys.UPC_DESC)));
      dim.id = PromoDimensionKeys.UPC_DESC;
      const colDef = this.gridService.generateDimensionColumn(dim, colDefMeta);
      colDef.pinned = 'left';
      defs.push(colDef);
    }
    // Pre-promo/YOY amount column
    {
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, valKey_pre_yoy);
      colDef.field = colDef.colId = valKey_pre_yoy;
      colDef.headerName = this.helperParams.parent.formData[PromoConfig.periodNames[this.periodKey]].customPeriodName + ' Amount';
      if (this.factKey.type === 'PERCENT') {
        colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, this.factKey);
      }
      this.helperParams.parent.takeRateComponent.addCellClassForFactKey(colDef, this.factKey.id);
      defs.push(colDef);
    }
    // Promo amount column
    {
      const colDef = this.gridService.generateMetricColumn(this.factKey, colDefMeta, valKey_promo);
      colDef.field = colDef.colId = valKey_promo;
      colDef.headerName = this.helperParams.parent.formData[PromoConfig.periodNames[PromoPeriods.PROMO]].customPeriodName + ' Amount';
      if (this.factKey.type === 'PERCENT') {
        colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, this.factKey);
      }
      this.helperParams.parent.takeRateComponent.addCellClassForFactKey(colDef, this.factKey.id);
      defs.push(colDef);
    }
    // Change column
    defs.push(this.generateDeltaPromoColDef(this.periodKey, this.factKey, colDefMeta));
    // % Change column
    defs.push(this.generatePercentDeltaPromoColDef(this.periodKey, this.factKey, colDefMeta));

    return defs;
  }

  private generateDeltaPromoColDef(period: PromoPeriods, metric: CmsMetric, colDefMeta: Map<string, GridColMetadata>): ColDef {
    const fact = new CmsMetric({
      display: 'Change',
      type: metric.type,
      aggType: AggregationType.SUM,
      id: 'PROMO_DELTA',
      active: true,
    });
    const periodKey = PromoService.generateValKey([period, metric.id]);
    const valKey = periodKey + '_D';
    const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, metric.id]);
    const colDef = this.gridService.generateMetricColumn(fact, colDefMeta, valKey);
    // Add a cellClass (based on the ref metric) so the Excel export is formatted correctly
    (<string[]>colDef.cellClass).push(GridService.ENUM_ID_PREPEND + metric.id);
    this.helperParams.parent.takeRateComponent.addCellClassForFactKey(colDef, metric.id);

    // Override props
    colDef.colId = valKey;
    colDef.cellClassRules = PromoService.getCellClassRules(fact);
    colDef.valueGetter = GridService.generateDeltaGetter(promoKey, periodKey);
    if (this.factKey.type === 'PERCENT') {
      colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, this.factKey);
    }
    colDef.sort = 'desc';

    delete colDef.field;

    return colDef;
  }

  private generatePercentDeltaPromoColDef(period: PromoPeriods, metric: CmsMetric, colDefMeta: Map<string, GridColMetadata>): ColDef {
    const fact = new CmsMetric({
      display: '% Change',
      type: 'PERCENT',
      aggType: AggregationType.PERCENT_DELTA,
      id: 'PROMO_PERCENT_DELTA',
      active: true,
    });
    const periodKey = PromoService.generateValKey([period, metric.id]);
    const valKey = periodKey + '_PD';
    const promoKey = PromoService.generateValKey([PromoPeriods.PROMO, metric.id]);
    const colDef = this.gridService.generateMetricColumn(fact, colDefMeta, valKey);
    GridService.addCellClassForRefMetric(fact, colDef);
    this.promoService.setRendererFramework(colDef, fact);

    // Override props
    const percentDeltaGetter = GridService.generatePercentDeltaGetter(promoKey, periodKey);
    colDef.colId = valKey;
    colDef.cellClassRules = PromoService.getCellClassRules(fact);
    colDef.valueGetter = percentDeltaGetter;
    colDef.filterValueGetter = this.promoService.generatePercentFilterValueGetter(colDef, fact);
    delete colDef.field;

    return colDef;
  }

  // Fn for grabbing the aggregate data for the TOTALS basket item case
  private getAggData(grid: AgGridAngular): any[] {
    const out = [];
    grid.api.forEachNode(r => {
      if (r.level === 0) {
        out.push(_.extend({
          [PromoDimensionKeys.UPC_DESC]: r.key
        }, r.aggData));
      }
    });
    return out;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === KEY_CODE.ESC) {
      DrawerService.clear();
    }
  }

  // called from html
  public togglePeriod(checked: boolean) {
    this.periodKey = PromoConfig.compPeriods[Number(checked)];
    this.updateView();
  }

  setupChart() {
    this.columnParams = {
      agChartOptions: null,
      parent: this,
      parentActivity: null,
      rawData$: this.columnData$,
      highlightDatum$: new BehaviorSubject(null),
      component: this.helperParams.parent
    };

    this.columnConfig = TakeRateHelperChartProcessor.generateBarColumnConfig();
  }

  setupFacts() { // update this.facts to PSPW (Per Store Per Week) according to UnitType
    PromoService.detectDynamicFacts(this, this.helperParams.facts);
  }

  setupTable() { // use PromoResultGridProcessor to create table
    this.gridParams = {
      parent: this,
      data: this.helperParams.data,
      readyForExport$: this.readyForExport$
    };
    this.gridParams.gridVisualOptions = PromoResultGridProcessor.generateGridVisualOptions(this.gridParams);
  }

  updateUnitType() {
    this.setupFacts();
    this.updateView();
  }

  updateView() {
    this.render$.next();
  }

  updateChart(sr: StatResponse): void {
    const rawData = GridService.jobToArray(sr);
    // Convert data: {xKey: '4900000634 - Coke...', NUM_STORES: {value: 25}} to {xKey: '4900000634 - Coke...', NUM_STORES: 25}
    let newData = [];
    rawData.forEach(value => {
      let obj = {};
      obj[this.factKey.id] = Object.values(Object.values(value)[1])[0];
      obj[this.dimKey] = Object.values(value)[0];
      newData.push(obj);
    });
    this.columnData$.next(newData.filter(data => data[this.factKey.id]));
  }

}

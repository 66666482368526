import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CmsField } from '@siq-js/cms-lib';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { SchemaController } from 'app/filter/components/base-filter-group/base-filter-group.component';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { ComparisonGroup } from 'app/siq-applications/modules/promo/models/comparison-group';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'siq-js-comparison-groups-holder',
  templateUrl: './comparison-groups-holder.component.html',
  styleUrls: ['./comparison-groups-holder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonGroupsHolderComponent implements OnInit {
  @Input() initComparisonGroups: ComparisonGroup[];
  @Input() comparisonGroups$: BehaviorSubject<ComparisonGroup[]>;
  @Input() schema$: SchemaController;

  public cgMax = PromoConfig.MAX_COMPARISON_GROUPS;
  public comparisonGroups: ComparisonGroup[] = [];

  constructor() { }

  ngOnInit(): void {
    this.comparisonGroups = this.initComparisonGroups || [];
  }

  public onAddCG() {
    this.comparisonGroups.push(new ComparisonGroup());
    this.comparisonGroups$.next(this.comparisonGroups);
  }

  public onCGNameChange(id: string) {
    this.comparisonGroups$.next(this.comparisonGroups); // when cg becomes valid/invalid due to name changed, step 7 needs to know to display only valid ones.
  }

  public onRemoveCG(id: string) {
    this.comparisonGroups = this.comparisonGroups.filter(cg => cg.id !== id);
    this.comparisonGroups$.next(this.comparisonGroups);
  }

  public onSelectFilters(filters: FilterSelection[], id: string) {
    const cg = this.comparisonGroups.find(c => c.id === id);
    cg.filters = filters;
    cg.isValid = (!!cg.name) && filters.length > 0;
    this.comparisonGroups$.next(this.comparisonGroups); // when cg becomes valid/invalid due to filter changed, step 7 needs to know to display only valid ones.
  }

  public productMapFn = (fields: CmsField[]) => fields.filter(f => f.table === 'product'); // mapping fn

}

import { Component } from '@angular/core';
import { AgRendererComponent, ICellRendererParams } from '@siq-js/visual-lib';
import * as pluralize from 'pluralize';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CmsField } from '@siq-js/cms-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { filter, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-js-filterset-info-renderer',
  templateUrl: './filterset-filters-renderer.component.html',
  styleUrls: ['./filterset-filters-renderer.component.scss']
})
export class FiltersetFiltersRendererComponent extends BaseSiqComponent implements AgRendererComponent {

  private static readonly MAX_FILTERS_IN_TOOLTIP = 5;

  public model: {
    filterSetsDisplay: {
      name: string, // Label for the chip
      tooltip: string, // Tooltip for hovering over the chip
      include: boolean, // Green chip for true, red for false
      allowed: boolean // Gray chip for false, green or red for true
    }[],
  };

  constructor( ) {
    super();
  }

  agInit(iCellRendererParams: ICellRendererParams): void {

    this.model = {
      filterSetsDisplay: null
    };
    CmsService.getData$().pipe(
      filter(data => !!data),
      takeUntil(this.unsub$)
    ).subscribe(
      cmsConfig =>  {

        this.model.filterSetsDisplay = iCellRendererParams.data.filterSets?.map(f => {
          let cmsEntity = f.id && cmsConfig?.findEntity<CmsField>(f.id);
          let filterName = cmsEntity ? cmsEntity.display : f.filterType;
          let name = '';
          let tooltip = '';
          if (f.values.length) {
            name = f.values.length.toString() + ' ';
            tooltip = f.values
            .slice(0, FiltersetFiltersRendererComponent.MAX_FILTERS_IN_TOOLTIP).join(', ');
            if (f.values.length > FiltersetFiltersRendererComponent.MAX_FILTERS_IN_TOOLTIP) {
              tooltip += ' + ' + (f.values.length - FiltersetFiltersRendererComponent.MAX_FILTERS_IN_TOOLTIP) + ' more';
            }
          }
          if (f.inNull || f.outNull) {
            if (f.values.length) {
              name += '+ ';
              tooltip = ', ' + tooltip;
            }
            name += 'Unknown ';
            tooltip = 'Unknowns' + tooltip;
          }
          if (f.values.length > 1 || f.inNull || f.outNull) {
            name += pluralize(filterName);
          } else {
            name += filterName;
          }
          return {
            name: name,
            tooltip: tooltip,
            include: f.include,
            allowed: f.allowed
          };
        });
      }
    );
  }

  refresh(): boolean {
    return true;
  }

}

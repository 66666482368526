<h3 mat-dialog-title *ngIf="!userGroupChanged">Are you sure?</h3>
<h3 mat-dialog-title *ngIf="userGroupChanged">Report's criteria changed</h3>

<mat-dialog-content *ngIf="ready$ | async">
  <p *ngIf="!userGroupChanged">By Changing the Retailer selection, you'll lose the following:</p>
  <p *ngIf="userGroupChanged">The following has been removed due to Access Group change:</p>

  <div class="columns">

    <div class="column" *ngIf="filterSummary.length">
      <h3 class="column-label">Filters:</h3>
      <ul>
        <li *ngFor="let f of filterSummary" class="remove">
          {{ f.field.display }} ({{ f.field.retailer }})
        </li>
      </ul>
    </div>

  </div>

  <div class="column" *ngIf="filterValueSummary.length">
    <h3 class="column-label">Filter Values:</h3>
    <ul>
      <li *ngFor="let fs of filterValueSummary">
        {{ fs.field.display }}
        <ul>
          <li *ngFor="let val of fs.values" class="remove">
            {{ val }}
          </li>
        </ul>
      </li>
    </ul>
  </div>

</mat-dialog-content>

<mat-dialog-actions *ngIf="!userGroupChanged">
  <button mat-stroked-button color="accent" (click)="cancel()" class="secondary-button">Cancel</button>
  <button mat-raised-button color="accent" (click)="confirm()" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="userGroupChanged">
  <button mat-raised-button color="accent" (click)="cancel()">OK</button>
</mat-dialog-actions>

<mat-form-field>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let recipient of recipients"
      [ngClass]="recipient.status"
      [selectable]="true"
      [removable]="true"
      (removed)="removeRecipient(recipient)"
    >
      {{ recipient.name || recipient.email }}
      <mat-icon matChipRemove>close</mat-icon>
    </mat-chip>
    <input
      #input
      [placeholder]="placeholder"
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="createRecipient($event.value)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addRecipient($event.option.value)">
    <mat-option *ngFor="let user of filteredUsers" [value]="user">
      {{ user.firstName }} {{ user.lastName}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

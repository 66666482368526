
<section class="promo-section">

  <div class="promo-panel" *ngIf="kpiKeys">
    <mat-form-field color="accent">
      <mat-select [(value)]="kpiKey">
        <mat-option value="PROMO">
          Promo KPIs
        </mat-option>

        <mat-option *ngFor="let key of kpiKeys" [value]="key">
          {{ key }} KPIs
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="toggle no-select">
      <label>{{preCustomName}}</label>
      <mat-slide-toggle (change)="togglePeriod($event.checked)"></mat-slide-toggle>
      <label>{{yoyCustomName}}</label>
    </div>
  </div>
  <div class="kpi-section" *ngIf="kpiModel">

    <div class="kpi-group">
      <label>Promo KPIs vs {{periodKeyName}}</label>

      <div class="kpi-chip-list">
        <siq-kpi-chip *ngFor="let kpi of config.kpiOrder;"
                      [factKey]="kpi"
                      [metrics]="kpiModel['PROMO'][kpi]"
                      [periodKey]="periodKey"
                      [periodKeyName]="periodKeyName"
        ></siq-kpi-chip>
      </div>
    </div>

    <div class="kpi-group" *ngIf="kpiKey && kpiKey !== 'PROMO'">
      <label>{{ kpiKey }} KPIs vs {{periodKeyName}}</label>

      <div class="kpi-chip-list">
        <siq-kpi-chip *ngFor="let kpi of config.kpiOrder;"
                      [factKey]="kpi"
                      [metrics]="kpiModel[kpiKey][kpi]"
                      [periodKey]="periodKey"
                      [periodKeyName]="periodKeyName"
        ></siq-kpi-chip>
      </div>
    </div>

  </div>

</section>


<mat-card class="promo-section">

  <div class="takerate-chart">

    <siq-js-bar-column
      *ngIf="stackedColumnParams && stackedColumnConfig "
      [ngClass]="{'hide': chartType !== chartTypes.TAKERATE}"
      [config]="stackedColumnConfig"
      [data]="stackedColumnParams"
      [processor]="stackedColumnProcessor"
    ></siq-js-bar-column>

    <siq-js-simple-line
      *ngIf="simpleLineParams && simpleLineConfig"
      [ngClass]="{'hide': chartType !== chartTypes.TIMESERIES}"
      [config]="simpleLineConfig"
      [data]="simpleLineParams"
      [processor]="simpleLineProcessor"
    ></siq-js-simple-line>

  </div>


  <div class="chart-selection-panel">

    <button mat-stroked-button color="accent"
      (click)="switchChart(chartTypes.TAKERATE)"
      class="chart-option thin-button"
      [ngClass]="chartType === chartTypes.TAKERATE ? 'selected' : 'secondary-button'"
    >Basket Item Count</button>
    <button mat-stroked-button color="accent"
      (click)="switchChart(chartTypes.TIMESERIES)"
      class="chart-option thin-button"
      [ngClass]="chartType === chartTypes.TIMESERIES ? 'selected' : 'secondary-button'"
    >Sales By Day</button>
  </div>

</mat-card>

<section class="promo-section">

  <div class="promo-panel">

    <mat-form-field color="accent">
      <mat-select [disabled]="chartType === chartTypes.TIMESERIES" [(value)]="unitType" (valueChange)="updateUnitType()">
        <mat-option [value]="unitTypes.ABSOLUTE">
          Absolute
        </mat-option>
        <mat-option [value]="unitTypes.PSPW">
          Per Store Per Week
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select [(value)]="factKey" (valueChange)="updateView()">
        <mat-option *ngFor="let fact of facts" [value]="fact">
          {{ fact.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="takerate-table">
    <siq-js-grid
      *ngIf="ready$.value && gridParams"
      [ngClass]="{'take-rate': this.chartType === chartTypes.TAKERATE, 'time-series': this.chartType === chartTypes.TIMESERIES}"
      [config]="gridParams.gridVisualOptions.gridConfig"
      [data]="gridParams"
      [processor]="gridProcessor"
      (featureUsed)="gridFeatureUsed($event)"
    ></siq-js-grid>
  </div>

</section>



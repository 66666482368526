import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WeekEndingDay } from '@siq-js/core-lib';
import { DatePickerComponent } from 'app/siq-forms/modules/dates/components/date-picker/date-picker.component';
import { DateRangeInterface, DateSelectionConfig } from 'app/siq-forms/modules/dates/models/interfaces';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'siq-js-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss']
})
export class DateSelectionComponent implements OnInit {
  @Input() config: DateSelectionConfig;
  @Input() disableInitModelValidation: boolean; // if true, don't auto perform init model validtion
  @ViewChild(DatePickerComponent) datePickerComponent: DatePickerComponent;
  @Input() initModel: DateRangeInterface;
  @Input() noDefault: boolean; // if true, the init dates will be set via setPrimaryDate method. eg: in comparison-dates-selection.component
  @Output() public onDateChanged = new EventEmitter<DateRangeInterface>();
  @Output() public onWeekEndingDayChanged = new EventEmitter<WeekEndingDay>();

  public primaryDate$: BehaviorSubject<DateRangeInterface> = new BehaviorSubject<DateRangeInterface>(null);

  constructor() { }

  ngOnInit(): void {}

  onDateSelected(date: DateRangeInterface) {
    this.primaryDate$.next(_.cloneDeep(date));
    this.onDateChanged.emit(date);
  }

  onWEndingChanged(we: WeekEndingDay) {
    this.onWeekEndingDayChanged.emit(we);
  }

  setPrimaryDate(dates: DateRangeInterface) {
    this.datePickerComponent.setDate(dates);
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';

@Injectable()
export class AffinitiesCloneResolverService implements Resolve<void> {

  constructor(private affinitiesService: AffinitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> | Promise<void> | void {
    this.affinitiesService.cloneReport(route.paramMap.get('id'));
  }
}

import { Component, ViewChild } from '@angular/core';
import { DaypartsFormData, DaypartsFormJson } from 'app/siq-applications/modules/dayparts/models/form/dayparts-form-data.model';
import { DaypartsConfig } from 'app/siq-applications/modules/dayparts/models/dayparts-config.model';
import { DaypartsService } from 'app/siq-applications/modules/dayparts/services/dayparts.service';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { Observable } from 'rxjs';
import { DaypartsQueryModeModalComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-query-mode-modal/dayparts-query-mode-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'siq-js-dayparts-form',
  templateUrl: './dayparts-form.component.html',
  styleUrls: ['./dayparts-form.component.scss']
})
export class DaypartsFormComponent {
  @ViewChild('queryMode') queryMode: QueryModeComponent; // component for controlling current schema

  public formData: DaypartsFormData;

  public queryModeModalConfig: QueryModeModalConfig = { // config for query modal when schema changes
    component: DaypartsQueryModeModalComponent,
    dataGetter: () => this.formData,
    diffFn: (schema: string) => !this.formData.isValidSchema(schema)
  };

  public overrideSchema: string;

  constructor(
    public config: DaypartsConfig,
    private daypartsService: DaypartsService
  ) {
    if (this.daypartsService.clonedActivity) { // if this is a cloned form, use the cloned data
      const formValues: DaypartsFormJson  = _.cloneDeep(JSON.parse(this.daypartsService.clonedActivity.formValues));
      formValues.name = this.daypartsService.clonedActivity.getName() + '(copy)';
      formValues.isCloned = true;
      this.formData = this.daypartsService.createForm(formValues);
    } else { // otherwise create new formData
      this.formData = new DaypartsFormData();
    }
    this.overrideSchema = this.formData.schema;
    this.formData.setWeekEndingDay$();
  }

  // Submit form
  public submit(): Observable<any> {
    const obs$: Observable<any> = this.daypartsService.createReport(this.formData);

    // Pass the Observable along to allow for additional subs (like in dayparts-stepper)
    return obs$;
  }

}

import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { guid } from '@siq-js/core-lib';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs';
import { VisualService } from 'libs/visual-lib/src/lib/services/visual.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Directive()
export abstract class BaseVisualizationComponent<Raw, Processed, Config, Api> extends BaseSiqComponent implements OnInit, OnDestroy {

  @Input() config: Config;
  @Input() data: Raw | Observable<Raw>;
  @Input() processor: (data: Raw) => Processed;

  public id: string; // TODO

  public abstract getApi(): Api;
  protected abstract render(data: Processed): void;

  ngOnInit(): void {
    if (!this.processor) {
      console.warn('No Processor supplied!');
      return;
    }

    // register
    this.register();

    this.setup();
  }

  ngOnDestroy() {
    // deregister
    VisualService.deregister(this.id);

    super.ngOnDestroy();
  }

  private register() {
    this.id = 'viz-' + VisualService.PREFIXES.grid + guid();
    VisualService.register(this.id, this);
  }

  private setup() {
    if (this.data instanceof Observable) {
      this.data
      .pipe(
        map(rawData => this.processor(rawData)),
        takeUntil(this.unsub$)
      )
      .subscribe(processedData => this.render(processedData));
    } else {
      const processedData = this.processor(this.data);
      this.render(processedData);
    }
  }
}

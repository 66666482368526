import { Component, OnInit } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceType } from 'app/public/models/maintenance-type.enum';

@Component({
  selector: 'siq-js-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends BaseSiqComponent implements OnInit {

  public entities: string[] = [];
  public maintenanceType = MaintenanceType;
  public type: string;

  constructor(
    public route: ActivatedRoute,
    private router: Router
  ) {
    super();
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {entities: string[]};
    this.entities = state?.entities;
  }

  ngOnInit(): void {
    this.route.paramMap // check URL
    .pipe(
      takeUntil(this.unsub$)
    )
    .subscribe(params => {
      this.type = params.get('type');
    });
  }
}


import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { Injectable } from '@angular/core';
import { ApplicationHash } from '@siq-js/core-lib';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { KPI, PromoDimensionKeys, PromoPeriods } from 'app/siq-applications/modules/promo/models/promo.enums';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';
import { ChartsService } from '@siq-js/visual-lib';

@Injectable()
export class PromoConfig extends SiqApplicationConfig {

  public static BUBBLE_CHART_CONTAINER_HEIGHT = 432; // px; 400px + padding (16top, 16bottom)
  public static NUM_SELLING_STORE_WEEKS = 'PROMO_NUMBER_SELLING_STORE_WEEKS';

  // Conditional coloring of bars
  public static growthColorFn(dataPoint: any): any {
    if (dataPoint.promo) {
      dataPoint.color = THEME_COLORS.SKY; // Blue color if promo UPC
    } else if (dataPoint.y > 0) {
      dataPoint.color = THEME_COLORS.GRASS; // Green if positive growth
    } else {
      dataPoint.color = THEME_COLORS.SIENNA; // Orange if negative growth
    }
    return dataPoint;
  }

  // Applies a different color gradient for the promo period stacked bar chart
  /**
   * @deprecated - discovered this seems to be unused during rebranding work
   */
  public static PromoPeriodColorFn(dataPoint: any): any {
    if (dataPoint[PromoDimensionKeys.PERIOD] === 'Promo Period') {
      const colorIndex = Number(dataPoint[PromoDimensionKeys.BASKET_SIZE].replace(/[^0-9]/g, '')) - 1;
      dataPoint.color = PromoConfig.PROMO_TAKE_RATE_COLORSET[colorIndex];
    }
    return dataPoint;
  }

  public static calculateBubbleColor(type: string, typeList: string[]): string {
    if (!typeList) return null;
    return PromoConfig.PROMO_TYPE_COLORSET[typeList.indexOf(type)];
  }

  public static compPeriods: PromoPeriods[] = [ PromoPeriods.PRE, PromoPeriods.YOY ];
  public static readonly defaultFact = KPI.TOTAL_QUANTITY;

  public static kpiNameMap = {
    [KPI.TOTAL_AMOUNT]: 'Dollars',
    [KPI.TOTAL_QUANTITY]: 'Units',
    [KPI.NUM_TRANSACTIONS]: 'Transactions',
    [KPI.BASKET_TOTAL_AMOUNT]: 'Basket Dollars',
    [KPI.BASKET_TOTAL_QUANTITY]: 'Basket Units',
    [KPI.AVG_PRICE]: 'Average Price'
  };
  public static MAX_COMPARISON_GROUPS = 5;
  public static MAX_SOV = 10;
  public static NULL_PROMO_TYPE = 'None';
  public static readonly periodOrder: PromoPeriods[] = [ PromoPeriods.PROMO, PromoPeriods.PRE, PromoPeriods.YOY, PromoPeriods.POST];
  public static PROMO_ITEMS_THRESHOLD = 5;

  /**
   * @deprecated
   */
  public static PROMO_TAKE_RATE_COLORSET = ['#286d8c', '#4a94bb', '#59aed3', '#80c0dd', '#abdaef'];
  // public static PROMO_TYPE_COLORSET = ['#f1712a', '#f0c418', '#4dba6f', '#2d95bf', '#2d95bf', '#955ca5', '#747170', '#747170', '#747170', '#747170']; // TODO: MAKE COLOR CONSISTENT AS V1
  public static PROMO_TYPE_COLORSET = ChartsService.THEME_PALETTE.fills; // Use colors in PDI theme
  /**
   * @deprecated
   */
  public static TAKE_RATE_COLORSET = ['#bc9831', '#eac447', '#edd08a', '#f4dea8', '#f9f2e1'];

  public actions: ActivityOptionsActions[] = [
    ActivityOptionsActions.CLONE,
    ActivityOptionsActions.RESULTS,
    ActivityOptionsActions.SHARE,
    ActivityOptionsActions.DELETE
  ];

  public appId = ApplicationHash.PROMO;

  public defaultPromoRange = 29;

  public kpiOrder = [
    KPI.TOTAL_AMOUNT,
    KPI.TOTAL_QUANTITY,
    KPI.NUM_TRANSACTIONS,
    KPI.BASKET_TOTAL_AMOUNT,
    KPI.BASKET_TOTAL_QUANTITY,
    KPI.AVG_PRICE
  ];

  public name = 'Promotion Measurement';

  public percentShareFactMap = {
    [KPI.TOTAL_AMOUNT]: 'PERCENT_TOTAL_AMOUNT',
    [KPI.TOTAL_QUANTITY]: 'PERCENT_TOTAL_QUANTITY',
  };

  public static periodNames = {
    [PromoPeriods.POST]: 'customPromoDateRange',
    [PromoPeriods.PRE]: 'prePromoDateRange',
    [PromoPeriods.YOY]: 'yoyDateRange',
    [PromoPeriods.PROMO]: 'promoDateRange'
  };

  public static DEFAULT_SOV_JOB_NAME = 'Promoted UPC';

  // All columns under this value% will not have a label (for the promo-measurement chart)
  public promoChartPercentThreshold = 5;

  constructor() {
    super();
  }
}

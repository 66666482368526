import {
  Component, Injector,
  OnInit
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { INavSecondaryEvent, NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { AuthService } from 'app/auth/services/auth.service';
import { RetailerMeta } from 'app/core/services/env-config/interfaces';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { GroupedArray } from '@siq-js/core-lib';
import { CmsApplication } from '@siq-js/cms-lib';
import { Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'siq-nav-secondary',
  templateUrl: './nav-secondary.component.html',
  styleUrls: ['./nav-secondary.component.scss']
})

export class NavSecondaryComponent extends BaseSiqComponent implements OnInit {

  public items: GroupedArray<CmsApplication>;
  public retailers: RetailerMeta[] = [];

  private sidenav: MatSidenav;

  constructor(
    private asyncStatusService: AsyncStatusService,
    private injector: Injector,
    private router: Router
  ) {
    super();
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ envConfig: true, applications: true });
    this.sidenav = this.injector.get(MatSidenav);
    this.items = ApplicationService.Applications$.getValue();

    EnvConfigService.data
    .pipe(filter(data => !!data))
    .subscribe(data => {
      this.retailers = data.retailersMeta; // sets NonAggEnvironment Links
    });

    AuthService.CurrentUser$.subscribe(user => {
      if (user && !this.router.url.includes('new-user')) {
        NavSecondaryService.open();
      } else {
        NavSecondaryService.close();
      }

    });

    NavSecondaryService.nav$.subscribe((event: INavSecondaryEvent) => {
      if (event.open) {
        this.sidenav.open().then(() => {
          event.callback && event.callback();
        });
      } else {
        this.sidenav.close().then(() => {
          event.callback && event.callback();
        });
      }
    });
  }
}

import * as _ from 'lodash';
import { Component, Inject } from '@angular/core';
import { ConfirmationModalConfig } from 'app/core/components/confirmation-modal/confirmation-modal-config.interface';
import { ConfirmationResponse } from 'app/core/components/confirmation-modal/confirmation-response.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';

@Component({
  selector: 'siq-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  public config: ConfirmationModalConfig;

  private _defaultConfig: ConfirmationModalConfig = {
    header: 'Confirmation',
    body: 'Are you sure you want to proceed? Are you sure you want to proceed? Are you sure you want to proceed?',
    buttons: [
      { label: 'Yes', response: { accepted: true, value: true } },
      { label: 'No', response: { accepted: false, value: false } }
    ]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) config: ConfirmationModalConfig,
    private dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {
    this.config = _.assign(this._defaultConfig, config);
  }

  public close(response: ConfirmationResponse = { accepted: false }): void {
    if (this.config.confirmationObs$ && response.accepted) {
      // this.config.confirmationObs$
      //   .pipe(
      //     take(1)
      //   )
      //   .subscribe(resp => {
      //     this.dialogRef.close(response);
      //   }, error => {
      //     this.dialogRef.close(response);
      //   });
      this.config.confirmationObs$
      .pipe(
        take(1)
      )
      .subscribe(
        resp => {},
        error => {},
        () => {
          this.dialogRef.close(response);
        });
    } else {
      this.dialogRef.close(response);
    }
  }
}

import { BaseModel } from 'app/core/models/base-model';

export class SiqError extends BaseModel {
  public blocking: boolean;
  public errorCode: string;
  public message: string;

  static fromJson<T>(j: any, parseKeys?: string[]): SiqError {
    return <SiqError><any> super.typedObjectFromJson(j, SiqError, parseKeys);
  }

  constructor (errorCode?: string, message?: string, blocking?: boolean) {
    super();

    this.blocking = blocking || false;
    this.errorCode = errorCode || '';
    this.message = message || '';
  }

  public isBlocking(): boolean {
    return this.blocking;
  }

  public setBlocking(blocking: boolean): void {
    this.blocking = blocking;
  }

  public getErrorCode(): string {
    return this.errorCode;
  }

  public setErrorCode(errorCode: string): void {
    this.errorCode = errorCode;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): void {
    this.message = message;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { Activity } from 'app/activity/models/activity.model';
import { ActivityService } from 'app/activity/services/activity.service';
import { AffinitiesConfig, AffinitiesDrillDownSummary, AffinitiesFacts } from 'app/siq-applications/modules/affinities/models/affinities-config.model';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';
import { BehaviorSubject, of } from 'rxjs';
import { delay, map, switchMap, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { SimpleLineConfig, SimpleLineParams, TextColorType } from '@siq-js/visual-lib';
import { BackgroundColorType } from 'app/core/models/background-color-type';
import { StatResponse } from 'app/core/models/stat-response.model';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import { AffinitiesDrillDownGridParams, AffinitiesDrillDownGridProcessor } from 'app/siq-applications/modules/affinities/components/affinities-drilldown/processors/affinities-drilldown-grid.processor';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { AffinitiesDrillDownSimpleLineProcessor } from 'app/siq-applications/modules/affinities/components/affinities-drilldown/processors/affnities-drilldown-simple-line-processor';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';

export interface AffinitiesDrilldownResultItem {
  color?: BackgroundColorType;
  error?: boolean;
  gridParams?: AffinitiesDrillDownGridParams;
  label?: string;
  name?: string;
  simpleLineConfig?: SimpleLineConfig;
  simpleLineParams?: SimpleLineParams;
  statResponse?: StatResponse;
}

@Component({
  selector: 'siq-js-affinities-drilldown',
  templateUrl: './affinities-drilldown.component.html',
  styleUrls: ['./affinities-drilldown.component.scss']
})
export class AffinitiesDrilldownComponent extends BaseSiqComponent implements OnInit {

  public activityId: string;
  public avgBasketDollars: string;
  public avgBasketItemCount: string;
  public chartHeight = 300;
  public drillDownActivity: Activity;
  public gridParams: AffinitiesDrillDownGridParams;
  public gridProcessor = AffinitiesDrillDownGridProcessor.processor;
  public hasUnknowns = false;
  public message: string;
  public messageBackground = BackgroundColorType.DANGER;
  public readyForExport$: BehaviorSubject<boolean>; // if grid is ready to be exported
  public resultChartItems: AffinitiesDrilldownResultItem [];
  public resultItems: AffinitiesDrilldownResultItem[] = [
    {
      name: 'avg-basket-amt-by-affinities',
      label: 'Avg Basket Amount',
      color: BackgroundColorType.SUCCESS,
    },
    {
      name: 'avg-basket-item-count-by-affinities',
      label: 'Avg Item Count',
      color: BackgroundColorType.INFO,
    },
    {
      name: 'total-dollars-by-ym',
      label: 'Total Dollars by Year-Month'
    },
    {
      name: 'total-units-by-ym',
      label: 'Total Units by Year-Month'
    },
    {
      name: 'pct-total-units-by-hod',
      label: '% of Total Units by Hour of the Day'
    },
    {
      name: 'pct-total-units-by-dow',
      label: '% of Total Units by Day of the Week',
    },
    {
      name: 'total-transactions-top-5',
      label: 'Top 5 Item Groups'
    }
  ];
  public show = false;
  public simpleLineConfigDaily: SimpleLineConfig;
  public simpleLineConfigHourly: SimpleLineConfig;
  public simpleLineConfigMonthlyDollars: SimpleLineConfig;
  public simpleLineConfigMonthlyUnits: SimpleLineConfig;
  public simpleLineParamsDaily: SimpleLineParams;
  public simpleLineParamsHourly: SimpleLineParams;
  public simpleLineParamsMonthlyDollars: SimpleLineParams;
  public simpleLineParamsMonthlyUnits: SimpleLineParams;
  public simpleLineProcessor = AffinitiesDrillDownSimpleLineProcessor.processor;
  public summary: AffinitiesDrillDownSummary;
  public title: string;

  constructor(
    public config: AffinitiesConfig,
    private affinitiesService: AffinitiesService,
    private activityService: ActivityService,
    private mixpanelService: MixpanelService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    const percentType = GridService.getVisualType('PERCENT');
    const activity = this.data.activity as Activity;
    const facts = activity.getResponse().getFacts();

    // collection to be used in ngFor loop in template
    this.resultChartItems = [
      this.resultItems[2],
      this.resultItems[3],
      this.resultItems[4],
      this.resultItems[5]
    ];
    this.summary = {
      occurrences: UtilsService.num(this.data.rowData[AffinitiesFacts.OCCURRENCE].val, 0),
      support: percentType.formatter({ value: this.data.rowData[AffinitiesFacts.SUPPORT].val }, true),
      confidence: percentType.formatter({ value: this.data.rowData[AffinitiesFacts.CONFIDENCE].val }, true),
      addedValue: percentType.formatter({ value: this.data.rowData[AffinitiesFacts.ADDED_VALUE].val }, true),
      addedValueCssClass: this.colorizeFacts(AffinitiesFacts.ADDED_VALUE, this.data.rowData[AffinitiesFacts.ADDED_VALUE].val, facts.find(f => f.id === AffinitiesFacts.ADDED_VALUE).type),
      lift: UtilsService.num(this.data.rowData[AffinitiesFacts.LIFT].val, 3),
      liftCssClass: this.colorizeFacts(AffinitiesFacts.LIFT, this.data.rowData[AffinitiesFacts.LIFT].val, facts.find(f => f.id === AffinitiesFacts.LIFT).type),
      conviction: UtilsService.num(this.data.rowData[AffinitiesFacts.CONVICTION].val, 3),
      convictionCssClass: this.colorizeFacts(AffinitiesFacts.CONVICTION, this.data.rowData[AffinitiesFacts.CONVICTION].val, facts.find(f => f.id === AffinitiesFacts.CONVICTION).type),
    };

  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
      'Usage': 'Drilldown'
    });
  }

  ngOnInit(): void {
    const leftItem = this.data.rowData[AffinitiesFacts.LEFT].val;
    const rightItem = this.data.rowData[AffinitiesFacts.RIGHT].val;
    this.title = `Affinity Detail, ${ leftItem } and ${ rightItem }`;
    if ([leftItem, rightItem].includes('UNKNOWN') || [leftItem, rightItem].includes('--UNKNOWN--')) {

      this.message = 'Affinity Drilldowns cannot be run for Unknown affinity type';
      this.hasUnknowns = true;

      return;

    }

    this.affinitiesService.createDrillDownRequest(this.data.activity, this.data.formData, this.data.rowData)
      .pipe(
        map((res: any) => res.body.appActivityId),
        takeUntil(this.unsub$)
      )
      .subscribe(id => {
        this.activityId = id;
        this.show = true;
        this.poll();
      });
  }

  private checkStatus() {
    if (this.drillDownActivity.isComplete()) {
      console.log('----- drill down polling complete -----', this.drillDownActivity);
      this.processResults();
      this.ready();
      this.mixpanelService.track(MixpanelEvent.RESULTS_VIEWED, {
        'Application': this.config.getApplication().display,
        'Application ID': this.config.appId,
        'Application Version': this.config.version,
        'Jobs': 6,
        'Viewed From': 'Drilldown'
      });
    } else {
      this.poll();
    }
  }

  private colorizeFacts(factId: string, val: number, type?: string): string {
    const cssClassStrings: string[] = [];

    if (this.affinitiesService.colorizeFact(factId, val, TextColorType.SUCCESS, null, type)) {
      cssClassStrings.push(TextColorType.SUCCESS.toString());
    }

    if (this.affinitiesService.colorizeFact(factId, val, TextColorType.WARN, null, type)) {
      cssClassStrings.push(TextColorType.WARN.toString());
    }

    return cssClassStrings.join(' ');
  }

  private poll() {
    console.log('----- drill down polling ----');
    of(true)
      .pipe(
        delay(this.config.pollInterval),
        takeUntil(this.unsub$),
        switchMap(_ =>
          this.activityService.getActivityResultsPartial(this.activityId, [0, 1, 2, 3, 4, 5, 6], true))
      )
      .subscribe(a => {
        this.drillDownActivity = a;
        this.checkStatus();
      }, err => {
        console.error('Error occurred during polling drilldown result', err);
      });
  }

  private processResults() {
    const datasets: AppResponseDataset[] = this.drillDownActivity.getJobs();
    const results: StatResponse[] = datasets.map(j => j.getResponse());
    results.forEach((res, i) => {
      this.resultItems[i].error = datasets[i].hasErrors();
      if (res.isPopulated() && !datasets[i].hasErrors()) {
        this.resultItems[i].statResponse = res;
        switch (i) {
          // Cases 0 and 1 have only 1 row of data - the last index is the fact value
          case 0:
            this.avgBasketDollars = _.last(this.resultItems[i].statResponse.getValues()[0]);
            break;
          case 1:
            this.avgBasketItemCount = _.last(this.resultItems[i].statResponse.getValues()[0]);
            break;
          case 2:
            // Total Dollars vs Year-Month
            this.simpleLineParamsMonthlyDollars = {
              dimensions: res.getDimensions(),
              metrics: res.getFacts(),
              parent: this,
              parentActivity: this.drillDownActivity,
              selectedDimension$: new BehaviorSubject<CmsField>(res.getDimensions()[0]),
              selectedMetrics$: new BehaviorSubject<CmsMetric[]>([res.getFacts()[0]]),
              statResponse: res
            };
            this.simpleLineConfigMonthlyDollars = AffinitiesDrillDownSimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParamsMonthlyDollars);
            this.resultItems[i].simpleLineParams = this.simpleLineParamsMonthlyDollars;
            this.resultItems[i].simpleLineConfig = this.simpleLineConfigMonthlyDollars;
            break;
          case 3:
            // Total Units vs Year-Month
            this.simpleLineParamsMonthlyUnits = {
              dimensions: res.getDimensions(),
              metrics: res.getFacts(),
              parent: this,
              parentActivity: this.drillDownActivity,
              selectedDimension$: new BehaviorSubject<CmsField>(res.getDimensions()[0]),
              selectedMetrics$: new BehaviorSubject<CmsMetric[]>([res.getFacts()[0]]),
              statResponse: res
            };
            this.simpleLineConfigMonthlyDollars = AffinitiesDrillDownSimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParamsMonthlyUnits);
            this.resultItems[i].simpleLineParams = this.simpleLineParamsMonthlyUnits;
            this.resultItems[i].simpleLineConfig = this.simpleLineConfigMonthlyDollars;
            break;
          case 4:
            // Hour of the Day vs % of Total Units
            this.simpleLineParamsDaily = {
              dimensions: res.getDimensions(),
              metrics: res.getFacts(),
              parent: this,
              parentActivity: this.drillDownActivity,
              selectedDimension$: new BehaviorSubject<CmsField>(res.getDimensions()[0]),
              selectedMetrics$: new BehaviorSubject<CmsMetric[]>([res.getFacts()[0]]),
              statResponse: res
            };
            this.simpleLineConfigHourly = AffinitiesDrillDownSimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParamsDaily);
            this.resultItems[i].simpleLineParams = this.simpleLineParamsDaily;
            this.resultItems[i].simpleLineConfig = this.simpleLineConfigHourly;
            break;
          case 5:
            // Day of the Week vs % of Total Units
            this.simpleLineParamsDaily = {
              dimensions: res.getDimensions(),
              metrics: res.getFacts(),
              parent: this,
              parentActivity: this.drillDownActivity,
              selectedDimension$: new BehaviorSubject<CmsField>(res.getDimensions()[0]),
              selectedMetrics$: new BehaviorSubject<CmsMetric[]>([res.getFacts()[0]]),
              statResponse: res
            };
            this.simpleLineConfigDaily = AffinitiesDrillDownSimpleLineProcessor.generateSimpleLineConfig(this.simpleLineParamsDaily);
            this.resultItems[i].simpleLineParams = this.simpleLineParamsDaily;
            this.resultItems[i].simpleLineConfig = this.simpleLineConfigDaily;
            break;
          case 6:
            this.gridParams = {
              parent: this,
              statResponse: res,
              readyForExport$: this.readyForExport$
            };
            this.gridParams.gridVisualOptions = AffinitiesDrillDownGridProcessor.generateGridVisualOptions(this.gridParams);
            this.resultItems[i].gridParams = this.gridParams;
            break;
          default:
            console.error('Extra result no processed', res);
        }
      }
    });
  }

}

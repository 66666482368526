import { CmsField } from '@siq-js/cms-lib';
import { Serializable } from '@siq-js/core-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import * as _ from 'lodash';

export interface FilterSelectionJson {
  id: string;
  values: string[];
  include: boolean;
  nulls: boolean;
  context?: string;
}

export class FilterSelection implements Serializable<FilterSelectionJson> {
  public field: CmsField;
  public id: string;
  public values: string[] = [];
  public include = true;
  public nulls = false;
  public context: string = '';

  constructor(json: FilterSelectionJson) {
    this.values = json.values;
    this.include = json.include;
    this.nulls = json.nulls;
    this.id = json.id;
    this.context = !_.isEmpty(json.context) ? json.context : ''; // Backwards compatability

    this.field = CmsService.get().findEntity<CmsField>(json.id);
  }

  getDisplayWithContext(): string {
    return this.field.display + this.context;
  }

  isEmpty(): boolean {
    return _.isEmpty(this.values) && !this.nulls;
  }

  toJson(): FilterSelectionJson {
    return {
      id: this.id,
      nulls: this.nulls,
      values: this.values,
      include: this.include
    };
  }
}

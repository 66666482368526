import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { debounceTime, takeUntil, BehaviorSubject, Subject } from 'rxjs';
import { User } from 'app/user/models/user.model';
import { UserDeleteComponent } from 'app/user/components/user-delete/user-delete.component';
import { UserService } from 'app/user/services/user.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { AppComponent } from 'app/app.component';
import { HeaderCellSortableComponent } from 'app/core/components/cell-renderers/header-cell-sortable/header-cell-sortable.component';
import * as _ from 'lodash';
import { AgGridAngular, ColDef, FilterNames, GridOptions, GridReadyEvent, GridService } from '@siq-js/visual-lib';
import { DeleteCellCallbackFn, DeleteCellComponent } from 'app/core/components/cell-renderers/delete-cell/delete-cell.component';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'siq-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnDestroy, OnInit {
  private static readonly DISPLAY_NAME = 'displayName';

  public agTheme: string;
  public gridApi: GridApi;
  public gridOptions: GridOptions;
  public isLoading = false;
  public usersStream$: BehaviorSubject<User[]>;

  private MatDialogRef: MatDialogRef<UserDeleteComponent>;
  public filterObs$: Subject<any> = new Subject();
  private sort$: Subject<void> = new Subject(); // gets set by child header components
  private unsub$: Subject<void> = new Subject<void>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('userListGrid') grid!: AgGridAngular;

  constructor(
    private router: Router,
    private utils: UtilsService
  ) {
    this.usersStream$ = UserService.createStream(list => list);
    UserService.refresh();

    // AccessGroups are needed to decorate the User list, so If AccessGroupService.AccessGroup$ is empty, trigger a refresh
    if (!AccessGroupService.AccessGroups$?.getValue()?.length) {
      AccessGroupService.refresh();
    }

    this.usersStream$?.subscribe(users => {
      if (_.get(this.grid, 'api')) {
        // Need AccessGroup list to get this user's AG name
        this.decorateUsers();
      }
    });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
    if (this.isLoading) {
      EmitterService.get('topLoading').emit(false);
    }
  }

  ngOnInit() {
    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });

    this.setupGrid();
  }

  newUser(): void {
    this.router.navigate(['./users/~/form']);
  }

  private autoSizeColumns() {
    if (!this.grid || !this.grid.api) {
      return;
    }

    if (!this.grid.api.isDestroyed()) {
      this.grid.api.autoSizeAllColumns();
      // Need to set all visible column widths to zero (to remove any previous modifications/auto-sizing)
      this.grid.api.getAllDisplayedColumns().forEach(col => this.grid.api.setColumnWidth(col.getColId(), 0));
      this.grid.api.sizeColumnsToFit();
    }
  }

  /**
   * The User only holds the AccessGroup ID. This fn gets the displayName and updates the User obj.
   */
  private decorateUsers() {
    const poll = () => {
      if (!AccessGroupService.AccessGroups$.getValue().length) {
        setTimeout(() => poll(), 1000);
      } else {
        let users = this.usersStream$.getValue().map((u: User) => {
          const ag = _.find(AccessGroupService.AccessGroups$.getValue(), { id: u.accessGroup });
          u[UserListComponent.DISPLAY_NAME] = ag ? ag.getDisplayName() : ''; // decorate just for this screen
          return u;
        });
        this.setListData(users);
      }
    };
    poll();
  }

  private generateDefaultColDef(name: string, field: string, sort?: string): ColDef {
    const colDef: ColDef = {
      headerName: name,
      field: field,
      sort: sort,
      cellClass: 'simple-cell',
      headerComponent: HeaderCellSortableComponent,
      headerComponentParams: {
        parent: this
      },
      filter: (FilterNames.TEXT).toString(),
      menuTabs: ['filterMenuTab'],
      filterParams: {
        clearButton: true
      },
      resizable: true,
      valueFormatter: (params) => params.value // Added this valueFormatter to support cloud-export (Excel)
    } as ColDef;
    return colDef;
  }

  private setColDefs(): ColDef[] {
    const colDefs: ColDef[] = [];
    colDefs.push(this.generateDefaultColDef('First Name', 'firstName'));
    colDefs.push(this.generateDefaultColDef('Last Name', 'lastName', 'asc'));
    colDefs.push(this.generateDefaultColDef('Email', 'email'));
    colDefs.push(this.generateDefaultColDef('User Groups', UserListComponent.DISPLAY_NAME));
    let statusValue = this.generateDefaultColDef('Status', 'active');
    statusValue.cellDataType = "string";
    statusValue.valueFormatter = (params) => !!params.value ? 'Active' : 'Inactive';
    colDefs.push(statusValue);


    const deleteFn: DeleteCellCallbackFn = (id: number) => {
      const user = _.find(this.usersStream$.getValue(), { id: id });
      this.MatDialogRef = this.utils.openModal(UserDeleteComponent, user, UtilsService.MODAL_CONFIG_SMALL);
      this.MatDialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          UserService.refresh();
        }
      });
    };
    colDefs.push(DeleteCellComponent.generateDeleteColDef('id', deleteFn));

    return colDefs;
  }

  private setupGrid(): void {
    const component: UserListComponent = this;

    this.gridOptions = {
      sideBar: false,
      headerHeight: 52,
      rowHeight: 58,
      suppressContextMenu: true,
      domLayout: 'autoHeight',
      suppressCellFocus: true,
      columnDefs: this.setColDefs(),
      animateRows: true,
      suppressDragLeaveHidesColumns: true,
      onRowClicked: (event) => {
        this.router.navigate(['./users/' + event.node.data.id + '/form']);
      },
      onGridReady: (grid: GridReadyEvent) => {
        this.gridApi = grid.api;
        // @ts-ignore
        this.sort$.next(this.grid.api.getColumnState()); //is this needed?
        this.autoSizeColumns();

        AppComponent.resize$
          .pipe(
            debounceTime(100),
            takeUntil(this.unsub$)
          )
          .subscribe(() => this.autoSizeColumns());

        const globalListener = (event, args) => {
          if (!_.get(component.grid, 'api')) return;
          switch (event) {
            case 'filterChanged':
              component.filterObs$.next(grid.api.getFilterModel());
              break;
          }
        };

        grid.api.addGlobalListener(globalListener);
      }
    };
  }

  private setListData(users: User[]) {
    if (!this.grid.api.isDestroyed()) {
      this.autoSizeColumns();
      this.grid.api.updateGridOptions({ rowData: users });
    }
  }
}

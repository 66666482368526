import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'siq-expired-app-not-found',
  templateUrl: './expired-app-not-found.component.html',
  styleUrls: ['./expired-app-not-found.component.scss']
})
export class ExpiredAppNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

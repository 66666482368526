import * as _ from 'lodash';
import { catchError, debounceTime, filter, map, BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ResponseCodesConfig,
  NotificationType,
  ResponseCode,
  ResponseCodes,
  NotificationService } from '@siq-js/angular-buildable-lib';
import { Router } from '@angular/router';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { User } from 'app/user/models/user.model';
import { AuthService } from 'app/auth/services/auth.service';
import { UserPayloadInterface } from 'app/user/models/user-payload-interface';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';

@Injectable()
export class UserService extends SiqHttpService {

  /**
   * Creates a BehaviorSubject stream (Not filled automatically, call UserService.refresh(); to fill with data)
   * @param mapFn: pure function that transforms the user list into the desired output array of users
   */
  public static createStream(
    mapFn: (users: User[]) => User[]
  ): BehaviorSubject<User[]> {

    const bs$ = new BehaviorSubject<User[]>([]);

    UserService.Users$
    .pipe(
      map(list => mapFn(list)),
      filter(list => {
        // nil check
        if (!list) return false;
        return true;
      })
    )
    .subscribe(list => {
      bs$.next(list);
    });

    return bs$;
  }

  public static extractUserFromUserListByEmail(users: User[], email: string): User {
    return _.find(users, (user: User) => user.email.trim().toLowerCase() === email.trim().toLowerCase());
  }

  public static refresh() {
    this.RefreshDebouncer$.next();
  }

  /** Not filled automatically, call UserService.refresh(); to fill with data */
  public static readonly Users$: BehaviorSubject<User[]> = new BehaviorSubject([]);
  
  private static readonly createUserFields = ['active', 'email', 'firstName', 'lastName', 'organizationId', 'userRole', 'accessGroup'];
  private static readonly RefreshDebouncer$: Subject<void> = new Subject<void>();
  private user: User;

  constructor(
    http: HttpClient,
    notificationService: NotificationService,
    public router: Router,
    private mixpanelService: MixpanelService,
  ) {
    super(http, notificationService);
    UserService.RefreshDebouncer$
    .pipe(
      debounceTime(500)
    )
    .subscribe(async () => {
      if (AuthService.CurrentUser$.getValue()) {
        this.retrieveUsers();
      }
    });
  }

  addOrEditUser(user: object): Observable<HttpResponse<any>> {
    // Hold the user data passed in to be used in Response notifications
    this.user = User.fromJson(user);
    if (this.user.id) {
      return this.update({
        endpoint: 'siteusers',
        body: user
      }).pipe(tap(response => { if (response.status === 200) this.mixPanelTrack(true); }));
    } else {
      return this.create({
        endpoint: 'siteusers',
        body: this.generateUserRequestPayload(user)
      }).pipe(tap(response => { if (response.status === 200) this.mixPanelTrack(false); }));
    }
  }

  /**
   * Determines what the appropriate default URL is, taking into account user preferences,
   * and global default page.
   *
   * @returns Observable<string> The default url.
  */
  public getDefaultUrl(): Observable<string> {
    return this.getSinglePreference('homePageUrl')
      .pipe(
        map( (url: string) => {
          // Navigates to the homepage url, if set, otherwise to the default path
          return url || AppLoadingService.defaultPath;
        })
      );
  }

  getPreferences () {
    return this.get({endpoint: 'preferences'})
      .pipe(
        map((res: any) => {
          res.preferenceJson = JSON.parse(res.preferenceJsonString);
          return res;
        })
      );
  }

  public getResponseCodes(responseCodesConfig: ResponseCodesConfig): ResponseCodes {
    // OVERRIDE of super class function
    let overrideCodes: ResponseCode[] = [];
    if (this.user) {
      overrideCodes.push(new ResponseCode(
        200,
        '<hr/>User "' + this.user.firstName + ' ' + this.user.lastName + '" has been ' + responseCodesConfig.responseCodeType['actions'][0],
        'User ' + _.capitalize(responseCodesConfig.responseCodeType['actions'][0]),
        NotificationType.INFO
      ));
      overrideCodes.push(new ResponseCode(
        204,
        '<hr/>User "' + this.user.firstName + ' ' + this.user.lastName + '" has been ' + responseCodesConfig.responseCodeType['actions'][0],
        'User ' + _.capitalize(responseCodesConfig.responseCodeType['actions'][0]),
        NotificationType.INFO
      ));
    } else if (responseCodesConfig.resp.url && _.includes(responseCodesConfig.resp.url, 'preferences')) {
      overrideCodes.push(new ResponseCode(
        200,
        '<hr/>Your user preferences have been successfully updated',
        'User Preferences Updated',
        NotificationType.INFO
      ));
      overrideCodes.push(new ResponseCode(
        204,
        '<hr/>Your user preferences have been successfully updated',
        'User Preferences Updated',
        NotificationType.INFO
      ));
    }  else if (responseCodesConfig.resp.url && _.includes(responseCodesConfig.resp.url, 'change-password')) {
      overrideCodes.push(new ResponseCode(
        200,
        '<hr/>Your password has been successfully changed',
        'User Password Changed',
        NotificationType.SUCCESS
      ));
      overrideCodes.push(new ResponseCode(
        204,
        '<hr/>Your password has been successfully changed',
        'User Password Changed',
        NotificationType.SUCCESS
      ));
    }
    const errorMessage = this.get500ErrorMessage(responseCodesConfig.resp);
    overrideCodes.push(new ResponseCode(
      500,
      !_.isEmpty(errorMessage) ? '<hr/>' + errorMessage : '',
      'Error ' + responseCodesConfig.responseCodeType['actions'][1] + ' User',
      NotificationType.ERROR
    ));
    if (responseCodesConfig.resp['error']) {
      if (responseCodesConfig.resp.status === 409) {
        overrideCodes.push(new ResponseCode(
          409,
          !_.isEmpty(responseCodesConfig.resp) ? '<hr/>' + responseCodesConfig.resp['error']['siqApi']['errors'][0]['message'] : '',
          'Error ' + responseCodesConfig.responseCodeType['actions'][1] + ' User',
          NotificationType.ERROR
        ));
      } else if (responseCodesConfig.resp.status === 422) {
        overrideCodes.push(new ResponseCode(
          422,
          '<hr/>The value entered for your current password is incorrect.',
          'Incorrect Password',
          NotificationType.ERROR
        ));
      } else {
        overrideCodes.push(new ResponseCode(
          400,
          '<hr/>An unknown error has occured.',
          'Unknown Error',
          NotificationType.ERROR
        ));
      }
    }

    return new ResponseCodes(overrideCodes);
  }

  getSinglePreference(key: string): Observable<any> {
    return this.getPreferences()
      .pipe(
        map((res: any) => {
          return res.preferenceJson[key];
        })
      );
  }

  getUser(id: string): Observable<User[]> {
    // first check if we can get the User from the current list:
    let out: Observable<User[]>;
    const u: User = _.find(UserService.Users$.getValue(), {id: parseInt(id, 10)});

    if (u) {
      out = new Observable<User[]>(observer => {
        observer.next([u]);
        observer.complete();
      });
    } else {
      let endpoint = 'siteusers/' + id;
      out = this.get({endpoint: endpoint})
      .pipe(
        map((res: any[]) => this.convertResponse([res]) as User[]),
        catchError(this.handleHttpError)
      );
    }

    return out;
  }

  removeUser(user: User): Observable<HttpResponse<any>> {
    this.user = User.fromJson(user);
    return this.remove({endpoint: 'siteusers/' + user.id}).pipe(
      tap( response => {
        if (response.status === 200 || response.status === 204) {
          this.mixpanelService.track(
            MixpanelEvent.USER_DELETED,
            {
              'First Name': user.firstName,
              'Last Name': user.lastName,
              'Email': user.email,
              'Access Group': user.accessGroup,
              'Organization Id': user.organizationId
              }
            )
          }
        }
      )
    );
  }

  retrieveUsers(): void {
    let endpoint = 'siteusers/all';
    this.get({endpoint: endpoint})
    .pipe(
      map((res: any[]) => this.convertResponse(res) as User[]),
      catchError(this.handleHttpError)
    )
    .subscribe(
      users => UserService.Users$.next(users),
      err => {throw new Error(err); }
    );
  }

  setPassword (obj, suppressNotification = false): Observable<HttpResponse<any>> {
    this.user = null;
    return this.create({
      endpoint: 'user/public/change-password',
      body: obj,
      suppressNotification: suppressNotification
    });
  }

  setPreferences (obj, suppressNotification: boolean = false): Observable<any> {
    this.user = null;
    return this.create({
      endpoint: 'preferences',
      body: obj,
      suppressNotification: suppressNotification
    })
    .pipe(
      map((res: any) => {
        res.body.preferenceJson = JSON.parse(res.body.preferenceJsonString);
        return res.body;
      })
    );
  }

  updateProfile(params: UserPayloadInterface): Observable<HttpResponse<any>> {
    this.user = User.fromJson(params);
    return this.update({
      endpoint: 'profile',
      body: params
    });
  }

  isRegistered(email: string): Observable<boolean> {
    return this.get({
      endpoint: 'siteusers/is-registered?email=' + encodeURIComponent(email)
    });
  }

  private convertResponse(jsonArr: any[]): User[] {
    return jsonArr.map(json => User.fromJson(json));
  }

  private generateUserRequestPayload(user: object): object {
    let payload = {};
    UserService.createUserFields.forEach(field => payload[field] = user[field]);
    return payload;
  }

  private mixPanelTrack(isEdit: boolean) {
    const mixpanelEvent = isEdit ? MixpanelEvent.USER_EDITED : MixpanelEvent.USER_CREATED;
    this.mixpanelService.track(
      mixpanelEvent,
      {
        'First Name': this.user.firstName,
        'Last Name': this.user.lastName,
        'Email': this.user.email,
        'Active': this.user.active,
        'Access Group': this.user.accessGroup,
        'Organization Id': this.user.organizationId
      }
    )
  }

}

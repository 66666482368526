<mat-card>
  <mat-card-content>
    <mat-tab-group
      color="accent"
      dynamicHeight="1"
      #tabs
      (selectedIndexChange)="tabChanged($event)"
    >
      <mat-tab [label]="tab.name" *ngFor="let tab of displayedTabs">

        <ng-template matTabContent>

          <siq-activity-table
            [activityList$]="filteredActivityList$"
            [tab]="tab"
            [parent]="this"
          ></siq-activity-table>

        </ng-template>

      </mat-tab>

    </mat-tab-group>
  </mat-card-content>
</mat-card>

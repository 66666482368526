<div class="renderer-container" *ngIf="ready$ | async">
  <ng-container *ngIf="model; else empty">
    <mat-chip class="mat-chip-xsmall default">{{ model.sheets.label }}</mat-chip>
    <mat-chip *ngIf="!isMultiMode" class="mat-chip-xsmall default" [matTooltip]="model.retailers.toolTip">{{model.retailers.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default">{{ model.dateRange }}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" [matTooltip]="model.dimensions.toolTip">{{model.dimensions.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" [matTooltip]="model.metrics.toolTip">{{model.metrics.label}}</mat-chip>
    <mat-chip class="mat-chip-xsmall default" [matTooltip]="model.filters.toolTip">{{model.filters.label}}</mat-chip>
  </ng-container>
  <ng-template #empty>
    <mat-chip class="mat-chip-xsmall default">Empty Report</mat-chip>
  </ng-template>
</div>

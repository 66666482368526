import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { CoreModule } from 'app/core/core.module';
import { UserModule } from 'app/user/user.module';
import { LoginComponent } from 'app/public/components/login/login.component';
import { LoginFormComponent } from 'app/public/components/login-form/login-form.component';
import { AuthService } from 'app/auth/services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    UserModule,
    SiqMaterialModule,
    RouterModule
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent
  ],
  exports: [
    LoginComponent,
    LoginFormComponent
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule {
}

import { BaseCriteriaRendererComponent } from 'app/core/components/cell-renderers/base-criteria-renderer/base-criteria-renderer';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { Activity } from 'app/activity/models/activity.model';
import { CanBeDynamic } from 'app/core/models/can-be-dynamic.interface';

export abstract class ActivityCriteriaRendererComponent<T extends Activity = Activity> extends BaseCriteriaRendererComponent<Activity> implements CanBeDynamic {
  public data: any; // Needed/used by DynamicComponent
  public model: any = null;

  protected abstract setupModel(activity: T): void;

  protected constructor (
    asyncStatusService: AsyncStatusService
  ) {
    super(asyncStatusService);
  }

  dynamicInit() {
    this.setupModel(this.data);
  }
}

<div class="app-container-wrapper app-header">
  <section class="app-container app-header-container">
    <h5 class="app-title">My Profile</h5>
  </section>
</div>

<div class="app-container-wrapper no-bg">
  <div class="app-container limited-width-content content-padded">

<mat-card>
  <mat-card-content>
    <mat-tab-group [dynamicHeight]="true" color="accent">
      <mat-tab label="User">
        <form (ngSubmit)="submitUserForm()" [formGroup]="userForm">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="First Name"
              formControlName="firstName"
            />
            <mat-error
              *ngIf="
                !userForm.controls.firstName.valid &&
                userForm.controls.firstName.touched
              "
              >First Name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Last Name"
              formControlName="lastName"
            />
            <mat-error
              *ngIf="
                !userForm.controls.lastName.valid &&
                userForm.controls.lastName.touched
              "
              >Last Name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              type="email"
              placeholder="Email Address"
              formControlName="email"
            />
            <mat-error
              *ngIf="
                userForm.controls.email.touched &&
                !userForm.controls.email.errors?.required &&
                userForm.controls.email.errors?.invalid
              "
              >{{emailErrorMsg}}
            </mat-error>
            <mat-error
              *ngIf="
                userForm.controls.email.errors?.required &&
                userForm.controls.email.touched
              "
              >Email Address is required
            </mat-error>
            
          </mat-form-field>

          <mat-form-field>
            <mat-label>Access Group</mat-label>
            <input matInput type="text" formControlName="accessGroup" />
          </mat-form-field>

          <button
            mat-raised-button
            color="accent"
            [disabled]="!userForm.valid || !formValueChanged"
          >
            Save
          </button>
        </form>
      </mat-tab>

      <mat-tab label="Password" ng-form="passwordFormElement">

        <form (ngSubmit)="submitPasswordForm()">

          <div class="password-form-wrapper tab-content-wrapper">
            <div>
              <mat-form-field>
                <input matInput placeholder="Current Password" [(ngModel)]="passwordForm.password" name="password" type="password" required>
                <mat-error>{{ currentPWErrorMsg }}</mat-error>
              </mat-form-field>

              <mat-form-field>
                <input matInput placeholder="New Password" [(ngModel)]="passwordForm.newPassword" name="newPassword" type="password" required>
              </mat-form-field>

              <mat-form-field>
                <input matInput placeholder="Confirm New Password" [(ngModel)]="passwordForm.newPasswordConfirmation" name="newPasswordConfirmation" type="password" required>
              </mat-form-field>
            </div>

            <div class="password-rules">
              <siq-password-rules
                [newPassword]="passwordForm.newPassword"
                [confirmNewPassword]="passwordForm.newPasswordConfirmation"
                #passwordValidation
              ></siq-password-rules>
            </div>
          </div>

          <button mat-raised-button color="accent" [disabled]="!passwordValidation.validatePasswords()">Save</button>

          <p class="text-success" *ngIf="passwordSuccess">Your password was successfully updated.</p>

        </form>

      </mat-tab>

      <mat-tab label="Preferences">
        <form (ngSubmit)="submitPreferencesForm()">

          <mat-form-field color="accent">
            <mat-select [(value)]="preferencesForm.homePageUrl" placeholder="Home Page">
              <mat-option *ngFor="let homepage of homePageValues" [value]="'/' + homepage.path">
                {{homepage.display}}
              </mat-option>
            </mat-select>
            <mat-hint align="start"> When you log in, you will be taken to this URL within the platform. </mat-hint>
          </mat-form-field>

          <button mat-raised-button color="accent" [disabled]="preferencesForm?.homePageUrl === pristinePrefs?.homePageUrl">Save</button>

        </form>
      </mat-tab>

      <mat-tab label="API Access">
        <form>

          <mat-form-field>
            <input matInput placeholder="Client ID" name="clientId" value="{{ currentUser?.clientId }}" disabled>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Client Secret" name="clientSecret" type="{{ showSecret ? 'text' : 'password' }}" value="{{ currentUser?.clientSecret }}" disabled>
          </mat-form-field>

          <mat-slide-toggle color="accent" [(ngModel)]="showSecret" name="showSecret">Show Client Secret</mat-slide-toggle>

        </form>
      </mat-tab>

      <mat-tab label="Admin" *ngIf="isAdmin">
        <div class="tab-content-wrapper">
          <p>
            Use this screen to navigate to another part of the application <em>without reloading the entire platform</em>.
          </p>
          <mat-form-field>
            <input matInput [(ngModel)]="adminRoute">
            <span matPrefix class="navToPrefix">{{ inputPrefix }}</span>
          </mat-form-field>
          <button (click)="adminNavTo()" mat-raised-button color="accent" >Go</button>
        </div>
      </mat-tab>

    </mat-tab-group>
  </mat-card-content>
</mat-card>

  </div>
</div>
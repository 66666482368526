<mat-card ng-form="model">

  <form (ngSubmit)="login()" #loginForm="ngForm">

    <mat-card-title>
      <img *ngIf="lightTheme" src="assets/img/PDI Logo-RGB-Two Color-with Tag-Digital.png" alt="PDI Technologies" />
      <img *ngIf="!lightTheme" src="assets/img/PDI Logo-RGB-White-with Tag-Digital.png" alt="PDI Technologies" />
    </mat-card-title>

    <mat-card-content *ngIf="loading" class="submitting">

      <siq-progress-spinner [show]="loading"></siq-progress-spinner>

    </mat-card-content>

    <mat-card-content *ngIf="!loading">

      <!-- Element to display any error messages -->
      <mat-toolbar color="warn" *ngIf="errorMessage">{{errorMessage}}</mat-toolbar>

      <mat-form-field class="required-no-asterisk">
        <input id="login-username" matInput type="email" placeholder="Email" [(ngModel)]="model.username" name="username" #inputEmail #username="ngModel" (keyup)="setEmailErrorMessage($event)" required email>
        <mat-error *ngIf="username.invalid && username.touched">{{invalidEmailMessage}}</mat-error>
      </mat-form-field>

      <mat-form-field class="required-no-asterisk">
        <input id="login-password" matInput type="password" placeholder="Password" [(ngModel)]="model.password" name="password" required>
      </mat-form-field>

    </mat-card-content>

    <mat-card-actions>
      <button id="login-btn" mat-raised-button color="accent" [disabled]="loginForm.invalid || loading">Log In</button>
    </mat-card-actions>

    <a [routerLink]="'/' + resetRoute.path">Forgotten Password?</a>

  </form>

</mat-card>

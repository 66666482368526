<div class="container">

  <div class="text-wrapper">
    <label>{{ dimValue | capitalize }}</label>
  </div>

  <div class="actions">
    <mat-icon class="add-icon hover-only" (click)="drilldown()">+</mat-icon>
  </div>

</div>

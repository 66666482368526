import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateShortcutsComponent } from 'app/siq-forms/modules/dates/components/date-shortcuts/date-shortcuts.component';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { CoreConstants, DatePickerAction, DatesServiceCore, WeekEndingDay } from '@siq-js/core-lib';
import { BehaviorSubject, Subject, debounceTime, filter, map, takeUntil } from 'rxjs';
import { MatDatepicker, MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import {
  CommunalShortcuts,
  DatePickerConfig,
  DateRangeInterface,
  DateRangeInterfaceType,
  DateShortcutsConfig,
  DynamicDateShortcut
} from 'app/siq-forms/modules/dates/models/interfaces';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { DateShortcutsService } from 'app/siq-forms/modules/dates/services/date-shortcuts.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import {
  AbstractControl,
  FormControlStatus,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

@Component({
  selector: 'siq-js-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent extends BaseSiqComponent implements OnInit {
  @ViewChild('rangePicker') rangePicker: MatDateRangePicker<Date>;
  @ViewChild('singlePicker') singlePicker: MatDatepicker<Date>;
  @Input() config: DatePickerConfig;
  @Input() disableInitModelValidation: boolean; // if true, don't auto perform init model validation
  @Input() initModel: DateRangeInterface; // Initial value from the form.
  @Input() noDefault: boolean; // if true, the init dates will be set via setPrimaryDate method. eg: in comparison-dates-selection.component.
  @Output() dateChanged = new EventEmitter<DateRangeInterface>();
  @Output() wEndingChanged = new EventEmitter<WeekEndingDay>();

  public actionApplied: DatePickerAction = DatePickerAction.DEFAULT;
  // Using the custom validator. We don't need to validate each individual input field, so no formControl is needed.
  public dateRangeForm = new FormGroup({},{ validators: this.customFormValidator() });
  public dateRangeFormStatusPointer: FormControlStatus; // pointer to use in the template (to better control timing)
  public dateShortcutsConfig: DateShortcutsConfig;
  public endDate: Date;
  public headerShortcutsComponent = DateShortcutsComponent;
  public isMultiMode = false;
  public isPreviewMode = false;
  public isPreviewTheme = false;
  public maxDate: Date;
  public minDate: Date;
  public preViewEnd: Date;
  public previewShortcut: DynamicDateShortcut;
  public preViewStart: Date;
  public selectedShortcut: DynamicDateShortcut;
  public shortcuts: DynamicDateShortcut[];

  public startDate: Date;
  public tooltipWarn: boolean;

  public days: {
    id: string,
    label: string,
    default?: boolean,
    selected?: boolean
  }[] = [
    {
      id: WeekEndingDay.SUN,
      label: 'Sunday',
    },
    {
      id: WeekEndingDay.MON,
      label: 'Monday'
    },
    {
      id: WeekEndingDay.TUE,
      label: 'Tuesday'
    },
    {
      id: WeekEndingDay.WED,
      label: 'Wednesday'
    },
    {
      id: WeekEndingDay.THU,
      label: 'Thursday'
    },
    {
      id: WeekEndingDay.FRI,
      label: 'Friday'
    },
    {
      id: WeekEndingDay.SAT,
      label: 'Saturday'
    }
  ];

  public tooltip: {
    msg: string;
    read: boolean;
  } = null;

  private isDateRangeValid: boolean = true;

  private debouncer$: Subject<DateRangeInterface> = new Subject<DateRangeInterface>();

  constructor(
    private datesService: DatesService,
    private dateShortcutsService: DateShortcutsService
  ) {
    super();
    this.dateShortcutsConfig = {
      type: 'primary',
      datePickerAction$: new BehaviorSubject<DatePickerAction>(null),
      parent: this,
    };

    // ICD-739: Use debouncer to prevent unready dateChanged emit during date picker selection
    this.debouncer$
    .pipe(
      takeUntil(this.unsub$),
      debounceTime(500)
    )
    .subscribe(d => {
      d.isDateRangeValid = this.validateDateRange(d);
      this.dateChanged.emit(d);
    })
  }

  init() {
    // set default we
    let day = this.days.find(v => v.id === this.datesService.getDefaultWeekEndingDay());

    day.default = true;
    day.selected = true;

    this.datesService.weekEndingDay$
    .pipe(
      takeUntil(this.unsub$),
      map(we => we === WeekEndingDay.OLD_REPORT ? WeekEndingDay.SAT : we)
    )
    .subscribe((we) => {
      if (!!we && !!this.days) {
        // reset selection
        this.days.find(v => v.selected === true).selected = false;
        // set day
        this.days.find(v => v.id === we).selected = true;
      }

    });

    this.dateRangeForm.statusChanges.pipe(debounceTime(300))
    .subscribe(status => {
      this.dateRangeFormStatusPointer = status
    })

    this.datesService.communalShortcuts$
    .pipe(
      takeUntil(this.unsub$)
    )
    .subscribe((communalSC: CommunalShortcuts) => {
      this.updateTooltip(communalSC,this.isMultiMode);
      this.setAvailableDates(communalSC);
      if (this.config.allowShortcuts) {
        this.setAvailableShortcuts(communalSC);
      }
      if (!this.noDefault) {
        let initModel: DateRangeInterface;

        if (this.isInitModelValid(this.initModel, communalSC) && this.initModel?.type === DateRangeInterfaceType.POPULATED) { // has valid initModel from form during edit/clone mode. The order matters!
          // use passed in date(s)
          initModel = this.initModel;
        } else if (this.disableInitModelValidation) {
          initModel = this.initModel;
        } else { // no valid initModel, just use default static dates.
          initModel = this.getDefaultDates(communalSC);
        }

        this.initDates(initModel, communalSC);
      }
    }, err => {
      console.log('Error retrieving DD: ', err);
      this.config.allowShortcuts = false;
    });

    this.dateShortcutsConfig.position = this.config.shortcutPosition;

    if (this.config.rangeMode && this.config.allowShortcuts) {
      this.headerShortcutsComponent.prototype.config = this.dateShortcutsConfig;
      const { datePickerAction$ } = this.dateShortcutsConfig;

      datePickerAction$
        .pipe(
          filter(val => !!val),
          takeUntil(this.unsub$)
        )
        .subscribe(action => {
          this.processAction(action);
        });
    }
  }

  ngOnInit(): void {
    this.isMultiMode = EnvConfigService.isMultiMode();
    this.init();
    this.ready();
  }

  ngOnDestroy(): void {
    if (this.config.weekEndingDay) {
      this.datesService.weekEndingDay$.next(this.datesService.getDefaultWeekEndingDay());
    }
    super.ngOnDestroy();
  }

  onDateChange(e: MatDatepickerInputEvent<Date>, type: 'start' | 'end') {
    this.isPreviewTheme = false;

    this.actionApplied = DatePickerAction.DEFAULT;
    if (type === 'start') {
      this.startDate = e.value;
    } else if (type === 'end') {
      this.endDate = e.value;
    }
    // emit values
    if (!this.config.rangeMode) {
      this.emit({
        begin: this.startDate,
        end: null,
        isDateRangeValid: this.isDateRangeValid,
        type: DateRangeInterfaceType.POPULATED
      });
    } else {
      this.emit({
        begin: this.startDate,
        end: this.endDate,
        dynamicBegin: null,
        dynamicEnd: null,
        isDateRangeValid: this.isDateRangeValid,
        type: DateRangeInterfaceType.POPULATED
      });
      this.selectedShortcut = null; // clear DD selection
    }
    this.tooltipWarn = false;
  }

  onRangePickerOpened() {
    if (this.headerShortcutsComponent.prototype.config) {
      this.headerShortcutsComponent.prototype.config.parent = this;
    }
  }

  onPickerClosed() {
    this.isPreviewMode = false;
    if (this.actionApplied === DatePickerAction.APPLY) {
      // dynamic range is selected, static range selection is handled in dateChange method
      this.emit({
        begin: this.startDate,
        end: this.endDate,
        dynamicBegin: this.selectedShortcut.in,
        dynamicEnd: this.selectedShortcut.out,
        isDateRangeValid: this.isDateRangeValid,
        type: DateRangeInterfaceType.POPULATED
      });
    } else {
      // Resseting - When user clicks away the date picker,
      // setting isPreviewMode = false so the picker will show the previous selected startDate and endDate.
      // setting isPreviewTheme to determine if date is selected from sortcut or directly
      this.isPreviewTheme = false; // reset theme
    }

    // Update the pointer with the new status. This pointer is used in the .html template and
    // although this.dateRangeForm.status is changed immediately, updating the pointer here/now
    // allows the error message (and colorization) to only show once the calendar closes (rather
    // than immediately while the user still has focus IN the date picker).
    this.dateRangeFormStatusPointer = this.dateRangeForm.status;
  }

  setAvailableDates(communalSC: CommunalShortcuts) {
    this.minDate = DatesService.parse(communalSC.communalRange.periodStart, AppSiqConstants._dateFormat, new Date());
    this.maxDate = DatesService.parse(communalSC.communalRange.periodEnd, AppSiqConstants._dateFormat, new Date());
  }

  setDate(dates: DateRangeInterface) {
    if (dates) {
      this.startDate = dates.begin;
      this.endDate = dates.end;
      this.emit(dates);
    }
  }

  showPicker(show: boolean) {
    const picker = this.config.rangeMode ? this.rangePicker : this.singlePicker;
    if (show) {
      picker.open();
    } else {
      picker.close();
    }
  }

  updateTooltip(communalSC: CommunalShortcuts, isMultiMode: boolean) {
    let msg = `Date Range(s) Available\n`;
    const currSchemas = this.datesService.datePickerSchema$.getValue();
    if (!currSchemas || !currSchemas.length) return;
    if (currSchemas.length > 1) {
      msg = `Communal Date Range\n${DatesService.format(DatesService.parse(communalSC.communalRange.periodStart, AppSiqConstants._dateFormat, new Date()), CoreConstants._shortDate)} - ${DatesService.format(DatesService.parse(communalSC.communalRange.periodEnd, AppSiqConstants._dateFormat, new Date()), CoreConstants._shortDate)}\n`;
    }

    // Reads actual date range from BE ICE-1600, so information from date picker icon will match report data
    this.dateShortcutsService.getDateRanges(currSchemas).pipe(takeUntil(this.unsub$)).subscribe(dateRange => {
        let arrDynDates = (dateRange['dataDateRange'] as any[]);

        if (!isMultiMode) {
          currSchemas.forEach(schema => {
            const dynamicDateRetailerName = arrDynDates.find(oneDynRetailer => oneDynRetailer['retailer'] === schema);
            const minDate = DatesServiceCore.parse(dynamicDateRetailerName.minDate, CoreConstants._dateFormat, new Date());
            const maxDate = DatesServiceCore.parse(dynamicDateRetailerName.maxDate, CoreConstants._dateFormat, new Date());
            msg += `\n${UtilsService.getRetailerDisplay(schema)}\n${DatesService.format(minDate, AppSiqConstants._shortDate)} - ${DatesService.format(maxDate, AppSiqConstants._shortDate)}\n`;
          });
        }

        this.tooltip = {
          msg: msg,
          read: false
        };
      }
    );

    this.tooltip = {
      msg: msg,
      read: false
    };
  }

  private emit(d: DateRangeInterface) {
    this.debouncer$.next(d);
  }

  private getDefaultDates(communalSC: CommunalShortcuts): DateRangeInterface {
    return this.config.rangeMode
      ?
      DatesService.getDefaultDateRange(DatesService.parse(communalSC.communalRange.periodEnd, AppSiqConstants._dateFormat, new Date()))
      :
      DatesService.getDefaultDate(DatesService.parse(communalSC.communalRange.periodStart, AppSiqConstants._dateFormat, new Date()));
  }

  /**
   * When switching retailers, previous selected dates may become invalid.
   */
  private isInitModelValid(initModel: DateRangeInterface, communalSC: CommunalShortcuts): boolean {
    if (!initModel) return false;
    if (DatesService.isDynamic(initModel)) return true; // this method only check static dates, so return true for DD. If DD, check will be performed in initDates()
    if (initModel.begin === null && initModel.end === null && initModel.type === DateRangeInterfaceType.POPULATED) return true; // Promo has empty defaults
    const communalEnd = DatesService.parse(communalSC.communalRange.periodEnd, AppSiqConstants._dateFormat, new Date());
    const communalStart = DatesService.parse(communalSC.communalRange.periodStart, AppSiqConstants._dateFormat, new Date());

    const res = this.config.rangeMode
      ? !(DatesServiceCore.isBefore(initModel.begin, communalStart) || DatesServiceCore.isAfter(initModel.end, communalEnd))
      : !DatesServiceCore.isBefore(initModel.begin, communalStart);

    if (!res && !this.disableInitModelValidation) initModel.type = DateRangeInterfaceType.DEFAULT;

    this.tooltipWarn = this.disableInitModelValidation && !res;
    return res;
  }

  private initDates(initModel: DateRangeInterface, communalSC: CommunalShortcuts) {
    if (this.config.rangeMode && this.config.allowShortcuts && DatesService.isDynamic(initModel)) { // dynamic dates
      this.selectedShortcut = DateShortcutsService.findDynamicDateShortCut(this.shortcuts, { in: initModel.dynamicBegin, out: initModel.dynamicEnd });
      this.actionApplied = DatePickerAction.APPLY;
      if (!this.selectedShortcut.disabled) { // valid
        this.startDate = this.selectedShortcut.begin;
        this.endDate = this.selectedShortcut.end;
        initModel.begin = this.selectedShortcut.begin;
        initModel.end = this.selectedShortcut.end;
        this.isPreviewTheme = true;
        this.emit(initModel);
      } else { // not valid, set default static dates. Occurs when switching retailers, previous DD selection(initModel) no longer valid
        const defaultDates = DatesService.getDefaultDateRange(DatesService.parse(communalSC.communalRange.periodEnd, AppSiqConstants._dateFormat, new Date()));
        this.startDate = defaultDates.begin;
        this.endDate = defaultDates.end;
        this.isPreviewTheme = false;
        this.selectedShortcut = null;
        this.emit(defaultDates);
      }
    } else { // static
      this.selectedShortcut = null;
      this.startDate = initModel.begin;
      this.endDate = initModel.end;
      this.isPreviewTheme = false;
      this.emit(initModel);
    }
  }

  private processAction(action: DatePickerAction) {

    if (action === DatePickerAction.APPLY) {
      if (this.isPreviewMode) {
        this.startDate = this.previewShortcut.begin;
        this.endDate = this.previewShortcut.end;
        this.selectedShortcut = this.previewShortcut;
      }
    }
    this.actionApplied = action;

    this.showPicker(false);
  }

  private setAvailableShortcuts(communalSC: CommunalShortcuts) {
    const shortcuts = communalSC.shortcuts;
    this.shortcuts = DateShortcutsService.getDynamicDateShortcuts();
    shortcuts.forEach(sc => {
      this.shortcuts[sc.id].disabled = false;
      this.shortcuts[sc.id].begin = DatesService.parse(sc.periodStart, AppSiqConstants._dateFormat, new Date());
      this.shortcuts[sc.id].end = DatesService.parse(sc.periodEnd, AppSiqConstants._dateFormat, new Date());
    });
  }

  private validateDateRange(model: DateRangeInterface): boolean {
    const start = model ? model.begin : this.startDate;
    const end = model ? model.end : this.endDate;
    if (this.disableInitModelValidation) {
      if (this.config.rangeMode) { // Promo List page date picker, range mode, Do not validate min and max date
        this.isDateRangeValid = model.type === DateRangeInterfaceType.FORCE_VALID || (!!start && !!end && start <= end);
      } else { // RB YOY Modal Date Picker, single mode, validate startDate only
        this.isDateRangeValid = model.type === DateRangeInterfaceType.FORCE_VALID || (!!start && start <= this.maxDate && start >= this.minDate);
      }
    } else { // All range mode except Promo List page date picker
      this.isDateRangeValid = model.type === DateRangeInterfaceType.FORCE_VALID || (
        !!start &&
        !!end &&
        start <= end // validate if user manually enter endDate earlier than startDate
      )
      if (this.isDateRangeValid && this.minDate && this.maxDate) {
        this.isDateRangeValid = start >= this.minDate && end <= this.maxDate // the combined conditions also validate if user manually enter wrong dates like 1/1/1018, 1/1/2123, 1/1/20023, etc.
      }
    }
    this.dateRangeForm.updateValueAndValidity();
    return this.isDateRangeValid;
  }

  // For custom material form validator
  private dateRangeValidator(group: FormGroup): ValidationErrors | null {
    if (this.isDateRangeValid) return null;
    else return { dateRangeInvalid: { value: !this.isDateRangeValid } };
  }

  // For custom material form validator
  private customFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.dateRangeValidator(control as FormGroup);
    };
  }

  weSettings(event) {
    event.stopPropagation();
  }

  weSetWEDay(day) {
    this.datesService.weekEndingDay$.next(day);
    this.wEndingChanged.emit(day);
  }
}

import * as _ from 'lodash';
import { NotificationType, ResponseCode } from '../../../index';

export class ResponseCodes {
  public all: ResponseCode[];

  constructor(override?: ResponseCode[]) {
    this.all = [];
    this.all.push(new ResponseCode(200, 'Success OK', 'Success', NotificationType.SUCCESS));
    this.all.push(new ResponseCode(201, 'Success; Created', 'Success', NotificationType.SUCCESS));
    this.all.push(new ResponseCode(204, 'Success; No Content', 'Success', NotificationType.SUCCESS));
    this.all.push(new ResponseCode(214, 'Success OK', 'Success', NotificationType.SUCCESS));
    this.all.push(new ResponseCode(401, 'Unauthorized', 'Error', NotificationType.ERROR));
    this.all.push(new ResponseCode(405, 'Method Not Allowed', 'Error', NotificationType.ERROR));
    this.all.push(new ResponseCode(409, 'Conflict', 'Error', NotificationType.ERROR));
    this.all.push(new ResponseCode(422, 'Unprocessable Entity', 'Error', NotificationType.ERROR));
    this.all.push(new ResponseCode(500, 'Server Error', 'Error', NotificationType.ERROR));

    if (override) {
      let _this = this;
      _.each(override, function(responseCode) {
        _this.updateResponseCode(responseCode);
      });
    }
  }

  public get(code?: number): ResponseCode[] {
    if (code) {
      return _.filter(this.all, ['code', code]);
    } else {
      return this.all;
    }
  }

  public updateResponseCode(responseCode: ResponseCode): void {
    if(responseCode) {
      let rc: ResponseCode = this.get(responseCode.getCode())[0];
      if (rc) {
        rc.setTitle(responseCode.getTitle());
        rc.setMessage(responseCode.getMessage());
        rc.setType(responseCode.getType());
        rc.setData(responseCode.getData());
      }
    }
  }
}

<siq-app-header [config]="config"></siq-app-header>

<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="statusEnums.ALERT">

    <siq-js-alert-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="errorAndInvalidOptionMenuItems"
      [reportActivityName]="applicationName"
    ></siq-js-alert-overlay>

  </div>

  <div *ngSwitchCase="statusEnums.ERROR">

    <siq-js-error-overlay
      (clickEvent)="handleOptionMenuClick($event)"
      [appErrorMessage]="activity.errorsDetails[0]"
      [reportActivityName]="applicationName"
      [menuItems]="errorAndInvalidOptionMenuItems"
    ></siq-js-error-overlay>

  </div>

  <ng-container *ngSwitchDefault>

    <!-- Query mode "Analysis Mode" -->
    <siq-query-mode #queryMode [disabled]="true"></siq-query-mode>

    <siq-activity-results
      [suppressOptions]="false"
      [hideHeader]="false"
      [isReady] = "ready$ | async"
      (clickEvent)="handleOptionMenuClick($event)"
      [menuItems]="customOptionMenuItems"
    >
    </siq-activity-results>

    <div class="sheets-container">

      <div class="sheet-content">

        <div class="loading-overlay" *ngIf="!(ready$ | async)">
          <siq-progress-spinner [show]="true"></siq-progress-spinner>
        </div>

        <siq-js-take-rate-result
          *ngIf="takeRateActivity && takeRateHelperActivity"
          [kpiModel]="kpiModel"
          [timeSeriesActivity]="timeSeriesActivity"
          [takeRateActivity]="takeRateActivity"
          [takeRatePSPWActivity]="takeRatePSPWActivity"
          [helperActivity]="takeRateHelperActivity"
          [helperPSPWActivity]="takeRateHelperPSPWActivity"
          [ngClass]="{'hidden': currentSheet !== sheetEnums.TAKERATE}"
          [parent]="this"
        >
        </siq-js-take-rate-result>

        <siq-js-sov-result
          *ngIf="sovActivities?.length"
          [ngClass]="{'hidden': currentSheet !== sheetEnums.SOV}"
          [activities]="sovActivities"
          [activitiesPSPW]="sovPSPWActivities"
          [parent]="this"
        >
        </siq-js-sov-result>

      </div>

      <div class="sheets-footer" *ngIf="ready$ | async">

        <div class="promo-label"
             [ngClass]="{'active': currentSheet === sheetEnums.TAKERATE}"
             (click)="setActiveSheet(sheetEnums.TAKERATE)"
        >
          <label>Promotion Measurement</label>
        </div>
        <div class="sov-label"
              *ngIf="sovActivities?.length"
             [ngClass]="{'active': currentSheet === sheetEnums.SOV}"
             (click)="setActiveSheet(sheetEnums.SOV)"
        >
          <label>Source of Volume</label>
        </div>

      </div>

    </div>

  </ng-container>

</ng-container>


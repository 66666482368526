<mat-list-item 
  *ngIf="!isGroup"
  routerLink="{{item.path}}"
  routerLinkActive="route-active"
>
{{item.display}} <span class="app-tag" *ngIf="item.tag">{{item.tag}}</span>
</mat-list-item>

<mat-accordion *ngIf="isGroup" [multi]="true">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{item.group}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list-item 
      *ngFor="let child of item.children"
      routerLink="{{child.path}}"
      routerLinkActive="route-active"
      >
      {{child.display}} <span class="app-tag" *ngIf="child.tag">{{child.tag}}</span>
    </mat-list-item >
  </mat-expansion-panel>
</mat-accordion>

import { ActivityErrorComponent } from 'app/core/components/activity-error/activity-error.component';
import { ActivityMenuComponent } from 'app/core/components/nav-primary/activity-menu/activity-menu.component';
import { ActivityMenuItemComponent } from 'app/core/components/nav-primary/activity-menu/item/item.component';
import { ActivityMenuScheduledItemComponent } from 'app/core/components/nav-primary/activity-menu/scheduled-item/scheduled-item.component';
import { ActivityMenuSharedItemComponent } from 'app/core/components/nav-primary/activity-menu/shared-item/shared-item.component';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { ApplicationButtonComponent } from 'app/core/components/nav-secondary/application-button/application-button.component';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BytesPipe } from 'app/core/pipes/bytes.pipe';
import { CapitalizePipe } from 'app/core/pipes/capitalize.pipe';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { ConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { CoreRoutingModule } from 'app/core/routes/core-routing.module';
import { DateToStringPipe } from 'app/core/pipes/date-to-string.pipe';
import { DropdownMenuComponent } from 'app/core/components/dropdown-menu/dropdown-menu.component';
import { DropdownMenuItemComponent } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import { DynamicComponentComponent } from 'app/core/components/dynamic-component/dynamic-component.component';
import { DynamicComponentHostDirective } from 'app/core/components/dynamic-component/dynamic-component-host.directive';
import { EnumToDisplayNamePipe } from 'app/core/pipes/enum-to-display-name.pipe';
import { ErrorListComponent } from 'app/core/components/error-list/error-list.component';
import { FilterDisplayNamePipe } from 'app/core/pipes/filter-display-name.pipe';
import { FormatDimPipe } from 'app/core/pipes/format-dim.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderCellComponent } from 'app/core/components/cell-renderers/header-cell/header-cell.component';
import { HeaderCellSortableComponent } from 'app/core/components/cell-renderers/header-cell-sortable/header-cell-sortable.component';
import { IdleService } from 'app/core/services/idle/idle.service';
import { LimitedLengthTextComponent } from 'app/core/components/limited-length-text/limited-length-text.component';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { NavPrimaryComponent } from 'app/core/components/nav-primary/nav-primary.component';
import { NavProfileComponent } from 'app/core/components/nav-primary/nav-profile/nav-profile.component';
import { NavSecondaryComponent } from 'app/core/components/nav-secondary/nav-secondary.component';
import { NavService } from 'app/core/services/nav/nav.service';
import { NavSupportComponent } from 'app/core/components/nav-primary/nav-support/nav-support.component';
import { NewUserComponent } from 'app/core/components/new-user/new-user.component';
import { NewUserService } from 'app/core/services/new-user/new-user.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { PasswordRulesComponent } from 'app/siq-forms/components/password-rules/password-rules.component';
import { PluralizePipe } from 'app/core/pipes/pluralize.pipe';
import { ProgressSpinnerComponent } from 'app/core/components/progress-spinner/progress-spinner.component';
import { RelativePipe } from 'app/core/pipes/relative.pipe';
import { RouterModule } from '@angular/router';
import { SearchMatchPipe } from 'app/core/pipes/search-match.pipe';
import { SeparatePipe } from 'app/core/pipes/separate.pipe';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { throwIfAlreadyLoaded } from 'app/core/models/module-import-guard';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { WhatfixService } from 'app/core/services/whatfix/whatfix.service';
import { NavSecondaryService } from 'app/core/components/nav-secondary/nav-secondary.service';
import { ValueFormatterPipe } from 'app/core/pipes/value-formatter.pipe';
import { DeleteCellComponent } from 'app/core/components/cell-renderers/delete-cell/delete-cell.component';
import { SqlInputBoxComponent } from 'app/core/components/sql-input-box/sql-input-box.component';
import { ACE_CONFIG, AceConfigInterface, AceModule } from 'ngx-ace-wrapper';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { CheckboxRendererComponent } from 'app/core/components/cell-renderers/checkbox-renderer/checkbox-renderer.component';
import { CheckboxHeaderRendererComponent } from 'app/core/components/cell-renderers/checkbox-header-renderer/checkbox-header-renderer.component';
import { PressComponent } from 'app/core/components/press/press.component';
import { ChipListCellComponent } from './components/cell-renderers/chip-list-cell/chip-list-cell.component';
import { CloudExportModule } from 'app/core/modules/cloud-export/cloud-export.module';
import { DrawerModule } from 'app/core/modules/drawer/drawer.module';
import { WelcomeComponent } from 'app/core/components/welcome/welcome.component';
import { AppTilesComponent } from 'app/core/components/app-tiles/app-tiles.component';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { HttpClientModule } from '@angular/common/http';
import { RetailerDisplayPipe } from './pipes/retailer-display.pipe';
import { StepFormMessageComponent } from './components/stepper/step-form-message/step-form-message.component';
import { SelectAllHeaderCellComponent } from './components/cell-renderers/select-all-header-cell/select-all-header-cell.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
  tabSize: 2
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    CoreRoutingModule,
    BrowserAnimationsModule,
    SiqMaterialModule,
    ToastrModule.forRoot(
      {
        closeButton: true,
        enableHtml: true
      }
    ),
    ToastContainerModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    AceModule,
    CloudExportModule,
    DrawerModule
  ],
  declarations: [
    ActivityErrorComponent,
    ActivityMenuComponent,
    ActivityMenuItemComponent,
    ActivityMenuScheduledItemComponent,
    ActivityMenuSharedItemComponent,
    ApplicationButtonComponent,
    AppTilesComponent,
    BytesPipe,
    CapitalizePipe,
    CheckboxHeaderRendererComponent,
    CheckboxRendererComponent,
    ChipListCellComponent,
    ConfirmationModalComponent,
    ConfirmDialogComponent,
    DateToStringPipe,
    DeleteCellComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DynamicComponentComponent,
    DynamicComponentHostDirective,
    EnumToDisplayNamePipe,
    ErrorListComponent,
    FilterDisplayNamePipe,
    FormatDimPipe,
    HeaderCellComponent,
    HeaderCellSortableComponent,
    LimitedLengthTextComponent,
    NavPrimaryComponent,
    NavProfileComponent,
    NavSecondaryComponent,
    NavSupportComponent,
    NewUserComponent,
    PasswordRulesComponent,
    PluralizePipe,
    PressComponent,
    ProgressSpinnerComponent,
    QueryModeComponent,
    RelativePipe,
    RetailerDisplayPipe,
    SearchMatchPipe,
    SeparatePipe,
    SqlInputBoxComponent,
    ValueFormatterPipe,
    WelcomeComponent,
    StepFormMessageComponent,
    SelectAllHeaderCellComponent,
  ],
  exports: [
    ActivityErrorComponent,
    ActivityMenuScheduledItemComponent,
    ActivityMenuSharedItemComponent,
    AppTilesComponent,
    BytesPipe,
    CapitalizePipe,
    CheckboxHeaderRendererComponent,
    CheckboxRendererComponent,
    CloudExportModule,
    DateToStringPipe,
    DrawerModule,
    DynamicComponentComponent,
    DynamicComponentHostDirective,
    EnumToDisplayNamePipe,
    ErrorListComponent,
    FilterDisplayNamePipe,
    FormatDimPipe,
    LimitedLengthTextComponent,
    NavPrimaryComponent,
    NavSecondaryComponent,
    NgxPaginationModule,
    PasswordRulesComponent,
    PluralizePipe,
    PressComponent,
    ProgressSpinnerComponent,
    QueryModeComponent,
    RelativePipe,
    RetailerDisplayPipe,
    SearchMatchPipe,
    SeparatePipe,
    SiqMaterialModule,
    SqlInputBoxComponent,
    ValueFormatterPipe,
    WelcomeComponent,
    StepFormMessageComponent
  ],
  providers: [
    NavService,
    MixpanelService,
    UtilsService,
    CmsService,
    NewUserService,
    IdleService,
    AsyncStatusService,
    WhatfixService,
    NavSecondaryService,
    EnvConfigService,
    EmitterService,
    SiqHttpService,
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG
    }
  ]
})
export class CoreModule {

  // Taken from: https://angular.io/styleguide#04-12
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}

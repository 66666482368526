<div class="filter-modal-container-width">
  <div mat-dialog-title class="filter-modal-title">

    <span>{{ data.model?.getDisplayWithContext() || field.display}}</span>

    <mat-form-field class="filter-search" color="accent">
      <mat-icon matPrefix>search</mat-icon>
      <input
        #searchInput
        matInput
        type="text"
        placeholder="Search for {{ field.display }} {{filter.async ? '(3 character minimum)' : ''}}"
        [(ngModel)]="searchString"
        (ngModelChange)="search(searchString)"
      />
      <mat-icon
        matSuffix
        (click)="search(); this.hideAsyncSpinner = true"
        matTooltip="Clear Search Term"
        matTooltipPosition="above"
        class="clear-search-term"
      >clear
      </mat-icon>
      <br/>
      <input *ngIf="showBulkUpload" matInput [(ngModel)]="where" type="text"/>
    </mat-form-field>

  </div>

  <mat-dialog-content class="filter-modal-container-width">

    <div class="same-store-sales-container">
      <siq-js-same-store-sales-selection
        #sameStoreSalesComponent
        *ngIf="showSameStoreSales"
        [isInsideFilterModal]="true"
        [selectedIds]="selectedIds$"
      ></siq-js-same-store-sales-selection>
    </div>

    <div class="checkbox-container">
      <mat-checkbox
        ngDefaultControl
        class="bulk-checkbox"
        (click)="toggleBulkUpload()"
        [(ngModel)]="bulkUploadCheckbox"
      >
        Upload multiple codes/IDs
      </mat-checkbox>

      <mat-checkbox
        ngDefaultControl
        *ngIf="!data.config.noNulls && data.field.id !== 'retailer'"
        [(ngModel)]="nulls"
      >
        {{ include ? 'Include' : 'Exclude' }} Unknown Values
      </mat-checkbox>
    </div>

    <siq-data-table
      [gridOptions]="gridOptions"
      class="hide-horizontal-scroll"
      [ngClass]="{'include': include, 'exclude': !include, 'show-whole-table' : showSameStoreSales }"
      tableType="scrollable"
      noCard="1"
    ></siq-data-table>

    <div class="loader" [hidden]="hideAsyncSpinner">
      <siq-progress-spinner show="1" color="accent"></siq-progress-spinner>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>

    <div class="slide-toggle-wrapper" *ngIf="!data.config.noToggle">
      <label class="mat-slide-toggle-label">
        Exclude &nbsp;
        <mat-slide-toggle
          class="include-exclude-toggle"
          [(ngModel)]="include"
        >
          Include
        </mat-slide-toggle>
      </label>
    </div>

    <div class="action-middle">
      <span>{{ (selectedIds$ | async).size }} of {{ filter.async ? 'many' : filter.size }} {{ nulls ? '+ Unknown Values ' : '' }}selected</span>
      <mat-slide-toggle
        *ngIf="this.filter.async && (selectedIds$ | async).size"
        (change)="onToggleSelectedOnly($event)"
        [disabled]="!(selectedIds$ | async).size"
      >
          Only show selected values
      </mat-slide-toggle>
    </div>


    <button type="button" mat-stroked-button (click)="cancel()" class="secondary-button">
      Cancel
    </button>

    <button type="submit" mat-raised-button (click)="apply()" color="accent">
      Apply
    </button>

  </mat-dialog-actions>

</div>
<div class="bulk-component-extra-space" [hidden]="!showBulkUpload">
  <div class="bulk-component-container">
   <siq-bulk-upload-modal [parent]="this" #bulkUploadModalComponent></siq-bulk-upload-modal>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

export enum FrequencyMode {
  DEFAULT = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  QUARTER = 'Quarter'
}

@Component({
  selector: 'siq-schedule-frequency',
  templateUrl: './schedule-frequency.component.html',
  styleUrls: ['./schedule-frequency.component.scss']
})
export class ScheduleFrequencyComponent implements OnInit {
  @Input() frqPosted: string;
  @Input() formGroupRecurrence: UntypedFormGroup;
  @Input() recurrenceData: any;
  public frequencyList = Object.keys(FrequencyMode).map(key => (FrequencyMode[key]));
  public dtoModeList = ['DAILY', 'WEEKLY', 'MONTHLY', 'QUARTERLY'];
  public frequencyMode = FrequencyMode.DEFAULT;
  public interval = 1;
  public freq2dto = this.mapTwoArrays(this.frequencyList, this.dtoModeList);
  public dto2freq = this.mapTwoArrays(this.dtoModeList, this.frequencyList);

  ngOnInit() {
    this.formGroupRecurrence.controls.freq.setValue(this.recurrenceData.freq);
    this.formGroupRecurrence.controls.interval.setValue(this.recurrenceData.interval);
    this.formGroupRecurrence.controls.byDay.setValue(this.recurrenceData.byDay);
    this.formGroupRecurrence.controls.byMonthDay.setValue(this.recurrenceData.byMonthDay);
    this.frequencyMode = this.dto2freq.get(this.formGroupRecurrence.controls.freq.value) || this.frequencyMode;
    this.interval = this.formGroupRecurrence.controls.interval.value || this.interval;
    this.updateForm();
  }

  mapTwoArrays(array1, array2) {
    let map = new Map();
    if (array1.length === array2.length) {
      for (let i = 0; i < array1.length; i++) {
        map.set(array1[i], array2[i]);
      }
    }
    return map;
  }

  updateFrequencyMode() {
    this.interval = 1;
    if (this.frequencyMode === FrequencyMode.WEEK) {
      this.formGroupRecurrence.controls.byDay.setValidators([Validators.required]);
    } else {
      this.formGroupRecurrence.controls.byDay.clearValidators();
    }

    this.updateForm();
  }

  updateForm() {
    this.formGroupRecurrence.controls.freq.setValue(this.freq2dto.get(this.frequencyMode));
    this.formGroupRecurrence.controls.interval.setValue(this.interval);
  }
}

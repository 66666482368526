import { Pipe, PipeTransform } from '@angular/core';
import { DataType } from 'app/core/models/data-type.enum';
import { CmsConfig, CmsEntity, CmsField, CmsMetric } from '@siq-js/cms-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { FilterService } from 'app/filter/services/filter.service';
import { Filter } from 'app/filter/models/filter';

@Pipe({
  name: 'enumToDisplayName'
})
export class EnumToDisplayNamePipe implements PipeTransform {

  constructor() { }

  transform(value: any, type: DataType): any {
    let entity: CmsEntity<any> | Filter;

    switch (type) {
      case DataType.DIMENSION:
        entity = CmsService.get().findEntity<CmsField>(value);
        break;
      case DataType.FACT:
        entity = CmsService.get().findEntity<CmsMetric>(value);
        break;
      case DataType.FILTER:
        entity = FilterService.getFilter(value);
        break;
    }

    if (!entity) return value;

    if (entity instanceof Filter) {
      return entity.id;
    } else {
      return entity.display;
    }
  }

}

<h3 mat-dialog-title *ngIf="!userGroupChanged">Are you sure?</h3>
<h3 mat-dialog-title *ngIf="userGroupChanged">Report's criteria changed</h3>


<mat-dialog-content *ngIf="ready$ | async">
  <p *ngIf="!userGroupChanged">By Changing the Retailer selection, you'll lose the following:</p>
  <p *ngIf="userGroupChanged">The following has been removed due to Access Group change:</p>

  <div class="columns">

    <div class="column" *ngIf="summary.affinityType || summary.secondaryAffinityType">
      <h3 class="column-label">Affinity Type:</h3>
      <ul>
        <li class="remove" *ngIf="summary.affinityType">
          {{ summary.affinityType.display }} ({{ summary.affinityType.retailer }})
        </li>
        <li class="remove" *ngIf="summary.secondaryAffinityType">
          {{ summary.secondaryAffinityType.display }} ({{ summary.secondaryAffinityType.retailer }})
        </li>
      </ul>
    </div>

    <div class="column" *ngIf="summary.filters.length">
      <h3 class="column-label">Transaction Filters:</h3>
      <ul>
        <li *ngFor="let f of summary.filters" class="remove">
          {{ f.field.display }} ({{ f.field.retailer }})
        </li>
      </ul>
    </div>

    <div class="column" *ngIf="summary.filterValues.length">
      <h3 class="column-label">Transaction Filter Values:</h3>
      <ul>
        <li *ngFor="let fs of summary.filterValues">
          {{ fs.field.display }}
          <ul>
            <li *ngFor="let val of fs.values" class="remove">
              {{ val }}
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="column" *ngIf="summary.leftFilters.length">
      <h3 class="column-label">Left Filters:</h3>
      <ul>
        <li *ngFor="let f of summary.leftFilters" class="remove">
          {{ f.field.display }} ({{ f.field.retailer }})
        </li>
      </ul>
    </div>

    <div class="column" *ngIf="summary.rightFilters.length">
      <h3 class="column-label">Right Filters:</h3>
      <ul>
        <li *ngFor="let f of summary.rightFilters" class="remove">
          {{ f.field.display }} ({{ f.field.retailer }})
        </li>
      </ul>
    </div>

  </div>

</mat-dialog-content>

<mat-dialog-actions *ngIf="!userGroupChanged">
  <button mat-stroked-button color="accent" (click)="cancel()" class="secondary-button">Cancel</button>
  <button mat-raised-button color="accent" (click)="confirm()" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="userGroupChanged">
  <button mat-raised-button color="accent" (click)="cancel()">OK</button>
</mat-dialog-actions>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { GroupedArray, GroupedArrayFunctions } from '@siq-js/core-lib';
import { CmsService } from 'app/core/services/cms/cms.service';
import { ComparisonGroup } from 'app/siq-applications/modules/promo/models/comparison-group';
import { SourceOfVolume } from 'app/siq-applications/modules/promo/models/source-of-volume';
import { EntitySelectorStatus } from 'app/siq-forms/components/entity-selector/entity-selector.component';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs';
import * as _ from 'lodash';
import { BaseStepperComponent } from 'app/core/components/stepper/base-stepper.component';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

@Component({
  selector: 'siq-js-sov',
  templateUrl: './sov.component.html',
  styleUrls: ['./sov.component.scss'],
})
export class SovComponent implements OnInit {
  @Input() sov: SourceOfVolume;
  @Input() sovs$: BehaviorSubject<SourceOfVolume[]>;
  @Input() schema$: BehaviorSubject<string>;
  @Input() comparisonGroups$: BehaviorSubject<ComparisonGroup[]>;
  @Output() sovRemoved: EventEmitter<string> = new EventEmitter<string>();

  public dimensionData: GroupedArray<CmsField>; // original copy of grouped CmsField data coming from CMS
  public filteredDimData: GroupedArray<CmsField>; // filtered version of dimensionData
  public filteredCGData: GroupedArray<CmsMetric>; // filtered version of dimensionData
  public currSchema: string;
  public cgInitModel: CmsMetric[];
  public dimInitModel: CmsField[];

  constructor() { }

  async ngOnInit() {
    this.dimInitModel = this.sov.dimension ? [this.sov.dimension] : [];
    this.dimensionData = GroupedArrayFunctions.filter(CmsService.get().fieldsOrder, el => el.table === 'product');
    this.initCGs();
    this.schema$
      .subscribe(async schema => {
        const p = EnvConfigService.getConfig().primaryEntity;
        this.currSchema = schema;
        this.filteredDimData = GroupedArrayFunctions.filter(this.dimensionData, entity => BaseStepperComponent.dimensionFilterFn(schema, entity, p));
        // remove invalid dimension when schema changes
        if (this.sov.dimension && !CmsService.isValidField(this.sov.dimension, schema)) {
          this.sov.oldDimension = _.cloneDeep(this.sov.dimension);
          this.sov.dimension = null;
        }
      });
  }

  public dimensionChanged(entitiesStatus: EntitySelectorStatus<CmsField>) {
    if (entitiesStatus.valid) {
      this.sov.dimension = entitiesStatus.selectedEntities[0];
    }
    this.sov.isValid = entitiesStatus.valid && (!!this.sov.comparisonGroup);
    this.sovs$.next(this.sovs$.getValue());
  }

  public cgChanged(entitiesStatus: EntitySelectorStatus<CmsField>) {
    if (entitiesStatus.valid) {
      const entity = entitiesStatus.selectedEntities[0];
      const cg = this.comparisonGroups$.getValue().find(cg => cg.id === entity.id);
      this.sov.oldComparisonGroup = _.cloneDeep(this.sov.comparisonGroup);
      this.sov.comparisonGroup = _.cloneDeep(cg);
    }
    this.sov.isValid = entitiesStatus.valid && (!!this.sov.dimension);
    this.sovs$.next(this.sovs$.getValue());
  }

  public onRemoveSOV() {
    this.sovRemoved.emit(this.sov.id);
  }

  private initCGs() {
    if (this.sov.comparisonGroup) {
      this.cgInitModel = [new CmsMetric({
        id: this.sov.comparisonGroup.id,
        display: this.sov.comparisonGroup.name,
        active: true,
        aggType: null,
        type: null
      })];
    }
    this.comparisonGroups$
      .pipe(map(cgs => cgs.filter(cg => cg.isValid))) // filter out invalid cg
      .subscribe(cgs => {
        this.filteredCGData = []; // reset
        cgs.forEach(cg => {
          // update the list of cg options
          this.filteredCGData.push(new CmsMetric({ // fake CmsMetric here to feed entity selector
            id: cg.id,
            display: cg.name,
            active: true,
            aggType: null,
            type: null
          }));
          // when a cg is valid but the name is changed, update the input in entity selector
          if (this.sov.isValid && cg.id === this.sov.comparisonGroup?.id && cg.name !== this.sov.comparisonGroup?.name) {
            this.cgInitModel = [new CmsMetric({
              id: cg.id,
              display: cg.name,
              active: true,
              aggType: null,
              type: null
            })];
          }
        });
      });
  }

}

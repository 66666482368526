import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DateRangeInterface, DateSelectionConfig } from 'app/siq-forms/modules/dates/models/interfaces';
import { DateSelectionComponent } from 'app/siq-forms/modules/dates/components/date-selection/date-selection.component';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { CoreConstants, DateUnit } from '@siq-js/core-lib';

export interface YearOverYearModalConfig {
  globalDateRange: DateRangeInterface;
  locked: boolean;
  model: any;
  activeSchemas$: Subject<string[]>;
}

@Component({
  selector: 'siq-year-over-year-modal',
  templateUrl: './year-over-year-modal.component.html',
  styleUrls: ['./year-over-year-modal.component.scss']
})
export class YearOverYearModalComponent implements OnInit {

  public static readonly MODAL_CONFIG: MatDialogConfig = {
    width: '400px',
    height: '400px',
    role: 'dialog'
  };

  public model: {
    all: boolean,
    ty: boolean,
    ly: boolean,
    lyd: boolean,
    lypd: boolean,
    locked: boolean,
    tooltip: string,
    dateRangeStr: string,
    compDateRange: DateRangeInterface
  };
  public options: any;

  public dateSelectionConfig: DateSelectionConfig = {
    primaryDate: {
      rangeMode: false,
      allowShortcuts: false,
      shortcutPosition: 'left',
      weekEndingDay: false
    }
  };

  public isDateRangeValid: boolean = true;

  private datePickerEmitCount = 0;

  @ViewChild(DateSelectionComponent) dateSelectionComponent: DateSelectionComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: YearOverYearModalConfig,
    private dialogRef: MatDialogRef<YearOverYearModalComponent>
  ) {
  }

  ngOnInit() {
    const m = this.data.model || {};

    this.model = {
      all: m.ty && m.ly && m.lyd && m.lypd,
      ty: m.ty || false,
      ly: m.ly || false,
      lyd: m.lyd || false,
      lypd: m.lypd || false,
      locked: this.data.locked,
      tooltip: (this.data.locked) ?
        'The comparative date range is a year before the global date range and cannot be changed, due to certain dimensions present in this report' :
        'The start date of the comparative date period can be selected here. The end date will automatically be filled in (based on the global date range)',
      compDateRange: {
        begin: null, end: null
      },
      dateRangeStr: ''
    };

    let dateModel: DateRangeInterface = {};
    if (m.compDateRange) {
      dateModel.begin = m.compDateRange.begin;
      dateModel.end = m.compDateRange.end;
    } else {
      let begin = new Date(this.data.globalDateRange.begin.getTime());
      begin.setFullYear(this.data.globalDateRange.begin.getFullYear() - 1);
      dateModel.begin = begin;
      let end = new Date(this.data.globalDateRange.end.getTime());
      end.setFullYear(this.data.globalDateRange.end.getFullYear() - 1);
      dateModel.end = end;
    }
    this.updateDate(dateModel, true);
  }

  updateDate(dateModel: DateRangeInterface, init = false): void {
    this.datePickerEmitCount += init ? 0 : 1;
    this.isDateRangeValid = init || dateModel.isDateRangeValid;
    if (this.datePickerEmitCount <= 1) { // this is to prevent the first datepicker emit affecting the YOY end date
      this.model.compDateRange.end = dateModel.end;
    } else {
      // calc the YOY end date using the delta of YOY start date
      const diff = DatesService.getDifference(DateUnit.DAY)(dateModel.begin, this.model.compDateRange.begin);
      this.model.compDateRange.end = DatesService.add(this.model.compDateRange.end, { days: diff });
    }
    this.model.compDateRange.begin = dateModel.begin;
    
    // Store the diff as '###360'. This diff will be used to re-calc YOY when report DD shifts. See ReportBuilderFormData -> updateYOY
    // Note this is used FE only in here and in serializing to formValues.
    // This is not picked up when sending payload to BE. See ReportBuilderParameters -> columnToAppRequest line 100.
    this.model.compDateRange.dynamicBegin = DatesService.DD_HASH + DatesService.getDifference(DateUnit.DAY)(this.data.globalDateRange.begin, this.model.compDateRange.begin).toString();
    this.model.compDateRange.dynamicEnd = DatesService.DD_HASH + DatesService.getDifference(DateUnit.DAY)(this.data.globalDateRange.end, this.model.compDateRange.end).toString();

    this.model.dateRangeStr = DatesService.format(this.model.compDateRange.begin, CoreConstants._shortDate) + ' to ' + DatesService.format(this.model.compDateRange.end, CoreConstants._shortDate);
  }

  // toggles all YOY params off/on
  toggleAll() {
    const m = this.model;
    m.ty = m.all;
    m.ly = m.all;
    m.lyd = m.all;
    m.lypd = m.all;
  }

  // checks if all params are either on or off
  updateAll() {
    const m = this.model;

    if (m.ty && m.ly && m.lyd && m.lypd) {
      m.all = true;
    }

    if (!m.ty && !m.ly && !m.lyd && !m.lypd) {
      m.all = false;
    }
  }

  apply() {
    this.dialogRef.close(this.model);
  }

  resetDateRange() {
    const date: DateRangeInterface = {};
    date.begin = DatesService.add(this.data.globalDateRange.begin, { years: -1 });
    this.dateSelectionComponent.setPrimaryDate(date);
  }
}

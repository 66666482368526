import { Routes } from '@angular/router';
import { NotFoundComponent } from 'app/not-found/components/not-found/not-found.component';
import { ExpiredAppNotFoundComponent } from 'app/not-found/components/expired-app-not-found/expired-app-not-found.component';

export const notFoundRoutes: Routes = [
  {
    path: 'expired-app-not-found',
    component: ExpiredAppNotFoundComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

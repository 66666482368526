import { Component, OnDestroy } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { UsesCheckboxRenderer } from 'app/core/components/cell-renderers/checkbox-renderer/uses-checkbox-renderer';
import { IHeaderAngularComp, IHeaderParams } from '@siq-js/visual-lib';
import { takeUntil } from 'rxjs';

export enum CheckedState {
  ALL,
  SOME,
  NONE
}
@Component({
  selector: 'siq-js-select-all-header-cell',
  templateUrl: './select-all-header-cell.component.html',
  styleUrls: ['./select-all-header-cell.component.scss']
})
export class SelectAllHeaderCellComponent extends BaseSiqComponent implements IHeaderAngularComp, OnDestroy {

  public state: CheckedState = CheckedState.NONE; // state used to render different text
  public stateEnums = CheckedState;
  public currIds: string[] = [];

  protected parent: UsesCheckboxRenderer<any>;

  constructor() {
    super();
  }

  agInit(params) {
    this.parent = params.column.colDef.refData.parent;
    this.parent.selectedIds$
      .pipe(takeUntil(this.unsub$))
      .subscribe((ids: Set<string>) => {
        // Set state based on how many items are selected
        setTimeout(() => { // wrap in setTimeout so gridOptions.api.forEachNode is ready in the next cycle
          if (!ids.size) {
            return this.state = CheckedState.NONE;
          }
          this.currIds = [];
          this.parent.gridApi.forEachNode(n => {
            this.currIds.push(n.data.n || n.data.id); // ! n.data can be either type Activity or FilterValue
          });
          let currIdsAllSelected = true;
          for (let i = 0; i < this.currIds.length; i++) {
            if (!ids.has(this.currIds[i])) {
              currIdsAllSelected = false;
              break;
            }
          }
          this.state = currIdsAllSelected ? CheckedState.ALL : CheckedState.NONE;
        });
      });
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  // Update selections based on current state
  public update() {
    const ids = this.parent.selectedIds$.getValue();
    if (this.state !== CheckedState.ALL) {
      this.parent.gridApi.forEachNode(node => {
        ids.add(this.parent.getId(node.data));
      });
      this.parent.selectedIds$.next(ids);
    } else {
      for (let i = 0; i < this.currIds.length; i++) {
        ids.delete(this.currIds[i]);
      }
      this.parent.selectedIds$.next(ids);
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from 'app/filter/filter.module';
import { GridModule } from 'app/grid/grid.module';
import { AgGridModule } from '@siq-js/visual-lib';
import { AccessGroupListComponent } from 'app/access-group/components/access-group-list/access-group-list.component';
import { AccessGroupRoutingModule } from 'app/access-group/routes/access-group-routing.module';
import { AccessGroupFormComponent } from './components/access-group-form/access-group-form.component';
import { AccessGroupFiltersComponent } from './components/access-group-form/access-group-filters/access-group-filters.component';
import { StatusRendererComponent } from './components/access-group-list/renderers/status-renderer/status-renderer.component';
import { AccessGroupCriteriaRendererComponent } from './components/access-group-list/renderers/access-group-criteria-renderer/access-group-criteria-renderer.component';
import { AccessGroupService } from 'app/access-group/services/access-group.service';

@NgModule({
    imports: [
        CommonModule,
        AccessGroupRoutingModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        GridModule,
        FilterModule,
        AgGridModule
    ],
  exports: [],
  declarations: [
    AccessGroupListComponent,
    AccessGroupFormComponent,
    AccessGroupFiltersComponent,
    StatusRendererComponent,
    AccessGroupCriteriaRendererComponent
  ],
  providers: [
    AccessGroupService
  ]
})
export class AccessGroupModule { }

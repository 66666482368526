import { ApplicationHash } from '@siq-js/core-lib';
import { AuthGuard } from 'app/guards/auth-guard';
import { ReportBuilderFormComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-form/report-builder-form.component';
import { ReportBuilderListComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-list/report-builder-list.component';
import { ReportBuilderResultComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-result/report-builder-result.component';
import { Routes } from '@angular/router';
import { AppGuard } from 'app/guards/app-guard';

export const reportBuilderRoutes: Routes = [
  {
    path: 'app/report-builder',
    canActivate: [
      AppGuard,
      AuthGuard
    ],
    data: {
      applicationHash: ApplicationHash.REPORT_BUILDER
    },
    children: [
      {
        path: ':id/export',
        component: ReportBuilderResultComponent
      },
      {
        path: '',
        children: [
          {
            path: '',
            component: ReportBuilderListComponent
          }
        ]
      },
      {
        path: '~',
        children: [
          {
            path: '',
            component: ReportBuilderFormComponent
          }
        ]
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: ReportBuilderResultComponent
          }
        ]
      }
    ]
  }
];

import { ReportBuilderSheet } from 'app/siq-applications/modules/report-builder/models/results/report-builder-sheet.model';
import {
    AgGridAngular,
  CellMouseOverEvent,
  ColDef,
  ColGroupDef,
  ExcelService,
  GridOptions,
  GridService,
  GridSettings,
  RowClickedEvent,
  TableStateManager,
  VisualOptions,
  Column
} from '@siq-js/visual-lib';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import {
  RBDrilldownInput,
  ReportBuilderDrilldownModalComponent
} from 'app/siq-applications/modules/report-builder/components/report-builder-drilldown-modal/report-builder-drilldown-modal.component';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { filter, takeUntil } from 'rxjs';
import { CmsField } from '@siq-js/cms-lib';
import * as _ from 'lodash';
import { GridTotalsService } from 'app/grid/services/grid-totals/grid-totals.service';

export class ReportBuilderGridProcessor {
  /**
   * This replaces ReportBuilderResultComponent.generateTableOptions(sheet: ReportBuilderSheet): VisualOptions
   * @param sheet
   */
  public static generateGridVisualOptions(sheet: ReportBuilderSheet): VisualOptions {
    const data = sheet.data.generateData();
    let vo: VisualOptions = GridService.generateDefaultGridVisualOptions();

    return _.merge(vo, {
      data: data,
      parentActivity: sheet.sr.parentActivity,
      dimensions: sheet.sr.getDimensions(true),
      metrics: sheet.sr.facts,
      globalDateRange: sheet.getForm().globalDateRange,
      gridConfig: {
        allowHeatmap: true,
        afterRender: (grid: AgGridAngular<any, ColDef<any, any>>) => {
          // Update model
          sheet.parent.updateGridSize(grid);
          grid.api.addGlobalListener(event => {
            if (sheet.parent.config.updateGridSizeEvents.includes(event)) {
              sheet.parent.updateGridSize(grid);
            }
          });
          grid.api.setSideBarVisible(true);
        },
        paginationConfig: { customPagination: true, pagination: true },
        persistState: !sheet.parent.readonly,
        defaultSortOptions: sheet.parent.config.defaultSort,
        tableHeight: '100%',
        customColDefMeta: sheet.data.colDefMeta,
        customGridOptions: {
          excelStyles: [
            ...ExcelService.generateExcelStyles()
          ],
          rowGroupPanelShow: 'always',
          suppressAggFuncInHeader: true,
          suppressExpandablePivotGroups: true,
          onRowClicked(event: RowClickedEvent): void {
            if (sheet.parent.readonly) return;
            if (!ReportBuilderService.canDrill(sheet.gridVisualOptions.apiRef().component, event)) return;

            sheet.parent.utils.openModal(
              ReportBuilderDrilldownModalComponent,
              <RBDrilldownInput>{dataNode: event.data, sheet: sheet, schema: sheet.parent.queryMode.schema$.getValue()},
              UtilsService.MODAL_CONFIG_MEDIUM
            )
            .afterClosed()
            .pipe(filter(r => !!r))
            .subscribe((dim: CmsField) => sheet.parent.drilldown(dim, event.data));
          },
          onCellMouseOver(event: CellMouseOverEvent): void {
            sheet.parent.canDrill = ReportBuilderService.canDrill(sheet.gridVisualOptions.apiRef().component, event);
          }
        },
        maxColumnsToFit: 1,
        configureColumns: () => !!data.length ? sheet.data.toColDef() : [],
        unlinkState: true,
        gridReadyForExport$: sheet.readyForExport$,
        onGridReady: grid => {
          let component = sheet.gridVisualOptions.apiRef().component;
          component.tableStateManager = new TableStateManager(component);
          component.getApi().tableStateManager = component.tableStateManager;

          component.getApi().tableStateManager.status.pipe(takeUntil(component.unsub$)).subscribe(value => {
            if (value.justLoaded) {
              // grid sort and filtering will be refreshed and tablestate values will be used
              value.tableState?.pivotSortModel?.forEach(pivotSortModel => {

                component.grid.api.getAllGridColumns().forEach((gridCol:Column) => {
                  if (pivotSortModel.colId === gridCol.getColId()) {
                    gridCol.setSort(pivotSortModel.sort);
                  }
                });
              });
              component.grid.api.refreshHeader();
              if (component.onPaginationChanged) {
                component.onPaginationChanged(); //ICE-2616: trigger ngOnChanges of CustomPagingComponent
              }
            }
          });

        }
      }
    });
  }

  public static processor(sheet: ReportBuilderSheet): GridSettings {
    // Generate the GridOptions (overrides) which will get merged to the default GridOptions created by/in GridComponent
    let gridOptions: GridOptions = sheet.gridVisualOptions.gridConfig.customGridOptions;

    // build the ColDefs
    gridOptions.columnDefs = sheet.gridVisualOptions.gridConfig.configureColumns();

    const allMetricColIds = {};
    const _setAllMetricColIdKeys = (cd: ColDef | ColGroupDef) => {
      const children = _.get(cd, 'children');
      if (children) {
        children.forEach(child => _setAllMetricColIdKeys(child));
      } else {
        if ((<ColDef>cd).aggFunc) {
          allMetricColIds[(<ColDef>cd).colId] = undefined;
        }
      }
    };
    gridOptions.columnDefs.forEach(cd => {
      _setAllMetricColIdKeys(cd);
    });

    /**
     * The configureColumns() fn for ReportBuilder may result in some "dynamic" columns being created (YOY, TimeSeries).
     * Determine if there are more cols than dims+metrics. If so, we need to account for these in sheet.gridVisualOptions.data.
     */
    let colDefCounter = 0;
    const _countColDefs = (cd: ColDef | ColGroupDef) => {
      const children = _.get(cd, 'children');
      if (_.get(cd, 'children')) {
        children.forEach(child => _countColDefs(child));
      } else {
        colDefCounter++;
      }
    };
    gridOptions.columnDefs.forEach(cd => _countColDefs(cd));
    if (colDefCounter > (sheet.gridVisualOptions.dimensions.length + sheet.gridVisualOptions.metrics.length)) {
      sheet.gridVisualOptions.data.forEach(dataObj => {
        _.defaults(dataObj, allMetricColIds); // Add any missing metric colIds
      });
    }

    const gridSettings: GridSettings = <GridSettings>{
      data: sheet.gridVisualOptions.data, // set pointer for ease of use/readability later
      gridOptions: gridOptions,
      gridVisualOptions: sheet.gridVisualOptions,
      parentActivity: sheet.gridVisualOptions.parentActivity // set pointer for ease of use/readability later
    };
    GridTotalsService.provideAsyncTotalsFunctionality(gridSettings);
    return gridSettings;
  }
}

import { NgModule } from '@angular/core';
import { FiltersetsListComponent } from './components/filtersets-list/filtersets-list.component';
import { FiltersetsGridComponent } from './components/filtersets-grid/filtersets-grid.component';
import { LoadFiltersetModalComponent } from './components/load-filterset-modal/load-filterset-modal.component';
import { FiltersetsRoutingModule } from 'app/filtersets/routes/filtersets-routing.module';
import { AgGridModule } from '@siq-js/visual-lib';
import { MatCardModule } from '@angular/material/card';
import { NameRendererComponent } from './components/renderers/name-renderer/name-renderer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FiltersetFiltersRendererComponent } from 'apps/dashboard/src/app/filtersets/components/renderers/filterset-filters-renderer/filterset-filters-renderer.component';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { CommonModule } from '@angular/common';
import { CreatedRendererComponent } from './components/renderers/created-renderer/created-renderer.component';
import { CoreModule } from 'app/core/core.module';
import { DeleteRendererComponent } from './components/renderers/delete-renderer/delete-renderer.component';
import { SaveFiltersetModalComponent } from './components/save-filterset-modal/save-filterset-modal.component';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { FiltersetsLegendComponent } from './components/filtersets-legend/filtersets-legend.component';

@NgModule({
  declarations: [
    FiltersetsListComponent,
    FiltersetsGridComponent,
    LoadFiltersetModalComponent,
    NameRendererComponent,
    FiltersetFiltersRendererComponent,
    CreatedRendererComponent,
    DeleteRendererComponent,
    SaveFiltersetModalComponent,
    DeleteModalComponent,
    FiltersetsLegendComponent
  ],
  imports: [
    FiltersetsRoutingModule,
    AgGridModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    SiqMaterialModule,
    CommonModule,
    CoreModule,
    SharedModule
  ]
})
export class FiltersetsModule { }

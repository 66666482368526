import * as _ from 'lodash'
import { AuthService } from 'app/auth/services/auth.service'
import {
  catchError,
  first,
  map,
  mergeMap,
  share
  } from 'rxjs'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service'
import { User } from 'app/user/models/user.model'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshObs: Observable<User>;

  constructor(private auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = AuthService.CurrentUser$.getValue();

    if (!user) {
      return next.handle(request);
    }

    if (!request.headers.get(SiqHttpService.AUTH_KEY)) {

      return next.handle(this.attachToken(request, user.token))
        .pipe(
          catchError((err) => {

            if (err instanceof HttpErrorResponse) {

              switch (err.status) {
                case 401:
                  return this.getUser()
                    .pipe(
                      first(),
                      mergeMap((user: User) => {
                        return next.handle(this.attachToken(request, user.token));
                      })
                    );
                default:
                  return new Observable<any>(obs => {
                    obs.error(err);
                    obs.complete();
                  });
              }

            } else {

              return next.handle(request);

            }
          })
        );

    } else if (request.headers.get(SiqHttpService.AUTH_KEY) === SiqHttpService.AUTH_SKIP_VALUE) {

      let headers = {};

      _.map(_.filter(request.headers.keys(), k => k !== SiqHttpService.AUTH_KEY) as any, (h: string) => {
        headers[h] = request.headers.getAll(h);
      });

      let req = request.clone({
        headers: new HttpHeaders(headers)
      });

      return next.handle(req);

    }

  };

  private attachToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    let newHeaders = {};
    newHeaders[SiqHttpService.AUTH_KEY] = 'Bearer ' + token;
    return request.clone({ setHeaders: newHeaders });
  }

  private getUser (): Observable<User> {

    if (!(this.refreshObs instanceof Observable)) {

      this.refreshObs = this.auth.refreshToken().pipe(
        share(),
        map(user => {
          this.refreshObs = null;
          return user;
        })
      );

    }

    return this.refreshObs;

  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

const _cache: {
  [searchStr in string]: {
    [text in string]: string
  }
} = {};

@Pipe({
  name: 'searchMatch'
})
export class SearchMatchPipe implements PipeTransform {

  transform(text: string, searchStr: string): string {
    if (_.isEmpty(text)) return text;

    // memo-ize results
    if (!_cache[searchStr]) {
      _cache[searchStr] = {};
    }

    if (!_cache[searchStr][text]) {
      try {
        const regex = new RegExp(searchStr, 'ig');
        _cache[searchStr][text] = text.replace(regex, match => `<span class="highlight">${match}</span>`);
      } catch (e) {
        console.warn('Something wrong with the regular expression...');
        return text;
      }
    }

    return _cache[searchStr][text];

  }
}

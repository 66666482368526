import { CmsKeys } from './cms-keys';
import { CoreConstants, DatesServiceCore, GroupedArray, GroupedArrayFunctions, Serializable } from '@siq-js/core-lib';
import { CmsApplication, CmsApplicationJson } from './cms-application';
import { CmsEntity } from './cms-entity';
import { CmsField, CmsFieldJson } from './cms-field';
import { CmsMetric, CmsMetricJson } from './cms-metric';
import * as _ from 'lodash';

export interface CmsConfigJson {
  // array of ALL entity configurations
  applications: CmsApplicationJson[];
  fields: CmsFieldJson[];
  metrics: CmsMetricJson[];

  // array of IDs or grouped IDs
  applicationsOrder: GroupedArray<string>;
  fieldsOrder: GroupedArray<string>;
  metricsOrder: GroupedArray<string>;

  // map with retailer as the key & date-range object as value
  availableData: {
    [k in string]: {
      min: string,
      max: string
    }
  };
}

export class CmsConfig implements Serializable<CmsConfigJson> {

  constructor(json: CmsConfigJson) {
    this.fields = json.fields.map(field => new CmsField(field)).filter(field => field.active);
    this.metrics = json.metrics.map(fact => new CmsMetric(fact)).filter(metric => metric.active);
    this.applications = json.applications.map(app => new CmsApplication(app)).filter(app => app.active);

    // build lookup cache so re-populating the orders will be much faster
    this.buildCache();

    // map the orders from entity IDs to the IDs in the cache
    const applicationsOrder = GroupedArrayFunctions.map(json.applicationsOrder || [], (id: string) => this.findEntity<CmsApplication>(id));
    const fieldsOrder = GroupedArrayFunctions.map(json.fieldsOrder || [], (id: string) => this.findEntity<CmsField>(id));
    const metricsOrder = GroupedArrayFunctions.map(json.metricsOrder || [], (id: string) => this.findEntity<CmsMetric>(id));

    this.applicationsOrder = GroupedArrayFunctions.filter(applicationsOrder, e => e && e.active);
    this.fieldsOrder = GroupedArrayFunctions.filter(fieldsOrder, e => e && e.active);
    this.metricsOrder = GroupedArrayFunctions.filter(metricsOrder, e => e && e.active);

    this.availableData = {};

    const availableData = json.availableData ?? {};

    for (const k in availableData) {
      const dateRange = json.availableData[k];
      this.availableData[k] = {
        min: DatesServiceCore.parse(dateRange.min, CoreConstants._dateFormat, new Date()),
        max: DatesServiceCore.parse(dateRange.max, CoreConstants._dateFormat, new Date())
      };
    }
  }

  public static readonly CORE_SCHEMA_ID = '636f726552657461696c6572';

  public fields: CmsField[];
  public fieldsOrder: GroupedArray<CmsField>;
  public metrics: CmsMetric[];
  public metricsOrder: GroupedArray<CmsMetric>;
  public applications: CmsApplication[];
  public applicationsOrder: GroupedArray<CmsApplication>;
  public availableData: {
    [k in string]: {
      min: Date,
      max: Date
    }
  };
  private lookupCache: Map<string, CmsEntity<any>> = new Map<string, CmsEntity<any>>();

  toJson(): CmsConfigJson {
    const json: CmsConfigJson = {
      metrics: this.metrics.map(fact => fact.toJson()),
      fields: this.fields.map(field => field.toJson()),
      applications: this.applications.map(app => app.toJson()),
      applicationsOrder: GroupedArrayFunctions.map(this.applicationsOrder, e => e.id),
      fieldsOrder: GroupedArrayFunctions.map(this.fieldsOrder, e => e.id),
      metricsOrder: GroupedArrayFunctions.map(this.metricsOrder, e => e.id),
      availableData: {}
    };

    for (const k in this.availableData) {
      const dateRange = this.availableData[k];
      json.availableData[k] = {
        min: DatesServiceCore.format(dateRange.min, CoreConstants._dateFormat),
        max: DatesServiceCore.format(dateRange.max, CoreConstants._dateFormat),
      };
    }

    return json;
  }

  findEntity<T extends CmsEntity<any>>(id: string): T {
    if (!this.lookupCache || !id) return null;

    let entity = this.lookupCache.get(id) as T;

    if (!entity) {
      // TODO: temp fix for ID case mismatch (for Metrics). Need to resolve later
      entity = this.lookupCache.get(id.toUpperCase()) as T;
    }

    /**
     * In earlier builds, the aggregated fields for core retailer had id of r|ta|id.
     * Later builds dropped the r|t| and left just the id. However older things (like AccessGroups)
     * created before this change may still have the r|t| and thus may not be matched above.
     */
    if (!entity && id.indexOf(CmsConfig.CORE_SCHEMA_ID) > -1) {
      const shortId = id.substring(id.lastIndexOf('|') + 1);
      entity = this.lookupCache.get(shortId) as T || this.lookupCache.get(shortId.toLowerCase()) as T || this.lookupCache.get(shortId.toUpperCase()) as T;
    }

    return entity;
  }

  getLookupCache(): Map<string, CmsEntity<any>> {
    return this.lookupCache;
  }

  getAvailableData(schema: string): { min: Date, max: Date } {
    const range = this.availableData[schema];

    if (!range) {
      console.error(`No data available for ${schema}!`);
    }

    return range;
  }

  private buildCache() {
    [
      ...this.applications,
      ...this.fields,
      ...this.metrics,
    ].forEach(entity => this.lookupCache.set(entity.id, entity));
  }
}
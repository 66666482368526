<div>
  <button
    type="button"
    mat-button
    (click)="expandAll(!allFormPanelsExpanded)"
  >
    <mat-icon class="rotated">
      {{ allFormPanelsExpanded ? 'vertical_align_center' : 'swap_vert '}}
    </mat-icon>
    {{ allFormPanelsExpanded ? 'Collapse' : 'Expand '}} All
  </button>
</div>

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { BaseFilterGroupComponent } from 'app/filter/components/base-filter-group/base-filter-group.component';
import { ComparisonGroup } from 'app/siq-applications/modules/promo/models/comparison-group';
import { debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-js-comparison-group',
  templateUrl: './comparison-group.component.html',
  styleUrls: ['./comparison-group.component.scss']
})
export class ComparisonGroupComponent extends BaseFilterGroupComponent implements AfterViewInit {
  @Input() cg: ComparisonGroup;
  @Output() cgRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() cgNameChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('form') ngForm: NgForm;

  constructor(
    protected asyncStatusService: AsyncStatusService,
    protected utils: UtilsService
  ) {
    super(asyncStatusService, utils);
  }

  ngAfterViewInit() {
    this.ngForm.form.valueChanges
      .pipe(
        takeUntil(this.unsub$),
        debounceTime(400)
      )
      .subscribe(val => {
        this.cg.isValid = (!!val.name) && this.cg.filters.length > 0;
        this.cgNameChanged.emit(this.cg.id);
      });
  }

  public onRemoveCG() {
    this.cgRemoved.emit(this.cg.id);
  }

}

import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { IncrementalsService } from 'app/siq-applications/modules/incrementals/services/incrementals.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { delay, finalize, mergeMap, skip, switchMap, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { IncrementalsConfig, INC_ENUMS } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { ActivityService } from 'app/activity/services/activity.service';
import { IncrementalsScatterWithLineProcessor } from 'app/siq-applications/modules/incrementals/components/incrementals-result/processors/incrementals-scatter-with-line-processor';
import {
  AgChartsAngular,
  AggregationType,
  BarColumnConfig,
  BarColumnParams,
  ColGroupDef,
  DatasetDetail,
  GridComponent,
  GridService,
  IStatResponse,
  ScatterWithLineParams,
  VisualOptions
} from '@siq-js/visual-lib';
import {
  IncrementalsGridParams,
  IncrementalsGridProcessor
} from 'app/siq-applications/modules/incrementals/components/incrementals-result/processors/incrementals-grid-processor';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { IncrementalsBarColumnProcessor } from 'app/siq-applications/modules/incrementals/components/incrementals-result/processors/incrementals-bar-column-processor';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { IncrementalsFormData } from 'app/siq-applications/modules/incrementals/models/form/incrementals-form-data.model';
import { IncrementalsActivity } from 'app/siq-applications/modules/incrementals/models/incrementals-activity.model';
import { ActivityGridOptionsMenu } from 'app/siq-applications/modules/shared/components/activity-grid-options-menu/activity-grid-options-menu.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { EmitterService } from 'app/core/services/emitter/emitter.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { DefaultMetrics } from 'app/siq-applications/modules/incrementals/models/interfaces';
import { SiqError } from 'app/core/models/siq-error.model';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import { CloudExportable } from 'app/core/modules/cloud-export/models/cloud-export.interface';
import { StatResponseErrors } from 'app/core/models/stat-response-errors.enum';
import { MatSelectChange } from '@angular/material/select';
import { ApplicationHash } from '@siq-js/core-lib';

@Component({
  selector: 'siq-js-incrementals-result',
  templateUrl: './incrementals-result.component.html',
  styleUrls: ['./incrementals-result.component.scss']
})
export class IncrementalsResultComponent extends BaseSiqComponent implements OnInit, AfterViewInit, CloudExportable {

  public static hideMetricsTemp: boolean; // this is temporary fix which hides metrics ICE-1932

  public absoluteData: any[];
  public absoluteDataAfterFilter: any[] = [];
  public absoluteSR: IStatResponse;
  public activeDataset$: BehaviorSubject<DatasetDetail>;
  public activeDatasetData$: BehaviorSubject<any[]>;
  public activeDatasetStatResponse$: BehaviorSubject<IStatResponse>;
  public applicationName: string = null;
  public customError: SiqError;
  public datasetChanged$: Subject<void>;
  public displayFacts: CmsMetric[];
  public dropdownValues = [IncrementalsConfig.ABSOLUTE, IncrementalsConfig.PER_STORE_WEEK];
  public formData: IncrementalsFormData;
  public grid: GridComponent;
  public gridParams: IncrementalsGridParams;
  public gridProcessor = IncrementalsGridProcessor.processor;
  public isCloudExportable: boolean;
  public statusEnums = ActivityStatus;
  public status: ActivityStatus;
  public parentActivity: IncrementalsActivity;
  public perStoreWeekData: any[];
  public perStoreWeekDataAfterFilter: any[] = [];
  public perStoreWeekSR: IStatResponse;
  public readyForExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // if grid is ready to be exported
  public selected = IncrementalsConfig.ABSOLUTE;
  public sheetActivity: Activity;

  // Chart-related variables
  public activeDatasetDimensions$: BehaviorSubject<CmsField[]>;
  public activeDatasetMetrics$: BehaviorSubject<CmsMetric[]>;
  public barColumnConfigX: BarColumnConfig;
  public barColumnConfigY: BarColumnConfig;
  public columnParamsY: BarColumnParams;
  public columnParamsX: BarColumnParams;
  public highlightDatum$: BehaviorSubject<any>;
  public processorColumnChartX = IncrementalsBarColumnProcessor.processor;
  public processorColumnChartY = IncrementalsBarColumnProcessor.processor;
  public processorScatterWithLine = IncrementalsScatterWithLineProcessor.processor;
  public scatterParams: ScatterWithLineParams;
  public xAxisFact: CmsMetric;
  public xAxisFactArr$: BehaviorSubject<CmsMetric[]>;
  public xAxisFact$: BehaviorSubject<CmsMetric>;
  public yAxisFact: CmsMetric;
  public yAxisFactArr$: BehaviorSubject<CmsMetric[]>;
  public yAxisFact$: BehaviorSubject<CmsMetric>;
  public showSpinner: boolean;

  @ViewChildren('assortmentCharts') public angularCharts: QueryList<AgChartsAngular>;
  @ViewChild('queryMode') queryMode: QueryModeComponent;

  // If you modify customOptionMenuItems, make sure to modify handleOptionMenuClick() function accordingly
  public customOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
    ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
    ActivityGridOptionsMenu.DOWNLOAD_CSV,
    ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
    ActivityGridOptionsMenu.SHARE_SCHEDULE,
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  // only items present in customOptionMenuItems, handleOptionMenuClick() can be used
  public errorAndInvalidOptionMenuItems: string[] = [
    ActivityGridOptionsMenu.CLONE_CRITERIA
  ];

  private allChartsReady$: Subject<void> = new Subject<void>();
  private readonly PER_STORE_PER_WEEK = '(Per Store Per Week)';

  constructor(
    public config: IncrementalsConfig,
    public route: ActivatedRoute,
    public router: Router,
    public cloudExportService: CloudExportService,
    private asyncStatusService: AsyncStatusService,
    private activityService: ActivityService,
    private incrementalsService: IncrementalsService,
    private mixpanelService: MixpanelService,
    private sharingService: SharingService
  ) {
    super();
    IncrementalsResultComponent.hideMetricsTemp = true;
    if (IncrementalsResultComponent.hideMetricsTemp) {
      this.dropdownValues = [IncrementalsConfig.ABSOLUTE];
    }

    // Initialize the BehaviorSubjects that drive the charts
    this.activeDataset$ = new BehaviorSubject<DatasetDetail>(null);
    this.activeDatasetData$ = new BehaviorSubject<any>(null);
    this.activeDatasetStatResponse$ = new BehaviorSubject<IStatResponse>(null);
    this.activeDatasetMetrics$ = new BehaviorSubject<CmsMetric[]>([]);
    this.activeDatasetDimensions$ = new BehaviorSubject<CmsField[]>([]);
    this.xAxisFact$ = new BehaviorSubject<CmsMetric>(null);
    this.yAxisFact$ = new BehaviorSubject<CmsMetric>(null);
    this.xAxisFactArr$ = new BehaviorSubject<CmsMetric[]>([]);
    this.yAxisFactArr$ = new BehaviorSubject<CmsMetric[]>([]);
    this.highlightDatum$ = new BehaviorSubject<any>(null);
    this.datasetChanged$ = new Subject<void>();
   }

  attachCloudData(collection: VisualOptions[]) {
    if (!this.isCloudExportable) return;
    this.cloudExportService.attachCloudData(collection);
  }

  autoSize() {
    const grid = this.gridParams.gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
    setTimeout(() => grid.api.autoSizeAllColumns(), 150);
  }

  cloneCriteria() {
    this.incrementalsService.cloneReport(this.parentActivity.getId());
  }

  editCriteria() {

  }

  exportSheetCSV() {
    this.incrementalsService.exportSheetAsCSV(this.parentActivity, this.gridParams.gridVisualOptions);
  }

  public exportSheetXLSX() {
    this.incrementalsService.exportSheetAsExcel(this.parentActivity, this.gridParams.gridVisualOptions);
  }

  fitColumnsToScreen() {
    const grid = this.gridParams.gridVisualOptions.apiRef().grid;
    grid.api.sizeColumnsToFit();
  }

  getDefaultMetrics(): DefaultMetrics {
    if (IncrementalsResultComponent.hideMetricsTemp) {
      return <DefaultMetrics>{
        xMetric: _.find(this.activeDatasetMetrics$.value, {id: INC_ENUMS.NUM_BASKETS}),
        yMetric: _.find(this.activeDatasetMetrics$.value, {id: INC_ENUMS.STORE_COUNT})
      };
    } else {
      return <DefaultMetrics>{
        xMetric: _.find(this.activeDatasetMetrics$.value, {id: INC_ENUMS.NUM_BASKETS}),
        yMetric: _.find(this.activeDatasetMetrics$.value, {id: INC_ENUMS.PERCENT_DISTRIBUTION})
      };
    }
  }

  gridFeatureUsed(feature: string) {
    this.mixpanelService.track(MixpanelEvent.FEATURE_SELECTED, {
      'Application': this.config.getApplication().display,
      'Feature': feature,
    });
  }

  handleOptionMenuClick(clickedItem: string) {
    switch (clickedItem) {
      case ActivityGridOptionsMenu.AUTOSIZE_COLUMNS.trim():
        this.autoSize();
        break;
      case ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN.trim():
        this.fitColumnsToScreen();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_CSV.trim():
        this.exportSheetCSV();
        break;
      case ActivityGridOptionsMenu.DOWNLOAD_EXCEL.trim():
        this.exportSheetXLSX();
        break;
      case ActivityGridOptionsMenu.SHARE_SCHEDULE.trim():
        this.share();
        break;
      case ActivityGridOptionsMenu.CLONE_CRITERIA.trim():
        this.cloneCriteria();
      case ActivityGridOptionsMenu.EDIT_CRITERIA.trim():
        this.editCriteria();
      default:
        break;
    }
  }

  ngAfterViewInit(): void {
     this.angularCharts.changes
     .pipe(
       takeUntil(this.allChartsReady$ || this.unsub$)
     )
     .subscribe(chartInfo => {

       combineLatest([...chartInfo.map(c => c.chartReady$)])
       .subscribe(resp => {
         const allChartsReady = !resp.includes(false);

         if (allChartsReady) {
           // Setup Subscription for when dataset changes
           this.activeDataset$.pipe(
             takeUntil(this.unsub$),
             skip(1) // do not run instantly upon creation of this subscription as we are creating it after the charts have been instantiated
           )
           .subscribe(dataset => {
             if (!dataset) return;

             this.datasetChanged(dataset);
             this.datasetChanged$.next();
           });

           this.allChartsReady$.next();
           this.allChartsReady$.complete();
         }
       });
     });
   }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ cms: true, envConfig: true });
    this.showSpinner = true;
    this.route.paramMap // check URL
      .pipe(
        mergeMap(params => {
          const activityId = params.get('id');
          return this.incrementalsService.getReport(activityId)
            .pipe(
              finalize(() => {
                // stop progress bar
                EmitterService.get('topLoading').emit(false);
              })
            );
        }),
        takeUntil(this.unsub$)
      )
      .subscribe(a => {
        this.parentActivity = a;
        this.sheetActivity = a.sheets[0];
        this.poll();
      }, error => {
        // stop progress bar
        EmitterService.get('topLoading').emit(false);
      });

    this.isCloudExportable = await CloudExportService.isExportable(this);
    if (this.isCloudExportable) {
      CloudExportService.setReadyForExportSubscription(this.readyForExport$, this);
    }
  }

  onSelectMetric(metric: string) {
    this.grid.grid.api.setFilterModel(null); // clear all filters

    if (metric === IncrementalsConfig.ABSOLUTE) {
      this.activeDataset$.next({
        data: this.absoluteData,
        statResponse: this.absoluteSR
      });
    } else {
      this.activeDataset$.next({
        data: this.perStoreWeekData,
        statResponse: this.perStoreWeekSR
      });
    }

    const colDefs = _.cloneDeep(this.grid.grid.gridOptions.columnDefs);
    colDefs.forEach((cg: ColGroupDef) => {
      cg.headerName = IncrementalsConfig.PER_STORE_WEEK_METRICS.indexOf(cg.children[0].headerName) > -1 ? metric : '';
    });
    this.grid.grid.api.updateGridOptions({ columnDefs: colDefs });
    this.grid.grid.api.updateGridOptions({ rowData: this.activeDataset$.value.data });
    this.grid.aggregate();
  }

  onFilterChanged() {
    if (this.selected === IncrementalsConfig.ABSOLUTE) {
      this.absoluteDataAfterFilter = [];
      this.grid.grid.api.forEachNodeAfterFilter((rowNode, index) => {
        this.absoluteDataAfterFilter.push(rowNode.data);
      });
      this.activeDatasetData$.next(this.absoluteDataAfterFilter);
    } else {
      this.perStoreWeekDataAfterFilter = [];
      this.grid.grid.api.forEachNodeAfterFilter((rowNode, index) => {
        this.perStoreWeekDataAfterFilter.push(rowNode.data);
      });
      this.activeDatasetData$.next(this.perStoreWeekDataAfterFilter);
    }
    this.datasetChanged$.next();
  }

  setDefaultMetrics() {
    const defaultMetrics: DefaultMetrics = this.getDefaultMetrics();

    this.xAxisFact$.next(defaultMetrics.xMetric);
    this.yAxisFact$.next(defaultMetrics.yMetric);
    this.xAxisFactArr$.next([defaultMetrics.xMetric]);
    this.yAxisFactArr$.next([defaultMetrics.yMetric]);
  }

  setYAxisFact(event: MatSelectChange) {
    const yFact: CmsMetric = event.value;
    this.yAxisFact = yFact;
    this.yAxisFact$.next(yFact);
    this.yAxisFactArr$.next([yFact]);
  }

  setXAxisFact(event: MatSelectChange) {
    const xFact: CmsMetric = event.value;
    this.xAxisFact = xFact;
    this.xAxisFact$.next(xFact);
    this.xAxisFactArr$.next([xFact]);

    // TODO: The above code could be moved into the processor and just called from here; then the processor could also use it when needed.
  }

  share() {
    this.sharingService.share(this.parentActivity);
  }

  private checkStatus() {
    this.sheetActivity.appId = ApplicationHash.INCREMENTALS;
    this.status = this.sheetActivity.getStatus();
    switch (this.status) {
      case ActivityStatus.ERROR:
        this.showSpinner = false;
        if (this.sheetActivity.errorsDetails[0].getErrorCode() === StatResponseErrors.SHEET_TIME_OUT['errorCode']) {
          this.customError = new SiqError('', IncrementalsConfig.CUSTOM_ERROR_MSG);
        }
        if (this.sheetActivity.errorsDetails[0].getErrorCode() === StatResponseErrors.RB_RESPONSE_SIZE_EXCEEDED['errorCode']) {
          this.customError = new SiqError('', IncrementalsConfig.CUSTOM_ERROR_MSG);
        }
        break;
      case ActivityStatus.ALERT:
        this.showSpinner = false;
        break;
      case ActivityStatus.READY:
        this.init(this.parentActivity);
        this.retrieveResults();
        break;
      case ActivityStatus.EXPIRED:
      case ActivityStatus.RUNNING:
        this.poll();
        break;
    }
  }

  private init(reportActivity: IncrementalsActivity) {
    this.parentActivity = reportActivity;
    this.formData = this.incrementalsService.createForm(JSON.parse(reportActivity.formValues));
    this.queryMode.setSchema(this.formData.schema);
    setTimeout(() => {
      this.mixpanelService.track(MixpanelEvent.RESULTS_VIEWED, {
        'Application': this.config.getApplication().display,
        'Application ID': this.config.appId,
        'Application Version': this.config.version,
        'Activity ID': reportActivity.getId(),
        'Viewed From': 'Application'
      });
    });

    this.activityService.markViewed(this.parentActivity, this.route.snapshot.queryParamMap.get('source'));

    if (!this.parentActivity.isMine()) { // user is not report owner, cannot share/schedule report
      this.customOptionMenuItems = [
        ActivityGridOptionsMenu.AUTOSIZE_COLUMNS,
        ActivityGridOptionsMenu.FIT_COLUMNS_TO_SCREEN,
        ActivityGridOptionsMenu.DOWNLOAD_CSV,
        ActivityGridOptionsMenu.DOWNLOAD_EXCEL,
        ActivityGridOptionsMenu.CLONE_CRITERIA
      ];
    }
  }

  private createColumnParam(metricsArr$: BehaviorSubject<CmsMetric[]>): BarColumnParams {
    return <BarColumnParams>{
      agChartOptions: null,
      dimensions$: this.activeDatasetDimensions$,
      metrics$: metricsArr$,
      parent: this,
      parentActivity: this.parentActivity,
      selectedDimension$: new BehaviorSubject<CmsField>(this.absoluteSR.dimensions[0]),
      selectedMetrics$: metricsArr$,
      statResponse: this.activeDatasetStatResponse$,
      highlightDatum$: this.highlightDatum$,
      datasetChanged$: this.datasetChanged$,
      rawData$: this.activeDatasetData$,
    };
  }

  private datasetChanged(dataset: DatasetDetail) {
    const filteredMetrics = dataset.statResponse.facts.filter(f => this.config.factOrder.includes(f.id)).map(cmsMetric => {
      if (IncrementalsConfig.PER_STORE_WEEK_METRICS.includes(cmsMetric.display)) {
        if (this.selected === IncrementalsConfig.ABSOLUTE) {
          const index = cmsMetric.display.indexOf(this.PER_STORE_PER_WEEK);
          if (index > -1) {
            cmsMetric.display = cmsMetric.display.slice(0, index);
          }
        }
        if (this.selected === IncrementalsConfig.PER_STORE_WEEK) {
          cmsMetric.display = cmsMetric.display + ' ' + this.PER_STORE_PER_WEEK;

        }
      }
      return cmsMetric;
    });

    // set order metrics into default order
    const filteredOrderedMetrics = this.config.factOrder.map(key => {
      return _.find(filteredMetrics, f => {
        return f.id === key;
      });
    });

    this.activeDatasetMetrics$.next(filteredOrderedMetrics);

    if (IncrementalsResultComponent.hideMetricsTemp) {
      this.activeDatasetMetrics$.next(this.activeDatasetMetrics$.value.filter(value => value.id != 'INC_PERCENT_DISTRIBUTION'));
    }
    this.activeDatasetDimensions$.next(dataset.statResponse.dimensions);

    this.activeDatasetData$.next(dataset.data);
    this.activeDatasetStatResponse$.next(dataset.statResponse);

    // Set default x/y metrics depending on the activeDataset
    this.setDefaultMetrics();
  }

  private poll() {
    of(true)
      .pipe(
        delay(this.config.pollInterval),
        takeUntil(this.unsub$),
        switchMap(_ =>
          this.incrementalsService.getSheet(this.sheetActivity.getId(), this.parentActivity.getId())
          .pipe(
            finalize(() => {
              // stop progress bar
              EmitterService.get('topLoading').emit(false);
            })
          )
        )
      )
      .subscribe(a => {
        this.sheetActivity = a;
        this.applicationName = this.parentActivity.getName();
        this.checkStatus();
      }, error => {
        // stop progress bar
        EmitterService.get('topLoading').emit(false);
        
      });
  }

  // By default, process the absolute dataset
  private processResult() {
    // Set the "absolute" dataset as the active one. Charts will reference this to initialize themselves.
    this.activeDataset$.next({
      data: this.absoluteData,
      statResponse: this.absoluteSR
    });
    this.gridParams = {
      parent: this,
      statResponse: this.activeDataset$.value.statResponse,
      data: this.activeDataset$.value.data,
      readyForExport$: this.readyForExport$
    };

    this.gridParams.gridVisualOptions = IncrementalsGridProcessor.generateGridVisualOptions(this.gridParams);
  }

  private retrieveResults() {
    const columnIds = this.sheetActivity.getMetaDataByKey('columns').split(',');
    const requests = columnIds.map(colId => {
      const obs: Observable<Activity> = this.activityService.getActivityResults(colId);
      return obs;
    });
    // Perform a forkJoin of the array of Observables, and stream in the data as they complete
    forkJoin(requests)
      .subscribe((columnGroupActivities: Activity[]) => {
        this.setup(columnGroupActivities);
        this.processResult();
        this.setupCharts();
        this.showSpinner = false;
        this.ready();
      });
  }

  private setup(columnGroupActivities: Activity[]) {
    // Construct absolute and per store week datasets
    columnGroupActivities.forEach(a => {
      const jobName = a.getJobs()[0].getName();
      const sr = a.getJobs()[0].getResponse();
      if (jobName === IncrementalsConfig.PER_STORE_WEEK_DATA) { // truncated date ranges dataset
        this.perStoreWeekSR = sr;
      } else {
        this.absoluteSR = sr;
      }
    });
    this.perStoreWeekData = GridService.jobToArray(this.incrementalsService.processPerStoreWeek(this.perStoreWeekSR, this.absoluteSR));
    this.absoluteData = GridService.jobToArray(this.absoluteSR); // this after processPerStoreWeek because processPerStoreWeek will make some stat response data value consistent.

    this.displayFacts = this.config.factOrder.map(key => {
      return _.find(this.absoluteSR.getFacts(), f => {
        return f.id === key;
      });
    });
    // These facts totallingType changed from NA to AS (backend) but they are not in CMS so code catch here...
    _.find(this.absoluteSR.getFacts(), f => {
      switch (f.id) {
        case INC_ENUMS.AVG_BASKET_AMT:
        case INC_ENUMS.AVG_ITEM_BASKET_AMT:
        case INC_ENUMS.AVG_NONITEM_BASKET_AMT:
        case INC_ENUMS.PERCENT_ITEM_BASKET_AMT:
        case INC_ENUMS.PERCENT_ITEM_BASKET_CNT:
          f.aggType = AggregationType.ASYNC;
          break;
      }
    });
  }

  private setupCharts() {
    /**
     * Initialize the charts, waiting for them to instantiate.
     */

    // Populate the core BehaviorSubjects needed by charts with initial value
    this.datasetChanged(this.activeDataset$.value);

    // Now initialize the additional BehaviorSubjects for the axis
    this.setDefaultMetrics();

    this.scatterParams = {
      parent: this,
      rawData: this.activeDatasetData$,
      selectedMetricX$: this.xAxisFact$,
      selectedMetricY$: this.yAxisFact$,
      metaData: {},
      dimensions: this.activeDatasetDimensions$,
      metrics: this.activeDatasetMetrics$,
      highlightDatum$: this.highlightDatum$,
      datasetChanged$: this.datasetChanged$
    };

    this.columnParamsY = this.createColumnParam(this.yAxisFactArr$);
    this.barColumnConfigY = IncrementalsBarColumnProcessor.generateBarColumnConfig(this.columnParamsY);
    this.columnParamsY.agChartOptions = this.barColumnConfigY.agChartOptions;

    this.columnParamsX = this.createColumnParam(this.xAxisFactArr$);
    this.barColumnConfigX = IncrementalsBarColumnProcessor.generateBarColumnConfig(this.columnParamsX);
    this.columnParamsX.agChartOptions = this.barColumnConfigX.agChartOptions;
  }

}

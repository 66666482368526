<mat-dialog-content>
  <h5>{{ config.header }}</h5>
  <span>{{ config.body }}</span>
</mat-dialog-content>


<mat-dialog-actions>
  <button
    *ngFor="let button of config.buttons"
    type="button"
    mat-button
    (click)="close(button.response)"
    color="accent"
  >
    {{ button.label }}
  </button>
</mat-dialog-actions>

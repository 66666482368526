import * as _ from 'lodash';

export abstract class BaseModel {
  /*
   * These functions could eventually be put into a class 'Serializable' which could be implemented
   * by this class via mixin if this kind of serialization is needed elsewhere.
   */

  static jsonArrayToTypedArray<T>(jsonList: any[], typeRef): T[] {
    const typedArray: T[] = [];
    jsonList.forEach(jsonObj => {
      typedArray.push(BaseModel.typedObjectFromJson(jsonObj, typeRef));
    });
    return typedArray;
  }

  static typedObjectFromJson<T>(j: any, type: { new(): T; }, parseKeys?: string[]): T {

    let t = new type();

    for (let k of Object.keys(t)) {
      // If the json object (j) has this key, then use it. Otherwise keep the original value.
      t[k] = this.getValue((_.has(j, k) ? j[k] : t[k]), (parseKeys && _.includes(parseKeys, k)) );
    }

    return t;
  }

  static fromJsonString<T>(j: string, type: {new(): T; }): T {
    return this.typedObjectFromJson(JSON.parse(j), type);
  }

  private static getValue(o: any, parse?: boolean): any {
    // Prevent empty string and non-string values from being parsed
    if (parse && _.isString(o)) {
      return JSON.parse(!_.isEmpty(o) ? o : '{}');
    } else {
      return o;
    }
  }
}

<mat-calendar-header></mat-calendar-header>
<div class="date-shortcuts-wrapper" [ngClass]="config.position === 'left' ? 'left' : 'right'">
  <mat-selection-list [multiple]="false" (selectionChange)="setPreview($event)">
    <mat-list-option 
      *ngFor="let sc of shortcuts" 
      [value]="sc" 
      [disabled]="sc.disabled"
      [ngClass]="{selected: sc.name === config.parent.selectedShortcut?.name}"
    >
      {{sc.name}}
    </mat-list-option>
  </mat-selection-list>
  <div class="date-shortcuts-wrapper-buttons">
    <button mat-stroked-button (click)="onClickAction(datePickerAction.CANCEL)" *ngIf="changed" class="secondary-button">
      Cancel
    </button>
    <button mat-raised-button color="accent" (click)="onClickAction(datePickerAction.APPLY)" [disabled]="!changed">
      Apply
    </button>
  </div>
</div>
export enum BarColumnType {
  BAR = 'bar',
  COLUMN = 'column'
}

export enum BarColumnTypeDisplay {
  GROUPED = 'grouped',
  SIMPLE = 'simple',
  STACKED = 'stacked',
  STACKED100 = 'stacked100'

}

export enum ChartThemes {
  DEFAULT = 'ag-default',
  DEFAULT_DARK = 'ag-default-dark',
  MATERIAL = 'ag-material',
  MATERIAL_DARK = 'ag-material-dark',
  PASTEL = 'ag-pastel',
  PASTEL_DARK = 'ag-pastel-dark',
  SOLAR = 'ag-solar',
  SOLAR_DARK = 'ag-solar-dark',
  VIVID = 'ag-vivid',
  VIVID_DARK = 'ag-vivid-dark',
  PDI = 'pdi-theme',
  PDI_DARK = 'pdi-theme-dark'
}
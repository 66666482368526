import { Injectable } from '@angular/core';
import { ContentType, CoreConstants } from '@siq-js/core-lib';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor() { }

  /**
   * This came from apps/dashboard/src/app/core/services/siq-http/siq-http.service.ts
   * @param customHeaders
   * @param contentType
   */
  public static createHeaders(customHeaders: any = {}, contentType?: ContentType): HttpHeaders {
    let headers: any = {
      'Content-Type': contentType || ContentType.JSON,
      'Cache-Control': 'no-cache',
      'Content-Location': ''
    };

    if (_.keys(customHeaders).length) {
      headers = _.extend(headers, customHeaders);
    }

    return new HttpHeaders(headers);
  }

  public static getFullEndpoint(application: string = 'dashboard', endpoint: string): string {
    return CoreConstants.environment[application as keyof typeof CoreConstants.environment].api + '/' + endpoint;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Route } from '@angular/router';
import { NavService } from 'app/core/services/nav/nav.service';
import { LoginComponent } from 'app/public/components/login/login.component';
import * as _ from 'lodash';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'siq-unsubscribed',
  templateUrl: './unsubscribed.component.html',
  styleUrls: ['./unsubscribed.component.scss']
})
export class UnsubscribedComponent implements OnInit, OnDestroy {

  public complete = false;
  public error = false;
  public notFound = false;
  public loginRoute: Route = _.find(NavService.getRoutesPublic(), r => r.component === LoginComponent);
  public reportName: string;
  private defaultReportName = 'Untitled Report';
  private unsub$ = new Subject<void>();
  private unsubPath = 'public/cloudscheduler/reports/{id}/users/{email}';

  constructor(private route: ActivatedRoute,
              private siqHttpService: SiqHttpService) { }

  ngOnDestroy () {
    this.unsub$.next();
    this.unsub$.complete();
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(
      takeUntil(this.unsub$)
    )
    .subscribe((params: ParamMap) => {
      const id = params.get('id');
      const email = params.get('user');

      this.setReportName(params);
      this.unsubPath = this.unsubPath.replace('{id}', id);
      this.unsubPath = this.unsubPath.replace('{email}', email);
    });
  }

  unsubscribeReport() {
    this.siqHttpService
    .get({endpoint: this.unsubPath})
      .subscribe({
        next: () => {
          this.complete = true;
        },
        error: (error: any) => {
          if (error.status == 404) {
            this.complete = true;
            this.notFound = true;
          } else if (error.status === 500) {
            this.error = true;
          }
        }
      });
  }

  private setReportName(params: ParamMap): void {
    let name;
    if (!_.isNil(params.get('report'))) {

      if (params.get('report').trim().length > 0) {
        name = params.get('report');
      }
    }

    this.reportName = name ? name : this.defaultReportName;
  }
}

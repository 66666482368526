import { BaseModel } from 'app/core/models/base-model';
import { User } from 'app/user/models/user.model';
import { SiqError } from 'app/core/models/siq-error.model';
import { AppResponseDataset } from 'app/siq-applications/modules/shared/models/app-response-dataset.model';
import { ActivityJson, ApplicationHash } from '@siq-js/core-lib';
import { StatResponse } from 'app/core/models/stat-response.model';
import { Activity } from 'app/activity/models/activity.model';
import { ReportActivity } from 'app/siq-applications/modules/report-builder/models/report-activity.model';
import { IncrementalsActivity } from 'app/siq-applications/modules/incrementals/models/incrementals-activity.model';
import { PromoActivity } from 'app/siq-applications/modules/promo/models/promo-activity.model';
import { DaypartsActivity } from 'app/siq-applications/modules/dayparts/models/dayparts-activity.model';
import { AffinitiesActivity } from 'app/siq-applications/modules/affinities/models/affinities-activity.model';

export class ActivityFactory {

  public static createActivity<T extends Activity = Activity>(json: ActivityJson): T {
    let a = <Activity>BaseModel.typedObjectFromJson(json.activity, Activity);

    try {
      a.siteUser = User.fromJson(a.siteUser);

      if (a.errorsDetails) {
        a.errorsDetails = a.errorsDetails.map(error => SiqError.fromJson(error));
      }

      if (a.jobs) {
        this.parseStatResponse(a);
      }

      if (json.report) {
        a.sharedReport = json.report;
      }

      if (json.sheets && json.sheets.length) {
        a.sheets = json.sheets.map(sheetJson => this.createActivity({
          activity: sheetJson,
          report: null,
          sheets: null
        }));
      }
    } catch (e) {
      console.warn('Error creating activity: %O, %O', e, a);
    }

    return this.cast(a) as unknown as T;
  }

  private static parseStatResponse(a: Activity): void {
    a.jobs = a.jobs.map(j => {
      const job = AppResponseDataset.fromJson(j);
      const response = job.getResponse();

      let isStatResponse = true;

      switch (a.getAppId()) {
        // case ApplicationHash.AFFINITIES:
        //   job.setParams(AffinitiesParameters.fromJson(job.getParams(), AffinitiesParameters));
        //   break;
      }

      if (response) {

        const formattedResponse = StatResponse.fromJson(response, StatResponse);

        formattedResponse.setParentActivity(a);

        job.setResponse(formattedResponse);

      }
      return job;
    });
  }

  /**
   * Converts a base Activity (with optional sheets/shared data) into an instance of a subclass, adds "application" variable into every Activity
   * @param a
   */
  private static cast<T>(a: Activity): Activity {
    switch (a.getAppId()) {
      case ApplicationHash.REPORT_BUILDER:
        return new ReportActivity(a);
      case ApplicationHash.INCREMENTALS:
        return new IncrementalsActivity(a);
      case ApplicationHash.PROMO:
        return new PromoActivity(a);
      case ApplicationHash.DAYPARTS:
        return new DaypartsActivity(a);
      case ApplicationHash.AFFINITIES:
        return new AffinitiesActivity(a);
    }
    return a;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'siq-stepper-footer',
  templateUrl: './stepper-footer.component.html',
  styleUrls: ['./stepper-footer.component.scss']
})
export class StepperFooterComponent {
  @Input() showPrev: boolean;
  @Input() showNext: boolean;
  @Output() actionEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  onClick(action: 'prev' | 'next') {
    this.actionEmitter.next(action);
  }

}

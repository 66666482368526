import { Component } from '@angular/core';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ColDef, AgRendererComponent } from '@siq-js/visual-lib';
import { PromoActivity } from 'app/siq-applications/modules/promo/models/promo-activity.model';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { KPI, KPIChipClass } from 'app/siq-applications/modules/promo/models/promo.enums';
import { isNaN, isNil } from 'lodash';

@Component({
  selector: 'siq-js-kpi-renderer',
  templateUrl: './kpi-renderer.component.html',
  styleUrls: ['./kpi-renderer.component.scss']
})
export class KpiRendererComponent implements AgRendererComponent {

  public kpiValue: string;
  public css: KPIChipClass;

  // Generates a ColDef based on the KPI passed in
  public static getColDef(kpi: KPI): ColDef {

    const WIDTH_MAP: {[_kpi in KPI]: number} = {
      TOTAL_QUANTITY: 75,
      AVG_PRICE: 86,
      TOTAL_AMOUNT: 80,
      NUM_TRANSACTIONS_SUMMABLE: 115,
      BASKET_TOTAL_AMOUNT: 85,
      BASKET_TOTAL_QUANTITY_FACT: 85
    };
    const width = WIDTH_MAP[kpi];

    return {
      headerName: PromoConfig.kpiNameMap[kpi],
      cellRenderer: KpiRendererComponent,
      width: width,
      resizable: false,
      valueGetter: params => {
        try {
          return (params.data as PromoActivity).getKPIGrowth(kpi);
        } catch (e) {
          console.warn(e);
          return null;
        }
      }
    };
  }

  constructor() { }

  agInit(params: any) {
    this.kpiValue = this.getValue(params.value);
  }

  refresh(params: any): boolean {
    if (params.value !== this.kpiValue) {
      this.kpiValue = this.getValue(params.value);
      return true;
    }
    return false;
  }

  getValue(val: number): string {
    if (isNil(val) || isNaN(val)) {
      this.css = null;
      return '--';
    }
    let value: string;
    value = (UtilsService.num(val * 100, 1, true) + '%');

    if (val > 0) {
      value = '+' + value;
      this.css = KPIChipClass.POSITIVE;
    } else if (val < 0) {
      this.css = KPIChipClass.NEGATIVE;
    }
    return value;
  }

}

import { Injectable } from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  Column,
  AggregationType,
  GRID_DEFAULTS,
  GridComponent,
  IAppRequest,
  GridSettings
} from '@siq-js/visual-lib';
import { Observable, of } from 'rxjs';
import { AppDataset } from 'app/siq-applications/modules/shared/models/app-dataset.model';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { AsyncTotalsParametersDTO } from 'app/grid/models/async-total-interfaces';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NotificationService } from '@siq-js/angular-buildable-lib';
import { CustomInjector } from 'app/core/models/custom-injector';
import { AppRequest } from 'app/siq-applications/modules/shared/models/app-request.model';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { delay, switchMap, takeUntil } from 'rxjs';
import { SiqError } from 'app/core/models/siq-error.model';
import * as _ from 'lodash';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { CmsService } from 'app/core/services/cms/cms.service';
import { ActivityResultType, ActivityService } from 'app/activity/services/activity.service';
import { SiqFilter } from 'app/filter/models/interfaces';

interface TempParams {
  dims: CmsField[];
  globalDateRange: DateRangeInterface;
  globalFilters: SiqFilter[];
  metricColDef: ColDef;
  // locationsWithHistory?: LWHModel;
}

@Injectable({
  providedIn: 'root'
})
export class GridTotalsService extends SiqHttpService {
  private static readonly asyncTotalsPath = 'totals';

  public static createAsyncTotals(gridComponent: GridComponent): Observable<HttpResponse<any>> {
    return GridTotalsService.submitAsyncTotalsRequest(
      GridTotalsService.createAsyncTotalsPayload(gridComponent)
    );
  }

  public static createAsyncTotalsPayload(gridComponent: GridComponent): AppRequest {
    const appDatasets: AppDataset[] = GridTotalsService.createDatasets(gridComponent);
    let metaData = new Map<string, string>();
    metaData.set('analysisType', UtilsService.getAnalysisType());

    return new AppRequest(
      appDatasets,
      undefined,
      metaData,
      true
    );
  }

  public static getAsyncTotalsResults(gridComponent: GridComponent, id: string): Observable<Object|Activity|SiqError[]> {
    const stub = (id.indexOf('.json') > -1);
    let totalsResults$: Observable<Activity|Object|SiqError[]>;

    if (!!stub) {
      const stubUrl = './assets/' + id;
      totalsResults$ = CustomInjector.injector.get(HttpClient).get(stubUrl);
    } else {
      totalsResults$ = new Observable(observer => {
        let _attempts = 1;

        const _checkStatus = (activity: Activity) => {
          const retry = activity.getStatus().toLowerCase() === ActivityStatus.RUNNING.toLowerCase();
          if (retry) {
            _attempts += 1;
            _poll();
          } else {
            observer.next(activity);
            observer.complete();
          }
        };

        const _poll = () => {
          console.log('----- async results polling (attempt: %O) ----', _attempts);
          observer.next('attempt:' + _attempts.toString());
          of(true)
          .pipe(
            delay(1000),
            takeUntil(gridComponent.unsub$),
            switchMap(_res => CustomInjector.injector.get(ActivityService).getActivity<Activity>({
              id: id,
              resultType: ActivityResultType.POLL,
              suppressTopLoadingBar: true
            }))
          )
          .subscribe(a => {
            _checkStatus(a);
          }, err => {
            console.error('Error occurred during polling async totals result', err);
          });
        };

        _poll();

      });
    }

    return totalsResults$;
  }

  public static provideAsyncTotalsFunctionality(gridSettings: GridSettings) {
    // This function sets all necessary pointers to the functions needed for AsyncTotals
    gridSettings.createAsyncTotals = GridTotalsService.createAsyncTotals;
    gridSettings.createAsyncTotalsPayload = GridTotalsService.createAsyncTotalsPayload;
    gridSettings.getAsyncTotalsResults = GridTotalsService.getAsyncTotalsResults;
    gridSettings.submitAsyncTotalsRequest = GridTotalsService.submitAsyncTotalsRequest;
  }

  public static submitAsyncTotalsRequest(appRequest?: AppRequest|IAppRequest): Observable<HttpResponse<any>> {
    return CustomInjector.injector.get(SiqHttpService).create({
      endpoint: GridTotalsService.asyncTotalsPath,
      body: (<AppRequest>appRequest).asJsonObject(),
      suppressNotification: true
    });
  }

  private static createDatasets(gridComponent: GridComponent, excludeGrandTotals = false): AppDataset[] {
    const _formValues = gridComponent.parentActivity.getFormValues();
    const globalFilters = _formValues.globalFilters;
    const datasets: AppDataset[] = [];
    const metricColDefsTemp: ColDef[] = GridTotalsService.getMetricColDefs(gridComponent);
    // Strip out any timeAgg metrics into a map to reduce to non-repetitive facts, map non-timeAgg metrics into fresh array
    const timeAggMetricsMap: Map<string, any> = new Map<string, any>();
    const metricColDefs: ColDef[] = metricColDefsTemp.filter(colDef => {
      if (!!colDef.cellRendererParams.colDefMeta.timeAgg) {
        const timeAggColGroupId = colDef.cellRendererParams.colDefMeta.asyncTotalsKey.split(':')[0];
        if (!timeAggMetricsMap.has(timeAggColGroupId)) {
          const cd = _.cloneDeep(colDef);
          timeAggMetricsMap.set(timeAggColGroupId, cd);
        }
        return false;
      }
      return true;
    }).map(colDef => {
      return colDef;
    });

    if (!!timeAggMetricsMap.size) {
      metricColDefs.push(...timeAggMetricsMap.values());
    }

    const rowGroupCmsFields: CmsField[] = GridTotalsService.getRowGroupCmsFields(gridComponent);
    const pivotColumnCmsFields: CmsField[] = [];
    if (gridComponent.gridSettings.gridVisualOptions.apiRef().grid.api.isPivotMode()) {
      pivotColumnCmsFields.push(
        ...gridComponent.gridSettings.gridVisualOptions.apiRef().grid.api.getPivotColumns().map((column: Column) => {
          return column.getColDef().cellRendererParams.colDefMeta.ref;
        })
      );
    }
    rowGroupCmsFields.forEach((cmsField, i) => {
      metricColDefs.forEach(mcd => {
        const _dims = rowGroupCmsFields.slice(0, i + 1);

        // If in pivotMode, tack on the Cols
        if (gridComponent.gridSettings.gridVisualOptions.apiRef().grid.api.isPivotMode()) {
          _dims.push(...pivotColumnCmsFields);
        }

        if (!!mcd.cellRendererParams.colDefMeta.timeAgg) {
          _dims.push(mcd.cellRendererParams.colDefMeta.timeAgg.dim);
        }

        datasets.push(GridTotalsService.createAppDataset(GridTotalsService.createTempParams(
          _dims,
          gridComponent.gridSettings.gridVisualOptions.globalDateRange,
          globalFilters,
          mcd,
          // _formValues.lwh
        )));
      });
    });

    // GrandTotals
    if (!excludeGrandTotals) {
      const nullCmsField: CmsField = new CmsField({id: 'NULL', table: 'NULL', retailer: null, field: 'NULL', filter: null, type: null, display: null, active: true});
      metricColDefs.forEach(mcd => {
        const _dims: CmsField[] = [];
        // If in pivotMode, then need to add dims for the "Column Labels"
        if (gridComponent.grid.api.isPivotMode()) {
          rowGroupCmsFields.forEach((cmsField, i) => {
            _dims.push(nullCmsField);
          });
          gridComponent.grid.api.getPivotColumns().forEach(col => {
            _dims.push(CmsService.get().findEntity<CmsField>(col.getColDef().colId));
          });
        } else {
          _dims.push(nullCmsField);
        }
        if (!!mcd.cellRendererParams.colDefMeta.timeAgg) {
          _dims.push(mcd.cellRendererParams.colDefMeta.timeAgg.dim);
        }

        datasets.push(GridTotalsService.createAppDataset(GridTotalsService.createTempParams(
          _dims,
          gridComponent.gridSettings.gridVisualOptions.globalDateRange,
          globalFilters,
          mcd,
          // _formValues.lwh
        )));
      });
    }

    return datasets;
  }

  private static createAppDataset(tempParams: TempParams): AppDataset {
    let cmsMetric: CmsMetric;
    let dateRangeParam = DatesService.paramify(tempParams.globalDateRange); // Default; This may get modified (ie YOY offset)
    let asyncTotalsKey: string = tempParams.metricColDef.cellRendererParams.colDefMeta.asyncTotalsKey;

    // YOY specific code:
    if (tempParams.metricColDef.cellRendererParams.colDefMeta.yoy) {
      cmsMetric = tempParams.metricColDef.cellRendererParams.colDefMeta.yoy.ref || tempParams.metricColDef.cellRendererParams.colDefMeta.ref;

      // Add key prefix (if needed)
      if (tempParams.metricColDef.cellRendererParams.colDefMeta.yoy.prefix) {
        asyncTotalsKey = tempParams.metricColDef.cellRendererParams.colDefMeta.yoy.prefix + asyncTotalsKey;
      }

      if (tempParams.metricColDef.cellRendererParams.colDefMeta.params?.dateRanges) {
        // Already in format of DateRangeParameter, no need to run through DatesService.paramify()
        dateRangeParam = tempParams.metricColDef.cellRendererParams.colDefMeta.params.dateRanges[0];
      }
    }

    // TimeAgg specific code:
    if (tempParams.metricColDef.cellRendererParams.colDefMeta.timeAgg) {
      cmsMetric = tempParams.metricColDef.cellRendererParams.colDefMeta.timeAgg.ref || tempParams.metricColDef.cellRendererParams.colDefMeta.ref;
      asyncTotalsKey = tempParams.metricColDef.cellRendererParams.colDefMeta.timeAgg.prefix + asyncTotalsKey.split(':')[0];
    }

    if (!tempParams.metricColDef.cellRendererParams.colDefMeta.yoy && !tempParams.metricColDef.cellRendererParams.colDefMeta.timeAgg) {
      // Standard metric
      cmsMetric = (<ColDef>tempParams.metricColDef).cellRendererParams.colDefMeta.ref;
    }

    const dims: any[] = tempParams.dims.map(cmsField => {
      let paramified = UtilsService.paramify(cmsField);
      paramified.n = paramified.n.toUpperCase();
      return paramified;
    });

    const filters = [];
    if (tempParams.metricColDef.cellRendererParams.colDefMeta.filters?.length) {
      filters.push(...tempParams.metricColDef.cellRendererParams.colDefMeta.filters);
    } else if (tempParams.metricColDef.cellRendererParams.colDefMeta.params?.filters) {
      // YOY (LY) cols will have this
      filters.push(...tempParams.metricColDef.cellRendererParams.colDefMeta.params?.filters);
    }

    const p = <AsyncTotalsParametersDTO>{
      dateRanges: [dateRangeParam],
      dimensions: dims,
      facts: [cmsMetric.id],
      filters: filters
    };
    // if (!_.isNil(tempParams.locationsWithHistory)) {
    //   p.locationsWithHistory = LocationsWithHistoryService.toJson(tempParams.locationsWithHistory);
    // }

    return new AppDataset(asyncTotalsKey, p);
  }

  private static createTempParams(dims: CmsField[], globalDateRange: DateRangeInterface, globalFilters: SiqFilter[], metricColDef: ColDef): TempParams {
    let tempParams: TempParams = {
      dims: dims,
      globalDateRange: globalDateRange,
      globalFilters: globalFilters,
      metricColDef: metricColDef
    };
    // if (!_.isNil(locationsWithHistoryJson)) {
    //   tempParams.locationsWithHistory = LocationsWithHistoryService.fromJson(locationsWithHistoryJson);
    // }
    return tempParams;
  }

  private static getMetricColDefs(gridComponent: GridComponent): ColDef[] {
    // Helper fn to get a list of the metric Columns (ColDef | ColGroupDef)
    const _getMetricCol = (cd: (ColGroupDef|ColDef), longKey: string[]) => {
      if ((<any>cd).groupId) {
        (<ColGroupDef>cd).children.forEach(child => {
          longKey.push((<ColGroupDef>cd).groupId);
          _getMetricCol(child, longKey);
        });
      } else {
        // ColDef
        if (
          (!(<ColDef>cd).cellRendererParams.colDefMeta.yoy?.dynamic) &&
          ((<ColDef>cd).aggFunc) && (<ColDef>cd).aggFunc === GRID_DEFAULTS.AGG_MAP[AggregationType.ASYNC]
        ) {
          // Metric; aggFunc is "async" and ColDef is NOT dynamic (TY vs LY, % ∆ TY vs LY)
          longKey.push((<ColDef>cd).colId);
          (<ColDef>cd).cellRendererParams.colDefMeta.longKey = longKey.join(':');
          const startIdx = longKey.length > 1 ? longKey.length - 2 : longKey.length;
          (<ColDef>cd).cellRendererParams.colDefMeta.asyncTotalsKey = longKey.slice(startIdx).join(':');
          metricColDefs.push(<ColDef>cd);
        }
      }
    };

    let metricColDefs: ColDef[] = [];
    gridComponent.grid.gridOptions.columnDefs.forEach((c: ColDef | ColGroupDef) => {
      _getMetricCol(c, []);
    });

    return metricColDefs;
  }

  private static getRowGroupCmsFields(gridComponent: GridComponent): CmsField[] {
    return gridComponent.grid.api.getRowGroupColumns().map(col => col.getColDef().cellRendererParams.colDefMeta.ref);
  }

  /**
   * New Service: GridTotalsService
   * This exists in siq-js/apps/dashboard because it will need to make use of multiple
   * interfaces that are specific to this "dashboard" application.
   */
  constructor(
    protected http: HttpClient,
    protected notificationService: NotificationService,
  ) {
    super(http, notificationService);
  }

}

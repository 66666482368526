import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject, debounceTime } from 'rxjs';

export interface StatusBlocker {
  value: Observable<boolean>;
  complete: boolean;
}

export class AppLoadingService {

  public static readonly defaultPath = '/welcome';
  public static readonly ready$: Subject<void> = new Subject<void>();
  private static blockingStatus = new BehaviorSubject<StatusBlocker[]>([]);
  private static body = document.getElementsByTagName('body')[0];
  private static messages = document.getElementsByClassName('loading-messages')[0];
  private static subscribed = false;

  public static addBlocker(value: Observable<boolean>, msg?: string) {

    AppLoadingService.body.classList.remove('init');
    AppLoadingService.body.classList.remove('ready');
    AppLoadingService.body.classList.add('loading');

    if (msg) {
      const div = this.messages;
      const node = document.createElement('p');
      const text = document.createTextNode(msg);
      node.appendChild(text);
      div.appendChild(node);
      div.scrollTop = div.scrollHeight - div.clientHeight;
    }

    if (!AppLoadingService.subscribed) {

      AppLoadingService.subscribed = true;

      AppLoadingService.blockingStatus.pipe(
        debounceTime(500)
      )
        .subscribe(res => {
          if (!_.find(res, r => !r.complete)) {
            this.doneLoading();
          }
        });

    }

    const current = AppLoadingService.blockingStatus.value;
    const newBlocker = <StatusBlocker>{
      value: value,
      complete: false
    };

    newBlocker.value.subscribe(res => {
      newBlocker.complete = !!res;
      AppLoadingService.blockingStatus.next(AppLoadingService.blockingStatus.value);
    });

    AppLoadingService.blockingStatus.next(current.concat(newBlocker));

  }

  private static doneLoading () {
    AppLoadingService.body.classList.remove('loading');
    AppLoadingService.body.classList.add('init');
    AppLoadingService.body.classList.add('ready');

    AppLoadingService.ready$.next();

    while (this.messages.firstChild) {
      this.messages.removeChild(this.messages.firstChild);
    }
  };

  constructor() { }

}

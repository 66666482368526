import { Component, OnInit } from '@angular/core';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { FiltersetGridConfig } from 'app/filtersets/models/interfaces';
import { FiltersetAppHeaderModel } from 'app/filtersets/models/filterset-app-header.model';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { CmsField } from '@siq-js/cms-lib';
import { FilterService } from 'app/filter/services/filter.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';

@Component({
  selector: 'siq-js-filtersets-list',
  templateUrl: './filtersets-list.component.html',
  styleUrls: ['./filtersets-list.component.scss']
})
export class FiltersetsListComponent extends BaseSiqComponent implements OnInit {

  public filtersetGridConfig: FiltersetGridConfig;
  public appModelConfig: FiltersetAppHeaderModel;
  public schemas: string[];
  public availableFilters: CmsField[];
  public filtersLoaded: Promise<boolean>;

  constructor(
    private asyncStatusService: AsyncStatusService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ envConfig: true, cms: true });
    const envConfig = EnvConfigService.getConfig();
    this.schemas = envConfig.retailers.slice() || [];
    const primaryEntity = EnvConfigService.getConfig().primaryEntity;
    if (!this.schemas.includes(primaryEntity)) this.schemas.push(primaryEntity); // List page does not have default retailer. Adding primaryEntity (multi-retailers) to make RETAILER filter chip green (allowed).
    this.availableFilters = [];
    this.schemas.forEach(s => {
      this.availableFilters = this.availableFilters.concat(FilterService.getAvailableFilters(s));
    });
    this.filtersetGridConfig = {
      forSelection: false,
      availableFilterIds: this.availableFilters.map(f => f.id)
    };
    this.filtersLoaded = Promise.resolve(true);
    this.appModelConfig = new FiltersetAppHeaderModel();
  }
}

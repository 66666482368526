import { Routes, Route } from '@angular/router';
import { AuthGuard } from 'app/guards/auth-guard';
import { FiltersetsListComponent } from 'app/filtersets/components/filtersets-list/filtersets-list.component';

const filtersetsRoute: Route = {
  path: 'filtersets',
  data: {
    name: 'Filtersets',
    icon: 'filter'
  },
  canActivate: [
    AuthGuard
  ],
  children: [
    {
      path: '',
      component: FiltersetsListComponent
    }
  ]
};

export const FiltersetsRoutes: Routes = [
  filtersetsRoute
];

import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separate'
})
export class SeparatePipe implements PipeTransform {

  transform(value: any[], separator: string, key?: string): any {
    if (!_.isEmpty(value)) {
      return _.join(_.map(value, v => (key ? _.get(v, key) : v)), separator);
    }
    return null;
  }

}

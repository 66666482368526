import { DaypartsFormData } from 'app/siq-applications/modules/dayparts/models/form/dayparts-form-data.model';
import { DaypartsDatasetType } from 'app/siq-applications/modules/dayparts/models/form/dayparts-dataset-type.enum';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { DateRangeParameter } from '@siq-js/core-lib';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { CmsService } from 'app/core/services/cms/cms.service';
import { CmsField } from '@siq-js/cms-lib';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { FieldReference } from 'app/core/models/field-reference';

export class DaypartsParameters {
  private dateRanges: DateRangeParameter[];
  private dimensions: FieldReference[];
  private facts: string[];
  private filters: any[];

  constructor(formData: DaypartsFormData, type: DaypartsDatasetType) {
    this.facts = ['PERCENT_TOTAL_QUANTITY', 'PERCENT_TOTAL_AMOUNT', 'PERCENT_NUM_TRANSACTIONS'];
    this.filters = formData.filters.map(f => UtilsService.paramify(f));
    // Add retailer filter if in single retailer analysis mode
    if (formData.schema !== EnvConfigService.getConfig().primaryEntity) {
      const model = new FilterSelection({
        id: CmsService.RETAILER_FIELD_ID,
        values: [formData.schema],
        include: true,
        nulls: false
      });
      this.filters.unshift(UtilsService.paramify(model));
    }
    this.dateRanges = [DatesService.paramify(formData.dateRanges, formData.weekEndingday)];
    const cmsConfig = CmsService.get();

    switch (type) {
      case DaypartsDatasetType.DAYOFWEEK:
        this.dimensions = [UtilsService.paramify(cmsConfig.findEntity<CmsField>('dayofweek'))];
        break;
      case DaypartsDatasetType.HEATMAP:
        this.dimensions = [UtilsService.paramify(cmsConfig.findEntity<CmsField>('hour')), UtilsService.paramify(cmsConfig.findEntity<CmsField>('dayofweek'))];
        break;
      case DaypartsDatasetType.HOUR:
        this.dimensions = [UtilsService.paramify(cmsConfig.findEntity<CmsField>('hour'))];
        break;
    }
  }

  getDateRanges(): DateRangeParameter[] {
    return this.dateRanges;
  }

  setDateRanges(value: DateRangeParameter[]) {
    this.dateRanges = value;
  }

  getDimensions(): FieldReference[] {
    return this.dimensions;
  }

  setDimensions(value: FieldReference[]): void {
    this.dimensions = value;
  }

  getFacts(): string[] {
    return this.facts;
  }

  setFacts(value: string[]): void {
    this.facts = value;
  }

  getFilters(): any[] {
    return this.filters;
  }

  setFilters(value: any[]) {
    this.filters = value;
  }
}

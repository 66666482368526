import { Injectable } from '@angular/core';
import { CmsService } from 'app/core/services/cms/cms.service';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ContentType } from '@siq-js/core-lib';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import * as _ from 'lodash';
import { CommunalShortcuts, DateRangeInterface, DateRangeInterfaceJson, DateRangeInterfaceType } from 'app/siq-forms/modules/dates/models/interfaces';

import { DateUnit, DateRangeParameter, DateMapping, WeekEndingDay } from '@siq-js/core-lib';
import { DatesServiceCore } from '@siq-js/core-lib';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { DateShortcutsService } from 'app/siq-forms/modules/dates/services/date-shortcuts.service';

interface SchemasCommunalShortcuts {
  key: string;
  communalShortcuts: CommunalShortcuts;
}

@Injectable()
export class DatesService extends DatesServiceCore {

  public static WEEK_END_PREFIX = 'week_end_';
  public static DD_HASH = '###'; // unique identifier. Prevent from being identified as DD
  public communalShortcuts$: Observable<CommunalShortcuts>;
  public dateMappings: Map<string, DateMapping>;
  public datePickerSchema$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public weekEndingDay$: BehaviorSubject<WeekEndingDay> = new BehaviorSubject<WeekEndingDay>(null);
  private schemasWEToShortcutsCache = new Map<string, CommunalShortcuts>();

  constructor(
    private http: SiqHttpService,
    private dateShortcutsService: DateShortcutsService
  ) {
    super();
    this.setupDateMappings();
    this.setupCommunalShortcutsSub();
    this.weekEndingDay$.next(this.getDefaultWeekEndingDay());
  }

  public static getDefaultDateRange(end?: Date): DateRangeInterface {
    return {
      begin: DatesService.getStartOf(DateUnit.YEAR)(end ?? new Date()),
      end: end ?? DatesService.getStartOf(DateUnit.TODAY)(null),
      dynamicBegin: null,
      dynamicEnd: null,
      type: DateRangeInterfaceType.DEFAULT
    };
  }

  public static getDefaultDate(end?: Date): DateRangeInterface {
    return {
      begin: end ?? DatesService.getStartOf(DateUnit.TODAY)(null),
      end: null,
      type: DateRangeInterfaceType.DEFAULT
    };
  }

  public static isDynamic(d: DateRangeInterface | DateRangeInterfaceJson): boolean {
    if (!d) return false;
    return (!!d.dynamicBegin && !!d.dynamicEnd);
  }

  public static isSameDate(d1: DateRangeInterface, d2: DateRangeInterface): boolean {
    if (_.isNil(d1) && _.isNil(d2)) return true;
    if (_.isNil(d1) || _.isNil(d2)) return false;
    return DatesService.isSameDateOrBothInvalid(d1.begin, d2.begin) &&
      DatesService.isSameDateOrBothInvalid(d1.end, d2.end) &&
      d1.dynamicBegin === d2.dynamicBegin &&
      d1.dynamicEnd === d2.dynamicEnd;
  }

  public static isSameDateOrBothInvalid(d1: Date, d2: Date): boolean {
    if (_.isNil(d1) && _.isNil(d2)) return true; // both are undefined or null
    if (_.isNil(d1) || _.isNil(d2)) return false; // one and only one is undefined or null
    if (!DatesService.isValidDate(d1) && !DatesService.isValidDate(d2)) return true; // both are invalid date - NaN
    if (!DatesService.isValidDate(d1) || !DatesService.isValidDate(d2)) return false; // one and only one is invalid date
    return d1.getTime() === d2.getTime(); // both are valid, compare values
  }

  public static toDateRangeInterface(dateRanges: DateRangeParameter): DateRangeInterface {
    return {
      begin: DatesService.parse(dateRanges.periodStart, AppSiqConstants._dateFormat, new Date()),
      end: DatesService.parse(dateRanges.periodEnd, AppSiqConstants._dateFormat, new Date())
    };
  }

  public static isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  public static paramify(dates: DateRangeInterface, weekEndingDay?: WeekEndingDay): DateRangeParameter {
    if (this.isDynamic(dates) && !dates.dynamicBegin.includes(this.DD_HASH) && !dates.dynamicEnd.includes(this.DD_HASH)) { // dynamic dates
      return {
        periodStart: weekEndingDay ? DatesService.getDynamicDateWithWeekEndingString(dates.dynamicBegin, weekEndingDay) : dates.dynamicBegin,
        periodEnd: weekEndingDay ? DatesService.getDynamicDateWithWeekEndingString(dates.dynamicEnd, weekEndingDay) : dates.dynamicEnd,
      };
    }
    return {
      periodStart: dates.begin? DatesService.format(dates.begin, AppSiqConstants._dateFormat) : '',
      periodEnd: dates.end? DatesService.format(dates.end, AppSiqConstants._dateFormat) : ''
    };
  }

  public static getActiveSchemas(schema: string, filters: FilterSelection[] = []): string[] {
    const envConfig = EnvConfigService.getConfig();
    const retailerFilter = filters.find(fs => fs.id === CmsService.RETAILER_FIELD_ID);
    if (schema !== envConfig?.primaryEntity) {
      // if schema is NOT primary schema, then just return it
      return [schema];
    }
    let allRetailers = envConfig.retailers;
    if (retailerFilter && retailerFilter.values) {
      // inclusive filters
      if (retailerFilter.include) return retailerFilter.values;
      // exclusive filters
      return allRetailers.filter(r => !retailerFilter.values.includes(r));
    }
    // otherwise, just return all available retailers
    return allRetailers;
  }

  public updateCommunalDateRange(schema: string, prevFilters: FilterSelection[], filters: FilterSelection[]) {
    const retailerFilter = filters?.find(fs => fs.id === CmsService.RETAILER_FIELD_ID);
    const prevRetailerFilter = prevFilters.find(fs => fs.id === CmsService.RETAILER_FIELD_ID);

    if (prevRetailerFilter && !retailerFilter) { // after removing retailer filter, reset communal date ranges
      this.datePickerSchema$.next(DatesService.getActiveSchemas(schema, []));
    }
    if (retailerFilter && retailerFilter.values) {
      this.datePickerSchema$.next(DatesService.getActiveSchemas(schema, [retailerFilter]));
    }
  }

  public getDynamicDateTranslation(dynamicString: string, retailers: Array<string> = []): Observable<HttpResponse<any>> {
    return this.http.create({
      endpoint: 'dynamicdate',
      body: {
        dateString: dynamicString,
        retailers: retailers
      },
      type: ContentType.JSON,
      suppressNotification: true,
      additionalConfig: { responseType: 'text' }
    });
  }

  public getDefaultWeekEndingDay(): WeekEndingDay {
    return EnvConfigService.getConfig()?.defaultWeekEndingDay ?? WeekEndingDay.SUN;
  }

  private setupCommunalShortcutsSub() {
    const schemas$ = this.datePickerSchema$.pipe(
      filter(schemas => !!schemas.length && !!schemas[0]), // last condition checks for undefined value in array
      map(schemas => schemas.filter(filterSchema => !_.isNil(filterSchema))));
    this.communalShortcuts$ = combineLatest([
      schemas$,
      this.weekEndingDay$
    ]).pipe(
      switchMap(([schemas, weekEndingDay]) => {
        if (weekEndingDay === WeekEndingDay.OLD_REPORT) weekEndingDay = WeekEndingDay.SAT;
        const key = schemas.toString() + weekEndingDay;
        return this.schemasWEToShortcutsCache.has(key)
          ? of({key: key, communalShortcuts: this.schemasWEToShortcutsCache.get(key)}) // use cache result
          : this.dateShortcutsService.getAvailableShortcuts(schemas, DateShortcutsService.getDynamicDateShortcuts(), weekEndingDay).pipe(map(res => ({key: key, communalShortcuts: res.body})));
      }),
      map((obj: SchemasCommunalShortcuts) => {
        if (!this.schemasWEToShortcutsCache.has(obj.key)) this.schemasWEToShortcutsCache.set(obj.key, obj.communalShortcuts); // cache result
        return obj.communalShortcuts;
      }),
      shareReplay(1)
    );
  }

  private setupDateMappings() {
    this.dateMappings = new Map<string, DateMapping>();
    const weekMapping = {
      unit: DateUnit.WEEK,
      format: '[Ending] yyyy-MM-dd',
      level: 1
    };
    this.dateMappings.set('year', { unit: DateUnit.YEAR, format: 'yyyy', level: 3 });
    this.dateMappings.set('yearmon', { unit: DateUnit.MONTH, format: 'yyyy MMMM', level: 2 });
    this.dateMappings.set('yearmonday', { unit: DateUnit.DAY, format: 'yyyy MMM do', level: 0 });
    this.dateMappings.set('week_end_mon', weekMapping);
    this.dateMappings.set('week_end_tue', weekMapping);
    this.dateMappings.set('week_end_wed', weekMapping);
    this.dateMappings.set('week_end_thu', weekMapping);
    this.dateMappings.set('week_end_fri', weekMapping);
    this.dateMappings.set('week_end_sat', weekMapping);
    this.dateMappings.set('week_end_sun', weekMapping);
  }
}

<div class="app-container-wrapper">
  <section class="app-container">

    <div class="greeting">
      <h5>
        Welcome, {{ currentUser?.firstName }}!
      </h5>
      <p>
        How would you like to explore your data?
      </p>
    </div>

    <siq-app-tiles></siq-app-tiles>
  </section>
</div>

import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CapitalizePipe } from 'app/core/pipes/capitalize.pipe';
import * as _ from 'lodash';
import { ColumnState, IHeaderAngularComp, IHeaderParams } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-header-cell-sortable',
  templateUrl: './header-cell-sortable.component.html',
  styleUrls: ['./header-cell-sortable.component.scss']
})
export class HeaderCellSortableComponent implements IHeaderAngularComp {

  public header: any;
  public sort: string;

  private colId: string;
  private params: IHeaderParams;
  private parent: any; // The component holding the grid in which this header appears
  private _unsub$: Subject<void> = new Subject<void>();
  public model: {
    header: string,
    tooltip?: string,
    sort: ColumnState['sort'],
    filterApplied: string
  } = {
    header: '',
    sort: null,
    filterApplied: null
  };

  constructor() {
  }

  agInit(params: IHeaderParams) {
    this.params = params;
    // Make pointer to "parent" param passed to custom header component via "headerComponentParams" provided by ag-grid,
    this.parent = params['parent'];
    this.colId = params.column.getId();
    this.model.header = params.displayName;

    const initSortModel = this.params.api.getColumnState().find(e => e.colId === params.column.getId());
    this.model = {
      header: params.displayName,
      sort: initSortModel ? initSortModel.sort : null,
      filterApplied: null
    };

    // If the table is not sorted through this column, set the model's sort to null
    this.parent.sort$
    .pipe(takeUntil(this._unsub$))
    .subscribe(sortModels => {
      const sortModel = _.find(sortModels, e => e.colId === this.colId);
      if (sortModel) {
        this.model.sort = sortModel.sort;
      } else {
        this.model.sort = null;
      }
    });

    // COPIED FROM VISUAL-TABLE-COLUMN-HEADER.COMPONENT.TS...
    // Update the filterApplied model based on whether this key is present in the new filter model
    this.parent.filterObs$
    .pipe(takeUntil(this._unsub$))
    .subscribe((filterModel: any) => {
      let filter = filterModel[this.colId];
      if (filter && filter.type && filter.filter) {
        this.model.filterApplied = new CapitalizePipe().transform(filter.type) + ' "' + filter.filter + '"';
      } else {
        this.model.filterApplied = null;
      }
    });
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  toggleSort() {
    let currentSort = this.model.sort;
    if (currentSort === 'desc') {
      this.model.sort = 'asc';
    } else if (currentSort === 'asc') {
      this.model.sort = null;
    } else {
      this.model.sort = 'desc';
    }

    const newSortColumnState = this.params.api.getColumnState();
    newSortColumnState.length = 0; // empty the array
    newSortColumnState.push({
      colId: this.colId,
      sort: this.model.sort
    });

    this.params.setSort(this.model.sort, false);
    this.parent.sort$.next(newSortColumnState);
  }

  showMenu(event) {
    this.params.showColumnMenu(event.target);
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { GroupedArray, GroupedArrayFunctions } from '@siq-js/core-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntil, debounceTime } from 'rxjs';
import { Subject } from 'rxjs';
import { ValidationStatus } from 'app/core/models/validation-status.enum';

type EntityType = CmsField | CmsMetric;

export interface EntitySelectorStatus<T extends EntityType> {
  valid: boolean;
  selectedEntities: T[];
}

interface FormInfo {
  value: string;
  index: number;
}

@Component({
  selector: 'siq-js-entity-selector',
  templateUrl: './entity-selector.component.html',
  styleUrls: ['./entity-selector.component.scss']
})
export class EntitySelectorComponent extends BaseSiqComponent implements OnInit, OnChanges {
  @Input() entityData: GroupedArray<EntityType>;
  @Input() allowDuplicates: boolean;
  @Input() initModel?: EntityType[];
  @Input() maxCount: number;
  @Input() minCount: number;
  @Input() placeholders: string[];

  @Output() formEmitter: EventEmitter<EntitySelectorStatus<EntityType>> = new EventEmitter<EntitySelectorStatus<EntityType>>();

  public allowAdd = false;
  public current: number;
  public entityOptions: GroupedArray<EntityType>; // Data for the dropdown. Will be updated for each input
  public form: UntypedFormGroup;
  public formArray: UntypedFormArray;
  public flattenedData: EntityType[]; // flatted data used to validate selected options after schema changes
  public initModelApplied: boolean;
  public selectedEntities: EntityType[] = []; // Array holding each user selected entity from the dropdowns
  public update$: Subject<FormInfo> = new Subject<FormInfo>();

  constructor() { super(); }

  ngOnInit(): void {
    this.initForm();
    this.update$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsub$)
      )
      .subscribe((res) => {
        this.entityOptions = GroupedArrayFunctions.filter<EntityType>(this.entityData, entity => this.filter(entity, res.value, res.index));
        this.validateForm();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Detect changes on entityData
    const curr = changes.entityData?.currentValue;
    const prev = changes.entityData?.previousValue; // Use previousValue to prevent emitting EntitySelectorStatus onInit
    if (curr && prev) {
      this.flattenedData = GroupedArrayFunctions.flatten(curr);
      this.update$.next({ value: '', index: 0 });
    }
    // Detect changes on initModel
    if (changes.initModel?.currentValue?.length) {
      this.initForm(); // re-init form to ensure the form has correct number of controls matching the length of initModel
      this.selectedEntities = changes.initModel.currentValue;
      // apply initModel to the input forms, so that initModel's values are displayed
      changes.initModel.currentValue.forEach((entity: EntityType, i: number) => {
        this.formArray.controls[i].patchValue({
          name: entity.display
        });
      });
      this.validateForm(); // validate and emit form values
    }
  }

  /**
   * Getter for FormArray. Used in template
   */
  public get selectorForms(): UntypedFormArray {
    return this.form.get('selectorForms') as UntypedFormArray;
  }

  public addEntity() {
    this.formArray.push(new UntypedFormGroup({ name: new UntypedFormControl(''), index: new UntypedFormControl(this.formArray.length) }));
    this.selectedEntities.push(null);
    this.allowAdd = false;
  }

  public removeEntity(i: number) {
    this.formArray.removeAt(i);
    this.selectedEntities.splice(i, 1);
    this.current = null;
    this.validateForm();
  }

  public onSelectOption(selected: EntityType) {
    this.selectedEntities.splice(this.current, 1, selected);
    this.validateForm();
  }

  public onFocus(i: number) {
    if (this.current !== i) { // Dropdown won't re-initialize if user clicks on the same input
      this.current = i;
      this.update$.next({ value: '', index: i });
    }
  }

  public onChange(val: string, i: number) {
    this.update$.next({ value: val, index: i });
  }

  // Reset states when user clicks the clear "x" button
  public reset(i: number) {
    this.onChange('', i);
    this.selectedEntities[i] = null;
  }

  private initForm() {
    this.formArray = new UntypedFormArray([]);
    for (let i = 0; i < this.minCount; i++) {
      this.formArray.push(new UntypedFormGroup({ name: new UntypedFormControl(''), index: new UntypedFormControl(i) }));
      this.selectedEntities.push(null);
    }
    this.form = new UntypedFormGroup({ selectorForms: this.formArray });
  }

  private filter(source: EntityType, target: string, index: number): boolean {
    if (!source.active) return false; // Filter out inactive CmsEntity
    if (source.display.toLowerCase().includes(target.toLowerCase())) {
      if (this.allowDuplicates) {
        return true;
      } else {
        const disallowedIds = this.selectedEntities.map(e => e?.id);
        disallowedIds.splice(index, 1);
        return !disallowedIds.includes(source.id);
      }
    } else {
      return false;
    }
  }

  /**
   * validateForm
   * Set each input form error status by checking if it's touched
   * Then check if the user input matches exactly with one of the value in its dropdown
   */

  private validateForm() {
    this.formArray.controls.forEach((group: UntypedFormGroup, index: number) => {
      const form = group.controls.name;
      if (form.value) {
        const entity = this.selectedEntities[index];
        if (!entity || form.value !== entity.display || this.validateEntity(entity)) {
          this.selectedEntities[index] = null;
          form.setErrors({ 'incorrect': true });
        } else {
          form.setErrors(null);
        }
      } else {
        return;
      }
    });
    this.allowAdd = this.form.status === ValidationStatus.VALID.toString();
    this.emitFormStatus();
  }

  private validateEntity(entity: EntityType): boolean {
    if (!this.flattenedData) return false;
    return this.flattenedData.filter(data => data.id === entity.id).length === 0;
  }
  /**
   * emitFormStatus
   * Emit FormStatus object to the parent component
   */
  private emitFormStatus() {
    const formStatus: EntitySelectorStatus<EntityType> = {
      valid: this.form.status === ValidationStatus.VALID.toString(),
      selectedEntities: this.selectedEntities
    };
    this.formEmitter.next(formStatus);
  }

}

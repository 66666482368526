import { Injectable } from '@angular/core';
import { Routes, Route, ActivatedRoute, UrlSegment } from '@angular/router';
import { ProfileRoutes } from 'app/profile/routes/profile-routes';
import { UserRoutes } from 'app/user/routes/user-routes';
import { FiltersetsRoutes } from 'app/filtersets/routes/filtersets-routes';
import { AppRoutes } from 'app/app-routes';
import { publicRoutes } from 'app/public/routes/public-routes';
import { SiqApplicationsRoutes } from 'app/siq-applications/routes/siq-applications-routes';
import { newUserRoutes } from 'app/core/routes/new-user-routes';
import { AccessGroupRoutes } from 'app/access-group/routes/access-group-routes';

@Injectable()
export class NavService {
  /*
   * This Service is an Injectable helper where you can access any/all routes
   * in the application by intuitive methods.
   */

  static getProfileMenuRoutes (): Routes {
    return [
      ...ProfileRoutes,
      ...UserRoutes,
      ...FiltersetsRoutes,
      ...AccessGroupRoutes
    ];
  }

  static getRoutes (): Routes {
    // All routes in the application.
    // As new [name]-routing.module.ts files are added, they should be added here as well

    return [
      ...AppRoutes,
      ...newUserRoutes,
      ...publicRoutes,
      ...ProfileRoutes,
      ...UserRoutes,
      ...AccessGroupRoutes,
      ...this.getRoutesSiqApps()
    ];
  }

  static getRoutesPublic(): Routes {
    return publicRoutes;
  }

  static getRoutesSiqApps (): Routes {
    return SiqApplicationsRoutes ? SiqApplicationsRoutes.filter(r => r.data) : [];
  }

  static getPrimaryPathFromRoot(route: ActivatedRoute): string {
    /*
     * Strange that I couldn't find a better/easier way of getting the (string) path
     * of the primary router-outlet, but this does the job.
     */
    let primarySegments: string[] = [];

    route.pathFromRoot.forEach((activatedRoute: ActivatedRoute) => {
      if (activatedRoute.outlet === 'primary' && activatedRoute.snapshot.url.length) {

        activatedRoute.snapshot.url.forEach((urlSegment: UrlSegment) => {
          primarySegments.push(urlSegment.path);
        });

      }
    });

    return primarySegments.join('/');
  }

  constructor() {

  }

  public getRouteByPath (p: string): Route {
    let out: Route = {};
    let routes: Routes = NavService.getRoutes();

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path.toLowerCase() === p.toLowerCase()) {
        out = routes[i];
        break;
      }
    }

    return out;
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'app/user/services/user.service';
import { User } from 'app/user/models/user.model';

@Component({
  selector: 'siq-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})
export class UserDeleteComponent implements OnInit {
  public submitting: boolean;
  public name: string;

  constructor(
    public dialogRef: MatDialogRef<UserDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
  ) {
    this.name = (data as User).getName();
  }

  ngOnInit() {
    this.submitting = false;
  }

  remove() {
    this.submitting = true;
    this.userService.removeUser(this.data)
      .subscribe(resp => {
        this.dialogRef.close(true);
      },
      err => {
        this.dialogRef.close();
        throw new Error(err);
      });
  }
}

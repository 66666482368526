import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatesServiceCore, WeekEndingDay } from '@siq-js/core-lib';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import {
  CommunalShortcuts,
  DynamicDateShortcut,
  DynamicDateShortcutParam
} from 'app/siq-forms/modules/dates/models/interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class DateShortcutsService {

  public static findDynamicDateShortCut(scList: DynamicDateShortcut[], model: {in: string, out: string}): DynamicDateShortcut {
    return scList.find(sc => {
      return sc.in === model.in && sc.out === model.out;
    });
  }

  public static getDynamicDateShortcuts(): DynamicDateShortcut[] {
    // id must match index for proper functionality of DatePickerComponent
    return [
      {
        id: 0,
        name: 'Last Week',
        in: '-1 WEEK START OF WEEK',
        out: '-1 WEEK END OF WEEK',
        disabled: true
      },
      {
        id: 1,
        name: 'Last 4 Weeks',
        in: '-4 WEEK START OF WEEK',
        out: '-1 WEEK END OF WEEK',
        disabled: true
      },
      {
        id: 2,
        name: 'Last 8 Weeks',
        in: '-8 WEEK START OF WEEK',
        out: '-1 WEEK END OF WEEK',
        disabled: true
      },
      {
        id: 3,
        name: 'Last 13 Weeks',
        in: '-13 WEEK START OF WEEK',
        out: '-1 WEEK END OF WEEK',
        disabled: true
      },
      {
        id: 4,
        name: 'Last 26 Weeks',
        in: '-26 WEEK START OF WEEK',
        out: '-1 WEEK END OF WEEK',
        disabled: true
      },
      {
        id: 5,
        name: 'Last 52 Weeks',
        in: '-52 WEEK START OF WEEK',
        out: '-1 WEEK END OF WEEK',
        disabled: true
      },
      {
        id: 6,
        name: 'Last Month',
        in: '-1 MONTH START OF MONTH',
        out: '-1 MONTH END OF MONTH',
        disabled: true
      },
      {
        id: 7,
        name: 'Last Quarter',
        in: '-1 QUARTER START OF QUARTER',
        out: '-1 QUARTER END OF QUARTER',
        disabled: true
      },
      {
        id: 8,
        name: 'Last Year',
        in: '-1 YEAR START OF YEAR',
        out: '-1 YEAR END OF YEAR',
        disabled: true
      },
      {
        id: 9,
        name: 'Year-to-Date (YTD)',
        in: 'START OF YEAR',
        out: '-0 DAY',
        disabled: true
      },
      {
        id: 10,
        name: 'Quarter-to-Date (QTD)',
        in: 'START OF QUARTER',
        out: '-0 DAY',
        disabled: true
      },
      {
        id: 11,
        name: 'Month-to-Date (MTD)',
        in: 'START OF MONTH',
        out: '-0 DAY',
        disabled: true
      },
      {
        id: 12,
        name: 'Week-to-Date (WTD)',
        in: 'START OF WEEK',
        out: '-0 DAY',
        disabled: true
      },
    ];
  }

  constructor(private http: SiqHttpService) {}

  public getAvailableShortcuts(retailers: string[], shortcuts: DynamicDateShortcut[], weekEndingDay: WeekEndingDay): Observable<HttpResponse<CommunalShortcuts>> {
    const scParam = shortcuts.map(sc => {
      return {
        id: sc.id,
        dynamicDateStart: DatesServiceCore.getDynamicDateWithWeekEndingString(sc.in, weekEndingDay),
        dynamicDateEnd: DatesServiceCore.getDynamicDateWithWeekEndingString(sc.out, weekEndingDay),
      } as DynamicDateShortcutParam;
    });
    return this.http.create({
      endpoint: 'dynamic-date-config',
      body: {
        retailers: retailers,
        shortcuts: scParam
      },
      suppressNotification: true,
    });
  }

  /**
   * Gets dynamic date ranges /api/dynamic-date-config?retailer=kg&.....
   * Reads actual date range from BE ICE-1600, so information from date picker icon will match report data
   * @param retailers
   */
  public getDateRanges(retailers: string[]): Observable<HttpResponse<any>> {
      let retailersParams = '';
      retailers.forEach(oneRetailer => {
        retailersParams += '&retailer=' + oneRetailer;
      });
      retailersParams = retailersParams.replace('&', '?');
      return this.http.get({
        endpoint: 'dynamic-date-config' + (retailersParams ? retailersParams : ''),
        suppressNotification: true,
      });
  }

}

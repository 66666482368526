import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/auth/services/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessGroupAdminGuard implements CanActivate {

  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Check if user is in Admin Access Group
    if (!AuthService.CurrentUser$.getValue()) return false;

    const isAdmin = await AuthService.CurrentUser$.getValue().isAdmin();
    if (!isAdmin) {
      return this.router.createUrlTree(['/unauthorized']);
    } else {
      return true;
    }
  }

}

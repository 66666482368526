import { Routes } from '@angular/router';
import { ApplicationHash } from '@siq-js/core-lib';
import { AppGuard } from 'app/guards/app-guard';
import { AuthGuard } from 'app/guards/auth-guard';
import { PromoFormComponent } from 'app/siq-applications/modules/promo/components/promo-form/promo-form.component';
import { PromoListComponent } from 'app/siq-applications/modules/promo/components/promo-list/promo-list.component';
import { PromoResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/promo-result.component';
import { PromoCloneResolverService } from 'app/siq-applications/modules/promo/services/promo-clone-resolver.service';

export const promoRoutes: Routes = [
  {
    path: 'app/measurement',
    canActivate: [
      AppGuard,
      AuthGuard
    ],
    data: {
      applicationHash: ApplicationHash.PROMO
    },
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: PromoListComponent
          }
        ]
      },
      {
        path: '~',
        children: [
          {
            path: '',
            component: PromoFormComponent
          }
        ]
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: PromoResultComponent
          },
          {
            path: 'edit',
            component: PromoFormComponent,
            data: {
              edit: true
            }
          },
          {
            path: 'clone',
            resolve: { nothing: PromoCloneResolverService},
            children: []
          },
          {
            path: 'export',
            component: PromoResultComponent
          }
        ]
      }
    ]
  }
];

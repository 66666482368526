import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModel } from 'app/core/models/base-model';

export class FavoritesParameters extends BaseModel {
  public description: string;
  public name: string;

  constructor(form?: UntypedFormGroup) {
    super();

    this.description = '';
    this.name = '';

    // Load in all relevant values from form
    if (form) {
      this.toParam(form);
    }
  }

  getDescription(): string {
    return this.description;
  }

  getName(): string {
    return this.name;
  }

  toForm(formBuilder: UntypedFormBuilder) {
    return formBuilder.group({
      description: [' '],
      name: [' ', Validators.required]
    });
  }

  toParam(form: UntypedFormGroup) {
    this.description = form.value['description'];
    this.name = form.value['name'];
  }

}

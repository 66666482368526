import { AgRendererComponent } from '@siq-js/visual-lib';
import { Component, Injector } from '@angular/core';
import { TakeRateResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-result/take-rate-result.component';

@Component({
  selector: 'siq-js-promo-takerate-drilldown-renderer',
  templateUrl: './promo-takerate-drilldown-renderer.component.html',
  styleUrls: ['./promo-takerate-drilldown-renderer.component.scss']
})
export class PromoTakerateDrilldownRendererComponent implements AgRendererComponent {

  public static readonly CELL_CLASS = 'no-padding';
  public static readonly TOTAL_KEY = 'TOTAL';

  public dimValue: string;
  public parent: TakeRateResultComponent;

  constructor(
    injector: Injector
  ) {
    this.parent = injector.get(TakeRateResultComponent);
  }

  agInit(params) {
    if (params.node.isRowPinned()) {
      this.dimValue = PromoTakerateDrilldownRendererComponent.TOTAL_KEY;
    } else {
      this.dimValue = params.value;
    }
  }

  refresh(params: any): boolean {
    return true;
  }

  public drilldown() {
    this.parent.drilldown(this.dimValue);
  }

}

import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrequencyMonthComponent } from 'app/activity/modules/sharing/components/frequency-month/frequency-month.component';
import { FrequencyWeekComponent } from 'app/activity/modules/sharing/components/frequency-week/frequency-week.component';
import { NgModule } from '@angular/core';
import { RecipientListComponent } from 'app/activity/modules/sharing/components/recipient-list/recipient-list.component';
import { ScheduleDetailComponent } from 'app/activity/modules/sharing/components/schedule-detail/schedule-detail.component';
import { ScheduleEndsComponent } from 'app/activity/modules/sharing/components/schedule-ends/schedule-ends.component';
import { ScheduleFrequencyComponent } from 'app/activity/modules/sharing/components/schedule-frequency/schedule-frequency.component';
import { ScheduleReportComponent } from 'app/activity/modules/sharing/components/schedule-report/schedule-report.component';
import { ScheduleTimeComponent } from 'app/activity/modules/sharing/components/schedule-time/schedule-time.component';
import { ShareReportComponent } from 'app/activity/modules/sharing/components/share-report/share-report.component';
import { SharingModalComponent } from 'app/activity/modules/sharing/components/sharing-modal/sharing-modal.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { TitleCriteriaComponent } from 'app/activity/modules/sharing/components/title-criteria/title-criteria.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    SharingModalComponent,
    ShareReportComponent,
    RecipientListComponent,
    TitleCriteriaComponent,
    ScheduleReportComponent,
    ScheduleDetailComponent,
    ScheduleTimeComponent,
    ScheduleEndsComponent,
    ScheduleFrequencyComponent,
    FrequencyWeekComponent,
    FrequencyMonthComponent
  ],
  exports: [
    SharingModalComponent
  ],
  providers: [
    SharingService
  ]
})
export class SharingModule { }

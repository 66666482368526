<mat-dialog-content *ngIf="model">

  <div class="yoy-header">
    <mat-checkbox [(ngModel)]="model.all" class="all-checkboxes" (change)="toggleAll()" color="accent">Select All</mat-checkbox>

    <siq-js-date-selection
      *ngIf="model.compDateRange && !model.locked"
      [config]="dateSelectionConfig"
      [initModel]="model.compDateRange"
      [disableInitModelValidation]="true"
      (onDateChanged)="updateDate($event)"
    ></siq-js-date-selection>

    <span class="date-range-info">
      <mat-icon [matTooltip]="model.tooltip">info</mat-icon>
      <i class="date-range-locked" *ngIf="model.locked">Date Range Locked</i>
    </span>
  </div>

  <div class="date-range-msg">
    <mat-divider></mat-divider>
    <mat-icon *ngIf="!model.locked"
              class="date-range-reset" matTooltip="Reset Comparative Date Range to default"
              (click)="resetDateRange()"
    >sync</mat-icon>
    <i>Comp. Date Range: </i>
    <b>{{ model.dateRangeStr }}</b>
  </div>

  <mat-divider></mat-divider>
  <mat-checkbox [(ngModel)]="model.ty" class="single-checkbox" (change)="updateAll()" color="accent">This Year</mat-checkbox>
  <mat-checkbox [(ngModel)]="model.ly" class="single-checkbox" (change)="updateAll()" color="accent">Last Year</mat-checkbox>
  <mat-checkbox [(ngModel)]="model.lyd" class="single-checkbox" (change)="updateAll() "color="accent">This Year vs. Last Year</mat-checkbox>
  <mat-checkbox [(ngModel)]="model.lypd" class="single-checkbox" (change)="updateAll() "color="accent">% &Delta; This Year vs. Last Year</mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="null" color="accent" class="secondary-button">Cancel</button>
  <button mat-raised-button (click)="apply()" color="accent" [disabled]="!isDateRangeValid">Apply</button>
</mat-dialog-actions>

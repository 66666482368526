<mat-toolbar
  *ngIf="currentUser?.token && visible.primary"
  class="siq-primary-navbar"
>

  <button mat-icon-button (click)="toggleSidenav()">
    <mat-icon class="mat-24" aria-label="Show menu">menu</mat-icon>
  </button>

  <a [routerLink]="'/welcome'" class="logo">
    <img src="{{ headerImageUrl }}"
      alt="PDI Technologies"
    />
  </a>

  <span class="platform">Insights Cloud</span>

  <span class="fill-space"></span>
  <siq-activity-menu></siq-activity-menu>
  <siq-nav-support></siq-nav-support>
  <siq-nav-profile></siq-nav-profile>

</mat-toolbar>

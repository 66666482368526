import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiqInternalRoutingModule } from 'app/siq-internal/routes/siq-internal-routing.module';
import { AboutComponent } from 'app/siq-internal/components/about/about.component';
import { AppActivitySqlComponent } from 'app/siq-internal/components/app-activity-sql/app-activity-sql.component';
import { AceModule } from 'ngx-ace-wrapper';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';

@NgModule({
  imports: [
    CommonModule,
    AceModule,
    SiqMaterialModule,
    SiqInternalRoutingModule,
    DatesModule
  ],
  declarations: [
    AboutComponent,
    AppActivitySqlComponent
  ]
})
export class SiqInternalModule { }

import { YearFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/year-filter/year-filter.component';
import { YearMonthFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/year-month-filter/year-month-filter.component';
import { YearMonthDayFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/year-month-day-filter/year-month-day-filter.component';
import { MonthFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/month-filter/month-filter.component';
import { DayOfWeekFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/day-of-week-filter/day-of-week-filter.component';
import { DayFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/day-filter/day-filter.component';
import { HourFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/hour-filter/hour-filter.component';
import { PercentageFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/percentage-filter/percentage-filter.component';
import { FormattedNumberFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/formatted-number-filter/formatted-number-filter.component';
import { DaypartFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/daypart-filter/daypart-filter.component';

export enum GridFilterComponents {
  yearFilter = <any>YearFilterComponent,
  yearMonthFilter = <any>YearMonthFilterComponent,
  yearMonthDayFilter = <any>YearMonthDayFilterComponent,
  monthFilter = <any>MonthFilterComponent,
  dayOfWeekFilter = <any>DayOfWeekFilterComponent,
  dayFilter = <any>DayFilterComponent,
  hourFilter = <any>HourFilterComponent,
  percentageFilter = <any>PercentageFilterComponent,
  formattedNumberFilter = <any>FormattedNumberFilterComponent,
  daypartFilter = <any>DaypartFilterComponent
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccessGroupRoutes } from 'app/access-group/routes/access-group-routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AccessGroupRoutes)
  ],
  exports: [ RouterModule ]
})
export class AccessGroupRoutingModule { }

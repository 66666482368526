<div class="inset-wrapper">
  <h5>Underlying SQL Query for App Activity: {{ id }} </h5>
  <br/>

  <mat-tab-group>
    <mat-tab *ngFor="let job of jobs; let index = index" [label]="'Job ' + index">
      <mat-dialog-content class="siq-ace-wrapper">
        <ace [ace]="config"></ace>
      </mat-dialog-content>
      <br/>
      <button mat-raised-button class="clipboard" [attr.data-clipboard-text]="job">Copy to clipboard</button>
    </mat-tab>
  </mat-tab-group>

</div>

import { Component } from '@angular/core';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';

@Component({
  selector: 'siq-nav-support',
  templateUrl: './nav-support.component.html',
  styleUrls: ['./nav-support.component.scss']
})
export class NavSupportComponent {

  constructor(private http: SiqHttpService) { }

  saveUserGuide () {
    this.http.saveFile('pdf/QuickStartGuide.pdf');
  }

}

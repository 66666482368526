import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { QueryModeModalConfig } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { PromoQueryModeModalComponent } from 'app/siq-applications/modules/promo/components/promo-query-mode-modal/promo-query-mode-modal.component';
import { PromoFormData, PromoFormJson } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import * as _ from 'lodash';

@Component({
  selector: 'siq-js-promo-form',
  templateUrl: './promo-form.component.html',
  styleUrls: ['./promo-form.component.scss']
})
export class PromoFormComponent extends BaseSiqComponent implements OnInit {

  public formData: PromoFormData;
  public accessGroupChanged = false;
  public editMode = false;
  public overrideSchema: string;


  @ViewChild('queryMode') queryMode: QueryModeComponent; // component for controlling current schema

  public queryModeModalConfig: QueryModeModalConfig = { // config for query modal when schema changes
    component: PromoQueryModeModalComponent,
    dataGetter: () => this.formData,
    diffFn: (schema: string) => !this.formData.isValidSchema(schema)
  };
  constructor(
    public config: PromoConfig,
    private promoService: PromoService,
    private route: ActivatedRoute, 
    private mixpanelService: MixpanelService
  ) {
    super();
  }

  public ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const promoId = params.get('id');
      if (promoId) {
        this.editMode = true;
        this.promoService.getReport(promoId).subscribe(activity => {
          const formValues: PromoFormJson  = _.cloneDeep(JSON.parse(activity.formValues));
          formValues.name = activity.metaData["name"];
          this.formData = this.promoService.createForm(formValues);
          this.formData.id = promoId; 
          this.formData.isCloned = true; // used to signify also edit mode
          this.accessGroupChanged = activity.accessGroupChanged;
          this.overrideSchema = this.formData.schema;
          this.ready();
        });
      } else {
        this.editMode = false;
        if (this.promoService.clonedActivity) { // if this is a cloned form, use the cloned data
          const formValues: PromoFormJson  = _.cloneDeep(JSON.parse(this.promoService.clonedActivity.formValues));
          formValues.name = this.promoService.clonedActivity.getName() + '(copy)';
          formValues.isCloned = true;
          this.accessGroupChanged = this.promoService.clonedActivity.accessGroupChanged;
          this.formData = this.promoService.createForm(formValues);

          this.mixpanelService.track(MixpanelEvent.REPORTS_CLONED, {
            'Application': this.config.getApplication().display,
            'Cloned Activity ID': this.promoService.clonedActivity.getId(),
            'Name': this.formData.name,
            'JSON': this.formData.toJson(),
            'Type': 'Report'
          });

        } else { // otherwise create new formData
          this.formData = new PromoFormData();
        }
        this.overrideSchema = this.formData.schema;
        this.ready();
      }
    });
  }

}

<div class="inset-wrapper">

  <h5>
    About This Version
  </h5>

  <p>Current Environment: {{projectId}}</p>

  <br/>

  <pre>{{ env | json }}</pre>

</div>

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsConfig, CmsField } from '@siq-js/cms-lib';
import { BulkSearchParams, BulkSearchResponse, SearchParams } from 'app/filter/models/async-search';
import { Filter, FilterSettingsJson } from 'app/filter/models/filter';
import { FilterValue } from 'app/filter/models/filter-value';
import { AppSiqConstants } from 'app/core/models/constants/app-constants';
import { ContentType, CrudConfig } from '@siq-js/core-lib';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap, toArray } from 'rxjs';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { SiqFilter } from 'app/filter/models/interfaces';
import { CmsService } from 'app/core/services/cms/cms.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import * as _ from 'lodash';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'app/auth/services/auth.service';
import { BaseFilterGroupComponent } from 'app/filter/components/base-filter-group/base-filter-group.component';

@Injectable()
export class FilterService {

  public static readonly loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private static readonly ENDPOINT = 'filtervalues';
  private static filters: Filter[] = [];
  private readonly CONCURRENT_REQUESTS = 4; // max concurrent requests. Set to 4 because GAE one instance allow max 4 concurrent requests before auto scaling.

  public static convertSiqFilterToFilterSelection(f: SiqFilter): FilterSelection {
    let values: any[];
    let include: boolean;
    let nulls: boolean;

    if (f.in.length) {
      values = f.in;
      include = true;
      nulls = f.inNull;
    } else if (f.out.length) {
      values = f.out;
      include = false;
      nulls = f.outNull;
    } else {
      values = [];
      include = f.inNull;
      nulls = f.inNull;
    }

    const id = f.r === CmsConfig.CORE_SCHEMA_ID ? f.n : f.r + '|' + f.ta + '|' + f.n;

    return new FilterSelection({
      id: id,
      include: include,
      nulls: nulls,
      values: values
    });
  }

  public static getAllFilters(): Filter[] {
    return this.filters;
  }

  public static getAvailableFilters(currentSchema: string): CmsField[] {
    const schemas = CmsService.getValidSchemas(currentSchema);
    const primaryEntity = EnvConfigService.getConfig().primaryEntity;
    let availableFilters: CmsField[] = CmsService.get().fields
    .filter(f => {
      if (!f.active || !f.filter || f.filter === 'DATE') return false;
      if (f.filter === 'RETAILER' && currentSchema !== primaryEntity) return false;
      return schemas.includes(f.retailer);
    });
    return availableFilters;
  }

  public static getFilter(id: string): Filter {
    let filter: Filter = this.filters.find(f => f.id === id);
    if (!filter) {
      /**
       * Backend hard-coded (non-dynamic) dims do not have an associated filter with same id.
       * Instead these are set to point to another existing filter, so use the "filter" field
       * as the fallback in this case.
       */
      const cmsField: CmsField = CmsService.get().findEntity<CmsField>(id);
      let tempId = id.substring(0, id.lastIndexOf('|') + 1) + cmsField.filter.toLowerCase();
      filter = this.filters.find(f => f.id === tempId);
    }
    return filter;
  }

  public static mapNToD = (n: string): string => {
    return n === 'prod_upc_desc' ? 'description' : n;
  }

  public static purgeFilterValues(filterComponent: BaseFilterGroupComponent, filters: FilterSelection[]) {
    let needRefresh = false;
    const newFilters: FilterSelection[] = [];
    filters.forEach(fs => {
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        fs.values = fs.values.filter(val => {
          if (retailers.includes(val)) {
            return true;
          } else {
            needRefresh = true;
            return false;
          }
        });
        if (fs.values.length > 0) {
          newFilters.push(_.cloneDeep(fs));
        }
      }
    });

    if (needRefresh) {
      filterComponent.setModel(newFilters);
    }
  }

  constructor(
    private siqHttp: SiqHttpService,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) { }

  public bulkSearchAsync(id: string, searchStrings: string[], params: BulkSearchParams, cache = true): Observable<BulkSearchResponse> {
    // Retrieve FilterValue[] from cache to merge with the response data
    const matchedCachedFV: FilterValue[] = [];
    const lookupIdList = [];

    searchStrings.forEach(str => {
      // Apply cache
      const cacheVal = FilterService.getFilter(id).values.getDisplay(str);
      if (cacheVal) {
        matchedCachedFV.push({ n: str, d: cacheVal });
      } else {
        lookupIdList.push(str);
      }
    });
    // Perform req if there's no cached values
    if (lookupIdList.length) {
      params = <BulkSearchParams>this.tempAdjustSearchFields(params);
      const crudConfig: CrudConfig = {
        body: { ...params, lookupIdList: lookupIdList },
        endpoint: `https://cloudsql-dot-${AppSiqConstants.environment.projectId}.appspot.com/api/bulklookup`,
        fullURL: true,
        suppressNotification: true,
        type: ContentType.JSON
      };

      return this.siqHttp.create(crudConfig).pipe(
        tap((res: HttpResponse<BulkSearchResponse>) => {
          if (cache) {
            this.addToCache(id, res.body.matchedIdList);
          }
        }),
        map((res: HttpResponse<BulkSearchResponse>) => {
          return {
            missingIdList: res.body.missingIdList,
            matchedIdList: [...matchedCachedFV, ...res.body.matchedIdList]
          };
        })
      );
    } else {
      return of({
        missingIdList: [],
        matchedIdList: [...matchedCachedFV]
      });
    }
  }

  public retrieveData(retailers: string[]): Observable<boolean> {

    this.batchFetchData(retailers);
    return FilterService.loaded$;

  }

  public search(id: string, params: SearchParams, cache = true): Observable<FilterValue[]> {
    params = <SearchParams>this.tempAdjustSearchFields(params);
    const crudConfig: CrudConfig = {
      body: params,
      endpoint: `https://cloudsql-dot-${AppSiqConstants.environment.projectId}.appspot.com/api/lookup`,
      fullURL: true,
      suppressNotification: true,
      type: ContentType.JSON
    };

    return this.siqHttp.create(crudConfig)
      .pipe(
        tap((res: HttpResponse<FilterValue[]>) => cache && this.addToCache(id, res.body)),
        map((res: HttpResponse<FilterValue[]>) => res.body)
      );
  }

  private addToCache(filterId: string, filterValues: FilterValue[]) {
    const filter = FilterService.getFilter(filterId);
    if (filter) {
      filterValues.forEach(fv => filter.values.put(fv));
    }
  }
  private batchFetchData(retailers: string[]) {
    // The "cloud-export" code will add the class "puppeteer-cloud-export" to the <head> element when it runs
    if (window.navigator.webdriver && window.document.querySelector('head').className === 'puppeteer-cloud-export') {
      // This is being called from Puppeteer; No need to load filters, but set "FilterService.loaded$" value to true
      FilterService.loaded$.next(true);
      return;
    }
    const missingRetailers = [];
    from(retailers).pipe(
      mergeMap((retailer) => this.getData(retailer, missingRetailers), this.CONCURRENT_REQUESTS),
      toArray(),
    )
    .subscribe((fvResponseArr: { filters: FilterSettingsJson[] }[]) => {
      this.setFilterData(retailers, fvResponseArr, missingRetailers);
      FilterService.loaded$.next(true); // final step to open the platform
    }, (error) => {
      console.error('ERROR! %O', error);
      // An error here will prevent the platform from loading, so maybe have to redirect or show message(?)
    });
    }
  private getData(id: string, missingRetailers: string[]) {
    const endpoint = FilterService.ENDPOINT + `/${id}`;
    return this.siqHttp.get({endpoint: endpoint}).pipe(
      filter(dto => {
        if (_.isNil(dto)) {
          missingRetailers.push(id);
        }
        return !!dto;
      }),
      map(dto => dto.url),
      mergeMap(url => this.http.get(url), this.CONCURRENT_REQUESTS),
    );
  }

  private setFilterData(retailers: string[], fvResponseArr: { filters: FilterSettingsJson[] }[], missingRetailers: string[]) {
    // Check for any missing retailers
    if (missingRetailers.length) {
      const navigationExtras: NavigationExtras = {state: {entities: missingRetailers}};
      this.authService.logout().subscribe(() => this.router.navigate(['/maintenance/data'], navigationExtras));
    }

    // add each filter value set to the FilterValueCache
    fvResponseArr.forEach(fvResponse => {
      fvResponse.filters.forEach(json => {
        const _filter = new Filter(json);
        FilterService.filters.push(_filter);
      });
    });

  }

  /**
   *
   * @param params
   * @private
   * Temporary function for ICE-1440 and ICE-1442
   * This function (and calls to it) can be removed in the future when the BE is adjusted to handle this situation.
   */
  private tempAdjustSearchFields(params: SearchParams|BulkSearchParams): (SearchParams|BulkSearchParams) {
    params.d = params.d.replace('prod_', '');
    params.n = params.n.replace('prod_', '');
    return params;
  }

}

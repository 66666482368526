import { Component, ElementRef, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Route, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { ForgotPasswordComponent } from 'app/public/components/forgot-password/forgot-password.component';
import { User } from 'app/user/models/user.model';
import { AuthService } from 'app/auth/services/auth.service';
import { NavService } from 'app/core/services/nav/nav.service';
import { UserService } from 'app/user/services/user.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import * as _ from 'lodash';
import { take, takeUntil } from 'rxjs';
import { NewUserService } from 'app/core/services/new-user/new-user.service';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent extends BaseSiqComponent implements OnInit, AfterViewInit {

  @ViewChild('inputEmail') inputEmail: ElementRef;

  public resetRoute: Route;
  public loading = false;
  public errorMessage = '';
  public invalidEmailMessage = '';
  public lightTheme = true;

  private readonly API_USER_DOWNLOAD_PATH = 'api/user/download';
  private model: {
    username?: string;
    password?: string;
  } = {};
  private returnUrl: string;
  private queryParams: Params;
  private readonly RETURN_URL = 'returnUrl';

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    super();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputEmail.nativeElement.focus();
    });
  }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.queryParams[this.RETURN_URL] ? decodeURI(this.activatedRoute.snapshot.queryParams[this.RETURN_URL]) : null;
    if (url) {
      this.returnUrl = UtilsService.scrubUrl(url);
      this.queryParams = UtilsService.rebuildParams(url);
    }

    this.resetRoute = _.find(NavService.getRoutesPublic(), r => r.component === ForgotPasswordComponent);

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      // Save to public var; used to show correct image in template
      this.lightTheme = theme === ThemesService.THEMES.LIGHT;
    });
  }

  login () {
    /*
    * Helpful page:
    * https://scotch.io/tutorials/angular-2-http-requests-with-observables
     */

    this.loading = true;

    let loginOperation: Observable<User>;

    loginOperation = this.authService.login(this.model.username, this.model.password);

    loginOperation.subscribe(
      user => {
      if (user.passwordExpired) {
        this.navigateToAfterLogin('/' + NewUserService.defaultPath);
      } else if (this.returnUrl) {
        // Navigates to the URL the user was redirected from (rather then the homepage or default)
        this.navigateToAfterLogin(this.returnUrl);
      } else {
          // Navigates to the homepage url, if set, otherwise to the default path
          this.userService.getDefaultUrl().pipe(
            take(1)
          ).subscribe((url: string) => {
            this.navigateToAfterLogin(url);
          });
        }
      },
      err => {
        // Sets the error message to display an invalid credentials warning
        /* This assumes any error is related to invalid credentials regardless what
           actually caused the issue to occur (network, internal error, etc.)      */
        this.errorMessage = 'Invalid credentials provided.';
        setTimeout(() => { this.loading = false; }, 500);
      });
  }

  /* Function:      navigateToAfterLogin
   * Description:   Navigates to a particular page after successfully login.
   * Parameters(s): url[string]: The url to navigate to.
   * Return:
   * Error(s):
   */
  navigateToAfterLogin (url: string, params: Params = {}): void {
    if (url.startsWith('/')) {
      // internal/absolute
      this.router.navigate([url], { queryParams: this.queryParams })
      .then(resp => {
        if (!resp) {
          // The router.navigate failed. This can happen upon logging in if user has no AccessGroup and navigation gets blocked by the AuthGuard.
          this.loading = false;
        }
      });
    } else {
      // Navigate to external URL
      let querystring = '';
      if (this.queryParams) {
        Object.keys(this.queryParams).forEach((k, i) => {
          const prefix = i === 0 ? '?' : '&';
          querystring = prefix + k + '=' + this.queryParams[k];
        });
      }

      /*
      In the case of a user not logged in who attempts to download a file (via a link from the share/schedule email),
      the user will get redirected to the "/login?returnUrl=xxxxx" screen. In this case the "returnUrl" will have already
      been encoded by the BE. Some special handling is required here because the Angular Router itself seems to automatically
      decode the url (including the querystring) but the decoding does not match exactly what is expected.

      Through testing I was able to determine the originally encoded "returnUrl" was still accessible in the Angular Router
      but only through the RouterStateSnapshot (router.routerState.snapshot). This is used here and the value of "returnUrl"
      is taken directly from the this.router.routerState.snapshot.url property. We know it is encoded, and after testing it is the
      "decodeURIComponent" function that produces the exact correct decoded URI needed to trigger the download successfully.
      */
      let encodedReturnUrl = null;
      if (url.includes(this.API_USER_DOWNLOAD_PATH)) {
        encodedReturnUrl = this.router.routerState.snapshot.url.substring(this.router.routerState.snapshot.url.indexOf(this.RETURN_URL + '=') + 10);
      }

      const urlToOpen = encodedReturnUrl ? decodeURIComponent(encodedReturnUrl) : (url + querystring);
      <Window>window.open(urlToOpen); // opens url in new window/tab (triggers the download...)
      this.loading = false;
      this.queryParams = null;
      // Navigates to the homepage url, if set, otherwise to the default path
      this.userService.getDefaultUrl().pipe(
        take(1)
      ).subscribe((_url: string) => {
        this.navigateToAfterLogin(_url);
      });
    }
  }

  /* Function:      setEmailErrorMessage(target)
   * Description:   Accepts an email input element and will set the invalidEmailMessage
   *                variable with the correct message.
   * Parameters(s): target [element(type: email)]: The dom element to evaluate.
   * Return:
   * Error(s):      TypeError: Thrown when the input type is not a email field.
   */
  setEmailErrorMessage(event: KeyboardEvent) {
    this.invalidEmailMessage = UtilsService.validateEmailField(event.target);
  }
}

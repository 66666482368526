import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AgGridAngular,
  ColDef,
  GridOptions,
  GridReadyEvent,
  GridService,
  RowDataTransaction,
  GridApi
} from '@siq-js/visual-lib';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { Activity, ActivityStatus } from 'app/activity/models/activity.model';
import { Router } from '@angular/router';
import { ActivityService } from 'app/activity/services/activity.service';
import { AppComponent } from 'app/app.component';
import { debounceTime, take, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { ActivityGridComponent } from 'app/activity/components/activity-grid/activity-grid.component';
import { ActivityTab } from 'app/activity/models/interfaces';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ApplicationHash } from '@siq-js/core-lib';
import { ReportBuilderCriteriaRendererComponent } from 'app/siq-applications/modules/report-builder/components/report-builder-criteria-renderer/report-builder-criteria-renderer.component';
import { ActivityColumnHeaderRendererComponent } from 'app/activity/components/renderers/activity-column-header-renderer/activity-column-header-renderer.component';
import { UsesCheckboxRenderer } from 'app/core/components/cell-renderers/checkbox-renderer/uses-checkbox-renderer';
import { BaseSiqComponent, NotificationService, ThemesService } from '@siq-js/angular-buildable-lib';
import { CheckboxRendererComponent } from 'app/core/components/cell-renderers/checkbox-renderer/checkbox-renderer.component';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { DaypartsCriteriaRendererComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-criteria-renderer/dayparts-criteria-renderer.component';
import { AffinitiesCriteriaRendererComponent } from 'app/siq-applications/modules/affinities/components/affinities-criteria-renderer/affinities-criteria-renderer.component';
import { IncrementalsCriteriaRendererComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-criteria-renderer/incrementals-criteria-renderer.component';
import { PromoCriteriaRendererComponent } from 'app/siq-applications/modules/promo/components/renderers/promo-criteria-renderer/promo-criteria-renderer.component';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';

export enum ActivityTableFilter {
  ALL,
  OPENED,
  UNOPENED,
  FAVORITE
}

@Component({
  selector: 'siq-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent extends BaseSiqComponent implements OnInit, OnDestroy, UsesCheckboxRenderer<Activity> {

  private static applyActivityTableFilter(activities: Activity[], key: ActivityTableFilter): Activity[] {
    switch (key) {
      case ActivityTableFilter.FAVORITE:
        return activities.filter(a => a.isFavorite());
      case ActivityTableFilter.OPENED:
        return activities.filter(a => a.isViewed());
      case ActivityTableFilter.UNOPENED:
        return activities.filter(a => !a.isViewed());
      default:
        return activities;
    }
  }

  public get tabKeys() {
    return ActivityTabKey;
  }

  @ViewChild('activityTableGrid') grid!: AgGridAngular;

  @ViewChild('grid', { static: true }) _gridInstance;
  @Input() public activityList$: BehaviorSubject<Activity[]>;
  public agTheme: string;
  // UsesCheckBoxRenderer interface fields
  public gridOptions: GridOptions;
  public gridApi: GridApi;

  public numSelectedIds: number;
  @Input() public parent: ActivityGridComponent;
  public prevFilteredActivities: Activity[] = [];
  public renderedActivities: Activity[]; // local synchronous copy of the last rendered activities
  public selectedIds$: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set<string>());
  public sort$: Subject<void> = new Subject();
  @Input() public tab: ActivityTab;

  private activeFilter$: BehaviorSubject<ActivityTableFilter> = new BehaviorSubject<ActivityTableFilter>(ActivityTableFilter.ALL);

  constructor(
    private router: Router,
    private activityService: ActivityService,
    private mixpanelService: MixpanelService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public applyTableFilter(f: ActivityTableFilter) {
    if (this.activeFilter$.getValue() !== f) {
      this.activeFilter$.next(f);
    }
  }

  getId(a: Activity): string {
    return a.getId();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sort$.complete();
    this.selectedIds$.complete();
  }

  ngOnInit() {
    this.selectedIds$
      .pipe(takeUntil(this.unsub$))
      .subscribe(ids => this.numSelectedIds = ids.size);

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      this.agTheme = GridService.getGridThemeName(theme);
    });

    this.gridOptions = {
      sideBar: false,
      headerHeight: 52,
      rowHeight: 58,
      suppressContextMenu: true,
      domLayout: 'autoHeight',
      suppressCellFocus: true,
      columnDefs: this.getColDefs(),
      components: {
        [ApplicationHash.REPORT_BUILDER.toString()]: ReportBuilderCriteriaRendererComponent,
        [ApplicationHash.DAYPARTS.toString()]: DaypartsCriteriaRendererComponent,
        [ApplicationHash.AFFINITIES.toString()]: AffinitiesCriteriaRendererComponent,
        [ApplicationHash.INCREMENTALS.toString()]: IncrementalsCriteriaRendererComponent,
        [ApplicationHash.PROMO.toString()]: PromoCriteriaRendererComponent
      },
      getRowId: (params) => {
        return (params.data as Activity).getId(true);
      },
      onSortChanged: (event) => this.sort$.next(),
      onRowClicked: (event) => {
        if (this.parent.callbacks.rowClick) {
          return this.parent.callbacks.rowClick(event as any);
        }

        const activity: Activity = event.data;

        if (activity.getStatus() === ActivityStatus.EXPIRED) {
          const ids = ActivityService.RerunningActivityIds.getValue();
          if (ids.has(activity.getId())) return;

          if (this.parent.callbacks.rerun) {
            const asyncResult = this.parent.callbacks.rerun(activity);

            if (asyncResult instanceof Observable) {
              asyncResult
                .pipe(take(1))
                .subscribe(() => ActivityService.refresh());
            }
          }
        } else {
          // Use the ApplicationService to find a match based on appId, then use matchApp.path.
          const matchApp = ApplicationService.find(activity.appId);
          return this.router.navigate([matchApp.path, activity.getId()]);
        }
      },
      onGridReady: (event: GridReadyEvent) => {
        this.gridApi = event.api
        this.sort$.next();

        AppComponent.resize$
          .pipe(
            debounceTime(100),
            takeUntil(this.unsub$)
          )
          .subscribe(() => this.resizeColumns());

        combineLatest([
          this.activityList$,
          this.activeFilter$
        ])
          .pipe(takeUntil(this.unsub$))
          .subscribe(([activities, activeFilter]: [Activity[], ActivityTableFilter]) => {
            const tabFilterFn = this.tab.filter;

            let filteredActivities = activities;

            if (tabFilterFn) {
              filteredActivities = tabFilterFn(activities);
            }

            if (!_.isNil(activeFilter)) {
              filteredActivities = ActivityTableComponent.applyActivityTableFilter(filteredActivities, activeFilter);
            }

            // Compare with prevFilteredActivities
            const rowDataTransaction = this.createRowDataTransaction(this.prevFilteredActivities, filteredActivities);
            const res = event.api.applyTransaction(rowDataTransaction);

            // Update prevFilteredActivities
            this.prevFilteredActivities = filteredActivities;

            this.parent.renderedActivities.emit(filteredActivities);
            this.selectedIds$.next(this.selectedIds$.getValue()); // fire signal for checkbox-header-renderer
            this.renderedActivities = filteredActivities;

            setTimeout(() => this.resizeColumns());
          });
      }
    };
  }

  public toggleTrashed(trashed: boolean) {
    const ids = this.selectedIds$.getValue();

    const activities = _.cloneDeep(this.activityList$.getValue().filter(a => ids.has(a.getId())));
    const cb = trashed ? this.parent.callbacks.delete : this.parent.callbacks.unDelete;
    let action;

    if (cb) {
      action = cb(activities);
    } else {
      const requests = activities.map(a => this.activityService.updateMetadata(a, { trashed: trashed }, true));
      action = forkJoin(requests);
    }

    action.subscribe(() => {
      const deletes = activities.map(a => this.activityService.remove({
        endpoint: `reports/${a.id}`, suppressNotification: true
      }));
      forkJoin(deletes).subscribe({
        next: () => {
          this.postDeleteAction(trashed, ids);
          this.selectedIds$.next(new Set<string>());
        },
        error: (e) => {
          console.error(e);
          this.notificationService.error(`Something went wrong ${trashed ? 'deleting' : 'restoring'} reports.`, `Failed to ${trashed ? 'Delete' : 'Restore'} Reports `);
        },
      });
    });
  }

  private postDeleteAction(deleting: boolean, ids: Set<string>) {
    const list = ActivityService.Activities$.getValue();
    const temp = list.filter(a => ids.has(a.id));
    for (const a of temp) {
      a.setMetaDataByKey('trashed', deleting.toString());
      if (deleting) {
        console.log('Report Deleted', a.getName());
        this.mixpanelService.track(
          MixpanelEvent.ACTIVITY_DELETED,
          {
            'Action': 'Report Deleted',
            'Report ID': a.getId(),
            'Report Name': a.getName(),
            'Report Created By': a.getCreatedBy(),
            'Type': 'Report'
          }
        );
      }
    }
    this.notificationService.success(`Your reports have been successfully ${deleting ? 'deleted' : 'restored'}.`, `Reports ${deleting ? 'Deleted' : 'Restored'}`);
    ActivityService.Activities$.next(list);
  }

  private getColDefs(): ColDef[] {
    const defs = this.parent.columns(this.tab.id);
    defs.forEach(def => {
      def.headerComponent = def.headerComponent || ActivityColumnHeaderRendererComponent;
      def.suppressMovable = true;
      def.suppressSizeToFit = def.suppressAutoSize = !def.resizable;

      if (def.colId === CheckboxRendererComponent.COL_ID) {
        def.refData = {
          parent: this as any
        };
      }
    });
    return defs;
  }

  private resizeColumns() {
    if (this.grid.api) {
      const totalWidth = this.grid.api.getAllGridColumns().map(c => c.getColDef().width).reduce(((p, c) => p + c));
      const gridWidth = this._gridInstance.nativeElement.clientWidth;

      if (totalWidth < gridWidth) {
        this.grid.api.autoSizeAllColumns();
        this.grid.api.sizeColumnsToFit();
      }
    }
  }

  private createRowDataTransaction(prevData: Activity[], currData: Activity[]): RowDataTransaction {
    const data: RowDataTransaction = {
      add: [],
      addIndex: 0,
      update: [],
      remove: []
    };

    const prevDataMap = new Map<string, Activity>();

    // construct prevDataMap
    prevData.forEach(a => {
      prevDataMap.set(a.id, a);
    });

    // iterate currData
    currData.forEach(item => {
      const prevA = prevDataMap.get(item.id);
      if (prevA) { // found in prev data
        // check if there's update
        if (item.getStatus() !== prevA.getStatus() || (item.getName()+item.isFavorite()+item.getMetaDataByKey('description')+item.isTrashed()) !== (prevA.getName()+prevA.isFavorite()+prevA.getMetaDataByKey('description')+prevA.isTrashed())) { // Since all these metadata properties are string, concat them together and supply them for the ag-grid change detection.
          data.update.push(item);
        }
        prevDataMap.delete(item.id); // delete what's found in prev data, the thing left in the end is the removed data
      } else { // not found in prev data, new item added
        data.add.push(item);
      }
    });
    if (prevDataMap.size) {
      for (const [key, val] of prevDataMap) {
        data.remove.push(val);
      }
    }

    return data;
  }
}

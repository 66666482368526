<div id="tabBody" class="ag-tab-body">
  <div class="ag-filter">
    <div>
      <div>
        <select class="ag-filter-select" [(ngModel)]="filterType">
          <option *ngFor="let filterType of filterTypes" [value]="filterType">{{ filterLabels[filterType] }}</option>
        </select>
      </div>
      <div class="ag-filter-body" (keydown.enter)="applyFilter()">
        <div>
          <input #day class="ag-filter-filter day" type="number" [(ngModel)]="form.day" maxLength="2" min="1" max="31" placeholder="dd">
        </div>
        <div *ngIf="filterType === 'inRange'">
          <input class="ag-filter-filter day" type="number" [(ngModel)]="formRangeEnd.day" maxLength="2" min="1" max="31" placeholder="dd">
        </div>
      </div>
      <div class="ag-filter-apply-panel" id="applyPanel">
        <button type="button" id="clearButton" (click)="clearFilter()">Clear Filter</button>
        <button type="button" id="applyButton" (click)="applyFilter()">Apply Filter</button>
      </div>
    </div>
  </div>
</div>

import { Routes, Route } from '@angular/router';
import { AuthGuard } from 'app/guards/auth-guard';
import { WelcomeComponent } from 'app/core/components/welcome/welcome.component';

const indexRoute: Route = {
  path: '',
  redirectTo: 'welcome',
  pathMatch: 'full'
};

let childRoutes = [
  {
    path: '',
    component: WelcomeComponent
  }
];

const welcomeRoute: Route = {
  path: 'welcome',
  data: {
    name: 'Welcome'
  },
  canActivate: [
    AuthGuard
  ],
  children: childRoutes
};

export const AppRoutes: Routes = [
  indexRoute,
  welcomeRoute
];

import { Component, OnInit } from '@angular/core';
import { BaseVisualizationComponent } from 'libs/visual-lib/src/lib/components/base-visualization/base-visualization.component';
import {
  ChartSettings,
  SimpleScatterApi,
  SimpleScatterConfig
} from 'libs/visual-lib/src/lib/modules/charts-graphs/models';
import { AgChartOptions, AgCartesianChartOptions } from 'libs/visual-lib/src';
import * as _ from 'lodash';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { takeUntil } from 'rxjs/operators';
import { ChartsService } from 'libs/visual-lib/src/lib/modules/charts-graphs/services/charts.service';

@Component({
  selector: 'siq-js-simple-scatter',
  templateUrl: './simple-scatter.component.html',
  styleUrls: ['./simple-scatter.component.scss']
})
export class SimpleScatterComponent extends BaseVisualizationComponent<any, any, SimpleScatterConfig, SimpleScatterApi> implements OnInit {

  public static generateDefaultChartOptions(component: SimpleScatterComponent): AgChartOptions {
    let typeSafe:AgCartesianChartOptions = {
      autoSize: true,
      theme: ChartsService.getThemePdi(),
      // data: [],
      title: {
        enabled:true,
        text: '',
        fontSize: 18,
      },
      subtitle: { text: '', enabled:true },
      series: [],//there could be pushed data later to empty initialized series array
      axes: [
        {
          position: 'bottom',
          type: 'number',
          title: {
            enabled:true,
            text: ''
          },
        },
        {
          position: 'left',
          type: 'number',
          title: {
            enabled:true,
            text: ''
          },
        },
      ],
      legend: { enabled: false },
    };
    return <AgChartOptions>typeSafe;
  }

  private static _agChartOptions: AgChartOptions;
  public agChartOptions: AgChartOptions;

  constructor() {
    super();
  }

  getApi(): SimpleScatterApi {
    return undefined;
  }

  ngOnInit(): void {
    super.ngOnInit();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      const updatedChartOptions = <AgChartOptions>{...this.agChartOptions};
      updatedChartOptions.theme = ChartsService.getChartThemeObject(theme);
      updatedChartOptions.background = ChartsService.getChartBackgroundForTheme();
      this.agChartOptions = updatedChartOptions;
    });
  }

  protected render(chartSettings: ChartSettings): void {
    this.agChartOptions = _.merge(SimpleScatterComponent.generateDefaultChartOptions(this), chartSettings.agChartOptions);
  }

}

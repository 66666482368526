import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { SharingModalTabComponent } from 'app/activity/modules/sharing/components/sharing-modal-tab/sharing-modal-tab.component';
import { SharingService } from 'app/activity/modules/sharing/services/sharing.service';
import { positiveNumberValidator } from 'app/siq-forms/validators/positive-number.directive';
import { forbiddenRegExpValidator } from 'app/siq-forms/validators/forbidden-regexp.directive';

@Component({
  selector: 'siq-schedule-report',
  templateUrl: './schedule-report.component.html',
  styleUrls: ['./schedule-report.component.scss'],
  providers: [{provide: SharingModalTabComponent, useExisting: ScheduleReportComponent}]
})
export class ScheduleReportComponent extends SharingModalTabComponent implements OnInit {

  public scheduleData: any = {};
  public absFormControlRecurrence: AbstractControl;
  public absFormControlStart: AbstractControl;
  public absFormControlEnd: AbstractControl;
  public absFormControlCount: AbstractControl;

  @Input() modalData: any;
  @Input() limitedLengthTextConfig: any;

  constructor(
    formBuilder: UntypedFormBuilder,
    sharingService: SharingService
  ) {
    super(sharingService);
    this.form = formBuilder.group({
      appId: [null, Validators.required],
      activityId: [null, Validators.required],
      name: [null, forbiddenRegExpValidator(/[<>/"\?\\*:]/i, `Input can not contain special characters: < > / " ? \\ * :`, 'invalid-file-name')],
      message: [],
      recipients: [null, Validators.required],
      deepLink: [null, Validators.required],
      schedule: formBuilder.group({
        recurrence: formBuilder.group({
          freq: [],
          interval: [null, [
              Validators.required,
              positiveNumberValidator(10000, 1, 'positiveNumberInterval')
            ]
          ],
          byDay: [],
          byMonthDay: [],
        }, Validators.required),
        start: [],
        end: [],
        count: [null, [
            positiveNumberValidator(10000, 1, 'positiveNumberCount')
          ]
        ],
      })
    });
  }

  ngOnInit() {
    this.form.controls.activityId.setValue(_.toNumber(this.modalData.activity.getId()));
    this.form.controls.appId.setValue(this.modalData.activity.getAppId());
    this.form.controls.deepLink.setValue(this.modalData.activity.getPath());
    this.form.controls.recipients.setValue([this.modalData.activity.getSiteUser().email]);
    this.absFormControlRecurrence = this.form.controls.schedule.get('recurrence');
    this.absFormControlStart = this.form.controls.schedule.get('start');
    this.absFormControlEnd = this.form.controls.schedule.get('end');
    this.absFormControlCount = this.form.controls.schedule.get('count');

    if (this.modalData.schedule) {
      this.form.controls.recipients.setValue(this.modalData.schedule.sharedReport.recipients);
      this.scheduleData = this.modalData.schedule.sharedReport.schedule;
      this.form.controls.message.setValue(this.modalData.schedule.sharedReport.message);
    }
  }

}

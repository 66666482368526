import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { promoRoutes } from 'app/siq-applications/modules/promo/routes/promo-routes';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(promoRoutes)
  ]
})
export class PromoRoutingModule { }

import { Component, Injector } from '@angular/core';
import { FilterValue } from 'app/filter/models/filter-value';
import { AgRendererComponent, ColDef, ICellRendererParams, IHeaderAngularComp } from '@siq-js/visual-lib';
import { EditFilterModalComponent } from 'app/filter/components/edit-filter-modal/edit-filter-modal.component';
import { UsesCheckboxRenderer } from 'app/core/components/cell-renderers/checkbox-renderer/uses-checkbox-renderer';
import { SelectAllHeaderCellComponent } from 'app/core/components/cell-renderers/select-all-header-cell/select-all-header-cell.component';

@Component({
  selector: 'siq-filter-value',
  templateUrl: './filter-value-renderer.component.html',
  styleUrls: ['./filter-value-renderer.component.scss']
})
export class FilterValueRendererComponent implements AgRendererComponent {

  public fv: FilterValue;
  public parent: EditFilterModalComponent;
  public primary: string;
  public searchStr: string;
  public secondary: string;

  constructor(injector: Injector) {
    this.parent = injector.get(EditFilterModalComponent);
    this.searchStr = injector.get(EditFilterModalComponent).searchString.trim();
  }

  public static GenerateColDef(params: {
    header?: IHeaderAngularComp | any
    parent?: UsesCheckboxRenderer<any>,
  } = {}): ColDef {
    return {
      cellRenderer: FilterValueRendererComponent,
      headerComponent: SelectAllHeaderCellComponent,
      headerName: '',
      field: 'name',
      resizable: false,
      suppressMenu: true,
      suppressNavigable: true,
      refData: {
        parent: params.parent as any
      }
    };
  }

  agInit(params: ICellRendererParams) {
    this.fv = params.node.data;

    if (this.fv.d) {
      // if an explicit display value exists, then this.fv.d !== this.fv.n, so the this.fv.d should be the primary string
      this.primary = this.fv.d;
      const parentCmsField = params.column.getUserProvidedColDef()?.refData?.parent as unknown as EditFilterModalComponent;
      const isUpcDisplay = parentCmsField?.field?.display?.includes('UPC / Description');
      this.secondary = (isUpcDisplay && (this.fv.d.toLocaleLowerCase() !== this.fv.n.toLocaleLowerCase())) ? `(${this.fv.n})` : null;
    } else {
      // otherwise, there is only the this.fv.n field, so use that as the primary string, and there is no secondary
      this.primary = this.fv.n;
    }
  }

  refresh(params): boolean {
    return true;
  }

  updateSelection() {
    const id = this.parent.getId(this.fv);
    const ids = this.parent.selectedIds$.getValue();
    if (ids.has(id)) {
      ids.delete(id);
    } else {
      ids.add(id);
    }
    this.parent.selectedIds$.next(ids);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators'
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import { AuthService } from 'app/auth/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { BrandMeta, RetailerMeta } from 'app/core/services/env-config/interfaces';
import * as _ from 'lodash';
import { ApplicationHash, WeekEndingDay } from '@siq-js/core-lib';
import { FilterService } from 'app/filter/services/filter.service';
import { CmsConfig } from '@siq-js/cms-lib';
import { ValidationService } from 'app/siq-applications/services/validation/validation.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ConfirmationModalConfig } from 'app/core/components/confirmation-modal/confirmation-modal-config.interface';
import { ConfirmationModalComponent } from 'app/core/components/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';

export enum PLATFORM_MODE {
  STANDARD = 'STANDARD',
  MULTI_ONLY = 'MULTI_ONLY',
  SINGLE_ONLY = 'SINGLE_ONLY'
}

export interface EnvConfig {
  /**
   * Used for dryRun validation of customSql for AccessGroup
   */
  accessGroupSql: string;
  /**
   * BE returns as number, casted to string in retrieve()
   */
  adminGroupId: string;
  /**
   * Metadata of all retailers
   */
  allAvailableRetailersMeta: RetailerMeta[];
  applications: ApplicationHash[];
  orgType: string; // TODO: probably will be an enum
  primaryEntity: string;
  primaryEntityMeta: BrandMeta;
  /**
   * Retailers belonging to user
   */
  retailers: string[];
  /**
   * Metadata of retailers belonging to user
   */
  retailersMeta: RetailerMeta[];
  defaultWeekEndingDay: WeekEndingDay;
  platformMode: PLATFORM_MODE;
}

@Injectable()
export class EnvConfigService {

  public static data: BehaviorSubject<EnvConfig> = new BehaviorSubject<EnvConfig>(null);
  private static endpoint = 'environment';
  private static organizationsEndpoint = 'organizations';

  public static isMultiMode(): boolean {
    return this.data.getValue().platformMode === PLATFORM_MODE.MULTI_ONLY;
  }

  public static isSingleMode(): boolean {
    return this.data.getValue().platformMode === PLATFORM_MODE.SINGLE_ONLY;
  }

  public static isStandardMode(): boolean {
    return this.data.getValue().platformMode === PLATFORM_MODE.STANDARD;
  }

  public static getConfig(): EnvConfig {
    return this.data.getValue();
  }

  constructor(
    private filterService: FilterService,
    private siqHttp: SiqHttpService,
    private validationService: ValidationService,
    private utilsService: UtilsService,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {
    AccessGroupService.AccessGroupReady$.subscribe(() => AppLoadingService.addBlocker(this.retrieve(), 'Loading Environment Config...'));
  }

  /**
   * Merges configuration from Datastore Organizations.properties.environmentRetailersConfig and standard configuration
   * This .retailersMeta field is used by the AccessGroup section and the NavSecondary.
   */
  private retrieve(): Observable<boolean> {
    const obs$ = new Subject<boolean>();
    /**
     * For scenario, where user is authenticated by login this will work correctly
     * AuthService.CurrentUser$.getValue()?.organizationId
     * For other future user authentication scenarios(token, SSO, ...) fill that from BE as well
     */
    forkJoin([
      this.siqHttp.get({ endpoint: EnvConfigService.endpoint }),
      this.siqHttp.get({ endpoint: `${EnvConfigService.organizationsEndpoint}/${AuthService.CurrentUser$.getValue()?.organizationId}`})
    ])
      .subscribe((resp) => {
        const config: EnvConfig = <EnvConfig>resp[0];

        // parse stringified configuration loaded from datastore
        const stubEnvironmentsJson: BrandMeta[] = <BrandMeta[]>JSON.parse(resp[1].properties.environmentRetailersConfig);

        if (_.isNil(config.adminGroupId)) {
          console.warn('EnvConfig.adminGroupId missing');
        } else {
          config.adminGroupId = config.adminGroupId.toString();
        }

        // Now add the metaData of retailer belonging to user
        const match: BrandMeta = _.find(stubEnvironmentsJson, <BrandMeta>{ brand: config.primaryEntity });
        if (match) {
          config.retailersMeta = match.retailers.filter(r => {
            return config.retailers.indexOf(r.val) > -1;
          });
          config.primaryEntityMeta = match;
        }

        // Add Metadata of all retailers
        config.allAvailableRetailersMeta = match ? match.retailers : [];
        config.defaultWeekEndingDay = resp[1].properties.defaultWeekEndingDay;
        config.platformMode = resp[1].properties.platformMode;
        EnvConfigService.data.next(config);

        AuthService.AdminGroupId = config.adminGroupId; // TODO: might want to move this elsewhere

        //admin group should be set to 'All Retailers' anyway
        if (_.isEmpty(config.retailers) && AuthService.CurrentUser$.getValue()?.accessGroup?.toString() !== config.adminGroupId) {
          this.utilsService.openModal(
            ConfirmationModalComponent,
            {
              header: 'No Retailer data',
              body: 'You cannot be logged in at this time because there is no Retailer data being accessible within your Access Group.',
              buttons: [
                { label: 'OK', response: { accepted: true, value: true } }
              ]
            } as ConfirmationModalConfig,
            UtilsService.MODAL_CONFIG_MEDIUM
          )
          .afterClosed()
          .pipe(first())
          .subscribe({
              complete: () => {
                this.authService.logout().subscribe({
                  complete: () => {
                  this.router.navigate(['/login']).then(() => obs$.next(true));
                }
              });
            }}
          );
          obs$.next(false);
        } else {
          //Retrieve Loyalty status for retailers belonging to user
          AppLoadingService.addBlocker(this.validationService.retrieveData([...config.retailers].filter(r => !!r)), 'Loading Loyalty Information...');
          // Retrieve filters
          AppLoadingService.addBlocker(this.filterService.retrieveData([...config.retailers, config.primaryEntity, CmsConfig.CORE_SCHEMA_ID].filter(r => !!r)), 'Loading Filter Values...');
          obs$.next(true);
        }
      });
    return obs$;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddFilterModalComponent } from 'app/filter/components/add-filter-modal/add-filter-modal.component';
import { ValueCountRendererComponent } from 'app/filter/components/add-filter-modal/cell-renderers/value-count-renderer/value-count-renderer.component';
import { BulkUploadModalComponent } from 'app/filter/components/bulk-upload-modal/bulk-upload-modal.component';
import { FilterValueRendererComponent } from 'app/filter/components/edit-filter-modal/cell-renderers/filter-value-renderer/filter-value-renderer.component';
import { EditFilterModalComponent } from 'app/filter/components/edit-filter-modal/edit-filter-modal.component';
import { FilterChipComponent } from 'app/filter/components/filter-chip/filter-chip.component';
import { FilterGroupComponent } from 'app/filter/components/filter-group/filter-group.component';
import { FilterSelectionLabelPipe } from 'app/filter/pipes/filter-selection-label.pipe';
import { FilterSelectionTooltipPipe } from 'app/filter/pipes/filter-selection-tooltip.pipe';
import { FilterService } from 'app/filter/services/filter.service';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { FormsModule } from '@angular/forms';
import { CoreModule } from 'app/core/core.module';
import { GridModule } from 'app/grid/grid.module';
import { SameStoreSalesModule } from 'app/siq-forms/modules/same-store-sales/same-store-sales.module';

@NgModule({
  declarations: [
    AddFilterModalComponent,
    FilterValueRendererComponent,
    FilterChipComponent,
    EditFilterModalComponent,
    FilterGroupComponent,
    FilterSelectionLabelPipe,
    FilterSelectionTooltipPipe,
    ValueCountRendererComponent,
    BulkUploadModalComponent
  ],
  imports: [
    CommonModule,
    SiqMaterialModule,
    FormsModule,
    CoreModule,
    GridModule,
    SameStoreSalesModule
  ],
  exports: [
    FilterChipComponent,
    FilterGroupComponent
  ],
  providers: [
    FilterService
  ]
})
export class FilterModule { }

<siq-title-criteria
  [formControl]="form.controls.name"
  [placeholder]="''"
  [activity]="modalData.activity"
  ngDefaultControl>
</siq-title-criteria>

<div class="row schedule-container">
  <div>
    <siq-schedule-detail
      [scheduleData]="scheduleData"
      [formGroupRecurrence]="absFormControlRecurrence"
      [formControlStart]="absFormControlStart"
      [formControlEnd]="absFormControlEnd"
      [formControlCount]="absFormControlCount"
      ngDefaultControl
    ></siq-schedule-detail>
  </div>

  <div>
    <p class="frequency-title">Share</p>
    <siq-recipient-list
      [formControlRecipients]="form.controls.recipients"
      [promptForExternal]="true"
      ngDefaultControl>
    </siq-recipient-list>

    <siq-limited-length-text
      [formControl]="form.controls.message"
      [rows]="limitedLengthTextConfig.rows"
      [maxLength]="limitedLengthTextConfig.maxLength"
      ngDefaultControl>
    </siq-limited-length-text>
  </div>
</div>

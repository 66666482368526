import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FiltersetsService } from 'app/filtersets/services/filtersets.service';

@Component({
  selector: 'siq-js-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  public submitting: boolean;
  public id: number;
  public name: string;

  constructor(

    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filtersetsService: FiltersetsService
  ) {
    this.id = data.id;
    this.name = data.name;
  }

  ngOnInit(): void {
    this.submitting = false;
  }

  remove() {
    this.submitting = true;
    this.filtersetsService.deleteFilterset(this.id)
    .subscribe(resp => {
        this.dialogRef.close(true);
      },
      err => {
        this.dialogRef.close();
        throw new Error(err);
      });
  }

}

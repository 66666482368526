<div class="announcement" [ngClass]="location">
  <mat-card>

    <mat-card-title>
      <img src="assets/img/PDI-Logo-White-SM.png"
           alt="PDI-Logo"/>
      <span class="heading-text">
        Acquires SwiftIQ Expanding Its Data and Insights Solutions to Include Analytics Cloud
      </span>
    </mat-card-title>

    <mat-card-content>

      <h2>
        PDI Acquires SwiftIQ
      </h2>
      <h4>
        Expanding Its Data and Insights Solutions to Include Analytics Cloud
      </h4>

      <div class="info-text">
        <p>
          PDI paves the way for convenience retailers and CPG brands to maximize store-level profitability through automated analytics and AI technology
        </p>
      </div>

    </mat-card-content>

    <mat-card-actions>
      <a href="https://www.pdisoftware.com/news/pdi-acquires-swiftiq/"
         target="_blank"
      >Read Full Text</a>
    </mat-card-actions>

  </mat-card>
</div>

<p class="frequency-title">Ends</p>
<div class="radios-block">
  <mat-radio-group [(ngModel)]="endsMode" (change)="updateForm()">
    <mat-radio-button value="never">
      <span class="frequency-option-span">Never</span>
    </mat-radio-button>
    <br>
    <mat-radio-button value="end">
      <span class="frequency-option-span">On</span>
      <mat-form-field class="form-field-time radio-with-form" color="accent">
        <input matInput [matDatepicker]="picker" [formControl]="dateFormControl" (click)="updateDateForm()">
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon (click)="updateDateForm()">date_range</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-radio-button>
    <br>
    <mat-radio-button value="count">
      <span class="frequency-option-span">After</span>
      <mat-form-field class="form-field-number radio-with-form">
        <input matInput type="number"
               [(ngModel)]="count"
               (ngModelChange)="updateCountForm()"
               [ngClass]="{'positiveNumberFromError': formControlCount.hasError('positiveNumberCount')}"
        >
      </mat-form-field>
        {{'occurrence' | pluralize: count}} (i.e. {{'delivery' | pluralize: count}})
    </mat-radio-button>
  </mat-radio-group>
</div>

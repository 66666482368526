import * as _ from 'lodash';
import { BehaviorSubject, mergeMap, Subject } from 'rxjs';
import { TableStateService } from 'libs/visual-lib/src/lib/modules/grid/services/table-state/table-state.service';
import { GridComponent, TableState, TableStateManagerStatus } from 'libs/visual-lib/src/lib/modules/grid';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs';
import { ActivityInterface } from '@siq-js/core-lib';

export class TableStateManager {
  private static readonly DEBOUNCE_TIME = 1000;

  public status = new BehaviorSubject<TableStateManagerStatus>({loading: true, justLoaded:false});

  protected activity: ActivityInterface;
  protected service: TableStateService;
  protected update = new Subject<TableState>();
  protected updatecolumnRowGroupChanged = new Subject<TableState>();

  private unsub = new Subject<void>();

  constructor(protected gridComponent: GridComponent) {
    this.service = gridComponent.injector.get(TableStateService);

    if (!gridComponent.gridSettings.gridVisualOptions.gridConfig.persistState) return;

    if (!_.get(gridComponent, 'parentActivity')) {
      this.broadcastError(`
        Unable to locate parent activity for grid ID ${gridComponent.id}.
        Updates to this visualization's state will not be persisted.
      `);
    } else if (!_.get(gridComponent, 'grid.api')) {
      this.broadcastError(`
        Unable to locate Grid API for visual ID ${gridComponent.id}.
        Updates to this visualization's state will not be persisted.
      `);
    }

    this.service
    .retrieveTableState(this.gridComponent)
    .pipe(takeUntil(this.unsub))
    .subscribe(tableState => {
      this.service.applyTableState(this.gridComponent, tableState);
      this.loadSuccess(tableState);

      if (gridComponent.gridSettings.gridVisualOptions.gridConfig.persistState) {
        this.watch();
      }
    });
  }

  public destroy() {
    this.unsub.next();
  }

  private broadcastError(errMsg: string): void {
    console.warn(errMsg);
    this.status.next({
      justLoaded: false,
      loading: false,
      saving: false,
      error: errMsg
    });
  }

  private loadSuccess(tableState: TableState): void {
    this.status.next({
      justLoaded: true,
      loading: false,
      saving: false,
      error: undefined,
      tableState: tableState
    });
  }

  private saveStart(): void {
    this.status.next({
      justLoaded: false,
      loading: false,
      saving: true,
      error: undefined
    });
  }

  private saveSuccess(): void {
    this.status.next({
      justLoaded: false,
      loading: false,
      saving: false,
      error: undefined
    });
  }

  private watch(): void {

    this.update
    .pipe(
      debounceTime(TableStateManager.DEBOUNCE_TIME), // Do not send a bunch of updates if the user is making rapid changes
      filter(tableState => !!tableState), // Do not send empty requests
      map(tableState => JSON.stringify(tableState)), // convert TableState to string for easy equality checking
      distinctUntilChanged(), // Do not attempt to send update to identical models
      map(tableStateString => JSON.parse(tableStateString)), // Convert TableState string back to a TableState
      tap(r => this.saveStart()), // Indicate to observers that save is in progress
      mergeMap(tableState => this.service.saveTableState(this.gridComponent, tableState)), // Save the TableState
      takeUntil(this.unsub)
    )
    .subscribe(res => this.saveSuccess()); // Indicate to observers that save is complete

    this.gridComponent.grid.api.addEventListener('filterChanged',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('columnVisible',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('columnPivotChanged',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('columnPivotModeChanged',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('columnEverythingChanged',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('columnGroupChanged',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('rowGroupOpened',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    this.gridComponent.grid.api.addEventListener('sortChanged',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });


    this.gridComponent.grid.api.addEventListener('dragStopped',() => {
      this.update.next(this.service.getCurrentTableState(this.gridComponent));
    });

    // event of type columnRowGroupChanged will be taken into account only each 2 seconds
    this.updatecolumnRowGroupChanged.pipe(debounceTime(2000),
      takeUntil(this.unsub))
    .subscribe(value => {
      this.update.next(value);
    });

    // event polluting pipe
    this.gridComponent.grid.api.addEventListener('columnRowGroupChanged',() => {
      this.updatecolumnRowGroupChanged.next(this.service.getCurrentTableState(this.gridComponent));
    });

  }
}

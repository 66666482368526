<mat-card *ngIf="!noCard">
  <mat-card-title *ngIf="title">{{ title }}</mat-card-title>
  <mat-card-subtitle *ngIf="subtitle">{{ subtitle }}</mat-card-subtitle>
  <mat-card-content>
    <ag-grid-angular #dataTableGridCard
      [className]="agTheme"
      [gridOptions]="gridOptions"
      ngClass="{{ autoHeight ? 'auto-height' : ''}}"
    >
    </ag-grid-angular>
  </mat-card-content>
</mat-card>

<div class="siq-data-table siq-data-table-no-card" *ngIf="noCard && ready$ | async">
  <ag-grid-angular #dataTableGridNoCard
    [className]="agTheme"
    [gridOptions]="gridOptions"
    ngClass="{{ autoHeight ? 'auto-height' : ''}}"
  >
  </ag-grid-angular>
</div>

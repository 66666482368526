import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/services/auth.service';
import { User } from 'app/user/models/user.model';

@Component({
  selector: 'siq-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  public currentUser: User;
  constructor() {}

  ngOnInit() {
    this.currentUser = AuthService.CurrentUser$.getValue();
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivityGridComponent, UsesActivityGrid } from 'app/activity/components/activity-grid/activity-grid.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { ReportBuilderService } from 'app/siq-applications/modules/report-builder/services/report-builder.service';
import { ColDef } from '@siq-js/visual-lib';
import { ActivityCriteriaGenerator } from 'app/activity/models/activity-criteria-generator';
import { ActivityService } from 'app/activity/services/activity.service';
import { ApplicationHash } from '@siq-js/core-lib';
import { Activity } from 'app/activity/models/activity.model';
import { ApplicationNameRendererComponent } from 'app/activity/components/renderers/application-name-renderer/application-name-renderer.component';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'siq-all-activity-list',
  templateUrl: './all-activity-list.component.html',
  styleUrls: ['./all-activity-list.component.scss']
})
export class AllActivitiesComponent implements OnInit, OnDestroy, UsesActivityGrid {
  private static readonly GenericCriteriaColDef: ColDef = {
    headerName: 'Criteria',
    maxWidth: 1100,
    flex: 1,
    resizable: false,
    sortable: false,
    cellRendererSelector: (params) => {
      const activity = params.data as Activity;

      return {
        component: activity.getAppId()
      };
    }
  };

  @ViewChild('activityGrid', { static: true }) activityGrid: ActivityGridComponent;

  // ActivityGrid interface fields
  public activityStream$: BehaviorSubject<Activity[]>;
  public actions = [
    ActivityOptionsActions.CLONE,
    ActivityOptionsActions.SHARE,
    ActivityOptionsActions.DELETE
  ];
  public columns: (tabKey: ActivityTabKey) => ColDef[];
  public tabs = ActivityGridComponent.TABS.map(t => t.id);
  public callbacks: ActivityCallbacks = {
    rerun: activity => {
      if (activity.getAppId() === ApplicationHash.REPORT_BUILDER) {
        this.router.navigate(['app', 'report-builder', activity.getId()]);
      } else {
        return this.activityService.rerun(activity);
      }
    },
    clone: activity => {
      if (activity.getAppId() === ApplicationHash.REPORT_BUILDER) {
        this.reportBuilderService.cloneReport(activity.getId());
      } else {
        this.router.navigate([ `${ activity.getPath() }/clone` ]);
      }
    }
  };

  private ngUnsubscribe = new Subject<void>();

  constructor(
    public reportBuilderService: ReportBuilderService,
    public activityService: ActivityService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    this.activityStream$ = ActivityService
      .createStream(list => list
        .filter(a => {
          return !a.isMine() || (a.isMine() && !a.isSharedOrScheduled());
        })
      );

    this.columns = tabKey => ActivityCriteriaGenerator.generateColDefs(tabKey, [
      AllActivitiesComponent.GenericCriteriaColDef,
      ApplicationNameRendererComponent.ColDef,
    ]);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(qp => {
      const tabKey = qp['tab'];
      this.activityService.getCurrentPageInfo().appId = ApplicationHash.ALL;
      
      if (tabKey) {
        this.activityService.getCurrentPageInfo().tab = tabKey;
        ActivityService.refresh();
        setTimeout(() => {
          this.activityGrid.selectTab(tabKey);
          this.router.navigate(['activity']);
        });
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.activityService.clearCurrentPageInfo();
  }

  public activityFilter(activities: Activity[]): Activity[] {
    const uniqueActivities: Set<string> = new Set();
    const resultArray: Activity[] = [];
    activities.forEach(activity => {
      if (!uniqueActivities.has(activity.id)) {
        uniqueActivities.add(activity.id);
        resultArray.push(activity);
      }
    })
    return resultArray;
  }

}

import {
  AgCartesianChartOptions,
  AgChartOptions,
  BarColumnConfig,
  BarColumnParams,
  BarColumnProcessor,
  ChartSettings,
  ChartsService,
  GridService,
  VISUAL_CONFIG
} from '@siq-js/visual-lib';
import { filter, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { TakeRateResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-result/take-rate-result.component';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export class TakeRateColumnChartProcessor extends BarColumnProcessor {
  private static HIGHLIGHT_COLOR = THEME_COLORS.INDIGO;
  private static EXCLUDE_KEYS = ['column', 'total'];
  private static BASKET_COMPARISON_COLORS: string[] = [
    THEME_COLORS.SUNSHINE,
    THEME_COLORS.SIENNA,
    THEME_COLORS.GRASS,
    THEME_COLORS.OCEAN,
    THEME_COLORS.PLUM
  ];

  public static generateBarColumnConfig(barColParams: BarColumnParams): BarColumnConfig {
    const data = barColParams.rawData$.value;
    let config: BarColumnConfig = {
      agChartOptions: <AgCartesianChartOptions>{}
    };
    let theme = ChartsService.getThemePdi();
    theme.palette.fills = TakeRateColumnChartProcessor.BASKET_COMPARISON_COLORS;
    theme.palette.strokes = TakeRateColumnChartProcessor.BASKET_COMPARISON_COLORS;
    let label = {
      color: THEME_COLORS.WHITE,
      formatter: function (params) {
        if (_.isNil(params.value) || params.value < 0.05 ) return '';
        const percentFormatter = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: 'PERCENT'}).formatter;
        return percentFormatter({value: params.value}, true);
      },
    };
    let tooltip = {
      renderer: (params) => {
        const formatter = GridService.getVisualType((<TakeRateResultComponent>barColParams.parent).factKey).formatter;
        const percentFormatter = _.find(VISUAL_CONFIG.VISUAL_DATA_TYPES, {type: 'PERCENT'}).formatter;
        let content = '';
        Object.keys(params.datum)
          .filter(key => !this.EXCLUDE_KEYS.includes(key))
          .forEach(key => {
            const val = params.datum[key];
            const originalVal = val * params.datum.total;
            const percent = percentFormatter({value: val}, true);
            if (key === params.yKey) {
              content += '<span style="color:' + params.color + '">' +  key + ': ' + formatter({value: originalVal}) + ' (' + percent + ')' + '</span>' +
                '<br/>';
            } else {
              content += '<span>' +  key + ': ' + formatter({value: originalVal}) + ' (' + percent + ')' + '</span>' +
                '<br/>';
            }
          });

        return (
          '<div class="ag-chart-tooltip-title" style="background-color:' + params.color + '">' +
          params.datum[params.xKey] +
          '</div>' +
          '<div class="ag-chart-tooltip-content">' +
          content +
          '</div>'
        );
      }
    };
    let highlightStyle = {
      item: {
        fill: TakeRateColumnChartProcessor.HIGHLIGHT_COLOR,
      }
    };
    let typesafe: AgChartOptions = {
      height: 400,
      data: data,
      title: {
        enabled: true,
        text: 'Basket Comparison by Items',
        fontSize: 25,
        fontWeight: 'bold'
      },
      theme: theme,
      series: [
        {
          type: 'bar',
          xKey: 'column',
          yKey: '1 Item',
          normalizedTo: 100,
          highlightStyle: highlightStyle,
          label: label,
          tooltip: tooltip,
          stacked: true
        },
        {
          type: 'bar',
          xKey: 'column',
          yKey: '2 Items',
          normalizedTo: 100,
          highlightStyle: highlightStyle,
          label: label,
          tooltip: tooltip,
          stacked: true
        },
        {
          type: 'bar',
          xKey: 'column',
          yKey: '3 Items',
          normalizedTo: 100,
          highlightStyle: highlightStyle,
          label: label,
          tooltip: tooltip,
          stacked: true
        },
        {
          type: 'bar',
          xKey: 'column',
          yKey: '4 Items',
          normalizedTo: 100,
          highlightStyle: highlightStyle,
          label: label,
          tooltip: tooltip,
          stacked: true
        },
        {
          type: 'bar',
          xKey: 'column',
          yKey: '5+ Items',
          normalizedTo: 100,
          highlightStyle: highlightStyle,
          label: label,
          tooltip: tooltip,
          stacked: true
        }
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {enabled: false},
          label: {
            rotation: 0
          }
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              return params.value + '%';
            },
          },
          title: {enabled: false},
          tick: {
            minSpacing: 20,
            maxSpacing: 100
          }
        },
      ],
      legend: { position: 'top' },

    };

    config.agChartOptions = typesafe;
    return config;
  }

  public static processor = (params: BarColumnParams): ChartSettings => {

    params.rawData$.pipe(
      filter(data => !!data && data.length > 0),
      takeUntil(params.parent.unsub$)
    )
    .subscribe(data => {
      let newChartOptions: AgChartOptions = _.cloneDeep(params.chart.getApi().chartOptions);
      newChartOptions.data = data;
      if (params.chart) {
        params.chart.agChartOptions = newChartOptions;
      }

    });

    return <ChartSettings>{
      agChartOptions: params.agChartOptions,
      parentActivity: params.parentActivity,
    };
  }

  /**
   * For 100% stacked column chart, we need to transform the data into format like this:
   * [
    * {
    *  column: 'column1',
    *  valueName1: 1,
    *  valueName2: 2,
    *  valueName3: 3,
    *  total: 6
    * },
    * {
    *  column: 'column2',
    *  valueName1: 4,
    *  valueName2: 5,
    *  valueName3: 6,
    *  total: 15
    * }
   * ]
   * @param rawData data from GridService.JobToArray.
   * @param keyDim dimension which is going to be a column in the stacked column chart.
   * @param valueDim
   * @param metric metric which holds the number given a column and a stack.
   * @param fractionMode whether we want to the display the stacks in fraction or not.
   */
  public static toStackedColumnData(rawData: any[], keyDim: string, valueDim: string, metric: string, fractionMode = false): any[] {
    const output = [];
    const columnMap = new Map<string, number>(); // key: columnName; val: index in the output array

    rawData.forEach(datum => {
      const columnName = datum[keyDim];
      const value = Number(GridService.getValueGetterFinalVal(datum[metric], 'val'));
      if (columnMap.has(columnName)) {
        const i = columnMap.get(columnName);
        const o = output[i];
        o[datum[valueDim]] = value;
        o.total += value;
      } else {
        columnMap.set(columnName, columnMap.size);
        const o = {
          column: columnName,
          total: value
        };
        o[datum[valueDim]] = value;
        output.push(o);
      }
    });

    if (fractionMode) {
      output.forEach(o => {
        Object.keys(o)
        .filter(k => !this.EXCLUDE_KEYS.includes(k))
        .forEach(k => {
          o[k] = o[k] / o.total;
        });
      });
    }

    return output;
  }
}

<div class="header-container">
  <div class="header-name">
    <div *ngIf="model">
      <label *ngIf="model.tooltip" [innerHtml]="model.header" [matTooltip]="model.tooltip"></label>
      <label *ngIf="!model.tooltip" [innerHtml]="model.header"></label>
    </div>
  </div>

  <div class="actions">
    <button *ngIf="!disableFilter" mat-icon-button (click)="showMenu($event)" [ngClass]="{selected: model.filterApplied}">
      <mat-icon [matTooltip]="model.filterApplied ? model.filterApplied : 'Apply a filter'">filter_list</mat-icon>
    </button>

    <button mat-icon-button (click)="toggleSort()" [ngClass]="{selected: model.sort}">
      <ng-container [ngSwitch]="model.sort">
        <mat-icon *ngSwitchCase="'asc'">vertical_align_top</mat-icon>
        <mat-icon *ngSwitchCase="'desc'">vertical_align_bottom</mat-icon>
        <mat-icon *ngSwitchCase="null">vertical_align_center</mat-icon>
      </ng-container>
    </button>
  </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CmsField } from '@siq-js/cms-lib';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { ValidationMessage, ValidationMessageStatus } from 'app/core/models/validation-message';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { PromoFormData } from 'app/siq-applications/modules/promo/models/form/promo-form-data.model';
import { UpcCountParams } from 'app/siq-applications/modules/promo/models/interfaces';
import { PromoService } from 'app/siq-applications/modules/promo/services/promo.service';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs';
@Component({
  selector: 'siq-js-upc-selection',
  templateUrl: './upc-selection.component.html',
  styleUrls: ['./upc-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component contains the UPC Selection step content
 */
export class UpcSelectionComponent extends BaseSiqComponent implements OnInit {
  public static readonly UPC_THRESHOLD = 500;

  @Input() datesUpdated: BehaviorSubject<boolean>;
  @Input() formData: PromoFormData;
  @Input() queryMode: QueryModeComponent;
  @Output() validationChange: EventEmitter<ValidationMessage> = new EventEmitter<ValidationMessage>();

  constructor(
    private promoService: PromoService
  ) {
    super();
  }

  public filterGroupChanged(filterModel: FilterSelection[]) {
    // Update form model which contains a list of filter selection
    this.formData.upcFilters = filterModel;
    if (filterModel.length) {
      this.validateUPCCount();
    } else {
      // emit different validation message
      this.validationChange.emit({
        message: 'Select UPC Filter(s)',
        status: ValidationMessageStatus.UNSET
      });
    }
  }

  ngOnInit(): void {
    this.datesUpdated
      .pipe(
        filter(d => !!d),
        takeUntil(this.unsub$)
      )
      .subscribe(_ => {
        if (this.formData.upcFilters?.length) { // check if there's any filter selections
          this.validateUPCCount();
        }
      });
  }

  public productMapFn = (fields: CmsField[]) => fields.filter(f => f.table === 'product');

  /**
   * Make REST call to get upc count
   * Emit validation status in the subscription
   */
  private validateUPCCount() {
    this.validationChange.emit({
      message: `Verifying filters...`,
      status: ValidationMessageStatus.UNSET
    });
    const dates = [this.formData.promoDateRange, this.formData.prePromoDateRange, this.formData.yoyDateRange];
    if (this.formData.customPromoDateRange.periodStart && this.formData.customPromoDateRange.periodEnd) {
      dates.push(this.formData.customPromoDateRange);
    }
    const params: UpcCountParams = {
      analysisType: this.formData.getAnalysisType(),
      retailer: this.formData.schema,
      dateRanges: dates,
      productFilters: this.formData.upcFilters.map(f => UtilsService.paramify(f))
    };
    this.promoService.getUpcCount(params)
      .subscribe((res) => {
        this.formData.upcCount = res.body;
        if (res.body > UpcSelectionComponent.UPC_THRESHOLD) { // above threshold
          this.formData.upcFilters = [];
          this.validationChange.emit({
            message: `UPC count (${res.body}) exceeds threshold of ${UpcSelectionComponent.UPC_THRESHOLD}`,
            status: ValidationMessageStatus.INVALID
          });
        } else if (res.body === 0) {
          this.validationChange.emit({
            message: `No UPCs matching the criteria`,
            status: ValidationMessageStatus.INVALID
          });
        } else {
          this.validationChange.emit({
            message: `${this.formData.upcFilters.length} UPC filter(s) selected - ${res.body} Matching UPC(s)`,
            status: ValidationMessageStatus.VALID
          });
        }
      }, err => {
        if (!this.formData.upcFilters?.length) {
          this.validationChange.emit({
            message: `Please apply UPC filters.`,
            status: ValidationMessageStatus.INVALID
          });
        }
        if (!this.formData.promoDateRange.periodStart) {
          this.validationChange.emit({
            message: `Date range is not provided. Please complete Step 3: Promo Date Range`,
            status: ValidationMessageStatus.INVALID
          });
        } else {
          this.validationChange.emit({
            message: `Something wrong has occurred. Not able to validate filters. Check if date range is valid.`,
            status: ValidationMessageStatus.INVALID
          });
        }

      });
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsConfig, CmsConfigJson, CmsField, CmsMetric, mergeCmsConfig } from '@siq-js/cms-lib';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CoreConstants } from '@siq-js/core-lib';
import { ValidationService } from 'app/siq-applications/services/validation/validation.service';

interface CmsConfigUrls {
  defaults: string;
  overrides: string;
}

@Injectable()
export class CmsService {

  // static variable holding final CMS Config data, generated from defaults & overrides
  public static data$: BehaviorSubject<CmsConfig> = new BehaviorSubject<CmsConfig>(null);
  public static readonly RETAILER_FIELD_ID = 'retailer';
  /** Single retailer metrics */
  public static readonly LOYALTY_FACT_EXCLUDE_LIST_MRV = ['SELLING_UPC_PER_CUSTOMER', 'AVG_PER_CUSTOMER_NUM_TRANSACTIONS'];

  private static validationService: ValidationService;
  // shorthand alternative to CmsConfigService.data$.getValue()
  public static get(): CmsConfig {
    return this.data$.getValue();
  }

  public static getData$(): BehaviorSubject<CmsConfig> {
    return this.data$;
  }

  public static isValidField(fieldRef: CmsField, schema: string): boolean {
    const primaryEntity = EnvConfigService.getConfig().primaryEntity;

    // retailer field is only available when the schema is the primaryEntity (multi-retailer analysis mode)
    if (fieldRef.id === this.RETAILER_FIELD_ID) return schema === primaryEntity;

    // primary entity fields are always valid
    if (fieldRef.retailer === primaryEntity) return true;

    // all other core schema fields are also valid except "loyalty status"
    if (fieldRef.retailer === CmsConfig.CORE_SCHEMA_ID) {
      return true;
    }

    return schema === fieldRef.retailer;
  }

  // ICE-2612: "Transaction Per Customer" and "Selling UPCs Per Customer" metrics should be available
  // only in Single Retailer analysis modes until SQL Engine Optimization is completed
  /** Only used in Report Builder now, all other apps have all metrics valid */
  public static isValidMetric(metricRef: CmsMetric, schema: string): boolean {
    if (this.LOYALTY_FACT_EXCLUDE_LIST_MRV.includes(metricRef.id)) {
      const primaryEntity = EnvConfigService.getConfig().primaryEntity;
      if (primaryEntity === schema) return false;
    }

    if (ValidationService.LOYALTY_FACT_LIST.includes(metricRef.id)) {
      if (!ValidationService.customerDataLookup.get(schema)) {
        return false;
      }
    }

    return true;
  }

  public static getValidSchemas(schema: string): string[] {
    const schemas = new Set<string>();
    schemas.add(EnvConfigService.getConfig().primaryEntity);
    schemas.add(CmsConfig.CORE_SCHEMA_ID);
    schemas.add(schema);
    return Array.from(schemas.values());
  }

  constructor(
    private siqHttp: SiqHttpService,
    private http: HttpClient
  ) {
    AccessGroupService.AccessGroupReady$.subscribe(() => AppLoadingService.addBlocker(this.retrieveData(), 'Loading Config...'));
  }

  private retrieveData(): Observable<boolean> {
    const obs = new Subject<boolean>();

    this.siqHttp.get({ endpoint: 'bootstrap' })
      .subscribe(async (res: CmsConfigUrls) => {

        let defaultsJson: CmsConfigJson;
        if (!res.defaults) {
          console.error('No defaults.json!');
          return;
        } else {
          defaultsJson = await this.http.get<CmsConfigJson>(res.defaults).toPromise();
        }

        let overridesJson: CmsConfigJson;
        if (!res.overrides) {
          console.warn('No overrides.json!');
          overridesJson = {} as any;
        } else {
          overridesJson = await this.http.get<CmsConfigJson>(res.overrides).toPromise();
        }

        const config = mergeCmsConfig(defaultsJson, overridesJson);

        CmsService.data$.next(config);
        CoreConstants.cmsConfig = config; // set so it is accessible from anything else using core-lib CoreConstants
        obs.next(true); // remove loading blocker
      });

    return obs;
  }
}

import { Component } from '@angular/core';
import { ILoadingOverlayParams } from "libs/visual-lib/src";
import { ILoadingOverlayAngularComp } from 'libs/visual-lib/src';

@Component({
  selector: 'siq-js-download-overlay',
  templateUrl: './download-overlay.component.html',
  styleUrls: ['./download-overlay.component.scss']
})
export class DownloadOverlayComponent implements ILoadingOverlayAngularComp {
  public params: ILoadingOverlayParams & {loadingMessage: string};
  agInit(params: ILoadingOverlayParams & {loadingMessage: string}): void {
    this.params = params;
  }
}

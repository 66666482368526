<ng-container *ngIf="state">

  <div *ngIf="rules" class="drawer-tab no-select">
    <mat-icon [ngClass]="{disabled: !rules.expand}" (click)="expand()">chevron_left</mat-icon>
    <span>Helper Table</span>
    <mat-icon [ngClass]="{disabled: !rules.contract}" (click)="contract()">chevron_right</mat-icon>
    <mat-icon [ngClass]="{disabled: !rules.close}" class="close-btn" (click)="close()">close</mat-icon>
  </div>
  <div class="drawer-content"
       [@resize]="state"
       (@resize.done)="onResize()"
  >
    <ng-container
      *ngIf="state !== stateEnums.PEEK && outletParams"
      [ngComponentOutlet]="outletParams.comp"
      [ngComponentOutletInjector]="outletParams.providers"
    ></ng-container>
  </div>

</ng-container>

import { Component } from '@angular/core';
import { ColDef } from '@siq-js/visual-lib';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { ActivityCriteriaRendererComponent } from 'app/activity/shared/renderers/activity-criteria-renderer/activity-criteria-renderer.component';
import { CriteriaChip } from 'app/core/components/cell-renderers/base-criteria-renderer/criteria-chip.interface';
import { AffinitiesService } from 'app/siq-applications/modules/affinities/services/affinities.service';
import { CriteriaChipFactory } from 'app/core/models/factories/criteria-chip-factory';
import { AffinitiesActivity } from 'app/siq-applications/modules/affinities/models/affinities-activity.model';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Model {
  dateRange: string;
  retailers: CriteriaChip;
  leftType: CriteriaChip;
  rightType?: CriteriaChip;
  filters: CriteriaChip;
}

@Component({
  selector: 'siq-js-affinities-criteria-renderer',
  templateUrl: './affinities-criteria-renderer.component.html',
  styleUrls: ['./affinities-criteria-renderer.component.scss']
})
export class AffinitiesCriteriaRendererComponent extends ActivityCriteriaRendererComponent {
  public static readonly ColDef: ColDef = {
    headerName: 'Criteria',
    sortable: false,
    cellRenderer: AffinitiesCriteriaRendererComponent,
    width: 600
  };

  public isMultiMode: boolean = false;
  public model: Model;

  constructor(
    private affinitiesService: AffinitiesService,
    asyncStatusService: AsyncStatusService
  ) {
    super(asyncStatusService);
  }

  protected setupModel(activity: AffinitiesActivity) {
    this.isMultiMode = EnvConfigService.isMultiMode();
    if (activity.getJob()?.getParams()) {
      const form = this.affinitiesService.createForm(JSON.parse(activity.formValues));
      this.model = {
        dateRange: CriteriaChipFactory.getDate(form.dateRanges),
        retailers: CriteriaChipFactory.getRetailers(form.filters, form.schema),
        leftType: {
          label: `${UtilsService.capitalizeFirstLetter(form.affinityType.display)} Affinity`},
        filters: CriteriaChipFactory.getFilters([...form.filters, ...form.leftFilters, ...form.rightFilters])
      };
      if (form.secondaryAffinityType) {
        this.model.rightType = {
          label: `${UtilsService.capitalizeFirstLetter(form.secondaryAffinityType.display)} Affinity`};
      }
    } else {
      this.model = null;
    }
    this.ready();
  }

  refresh(params: any) {
    return true;
  }
}

import { Component, OnInit } from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { GridComponent, IToolPanel, IToolPanelParams } from 'libs/visual-lib/src';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-js-heatmap',
  templateUrl: './heatmap-panel.component.html',
  styleUrls: ['./heatmap-panel.component.scss']
})
export class HeatmapPanelComponent extends BaseSiqComponent implements IToolPanel, OnInit {

  public grid: GridComponent;
  private params: IToolPanelParams;

  constructor() {
    super();
  }

  agInit(params: IToolPanelParams): void {
    this.params = params;
    this.grid = params['gridComponent']; // passed in from 'toolPanelParams'

    // Example
    // this.params.api.addEventListener(
    //   'modelUpdated',
    //   this.updateTotals.bind(this)
    // );
  }

  ngOnInit(): void {
  }

  refresh(): void {
    // required by IToolPanel
  }

}

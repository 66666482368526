import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import {
  IDoesFilterPassParams,
  IFilterParams,
  AgFilterComponent
} from 'libs/visual-lib/src';
import * as _ from 'lodash';
import {
  FilterLabels,
  FilterNumberTypes,
  FilterTypes,
  PercentageFilterForm
} from 'libs/visual-lib/src/lib/modules/custom-filters/models';

@Component({
  selector: 'siq-js-percentage-filter',
  templateUrl: './percentage-filter.component.html',
  styleUrls: ['./percentage-filter.component.scss']
})
export class PercentageFilterComponent implements AgFilterComponent, AfterViewInit {
  public filterLabels = FilterLabels;
  public filterType: FilterTypes['number'] = FilterNumberTypes.EQUALS;
  public filterTypes: string[] = _.map(FilterNumberTypes);
  public isRange: boolean;
  public percentageFilterForm: PercentageFilterForm;
  private _active = false;
  private params: IFilterParams;

  @ViewChild('input', { read: ViewContainerRef }) public input;

  constructor() {
    this.setForm();
  }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  applyFilter(): void {
    this.params.filterChangedCallback();
  }

  public clearFilter(): void {
    // Resets filter form
    this.filterType = FilterNumberTypes.EQUALS;
    this.isRange = false;
    this.setForm();

    // Deactivates filter
    this._active = false; // Updates the filter status
    this.params.filterChangedCallback(); // Triggers the filter to be removed from the grid
  }

  isFilterActive(): boolean {
    let isActive = false;
    if (this.isRange) {
      isActive = !_.isNil(this.percentageFilterForm.rangeLow) && !_.isNil(this.percentageFilterForm.rangeHigh)
        && _.isFinite(this.percentageFilterForm.rangeLow) && _.isFinite(this.percentageFilterForm.rangeLow);
    } else {
      isActive = this.percentageFilterForm.text !== null && this.percentageFilterForm.text !== undefined && this.percentageFilterForm.text !== '';
    }
    return isActive;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {

    const nodeVal = this.params.getValue(params.node);
    if (_.isNil(nodeVal) || !isFinite(nodeVal)) return false;
    let numPercent = typeof nodeVal === 'object' ? nodeVal.val : nodeVal;
    numPercent = parseFloat(numPercent);

    if (this.filterType === FilterNumberTypes.IN_RANGE) {
      return _.inRange(numPercent, this.percentageFilterForm.rangeLow, this.percentageFilterForm.rangeHigh)
        && _.gt(numPercent, this.percentageFilterForm.rangeLow);
    }

    if (_.isNumber(this.percentageFilterForm.text)) {
      this.percentageFilterForm.text = this.percentageFilterForm.text.toString();
    }

    return this.percentageFilterForm.text.toLowerCase()
      .split(' ')
      .every((filterWord) => {

        filterWord = filterWord.replace(/%/g, '').trim();
        const filterWordNum = parseFloat(filterWord);

        switch (this.filterType) {
          case FilterNumberTypes.EQUALS:
            return _.isEqual(numPercent, filterWordNum);

          case FilterNumberTypes.GREATER_THAN:
            return _.gt(numPercent, filterWordNum);

          case FilterNumberTypes.GREATER_THAN_OR_EQUAL:
            return _.gte(numPercent, filterWordNum);

          case FilterNumberTypes.LESS_THAN:
            return _.lt(numPercent, filterWordNum);

          case FilterNumberTypes.LESS_THAN_OR_EQUAL:
            return _.lte(numPercent, filterWordNum);

          case FilterNumberTypes.NOT_EQUAL:
            return !_.isEqual(numPercent, filterWordNum);
        }
      });
  }

  getModel(): any {
    return {value: this.percentageFilterForm?.text, type: this.filterType, rangeLow: this.percentageFilterForm?.rangeLow, rangeHigh: this.percentageFilterForm?.rangeHigh};
  }

  public setForm(): void {
    this.percentageFilterForm = {
      rangeLow: null,
      rangeHigh: null,
      text: ''
    };
  }

  setModel(model: any): void {
    if (model && model.type) {
      this.filterType = model.type;
    }

    if(this.filterType == FilterNumberTypes.IN_RANGE) {
      this.percentageFilterForm.rangeLow = model?.rangeLow;
      this.percentageFilterForm.rangeHigh = model?.rangeHigh;
    } else {
      this.percentageFilterForm.text = model ? model.value : '';
    }
    this.onChangeFilterType(this.filterType);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input?.element?.nativeElement?.focus();
    });
  }

  onChangeFilterType(newValue): void {
    this.isRange = _.isEqual(this.filterType, FilterNumberTypes.IN_RANGE);
  }

}


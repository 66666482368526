import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { ContentType, CrudConfig } from '@siq-js/core-lib';
import { NotificationType, ResponseCode, ResponseCodes } from '@siq-js/angular-buildable-lib';
import { FilterSelection, FilterSelectionJson } from 'app/filter/models/filter-selection';
import { Filterset } from 'app/filtersets/models/filterset.model';

@Injectable({
  providedIn: 'root'
})
export class FiltersetsService {

  private static readonly path = 'filtersets';
  public deleteClicked$ = new BehaviorSubject<string>(''); // used to notify filtersets-grid to refresh list

  private readonly notificationMessages = {
    saveFilterset: {
      title: 'Filterset Saved',
      message: '<hr/>Your filterset has been saved.'
    },
    deleteFilterset: {
      title: 'Filterset Deleted',
      message: '<hr/>Your filterset has been deleted.'
    }
  };

  constructor(
    private siqHttpService: SiqHttpService
  ) { }

  // Gets the list of the user's filtersets
  public retrieveFiltersets(): Observable<HttpResponse<any>> {
    return this.siqHttpService.get({endpoint: FiltersetsService.path});
  }

  // Saves or edits the filterset. If an id is supplied, it is an edit operation. Otherwise, it's a save operation
  public saveFilterset(payload: {name: string, filterSets: any[], id?: number}): Observable<HttpResponse<any>> {

    let overrideCodes: ResponseCodes = this.getResponseCodes();
    let obs$: Observable<HttpResponse<any>> = this.siqHttpService.create({
      endpoint: FiltersetsService.path,
      body: payload,
      type: ContentType.JSON,
      suppressNotification: true
    });
    obs$.subscribe(resp => {
      this.siqHttpService.showNotification(resp.status, overrideCodes);
    }, error => {
      console.error('error: %O', error);
      this.updateErrorResponseCode(error, overrideCodes);
      this.siqHttpService.showNotification(error.status, overrideCodes);
    });

    return obs$;
  }

  public deleteFilterset(filterSetId: number): Observable<HttpResponse<any>> {
    let overrideCodes: ResponseCodes = this.getResponseCodes();
    let crudConfig: CrudConfig = {
      endpoint: FiltersetsService.path + '/' + filterSetId,
      suppressNotification: true
    };
    let obs: Observable<HttpResponse<any>> = this.siqHttpService.remove(crudConfig);
    obs.subscribe(resp => {
      this.siqHttpService.showNotification(resp.status, overrideCodes);
      this.emitDeleteEvent(); // this is to notify filterset-grid to refresh
    }, error => {
      console.error('error: %O', error);
      this.updateErrorResponseCode(error, overrideCodes);
      this.siqHttpService.showNotification(error.status, overrideCodes);
    });
    return obs;
  }

  // called when delete button is clicked
  public emitDeleteEvent() {
    this.deleteClicked$.next('');
  }

  public deleteButtonClicked() {
    return this.deleteClicked$;
  }

  private getResponseCodes(): ResponseCodes {

    let codes = [
      new ResponseCode(
        500,
        '',
        'Error Saving Filterset'
      ),
      new ResponseCode(
        200,
        this.notificationMessages.saveFilterset.message,
        this.notificationMessages.saveFilterset.title,
        NotificationType.INFO
      ),
      new ResponseCode(
        204,
        this.notificationMessages.deleteFilterset.message,
        this.notificationMessages.deleteFilterset.title,
        NotificationType.INFO
      )
    ];

    return new ResponseCodes (
      codes
    );
  }

  private updateErrorResponseCode(res: Observable<HttpResponse<any>>, overrideCodes: ResponseCodes): ResponseCodes {
    let e = overrideCodes.get(500);
    if (e) {
      e[0].setMessage('Error: ' + res.toString());
    }
    return overrideCodes;
  }

  /*
  FilterSelection: using CmsField, for display and selection.
  Filterset: not using CmsField, for saving and retrieving from BE.
  */
  public getFilerSelectionsFromFiltersets(filterSets: Filterset[]): FilterSelection[] {
    let filterSelections: FilterSelection[] = [];
    filterSets.forEach(f => {
      let thisFilterSelectionJson: FilterSelectionJson = new FilterSelection({
        id: f.id,
        include: f.include,
        nulls: f.inNull || f.outNull,
        values: f.values
      });
      let thisFilterSelection: FilterSelection = new FilterSelection(thisFilterSelectionJson);
      filterSelections.push(thisFilterSelection);
    });
    return filterSelections;
  }
}

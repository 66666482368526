import { Injectable } from '@angular/core';
import { Color, Scale } from 'chroma-js';
import * as chroma from 'chroma-js';
import * as _ from 'lodash';
import { fabric } from 'fabric';
import {
  ColDef,
  GridComponent,
  LegendData,
  MetricColData
} from 'libs/visual-lib/src';
import { GridService } from 'libs/visual-lib/src/lib/modules/grid/services/grid.service';

@Injectable({
  providedIn: 'root'
})
export class HeatmapService {

  /**
   * Accepts a hexcode for a color and determine a light or dark contrast color for best readability.
   * Used to apply text over shaded cells in heatmaps.
   * Sourced from: https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
   */
  static getContrastColor(hexColor: string): string {
    const DARK = '#000';
    const LIGHT = '#fff';
    const hex = hexColor.replace(/#/, '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    const val = 1 - ([
      0.299 * r,
      0.587 * g,
      0.114 * b
    ].reduce((total, currentValue) => total + currentValue) / 255);

    return val < 0.5 ? DARK : LIGHT;
  }

  /**
   * This uses (as default) a color scheme that is highly visible for color-blind individuals
   * @param colors
   * @param domain
   */
  static getScale(colors?: Color | string, domain?: number[]) {

    let scale: Scale;

    // https://www.thedataschool.co.uk/gwilym-lockwood/viridis-colours-tableau/
    const viridis = [
      '#440154FF',
      '#481567FF',
      '#482677FF',
      '#453781FF',
      '#404788FF',
      '#39568CFF',
      '#33638DFF',
      '#2D708EFF',
      '#287D8EFF',
      '#238A8DFF',
      '#1F968BFF',
      '#20A387FF',
      '#29AF7FFF',
      '#3CBB75FF',
      '#55C667FF',
      '#73D055FF',
      '#95D840FF',
      '#B8DE29FF',
      '#DCE319FF',
      '#FDE725FF'
    ];

    if (colors && colors !== HeatmapService.COLOR_SCALE_VIRIDIS) {
      scale = chroma.scale(colors);
    } else {
      scale = chroma.scale(viridis);
    }

    if (domain) {
      scale.domain(domain);
    }

    return scale;

  }

  /**
   * Takes a fact and renders the legend for it.
   *
   * @param colDef
   * @param grid
   */
  static renderLegend(colDef: ColDef, grid: GridComponent): void {
    // Get legend data
    let legendData = grid.legends.get(colDef.colId);

    // Clear the existing legend
    grid.legend.clear();

    // Set legend parameters
    grid.legend.add(legendData.labels[0]);
    grid.legend.add(legendData.labels[1]);
    grid.legend.add(legendData.group);
    grid.legend.setDimensions(legendData.dimensions);

    // Render the legend
    grid.legend.renderAll();
  }

  /**
   * Takes an array of facts, and creates and stores maps for scale and legend.
   */
  static setupHeatmap(grid: GridComponent): void {
    // Initialize scale and legend maps
    grid.scales = new Map<string, Scale>();
    grid.legends = new Map<string, LegendData>();

    const metricColData = GridService.getMetricColData(grid.gridOptions.columnDefs, grid.gridSettings.gridVisualOptions.metrics);
    _.each(metricColData, (mcd: MetricColData) => {
      grid.scales.set(mcd.key, this.setupScale(mcd.key, grid.gridSettings.data)); // The processed data
      grid.legends.set(mcd.key, this.setupLegend(mcd, grid.scales));
    });
  }

  /**
   * Takes a fact as an input, and returns an object containing legend data.
   *
   * @param mcd
   * @param scales
   * @returns {object} An object containing data to display the legend
   */
  static setupLegend(mcd: MetricColData, scales: Map<string, Scale>): LegendData {
    // Scale and domain data
    let scale: Scale = scales.get(mcd.key);
    let domain: any = scale.domain();

    // Genderate label data
    let blockSize = 15;
    let step = (domain[1] - domain[0]) / 4;
    let steps = _.range(5).reduce((p, c, k) => {
      p.push(domain[0] + step * k);
      return p;
    }, []);
    let labelSpace = 6;

    let labels: fabric.Text[] = [];

    let textFormatter = GridService.getVisualType(mcd.metric).formatter;
    labels[0] = this.getNewFabricText(textFormatter({value: domain[0]}), {
      originX: 'left',
      left: 10,
      top: 10 + (blockSize + 2) / 2
    });

    labels[1] = this.getNewFabricText(textFormatter({value: domain[1]}), {
      originX: 'left',
      left: 10 + blockSize * steps.length + 2 * (steps.length + 1) + labels[0].width + labelSpace * 2,
      top: 10 + (blockSize + 2) / 2
    });

    // Generate group data
    let groupItems = [];

    for (let i = 0; i < steps.length; i++ ) {

      groupItems.push(new fabric.Rect({
        top: 10,
        left: 10 + 2 + labels[0].width + i * (blockSize + 2) + labelSpace,
        width: blockSize,
        height: blockSize,
        fill: scale(steps[i]),
        selectable: false,
        hoverCursor: 'default'
      }));

    }

    let group = new fabric.Group(groupItems, {
      selectable: false,
      hoverCursor: 'default'
    });

    // Generate dimension data
    let legendWidth = 10 * 2 + labels[0].width + labels[1].width + group.width + 10;
    let legendHeight = 10 * 2 + blockSize + 2;

    // Return data
    return { labels: labels, group: group, dimensions: { width: legendWidth, height: legendHeight } };
  }

  static setupScale(factKey: string, data: any[]): Scale {
    let min: number;
    let max: number;
    try {
      min = _.minBy(data, (p) => {
        return _.isFinite(p[factKey].val) ? p[factKey].val : Infinity;
      });

      if (min) {
        min = min[factKey].val;
      }

      max = _.maxBy(data, (p) => {
        return _.isFinite(p[factKey].val) ? p[factKey].val : -Infinity;
      });

      if (max) {
        max = max[factKey].val;
      }

    } catch (err) {
      console.error('Error setting min/max for Heatmap Scale: %O', err);

    } finally {
      // 1: In case (if factKey is not found), return a value of [0, 1]
      // 2: In case min[factKey].val or max[factKey].val are null
      min = !_.isNil(min) ? min : 0;
      max = !_.isNil(max) ? max : min + 1;
    }

    return HeatmapService.getScale(HeatmapService.COLOR_SCALE_VIRIDIS, [min, max]);
  }

  private static getNewFabricText (text: string, overrideOptions?): fabric.Text {

    let options = <any>{
      fontSize: 12,
      fontWeight: '500',
      fontFamily: window.getComputedStyle(document.documentElement).getPropertyValue('--font-family').trim(), // css3 variable from :root{} section in variables.scss
      originY: 'center',
      originX: 'center',
      selectable: false,
      hoverCursor: 'default',
      fill: '#000000'
    };

    return new fabric.Text(text.toString(), _.extend(options, overrideOptions));

  }

  public static COLOR_SCALE_VIRIDIS = 'viridis';

  constructor() { }

}

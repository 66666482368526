<div class="siq-ace-wrapper">
  <ace
    [ace]="config"
    [(value)]="query"
    (change)="onContentChange($event)"
  >
  </ace>
</div>

<div class="{{ feedbackCss }}">
  <span>{{ feedback }}</span>
  <span>&nbsp;&nbsp;&nbsp;{{ status }}</span>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';

@Injectable({providedIn: 'root'})
export class AppGuard implements CanActivate {
  constructor(
    private router: Router,
    private asyncStatusService: AsyncStatusService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Ensure the ApplicationService.items is ready and populated for these checks
    //
    // https://pdisoftware.atlassian.net/browse/ICE-3394:
    // also wait for envConfig, cms and filterValues, so QueryModeComponent will be usable on page init
    await this.asyncStatusService.isReady({ applications: true, envConfig: true, cms: true, filterValues: true });

    if (state.url.includes('export')) return true;

    return new Promise<boolean | UrlTree>(
      (resolve, reject) => {
        try {
          const appHash = route.data.applicationHash;
          if (ApplicationService.find(appHash)) {
            resolve(true);
          } else {
            resolve(this.router.createUrlTree(['/welcome']));
          }
        } catch (err) {
          console.error('err: %O', err);
          reject(false);
        }
      });
  }
}

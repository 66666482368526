import { AuthGuard } from 'app/guards/auth-guard';
import { NewUserComponent } from 'app/core/components/new-user/new-user.component';
import { NewUserService } from 'app/core/services/new-user/new-user.service';
import { Routes } from '@angular/router';

export const newUserRoutes: Routes = [
  {
    path: NewUserService.defaultPath,
    component: NewUserComponent,
    canActivate: [ AuthGuard ]
  }
];

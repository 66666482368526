import { Activity } from 'app/activity/models/activity.model';
import {
  Component,
  Input,
  OnDestroy,
  OnInit, QueryList, ViewChildren
} from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs';
import { DropdownMenuItem } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.interface';
import { Observable, Subject } from 'rxjs';
import { ProgressIndicatorColorType } from '@siq-js/visual-lib';
import { DropdownMenuItemComponent } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';

@Component({
  selector: 'siq-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit, OnDestroy {

  public exposedTree: DropdownMenuItem<Activity|void>[] = [];

  @Input() public rootLabel = 'My Menu';
  @Input() public hideCaret = false;
  @Input() public notifyCount = 0;
  @Input() public notifyColor: ProgressIndicatorColorType = ProgressIndicatorColorType.ACCENT;
  @Input() private tree: DropdownMenuItem<Activity|void>[] | Observable<DropdownMenuItem<Activity|void>[]>;

  @ViewChildren(DropdownMenuItemComponent) public dropdownMenuItemComponents: QueryList<DropdownMenuItemComponent>;

  protected unsub = new Subject<void>();

  constructor() { }

  ngOnInit () {
    if (this.tree instanceof Observable) {
      this.tree
        .pipe(
          debounceTime(250),
          takeUntil(this.unsub)
        )
        .subscribe(
          res => this.growTree(res)
        );
    } else {
      this.exposedTree = this.tree;
    }
  }

  ngOnDestroy () {
    this.unsub.next();
  }

  private growTree (tree: DropdownMenuItem<Activity|void>[]) {
    // Last minute sanity checl before view update
    this.exposedTree = tree;
  }

  public closeOpenedMenus(submenu) {
    // Close any other menus that are already open
    this.dropdownMenuItemComponents.filter(dropdownMenuItemComponent => {
      return dropdownMenuItemComponent.item.children
        && dropdownMenuItemComponent.menuTrigger.menuOpen
        && (dropdownMenuItemComponent.item !== submenu);
    }).forEach(menuItem => {
      menuItem.menuTrigger.closeMenu();
    });
  }
}

import { ApplicationHash } from '@siq-js/core-lib';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { CmsApplication } from '@siq-js/cms-lib';

export abstract class SiqApplicationConfig {

  public abstract appId: ApplicationHash;
  public version: string;

  public getApplication(): CmsApplication {
    return ApplicationService.find(this.appId);
  }

  protected constructor() { }
}

import * as _ from 'lodash';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { User } from 'app/user/models/user.model';
import { AccessGroupService } from 'app/access-group/services/access-group.service';
import { ContentType, CrudConfig, ResponseType } from '@siq-js/core-lib';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { SiqHttpService } from 'app/core/services/siq-http/siq-http.service';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';
import { ActivityService } from 'app/activity/services/activity.service';

@Injectable()
export class AuthService {

  public static AdminGroupId: string;
  public static CurrentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  private static readonly superUserTests = [ /.*swiftiq\.com$/ ];

  constructor(
    @Inject(forwardRef(() => AccessGroupService)) private accessGroupService: AccessGroupService,
    private siqHttpService: SiqHttpService,
    private mixpanelService: MixpanelService
  ) {
  }

  public isSuperUser(): boolean {
    const user = AuthService.CurrentUser$.getValue();
    return _.some(AuthService.superUserTests, test => test.test(user.email));
  }

  public login (name: string, pwd: string): Observable<User> {

    let body = 'login=' + encodeURIComponent(name) + '&password=' + encodeURIComponent(pwd);

    const crudConfig: CrudConfig = {
      endpoint: 'user/login',
      type: ContentType.FORM_URL_ENCODED,
      body: body,
      additionalConfig: { withCredentials: 'true' },
      suppressNotification: true
    };
    return this.siqHttpService.create(crudConfig)
    .pipe(
      map((res) => {
        if (res.body['passwordExpired']) {
          res.body['password'] = pwd;
        }
        return this.handleLoginSuccess(new User(res.body));
      })
    );

  }

  public logout (): Observable<any> {
    this.setCurrentUser(null);

    this.destroyStorage();

    ThemesService.setTheme(); // reset to light-theme (default)

    ActivityService.Activities$.next([]); // clear all activities

    const crudConfig: CrudConfig = {
      endpoint: 'user/logout',
      type: ContentType.FORM_URL_ENCODED,
      body: {},
      additionalHeaders: { responseType: ResponseType.TEXT },
      additionalConfig: { withCredentials: 'true' },
      suppressNotification: true
    };
    return this.siqHttpService.create(crudConfig).pipe(
      tap(() => {
        this.mixpanelService.track(MixpanelEvent.LOGOUT);
        this.mixpanelService.logout();
      }));
  }

  public refreshToken (): Observable<User> {

    const crudConfig: CrudConfig = {
      endpoint: 'user/status',
      type: ContentType.FORM_URL_ENCODED,
      body: {},
      additionalConfig: { withCredentials: 'true' },
      suppressNotification: true
    };
    return this.siqHttpService.create(crudConfig)
      .pipe(
        map((res) => this.handleLoginSuccess(new User(res.body)))
      );
  }

  public setCurrentUser (user?: User): void {
    AuthService.CurrentUser$.next(user);
  }

  /**
   * Destroys a specific cookie.
   *
   * @param {string} name The name of the cookie.
   */
  private destroyCookie (name: string): void {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }

  /**
   * Destroys all cookies.
   *
   */
  private destroyCookies (): void {
    let cookies: string = document.cookie;

    for (let cookie of cookies.split(';')) {
      let index: number = cookie.indexOf('=');
      let name: string = (-1 < index ? cookie.substr(0, index) : cookie);
      this.destroyCookie(name);
    }
  }

  /**
   * Destroys all local storage items, including cookies, LocalStorage, and SessionStorage.
   *
   * @private authService
  */
  private destroyStorage (): void {
    this.destroyCookies();
    localStorage.clear();
    sessionStorage.clear();
  }

  private handleLoginSuccess(user: User): User {
    this.setCurrentUser(user);
    this.accessGroupService.validateGroupStatus(user.accessGroup);
    this.mixpanelService.track(MixpanelEvent.LOGIN);
    return user;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { FilterGroupComponent } from 'app/filter/components/filter-group/filter-group.component';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { SameStoreSalesService } from 'app/siq-forms/modules/same-store-sales/services/same-store-sales.service';

@Component({
  selector: 'siq-filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss']
})
export class FilterChipComponent extends BaseSiqComponent implements OnInit {

  @Input() model: FilterSelection;
  @Input() parent: FilterGroupComponent;

  public label: string;
  public toolTip: string;

  constructor(
    private asyncStatusService: AsyncStatusService,
    private utils: UtilsService
  ) {
    super();
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({cms: true});
    this.ready();
  }

  public edit() {
    this.parent.editFilter(this.model.field, this.parent.isColumnFilter);
  }

  public remove() {
    this.utils
      .confirmDialog(`Remove ${this.model.field.display} filter?`)
      .afterClosed()
      .subscribe(res => {
        if (res === 'confirm') {
          this.parent.removeFilter(this.model.field.id);
        }
      });
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AgCartesianChartOptions, AgChartOptions, AgLineSeriesOptions } from 'libs/visual-lib/src';
import { BaseVisualizationComponent } from 'libs/visual-lib/src/lib/components/base-visualization/base-visualization.component';
import {
  ChartSettings,
  SimpleLineApi,
  SimpleLineConfig,
  SimpleLineParams
} from 'libs/visual-lib/src/lib/modules/charts-graphs/models';
import * as _ from 'lodash';
import { filter, takeUntil } from 'rxjs';
import { ThemesService } from '@siq-js/angular-buildable-lib';
import { ChartsService } from 'libs/visual-lib/src/lib/modules/charts-graphs/services/charts.service';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'siq-js-simple-line',
  templateUrl: './simple-line.component.html',
  styleUrls: ['./simple-line.component.scss']
})
export class SimpleLineComponent extends BaseVisualizationComponent<any, any, SimpleLineConfig, SimpleLineApi> implements OnInit, AfterViewInit {

  private static generateDefaultChartOptions(component: SimpleLineComponent): AgChartOptions {
    let typeSafe:AgCartesianChartOptions = {
      autoSize: true,
      theme: ChartsService.getThemePdi(),
      // data: [],
      series: [
        {
          xKey: 'quarter',
          yKey: 'spending',
          type: 'line'
        }
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            formatter: (params) => { // This formats the x-axis label
              // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
              return params.value;
            }
          },
          title: {
            enabled: true,
            text: 'Dimension Axis Title'
          },
          tick: {
            minSpacing: 20
          }
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: (params) => { // This formats the y-axis label
              // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
              return params.value;
            }
          },
          title: {
            enabled: true,
            text: 'Metric Axis Title'
          }
        }
      ]
    };
    return <AgChartOptions>typeSafe;
  }

  public agChartOptions: AgChartOptions;

  /**
   * The SimpleLineComponent accepts two parameters (via BaseVisualizationComponent):
   * @data: SimpleLineParams
   *  dimensions: CmsField[]; // Immutable array of all possible Dimension(s) that will be on the x-axis
   *  metrics: CmsMetric[]; // Immutable array of all possible CmsMetrics to display in the chart (1-n);
   *                        // Chart will create series data for all of these upon instantiation
   *  parent: BaseSiqComponent;
   *  parentActivity: ActivityInterface;
   *  selectedDimension$: BehaviorSubject<CmsField>; // The Dimension to show on the x-axis; Chart subscribes to this and is updated upon changes
   *  selectedMetrics$: BehaviorSubject<CmsMetric[]>; // The CmsMetric(s) to show in the chart (1-n); Chart subscribes to this and is updated upon changes
   *                                                  // Only CmsMetrics that appear in the value of this BehaviorSubject will be displayed (visible:true)
   *  statResponse: IStatResponse;
   *
   * @processor: function that takes in SimpleLineParams and outputs ChartSettings
   *
   */
  constructor() {
    super();
  }

  getApi(): SimpleLineApi {
    return {
      component: this,
      chartOptions: this.agChartOptions
    };
  }

  ngOnInit(): void {
    super.ngOnInit();

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      const updatedChartOptions = <AgChartOptions>{...this.agChartOptions};
      updatedChartOptions.theme = ChartsService.getChartThemeObject(theme);
      updatedChartOptions.background = ChartsService.getChartBackgroundForTheme();
      this.agChartOptions = updatedChartOptions;
    });
  }

  ngAfterViewInit(): void {
    (<SimpleLineParams>this.data).chart = this; // set pointer to this chart instance to be used by processor/other classes
  }

  protected render(chartSettings: ChartSettings): void {
    // Override config object with custom options
    this.agChartOptions = _.merge(SimpleLineComponent.generateDefaultChartOptions(this), this.config.agChartOptions);
    if ((<SimpleLineParams>this.data).selectedMetrics$) {
      (<SimpleLineParams>this.data).selectedMetrics$.pipe(
        filter(isNotNullOrUndefined),
        takeUntil(this.unsub$)
      )
      .subscribe(selectedMetrics => {
        const newChartOptions = _.cloneDeep(this.agChartOptions) as AgCartesianChartOptions;
        newChartOptions.series.forEach((serie: AgLineSeriesOptions) => {
          const matchMetric = _.find(selectedMetrics, {id:serie.yKey});

          serie.visible = !!matchMetric;
          if (serie.visible) {
            const yAxis = _.find(newChartOptions.axes, {type: 'number'});
            if (yAxis) {
              yAxis.title.text = matchMetric.display;
            }
          }
        });
        this.agChartOptions = newChartOptions;
      });
    }
  }

}

import { Serializable } from '@siq-js/core-lib';
import { CmsEntity, CmsEntityJson } from './cms-entity';

export interface CmsFieldJson extends CmsEntityJson {
  retailer: string;
  table: string;
  field: string;

  // configurable
  filter: string;
  type: string;
  excelFormula?: string;
}

export class CmsField extends CmsEntity<CmsFieldJson> implements Serializable<CmsFieldJson> {
  retailer: string;
  table: string;
  field: string;
  filter: string;
  type: string;

  excelFormula: string;

  constructor(model: CmsFieldJson) {
    super(model);

    this.retailer = model.retailer;
    this.table = model.table;
    this.field = model.field;
    this.filter = model.filter;
    this.type = model.type;
    this.excelFormula = model.excelFormula || '';
  }

  toJson(): CmsFieldJson {
    return {
      ...super.toJson(),
      retailer: this.retailer,
      table: this.table,
      field: this.field,
      filter: this.filter,
      type: this.field,
      excelFormula: this.excelFormula
    };
  }

  isNull(): boolean {
    return this.type === 'NULL';
  }

  clone(): CmsField {
    return new CmsField(this.toJson());
  }
}

import { CmsField, CmsMetric } from '@siq-js/cms-lib';
import { CmsService } from '../services/cms/cms.service';
import { Activity } from 'app/activity/models/activity.model';
import { BaseModel } from 'app/core/models/base-model';
import { IStatResponse, VisualService } from '@siq-js/visual-lib';

export class StatResponse extends BaseModel implements IStatResponse {

  static fromJson<T>(j: any, type: new () => T, parseKeys?: string[]): StatResponse {
    const sr = <StatResponse><any>super.typedObjectFromJson(j, type, parseKeys);

    sr.facts = sr.facts.map(mJson => CmsService.get().findEntity<CmsMetric>(mJson.id) ?? VisualService.factToCmsMetric(mJson)); // The Affinity response's fact's id is not found in CMS
    sr.dimensions = sr.dimensions.map(dJson => CmsService.get().findEntity<CmsField>(dJson.id) ?? VisualService.dimensionToCmsField(dJson)); // The Affinity response's dimension's id is not found in CMS
    return sr;
  }
  public dimensionMatrix: string[][];

  public dimensions: CmsField[];
  public facts: CmsMetric[];
  public jobId: string;
  public nextPageToken?: string;
  public parentActivity: Activity;
  public populated: boolean; // DEPRECATED  TODO: going away soon
  public title: string;
  public valuesMatrix: string[][];
  public weightsValues: string[][];

  constructor(
    dimensions: CmsField[] = [],
    dimensionMatrix: string[][] = [],
    metrics: CmsMetric[] = [],
    valuesMatrix: string[][] = [],
    weightsValues: string[][] = [],
    jobId?: string,
    nextPageToken?: string,
    populated?: boolean,
    title?: string
  ) {
    super();

    this.dimensions = dimensions || [];
    this.dimensionMatrix = dimensionMatrix || [];
    this.facts = metrics || [];
    this.jobId = jobId || '';
    this.nextPageToken = nextPageToken;
    this.populated = populated || false;
    this.title = title || '';
    this.valuesMatrix = valuesMatrix || [];
    this.weightsValues = weightsValues || [];
  }

  getDimensions(includeNulls?: boolean): CmsField[] {
    return this.dimensions.filter(d => includeNulls || d.id !== 'NULL');
  }

  getDimensionValues(): string[][] {
    return this.dimensionMatrix;
  }

  getFacts(includeNulls?: boolean): CmsMetric[] {
    return this.facts.filter(f => includeNulls || f.id !== 'NULL');
  }

  getNextPageToken(): string {
    return this.nextPageToken;
  }

  getParentActivity(): Activity {
    return this.parentActivity;
  }

  getTitle(): string {
    return this.title;
  }

  getValues(): string[][] {
    return this.valuesMatrix;
  }

  getWeights(): string[][] {
    return this.weightsValues;
  }

  isPopulated(): boolean {
    return this.populated;
  }

  setParentActivity(a: Activity) {
    this.parentActivity = a;
  }

}

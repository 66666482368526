<div class="selector-container">

  <form [formGroup]="form" class="selector-form">
    <mat-grid-list [cols]="maxCount" rowHeight="82" gutterSize="24" formArrayName="selectorForms">
      <mat-grid-tile [colspan]="1" *ngFor="let selector of selectorForms.controls; index as i" [formGroupName]="i">
        <div class="entity-selector">
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="{{placeholders[i]}}"
              formControlName="name"
              (focus)="onFocus(i)"
              (keyup)="onChange($event.target.value, i)"
              [ngModel]="selectedEntities[i]?.display"
              required
              [matAutocomplete]="autoGroup"
            >
            <button
              mat-button
              *ngIf="selectedEntities[i]?.display"
              matSuffix mat-icon-button
              aria-label="Clear"
              (click)="reset(i)"
            >
              <mat-icon>close</mat-icon>
            </button>

            <mat-autocomplete #autoGroup="matAutocomplete">
              <ng-container *ngFor="let item of entityOptions">

                <mat-optgroup *ngIf="item.group" [label]="item.group">
                  <mat-option *ngFor="let child of item.children" [value]="child.display" (click)="onSelectOption(child)">
                    {{child.display}}
                  </mat-option>
                </mat-optgroup>

                <mat-option *ngIf="!item.group" [value]="item.display" (click)="onSelectOption(item)">
                  {{item.display}}
                </mat-option>

              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <span *ngIf="i > minCount - 1" class="entity-remove" (click)="removeEntity(i)">Remove</span>
        </div>
      </mat-grid-tile>

    </mat-grid-list >
  </form >

  <button
    class="add-entity-button gray-button"
    *ngIf="maxCount > 1 && formArray.length < maxCount"
    mat-raised-button
    (click)="addEntity()"
    [disabled]="!allowAdd"
    >Add
  </button>

</div>

import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'app/siq-applications/services/application/application.service';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { CmsApplication } from '@siq-js/cms-lib';
import { ApplicationHash } from '@siq-js/core-lib';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

@Component({
  selector: 'siq-app-tiles',
  templateUrl: './app-tiles.component.html',
  styleUrls: ['./app-tiles.component.scss']
})
export class AppTilesComponent implements OnInit {

  public applications: CmsApplication[];

  constructor(private asyncStatusService: AsyncStatusService) {
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({ applications: true });

    this.applications = ApplicationService.flattenedApplications;

    // TEMP (until all get adjusted inside of CMS itself (if desired))
    this.applications.forEach(cmsApplication => {
      switch (cmsApplication.id) {
        case ApplicationHash.REPORT_BUILDER:
          cmsApplication.color = THEME_COLORS.GRASS;
          break;
        case ApplicationHash.AFFINITIES:
          cmsApplication.color = THEME_COLORS.SKY;
          break;
        case ApplicationHash.INCREMENTALS:
          cmsApplication.color = THEME_COLORS.OCEAN;
          break;
        case ApplicationHash.DAYPARTS:
          cmsApplication.color = THEME_COLORS.SUNSHINE;
          break;
        case ApplicationHash.PROMO:
          cmsApplication.color = THEME_COLORS.INDIGO;
          break;
      }
    });
  }
}

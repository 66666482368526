<siq-nav-primary (click)="onGlobalClick($event)"></siq-nav-primary>
<div toastContainer></div>

<mat-drawer-container [hasBackdrop]="false" (click)="onGlobalClick($event)">

  <mat-drawer
    #drawer
    mode="over"
    position="end"
  >
    <siq-drawer [drawer]="drawer"></siq-drawer>
  </mat-drawer>

  <mat-drawer-content>

    <mat-sidenav-container
      [ngClass]="isLoggedIn ? 'logged-in' : 'logged-out'"
      class="pdi-side-nav"
    >
      <mat-sidenav
        mode="side"
        opened="false"
        (openedChange)="sidenavOpenedChange($event)"
      >
        <siq-nav-secondary></siq-nav-secondary>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="router-footer-container">
          <div class="fill-space">
            <router-outlet></router-outlet>
          </div>

          <mat-toolbar-row>
            <span class="fill-space"></span>
            <p>©2015-{{curYear}} <a href="https://pditechnologies.com/gain-insights/insights-analytics/" target="_blank">PDI Technologies</a></p>
          </mat-toolbar-row>

          <div class="top-progress-bar-wrapper">
            <visual-lib-progress-bar
              [color]="progressIndicatorColorType"
              [mode]="progressIndicatorType"
              [isLoading]="isLoading"
            ></visual-lib-progress-bar>
          </div>

        </div>
      </mat-sidenav-content>

    </mat-sidenav-container>

  </mat-drawer-content>

</mat-drawer-container>

<router-outlet name="m"></router-outlet>
<siq-cloud-export></siq-cloud-export>

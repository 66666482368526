<siq-progress-spinner [show]="isLoading" [overlay]="true"></siq-progress-spinner>

<div class="app-container-wrapper app-header">
  <section class="app-container app-header-container">
    <h5 class="app-title">Manage User Groups</h5>
  </section>
</div>

<div class="app-container-wrapper no-bg">
  <div class="app-container limited-width-content content-padded">

<mat-card>
  <mat-card-content>

    <div #grid class="grid-container">
      <ag-grid-angular #accessGroupListGrid
        *ngIf="gridOptions"
        class="{{ agTheme }}"
        [gridOptions]="gridOptions"
      ></ag-grid-angular>
    </div>

  </mat-card-content>
</mat-card>

  </div>
</div>

<div class="container" *ngIf="!isLoading && isAdminGroup">
  <button mat-raised-button color="accent" class="new-access-group" (click)="create()">Create User Group</button>
</div>

import { getCmsMetricDataValue } from '@siq-js/core-lib';
import {
  AgCartesianChartOptions,
  AgChartOptions,
  AgScatterSeriesOptions,
  BaseChartSettings,
  BubbleParams,
  ChartsService,
  GridService,
  SimpleBubbleProcessor
} from '@siq-js/visual-lib';
import { take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { PromoActivity } from 'app/siq-applications/modules/promo/models/promo-activity.model';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { PromoListComponent } from 'app/siq-applications/modules/promo/components/promo-list/promo-list.component';
import { ActivityStatus } from 'app/activity/models/activity.model';
import { Observable } from 'rxjs';
import { ActivityService } from 'app/activity/services/activity.service';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';
import { AgBubbleSeriesOptions } from 'ag-charts-community';

export class PromoListBubbleProcessor extends SimpleBubbleProcessor {
  private static PDI_LIGHT_BLUE = THEME_COLORS.SKY;
  private static PDI_DARK_BLUE = THEME_COLORS.INDIGO;
  private static POSITIVE_COLOR = THEME_COLORS.SUCCESS_GREEN;
  private static NEGATIVE_COLOR = THEME_COLORS.ALERT_RED;
  private static X_METRIC = 'X_METRIC';
  private static Y_METRIC = 'Y_METRIC';
  private static Z_METRIC = 'Z_METRIC';

  public static processor(bubbleParams: BubbleParams): BaseChartSettings {
    const _data = PromoListBubbleProcessor.processData(bubbleParams);
    const formatterX = GridService.getVisualType(bubbleParams.metrics.value[0]).formatter;
    const formatterY = GridService.getVisualType(bubbleParams.metrics.value[1]).formatter;

    let chartOptions: AgChartOptions = {
      theme: ChartsService.getThemePdi(),
      // No "data: []" here as each entry in the ".series" collection will set its own data attribute
      series: [],
      axes: [
        {
          position: 'bottom',
          type: 'number',
          label: {
            formatter: (params) => { // This formats the x-axis label
              // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
              return formatterX({value: params.value}, true);
            }
          },
          title: {
            enabled: true,
            fontWeight: 'bold',
            text: bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.X_METRIC).display
          },
        },
        {
          position: 'left',
          type: 'number',
          label: {
            formatter: (params) => { // This formats the x-axis label
              // params: ParamsType ( https://www.ag-grid.com/javascript-charts-api-explorer/ )
              return formatterY({value: params.value}, true);
            }
          },
          title: {
            enabled: true,
            fontWeight: 'bold',
            text: bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.Y_METRIC).display
          },
        },
      ],
      title: {
        enabled:true,
        text: 'Activity Measurement Evaluation',
        fontWeight: 'bold',
        fontSize: 18,
      },
      navigator: {
        enabled: true,
        height: 25,
        mask: {
          fill: 'gray',
          strokeWidth: 1,
          fillOpacity: 0.3,
        },
        minHandle: {
          stroke: 'black',
          width: 15,
          height: 27,
        },
        maxHandle: {
          stroke: 'black',
          width: 15,
          height: 27,
        },
      },
    };

    (<AgCartesianChartOptions>chartOptions).series.push(<AgBubbleSeriesOptions>{
      type: 'bubble',
      xKey: PromoListBubbleProcessor.X_METRIC,
      yKey: PromoListBubbleProcessor.Y_METRIC,
      xName: bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.X_METRIC).display,
      yName: bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.Y_METRIC).display,
      listeners: {
        nodeClick: (e) => {
          const activity = bubbleParams.activityStream$.getValue().find(a => a.id === e.datum.promoId) as PromoActivity;
          switch (activity.getStatus()) {
            case ActivityStatus.READY:
              bubbleParams.router.navigate([(bubbleParams.parent as PromoListComponent).appPath, e.datum.promoId]);
              break;
            case ActivityStatus.EXPIRED:
              const asyncResult = bubbleParams.activityService.rerun(activity);
              if (asyncResult instanceof Observable) {
                asyncResult
                  .pipe(take(1))
                  .subscribe(() => ActivityService.refresh());
              }
              break;
          }

        }
      },
      marker: {
        fill: PromoListBubbleProcessor.PDI_LIGHT_BLUE,
        stroke: PromoListBubbleProcessor.PDI_LIGHT_BLUE,
        strokeOpacity: 0,
        size: 15,
        maxSize: 50,
        strokeWidth: 1,
        formatter: (params) => {
          return {
            fill: params['highlighted']
              ? params['fill']
              : PromoConfig.calculateBubbleColor(params['datum']['promoType'], bubbleParams.parent['promoTypes'])
          };
        }
      },
      highlightStyle: {
        item : {
          fill: PromoListBubbleProcessor.PDI_LIGHT_BLUE,
          stroke: PromoListBubbleProcessor.PDI_LIGHT_BLUE,
          fillOpacity: 0.5
        }
      },
      data: _data,
      tooltip:  {
        renderer: (params) => {
          const xVal = params.datum[PromoListBubbleProcessor.X_METRIC];
          const yVal = params.datum[PromoListBubbleProcessor.Y_METRIC];
          const zVal = params.datum[PromoListBubbleProcessor.Z_METRIC];
          const xColor = xVal > 0 ? PromoListBubbleProcessor.POSITIVE_COLOR : PromoListBubbleProcessor.NEGATIVE_COLOR;
          const yColor = yVal > 0 ? PromoListBubbleProcessor.POSITIVE_COLOR : PromoListBubbleProcessor.NEGATIVE_COLOR;
          const zColor = zVal > 0 ? PromoListBubbleProcessor.POSITIVE_COLOR : PromoListBubbleProcessor.NEGATIVE_COLOR;
  
          const fX = GridService.getVisualType(bubbleParams.metrics.value[0]).formatter;
          const fY = GridService.getVisualType(bubbleParams.metrics.value[1]).formatter;
          const fZ = GridService.getVisualType(bubbleParams.metrics.value[2]).formatter;
          return (
            '<div class="ag-chart-tooltip-title" style="background-color:' + PromoListBubbleProcessor.PDI_LIGHT_BLUE + '">' +
              params.datum.promoName +
            '</div>' +
            '<div class="ag-chart-tooltip-content">' +
              bubbleParams.metrics.value[0].display + ': ' + '<span style="color: ' + xColor + '">' + fX({value: xVal}) + '</span>' +
              '<br />' +
              bubbleParams.metrics.value[1].display + ': ' + '<span style="color: ' + yColor + '">' + fY({value: yVal}) + '</span>' +
              '<br />' +
              bubbleParams.metrics.value[2].display + ': ' + '<span style="color: ' + zColor + '">' + fZ({value: zVal}) + '</span>' +
            '</div>'
          );
        }
      }
    });

    chartOptions.series[0]['sizeKey'] = PromoListBubbleProcessor.Z_METRIC;
    chartOptions.series[0]['sizeName'] = bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.Z_METRIC).display;

    bubbleParams.datasetChanged$.pipe(
      takeUntil(bubbleParams.parent.unsub$)
    )
    .subscribe(() => {
      if (_.isEmpty(bubbleParams.metrics.value)) return;
      PromoListBubbleProcessor.updateEverything(bubbleParams.rawData.value, bubbleParams);
    });
    return <BaseChartSettings>{
      agChartOptions: chartOptions,
      parentActivity: null,
    };
  }

  public static processData(bubbleParams: BubbleParams): any[] {
    // Process the data
    const promoNames = bubbleParams.statResponse.value.getDimensionValues()[0].map((a: any) => a.metaData.name);
    const promoTypes = bubbleParams.statResponse.value.getDimensionValues()[0].map((a: any) => a.promoType);
    const promoIds = bubbleParams.statResponse.value.getDimensionValues()[0].map((a: any) => a.id);
    return bubbleParams.rawData.value.map((value: any, i: number) => {
      bubbleParams.dimensions.value.forEach(cmsField => {
        value[cmsField.id] = value[cmsField.id].toString();
      });

      const dimKeys = bubbleParams.dimensions.value.map(cmsField => cmsField.id);
      Object.keys(value).filter(key => !dimKeys.includes(key)).forEach(metricKey => {
        value[metricKey] = getCmsMetricDataValue(value, metricKey);
      });

      // inject promo name to the data
      value['promoName'] = promoNames[i];
      value['promoType'] = promoTypes[i];
      value['promoId'] = promoIds[i];
      return value;
    });
  }

  private static updateEverything(rawData: any[], bubbleParams: BubbleParams) {
    let newChartOptions = _.cloneDeep(bubbleParams.chart.getApi().chartOptions) as AgCartesianChartOptions;

    // Process the data
    const _data = PromoListBubbleProcessor.processData(bubbleParams);

    // declare the new selected metrics
    const newSelectedMetricX = bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.X_METRIC);
    const newSelectedMetricY = bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.Y_METRIC);
    const newSelectedMetricZ = bubbleParams.statResponse.value.facts.find(f => f.id === PromoListBubbleProcessor.Z_METRIC);

    let matchSeries: AgScatterSeriesOptions = <AgScatterSeriesOptions>newChartOptions.series[0];
    matchSeries.data = _data;

    matchSeries.xName = newSelectedMetricX.display;
    matchSeries.yName = newSelectedMetricY.display;
    matchSeries['sizeName'] = newSelectedMetricZ.display;

    let matchAxisX = newChartOptions.axes[0];
    let matchAxisY = newChartOptions.axes[1];
    const formatterX = GridService.getVisualType(newSelectedMetricX).formatter;
    const formatterY = GridService.getVisualType(newSelectedMetricY).formatter;

    matchAxisX.title.text = newSelectedMetricX.display;
    matchAxisY.title.text = newSelectedMetricY.display;
    matchAxisX.label.formatter = (params) => formatterX({value: params.value}, true);
    matchAxisY.label.formatter = (params) => formatterY({value: params.value}, true);

    if (bubbleParams.chart) {
      bubbleParams.chart.agChartOptions = newChartOptions;
    }
  }

}

<div class="access-group-changed-overlay">
  <mat-toolbar>
    <div class="align-left">
      <span class="application-name-in-reports">{{ reportActivityName}}</span>
    </div>
    <div class="align-right">
      <siq-js-activity-grid-options-menu
        (clickEvent)="clickEvent.emit($event)"
        [config]="menuItems"
      ></siq-js-activity-grid-options-menu>
    </div>
  </mat-toolbar>

  <mat-icon>priority_high</mat-icon>
  <p class="main">The Access Group for this sheet has been modified, you can still edit or clone this sheet</p>
</div>

import {
  AgBarSeriesOptions,
  AgCartesianChartOptions,
  BarColumnConfig,
  BarColumnParams,
  BarColumnProcessor,
  ChartSettings,
  ChartsService,
  GridService
} from '@siq-js/visual-lib';
import { PromoConfig } from 'app/siq-applications/modules/promo/models/promo-config.model';
import { filter, takeUntil } from 'rxjs';
import { TakeRateResultComponent } from 'app/siq-applications/modules/promo/components/promo-result/take-rate-result/take-rate-result.component';
import { THEME_COLORS } from 'app/core/models/constants/theme-colors';

export interface GrowersDecliners {
  growers: any[];
  decliners: any[];
}

export class TakeRateHelperChartProcessor extends BarColumnProcessor {
  public static X_LABEL_LENGTH = 50;
  public static generateBarColumnConfig(): BarColumnConfig {
    let config: BarColumnConfig = {
      agChartOptions: <AgCartesianChartOptions>{
        theme: ChartsService.getThemePdi()
      }
    };

    let typeSafe: AgCartesianChartOptions = {
      height: 500,
      title: {
        enabled: true,
        text: `Promoted Items Performance`,
        fontSize: 25,
        fontWeight: 'bold'
      },
      series: [], // series are generated by processor at run time to assign different colors for grower and decliner bars
      axes: [
        {
          type: 'number',
          position: 'bottom',
          title: {enabled: true},
          label: {
            rotation: 0
          }
        },
        {
          type: 'category',
          position: 'left',
          title: {enabled: false},
          label: {
            fontSize: 10,
            avoidCollisions: false,
            formatter: (params) => {
              if (params.value.length > TakeRateHelperChartProcessor.X_LABEL_LENGTH) {
                return params.value.slice(0, TakeRateHelperChartProcessor.X_LABEL_LENGTH) + '...';
              } else {
                return params.value;
              }
            }
          },
          gridLine: {
            style: [
              {
                stroke: 'lightgray',
                lineDash: [4,2],
              },
            ],
          },
        },
      ],
      legend: {enabled: false}
    };

    config.agChartOptions = typeSafe;
    return config;
  }

  public static processor = (params: BarColumnParams): ChartSettings => {
    let newChartOptions: AgCartesianChartOptions;
    params.rawData$.pipe(
      filter(data => !!data && data.length > 0),
      takeUntil(params.parent.unsub$)
    )
      .subscribe(data => {
        // Generate new series of growers and decliners
        const parent = (<TakeRateResultComponent>params.parent);
        const growersKey = 'grow';
        const declinersKey = 'decline';
        const dataSet = TakeRateHelperChartProcessor.categorizeData(data, parent.factKey.id, growersKey, declinersKey);
        const periodKey = parent.periodKey;
        const periodName = params.component.formData[PromoConfig.periodNames[periodKey]].customPeriodName;
        const tooltipRendererFcn = p => {
          const factKey = parent.factKey;
          const formatterY = GridService.getVisualType(factKey).formatter;
          return (
            '<div class="ag-chart-tooltip-title" style="background-color:' + p.color + '">' +
            p.datum[p.xKey] +
            '</div>' +
            '<div class="ag-chart-tooltip-content">' +
            '<span>' + parent.factKey.display + ' Change vs ' + periodName + ':' + formatterY({value: p.datum[parent.factKey.id]}) + '</span>' +
            '</div>'
          );
        };
        let growersSeries = {} as AgBarSeriesOptions;
        let declinersSeries = {} as AgBarSeriesOptions;
        const newSeries = [];
        if (dataSet.growers.length) {
          growersSeries.grouped = false;
          growersSeries.direction = 'horizontal';
          growersSeries.type = 'bar';
          growersSeries.fill = THEME_COLORS.SUCCESS_GREEN;
          growersSeries.stroke = THEME_COLORS.SUCCESS_GREEN;
          growersSeries.data = dataSet.growers;
          growersSeries.xKey = 'xKey';
          growersSeries.yKey = growersKey;
          growersSeries.tooltip = {renderer: tooltipRendererFcn};
          newSeries.push(growersSeries);
        }
        if (dataSet.decliners.length) {
          declinersSeries.grouped = false;
          declinersSeries.direction = 'horizontal';
          declinersSeries.type = 'bar';
          declinersSeries.fill = THEME_COLORS.SIENNA;
          declinersSeries.stroke = THEME_COLORS.SIENNA;
          declinersSeries.data = dataSet.decliners;
          declinersSeries.xKey = 'xKey';
          declinersSeries.yKey = declinersKey;
          declinersSeries.tooltip = {renderer: tooltipRendererFcn};
          newSeries.push(declinersSeries);
        }
        newChartOptions = {...params.chart.getApi().chartOptions} as AgCartesianChartOptions;
        newChartOptions.series = newSeries;
        let xAxis = newChartOptions.axes[0];
        const xFormatter = GridService.getVisualType(parent.factKey).formatter;
        xAxis.label.formatter = (p) => xFormatter({value: p.value}, true);
        xAxis.title.fontWeight = 'bold';
        xAxis.title.text = parent.factKey.display + ' Change vs ' + periodName;
        if (params.chart) {
          params.chart.agChartOptions = newChartOptions;
        }
        params.agChartOptions = newChartOptions;
      });
    return <ChartSettings>{
      agChartOptions: params.agChartOptions,
      parentActivity: params.parentActivity,
    };
  }

  public static categorizeData(rawData: any[], key: string, keyPositive: string, keyNegative: string): GrowersDecliners {
    const growers = [];
    const decliners = [];
    rawData.forEach(datum => {
      const val = GridService.getValueGetterFinalVal(datum[key], 'val');
      datum[key] = val;
      if (val && val >= 0) {
        datum[keyPositive] = val;
        growers.push(datum);
      }
      if (val && val < 0) {
        datum[keyNegative] = val;
        decliners.push(datum);
      }
    });
    return {
      growers: growers,
      decliners: decliners
    };
  }
}

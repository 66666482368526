import { IDoesFilterPassParams } from 'libs/visual-lib/src';
import { DateCategoryFilterComponent } from 'libs/visual-lib/src/lib/modules/custom-filters/components/date-category-filter/date-category-filter.component';
import { CustomFilterService } from 'libs/visual-lib/src/lib/modules/custom-filters/services/custom-filter.service';
import { Directive } from "@angular/core";
import {DateUnit} from '@siq-js/core-lib';

@Directive()
export abstract class DatesFilterComponent extends DateCategoryFilterComponent {
  private _precision: DateUnit;

  constructor() {
    super();
  }

  /**
   * Checks a single row, as passed to it, to see if it falls inside of the specified filter criteria.
   *
   * @param {IDoesFilterPassParams} params A IDoesFilterPassParams object containing a reference to a single cell.
   * @returns {boolean} A boolean variable indicating if the row passes the filter criteria or not.
   *
   */
  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return CustomFilterService.dateCompare(
      Number(this.params.getValue(params.node)),
      this.getModel().model,
      this.getFilterType(),
      this.getPrecision(),
      this.getModel().modelRangeEnd
    );
  }

  /**
   * Returns the precision variable.
   *
   * @returns {DateUnit} The date model object based on the filter form.
   *
   */
  public getPrecision(): DateUnit {
    return this._precision;
  }

  /**
   * Sets the precision variable.
   *
   * @param {DateUnit} precision The precision to be set.
   *
   */
  public setPrecision(precision: DateUnit): void {
    this._precision = precision;
  }
}

import { Serializable } from '@siq-js/core-lib';
import { CmsEntity, CmsEntityJson } from './cms-entity';

export interface CmsApplicationJson extends CmsEntityJson {
  description: string;
  tag?: string;
  color: string;
  path: string;
}

export class CmsApplication extends CmsEntity<CmsApplicationJson> implements Serializable<CmsApplicationJson> {
  description: string;
  tag: string;
  color: string;
  path: string;

  constructor(model?: CmsApplicationJson) {
    super(model);
    this.description = model?.description || '';
    this.tag = model?.tag || '';
    this.color = model?.color || '';
    this.path = model.path || '';
  }

  toJson(): CmsApplicationJson {
    return {
      ...super.toJson(),
      description: this.description,
      tag: this.tag,
      color: this.color,
      path: this.path
    };
  }

  clone(): CmsApplication {
    return new CmsApplication(this.toJson());
  }
}

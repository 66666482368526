import { Component, Input, OnInit } from '@angular/core';
import { SiqApplicationConfig } from 'app/siq-applications/modules/shared/models/siq-application-config';
import { ActivatedRoute } from '@angular/router';
import { CmsApplication } from '@siq-js/cms-lib';

@Component({
  selector: 'siq-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  @Input() config: SiqApplicationConfig;
  @Input() showBack: boolean;

  public params: any;
  public app: CmsApplication;

  constructor(
    route: ActivatedRoute
  ) {
    route.params.subscribe(params => this.params = params);
  }

  ngOnInit(): void {
    this.app = this.config.getApplication();
  }
}

<siq-title-criteria
  [formControl]="form.controls.name"
  [activity]="modalData.activity"
  [placeholder]="''"
  ngDefaultControl>
</siq-title-criteria>

<br>

<siq-recipient-list
  [formControlRecipients]="form.controls.recipients"
  [promptForExternal]="true"
  ngDefaultControl>
</siq-recipient-list>

<siq-limited-length-text
  [formControl]="form.controls.message"
  [rows]="limitedLengthTextConfig.rows"
  [maxLength]="limitedLengthTextConfig.maxLength"
  ngDefaultControl>
</siq-limited-length-text>

import { Component, OnInit } from '@angular/core';
import { UsesActivityGrid } from 'app/activity/components/activity-grid/activity-grid.component';
import { BehaviorSubject } from 'rxjs';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ColDef } from '@siq-js/visual-lib';
import { ActivityCallbacks } from 'app/activity/models/interfaces';
import { ActivityService } from 'app/activity/services/activity.service';
import { ActivityCriteriaGenerator } from 'app/activity/models/activity-criteria-generator';
import { IncrementalsConfig } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { IncrementalsService } from 'app/siq-applications/modules/incrementals/services/incrementals.service';
import { IncrementalsCriteriaRendererComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-criteria-renderer/incrementals-criteria-renderer.component';
import { BaseSiqComponent } from '@siq-js/angular-buildable-lib';
import { IncrementalsActivity } from 'app/siq-applications/modules/incrementals/models/incrementals-activity.model';
import { ApplicationHash } from '@siq-js/core-lib';

@Component({
  templateUrl: './incrementals-list.component.html',
  styleUrls: ['./incrementals-list.component.scss']
})
export class IncrementalsListComponent extends BaseSiqComponent implements OnInit, UsesActivityGrid {
  public actions = this.config.actions;

  // ActivityGrid interface fields
  public activityStream$: BehaviorSubject<IncrementalsActivity[]>;

  public callbacks: ActivityCallbacks = {
    rerun: activity => this.activityService.rerun(activity),
    clone: activity => {
      this.incrementalsService.cloneReport(activity.getId());
    }
  };
  public columns: (tabKey: ActivityTabKey) => ColDef[];

  constructor(
    public config: IncrementalsConfig,
    private activityService: ActivityService,
    private incrementalsService: IncrementalsService,
  ) {
    super();

    this.activityStream$ = IncrementalsService.Activities$;
    this.columns = tabKey => ActivityCriteriaGenerator.generateColDefs(tabKey, [IncrementalsCriteriaRendererComponent.ColDef]);
    this.activityService.getCurrentPageInfo().appId = ApplicationHash.INCREMENTALS;
    this.activityService.getCurrentPageInfo().tab = ActivityTabKey.MY_REPORTS;
    ActivityService.refresh();
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.activityService.clearCurrentPageInfo();
    super.ngOnDestroy();
  }

}

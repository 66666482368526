import { Component } from '@angular/core';
import { SelectAllHeaderCellComponent } from 'app/core/components/cell-renderers/select-all-header-cell/select-all-header-cell.component';

@Component({
  selector: 'siq-checkbox-header-renderer',
  templateUrl: './checkbox-header-renderer.component.html',
  styleUrls: ['./checkbox-header-renderer.component.scss']
})
export class CheckboxHeaderRendererComponent extends SelectAllHeaderCellComponent {

  constructor() {
    super();
  }

}

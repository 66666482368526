<mat-grid-list [cols]="maxCount" rowHeight="82" gutterSize="24" *ngIf="formArray">

  <mat-grid-tile [colspan]="1" *ngFor="let selector of selectorData; let i = index">

    <div class="add-button-wrapper" *ngIf="i <= maxCount - 1 && !visibility[i] && visibility[i - 1]">
      <button
        mat-raised-button
        type="button"
        class="gray-button"
        (click)="add(i)"
        [disabled]="!formArray.controls[i - 1].valid"
      >
        Add a {{ displayName }}
      </button>
    </div>

    <mat-form-field *ngIf="visibility[i]">

      <input
        matInput
        placeholder="{{ placeholders[i] }}"
        required
        [formControl]="formArray.controls[i]"
        [matAutocomplete]="autoGroup"
        type="text"
        autocomplete="off"
      />

      <button mat-button *ngIf="formArray.controls[i].value" matSuffix mat-icon-button (click)="formArray.controls[i].setValue('')">
        <mat-icon>close</mat-icon>
      </button>

      <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="idToName.bind(this)">
        <mat-optgroup *ngFor="let group of selectorData[i] | async" [label]="group.displayGroup.value.displayName">
          <mat-option *ngFor="let datum of group.items" [value]="datum.id">
            {{ datum.name }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>

    </mat-form-field>

    <button
      mat-button
      type="button"
      *ngIf="!required[i] && visibility[i]"
      (click)="remove(i)"
      class="remove-button"
    >
      Remove
    </button>

  </mat-grid-tile>

</mat-grid-list>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsyncCellComponent } from 'libs/visual-lib/src/lib/modules/grid/components/cell-renderers/async-cell/async-cell.component';
import { ColumnHeaderComponent } from 'libs/visual-lib/src/lib/modules/grid/components/cell-renderers/column-header/column-header.component';
import { GroupingDisabledComponent } from 'libs/visual-lib/src/lib/modules/grid/components/cell-renderers/grouping-disabled/grouping-disabled.component';
import { PinnedDimensionCellComponent } from 'libs/visual-lib/src/lib/modules/grid/components/cell-renderers/pinned-dimension-cell/pinned-dimension-cell.component';
import { PinnedFactCellComponent } from 'libs/visual-lib/src/lib/modules/grid/components/cell-renderers/pinned-fact-cell/pinned-fact-cell.component';
import { GridComponent } from 'libs/visual-lib/src/lib/modules/grid/components/grid/grid.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { HeatmapSelectorComponent } from 'libs/visual-lib/src/lib/modules/grid/components';
import { HeatmapPanelComponent } from 'libs/visual-lib/src/lib/modules/grid/components/tool-panel/heatmap-panel/heatmap-panel.component';
import { CustomPagingComponent } from 'libs/visual-lib/src/lib/modules/grid/components/grid/custom-paging/custom-paging.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MaterialModule } from 'libs/visual-lib/src/lib/modules/material/material.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { DownloadOverlayComponent } from 'libs/visual-lib/src/lib/modules/grid/components';
import { AsyncTotalsComponent } from 'libs/visual-lib/src/lib/modules/grid/components/async-totals/async-totals/async-totals.component';
import { AgGridModule } from 'ag-grid-angular';

const gridComponents: any[] = [
  GridComponent,
  AsyncCellComponent,
  ColumnHeaderComponent,
  GroupingDisabledComponent,
  HeatmapSelectorComponent,
  PinnedDimensionCellComponent,
  PinnedFactCellComponent
];

@NgModule({
  declarations: [
    ...gridComponents,
    HeatmapSelectorComponent,
    HeatmapPanelComponent,
    AsyncTotalsComponent,
    HeatmapPanelComponent,
    CustomPagingComponent,
    DownloadOverlayComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressBarModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    MatPaginatorModule,
    MatInputModule
  ],
  exports: [
    ...gridComponents
  ],
  providers: [
  ]
})
export class GridModule { }

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  QueryModeModalComponent,
  QueryModeModalData
} from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { CmsService } from 'app/core/services/cms/cms.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { ReportBuilderFormData } from 'app/siq-applications/modules/report-builder/models/form/report-builder-form-data.model';
import { DimensionColumn } from 'app/siq-applications/modules/report-builder/models/form/dimension-column.model';
import { MetricColumn } from 'app/siq-applications/modules/report-builder/models/form/metric-column.model';
import * as _ from 'lodash';
import { EnvConfigService } from 'app/core/services/env-config/env-config.service';

interface Summary {
  dimensions: DimensionColumn[];
  filters: FilterSelection[];
  filterValues: FilterSelection[];
  metrics: MetricColumn[];
  metricsFilters: MetricColumn[];
}

@Component({
  selector: 'siq-report-builder-query-mode-modal',
  templateUrl: './report-builder-query-mode-modal.component.html',
  styleUrls: ['./report-builder-query-mode-modal.component.scss']
})
export class ReportBuilderQueryModeModalComponent extends QueryModeModalComponent implements OnInit {
  public userGroupChanged: boolean;
  public summary: Summary;

  constructor(
    protected dialogRef: MatDialogRef<ReportBuilderQueryModeModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected modalData: QueryModeModalData
  ) {
    super(dialogRef, modalData);
  }

  ngOnInit(): void {
    const form: ReportBuilderFormData = this.modalData.data;
    const schema = this.modalData.schema;
    this.userGroupChanged = !!this.modalData.userGroupChanged;

    const dimensionSummary: DimensionColumn[] = form.columns.filter(col => {
      return col.isDim() && !CmsService.isValidField((col as DimensionColumn).ref, schema);
    });

    const metricsSummary: MetricColumn[] = form.columns.filter(col => {
      return col.isMetric() && !CmsService.isValidMetric((col as MetricColumn).ref, schema);
    }).map(col => (col as MetricColumn));

    const metricsFiltersSummary: MetricColumn[] = _.cloneDeep(form.columns)
      .filter(col => col.isMetric())
      .map((col: MetricColumn) => {
        col.filters = col.filters.filter(fs => !CmsService.isValidField(fs.field, schema));
        return col;
      })
      .filter(col => col.filters.length);

    const filtersSummary: FilterSelection[] = form.globalFilters.filter(fs => !CmsService.isValidField(fs.field, schema));

    const filterValueSummary = [];
    form.globalFilters.forEach(fs => {
      if (fs.id === CmsService.RETAILER_FIELD_ID) {
        const retailers = EnvConfigService.data.getValue().retailers;
        const tmp = new FilterSelection(fs.toJson());
        tmp.values = fs.values.filter(val =>!retailers.includes(val));
        if (tmp.values.length) {
          filterValueSummary.push(tmp);
        }
      }
    });

    this.summary = {
      dimensions: dimensionSummary,
      metrics: metricsSummary,
      filters: filtersSummary,
      filterValues: filterValueSummary,
      metricsFilters: metricsFiltersSummary
    };

    this.ready();
  }
}

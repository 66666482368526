  <form [formGroup]="dateRangeForm">
    <mat-form-field
      color="accent"
      class="mat-form-field"
      [ngClass]="{'mat-form-field-invalid': dateRangeFormStatusPointer === 'INVALID'}"
      appearance="fill"
      *ngIf="ready$ | async"
      (click)="showPicker(true)">
      <mat-label>{{ !config.rangeMode ? "Enter a date" : "Enter a date range" }}</mat-label>

      <input
        *ngIf="!config.rangeMode"
        matInput
        [matDatepicker]="singlePicker"
        [min]="minDate"
        [max]="maxDate"
        [value]="startDate"
        (dateChange)="onDateChange($event, 'start')"
      >

      <mat-date-range-input
        *ngIf="config.rangeMode"
        [rangePicker]="rangePicker"
        [min]="minDate"
        [max]="maxDate"
      >
        <input
          matStartDate
          placeholder="Start date"
          [value]="isPreviewMode ? preViewStart : startDate"
          (dateChange)="onDateChange($event, 'start')"
        >
        <input
          matEndDate
          placeholder="End date"
          [value]="isPreviewMode ? preViewEnd : endDate"
          (dateChange)="onDateChange($event, 'end')"
        >
      </mat-date-range-input>

      <mat-datepicker-toggle matPrefix [for]="singlePicker" *ngIf="!config.rangeMode"></mat-datepicker-toggle>
      <mat-datepicker
        #singlePicker
        [startAt]="startDate"
        (closed)="onPickerClosed()"
        >
      </mat-datepicker>

      <mat-datepicker-toggle matPrefix [for]="rangePicker" *ngIf="config.rangeMode"></mat-datepicker-toggle>
      <mat-date-range-picker
        #rangePicker
        [calendarHeaderComponent]="config.allowShortcuts ? headerShortcutsComponent : null"
        (closed)="onPickerClosed()"
        (opened)="onRangePickerOpened()"
        [startAt]="isPreviewMode ? preViewEnd : endDate"
        [panelClass]="isPreviewTheme ? 'previewTheme' : '' "
        >
      </mat-date-range-picker>

      <div matSuffix class="icons">
        <mat-icon
          class="info-icon"
          *ngIf="tooltip && !config.hideTooltip"
          [matTooltip]="tooltip.msg"
          matTooltipClass="multi-line-tooltip"
          [ngClass]="{'read': tooltip.read, 'warn': tooltipWarn}"
          (mouseover)="tooltip.read = true;"
        >
          info
        </mat-icon>

        <button
          *ngIf="config.weekEndingDay"
          mat-icon-button
          [matMenuTriggerFor]="weSettingsMenu"
          (click)="weSettings($event)"
          class="settings-icon"
          aria-label="Set Week Ending Day">
          <mat-icon>settings</mat-icon>
        </button>
      </div>

    </mat-form-field>

    <!-- WeekEndingDay seetings menu -->
    <mat-menu #weSettingsMenu="matMenu" id="settings-menu" class="settings-menu">
      <div class="settings-menu-label mat-menu-item" disabled="true">Select Week Ending:</div>
      <button
        *ngFor="let day of days"
        mat-menu-item
        [value]="day.id"
        [ngClass]="{selected: day.selected}"
        (click)="weSetWEDay(day.id)"
        >{{ day.label }} {{day.default ? "(default)": ""}}
      </button>
    </mat-menu>
    <mat-error *ngIf="dateRangeFormStatusPointer === 'INVALID'">
      Invalid date range
    </mat-error>
  </form>

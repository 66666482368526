<h1 mat-dialog-title>{{ edit ? 'Edit' : 'Save' }} Favorite</h1>

<form [formGroup]="form">

  <mat-form-field>
    <input
      matInput
      placeholder="Name *"
      [formControl]="form.controls['name']"
    >
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="Description"
      [formControl]="form.controls['description']"
    >
  </mat-form-field>

  <div mat-dialog-actions>
    <div>
      <ng-container *ngIf="edit">
        <button
          color="warn"
          mat-raised-button
          (click)="remove()"
          matTooltip="Remove from Favorites"
          [matTooltipPosition]="'above'"
        >Remove</button>
      </ng-container>
    </div>

    <div>
      <button
        mat-stroked-button
        color="accent"
        class="secondary-button"
        (click)="cancel()">
        Cancel
      </button>

      <ng-container *ngIf="edit">

        <button
          color="accent"
          mat-raised-button
          (click)="update()"
          [disabled]="!form.valid || submitting"
        >Update</button>
      </ng-container>

      <ng-container *ngIf="!edit">
        <button
          color="accent"
          mat-raised-button
          (click)="save()"
          [disabled]="!form.valid || submitting || isEmptyString()"
        >Save</button>
      </ng-container>

    </div>

  </div>

</form>

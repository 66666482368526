import { Component } from '@angular/core';
import { AgRendererComponent, ColDef, ICellRendererParams } from '@siq-js/visual-lib';
import { Activity } from 'app/activity/models/activity.model';
import { FavoritesDetailComponent } from 'app/activity/modules/favorites/favorites-detail/favorites-detail.component';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ActivityService } from 'app/activity/services/activity.service';
import * as _ from 'lodash';

@Component({
  selector: 'siq-favorite-renderer',
  templateUrl: './favorite-renderer.component.html',
  styleUrls: ['./favorite-renderer.component.scss']
})
export class FavoriteRendererComponent implements AgRendererComponent {

  public static readonly ColDef: ColDef = {
    cellRenderer: FavoriteRendererComponent,
    sortable: false,
    width: 36,
    resizable: false,
    pinned: 'left',
  };

  public favorite: boolean;
  private activity: Activity;

  constructor(
    private utils: UtilsService,
  ) {
  }

  agInit(params: any) {
    const activity = params.data as Activity;
    this.favorite = activity.isFavorite();
    this.activity = activity;
  }

  refresh(params: ICellRendererParams): boolean {
    const a: Activity = params.data;
    const newVal = a.isFavorite();
    const isChanged = newVal !== this.favorite;
    if (isChanged) {
      this.favorite = newVal;
    }
    return isChanged;
  }

  public toggleFavorite($event) {
    $event.stopPropagation();
    this.utils.openModal(FavoritesDetailComponent, this.activity, UtilsService.MODAL_CONFIG_MEDIUM)
      .afterClosed()
      .subscribe(res => {
        if (!_.isNil(res)) {
          this.favorite = res;
          ActivityService.refresh();
        }
      });
  }
}

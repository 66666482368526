import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './components/chart/chart.component';
import { GridModule } from 'libs/visual-lib/src/lib/modules/grid';
import { CustomFiltersModule } from 'libs/visual-lib/src/lib/modules/custom-filters';
import { VisualService } from 'libs/visual-lib/src/lib/services/visual.service';
import { ExcelService } from 'libs/visual-lib/src/lib/services/excel.service';
import { ChartsGraphsModule } from 'libs/visual-lib/src/lib/modules/charts-graphs';
import { MaterialModule } from 'libs/visual-lib/src/lib/modules/material/material.module';
import { AgGridModule } from 'ag-grid-angular';

const visualLibComponents: any[] = [
  ChartComponent
];

@NgModule({
  imports: [CommonModule, AgGridModule, GridModule, CustomFiltersModule, ChartsGraphsModule, MaterialModule],
  exports: [
    ...visualLibComponents,
    GridModule,
    CustomFiltersModule,
    ChartsGraphsModule,
    MaterialModule
  ],
  declarations: [
    ...visualLibComponents
  ],
  providers: [VisualService, ExcelService]
})
export class VisualLibModule {}

import { ChartSettings } from 'libs/visual-lib/src/lib/modules/charts-graphs/models';

export abstract class SimpleLineProcessor {

  /**
   *
   * @param rawData
   * This static function should be overridden by derived classes
   */
  public static processor = (rawData: (any | any[])): ChartSettings => {
    return <ChartSettings>{
      agChartOptions: null,
      parentActivity: null
    }
  };
}

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ActiveToast, Toast, ToastrService } from 'ngx-toastr';
import { NotificationType, ResponseCode, ResponseCodes } from '../../../index'; // Must be relative path!

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public toastrService: ToastrService) {
  }

  public success(message: string, title: string, options?: any): ActiveToast<Toast> {
    return this.handleCustomClick(
      this.toastrService.success(message, title, options),
      options
    );
  }

  public info(message: string, title: string, options?: any): ActiveToast<Toast> {
    return this.handleCustomClick(
      this.toastrService.info(message, title, options),
      options
    );
  }

  public error(message: string, title: string, options?: any): ActiveToast<Toast> {
    return this.toastrService.error(message, title, options);
  }

  public warn(message: string, title: string, options?: any): ActiveToast<Toast> {
    return this.toastrService.warning(message, title, options);
  }

  private handleCustomClick(activeToast: ActiveToast<Toast>, options?: any): ActiveToast<Toast> {
    if (options && _.get(options, 'data.onClick')) {

      activeToast.onTap.subscribe(val => {
        options.data.onClick();
      });

    }

    return activeToast;
  }

  public showHttpNotification(status: number, responseCodes: ResponseCodes = new ResponseCodes()) {

    const rc: ResponseCode = <ResponseCode>_.find(responseCodes.all, ['code', status]);

    if (rc) {
      switch (rc.getType()) {
        case NotificationType.INFO:
          this.info(rc.getMessage(), rc.getTitle(), {data: rc.getData()});
          break;
        case NotificationType.ERROR:
          this.error(rc.getMessage(), rc.getTitle(), rc.getData());
          break;
        case NotificationType.SUCCESS:
          this.success(rc.getMessage(), rc.getTitle());
          break;
      }
    }

  }

}

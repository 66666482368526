import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Activity } from 'app/activity/models/activity.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs';
import { ActivityOptionsActions } from 'app/activity/components/activity-options/activity-options-actions.enum';
import { AppComponent } from 'app/app.component';
import { UtilsService } from 'app/core/services/utils/utils.service';
import { ActivityTabKey } from 'app/activity/models/activity-tab-key.enum';
import { ActivityCallbacks, ActivityTab } from 'app/activity/models/interfaces';
import { ActivityService } from 'app/activity/services/activity.service';
import { MatTabGroup } from '@angular/material/tabs';
import { ColDef } from '@siq-js/visual-lib';

export interface UsesActivityGrid {
  activityStream$: BehaviorSubject<Activity[]>;
  columns: (tabKey: ActivityTabKey) => ColDef[];
  actions?: ActivityOptionsActions[];
  callbacks?: ActivityCallbacks;
}

@Component({
  selector: 'siq-activity-grid',
  templateUrl: './activity-grid.component.html',
  styleUrls: ['./activity-grid.component.scss']
})
export class ActivityGridComponent implements OnInit, UsesActivityGrid {

  public static TABS: ActivityTab[] = [
    {
      id: ActivityTabKey.ALL_REPORTS,
      name: 'All Reports',
      filter: (activityList: Activity[]): Activity[] => {
        return activityList.filter(a =>
          !a.isTrashed() &&
          !a.sharedReport?.deleted &&
          (!a.isMine() || (a.isMine() && !a.isSharedOrScheduled()))
        );
      }
    },
    {
      id: ActivityTabKey.MY_REPORTS,
      name: 'My Reports',
      filter: (activityList: Activity[]): Activity[] => {
        return activityList.filter(a => !a.isSharedOrScheduled() && a.isMine() && !a.isTrashed());
      }
    },

    {
       id: ActivityTabKey.SCHEDULED,
       name: 'Scheduled',
       filter: (activityList: Activity[]): Activity[] => {
         let ids = []; // contains activity ids that have been added in the list of scheduled report
         let newActivityList = [];
         for (const a of activityList) {
           // the last condition eliminates duplicate reports (same activity id but different activity.sharedReport.id)
           if ( !a.isMine() && a.isScheduled() && !a.sharedReport.deleted && !ids.includes(a.getId(false))) {
             newActivityList.push(a);
             ids.push(a.getId(false));
           }
         }
         return newActivityList;
       }
     },
     {
       id: ActivityTabKey.SHARED,
       name: 'Shared with Me',
       filter: (activityList: Activity[]): Activity[] => {
         return activityList.filter(a => !a.isMine() && a.isShared() && !a.sharedReport.deleted);
       }
     },

    {
      id: ActivityTabKey.DELETED,
      name: 'Deleted',
      filter: (activityList: Activity[]): Activity[] => {
        return activityList.filter(a => a.isMine() && a.isTrashed());
      }
    }
  ];

  // Interface implementations
  @Input() public activityStream$: BehaviorSubject<Activity[]>;
  @Input() public columns: (tabKey: ActivityTabKey) => ColDef[];
  @Input() public actions: ActivityOptionsActions[];
  @Input() public callbacks: ActivityCallbacks = {};
  // Optional filter fn
  @Input() public activityFilter: (activities: Activity[]) => Activity[];

  // Tabs to display - default to all
  @Input() public tabs: ActivityTabKey[] = ActivityGridComponent.TABS
    .map(t => t.id)
    .filter(t => t !== ActivityTabKey.ALL_REPORTS);

  @Output() public renderedActivities = new EventEmitter<Activity[]>();

  @ViewChild('tabs', {static: true}) tabGroup: MatTabGroup;

  public displayedTabs: ActivityTab[];
  public selectedTab = this.tabs[0];

  // Shared by all of the internal ActivityTable instances for each tab
  // This is the observable that each ActivityTable uses to populate themselves
  public filteredActivityList$: BehaviorSubject<Activity[]>;

  constructor(private activityService: ActivityService) {
  }

  ngOnInit() {
    this.displayedTabs = ActivityGridComponent.TABS.filter(t => this.tabs.includes(t.id));
    this.activityService.getCurrentPageInfo().tab = this.displayedTabs[0].id;

    // Set up the internal observable
    this.filteredActivityList$ = UtilsService.obsToBehaviorSubject(
      this.activityStream$.pipe(
        map((activities: Activity[]) => {
          return (this.activityFilter) ? this.activityFilter(activities) : activities;
        })
      ), this.activityStream$.getValue()
    );
  }

  public tabChanged(index: number) {
    this.activityService.getCurrentPageInfo().tab = this.tabs[index];
    ActivityService.refresh();
    AppComponent.resize$.next();
    this.selectedTab = this.tabs[index];
  }

  public selectTab(tabKey: ActivityTabKey) {
    this.tabGroup.selectedIndex = this.displayedTabs.findIndex(tab => tab.id === tabKey);
  }

}

import * as _ from 'lodash';
import { CoreConstants, DatesServiceCore, DateUnit, num } from '@siq-js/core-lib';
import { VisualDataType } from 'libs/visual-lib/src/lib/models/interfaces';
import { ExcelDataTypeStatic } from 'libs/visual-lib/src/lib/models/excel-data-type.static';
import { FilterNames } from 'libs/visual-lib/src/lib/models/filter-names.enum';
import { DaypartRange } from 'libs/visual-lib/src/lib/models';

const parseNum = val => {
  if (_.isNil(val)) return val;
  if (typeof val === 'string') {
    // Special characters were causing a problem if present when calling Number(val), so strip them out
    // Remove all occurrences of these chars: (,) ($) (%) -- but leave decimal point
    val = val.replace(/\,?\$?\%?/g, '');
  }

  return Number(val);
};

const parseStr = val => {
  if (_.isNil(val)) return val;
  return val.toString();
};

const toJsDate = val => {
  const num = Number(val);

  try {
    if (isNaN(num)) {
      return new Date(val);
    } else {
      return new Date(num);
    }
  } catch (e) {
    console.error('Unable to convert to JS Date', e);
  }
};

const format = DatesServiceCore.format;
const utcToZonedTime = DatesServiceCore.utcToZonedTime;
const setValue = DatesServiceCore.setValue;
const getValue = DatesServiceCore.getValue;

const visualDataTypes: VisualDataType[] = [
  {
    type: 'HOUR_OF_DAY',
    interval: 1,
    intervalType: 'number',
    filterType: FilterNames.HOUR,
    formatter: (x) => {
      if (_.isNil(x.value)) return '';

      const thisX = _.clone(x);
      try {
        thisX.value = Number(x.value);
      } catch (err) {
        return '';
      }

      if (!_.isNaN(thisX.value)) {
        return format(setValue(DateUnit.HOUR)(new Date(), thisX.value), 'h a');
      }
      return '';
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER
  },
  {
    type: 'DATE_YEAR',
    interval: 1,
    intervalType: 'year',
    filterType: FilterNames.YEAR,
    formatter: (x) => {
      if (_.isNil(x.value)) {
        return '';
      }
      return format(utcToZonedTime(toJsDate(x.value), '-00:00'), 'yyyy');
    },
    parse: (x) => x,
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'DATE_YEARMONTH',
    interval: 1,
    intervalType: 'month',
    filterType: FilterNames.YEAR_MONTH,
    formatter: (x) => {
      if (_.isNil(x.value)) {
        return '';
      }
      return format(utcToZonedTime(toJsDate(x.value), '-00:00'), 'yyyy-MM');
    },
    parse: (x) => x,
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'DATE',
    interval: 1,
    intervalType: 'day',
    filterType: FilterNames.YEAR_MONTH_DAY,
    formatter: (x) => {
      if (_.isNil(x.value)) {
        return '';
      }
      return format(utcToZonedTime(toJsDate(x.value), '-00:00'), CoreConstants._dateFormat);
    },
    parse: (x) => x,
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'DATE_MONTH',
    interval: 1,
    intervalType: 'number',
    filterType: FilterNames.MONTH,
    formatter: (x) => {
      const val = Number(x.value);
      if (!_.isFinite(val) || _.isNaN(val)) {
        return '';
      }
      return format(setValue(DateUnit.MONTH)(new Date(), val), 'MMMM'); // Doesn't +1 for JS Date.setMonth()
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER
  },
  {
    type: 'DATE_QUARTER',
    interval: 1,
    intervalType: 'number',
    formatter: (x) => {
      if (_.isNil(x.value)) return '';
      const utcDate = utcToZonedTime(toJsDate(x.value), '-00:00');
      return 'Q' + getValue(DateUnit.QUARTER)(utcDate) + ' ' + getValue(DateUnit.YEAR)(utcDate);
    },
    filterValueGetter:(x) => {
      const timestamp = x.data[DateUnit.QUARTER];
      const utcDate = utcToZonedTime(toJsDate(timestamp), '-00:00');
      return 'Q' + getValue(DateUnit.QUARTER)(utcDate) + ' ' + getValue(DateUnit.YEAR)(utcDate);
    },
    parse: (x) => x,
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'DAY_OF_WEEK',
    interval: 1,
    intervalType: 'number',
    filterType: FilterNames.DAY_OF_WEEK,
    formatter: (x) => {
      if (_.isNil(x.value)) return '';

      const thisX = _.clone(x);
      try {
        thisX.value = Number(x.value);
      } catch (err) {
        return '';
      }

      if (!_.isNaN(thisX.value)) {
        return format(setValue(DateUnit.DAY)(new Date(), thisX.value - 1), 'E');
      }
      return '';

    },
    parse: (x) => {
      return parseNum(x);
    },
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'DAY_PART',
    interval: 1,
    intervalType: 'number',
    filterType: FilterNames.DAYPART,
    formatter: (x) => {
      const ranges = _.map(DaypartRange);
      return ranges[x.value];
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'NUMBER',
    filterType: FilterNames.NUMBER,
    formatter: (x, t) => {
      if (!_.isFinite(x.value) && _.isNaN(x.value)) {
        return '';
      }
      const xVal = typeof x.value === 'string' ? Number(x.value) : x.value;
      return num(xVal, 3, t);
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0.000'
  },
  {
    type: 'STRING',
    filterType: FilterNames.TEXT,
    formatter: (x) => x.value,
    interval: 1,
    parse: (x) => parseStr(x),
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  {
    type: 'DAY',
    filterType: FilterNames.DAY,
    formatter: (x) => {
      const val = Number(x.value);
      if (!_.isFinite(val)) {
        return '';
      }
      return val;
    },
    interval: 1,
    intervalType: 'number',
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_STRING
  },
  // Default FactTypes
  {
    type: 'VOLUME',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      return num(x.value, 0, t);
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0'
  },
  {
    type: 'MID_ACCUR_NUMBER',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      return num(x.value, 3, t);
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0.000'
  },
  {
    type: 'DOLLAR',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      if(x.value < 0){
        return ('-$' + num(Math.abs(x.value), 2, t));
      } else
      {
        return ('$' + num(x.value, 2, t));
      }
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '$#,##0.00'
  },
  {
    type: 'PERCENT',
    filterType: FilterNames.PERCENTAGE,
    formatter: (x, t) => {
      return (num(x.value * 100, 1, t) + '%');
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0.0%'
  },
  // CMS FactTypes
  {
    type: 'DOLLAR_SALES',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      return ('$' + num(x.value, 0, t));
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '$#,##0'
  },
  {
    type: 'DOLLAR_UNIT',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      return ('$' + num(x.value, 2, t));
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '$#,##0.00'
  },
  {
    type: 'ITEM_UNIT',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      return (num(x.value, 1, t));
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0.0'
  },
  {
    type: 'UNIT_COUNT',
    filterType: FilterNames.FORMATTED_NUMBER,
    formatter: (x, t) => {
      return num(x.value, 0, t);
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0'
  },
  {
    type: 'PERCENT_TOTAL',
    filterType: FilterNames.PERCENTAGE,
    formatter: (x, t) => {
      return (num(x.value * 100, 1, t) + '%');
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0.0%'
  },
  {
    type: 'PERCENT_SHARE',
    filterType: FilterNames.PERCENTAGE,
    formatter: (x, t) => {
      return (num(x.value * 100, 2, t) + '%');
    },
    parse: (x) => parseNum(x),
    dataType: ExcelDataTypeStatic.EXCEL_NUMBER,
    excelFormula: '#,##0.00%'
  },
  {
    type: 'CUSTOM',
    formatter: (x) => x,
    parse: (x) => x,
    dataType: ExcelDataTypeStatic.EXCEL_STRING // use string as we dont know other specifics
  }
];

/**
 * AGG_MAP and CUSTOM_AGG_FUNCTIONS could (in theory) be removed as they are now in GRID_DEFAULTS (grid-defaults.ts in visual-lib)
 */
export const VISUAL_CONFIG = {
  VISUAL_DATA_TYPES: visualDataTypes
};

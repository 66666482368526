<div mat-dialog-title>{{ title }}</div>

<div mat-dialog-content>
  <div *ngIf="hasUnknowns; else drilldown">
    <mat-grid-list cols="1" gutterSize="5" rowHeight="130px">
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-grid-tile-header
          ngClass="{{ messageBackground }}"
        >
          <mat-icon>warning</mat-icon>
          Error
        </mat-grid-tile-header>
        <br/><br/>
        <p>
          {{ message }}
        </p>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="2">
        <ul class="summary-list">
          <li> <b>{{ summary.occurrences }}</b> Occurrences</li>
          <li> <b>{{ summary.support }}</b> Support </li>
          <li> <b>{{ summary.confidence }}</b> Confidence </li>
          <li> <b class="{{ summary.addedValueCssClass }}">{{ summary.addedValue }}</b> Added Value </li>
          <li> <b class="{{ summary.liftCssClass }}">{{ summary.lift }}</b> Lift </li>
          <li> <b class="{{ summary.liftCssClass }}">{{ summary.conviction }}</b> Conviction </li>
        </ul>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <ng-template #drilldown>

    <div *ngIf="!show" class="loading-overall">
      <p>Generating Query...</p>
      <siq-progress-spinner [show]=true color="accent"></siq-progress-spinner>
    </div>

    <div *ngIf="show">
      <mat-grid-list cols="2" gutterSize="5" rowHeight="130px">
        <mat-grid-tile colspan="1" rowspan="2">
          <ul class="summary-list">
            <li> <b>{{ summary.occurrences }}</b> Occurrences</li>
            <li> <b>{{ summary.support }}</b> Support </li>
            <li> <b>{{ summary.confidence }}</b> Confidence </li>
            <li> <b class="{{ summary.addedValueCssClass }}">{{ summary.addedValue }}</b> Added Value </li>
            <li> <b class="{{ summary.liftCssClass }}">{{ summary.lift }}</b> Lift </li>
            <li> <b class="{{ summary.convictionCssClass }}">{{ summary.conviction }}</b> Conviction </li>
          </ul>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <div class="summary-callout">
            <div class="summary-icon {{ resultItems[0].color }}">
              <mat-icon aria-hidden="false" aria-label="">attach_money</mat-icon>
            </div>

            <div>
              <span *ngIf="!resultItems[0].statResponse && !resultItems[0].error">
                <mat-icon
                  matSuffix
                  class="icon-refresh-animate"
                >
                  autorenew
                </mat-icon>
              </span>

              <span *ngIf="resultItems[0].error">
                <mat-icon>warning</mat-icon> Error
              </span>

              <span
                class="text-large"
                *ngIf="resultItems[0].statResponse"
              >
                {{ avgBasketDollars | currency:'USD':'symbol':'1.2' }}
              </span>
              <br/>
              {{ resultItems[0].label }}
            </div>

          </div>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <div class="summary-callout">
            <div class="summary-icon {{ resultItems[1].color }}">
              <mat-icon aria-hidden="false" aria-label="">shopping_cart</mat-icon>
            </div>

            <div>
              <span *ngIf="!resultItems[1].statResponse && !resultItems[1].error">
                <mat-icon
                  matSuffix
                  class="icon-refresh-animate"
                >
                  autorenew
                </mat-icon>
              </span>
              <span *ngIf="resultItems[1].error">
                <mat-icon>warning</mat-icon> Error
              </span>

              <span
                class="text-large"
                *ngIf="resultItems[1].statResponse"
              >
              {{ avgBasketItemCount | number:'1.0-3' }}
            </span>
              <br/>
              {{ resultItems[1].label }}
            </div>
          </div>
        </mat-grid-tile>

      </mat-grid-list>

      <br/>

      <div class="top-five">

        <p *ngIf="!resultItems[6].gridParams && !ready$.value">Loading {{ resultItems[6].label }}...</p>

        <siq-progress-spinner [show]="!resultItems[6].gridParams && !ready$.value && !resultItems[6].error" color="accent"></siq-progress-spinner>

        <siq-js-grid
          *ngIf="ready$.value"
          [config]="gridParams.gridVisualOptions.gridConfig"
          [data]="gridParams"
          [processor]="gridProcessor"
          (featureUsed)="gridFeatureUsed($event)"
        ></siq-js-grid>

        <span *ngIf="resultItems[6].error">
          <mat-icon>warning</mat-icon> Error
        </span>

      </div>

      <mat-grid-list cols="2" gutterSize="5" [rowHeight]="chartHeight">

        <mat-grid-tile colspan="1" rowspan="1" *ngFor="let rc of resultChartItems">
          <mat-grid-tile-header *ngIf="!rc.simpleLineParams && !ready$.value">Loading {{ rc.label}}...</mat-grid-tile-header>
          <siq-progress-spinner
            [show]="!rc.simpleLineParams && !ready$.value && !rc.error"
            color="accent"
          >
          </siq-progress-spinner>
          <siq-js-simple-line
          *ngIf="rc.simpleLineParams"
          [config]="rc.simpleLineConfig"
          [data]="rc.simpleLineParams"
          [processor]="simpleLineProcessor"
        ></siq-js-simple-line>
          <span *ngIf="rc.error">
            <mat-icon>warning</mat-icon> Error
          </span>
        </mat-grid-tile>
      </mat-grid-list>

    </div>
  </ng-template>

  <br/>
  <br/>

</div>

<button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>

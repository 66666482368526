import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseStepperComponent } from 'app/core/components/stepper/base-stepper.component';
import { IncrementalsService } from 'app/siq-applications/modules/incrementals/services/incrementals.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs';
import { QueryModeComponent } from 'app/core/components/query-mode-component/query-mode.component';
import { Observable } from 'rxjs';
import { ValidationMessage, ValidationMessageStatus } from 'app/core/models/validation-message';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';
import { DatesService } from 'app/siq-forms/modules/dates/services/dates.service';
import { FilterSelection } from 'app/filter/models/filter-selection';
import { IncrementalsFormData } from 'app/siq-applications/modules/incrementals/models/form/incrementals-form-data.model';
import { GroupedArray, GroupedArrayFunctions, ApplicationHash, CoreConstants, WeekEndingDay } from '@siq-js/core-lib';
import { CmsField, CmsConfig, CmsApplication } from '@siq-js/cms-lib';
import { EntitySelectorStatus } from 'app/siq-forms/components/entity-selector/entity-selector.component';
import { IncrementalFilterType } from 'app/siq-applications/modules/incrementals/models/form/incremental-filter-type.enum';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CmsService } from 'app/core/services/cms/cms.service';
import { UtilsService } from 'app/core/services/utils/utils.service';
import * as _ from 'lodash';
import { IncrementalsQueryModeModalComponent } from 'app/siq-applications/modules/incrementals/components/incrementals-query-mode-modal/incrementals-query-mode-modal.component';
import { QueryModeModalData } from 'app/core/components/query-mode-modal/query-mode-modal.component';
import { DateRangeInterface } from 'app/siq-forms/modules/dates/models/interfaces';
import { FilterGroupComponent } from 'app/filter/components/filter-group/filter-group.component';
import { FilterService } from 'app/filter/services/filter.service';
import { IncrementalsConfig } from 'app/siq-applications/modules/incrementals/models/incrementals-config.model';
import { MixpanelService } from 'app/core/services/mixpanel/mixpanel.service';
import { MixpanelEvent } from 'app/core/services/mixpanel/mixpanel-event.enum';

@Component({
  selector: 'siq-js-incrementals-stepper',
  templateUrl: './incrementals-stepper.component.html',
  styleUrls: ['./incrementals-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class IncrementalsStepperComponent extends BaseStepperComponent implements OnInit {
  @Input() formData: IncrementalsFormData;
  @Input() queryMode: QueryModeComponent;
  @Input() overrideSchema: string;
  @Input() submit: () => Observable<any>;
  @ViewChild('transactionFilters') transactionFilters: FilterGroupComponent; // custom FilterGroupComponent for global filters


  public appName: string;
  public entityData: GroupedArray<CmsField>; // original copy of grouped CmsField data coming from CMS
  public filteredData: GroupedArray<CmsField>; // filtered version of entityData
  public incrementalFilterType = IncrementalFilterType;
  public incrementalTypeSet = new Set(['department', 'category', 'categorysubdescription', 'deptdescription']); // incremental type set that makes step 3 product filter optional
  public initModel: CmsField[] = [];
  public isDateRangeValid: boolean = false;
  public transactionStepMessage = 'Select Transaction Filter(s)';
  public productStepMessage: string;
  public stepOneError = true; // show error if step 1 is not filled
  public stepThreeError = false; // show error if step 3 is not filled
  public stepValidationMessages: ValidationMessage[] = [null, null, null, null]; // validation message for each step
  public validationMessageStatus = ValidationMessageStatus;

  constructor(
    protected asyncStatusService: AsyncStatusService,
    private incrementalsService: IncrementalsService,
    private config: IncrementalsConfig,
    private mixpanelService: MixpanelService,
    private router: Router,
    private utilsService: UtilsService,
    private datesService: DatesService
  ) {
    super(asyncStatusService);

  }

  // function called when dates selected from the date selector
  public dateChanged(dates: DateRangeInterface) {
    this.formData.dateRanges = dates;
    let start: string, end: string;
    start = dates.begin ? DatesService.format(dates.begin, CoreConstants._shortDate) : '';
    end = dates.end ? DatesService.format(dates.end, CoreConstants._shortDate) : '';
    this.updateValidationMessage(1, ValidationMessageStatus.VALID, `${start} through ${end}`);
    this.isDateRangeValid = dates.isDateRangeValid;
    this.validateForm();
  }

  /**
   * Function called when FilterGroup changes
   * @param filterModel: FilterSelection[]
   */
  public filterGroupChanged(filterModel: FilterSelection[], group: IncrementalFilterType) {
    switch (group) {
      // product filter
      case IncrementalFilterType.PRODUCT:
        this.formData.productFilters = filterModel || this.formData.productFilters;
        switch (this.validateProductStep()) {
          case ValidationMessageStatus.UNSET:
            this.updateValidationMessage(2, ValidationMessageStatus.UNSET, 'Select Product Filter(s)');
            this.stepThreeError = false;
            break;
          case ValidationMessageStatus.VALID:
            this.updateValidationMessage(2, ValidationMessageStatus.VALID, `${this.formData.productFilters.length} Product Filter(s) Selected`);
            this.stepThreeError = false;
            break;
          case ValidationMessageStatus.INVALID:
            this.updateValidationMessage(2, ValidationMessageStatus.INVALID, this.productStepMessage);
            this.stepThreeError = true;
            break;
        }
        break;
      // location filter
      case IncrementalFilterType.LOCATION:
        this.datesService.updateCommunalDateRange(this.queryMode.schema$.getValue(), this.formData.locationFilters, filterModel);
        this.formData.locationFilters = filterModel || this.formData.locationFilters;
        if (!this.formData.locationFilters.length) {
          this.updateValidationMessage(3, ValidationMessageStatus.UNSET, this.transactionStepMessage);
        } else {
          this.updateValidationMessage(3, ValidationMessageStatus.VALID, `${this.formData.locationFilters.length} Transaction Filter(s) Selected`);
        }
        break;
    }
    this.validateForm();
  }

  /**
   * Function called when entity-selector component emits values
   * @param entitiesStatus: status of the entity-selector component
   */
  public IncrementalTypeChanged(entitiesStatus: EntitySelectorStatus<CmsField>) {
    const {valid, selectedEntities} = entitiesStatus;
    if (valid) {
      this.formData.incrementalType = selectedEntities[0];
      let valMsg = `${selectedEntities[0].display}`;
      this.stepOneError = false;
      this.updateValidationMessage(0, ValidationMessageStatus.VALID, valMsg);
      this.filterGroupChanged(null, IncrementalFilterType.PRODUCT); // check if the current selection make Step 3 Product Filter required/optional
    } else {
      this.formData.incrementalType = null;
      this.stepOneError = true;
      this.updateValidationMessage(0, ValidationMessageStatus.INVALID, `Select Correct ${this.appName} Type`);
      this.filterGroupChanged(null, IncrementalFilterType.PRODUCT); // check if the current selection make Step 3 Product Filter required/optional
    }
    this.validateForm();
  }

  public init(): void {
    this.entityData = GroupedArrayFunctions.filter(CmsService.get().fieldsOrder, el => el.table === 'product');
    this.appName = CmsService.get().findEntity<CmsApplication>(ApplicationHash.INCREMENTALS).display;
    this.productStepMessage = `Some ${this.appName} Types require at least one Product Filter to be selected`;
    if (this.formData.isCloned) {
      this.processClonedForm();
      this.stepOneError = false; // cloned forms are always valid
    } else {
      this.stepOneError = true;
    }
    this.purgeFilterValues(); // ICE-1921
    this.queryMode.schema$
      .pipe(filter(schema => !!schema))
      .subscribe(schema => {
        this.formData.schema = schema;
        this.filterFormData(schema);
      });
    this.validateForm();
  }
  public transactionMapFn = (fields: CmsField[]) => fields.filter(f => f.table === 'locations' || f.table === 'customer' || f.filter === 'RETAILER' || f.id.includes('loyalty_status')); // mapping fn for location filters

  // Submit form
  public onSubmit() {
    this.disableSubmitBtn = true;

    // The submit fn returns an Observable to which we can subscribe
    this.submit()
      .subscribe(res => {
        // form handles whatever needs to be done here
        // redirect back to the list
        this.router.navigate(['app/assortment']);
      }, err => {
        console.log(`Error submitting ${this.appName} Form: %O`, err);
        this.validateForm();
      });
  }
  public productMapFn = (fields: CmsField[]) => fields.filter(f => f.table === 'product'); // mapping fn for product filters

  public weekEndingDayChanged(we: WeekEndingDay) {
    if (we) {
      this.formData.weekEndingday = we;
    }
  }

  /**
   * Filter form input data when schema changes
   * @param schema: new schema
   */
  private filterFormData(schema: string) {
    // left and right entityData
    this.filteredData = GroupedArrayFunctions.filter(this.entityData, entity => {
      return entity.retailer === schema || entity.retailer === this.primaryEntity || entity.retailer === CmsConfig.CORE_SCHEMA_ID;
    });
    this.datesService.datePickerSchema$.next(DatesService.getActiveSchemas(schema, [...this.formData.locationFilters, ...this.formData.productFilters]));
  }

  // ICE-1921: old retailer filter selections become invalid after AG changed(available retailers changed
  private purgeFilterValues() {
    FilterService.purgeFilterValues(this.transactionFilters, this.formData.locationFilters);
  }

  // Process form when current form is cloned
  private processClonedForm(): void {
    const currSchema = UtilsService.getQueryModeDefaultSchema(this.overrideSchema);
    if (this.incrementalsService.clonedActivity.accessGroupChanged && !this.formData.isValidSchema(currSchema)) { // AG has changed and form no longer valid
      this.utilsService.openModal(
        IncrementalsQueryModeModalComponent,
        {
          data: _.cloneDeep(this.formData),
          schema: this.queryMode.schema$.getValue(),
          userGroupChanged: true
        } as QueryModeModalData
      );
      // Clear invalid incrementals type
      // Invalid Filters don't get cleared here. Instead in the filterGroup component
      if (!CmsService.isValidField(this.formData.incrementalType, currSchema)) {
        this.formData.incrementalType = null;
      }
      // reset form schema
      this.formData.schema = currSchema;
      if (!this.formData.incrementalType) {
        this.stepOneError = true;
      }
    } else { // form still valid
      this.stepOneError = false;
    }
    if (this.formData.incrementalType) {
      this.initModel = [this.formData.incrementalType];
    }
    this.mixpanelService.track(MixpanelEvent.REPORTS_CLONED, {
      'Application': this.config.getApplication().display,
      'Cloned Activity ID': this.incrementalsService.clonedActivity.getId(),
      'Name': this.formData.name,
      'JSON': this.formData.toJson(),
      'Type': 'Report'
    });
    this.incrementalsService.clonedActivity = null; // clear activity
  }

  private validateForm() {
    this.disableSubmitBtn = this.stepOneError || this.stepThreeError || !this.isDateRangeValid;
  }

  // Check the validity of step 3 Product Filter based on the selected incrementalType
  private validateProductStep(): ValidationMessageStatus {
    if (!this.formData.productFilters.length) { // no filter selected, check if product filter is optional based on the selected incrementalType
      return this.incrementalTypeSet.has(this.formData.incrementalType?.field)
        ? ValidationMessageStatus.UNSET
        : ValidationMessageStatus.INVALID;
    } else { // filter selected, return valid
      return ValidationMessageStatus.VALID;
    }
  }
}

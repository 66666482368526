import { ActivityDetailsSubmitComponent } from 'app/siq-forms/components/activity-details-submit/activity-details-submit.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { EnumSelectorComponent } from 'app/siq-forms/components/enum-selector/enum-selector.component';
import { ExpandAllComponent } from 'app/siq-forms/components/expand-all/expand-all.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SiqMaterialModule } from 'app/siq-material/siq-material.module';
import { StepperFooterComponent } from 'app/siq-forms/components/stepper-footer/stepper-footer.component';
import { SubmitButtonComponent } from 'app/siq-forms/components/submit-button/submit-button.component';
import { ValidationDescriptionComponent } from 'app/siq-forms/components/validation-description/validation-description';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';
import { EntitySelectorComponent } from './components/entity-selector/entity-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SiqMaterialModule,
    CoreModule,
    DatesModule
  ],
  declarations: [
    EnumSelectorComponent,
    ActivityDetailsSubmitComponent,
    SubmitButtonComponent,
    ValidationDescriptionComponent,
    ExpandAllComponent,
    StepperFooterComponent,
    EntitySelectorComponent,
  ],
  exports: [
    EnumSelectorComponent,
    EntitySelectorComponent,
    ActivityDetailsSubmitComponent,
    SubmitButtonComponent,
    ValidationDescriptionComponent,
    ExpandAllComponent,
    StepperFooterComponent
  ]
})
export class SiqFormsModule { }

import { takeUntil } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { LoginFormComponent } from 'app/public/components/login-form/login-form.component';
import { User } from 'app/user/models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AppLoadingService } from 'app/core/services/app-loading/app-loading.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'app/auth/services/auth.service';

@Component({
    selector: 'siq-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {

  public currentUser: User = new User();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private returnUrl;
  private sub: Subscription;

  constructor (
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.route.queryParams.subscribe(queryParams => {
      this.returnUrl = queryParams.returnUrl || AppLoadingService.defaultPath;
    });

    this.sub = AuthService.CurrentUser$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(user => {
      if (user) {
        this.currentUser = user;
      } else {
        // Determine finalUrl; If external link, then use encodeURIComponent to preserve the full querystring
        const url = this.returnUrl || AppLoadingService.defaultPath;
        const finalUrl = url.startsWith('/') ? url : '/login?returnUrl=' + encodeURIComponent(url);
        this.router.navigateByUrl(finalUrl);
      }
    });

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.sub.unsubscribe();
  }
}

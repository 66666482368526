<div class="bulk-container">
  <mat-icon class="bulk-cancel-icon" (click)="cancel()">keyboard_arrow_left</mat-icon>

  <span class="bulk-title">Bulk Upload</span>

  <textarea
    matInput
    class="bulk-textarea"
    [ngClass]="{'not-found': notFound}"
    [placeholder]="'Please enter ' + (parent.field.display | pluralize : 2) + ' here. Separate them by lines.'"
    [(ngModel)]="inputText"
  ></textarea>

  <div class="bulk-action-container">
    <span [ngClass]="{'not-found': notFound}">{{codeCount}} {{parent.field.display | pluralize: codeCount}} {{notFound ? 'failed' : 'detected'}}</span>
    <button mat-flat-button color="accent" (click)="search()" *ngIf="!notFound">Search</button>
    <button mat-flat-button color="accent" (click)="downloadWordArrayAsCSV()" *ngIf="notFound">Download Failures</button>
  </div>

</div>

import { Component } from '@angular/core';
import { HeaderCellComponent } from 'app/core/components/cell-renderers/header-cell/header-cell.component';
import * as _ from 'lodash';
import { AgRendererComponent, ColDef } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-delete-cell',
  templateUrl: './delete-cell.component.html',
  styleUrls: ['./delete-cell.component.scss']
})
export class DeleteCellComponent implements AgRendererComponent {

  public callbackFn: DeleteCellCallbackFn;
  public canDelete: boolean;
  public id: number;

  public static generateDeleteColDef(field: string, callbackFn: any, canDeleteFn?: any): ColDef {
    if (_.isNil(canDeleteFn)) {
      canDeleteFn = (id: number) => true;
    }
    const cd: ColDef = {
      cellRenderer: DeleteCellComponent,
      cellRendererParams: {
        callbackFn: callbackFn,
        canDeleteFn: canDeleteFn
      },
      field: field,
      headerComponent: HeaderCellComponent,
      headerName: '',
      maxWidth: 65,
      resizable: false,
      sortable: false,
      width: 65
    } as ColDef;

    return cd;
  }

  constructor() { }

  agInit(params: any) {
    /*
      Expects attributes "callbackFn" and "canDeleteFn" to be present due to being supplied as part of "headerComponentParams" into this component.
      Make pointer to "id" param passed to custom header component in params.data.
     */
    this.id = params.data.id;
    this.callbackFn = params.callbackFn;
    this.canDelete = params.canDeleteFn(params.data.id);
  }

  refresh(): boolean {
    return true;
  }

  deleteThis(): void {
    if (!_.isNil(this.callbackFn) && this.canDelete) {
      try {
        this.callbackFn(this.id);
      } catch (err) {
        console.error(err);
      }
    }
  }
}

export type DeleteCellCallbackFn = (id: number) => void;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityModule } from 'app/activity/activity.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/siq-applications/modules/shared/shared.module';
import { CoreModule } from 'app/core/core.module';
import { GridModule } from 'app/grid/grid.module';
import { SiqFormsModule } from 'app/siq-forms/siq-forms.module';
import { FilterModule } from 'app/filter/filter.module';
import { DatesModule } from 'app/siq-forms/modules/dates/dates.module';
import { DaypartsService } from 'app/siq-applications/modules/dayparts/services/dayparts.service';
import { DaypartsRoutingModule } from './routes/dayparts-routing.module';
import { DaypartsFormComponent } from 'app/siq-applications/modules/dayparts/components/dayparts-form/dayparts-form.component';
import { DaypartsListComponent } from './components/dayparts-list/dayparts-list.component';
import { DaypartsQueryModeModalComponent } from './components/dayparts-query-mode-modal/dayparts-query-mode-modal.component';
import { DaypartsConfig } from 'app/siq-applications/modules/dayparts/models/dayparts-config.model';
import { DaypartsStepperComponent } from './components/dayparts-stepper/dayparts-stepper.component';
import { RouterModule } from '@angular/router';
import { DaypartsCriteriaRendererComponent } from './components/dayparts-criteria-renderer/dayparts-criteria-renderer.component';
import { DaypartsResultComponent } from './components/dayparts-result/dayparts-result.component';
import { VisualLibModule, AgGridModule } from '@siq-js/visual-lib';
import { DaypartsCloneResolverService } from 'app/siq-applications/modules/dayparts/services/dayparts-clone-resolver.service';

@NgModule({
  declarations: [
    DaypartsFormComponent,
    DaypartsListComponent,
    DaypartsQueryModeModalComponent,
    DaypartsStepperComponent,
    DaypartsCriteriaRendererComponent,
    DaypartsResultComponent
  ],
  imports: [
    ActivityModule,
    RouterModule,
    FormsModule,
    CommonModule,
    SharedModule,
    CoreModule,
    GridModule,
    SiqFormsModule,
    AgGridModule,
    FilterModule,
    DatesModule,
    DaypartsRoutingModule,
    VisualLibModule
  ],
  providers: [
    DaypartsService,
    DaypartsConfig,
    DaypartsCloneResolverService
  ]
})
export class DaypartsModule { }

<siq-app-header [config]="config"></siq-app-header>
<button
  mat-raised-button
  color="accent"
  class="new-report-button"
  [routerLink]="['/app/affinities/~']"
>Create New</button>

<div class="app-container-wrapper no-bg">
  <section class="app-container limited-width-content content-padded">
    <siq-activity-grid
      [activityStream$]="activityStream$"
      [columns]="columns"
      [actions]="actions"
      [callbacks]="callbacks"
    ></siq-activity-grid>
  </section>
</div>

import { takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ForgotPasswordService } from 'app/public/services/forgot-password.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NavService } from 'app/core/services/nav/nav.service';
import { LoginComponent } from 'app/public/components/login/login.component';
import { PasswordRulesComponent } from 'app/siq-forms/components/password-rules/password-rules.component';
import { Subject } from 'rxjs';
import { SecurityQuestionPayloadInterface } from 'app/public/models/security-question-payload-interface';
import { ResetPasswordPayloadInterface } from 'app/public/models/reset-password-payload-interface';
import { BaseSiqComponent, ThemesService } from '@siq-js/angular-buildable-lib';

@Component({
  selector: 'siq-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseSiqComponent implements OnInit, OnDestroy {

  public model: {
    email?: string,
    securityQuestion?: string,
    securityAnswer?: string,
    token?: string,
    password?: string,
    confirmPassword?: string
  } = {};

  private loginRoute: Route;
  private unsub = new Subject<void>();

  public errorMessage: string;
  public lightTheme = true;
  public step: number;
  public submitDisabled: boolean;
  @ViewChild(PasswordRulesComponent) passwordRules: PasswordRulesComponent;

  constructor(
    private forgotPasswordService: ForgotPasswordService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();

    this.step = 0;
    this.canSubmit();
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(
      takeUntil(this.unsub)
    )
      .subscribe(params => {
        // Allows for direct URLs with a password reset token to bypass some steps and go straight to password reset
        if (params.get('passwordResetToken')) {
          this.setToken(params.get('passwordResetToken'));
        }
      });

    this.loginRoute = _.find(NavService.getRoutesPublic(), r => r.component === LoginComponent);

    ThemesService.theme$.pipe(
      takeUntil(this.unsub$)
    ).subscribe((theme: string) => {
      // Save to public var; used to show correct image in template
      this.lightTheme = theme === ThemesService.THEMES.LIGHT;
    });
  }

  ngOnDestroy () {
    this.unsub.next();
    this.unsub.complete();
  }

  public canSubmit() {
    setTimeout(() => {
      this.submitDisabled = !this.validateModel();
    });
  }

  public submit() {
    switch (this.step) {
      case 0: // Check if email is valid
        this.validateEmail();
        break;
      case 1: // Retrieve and answer security question
        this.answerSecurityQuestion();
        break;
      case 2: // Enter the token
        this.setToken(this.model.token);
        break;
      case 3: // Enter new password
        this.resetPassword();
        break;
      case 4: // Navigate to login page following successful reset
        this.router.navigateByUrl('');
        break;
      case 5: // Navigate to login page following reciept of no security questions or errors (unable to proceed)
        this.router.navigateByUrl('');
        break;
    }
  }

  private validateModel(): boolean {
    switch (this.step) {
      case 0:
        return !!this.model.email;
      case 1:
        return !!(this.model.securityAnswer || '').trim();
      case 2:
        return !!this.model.token;
      case 3:
        return this.passwordRules && this.passwordRules.validatePasswords();
    }
  }

  private setToken(token: string): void {
    this.model.token = token;
    this.step = 3;
    this.model.password = '';
    this.model.confirmPassword = '';
    this.canSubmit();
  }

  private validateEmail() {
    this.forgotPasswordService
      .validateEmail({ email: this.model.email })
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(res => {
        if (res.body['error']) {
          this.errorMessage = res.body['error'];
        } else if (_.isNil(res.body['securityQuestion'])) { // Either Security Questions are null OR undefined
          this.errorMessage = null;
          this.step = 5;
        } else {
          this.errorMessage = null;
          this.model.securityQuestion = res.body['securityQuestion'];
          this.step++;
          this.canSubmit();
        }
      });
  }

  private answerSecurityQuestion() {
    this.forgotPasswordService.answerSecurityQuestion(<SecurityQuestionPayloadInterface>{
      email: this.model.email,
      securityQuestion: {
        answer: this.model.securityAnswer,
        question: this.model.securityQuestion
      }
    })
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(res => {
        if (res.body['error']) {
          this.errorMessage = res.body['error'];
          this.model.securityAnswer = null;
          this.model.securityQuestion = res.body['securityQuestion'];
        } else {
          this.errorMessage = null;
          this.step++;
          this.canSubmit();
        }
      });
  }

  private resetPassword() {
    this.forgotPasswordService.resetPassword(<ResetPasswordPayloadInterface>{
      newPassword: this.model.password,
      newPasswordConfirmation: this.model.confirmPassword,
      passwordResetToken: this.model.token
    })
      .pipe(
        takeUntil(this.unsub)
      )
      .subscribe(res => {
        this.errorMessage = null;
        this.step++;
      }, err => {
        this.errorMessage = 'Invalid password reset token';
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { CloudExportService } from 'app/core/modules/cloud-export/services/cloud-export.service';
import { Router } from '@angular/router';
import { AsyncStatusService } from 'app/core/services/async-status/async-status.service';

@Component({
  selector: 'siq-cloud-export',
  templateUrl: './cloud-export.component.html',
  styleUrls: ['./cloud-export.component.scss']
})
export class CloudExportComponent implements OnInit {

  public blob_url: string;
  public data: Blob;
  public url: string;
  public ready = false;

  constructor(
    private asyncStatusService: AsyncStatusService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    await this.asyncStatusService.isReady({cms: true});
    this.ready = true;

    CloudExportService.Data$.subscribe(data => {
      this.data = data;
      if (data) {
        this.blob_url = URL.createObjectURL(data);
        }
    });
  }

  navToUrl() {
    this.router.navigate([this.url]);
  }

}

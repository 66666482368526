import { Component, OnInit } from '@angular/core';
import { StatusRendererBaseComponent } from 'app/core/components/cell-renderers/status-renderer/status-renderer-base.component';
import { ICellRendererParams } from '@siq-js/visual-lib';

@Component({
  selector: 'siq-status-renderer',
  templateUrl: './status-renderer.component.html',
  styleUrls: ['./status-renderer.component.scss']
})
export class StatusRendererComponent extends StatusRendererBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  agInit(params: ICellRendererParams): void {
    this.iconStatus = StatusRendererComponent.StatusIconMap.get(params.value.toLowerCase());
  }

}

import { Component, OnInit } from '@angular/core';
import { DropdownMenuItem } from 'app/core/components/dropdown-menu/dropdown-menu-item/dropdown-menu-item.interface';
import { Activity } from 'app/activity/models/activity.model';

@Component({
  selector: 'siq-activity-menu-shared-item',
  templateUrl: './shared-item.component.html',
  styleUrls: ['./shared-item.component.scss']
})
export class ActivityMenuSharedItemComponent implements OnInit {

  public template: { [key: string]: string | boolean} = {};

  private data: DropdownMenuItem<Activity>;

  constructor() { }

  ngOnInit() {
    this.template.name = this.data.data.getName();
    this.template.owner = this.data.data.getCreatedBy();
    this.template.viewed = this.data.data.isViewed();
    this.template.routerLink = this.data.routerLink || this.data.data.getPath();
  }

}
